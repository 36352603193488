import { InfoIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { PurchaseShowData, useGetPurchaseReturns } from "../../../api/purchase";
import {
  PurchaseReturnLineItemsShowData,
  useCreatePurchaseReturnServiceRequest,
} from "../../../api/purchase_returns";
import { UserMinimalData } from "../../../api/user";

type PurchaseReturnRequiredAlertProps = {
  user: UserMinimalData;
  purchase: PurchaseShowData;
  purchaseReturnLineItems: PurchaseReturnLineItemsShowData[];
};

export const PurchaseReturnRequiredAlert = ({
  user,
  purchase,
  purchaseReturnLineItems,
}: PurchaseReturnRequiredAlertProps) => {
  const toast = useToast();
  const { mutateAsync: createPurchaseReturnServiceRequest, isLoading } =
    useCreatePurchaseReturnServiceRequest();

  const { data: purchaseReturns } = useGetPurchaseReturns(purchase.id);

  const handleCreateServiceRequest = () => {
    const returnId = _.last(purchaseReturns)?.id;
    if (!returnId) return;

    createPurchaseReturnServiceRequest(returnId).catch((error) => {
      console.error(error);
      toast({
        title: "An error occurred.",
        description: error.response?.data?.error?.message || "Unknown error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  return (
    <Flex
      width={"100%"}
      direction={"column"}
      borderRadius={"md"}
      border={"1px"}
      borderColor={"orange.200"}
      height={"auto"}>
      <HStack bg={"orange.100"} padding={"3"}>
        <InfoIcon color={"orange.500"} />
        <Text color={"gray.800"} fontSize={"sm"} fontWeight={"bold"}>
          Action Required{" "}
        </Text>
      </HStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg={"orange.50"}
        padding={"3"}
        justifyContent={"space-between"}>
        <HStack>
          <Avatar size="sm" src={user.picture_uri} />
          <Text
            fontSize={"sm"}
            fontWeight={"bold"}
            color={useColorModeValue("gray.800", "gray.800")}>
            {user.name}
          </Text>
          <Text fontSize={"sm"} color={"gray.600"}>
            would like to return {purchaseReturnLineItems.map((item) => `${item.name}`).join(", ")}.
          </Text>
        </HStack>
        <Stack marginLeft={{ base: "0", md: "3" }}>
          <Button
            size="sm"
            colorScheme="teal"
            onClick={handleCreateServiceRequest}
            isLoading={isLoading}>
            <Box as={RiArrowGoBackLine} mr="2" />
            Create Return Request
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};
