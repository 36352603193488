import { ZodSchema, z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from ".";
import { SearchParams, aggregationSchema, indexParams, searchParams } from "./collection_types";
import { paginationSchema } from "./shared";
import { userMinimalSchema } from "./user";
import { timelineEventSchema } from "./timeline_events";
import { zodParse } from "./zodParse";

export const NOTIFICATIONS_BASE_URL = "notifications";

const notificationContentSchema = z.object({
  type: z.string(),
  content: z.string(),
  style: z.string().nullable(),
});

export const notificationSchema = z.object({
  id: z.number(),
  notification_type: z.string(),
  action_required: z.boolean(),
  read: z.boolean(),
  saved: z.boolean(),
  sender: userMinimalSchema.nullable(),
  rendered_content: z.array(notificationContentSchema),
  triggering_event: timelineEventSchema.nullable(),
  content: z.string(),
  notifiable_id: z.number(),
  notifiable_type: z.string(),
  parent_model: z
    .object({
      model_name: z.string(),
      model_id: z.number(),
    })
    .nullable(),
  created_at: z.string(),
});

export const notificationTypesSchema = z.object({
  action_required: z.array(notificationSchema),
  all: z.array(notificationSchema),
  saved: z.array(notificationSchema),
});

export const notificationIndexSchema = z.object({
  results: z.array(notificationSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable(),
  unread_counts: z.object({
    action_required: z.number(),
    all: z.number(),
    saved: z.number(),
  }),
});

export const notificationBulkUpdateSchema = z.object({
  notification_ids: z.array(z.number()),
  read: z.boolean(),
});

export type NotificationData = z.infer<typeof notificationSchema>;
export type NotificationIndexData = z.infer<typeof notificationIndexSchema>;
export type NotificationBulkUpdateData = z.infer<typeof notificationBulkUpdateSchema>;

export const getNotifications = async () => {
  const result = await api.get(`${NOTIFICATIONS_BASE_URL}`);
  return zodParse(notificationTypesSchema, result.data);
};

export const searchNotifications = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [NOTIFICATIONS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(notificationIndexSchema, result.data);
};

export const updateNotification = async (notification: Partial<NotificationData>) => {
  const result = await api.put(`${NOTIFICATIONS_BASE_URL}/${notification.id}`, { notification });
  return result.data;
};

export const updateNotificationsBulk = async (notification: NotificationBulkUpdateData) => {
  const result = await api.put(`${NOTIFICATIONS_BASE_URL}/bulk`, { notification });
  return result.data;
};

export const markAllNotificationsRead = async (notification: Partial<NotificationData>) => {
  const result = await api.put(`${NOTIFICATIONS_BASE_URL}/mark_read`, { notification });
  return result.data;
};

export const deleteNotification = async (id: number) => {
  await api.delete(`${NOTIFICATIONS_BASE_URL}/${id}`);
};

const invalidateNotifications = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [NOTIFICATIONS_BASE_URL],
    });
};

export const useGetNotifications = () => {
  return useQuery({
    queryKey: [NOTIFICATIONS_BASE_URL],
    queryFn: getNotifications,
  });
};

export const useSearchNotifications = (params: SearchParams) => {
  return useQuery({
    queryKey: [NOTIFICATIONS_BASE_URL, params],
    queryFn: () => searchNotifications(params),
    refetchOnWindowFocus: true,
  });
};

export const useUpdateNotification = () => {
  return useMutation({
    mutationFn: updateNotification,
    onSuccess: invalidateNotifications(),
  });
};

export const useUpdateNotificationsBulk = () => {
  return useMutation({
    mutationFn: updateNotificationsBulk,
    onSuccess: invalidateNotifications(),
  });
};

export const useMarkAllNotificationsRead = () => {
  return useMutation({
    mutationFn: markAllNotificationsRead,
    onSuccess: invalidateNotifications(),
  });
};

export const useDeleteNotification = () => {
  return useMutation({
    mutationFn: deleteNotification,
    onSuccess: invalidateNotifications(),
  });
};
