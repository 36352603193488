import { z } from "zod";
import { api } from ".";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Keyring, decryptPrivateKey } from "../keyring";
import { UserLoggedInData, userMinimalSchema, userPartialSchema } from "./user";
import { paginationSchema } from "./shared";
import { zodParse } from "./zodParse";

export const REVIEW_FEEDBACKS_BASE_URL = "review_feedbacks";

export const reviewFeedbackPartialSchema = z.object({
  id: z.number(),
  keyring_message: z.object({
    id: z.number(),
    content: z.string(),
    message_type: z.string(),
    created_at: z.string(),
  }),
  reviewee: userMinimalSchema,
  onboarding_user: z.object({ id: z.number(), name: z.string() }).optional(),
  review_feedback_type: z.string(),
  created_at: z.string(),
  viewed: z.boolean(),
  is_sent: z.boolean(),
  occasion: z.string().optional(),
  send_at: z.string().optional(),
});

export const ReviewFeedbackIndexSchema = z.object({
  results: z.array(reviewFeedbackPartialSchema),
  pagination: paginationSchema,
});

export const reviewFeedbackIndexSchema = z.array(reviewFeedbackPartialSchema);

export type ReviewFeedbackPartialData = z.infer<typeof reviewFeedbackPartialSchema>;
export type ReviewIndexData = z.infer<typeof reviewFeedbackIndexSchema>;
export type ReviewFeedbackIndexData = z.infer<typeof ReviewFeedbackIndexSchema>;

export const REVIEW_FEEDBACKS_QUERY_KEY = "review_feedbacks";

export const decryptReviewFeedback = async (
  feedback: ReviewFeedbackPartialData,
  user: UserLoggedInData,
  pin: string
) => {
  const keyring = new Keyring([]);
  if (!user.encrypted_private_key) {
    console.log("no encrypted private key");
    return false;
  }
  const privateKey = decryptPrivateKey(user.encrypted_private_key, pin);
  if (!privateKey) {
    console.log("failed to decrypt private key");
    return false;
  }
  const success = keyring.decrypt(privateKey, feedback.keyring_message.content);
  if (!success) {
    console.log("failed to decrypt keyring message");
    return false;
  }

  return JSON.parse(keyring.getContent());
};

/** queries */
export const getReviewFeedbacks = async (params?: Object | null) => {
  const result = await api.get(REVIEW_FEEDBACKS_BASE_URL, { params });
  return zodParse(ReviewFeedbackIndexSchema, result.data);
};

export const getOnboardingReviewFeedbacks = async () => {
  const result = await api.get(`${REVIEW_FEEDBACKS_BASE_URL}/onboarding`);
  return result.data;
};

export type newReviewFeedbackParams = {
  revieweeId: string | number;
  content: string | object;
  score: string;
  keys: string[];
  keyringMessageType?: string;
  type?: string;
  send_at?: string;
  occasion?: string;
  onboarding_user_id?: number;
};
export const newReviewFeedback = async ({
  revieweeId,
  content,
  score,
  keys,
  keyringMessageType,
  type,
  send_at,
  occasion,
  onboarding_user_id,
}: newReviewFeedbackParams) => {
  const keyring = new Keyring(keys);
  keyring.setContent(
    JSON.stringify({
      feedback: content,
      score: score,
    })
  );

  const sealedMessage = keyring.getSealedMessage();

  return await api.post(REVIEW_FEEDBACKS_BASE_URL, {
    review_feedback: {
      reviewee_id: revieweeId,
      keyring_message: sealedMessage,
      keyring_message_type: keyringMessageType,
      review_feedback_type: type,
      onboarding_user_id,
      occasion,
      send_at,
    },
  });
};

export type newOnboardingFeedbackParams = {
  reviewee_id: number;
  keyring_message_id: number;
  onboarding_user_id: number;
  review_feedback_type: string;
  occasion: string;
};
export const newOnboardingFeedback = async (reviewFeedback: newOnboardingFeedbackParams) => {
  return await api.post(REVIEW_FEEDBACKS_BASE_URL, {
    review_feedback: reviewFeedback,
  });
};

export const deleteReviewFeedback = async (reviewFeedbackId: string | number) => {
  return api.delete(`${REVIEW_FEEDBACKS_BASE_URL}/${reviewFeedbackId}`);
};

export const updateReviewFeedback = async (reviewFeedback: Partial<ReviewFeedbackPartialData>) => {
  return api.put(`${REVIEW_FEEDBACKS_BASE_URL}/${reviewFeedback.id}`, {
    review_feedback: reviewFeedback,
  });
};

/** hooks */
export const useReviewFeedbacksQuery = (indexParams?: Object | null) => {
  return useQuery({
    queryKey: [REVIEW_FEEDBACKS_QUERY_KEY, indexParams],
    queryFn: () => getReviewFeedbacks(indexParams),
  });
};

export const useGetOnboardingReviewFeedbacks = () => {
  return useQuery({
    queryKey: [REVIEW_FEEDBACKS_QUERY_KEY],
    queryFn: () => getOnboardingReviewFeedbacks(),
  });
};

export const invalidateReviewFeedback = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [REVIEW_FEEDBACKS_QUERY_KEY],
    });
};

export const useUpdateReviewFeedback = () => {
  return useMutation({
    mutationFn: updateReviewFeedback,
    onSuccess: () => invalidateReviewFeedback(),
  });
};
