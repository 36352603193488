import React, { useEffect } from "react";
import { Text } from "@chakra-ui/react";
import { Editable, EditableProps } from "@sciencecorp/helix-components";
import { MoneyInputProps, MoneyInput } from "./MoneyInput";
import { MoneyText, MoneyTextProps } from "./MoneyText";
import { Currency, Money, MoneyFormatOptions } from "../helpers/Money";
import { useCurrency } from "../contexts/CurrencyContext";

export type EditableMoneyProps = Pick<
  EditableProps,
  "disabled" | "persistentEdit" | "isLoading"
> & {
  defaultValue?: Money;
  max?: Money;
  min?: Money;
  placeholder?: number;
  moneyFormatOptions?: Partial<MoneyFormatOptions>;
  moneyInputProps?: MoneyInputProps;
  onSubmit?: (money: Money) => void;
};

export const EditableMoney: React.FC<EditableMoneyProps> = ({
  defaultValue,
  max,
  min,
  placeholder = null,
  onSubmit,
  moneyFormatOptions,
  moneyInputProps,
  ...props
}) => {
  const currency = useCurrency();
  const isDefaultValueDefined = defaultValue !== undefined && defaultValue !== null;
  const [actualValue, setActualValue] = React.useState<Money>();

  useEffect(() => {
    if (defaultValue !== actualValue) {
      setActualValue(defaultValue);
    }
  }, [defaultValue]);

  const getPreview = () => {
    return (
      <MoneyText
        formatOptions={{
          compact: "never",
          ...(moneyFormatOptions || {}),
        }}
        money={defaultValue || Money.zero(currency)}
      />
    );
  };

  return (
    <Editable
      {...props} /** Has to be listed first */
      onSubmit={(value?: string | null) => {
        if (actualValue === null || actualValue === undefined) return;
        if (onSubmit) {
          onSubmit(actualValue);
        }
      }}
      defaultValue={isDefaultValueDefined ? defaultValue.toString() : ""}
      preview={getPreview()}
      renderInput={(value, setValue, submit) => {
        return (
          <MoneyInput
            {...moneyInputProps}
            size={"xs"}
            max={max}
            min={min}
            value={actualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter" && submit) {
                submit();
              }
            }}
            onBlur={() => {
              if (submit) {
                submit();
              }
            }}
            isDisabled={!submit}
            onChange={(value: Money) => {
              setActualValue(value);
              setValue(value.majorUnitString());
            }}
          />
        );
      }}
    />
  );
};
