import axios from "axios";

export const api = axios.create({
  baseURL: "/api",
  headers: {
    "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content"),
  },
});

export const handleLogin = () => {
  window.location.href = "/api/auth/login";
};

export const handleLogout = () => {
  window.location.href = "/api/auth/logout";
};
