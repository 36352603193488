import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Switch,
  Flex,
  Tag,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormModal, Option } from "@sciencecorp/helix-components";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSitesOptions } from "../../../api/options";
import { useCreateServiceRequest } from "../../../api/service_requests";
import type { ServicePartialData } from "../../../api/services";
import { useCreateService, useUpdateService } from "../../../api/services";
import { TeamData } from "../../../api/team";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { LinkifyText } from "../../Purchasing/util";
import { SpendingAuthoritySelect } from "../../Purchasing/SpendingAuthoritySelectTree";
import { NullableSpendingAuthority } from "../../../api/spending_authority";

type TeamServiceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  team: TeamData;
  service?: ServicePartialData;
  setActiveService?: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
};

const defaultServiceValues = {
  name: "",
  description: "",
  support_text: "",
  spending_authority: null,
  external_url: "",
  spending_authority_required: false,
};

// making new services
export const TeamServiceModal = ({
  isOpen,
  onClose,
  team,
  service,
  setActiveService,
}: TeamServiceModalProps) => {
  const { mutate: createService } = useCreateService();
  const { mutate: updateService } = useUpdateService();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultServiceValues,
  });

  useEffect(() => {
    if (service) {
      reset({
        name: service.name,
        description: service.description,
        support_text: service.support_text,
        external_url: service.external_url,
        spending_authority_required: service.spending_authority_required,
      });
    }
  }, [service, reset]);

  const handleClose = () => {
    onClose();
    reset(defaultServiceValues);
    setActiveService && setActiveService(null);
  };

  const onSubmit = (data) => {
    if (service) {
      updateService(
        {
          id: service.id,
          ...data,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      createService(
        { ...data, team_id: team.id },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    }
  };
  return (
    <FormModal
      title={service ? "Edit Service" : "Create a New Service"}
      isOpen={isOpen}
      onClose={handleClose}
      size="lg"
      submitButtonColorSchema="teal"
      submitButtonTitle={service ? "Done" : "Add Service"}
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack width="100%" gap={4} px={2}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Service Name</FormLabel>
              <Input {...field} placeholder="e.g Facility Repairs" />
            </FormControl>
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Service Description</FormLabel>
              <Textarea size="sm" {...field} placeholder="Type Here..." />
            </FormControl>
          )}
        />
        <Controller
          name="support_text"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Instructions to Requestor</FormLabel>
              <Textarea {...field} size="sm" placeholder="Type Here..." />
              <FormHelperText>
                Describe the information users need to provide when submitting a request.
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="spending_authority_required"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <Flex
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <Flex direction="column" gap={0}>
                  <FormLabel mb={0}>Spending Authority Required</FormLabel>
                  <FormHelperText mt={1}>
                    Toggling this on will require the user to set a spend authority when submitting
                    a request.
                  </FormHelperText>
                </Flex>
                <Switch
                  colorScheme="teal"
                  isChecked={field.value === true}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              </Flex>
            </FormControl>
          )}
        />
        <Controller
          name="external_url"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>External URL</FormLabel>
              <Input {...field} placeholder="https://science.xyz/foundry" />
              <FormHelperText>
                If you have an external page that you would like users to redirect to, paste the
                link here.
              </FormHelperText>
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};

type ServiceRequestFormValues = {
  spending_authority: NullableSpendingAuthority;
  site: Option | null;
  request_description: string;
  files: File[];
};

// making new serice requests
export const TeamServiceRequestModal = ({ service, isOpen, onClose, setActiveService }) => {
  const [encodedFiles, setEncodedFiles] = useState<{ name: string; file: string }[]>([]);
  const defaultVal = {
    request_description: "",
    spending_authority: null,
    site: null,
    files: [],
  };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<ServiceRequestFormValues>({ defaultValues: defaultVal });
  const currentUserQuery = useCurrentUserQuery();
  const currentUser = currentUserQuery?.data;
  const { mutateAsync: createServiceRequest, isLoading } = useCreateServiceRequest();
  const requiredFieldsDirty = () => {
    return (
      dirtyFields.request_description &&
      (service.spending_authority_required ? dirtyFields.spending_authority : true) &&
      dirtyFields.site
    );
  };
  const toast = useToast();

  const formValues = watch();

  const navigate = useNavigate();

  const SITE_OPTIONS = useSitesOptions();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => setEncodedFiles(encodedFileObjects))
        .catch((error) => console.log(error));
    }
  };

  const onSubmit = (data) => {
    const { site, request_description } = data;
    if (currentUser && site) {
      createServiceRequest({
        files: encodedFiles,
        site_id: Number(site.value),
        request_description,
        spending_authority_id: data.spending_authority?.id || null,
        spending_authority_type: data.spending_authority?.type || null,
        requesting_user_id: currentUser.id,
        service_id: service.id,
      })
        .then((data) => {
          setActiveService(null);
          reset();
          onClose();
          navigate(`/services/requests/${data.id}`);
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "An error occurred.",
            description: error.response.data.error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const handleClose = () => {
    setActiveService(null);
    onClose();
  };

  return (
    <FormModal
      title="New Request"
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isLoading}
      submitButtonDisabled={!requiredFieldsDirty()}
      size="xl"
      submitButtonColorSchema="teal"
      submitButtonTitle="Create Request"
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack
        border="1px"
        borderColor={useColorModeValue("teal.500", "gray.500")}
        borderRadius="md"
        width="100%"
        align="start"
        p={5}
        spacing={3}
        bg={useColorModeValue("teal.50", "gray.600")}>
        <Stack direction="column" spacing={1}>
          <Link to={`/teams/${service.team.id}/services`}>
            <Tag colorScheme="teal" maxWidth="max-content">
              {service.team.name}
            </Tag>
          </Link>
          <Heading size="sm" fontWeight="medium">
            {service.name}
          </Heading>
          <Text>
            {service.description.split("\n").map((line, i) => (
              <Text
                key={i}
                fontSize={["xs", "sm"]}
                color={useColorModeValue("gray.600", "gray.200")}
                fontWeight="medium">
                {line}
              </Text>
            ))}
          </Text>
        </Stack>
      </VStack>
      <VStack width="100%" gap={4} px={2}>
        <HStack width="100%">
          <Controller
            name="spending_authority"
            control={control}
            render={({ field }) => (
              <FormControl isRequired={service.spending_authority_required}>
                <FormLabel>
                  Spending Authority{" "}
                  {!service.spending_authority_required && (
                    <Box as="span" opacity="0.7" fontWeight="normal">
                      (optional)
                    </Box>
                  )}
                </FormLabel>
                <SpendingAuthoritySelect
                  onChange={(value) => {
                    if (value) {
                      setValue("spending_authority", value, {
                        shouldDirty: true,
                      });
                    }
                  }}
                  spendingAuthority={formValues.spending_authority}
                  excludeTypes={["Service"]}
                />
              </FormControl>
            )}
          />
          <Controller
            name="site"
            control={control}
            render={({ field }) => (
              <FormControl isRequired={true}>
                <FormLabel>Site</FormLabel>
                <Select {...field} useBasicStyles placeholder="Select" options={SITE_OPTIONS} />
              </FormControl>
            )}
          />
        </HStack>
        <Controller
          name="request_description"
          control={control}
          render={({ field }) => (
            <FormControl isRequired={true}>
              <FormLabel>Describe what you need</FormLabel>
              <Textarea size="sm" {...field} placeholder="Type Here..." />
              <FormHelperText>
                <LinkifyText
                  text={service.support_text || ""}
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-word"
                  linkProps={{
                    maxWidth: ["35ch", "40ch", "40ch", "40ch", "50ch", "50ch"],
                  }}
                />
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="files"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Attach file</FormLabel>
              <Box
                width="100%"
                border="1px"
                p={1}
                borderColor={useColorModeValue("gray.200", "gray.500")}
                borderRadius="base">
                <input
                  name="files"
                  type="file"
                  multiple={true}
                  ref={field.ref}
                  onChange={handleFileChange}
                />
              </Box>
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};
