import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { searchParams } from "./collection_types";
import { TEAMS_BASE_URL } from "./team";
import { zodParse } from "./zodParse";

export const SERVICES_BASE_URL = "services";

export const servicePartialSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number(),
  description: z.string(),
  support_text: z.string(),
  external_url: z.string(),
  archived: z.boolean(),
  spending_authority_required: z.boolean(),
  team: z.object({ id: z.number(), name: z.string() }),
  service_request_count: z.number(),
});

export type ServicePartialData = z.infer<typeof servicePartialSchema>;
export type ServiceCreateParams = Pick<
  ServicePartialData,
  "name" | "team_id" | "description" | "support_text" | "spending_authority_required"
>;
//api queries

export const getAllServices = async () => {
  const result = await api.get(`${SERVICES_BASE_URL}`);
  return result.data;
};

export const getTeamServices = async (teamId: number) => {
  const result = await api.get(`${TEAMS_BASE_URL}/${teamId}/${SERVICES_BASE_URL}`);
  return result.data;
};

export const getService = async (id: number | undefined) => {
  const result = await api.get(`${SERVICES_BASE_URL}/${id}`);
  return zodParse(servicePartialSchema, result.data);
};

export const getUniqueTeamsWithServices = async () => {
  const result = await api.get(`${SERVICES_BASE_URL}/teams`);
  return result.data;
};

export const searchService = async ({ aggs, filters, term }) => {
  const path = [SERVICES_BASE_URL, "search"];
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...search });
  return result.data;
};

export const createService = async (service: ServiceCreateParams) => {
  return await api.post(`${SERVICES_BASE_URL}`, { service });
};

export const updateService = async (service: Partial<ServicePartialData>) => {
  const result = await api.put(`${SERVICES_BASE_URL}/${service.id}`, { service });
  return result.data;
};

export const deleteServce = async (id: number) => {
  await api.delete(`${SERVICES_BASE_URL}/${id}`);
};

export const invalidateService = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [SERVICES_BASE_URL] });
};

export const useGetAllServicesQuery = () => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL],
    queryFn: () => getAllServices(),
  });
};

export const useGetTeamServicesQuery = (teamId: number) => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL],
    queryFn: () => getTeamServices(teamId),
  });
};

export const useGetServiceQuery = (id: number | undefined) => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, id],
    queryFn: () => getService(id),
    enabled: !!id,
  });
};

export const useSearchServices = (params) => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, params],
    queryFn: () => searchService(params),
  });
};

export const useGetUniqueTeamWithServicesQuery = () => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, "team"],
    queryFn: () => getUniqueTeamsWithServices(),
  });
};

export const useCreateService = () => {
  return useMutation({
    mutationFn: createService,
    onSuccess: invalidateService(),
  });
};

export const useUpdateService = () => {
  return useMutation({
    mutationFn: updateService,
    onSuccess: invalidateService(),
  });
};

export const useDeleteService = () => {
  return useMutation({
    mutationFn: deleteServce,
    onSuccess: invalidateService(),
  });
};
