import { Button, useDisclosure } from "@chakra-ui/react";
import { ConfirmationModal } from "@sciencecorp/helix-components";
import forge from "node-forge";
import React from "react";

export function getPublicKeyFingerprint(publicKey: string) {
  const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
  const publicKeyFingerprint = forge.pki.getPublicKeyFingerprint(publicKeyObj, {
    encoding: "hex",
    delimiter: ":",
  });
  return publicKeyFingerprint;
}

export const ButtonWithConfirmation = ({
  colorScheme,
  isDisabled = false,
  message,
  actionLabel,
  onConfirm,
  size = "md",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button colorScheme={colorScheme} onClick={onOpen} isDisabled={isDisabled} size={size}>
        {actionLabel}
      </Button>
      <ConfirmationModal
        colorScheme={colorScheme}
        confirmText={actionLabel}
        header={actionLabel}
        isOpen={isOpen}
        onClick={() => {
          onConfirm();
          onClose();
        }}
        onClose={onClose}>
        {message}
      </ConfirmationModal>
    </>
  );
};
