import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Select } from "@sciencecorp/helix-components";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { searchEmployees } from "../../api/hr";
import {
  generateReviewPromptsForAllPairs,
  generateReviewPromptsForReviewee,
} from "../../api/review";

const TriggerReviewPromptsModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [revieweeQuery, setRevieweeQuery] = useState<string>("");
  const [revieweeResults, setRevieweeResults] = useState<{ value: string; label: string }[]>([]);
  const [revieweeEmail, setRevieweeEmail] = useState<string>("");

  const [reviewerQuery, setReviewerQuery] = useState<string>("");
  const [reviewerResults, setReviewerResults] = useState<{ value: string; label: string }[]>([]);
  const [reviewerEmails, setReviewerEmails] = useState<string[]>([]);

  const [pairQuery, setPairQuery] = useState<string>("");
  const [pairResults, setPairResults] = useState<{ value: string; label: string }[]>([]);
  const [pairEmails, setPairEmails] = useState<string[]>([]);

  const searchAndFilterEmployees = (
    term: string,
    results: { value: string; label: string }[],
    setResults: (results: { value: string; label: string }[]) => void
  ) => {
    searchEmployees({ term }).then((response) => {
      setResults(
        response.results
          .filter((employee) => employee.is_reviewable)
          .filter((employee) => results.every((result) => result.value !== employee.email))
          .map((employee) => ({ value: employee.email, label: employee.name }))
      );
    });
  };

  useEffect(() => {
    searchAndFilterEmployees(revieweeQuery, revieweeResults, setRevieweeResults);
  }, [revieweeQuery]);

  useEffect(() => {
    searchAndFilterEmployees(reviewerQuery, reviewerResults, setReviewerResults);
  }, [reviewerQuery]);

  useEffect(() => {
    searchAndFilterEmployees(pairQuery, pairResults, setPairResults);
  }, [pairQuery]);

  const handleResponse = (response: AxiosResponse, onSuccess: () => void) => {
    if (response.status === 201) {
      toast({
        title: "Review Prompts Triggered",
        description: `Successfully triggered ${response.data.length} review prompts`,
        status: "success",
      });
      onSuccess();
    } else {
      toast({
        title: "Error Triggering Review Prompts",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="green" onClick={onOpen}>
        Review Prompts
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manually Trigger Review Prompts</ModalHeader>
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>For Reviewee</Tab>
                <Tab>All Pairs</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Stack spacing={8}>
                    <FormControl>
                      <FormLabel>Reviewee</FormLabel>
                      {revieweeEmail ? (
                        <Tag size="lg">
                          <TagLabel>{revieweeEmail}</TagLabel>
                          <TagCloseButton onClick={() => setRevieweeEmail("")} />
                        </Tag>
                      ) : (
                        <Select
                          placeholder="Search employees..."
                          options={revieweeResults}
                          onChange={(value) => {
                            setRevieweeEmail(String(value));
                          }}
                          onInputChange={(value) => {
                            setRevieweeQuery(value);
                          }}
                          value={revieweeQuery}
                          width={"100%"}
                        />
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Reviewers</FormLabel>
                      {reviewerEmails.length > 0 && (
                        <Wrap mb={4}>
                          {reviewerEmails.map((reviewerEmail) => (
                            <Tag size="lg">
                              <TagLabel>{reviewerEmail}</TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setReviewerEmails(
                                    reviewerEmails.filter((email) => email !== reviewerEmail)
                                  )
                                }
                              />
                            </Tag>
                          ))}
                        </Wrap>
                      )}
                      <Select
                        placeholder="Search employees..."
                        options={reviewerResults}
                        onChange={(value) => {
                          setReviewerEmails([...reviewerEmails, String(value)]);
                        }}
                        onInputChange={(value) => {
                          setReviewerQuery(value);
                        }}
                        value={reviewerQuery}
                        width={"100%"}
                      />
                    </FormControl>
                    <FormControl>
                      <Button
                        colorScheme="green"
                        isDisabled={!revieweeEmail || reviewerEmails.length === 0}
                        onClick={async () => {
                          const response = await generateReviewPromptsForReviewee({
                            revieweeEmail,
                            reviewerEmails,
                          });
                          handleResponse(response, () => {
                            setRevieweeEmail("");
                            setReviewerEmails([]);
                          });
                        }}>
                        Trigger {reviewerEmails.length} Review Prompts
                      </Button>
                    </FormControl>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={8}>
                    <FormControl>
                      <FormLabel>Employees</FormLabel>
                      {pairEmails.length > 0 && (
                        <Wrap mb={4}>
                          {pairEmails.map((pairEmail) => (
                            <Tag size="lg">
                              <TagLabel>{pairEmail}</TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setPairEmails(pairEmails.filter((email) => email !== pairEmail))
                                }
                              />
                            </Tag>
                          ))}
                        </Wrap>
                      )}
                      <Select
                        placeholder="Search employees..."
                        options={pairResults}
                        onChange={(value) => {
                          setPairEmails([...pairEmails, String(value)]);
                        }}
                        onInputChange={(value) => {
                          setPairQuery(value);
                        }}
                        value={pairQuery}
                        width={"100%"}
                      />
                    </FormControl>
                    <FormControl>
                      <Button
                        colorScheme="green"
                        isDisabled={pairEmails.length === 0}
                        onClick={async () => {
                          const response = await generateReviewPromptsForAllPairs({
                            emails: pairEmails,
                          });
                          handleResponse(response, () => {
                            setPairEmails([]);
                          });
                        }}>
                        Trigger {pairEmails.length * (pairEmails.length - 1)} Review Prompts
                      </Button>
                    </FormControl>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TriggerReviewPromptsModal;
