import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Stack,
  Flex,
  Heading,
  useColorModeValue,
  VStack,
  HStack,
  Divider,
  Box,
  Text,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableText,
  EditableSelect,
  RecordLink,
  ConfirmationButton,
} from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import {
  invalidateInventories,
  InventoryShowData,
  updateInventory,
} from "../../../../api/inventory";
import {
  useUpdateInventoryVendor,
  useDeleteInventoryVendor,
} from "../../../../api/inventory_vendor";
import { EditableLink } from "../../../Purchasing/Purchase/components/EditableLink";
import { useUserOptions } from "../../../../api/options";
import { InventoryCategoryOptions } from "../../util";
import { EditableInventoryAssociation } from "./EditableInventoryAssociation";
import { InventoryVendorModal } from "./InventoryVendorModal";
import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";
import { useDeleteFile } from "../../../../api/blob_files";
import { BlobUploadButton } from "../../../shared/BlobUploadButton";

type InventoryDetailInformationProps = {
  inventory: InventoryShowData;
  isInventoryManager: boolean;
};

export const InventoryDetailInformation = ({
  inventory,
  isInventoryManager,
}: InventoryDetailInformationProps) => {
  const userOptions = useUserOptions();
  const { mutate: updateInventoryVendor } = useUpdateInventoryVendor(inventory.id);
  const { mutate: deleteInventoryVendor } = useDeleteInventoryVendor(inventory.id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateInventories(inventory.id));
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      w="100%"
      h="100%"
      alignItems="stretch"
      justifyContent="space-between"
      gap="0">
      <Flex
        flex="1"
        direction="column"
        p={6}
        w="100%"
        border="1px"
        borderRight={["1px", "0px"]}
        borderColor="chakra-border-color"
        borderBottomLeftRadius={["auto", "md"]}
        borderTopLeftRadius={["auto", "md"]}>
        <Stack direction={{ base: "column", md: "column" }} spacing={2} w="100%" mb={4}>
          <Heading size="md" fontWeight="medium">
            Basic Information
          </Heading>
          <Box bg={useColorModeValue("gray.50", "gray.700")} borderRadius="sm">
            <AttributesTable
              attributes={[
                {
                  label: "ID/SKU",
                  value: (
                    <EditableText
                      defaultValue={inventory.sku || ""}
                      preview={inventory.sku || ""}
                      onSubmit={(value) => {
                        updateInventory({ id: inventory.id, sku: String(value) });
                      }}
                    />
                  ),
                },
                {
                  label: "Type",
                  value: (
                    <Box maxW={40}>
                      <EditableSelect
                        options={[
                          { label: "Consumable", value: "consumable" },
                          { label: "Durable", value: "durable" },
                        ]}
                        selectedValue={inventory.is_consumable ? "consumable" : "durable"}
                        onSubmit={(value) => {
                          updateInventory({
                            id: inventory.id,
                            is_consumable: value === "consumable",
                          });
                        }}
                      />
                    </Box>
                  ),
                },
                {
                  label: "Category",
                  value: (
                    <EditableSelect
                      selectedValue={inventory.category || ""}
                      preview={titleize(humanize(inventory.category)) || ""}
                      options={InventoryCategoryOptions}
                      onSubmit={(value) => {
                        updateInventory({ id: inventory.id, category: String(value) });
                      }}
                    />
                  ),
                },
                {
                  label: "Owner(s)",
                  value: (
                    <EditableInventoryAssociation
                      associationOptions={userOptions}
                      inventoryId={inventory.id}
                      currentAssociations={inventory.users}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Stack>
      </Flex>
      <Stack
        flex="1"
        direction={{ base: "column", md: "column" }}
        w="100%"
        h="100%"
        gap={0}
        justifyContent="space-between">
        <Flex
          flex="1"
          direction="row"
          border={"1px"}
          borderColor="chakra-border-color"
          borderTopRightRadius={["auto", "md"]}
          w="100%"
          h="100%"
          p={6}
          justifyContent="space-between">
          <VStack align="start" h="100%" w="100%">
            <Heading size="md" mb={2} fontWeight="medium">
              Supplier Information
            </Heading>
            <VStack w="100%">
              <Flex direction="column" w="100%" px={[0, 3]}>
                <HStack w="100%" justify="space-between">
                  <Text
                    textTransform={"uppercase"}
                    fontSize="xs"
                    letterSpacing={0.5}
                    fontWeight={"bold"}>
                    Vendors
                  </Text>
                  {isInventoryManager && <InventoryVendorModal inventory={inventory} />}
                </HStack>
                {inventory.vendors.map((vendor) => (
                  <Flex direction="column" gap={1} key={`inventory-vendor-${vendor.id}`}>
                    <HStack w="100%" key={vendor.id} justify="space-between" py={2}>
                      <RecordLink
                        identifier={vendor.vendor.name}
                        link={`/services/vendors/${vendor.vendor.id}`}
                      />
                      <HStack>
                        <EditableLink
                          url={vendor.link || ""}
                          onSubmit={(value) => {
                            if (!value) return;
                            updateInventoryVendor({ id: vendor.id, link: String(value) });
                          }}
                        />
                        <ConfirmationButton
                          size="xs"
                          aria-label="deletes supplier"
                          icon={<DeleteIcon />}
                          variant="IconButton"
                          label="Delete Supplier"
                          children="Are you sure you want to delete this supplier?"
                          confirmationButtonLabel="Delete"
                          onConfirm={() => deleteInventoryVendor(vendor.id)}
                        />
                      </HStack>
                    </HStack>
                    <Divider />
                  </Flex>
                ))}
              </Flex>
              <AttributesTable
                attributes={[
                  {
                    label: "Original Equipment Manufacturer",
                    value: (
                      <EditableText
                        defaultValue={inventory.original_equipment_manufacturer || ""}
                        preview={inventory.original_equipment_manufacturer || ""}
                        onSubmit={(value) => {
                          updateInventory({
                            id: inventory.id,
                            original_equipment_manufacturer: String(value),
                          });
                        }}
                      />
                    ),
                  },
                  {
                    label: "Manufacturing Part Number",
                    value: (
                      <EditableText
                        defaultValue={inventory.manufacturing_part_number || ""}
                        preview={inventory.manufacturing_part_number || ""}
                        onSubmit={(value) => {
                          updateInventory({
                            id: inventory.id,
                            manufacturing_part_number: String(value),
                          });
                        }}
                      />
                    ),
                  },
                ]}
              />
            </VStack>
          </VStack>
        </Flex>
        <Flex
          flex="1"
          direction="row"
          border={"1px"}
          borderColor="chakra-border-color"
          borderBottomRightRadius={["auto", "md"]}
          borderTop="0px"
          p={6}
          w="100%"
          justifyContent="space-between">
          <Stack align="start" gap={4}>
            <Box>
              <Heading size="md" mb={2} fontWeight="medium">
                Notes
              </Heading>
              <EditableText
                defaultValue={inventory.description || undefined}
                onSubmit={(value) => {
                  updateInventory({ id: inventory.id, description: String(value) });
                }}
              />
            </Box>
            <Flex direction="column" gap={2}>
              <Heading size="md" fontWeight="medium">
                Attachments
              </Heading>
              <HStack spacing={2} flexWrap="wrap">
                {inventory.uploaded_files.map((file) => (
                  <DeleteableFileDownload key={file.id} file={file} deleteFile={deleteFile} />
                ))}
                {isInventoryManager && (
                  <BlobUploadButton
                    fileableColumn="uploaded_files"
                    recordId={inventory.id}
                    recordType="Inventory"
                    onSuccessCallback={() => invalidateInventories(inventory.id)}
                  />
                )}
              </HStack>
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};
