import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Textarea,
  useDisclosure,
  useToast,
  Text,
  VStack,
  useColorModeValue,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { MoneyInput } from "../../../../MoneyInput";
import { Money } from "../../../../../helpers/Money";
import { useUpdateBudgetGroupSchedule } from "../../../../../api/budget_group_schedules";
import { EditIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import { BudgetGroupStats } from "./BudgetGroupStats";
import {
  BudgetGroupShowData,
  useGetAllBudgetGroupsSummary,
} from "../../../../../api/budget_groups";

type FormValuesType = {
  amount: Money;
  schedule_type: "one_time" | "monthly" | null;
  memo: string;
  start_date: string;
  end_date: string;
};

const defaultFormValues: FormValuesType = {
  amount: Money.zero(),
  schedule_type: null,
  memo: "",
  start_date: "",
  end_date: "",
};

export const EditAllocationModal = ({ budgetGroup }: { budgetGroup: BudgetGroupShowData }) => {
  const currency = "USD";
  const toast = useToast();
  const endDate = DateTime.now().endOf("year");

  const budgetGroupSchedule = budgetGroup.budget_group_schedule;

  const companySummaryQuery = useGetAllBudgetGroupsSummary(endDate);

  const { mutateAsync: updateBudgetGroupSchedule, isLoading } = useUpdateBudgetGroupSchedule();

  const { control, watch, reset, handleSubmit } = useForm<FormValuesType>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    reset({
      amount: budgetGroup.budget_group_schedule.amount,
      schedule_type: budgetGroup.budget_group_schedule.schedule_type,
      start_date: budgetGroup.budget_group_schedule.start_date?.toISODate() || "",
      end_date: budgetGroup.budget_group_schedule.end_date?.toISODate() || "",
      memo: budgetGroup.budget_group_schedule.memo,
    });
  }, [budgetGroup]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formValues = watch();

  const disableSubmitButton =
    formValues.amount === Money.zero() ||
    !formValues.schedule_type ||
    (formValues.schedule_type === "one_time" && (!formValues.start_date || !formValues.end_date));

  const handleClose = () => {
    reset();
    onClose();
  };

  const fontColor = useColorModeValue("gray.500", "auto");

  const onSubmit = (data: FormValuesType) => {
    if (data.schedule_type) {
      updateBudgetGroupSchedule({
        id: budgetGroupSchedule.id,
        amount: data.amount,
        schedule_type: data.schedule_type,
        memo: data.memo,
        start_date: DateTime.fromISO(data.start_date),
        end_date: DateTime.fromISO(data.end_date),
      })
        .then(() => {
          toast({
            title: "Success!",
            description: "You changed the budget group's allocation",
            status: "success",
          });
          reset();
          onClose();
        })
        .catch(() => {
          toast({
            title: "Error!",
            description: "Failed to update the budget groups allocation",
            status: "error",
          });
        });
    }
  };

  const withinSchedule =
    budgetGroup.budget_group_schedule.schedule_type === "one_time" &&
    DateTime.now() > budgetGroup.budget_group_schedule.start_date! &&
    budgetGroup.budget_group_schedule.end_date! > DateTime.now();

  return (
    <>
      <Tooltip label="Editing is locked during the funding period" isDisabled={!withinSchedule}>
        <IconButton
          icon={<EditIcon />}
          onClick={onOpen}
          aria-label="edit-allocation"
          size="sm"
          isDisabled={withinSchedule}
        />
      </Tooltip>

      <FormModal
        title="Edit Budget Group Schedule"
        submitButtonColorSchema="teal"
        submitButtonTitle="Submit"
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={disableSubmitButton}
        handleSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="amount"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <FormControl isRequired>
                <FormLabel>Allocation Amount</FormLabel>
                <MoneyInput
                  {...field}
                  min={Money.fromMinorUnits(Number.MIN_SAFE_INTEGER, currency)}
                />
              </FormControl>
            );
          }}
        />

        <Controller
          name="schedule_type"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Type</FormLabel>
              <Select
                options={[
                  { label: "One Time", value: "one_time" },
                  { label: "Recurring Monthly", value: "monthly" },
                ]}
                {...field}
                placeholder="Choose"
              />
            </FormControl>
          )}
        />

        {formValues.schedule_type === "one_time" && (
          <Stack direction={{ base: "column", md: "row" }} w="100%">
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Start Date</FormLabel>
                  <Input type="date" {...field} />
                </FormControl>
              )}
            />
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>End Date</FormLabel>
                  <Input type="date" {...field} />
                </FormControl>
              )}
            />
          </Stack>
        )}

        <Controller
          name="memo"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Memo</FormLabel>
              <Textarea {...field} />
            </FormControl>
          )}
        />
        <VStack w="100%" bg={"gray.50"} p={3}>
          <Text fontSize="sm" color={fontColor}>
            This budget group as of {DateTime.now().toFormat("LLL dd yyyy")}
          </Text>
          <BudgetGroupStats summary={budgetGroup.summary} />
        </VStack>

        {companySummaryQuery.isLoading ? (
          <Spinner />
        ) : companySummaryQuery.isError ? (
          <Text>Error loading company wide summary</Text>
        ) : (
          <VStack w="100%" bg={"gray.50"} p={3}>
            <Text fontSize="sm" color={fontColor}>
              Company wide as of today
            </Text>
            <BudgetGroupStats summary={companySummaryQuery.data.spend_summary} />
          </VStack>
        )}
      </FormModal>
    </>
  );
};
