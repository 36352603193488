import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Progress,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Select } from "@sciencecorp/helix-components";
import { DebouncedFunc } from "lodash";
import React, { useState } from "react";
import {
  invalidatePurchases,
  PurchaseFinancialsData,
  PurchaseShowData,
} from "../../../../api/purchase";
import { PurchaseLineItemShowData } from "../../../../api/purchase_line_item";
import {
  useCreatePurchasePayment,
  useDeletePurchasePayment,
  useUpdatePurchasePayment,
} from "../../../../api/purchase_payment";
import { UserLoggedInData } from "../../../../api/user";
import { Money } from "../../../../helpers/Money";
import { useDebounced } from "../../../hooks/useDebouncedUpdate";
import { MoneyText } from "../../../MoneyText";
import { disableAddNewPayment } from "../util";
import { EditPurchaseLineItemList } from "./PurchaseLineItemList";
import { PurchasePaymentList } from "./PurchasePayment";
import { useCurrency } from "../../../../contexts/CurrencyContext";

type PaymentDetailsProps = {
  purchase: PurchaseShowData;
  purchaseFinancials: PurchaseFinancialsData;
  isPurchasingAdmin: boolean;
  currentUser: UserLoggedInData;
};

export const PaymentDetails = ({
  purchase,
  purchaseFinancials,
  isPurchasingAdmin,
  currentUser,
}: PaymentDetailsProps) => {
  const [paymentType, setPaymentType] = useState<string>(
    purchaseFinancials.payment_schedule ? purchaseFinancials.payment_schedule.schedule_type : "cash"
  );
  const { mutate: createPurchasePaymentNonDebounced } = useCreatePurchasePayment(() =>
    invalidatePurchases(purchase.id)
  );
  const { mutate: updatePurchasePaymentNonDebounced } = useUpdatePurchasePayment(() =>
    invalidatePurchases(purchase.id)
  );
  const { mutate: deletePurchasePaymentNonDebounced } = useDeletePurchasePayment(() =>
    invalidatePurchases(purchase.id)
  );

  const createPurchasePayment: DebouncedFunc<typeof createPurchasePaymentNonDebounced> =
    useDebounced(createPurchasePaymentNonDebounced, [purchase.id], 500);
  const updatePurchasePayment: DebouncedFunc<typeof updatePurchasePaymentNonDebounced> =
    useDebounced(updatePurchasePaymentNonDebounced, [purchase.id], 500);
  const deletePurchasePayment: DebouncedFunc<typeof deletePurchasePaymentNonDebounced> =
    useDebounced(deletePurchasePaymentNonDebounced, [purchase.id], 500);

  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");
  const paidSumAmount = purchase.purchase_payments_paid;
  const totalSumAmount = purchase.line_items_sum;

  const pctPaid = Money.pct(paidSumAmount, totalSumAmount).toNumber();

  const hasPayments =
    purchaseFinancials?.payment_schedule !== null ||
    purchaseFinancials?.purchase_payments?.length > 0;

  return (
    <VStack
      align="start"
      width="100%"
      spacing={4}
      p={6}
      border={"1px"}
      borderColor="chakra-border-color"
      borderRadius="md"
      h="100%"
      w="100%">
      <Flex
        flexDirection={["column", "column", "row"]}
        justify="space-between"
        gap={4}
        width="100%">
        <HStack spacing={1}>
          <Heading size="md">Payments</Heading>
          {!purchaseFinancials?.purchase_payments.length ? (
            <Tooltip label=' Add payments to the purchase by pressing the "Add New Payment" button'>
              <Text fontSize="sm">
                <InfoIcon />
              </Text>
            </Tooltip>
          ) : null}
        </HStack>
        <HStack>
          <Text fontSize="sm">Type</Text>
          <Box w={"max-content"}>
            <Select
              size="sm"
              value={paymentType}
              isDisabled={hasPayments || !isPurchasingAdmin || purchase.status == "declined"}
              options={[{ label: "Cash", value: "cash" }]}
              onChange={(value) => {
                value && setPaymentType(value.toString());
              }}
            />
          </Box>
          {isPurchasingAdmin && (
            <Button
              size="sm"
              isDisabled={disableAddNewPayment(purchase)}
              leftIcon={<AddIcon />}
              onClick={() => createPurchasePayment({ purchase_id: purchase.id })}>
              {isSmallScreen ? "Add New" : "New Payment"}
            </Button>
          )}
        </HStack>
      </Flex>
      {purchase.status !== "declined" && totalSumAmount !== undefined && (
        <VStack width="100%" align="start" pb={3}>
          <Heading size="sm">
            <MoneyText as={"span"} money={paidSumAmount} formatOptions={{ compact: "never" }} /> /{" "}
            <Box as="span" fontSize="sm" fontWeight="medium" color="gray.500">
              <MoneyText as={"span"} money={totalSumAmount} formatOptions={{ compact: "never" }} />
            </Box>{" "}
            {!totalSumAmount.subtract(paidSumAmount).isZero() && (
              <Box as="span" fontSize="sm" fontWeight="normal" color="red.500">
                (
                <MoneyText
                  as={"span"}
                  money={totalSumAmount.subtract(paidSumAmount)}
                  formatOptions={{ compact: "never" }}
                />{" "}
                remaining)
              </Box>
            )}
          </Heading>
          <Progress width="100%" size="lg" value={pctPaid} colorScheme="teal" />
        </VStack>
      )}
      <Box w="100%">
        {purchaseFinancials?.payment_schedule ? (
          <PurchasePaymentList
            purchasePayments={purchaseFinancials.payment_schedule.purchase_payments}
            location="contract"
            paymentScheduleId={purchaseFinancials.payment_schedule.id}
            isPurchasingAdmin={isPurchasingAdmin}
            updatePurchasePayment={updatePurchasePayment}
            deletePurchasePayment={deletePurchasePayment}
            createPurchasePayment={createPurchasePayment}
            uploadFileOnSuccess={() => invalidatePurchases(purchase.id, "financials")}
          />
        ) : purchaseFinancials?.purchase_payments.length ? (
          <PurchasePaymentList
            purchasePayments={purchaseFinancials.purchase_payments}
            isPurchasingAdmin={isPurchasingAdmin}
            updatePurchasePayment={updatePurchasePayment}
            deletePurchasePayment={deletePurchasePayment}
            location="purchase"
            purchase={purchase}
            currentUser={currentUser}
            uploadFileOnSuccess={() => invalidatePurchases(purchase.id, "financials")}
          />
        ) : null}
      </Box>
      <EditPurchaseLineItemList purchase={purchase} />
    </VStack>
  );
};
