export const reimbursementStatusColor = (status: string) => {
  switch (status) {
    case "draft":
      return "gray";
    case "needs_approval":
      return "red";
    case "declined":
      return "red";
    case "approved":
      return "green";
  }
  return "gray";
};
