import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";

export const HrWarning = () => {
  return (
    <Flex
      backgroundColor={"red.400"}
      padding={2}
      mb={5}
      mx={-4}
      justify="center"
      align="center"
      gap={4}>
      <Icon as={AiOutlineEye} bg="white" borderRadius="md" boxSize={6} color="red.400" />
      <Text fontWeight="medium" fontSize="sm" color={"white"}>
        HR ADMIN - SENSITIVE - PROTECT THIS INFORMATION
      </Text>
    </Flex>
  );
};
