import React, { useEffect, useRef, useState } from "react";
import {
  Heading,
  Text,
  useColorModeValue,
  VStack,
  LinkBox,
  HStack,
  Box,
  Avatar,
  Divider,
  Tag,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ProjectIndex } from "../../../api/planning/projects";
import { MoneyText } from "../../MoneyText";
import { Money } from "../../../helpers/Money";

const statusMap = [
  { label: "Draft", value: "draft", color: "gray" },
  { label: "Active", value: "active", color: "green" },
  { label: "Approved", value: "approved", color: "blue" },
  { label: "Needs Approval", value: "needs_approval", color: "red" },
  { label: "Declined", value: "declined", color: "red" },
  { label: "Abandoned", value: "abandoned", color: "orange" },
  { label: "Completed", value: "completed", color: "green" },
  { label: "Suspended", value: "suspended", color: "yellow" },
];

interface ProjectCardProps {
  project: ProjectIndex;
  summary?: {
    spent_amount: Money;
  };
  summaryIsLoading: boolean;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project, summary, summaryIsLoading }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [avatarGroupSize, setAvatarGroupSize] = useState(0);

  const handleResize = () => {
    setTimeout(() => {
      const containerWidth = cardRef.current!.offsetWidth - 52; // card padding
      const avatarWidth = 36; // width of medium size avatar - overlap
      const maxNumAvatars = Math.floor(containerWidth / avatarWidth) - 1; // "and X more" avatar
      setAvatarGroupSize(maxNumAvatars);
    }, 0);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const findStatusColor = (status) => {
    const statusObject = statusMap.find((s) => s.value === status);
    return statusObject ? statusObject.color : "gray";
  };

  return (
    <LinkBox
      as={RouterLink}
      to={`/planning/projects/${project.id}`}
      p={5}
      bg={useColorModeValue("gray.50", "gray.700")}
      boxShadow="sm"
      borderRadius="lg"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      width="100%"
      ref={cardRef}>
      <VStack align="start" spacing={4} h="100%" justify="space-between">
        <Heading size="md">{project.title}</Heading>
        <Flex width="100%" position="relative" overflow="hidden" zIndex={0}>
          {project.users.slice(0, avatarGroupSize).map((member) => (
            <LinkBox
              marginInlineEnd={-2}
              key={member.id}
              as={RouterLink}
              to={`/users/${member.id}`}>
              <Avatar name={member.name} src={member.picture_uri} />
            </LinkBox>
          ))}
          {project.users.length > avatarGroupSize && (
            <Flex
              zIndex={1}
              width="min-content"
              height="100%"
              align="center"
              right={0}
              background={`linear-gradient(to right, var(--chakra-colors-transparent) 0, var(--chakra-colors-gray-${useColorModeValue(
                "50",
                "700"
              )}) 80%)`}
              lineHeight="shorter"
              pointerEvents="none"
              paddingInlineStart={32}
              position="absolute">
              <Text pointerEvents="all" fontSize="sm" textAlign="end">
                +{project.users.length - avatarGroupSize} more
              </Text>
            </Flex>
          )}
        </Flex>
        <Divider />
        <HStack justifyContent="space-between" w="100%">
          <Tag colorScheme={findStatusColor(project.status)}>
            {statusMap.find((s) => s.value === project.status)?.label}
          </Tag>
          <Box fontSize="md" fontWeight="bold">
            {summaryIsLoading ? <Spinner size="sm" /> : <MoneyText money={summary?.spent_amount} />}
          </Box>
        </HStack>
      </VStack>
    </LinkBox>
  );
};
