import React from "react";
import { Flex, HStack, Divider, useColorModeValue, Text } from "@chakra-ui/react";
import { MoneyText } from "../../../../MoneyText";

export const BudgetGroupStats = ({ summary }) => {
  const defaultGray = useColorModeValue("gray.600", "auto");

  return (
    <Flex direction="column" w="100%" p={2}>
      <HStack justify="space-between" w="100%">
        <Text color={defaultGray}>Funded:</Text>
        <MoneyText
          fontWeight="semibold"
          money={summary.funding_amount}
          formatOptions={{ compact: "never" }}
        />
      </HStack>
      <HStack justify="space-between" w="100%">
        <Text color={defaultGray}>Spent:</Text>
        <MoneyText
          color={"teal.500"}
          as="span"
          money={summary.spent_amount}
          formatOptions={{ compact: "never" }}
        />
      </HStack>
      <HStack justify="space-between" w="100%">
        <Text color={defaultGray}>Committed to Pay:</Text>
        <MoneyText
          as="span"
          color={"orange.400"}
          money={summary.committed_amount}
          formatOptions={{ compact: "never" }}
        />
      </HStack>
      <Divider />
      <HStack justify="space-between" w="100%">
        <Text color={defaultGray}>Available:</Text>
        <MoneyText
          fontWeight="semibold"
          color={defaultGray}
          fontSize="xl"
          money={summary.remaining_amount}
          formatOptions={{ compact: "never" }}
        />
      </HStack>
    </Flex>
  );
};
