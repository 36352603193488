import React, { useEffect, useState } from "react";
import {
  Flex,
  Tag,
  Button,
  Text,
  Heading,
  HStack,
  Tabs,
  TabList,
  Tab,
  Spinner,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import { PaginationControls, Searchbar, useCollection } from "@sciencecorp/helix-components";
import { TiArrowForward } from "react-icons/ti";
import { BudgetableTypeData, BudgetIndexItemData, useSearchBudgets } from "../../../../api/budget";
import { UnassignedBudgetsCard } from "./UnassignedBudgetsCard";
import { AddUnassignedBudgetToBudgetGroupModal } from "./AddUnassignedBudgetToBudgetGroupModal";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export const UnassignedBudgetsTable = () => {
  const [selectedBudgets, setSelectedBudgets] = useState<BudgetIndexItemData[]>([]);
  const { pagination, order, onPagination, resetPagination } = useCollection();
  const [budgetableType, setBudgetableType] = useState<BudgetableTypeData>("Team");
  const { search, debouncedSearch } = useDebouncedSearch();
  const {
    isOpen: isOpenAddUnassignedBudgetToBudgetGroupModal,
    onOpen: onOpenAddUnassignedBudgetToBudgetGroupModal,
    onClose: onCloseAddUnassignedBudgetToBudgetGroupModal,
  } = useDisclosure();

  const handleTabsChange = (index: number) => {
    if (index === 0) {
      setBudgetableType("Team");
    } else if (index === 1) {
      setBudgetableType("Project");
    } else if (index === 2) {
      setBudgetableType("CapitalEquipment");
    } else if (index === 3) {
      setBudgetableType("User");
    }
  };

  const addToSelectedBudgets = (budget: BudgetIndexItemData) => {
    const budgetExists = selectedBudgets.some((selectedBudget) => selectedBudget.id === budget.id);

    if (budgetExists) {
      setSelectedBudgets(
        selectedBudgets.filter((selectedBudget) => selectedBudget.id !== budget.id)
      );
    } else {
      setSelectedBudgets([...selectedBudgets, budget]);
    }
  };

  useEffect(() => {
    resetPagination();
  }, [budgetableType]);

  const { data: budgetData, isLoading } = useSearchBudgets({
    term: search || "*",
    include_summaries: false,
    pagination,
    order,
    filters: {
      approved: true,
      budgetable_type: budgetableType,
      budget_group_id: null,
    },
  });

  return (
    <>
      <Flex
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={6}
        gap={8}
        direction="column">
        <HStack w="100%">
          <Heading size="md">Unassigned Budgets</Heading>
          <Tag>{budgetData?.pagination.total}</Tag>
        </HStack>
        <Tabs colorScheme="teal" onChange={handleTabsChange}>
          <TabList
            whiteSpace={"nowrap"}
            overflowX={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}>
            <Tab>Teams</Tab>
            <Tab>Projects</Tab>
            <Tab>Capital Equipments</Tab>
            <Tab>User Budget</Tab>
          </TabList>
        </Tabs>

        <Flex
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          p={4}
          gap={4}
          direction={["column", "row"]}
          justifyContent={"space-between"}
          alignItems={["start", "center"]}>
          <Flex direction="row" alignItems={"center"} justify={["space-between", "start"]} w="100%">
            {selectedBudgets.length === 0 ? (
              <Text marginRight={"2"} w="max-content">
                Select an item
              </Text>
            ) : (
              <Text marginRight={"2"}>{selectedBudgets.length} Items Selected</Text>
            )}
            <Button
              size={"sm"}
              isDisabled={!selectedBudgets.length}
              onClick={onOpenAddUnassignedBudgetToBudgetGroupModal}>
              <TiArrowForward />
              Assign to Group
            </Button>
          </Flex>
          <Searchbar
            onChange={(value) => {
              debouncedSearch(value);
            }}
            onSelect={() => {}}
            width={["100%", "40%"]}
          />
        </Flex>
        <Grid templateColumns="repeat(auto-fit, minmax(var(--chakra-space-64), 1fr))" gap={6}>
          {!isLoading && budgetData ? (
            budgetData.results.map((budget) => (
              <GridItem
                key={budget.id}
                onClick={() => {
                  addToSelectedBudgets(budget);
                }}>
                <UnassignedBudgetsCard
                  budget={budget}
                  selectedBudget={selectedBudgets.some(
                    (selectedBudget) => selectedBudget.id === budget.id
                  )}
                />
              </GridItem>
            ))
          ) : (
            <Spinner />
          )}
        </Grid>
        <PaginationControls
          pagination={budgetData?.pagination || pagination}
          onPagination={onPagination}
        />
      </Flex>
      <AddUnassignedBudgetToBudgetGroupModal
        isOpen={isOpenAddUnassignedBudgetToBudgetGroupModal}
        onClose={onCloseAddUnassignedBudgetToBudgetGroupModal}
        groups={selectedBudgets}
        setSelectedBudgets={setSelectedBudgets}
      />
    </>
  );
};
