import { default as React } from "react";
import {
  VStack,
  Box,
  IconButton,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Alert,
  AlertIcon,
  Divider,
  Spinner,
  Center,
  Flex,
} from "@chakra-ui/react";
import { ConfirmationButton, Header, SplitPage } from "@sciencecorp/helix-components";
import { DeleteIcon } from "@chakra-ui/icons";
import { useParams, useNavigate } from "react-router";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { ReimbursementAttributesTable } from "./ReimbursementAttributesTable";
import {
  deleteReimbursement,
  useGetReimbursement,
  useGetReimbursementLineItems,
  useUpdateReimbursement,
  invalidateReimbursement,
  useGetReimbursementTimelineEvents,
  useApproveReimbursement,
  useDeclineReimbursement,
} from "../../../../api/reimbursement";
import { ReimbursementInstructionsTable } from "./ReimbursementInstructionsTable";
import { EditReimbursementLineItemList } from "./ReimbursementLineItemList";
import { LuSend } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DeleteModal } from "../../../Hiring/components/DeleteModal";
import { TimelineTable } from "../../../shared/TimelineTable";
import { ApprovalFlowTable } from "../../../shared/ApprovalFlowTable";
import { reimbursementStatusColor } from "./utils";
import { DateTime } from "luxon";

export const ReimbursementDetailsPage = () => {
  const { id } = useParams();
  if (!id) return null;

  const currentUser = useCurrentUserQuery();
  const isPurchasingAdmin = userHasRole(currentUser, "purchasing_admin");
  const navigate = useNavigate();
  const toast = useToast();

  const { data: reimbursement } = useGetReimbursement(+id);
  const { mutate: updateReimbursement, isLoading: isUpdateLoading } = useUpdateReimbursement(+id);
  const eventsQuery = useGetReimbursementTimelineEvents(+id);

  const lineItemsQuery = useGetReimbursementLineItems(reimbursement?.id ?? null);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

  const submitButtonIsDisabled = () => {
    if (reimbursement?.approval_status !== "draft") return true;
    if (isUpdateLoading) return true;
    if (lineItemsQuery.data?.length === 0) return true;
    if (lineItemsQuery.data?.some((li) => li.uploaded_files.length === 0)) return true;
    return false;
  };

  const { mutateAsync: approveReimbursement, isLoading: isLoadingApprove } =
    useApproveReimbursement();
  const { mutateAsync: declineReimbursement, isLoading: isLoadingDecline } =
    useDeclineReimbursement();

  const editable =
    currentUser.data?.id === reimbursement?.user?.id && reimbursement?.approval_status === "draft";

  return (
    <>
      <Header
        title={`Reimbursement #${id}`}
        crumbs={[{ label: "Reimbursement", url: "/services/reimbursements" }]}
        crumbsColor="teal.500"
        badge={{
          label: `${reimbursement?.approval_status}`,
          colorScheme: reimbursementStatusColor(reimbursement?.approval_status ?? ""),
        }}
        actions={[
          <>
            {editable && (
              <>
                <ConfirmationButton
                  label="Submit Request"
                  confirmationButtonLabel="Yes"
                  variant="Button"
                  size="md"
                  colorScheme="teal"
                  isDisabled={submitButtonIsDisabled()}
                  aria-label="submit reimbursement request"
                  leftIcon={<LuSend />}
                  children="Are you ready to submit this request for approval?"
                  onConfirm={() => {
                    if (reimbursement && currentUser.data) {
                      updateReimbursement(
                        {
                          id: reimbursement.id,
                          reimbursement_type: reimbursement.reimbursement_type,
                          submitted_at: DateTime.now(),
                        },
                        {
                          onSuccess: () => {
                            toast({
                              title: "Request successfully created.",
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                            });
                            onDeleteClose();
                            navigate("/services/reimbursements", {
                              state: { needsRefresh: true },
                            });
                          },
                        }
                      );
                    }
                  }}
                />
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        as={IconButton}
                        icon={<BsThreeDotsVertical />}
                        size="md"
                        aria-label="More actions"
                        isActive={isOpen}
                      />
                      <MenuList zIndex={"2"}>
                        <MenuItem color="red.600" onClick={onDeleteOpen}>
                          <DeleteIcon marginRight="4" />
                          Delete Request
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </>
            )}
          </>,
        ]}
      />

      <DeleteModal
        title="Delete Request"
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={() => {
          deleteReimbursement(+id);
          navigate("/services/reimbursements");
          toast({
            title: "Success!",
            description: "You deleted a reimbursement request.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }}
      />
      {reimbursement?.approval_status === "declined" && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          This reimbursement has been declined.
        </Alert>
      )}
      {!reimbursement?.reimbursement_within_budget && reimbursement?.spending_authority && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          This reimbursement exceeds the remaining funding for the selected spending authority.
        </Alert>
      )}
      <SplitPage
        breakpoint="lg"
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        sidebar={
          <VStack spacing={3} align="start" w="100%">
            <Box w="100%">
              <ReimbursementInstructionsTable />
              {reimbursement && (
                <>
                  <ReimbursementAttributesTable
                    reimbursement={reimbursement}
                    isPurchasingAdmin={isPurchasingAdmin}
                    currentUser={currentUser?.data}
                  />
                </>
              )}
            </Box>
            {reimbursement && currentUser.isSuccess && (
              <ApprovalFlowTable
                approvable={reimbursement}
                currentUser={currentUser.data}
                approve={approveReimbursement}
                decline={declineReimbursement}
                isApproveLoading={isLoadingApprove}
                isDeclineLoading={isLoadingDecline}
                showSubmission={false}
              />
            )}
          </VStack>
        }
        main={
          <>
            <Flex direction="column" align="start" gap={3}>
              <EditReimbursementLineItemList reimbursement={reimbursement} />
              <Divider />
              {eventsQuery.isSuccess && reimbursement ? (
                <TimelineTable
                  timelineable_id={reimbursement.id}
                  timelineable_type={"Reimbursement"}
                  events={eventsQuery.data}
                  onComment={invalidateReimbursement(reimbursement.id, "events")}
                  disableCommentBox={false}
                />
              ) : (
                <Center>
                  <Spinner />
                </Center>
              )}
            </Flex>
          </>
        }
      />
    </>
  );
};
