import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Text,
  useColorModeValue,
  Heading,
  Button,
  Stack,
  HStack,
  StatGroup,
  StatLabel,
  StatNumber,
  Stat,
  Popover,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  IconButton,
  PopoverBody,
  VStack,
  Link,
  Avatar,
  Tooltip,
  Divider,
  Input,
  useToast,
} from "@chakra-ui/react";
import {
  CollectionTable,
  ConfirmationButton,
  EditableNumber,
  EditableSelect,
  EmptyState,
} from "@sciencecorp/helix-components";
import { DeleteIcon, SmallAddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  useUpdateBudgetHeadcount,
  useDeleteBudgetHeadcountUsers,
  BudgetHeadcountShowData,
  useDeleteBudgetHeadcount,
  useCreateBudgetHeadcountUsers,
  useSearchBudgetHeadcountQuery,
  useUpdateBudgetHeadcountUsers,
} from "../../api/budget_headcounts";
import { Link as RouterLink } from "react-router-dom";
import { UserServiceRequestPill } from "../Requests/utils";
import {
  AddBudgetHeadcountModal,
  TeamMemberOption,
} from "../Teams/Team/components/AddTeamHeadcountModal";
import { useBudgetHeadcount } from "../../api/budget";
import { UserMinimalData } from "../../api/user";
import { useArchetypeOptions } from "../../api/options";

export const priorityOptions = [
  { label: "M - Mandatory, Minimal Needs", value: "m" },
  { label: "O1 - Optional 1, Prioritized Need", value: "o1" },
  { label: "O2 - Optional 2, Priority After O1", value: "o2" },
  { label: "O3 - Optional 3, Priority After O2", value: "o3" },
  { label: "H - Hired", value: "h" },
];

export const shortenedPriorityOptions = [
  { label: "M", value: "m" },
  { label: "O1", value: "o1" },
  { label: "O2", value: "o2" },
  { label: "O3", value: "o3" },
  { label: "H", value: "h" },
];

const getTeamMemberOptions = (
  allMemberships: UserMinimalData[],
  headcountUsers: { id: number; user: UserMinimalData }[] = []
): TeamMemberOption[] => {
  const headcountUserIds = new Set(headcountUsers.map((user) => user.user.id));
  return allMemberships.reduce((acc: TeamMemberOption[], user) => {
    if (!acc.some((item) => item.value === user.id) && !headcountUserIds.has(user.id)) {
      acc.push({
        label: user.name,
        value: user.id,
        user,
      });
    }
    return acc;
  }, []);
};

type TeamData = {
  id: number;
  all_sub_team_ids: number[];
};

type HeadcountProps = {
  budgetId: number;
  users: UserMinimalData[];
  editable: boolean;
  location?: string;
  team?: TeamData;
  onSuccessCallback: (id?: number) => () => Promise<void>;
};

export const Headcount = ({
  budgetId,
  team,
  users,
  editable,
  location,
  onSuccessCallback,
}: HeadcountProps) => {
  const archetypeOptions = useArchetypeOptions();
  const [filteredUsers, setFilteredUsers] = useState<UserMinimalData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { mutate: updateBudgetHeadcountUser, isLoading } = useUpdateBudgetHeadcountUsers(() =>
    onSuccessCallback()
  );

  const { data, isLoading: isLoadingBudgetHeadcount } = team
    ? useSearchBudgetHeadcountQuery({
        term: "*",
        team_ids: [team.id, ...team.all_sub_team_ids],
        pagination: { per_page: -1 },
      })
    : useBudgetHeadcount(budgetId);

  const budgetHeadcountUsers =
    data?.results
      .filter((ele) => ele.team?.id === team?.id)
      .map((headcount) => headcount.budget_headcount_users)
      .flat() || [];

  const budgetLead = useMemo(
    () => budgetHeadcountUsers?.find((user) => user.is_lead),
    [budgetHeadcountUsers]
  );

  const { mutate: updateTeamHeadcount } = useUpdateBudgetHeadcount(() => onSuccessCallback());
  const { mutate: createBudgetHeadcountUser, isLoading: isLoadingCreateBudgetHeadcountUser } =
    useCreateBudgetHeadcountUsers(() => onSuccessCallback());
  const { mutate: deleteBudgetHeadcountUser, isLoading: isLoadingDeleteBudgetHeadountUser } =
    useDeleteBudgetHeadcountUsers(() => onSuccessCallback());
  const { mutate: deleteBudgetHeadcount } = useDeleteBudgetHeadcount(() => onSuccessCallback());

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const toast = useToast();

  const columns = useMemo(
    () => [
      {
        label: "Role",
        render: (teamHeadcount: BudgetHeadcountShowData) => (
          <EditableSelect
            disabled={!editable}
            options={archetypeOptions}
            selectedValue={teamHeadcount.archetype_id}
            onSubmit={(value) => {
              if (value) updateTeamHeadcount({ id: teamHeadcount.id, archetype_id: Number(value) });
            }}
            size="sm"
          />
        ),
      },
      {
        label: "Team",
        render: (teamHeadcount: BudgetHeadcountShowData) => teamHeadcount.team?.name,
      },
      {
        label: "Priority",
        render: (teamHeadcount: BudgetHeadcountShowData) => (
          <EditableSelect
            disabled={!editable}
            options={shortenedPriorityOptions}
            selectedValue={teamHeadcount.priority}
            onSubmit={(value) => {
              if (value) updateTeamHeadcount({ id: teamHeadcount.id, priority: value.toString() });
            }}
          />
        ),
      },
      {
        label: "Vacancies",
        render: (teamHeadcount: BudgetHeadcountShowData) => teamHeadcount.vacancies,
      },
      {
        label: "Hired",
        render: (budgetHeadcount: BudgetHeadcountShowData) => {
          const TeamMemberOptions = getTeamMemberOptions(
            filteredUsers,
            budgetHeadcount.budget_headcount_users
          );
          return (
            <Flex direction="row" flexWrap="wrap" gap={2} maxW={48}>
              {budgetHeadcount.budget_headcount_users.map((headcount_user) => (
                <HStack spacing={0} key={headcount_user.id}>
                  <Tooltip label={headcount_user.user.name} closeDelay={500}>
                    <Avatar
                      size="xs"
                      name={headcount_user.user.name}
                      src={headcount_user.user.picture_uri}
                    />
                  </Tooltip>
                  <IconButton
                    colorScheme="red"
                    variant="ghost"
                    size="xs"
                    isDisabled={!editable}
                    aria-label="delete user from headcount"
                    icon={<SmallCloseIcon />}
                    onClick={() => deleteBudgetHeadcountUser(headcount_user.id)}
                  />
                </HStack>
              ))}
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <IconButton
                    isDisabled={budgetHeadcount.vacancies === 0 || !editable}
                    p={0}
                    bg="transparent"
                    aria-label="add-link"
                    size="xs"
                    icon={<SmallAddIcon />}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverCloseButton />
                  <PopoverBody maxH={52} overflowY="scroll">
                    <VStack align="start">
                      <Text fontWeight="semibold">Add Employee to Headcount</Text>
                      <Input
                        placeholder="Search for a member..."
                        isDisabled={budgetHeadcount.vacancies === 0 || !editable}
                        size="sm"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setFilteredUsers(
                            users.filter((user) =>
                              user.name.toLowerCase().includes(e.target.value.toLowerCase())
                            )
                          );
                        }}
                      />
                      {TeamMemberOptions.map((option: TeamMemberOption) => (
                        <Button
                          key={`${option.user.id} ${option.user.name} ${budgetHeadcount.archetype_id}`}
                          cursor="pointer"
                          isDisabled={budgetHeadcount.vacancies === 0 || !editable}
                          isLoading={
                            isLoadingCreateBudgetHeadcountUser || isLoadingDeleteBudgetHeadountUser
                          }
                          justifyContent="start"
                          bg="transparent"
                          width="100%"
                          onClick={() => {
                            createBudgetHeadcountUser(
                              {
                                user_ids: [option.user.id],
                                budget_headcount_id: budgetHeadcount.id,
                              },
                              {
                                onSuccess: () => {
                                  setSearchTerm("");
                                  setFilteredUsers(users);
                                },
                                onError: (e) => {
                                  console.error(e);
                                  toast({
                                    title: "Error Adding User",
                                    description: `Failed to add ${option.user.name}: User cannot exist on more than 2 teams`,
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true,
                                  });
                                },
                              }
                            );
                          }}>
                          <UserServiceRequestPill user={option.user} />
                        </Button>
                      ))}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          );
        },
      },
      {
        label: "Total",
        render: (teamHeadcount: BudgetHeadcountShowData) => (
          <EditableNumber
            disabled={!editable}
            defaultValue={teamHeadcount.total_people}
            onSubmit={(value) => {
              if (value) updateTeamHeadcount({ id: teamHeadcount.id, total_people: Number(value) });
            }}
          />
        ),
      },
      {
        label: "",
        render: (teamHeadcount: BudgetHeadcountShowData) => (
          <ConfirmationButton
            label="Delete Budget Headcount"
            confirmationButtonLabel="Delete"
            variant="IconButton"
            isLoading={isLoadingDeleteBudgetHeadountUser || isLoadingCreateBudgetHeadcountUser}
            size="xs"
            colorScheme="gray"
            aria-label="delete team headcount"
            isDisabled={!editable}
            icon={<DeleteIcon />}
            children="Are you sure you want to delete this budget headcount? This action cannot be undone."
            onConfirm={() => deleteBudgetHeadcount(teamHeadcount.id)}
          />
        ),
      },
    ],
    [data, archetypeOptions]
  );

  return (
    <Flex
      direction="column"
      p={6}
      gap={4}
      border={location !== "subteam" ? "1px" : "none"}
      borderColor="chakra-border-color"
      borderRadius="md">
      {location !== "subteam" && (
        <>
          <Stack direction="row" justify="space-between" align="center">
            <Flex direction="column" gap={1}>
              <Heading size="md"> Headcount</Heading>
              {location === "team" && (
                <HStack>
                  <Text fontSize="sm">Lead: </Text>
                  <EditableSelect
                    isLoading={isLoading}
                    disabled={!editable}
                    persistentEdit={true}
                    preview={
                      budgetLead ? (
                        <Link as={RouterLink} to={`/users/${budgetLead?.user.id}`}>
                          <HStack>
                            <Avatar
                              size="xs"
                              name={budgetLead?.user.name}
                              src={budgetLead?.user.picture_uri}
                            />
                            <Text fontSize="sm">{budgetLead?.user.name}</Text>
                          </HStack>
                        </Link>
                      ) : (
                        <Text as="i" opacity="0.7">
                          Select Team Lead
                        </Text>
                      )
                    }
                    options={[
                      { label: "No Lead", value: "-1" },
                      ...(budgetHeadcountUsers?.map((user) => ({
                        label: user.user.name,
                        value: user.id,
                      })) || []),
                    ]}
                    onSubmit={(headcountUserId) => {
                      if (headcountUserId !== (budgetLead?.id || "-1")) {
                        if (budgetLead) {
                          updateBudgetHeadcountUser({ id: budgetLead.id, is_lead: false });
                        }
                        if (headcountUserId !== "-1") {
                          updateBudgetHeadcountUser({ id: Number(headcountUserId), is_lead: true });
                        }
                      }
                    }}
                  />
                </HStack>
              )}
            </Flex>

            <AddBudgetHeadcountModal
              budgetId={budgetId!}
              userOptions={getTeamMemberOptions(users)}
              isHrAdmin={editable}
              onSuccessCallback={onSuccessCallback}
            />
          </Stack>
          <Divider />

          <HStack
            p={6}
            bg={useColorModeValue("gray.50", "gray.700")}
            width="100%"
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md">
            <StatGroup width="100%">
              <Stat>
                <StatLabel>Total Headcount</StatLabel>
                <StatNumber>{data?.total_headcount || 0}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Vacancies</StatLabel>
                <StatNumber>{data?.vacancies || 0}</StatNumber>
              </Stat>
            </StatGroup>
          </HStack>
        </>
      )}
      {data?.results.length ? (
        <CollectionTable columns={columns} items={data?.results || []} />
      ) : (
        <EmptyState
          size="xs"
          title="No roles yet. Create a team budget and add a role to get started."
        />
      )}
    </Flex>
  );
};
