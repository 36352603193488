import React from "react";
import { VStack, Heading, Text, useColorModeValue, useToken } from "@chakra-ui/react";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTheme, VictoryLabel } from "victory";

export const BarChart = ({ data, barLabel, axisYLabel, tickFormat }) => {
  const color = barLabel[0] === 1 ? "teal" : "#718096";
  const fillText = localStorage.getItem("chakra-ui-color-mode") === "dark" ? "white" : "black";

  const gray200 = useColorModeValue("gray.200", "gray.50");
  const axisColor = useToken("colors", gray200);

  return (
    <VictoryChart theme={VictoryTheme.grayscale} domainPadding={{ x: 25 }} width={300} height={190}>
      <VictoryAxis
        label="Scores"
        style={{
          axisLabel: { padding: 30, fontSize: 6, fill: fillText, fontFamily: "inherit" },
          grid: { stroke: "transparent" },
          tickLabels: { fontSize: 5, fill: fillText, fontFamily: "inherit" },
          axis: { stroke: axisColor, strokeWidth: 1 },
        }}
        tickFormat={barLabel}
      />
      <VictoryAxis
        dependentAxis
        label={axisYLabel}
        style={{
          grid: { stroke: axisColor, strokeWidth: 1 },
          axis: { stroke: "transparent" },
          axisLabel: { padding: 25, fontSize: 6, fill: fillText, fontFamily: "inherit" },
          tickLabels: { fontSize: 5, fill: fillText, fontFamily: "inherit" },
        }}
        tickFormat={tickFormat}
      />
      <VictoryBar
        x="x"
        y="y"
        barWidth={30}
        labels={({ datum }) => {
          if (datum.y) return `${datum.y.toFixed(2)}`;
          else return "";
        }}
        style={{
          data: { fill: color },
          labels: { fill: "white", fontSize: 9, fontFamily: "inherit" },
        }}
        labelComponent={<VictoryLabel dy={12} />}
        data={data}
      />
    </VictoryChart>
  );
};

export const BarChartContainer = ({ data, title, question, barLabels, axisYLabel, tickFormat }) => (
  <VStack
    align="left"
    width={["100%", "100%", "50%"]}
    p={8}
    border="1px"
    borderColor={useColorModeValue("gray.200", "gray.600")}
    borderRadius="md"
    bg={useColorModeValue("white", "gray.700")}>
    <Heading size="md">{title}</Heading>
    <Text>{question}</Text>
    <BarChart data={data} barLabel={barLabels} axisYLabel={axisYLabel} tickFormat={tickFormat} />
    {title === "Data Roundup" && (
      <Text fontSize="sm" align="center">
        Candidate rating
      </Text>
    )}
  </VStack>
);
