import React, { useMemo, useState } from "react";
import { CollectionTable } from "@sciencecorp/helix-components";
import { useGetEmployeeOnboardingFeedback } from "../../../../api/hr";
import { Spinner, Button, useDisclosure, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import { OnboardingFeedbackModal } from "../../../Reviews/components/OnboardingFeedbackModal";

export const OnboardingFeedbackTable = ({ employeeId }) => {
  const { data: onboardingFeedback, isLoading } = useGetEmployeeOnboardingFeedback(employeeId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [feedback, setFeedback] = useState(null);
  const columns = useMemo(
    () => [
      {
        label: "Generated",
        render: (onboardingFeedback) =>
          DateTime.fromISO(onboardingFeedback.created_at).toFormat("LLL dd yyyy"),
      },
      {
        label: "From",
        render: (onboardingFeedback) => `${onboardingFeedback.occasion} Review`,
      },
      {
        label: "",
        render: (onboardingFeedback) => (
          <Button
            size="sm"
            p={[0, 2]}
            onClick={() => {
              setFeedback(onboardingFeedback);
              onOpen();
            }}
            color="teal"
            bg="transparent">
            View
          </Button>
        ),
      },
    ],
    [onboardingFeedback]
  );

  return isLoading ? (
    <Spinner size="lg" />
  ) : (
    <>
      {onboardingFeedback?.length ? (
        <CollectionTable items={onboardingFeedback || []} columns={columns} />
      ) : (
        <VStack
          width="100%"
          p={4}
          justify="center"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}
          opacity="0.7"
          spacing={5}>
          <Search2Icon boxSize={6} />
          <VStack spacing={0}>
            <Text fontWeight="semibold" fontSize="sm">
              It’s empty here.
            </Text>
            <Text fontWeight="medium" fontSize="xs">
              There are no onboarding review feedbacks for this employee.
            </Text>
          </VStack>
        </VStack>
      )}

      {feedback && (
        <OnboardingFeedbackModal isOpen={isOpen} onClose={onClose} encryptedFeedback={feedback} />
      )}
    </>
  );
};
