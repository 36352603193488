import { VStack, Box, Button, Skeleton } from "@chakra-ui/react";
import {
  EmptyState,
  Header,
  PaginationControls,
  useCollection,
} from "@sciencecorp/helix-components";
import React from "react";
import { useCreateMeetingNotes, useSearchMeeting } from "../../api/meeting_notes";
import { MeetingItemInfo } from "./MeetingItemInfo";
import { useCurrentUserQuery } from "../../api/user";
import { useNavigate } from "react-router";
import { AddIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";

export const ListMeetingNotes = () => {
  const { data: current_user } = useCurrentUserQuery();
  const navigate = useNavigate();

  const { onPagination, order, pagination, filters, resetPagination } = useCollection({
    pagination: { per_page: 100 },
    order: { created_at: "desc" },
  });

  const { data, isLoading } = useSearchMeeting({
    term: "*",
    aggs: [],
    filters,
    pagination,
    order,
  });
  const { mutateAsync: createMeetingNotes, isLoading: isCreateLoading } = useCreateMeetingNotes();

  const handleCreate = () => {
    if (!current_user) return;

    createMeetingNotes({
      title: "Meeting",
      user_id: current_user.id,
      meeting_time: DateTime.now(),
    })
      .then((data) => {
        navigate(`/meeting_notes/${data.id}`);
      })
      .catch((error) => {
        console.error("Error creating meeting notes:", error);
      });
  };

  return (
    <>
      <Header
        title={"Your Meeting Notes"}
        actions={[
          <Button colorScheme="teal" onClick={handleCreate} isLoading={isCreateLoading}>
            <AddIcon marginRight={"2"} boxSize={"3"} />
            Create Note
          </Button>,
        ]}
      />
      <VStack
        width={"100%"}
        border={"1px"}
        p={"4"}
        borderRadius={"md"}
        borderColor={"chakra-border-color"}>
        <Skeleton isLoaded={!isLoading} width={"100%"}>
          {data && data.results.length > 0 ? (
            data.results.map((note) => <MeetingItemInfo meeting={note} key={note.id} />)
          ) : (
            <EmptyState />
          )}
        </Skeleton>
        <Box width={"100%"}>
          {data && (
            <PaginationControls
              pagination={data.pagination || pagination}
              onPagination={onPagination}
            />
          )}
        </Box>
      </VStack>
    </>
  );
};
