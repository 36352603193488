import { z } from "zod";
import { userMinimalSchema } from "./user";
import { api } from ".";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { zodParse } from "./zodParse";

export const budgetHeadcountPanelistSchema = z.object({
  id: z.number(),
  budget_headcount_id: z.number(),
  user_id: z.number(),
  hiring_role: z.string(),
});

export const budgetHeadcountPanelistShowSchema = budgetHeadcountPanelistSchema
  .omit({ user_id: true, budget_headcount_id: true })
  .extend({
    user: z.lazy(() => userMinimalSchema),
  });

export type BudgetHeadcountPanelistData = z.infer<typeof budgetHeadcountPanelistSchema>;
export type BudgetHeadcountPanelistShowData = z.infer<typeof budgetHeadcountPanelistShowSchema>;
export type BudgetHeadcountPanelistCreateParams = Pick<
  BudgetHeadcountPanelistData,
  "user_id" | "hiring_role" | "budget_headcount_id"
>;

export const BUDGET_HEADCOUNT_PANELISTS_BASE_URL = "budget_headcount_panelists";

export const searchBudgetHeadcountPanelists = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [BUDGET_HEADCOUNT_PANELISTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });

  return zodParse(createSearchResponseSchema(budgetHeadcountPanelistShowSchema), result.data);
};

export const createBudgetHeadcountPanelist = async (
  params: BudgetHeadcountPanelistCreateParams
) => {
  try {
    const result = await api.post(`${BUDGET_HEADCOUNT_PANELISTS_BASE_URL}`, {
      budget_headcount_panelist: params,
    });

    return result.data;
  } catch (e) {
    console.error("Error creating budget headcount panelist:", e);
    throw e;
  }
};

export const updateBudgetHeadcountPanelist = async (
  budget_headcount_panelist: Partial<BudgetHeadcountPanelistData>
) => {
  try {
    const result = await api.put(
      `${BUDGET_HEADCOUNT_PANELISTS_BASE_URL}/${budget_headcount_panelist.id}`,
      {
        budget_headcount_panelist,
      }
    );

    return zodParse(budgetHeadcountPanelistShowSchema, result.data);
  } catch (e) {
    console.error("Error updating budget headcount panelist:", e);
    throw e;
  }
};

export const deleteBudgetHeadcountPanelist = async (id: number) => {
  try {
    await api.delete(`${BUDGET_HEADCOUNT_PANELISTS_BASE_URL}/${id}`);
  } catch (e) {
    console.error("Error deleting budget headcount panelist:", e);
    throw e;
  }
};

export const invalidateBudgetHeadcountPanelists = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([BUDGET_HEADCOUNT_PANELISTS_BASE_URL]);
};

export const useSearchBudgetHeadcountPanelist = (params: SearchParams) => {
  return useQuery({
    queryKey: [BUDGET_HEADCOUNT_PANELISTS_BASE_URL, params],
    queryFn: () => searchBudgetHeadcountPanelists(params),
  });
};

export const useCreateBudgetHeadcountPanelist = () => {
  return useMutation({
    mutationFn: createBudgetHeadcountPanelist,
    onSuccess: invalidateBudgetHeadcountPanelists(),
  });
};

export const useUpdateBudgetHeadcountPanelist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateBudgetHeadcountPanelist,
    onSuccess: invalidateBudgetHeadcountPanelists(),
  });
};

export const useDeleteBudgetHeadcountPanelist = () => {
  return useMutation({
    mutationFn: deleteBudgetHeadcountPanelist,
    onSuccess: invalidateBudgetHeadcountPanelists(),
  });
};
