import { z } from "zod";

/**
 * Parses the given data using the provided Zod schema.
 * This exists because zod has stopped reporting errors in the
 * console in a format that is helpful in any sense of the word.
 *
 * @template T - The Zod schema type.
 * @param {T} schema - The Zod schema to use for parsing.
 * @param {any} data - The data to be parsed.
 * @returns {z.infer<T>} - The parsed result.
 * @throws {Error} - If the data fails to parse according to the schema.
 */
export function zodParse<T extends z.ZodTypeAny>(schema: T, data: any): z.infer<T> {
  const parsedResult = schema.safeParse(data);
  if (parsedResult.success) {
    return parsedResult.data;
  } else {
    console.error("Unable to parse data using zod", {
      data,
      errors: parsedResult.error.errors,
      schema_description: schema.description,
      schema_shape: (schema as any).shape,
    });
    throw new Error(parsedResult.error.errors.map((e) => e.message).join(", "));
  }
}
//
