import { z } from "zod";
import { TreeOptionValue, TreeSelectOption } from "../components/shared/TreeSelect";
import { MinimalSpendingAuthority, minimalSpendingAuthoritySchema } from "./spending_authority";

// From the API
export const nonRecursiveSpendingAuthorityTreeSchema = z.object({
  spending_authorities: z.array(minimalSpendingAuthoritySchema),
  id: z.number(),
  type: z.enum(["Service", "Project", "Team", "User", "CapitalEquipment"]),
  name: z.string(),
});

export const spendingAuthorityTreeSchema = nonRecursiveSpendingAuthorityTreeSchema.extend({
  children: z.array(z.lazy(() => spendingAuthorityTreeSchema)).optional(),
});

export type SpendingAuthorityTree = z.infer<typeof nonRecursiveSpendingAuthorityTreeSchema> & {
  children?: SpendingAuthorityTree[];
};

export type SpendingAuthorityOption = TreeSelectOption<SpendingAuthorityKey>;
export type SpendingAuthorityValue = TreeOptionValue<SpendingAuthorityKey>;

export type SpendingAuthorityKey = {
  spendingAuthority: { name: string; id: number; type: MinimalSpendingAuthority["type"] } | null;
  id: number;
  type: SpendingAuthorityTree["type"];
  name: string;
};

export const buildSpendingAuthorityOptions = (
  tree: SpendingAuthorityTree
): SpendingAuthorityOption => {
  const value: SpendingAuthorityValue = {
    spendingAuthority: null,
    id: tree.id,
    type: tree.type,
    name: tree.name,
  };
  return {
    label: tree.name,
    value: value,
    type: tree.type,
    children: [
      ...(tree.children?.map((subTree) => buildSpendingAuthorityOptions(subTree)) || []),
      ...tree.spending_authorities.map((item) => ({
        name: item.name,
        label: item.name,
        value: {
          id: tree.id,
          type: tree.type,
          name: tree.name,
          label: tree.name,
          spendingAuthority: { name: item.name, label: item.name, id: item.id, type: item.type },
        },
      })),
    ],
  };
};
