import React from "react";
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Select,
  Spinner,
  Tag,
  Text,
  Tooltip,
  VStack,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { AttributesTable } from "@sciencecorp/helix-components";
import { useUpdateUser, toggleFeatureForUser, userHasFeatureFlag } from "../../api/user";
import { getPublicKeyFingerprint } from "../util";
import PinModal from "./pinModal";
import ApiKeys from "./apiKeys";
import { useFeatureFlagsQuery } from "../../api/feature_flag";

export const Settings = ({ userQuery }) => {
  const featureFlagQuery = useFeatureFlagsQuery();
  const { mutate: updateUser } = useUpdateUser(userQuery.data?.id);

  const toast = useToast();

  if (userQuery.isLoading) {
    return <Spinner />;
  }
  if (userQuery.isError) {
    return <Text>Error loading user</Text>;
  }

  const keyFingerprintAttribute = userQuery.data.public_key
    ? getPublicKeyFingerprint(userQuery.data.public_key)
    : "No key";

  const user = userQuery.data;

  return (
    <Stack direction={{ base: "column", xl: "row" }}>
      <Flex
        p={6}
        gap={6}
        flexDirection="column"
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Heading size="md">Preferences</Heading>
        <AttributesTable
          attributes={[
            {
              label: "Homepage",
              value: (
                <Select
                  onChange={async (event) => {
                    await updateUser({
                      id: user?.id,
                      show_on_homepage: event.target.value === "show-on-homepage" ? true : false,
                    });
                    userQuery.refetch();
                    toast({ title: "Homepage Preference Updated" });
                  }}
                  defaultValue={
                    user.show_on_homepage ? "show-on-homepage" : "do-not-show-on-homepage"
                  }>
                  <option value="show-on-homepage">
                    I would like my birthday & work anniversary to be shown on the homepage
                  </option>
                  <option value="do-not-show-on-homepage">
                    I do not want my birthday or work anniversary to be shown on the homepage
                  </option>
                </Select>
              ),
            },
            {
              label: "Desk",
              value: (
                <Select
                  onChange={async (event) => {
                    const updatedUser = { ...user, desk_preference: event.target.value };
                    await updateUser(updatedUser);
                    userQuery.refetch();
                    toast({ title: "Desk preferences updated" });
                  }}>
                  <option
                    value="need-dedicated"
                    selected={user?.desk_preference == "need-dedicated"}>
                    I need a dedicated desk in the office
                  </option>
                  <option
                    value="prefer-dedicated"
                    selected={user?.desk_preference == "prefer-dedicated"}>
                    I would like to have a dedicated desk in the office
                  </option>
                  <option value="shared" selected={user?.desk_preference == "shared"}>
                    I can use a shared desk with a dock and monitors
                  </option>
                  <option value="float" selected={user?.desk_preference == "float"}>
                    I don't need a desk, I can work from anywhere
                  </option>
                </Select>
              ),
            },
            {
              label: "Betas",
              labelVerticalAlign: "top",
              value: (
                <BetaFeatures
                  userQuery={userQuery}
                  featureFlagQuery={featureFlagQuery}
                  toast={toast}
                />
              ),
            },
          ]}
        />
      </Flex>
      <Flex
        p={6}
        gap={6}
        flexDirection="column"
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Heading size="md">Account Security</Heading>
        <Box overflowX={"scroll"}>
          <AttributesTable
            attributes={[
              {
                label: "PIN",
                value: <PinModal user={userQuery.data} onUpdate={() => userQuery.refetch()} />,
              },
              {
                label: "Key Fingerprint",
                value: keyFingerprintAttribute,
              },
              {
                label: "API keys",
                value: <ApiKeys user={userQuery.data} />,
              },
            ]}
          />
        </Box>
      </Flex>
    </Stack>
  );
};

const BetaFeatures = ({ userQuery, featureFlagQuery, toast }) => {
  return (
    <VStack align="start">
      {featureFlagQuery.data?.map((featureFlag) => {
        const hasFlag = userHasFeatureFlag(userQuery, featureFlag.name);
        return (
          <Checkbox
            key={featureFlag.id}
            isChecked={hasFlag}
            colorScheme="teal"
            onChange={async () => {
              await toggleFeatureForUser(featureFlag.name, userQuery.data!.id);
              userQuery.refetch();
            }}>
            <Tooltip label={featureFlag.description} placement="right" bg="gray.400">
              <Tag colorScheme={hasFlag ? "teal" : "orange"}>{featureFlag.name}</Tag>
            </Tooltip>
          </Checkbox>
        );
      })}
    </VStack>
  );
};
