import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";

export const useDebouncedSearch = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useCallback(
    debounce((query: string) => setSearch(query), 300),
    []
  );

  return { debouncedSearch, search };
};

export const useDebouncedOmniSearch = () => {
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const term = queryParams.get("term") || "";

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      queryParams.set("term", `${query}`);
      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });
      setSearch(query);
    }, 300),
    []
  );

  return { debouncedSearch, search, term };
};
