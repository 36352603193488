import React, { useMemo, useState } from "react";
import {
  Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tag,
  AccordionIcon,
  Box,
  Icon,
  HStack,
} from "@chakra-ui/react";
import {
  RecordLink,
  CollectionTable,
  EmptyState,
  HelixHomeLink,
  Column,
} from "@sciencecorp/helix-components";
import { MoneyText } from "../../../MoneyText";
import { DateTime } from "luxon";
import { PurchasePaymentScorecardData } from "../../../../api/purchase_payment";
import { MdOutlineStorefront } from "react-icons/md";
import { humanize, titleize } from "inflection";

export const PaymentTableItem = ({ scorecard }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const columns = useMemo(
    () =>
      [
        {
          label: "Vendor",
          weight: 1.5,
          render: (purchasePayment: PurchasePaymentScorecardData) => (
            <Box>
              <RecordLink
                maxWidth="15ch"
                type=""
                identifier={purchasePayment.vendor.name || "Unknown"}
                link={`/services/vendors/${purchasePayment.vendor.id}`}
                icon={
                  <Box ml={3} mt={1}>
                    <Icon as={MdOutlineStorefront} />
                  </Box>
                }
              />
            </Box>
          ),
        },
        {
          label: "Source of Authority",
          render: (purchasePayment: PurchasePaymentScorecardData) => (
            <Box>
              {purchasePayment.source_of_authority !== null ? (
                <RecordLink
                  maxWidth="15ch"
                  type={purchasePayment.source_of_authority.type}
                  identifier={purchasePayment.source_of_authority.name}
                  link={purchasePayment.source_of_authority.app_href}
                />
              ) : (
                "No Spending Authority assigned"
              )}
            </Box>
          ),
        },
        {
          label: "Record",
          render: (purchasePayment: PurchasePaymentScorecardData) => {
            const tab =
              purchasePayment.record.identifier === "Purchase" ? "purchasing" : "contracts";
            return (
              <Box>
                <HelixHomeLink
                  model={purchasePayment.record.identifier}
                  modelName={purchasePayment.record.identifier}
                  id={purchasePayment.record.id}
                  identifier={purchasePayment.record.id}
                  navigateTo={`/services/${tab}/${purchasePayment.record.id}`}
                />
              </Box>
            );
          },
        },
        {
          label: scorecard.month === "No Date" ? "Due Condition" : "Due Date",
          weight: 1.5,
          render: (purchasePayment: PurchasePaymentScorecardData) =>
            purchasePayment.due_date
              ? DateTime.fromISO(purchasePayment.due_date).toFormat("LLL dd yyyy")
              : purchasePayment.due_date_condition
              ? purchasePayment.due_date_condition
              : "N/A",
        },
        scorecard.month === "No Date" && {
          label: "Created At",
          render: (purchasePayment: PurchasePaymentScorecardData) =>
            DateTime.fromISO(purchasePayment.record.created_at).toFormat("LLL dd yyyy"),
        },
        {
          label: "Status",
          render: (purchasePayment: PurchasePaymentScorecardData) => {
            const status =
              purchasePayment.status === "new" ? "awaiting_payment" : purchasePayment.status;
            return (
              <Box>
                <Tag colorScheme={status === "awaiting_payment" ? "red" : "green"}>
                  {titleize(humanize(status))}
                </Tag>
              </Box>
            );
          },
        },
        {
          label: "Paid at",
          render: (purchasePayment: PurchasePaymentScorecardData) => {
            return purchasePayment.paid_at ? (
              <Text>
                {DateTime.fromISO(purchasePayment.paid_at, { zone: "utc" }).toFormat(
                  "LLL dd, yyyy"
                )}{" "}
              </Text>
            ) : (
              "-"
            );
          },
        },
        {
          label: "Amount",
          render: (purchasePayment: PurchasePaymentScorecardData) => (
            <MoneyText
              money={purchasePayment.amount}
              fontWeight="semibold"
              formatOptions={{ compact: "never" }}
            />
          ),
        },
      ].filter(Boolean) as Column[],
    [scorecard]
  );

  return (
    <AccordionItem border="none" key={scorecard.month}>
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        p={4}
        borderBottom="1px"
        borderColor="chakra-border-color"
        onClick={handleToggle}>
        <Text fontSize="sm" fontWeight="medium">
          {scorecard.month !== "No Date"
            ? DateTime.fromISO(scorecard.month).toFormat("LLLL yyyy")
            : scorecard.month}
        </Text>
        <HStack>
          <MoneyText
            fontSize="sm"
            fontWeight="semibold"
            money={scorecard.total}
            formatOptions={{ compact: "never" }}
          />
          <AccordionIcon />
        </HStack>
      </AccordionButton>
      {isOpen && (
        <AccordionPanel p={6} bg="gray.100">
          {scorecard.results.length ? (
            <Box bg="chakra-body-bg" p={4} borderRadius="md">
              <CollectionTable columns={columns} items={scorecard.results} />
            </Box>
          ) : (
            <EmptyState title="No payments scheduled for this month" />
          )}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};
