import React, { useState } from "react";
import { Grid } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { CalendarEvent } from "../../../api/calendar_event";
import { DayCell } from "./DayCell";

interface DaysGridProps {
  currentMonth: DateTime;
  events: CalendarEvent[];
  onDayClick: (date: DateTime) => void;
}

export const DaysGrid: React.FC<DaysGridProps> = ({ currentMonth, events, onDayClick }) => {
  const days: DateTime[] = [];
  const startDay = currentMonth.startOf("month");
  const endDay = currentMonth.endOf("month");

  let currentDay = startDay;
  while (currentDay <= endDay) {
    days.push(currentDay);
    currentDay = currentDay.plus({ days: 1 });
  }

  while (days[0].weekday !== 7) {
    days.unshift(days[0].minus({ days: 1 }));
  }

  return (
    <Grid templateColumns="repeat(7, 1fr)" autoRows={"1fr"}>
      {days.map((day, index) => (
        <DayCell
          key={index}
          day={day}
          currentMonth={currentMonth}
          events={events}
          onDayClick={onDayClick}
        />
      ))}
    </Grid>
  );
};

export default DaysGrid;
