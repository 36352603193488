import { useCallback } from "react";
import { debounce, DebouncedFunc } from "lodash";

export const useDebouncedUpdate = (updateFunction, delay = 300) => {
  // Create a debounced version of the update function that is passed in
  const debouncedUpdate = useCallback(
    debounce((...args) => {
      updateFunction(...args);
    }, delay),
    [updateFunction, delay]
  );

  return debouncedUpdate;
};

export type AnyFn = (...args: any[]) => any;
export type AnyDebouncedFn = DebouncedFunc<AnyFn>;
export function useDebounced<T extends AnyFn>(fn: AnyFn, deps, delay = 300): DebouncedFunc<T> {
  return useCallback(debounce(fn, delay), [...deps, delay]);
}
