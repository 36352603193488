import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { useNewMilestone } from "../../api/milestone";
import { TeamData } from "../../api/team";

export const NewMilestoneModal: React.FC<{ team: TeamData }> = ({ team }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: newMilestone } = useNewMilestone();

  const [name, setName] = React.useState("");

  return (
    <>
      <Card
        as={Button}
        w="100%"
        h="100%"
        _hover={{ background: "green.500", color: "white" }}
        textAlign="start"
        onClick={onOpen}>
        <CardBody paddingInline={2} w="100%">
          <HStack spacing={4} h="100%">
            <AddIcon />
            <Text>Add Milestone</Text>
          </HStack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Milestone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={() => {
                newMilestone({ name, team_id: team.id });
                onClose();
              }}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
