import { omniResponseSchema, OmniSearchParams } from "./schema";
import { api } from "..";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { zodParse } from "../zodParse";

export const OMINI_SEARCH_BASE_URL = "omnisearch";

export const ominiSearch = async (params?: OmniSearchParams) => {
  const path = [OMINI_SEARCH_BASE_URL, "search"];
  const response = await api.post(path.join("/"), { ...params });
  return zodParse(omniResponseSchema, response.data);
};

export const useOminiSearch = (params?: OmniSearchParams) => {
  return useQuery({
    queryKey: [OMINI_SEARCH_BASE_URL, params],
    queryFn: () => ominiSearch(params),
    refetchOnMount: true,
  });
};
