import { z } from "zod";
import { expenditureSchema } from "./expenditures";
import { approvableSchema } from "./approvals";
import { SpendingAuthorityReference } from "./spending_authority";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidatePurchases } from "./purchase";

export const SPENDING_AUTH_CHANGE_REQUEST_BASE_URL = "spending_authority_change_requests";

/** spending authority change requests */
export const spendingAuthorityChangeRequestSchema = z
  .object({
    id: z.number(),
    user_id: z.number(),
    expenditure_id: z.number(),
    expenditure_type: z.string(),
  })
  .extend(expenditureSchema.shape)
  .extend(approvableSchema.shape);

export type SpendingAuthorityChangeRequestData = z.infer<
  typeof spendingAuthorityChangeRequestSchema
>;
export type SpendingAuthorityChangeRequestCreateParams = Pick<
  SpendingAuthorityChangeRequestData,
  "user_id" | "id" | "expenditure_id" | "expenditure_type"
> &
  Partial<SpendingAuthorityReference>;

export const approveSpendingAuthorityChangeRequest = async (requestId: number) => {
  const result = await api.post(`${SPENDING_AUTH_CHANGE_REQUEST_BASE_URL}/${requestId}/approve`);
  return zodParse(spendingAuthorityChangeRequestSchema, result.data);
};

export const declineSpendingAuthorityChangeRequest = async (requestId: number) => {
  const result = await api.post(`${SPENDING_AUTH_CHANGE_REQUEST_BASE_URL}/${requestId}/decline`);
  return zodParse(spendingAuthorityChangeRequestSchema, result.data);
};

export const createSpendingAuthorityChangeRequest = async (
  spending_authority_change_request: SpendingAuthorityChangeRequestCreateParams
) => {
  const result = await api.post(SPENDING_AUTH_CHANGE_REQUEST_BASE_URL, {
    spending_authority_change_request,
  });
  return result.data.id;
};

/** hooks */

export const useCreateSpendingAuthorityRequest = (purchaseId: number) => {
  return useMutation({
    mutationFn: createSpendingAuthorityChangeRequest,
    onSuccess: invalidatePurchases(purchaseId),
  });
};

export const useApproveSpendingAuthorityChangeRequest = () => {
  return useMutation({
    mutationFn: approveSpendingAuthorityChangeRequest,
    onSuccess: invalidatePurchases(),
  });
};

export const useDeclineSpendingAuthorityChangeRequest = () => {
  return useMutation({
    mutationFn: declineSpendingAuthorityChangeRequest,
    onSuccess: invalidatePurchases(),
  });
};
