import { Divider, Flex, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { Select } from "@sciencecorp/helix-components";
import React from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useInventoryOptions } from "../../../../../api/options";
import { NewInventoryDetails } from "../../../../Inventory/components/NewInventoryDetails";
import {
  InventoryFormValues,
  RestockFormValues,
} from "../../../../Inventory/Inventories/components/RestockModalComponents/util";

type SelectInventorySourceProps = {
  control: Control<RestockFormValues, any>;
  restockFormValues: RestockFormValues;
  newInventoryFormValues: InventoryFormValues;
  newInventoryControl: Control<InventoryFormValues, any>;
  isInventoryManager: boolean;
  newInventorySetValue: UseFormSetValue<InventoryFormValues>;
};

export const SelectInventorySource = ({
  control,
  restockFormValues,
  newInventoryFormValues,
  newInventoryControl,
  newInventorySetValue,
  isInventoryManager,
}: SelectInventorySourceProps) => {
  const inventoryOptions = useInventoryOptions();
  const options = isInventoryManager
    ? [{ label: "+ Create New", value: -1 }, ...inventoryOptions]
    : inventoryOptions;

  return (
    <Flex direction="column" width="100%" gap={4} p={4}>
      <Controller
        name="inventory_id"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Which inventory would you like to restock?</FormLabel>
            <Select options={options} {...field} />
          </FormControl>
        )}
      />
      {restockFormValues.inventory_id === -1 && (
        <VStack spacing={4} w="100%">
          <Divider />

          <NewInventoryDetails
            formValues={newInventoryFormValues}
            control={newInventoryControl}
            setValue={newInventorySetValue}
          />
        </VStack>
      )}
    </Flex>
  );
};
