import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useSecureMode } from "@sciencecorp/helix-components";

export function SecureModeModal({}: any): JSX.Element {
  const [pin, setPin] = useState<string>("");
  const [smState, smDispatch] = useSecureMode();

  const pinModalState = smState.pinModal;
  const isOpen = !!pinModalState;
  const errorMessage = smState.pinModal?.errorMessage || null;
  const message = smState.pinModal?.message || null;

  const handleClose = () => {
    smDispatch({ type: "dismiss" });
  };

  const handleSubmit = () => {
    if (pin) {
      smDispatch({ type: "try-pin", pin: pin, badPinMessage: "Incorrect PIN, please try again" });
    } else {
      smDispatch({ type: "try-pin", pin: null, badPinMessage: "Please enter a PIN!" });
    }
    setPin("");
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter Secure Mode</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>{message}</FormLabel>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <HStack>
              <Input
                type="password"
                placeholder="Enter your PIN"
                value={pin}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
              <Button colorScheme="teal" onClick={handleSubmit}>
                Submit
              </Button>
            </HStack>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
