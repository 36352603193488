import React from "react";
import { Box, Divider, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Project } from "../../../api/planning/projects";
import { ProjectDurationVisualization } from "./ProjectDurationVisualization";
import { ProjectAttributesTable } from "./ProjectAttributesTable";

interface ProjectDurationVisualizationProps {
  project: Project;
}

export const ProjectDurationBar: React.FC<ProjectDurationVisualizationProps> = ({ project }) => (
  <Flex
    justifyContent={"start"}
    direction={"column"}
    w={"100%"}
    border="1px"
    borderColor={useColorModeValue("gray.200", "gray.600")}
    p={6}
    gap={4}
    borderRadius="md">
    <Text fontSize="lg" fontWeight="bold">
      Project Duration
    </Text>
    <Divider />
    <Flex direction={{ base: "column", lg: "row" }}>
      <Box
        flex="1"
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        maxWidth={"100%"}
        maxHeight={"100%"}
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        p={4}
        alignContent={"center"}>
        <ProjectAttributesTable project={project} />
      </Box>
    </Flex>
  </Flex>
);
