import { z } from "zod";
import { invalidatePurchases } from "./purchase";
import { useMutation } from "@tanstack/react-query";
import { api } from ".";
import { userMinimalSchema } from "./user";
import { ServiceRequestCreateParams } from "./service_requests";
import { purchaseRefundsSchema } from "./purchase_refunds";
import { zodParse } from "./zodParse";
import { moneySchema } from "../helpers/Money";

export const PURCHASE_RETURNS_BASE_URL = "purchase_returns";

/** purchase return line items */
export const purchaseReturnLineItemsSchema = z.object({
  id: z.number(),
  purchase_line_item_id: z.number(),
  quantity: z.number(),
});

export const purchaseReturnLineItemsShowSchema = purchaseReturnLineItemsSchema.extend({
  name: z.string(),
  amount: moneySchema,
});

/** purchase returns */
export const purchaseReturnsSchema = z.object({
  id: z.number(),
  purchase_id: z.number(),
  service_request_id: z.number().nullable(),
  status: z.string(),
  purchase_return_line_items: z.array(purchaseReturnLineItemsSchema),
  reason_for_return: z.string(),
  additional_information: z.string(),
});

export const purchaseReturnsShowSchema = purchaseReturnsSchema.extend({
  user: z.lazy(() => userMinimalSchema),
  purchase_return_line_items: z.array(purchaseReturnLineItemsShowSchema),
  purchase_refund: purchaseRefundsSchema.nullable(),
  total_returned: moneySchema,
});

export type PurchaseReturnsData = z.infer<typeof purchaseReturnsSchema>;
export type PurchaseReturnsShowData = z.infer<typeof purchaseReturnsShowSchema>;
export type PurchaseReturnLineItemsData = z.infer<typeof purchaseReturnLineItemsSchema>;
export type PurchaseReturnLineItemsShowData = z.infer<typeof purchaseReturnLineItemsShowSchema>;
export type PurchaseReturnLineItemsCreateParams = Omit<PurchaseReturnLineItemsData, "id">;

export type PurchaseReturnCreateParams = Pick<
  PurchaseReturnsData,
  "reason_for_return" | "additional_information" | "purchase_id"
> & {
  purchase_return_line_items: PurchaseReturnLineItemsCreateParams[];
};

export type PurchaseReturnUpdateParams = Partial<PurchaseReturnCreateParams> &
  Pick<PurchaseReturnsData, "id">;

export const newPurchaseReturn = async (purchase_return: PurchaseReturnCreateParams) => {
  const result = await api.post(PURCHASE_RETURNS_BASE_URL, { purchase_return });
  return result.data.id;
};

export const updatePurchaseReturn = async (
  purchase_return: Partial<PurchaseReturnUpdateParams>
) => {
  const result = await api.put(`${PURCHASE_RETURNS_BASE_URL}/${purchase_return.id}`, {
    purchase_return,
  });
  return zodParse(purchaseReturnsShowSchema, result.data);
};

export const createPurchaseReturnServiceRequest = async (
  purchase_return_id: number
): Promise<any> => {
  const result = await api.post(
    `${PURCHASE_RETURNS_BASE_URL}/${purchase_return_id}/create_service_request`,
    {}
  );

  return zodParse(purchaseReturnsShowSchema, result.data);
};

export const useNewPurchaseReturn = () => {
  return useMutation({
    mutationFn: newPurchaseReturn,
    onSuccess: invalidatePurchases(),
  });
};

export const useUpdatePurchaseReturn = () => {
  return useMutation({
    mutationFn: updatePurchaseReturn,
    onSuccess: invalidatePurchases(),
  });
};

export const useCreatePurchaseReturnServiceRequest = () => {
  return useMutation(
    (purchase_return_id: number) => createPurchaseReturnServiceRequest(purchase_return_id),
    {
      onSuccess: invalidatePurchases(),
    }
  );
};
