import { Avatar, Box, Grid, GridItem, LinkBox, Skeleton, Text, Wrap } from "@chakra-ui/react";
import { Header } from "@sciencecorp/helix-components";
import { MultiValue, Select as MultiSelect } from "chakra-react-select";
import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTeamTypeOptions } from "../../api/options";
import { TeamType, useSearchTeams } from "../../api/team";
import { useActiveUsersQuery, useCurrentUserQuery, userHasRole } from "../../api/user";
import { NewTeamModal } from "./newTeam";
import { TeamCard } from "./teamCard";
import { CsvDownload } from "../shared/csvDownloadButton";
import { getTeamCSV } from "../../api/hr";

export const Teams = () => {
  /** TODO: Use useTeamsQuery when pagination added */
  const [selectedTypeOptions, setSelectedTypeOptions] = useState<
    MultiValue<{
      label: string;
      value: TeamType;
    }>
  >([]);
  const { data, isLoading } = useSearchTeams({
    term: "*",
    pagination: { per_page: -1, page: 1 },
    filters: {
      parent_team_id: null,
      is_archived: false,
      team_type: selectedTypeOptions.length
        ? selectedTypeOptions.map(({ value }) => value)
        : undefined,
    },
    order: { name: "asc" },
  });

  const activeUsersQuery = useActiveUsersQuery();
  const currentUserQuery = useCurrentUserQuery();
  const isHRAdmin = userHasRole(currentUserQuery, "hr_admin");
  const isPeopleOpsSpecialist = userHasRole(currentUserQuery, "people_ops_specialist") || false;
  const typeOptions = useTeamTypeOptions();

  const unassignedUsers = useMemo(() => {
    if (!activeUsersQuery.data || !data) return [];

    const usersWithTeams = new Set();
    data?.results?.forEach((team) => {
      team.all_memberships?.forEach((membership) => {
        usersWithTeams.add(membership.user.id);
      });
    });
    return activeUsersQuery.data
      .filter((user) => !usersWithTeams.has(user.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data, activeUsersQuery.data]);

  const FilterNotice = () => {
    if (!selectedTypeOptions.length) return null;
    const selectedTypes = selectedTypeOptions.map(({ label }) => label).join(", ");
    return (
      <Text lineHeight={1} fontSize="sm" color="GrayText" mb="24px">
        <b>{data?.results?.length || 0}</b> results for (<b>{selectedTypes}</b>) teams
      </Text>
    );
  };

  const teamDownloadButton = isPeopleOpsSpecialist
    ? [<CsvDownload title="org-chart.csv" apiCall={getTeamCSV} buttonName="Org Chart" />]
    : [];

  return (
    <Box>
      <Header
        title="Teams"
        actions={[
          ...teamDownloadButton,
          <Box minWidth={"xs"} zIndex={2}>
            <MultiSelect
              components={{
                DropdownIndicator: null,
              }}
              variant="outline"
              isMulti
              selectedOptionStyle="check"
              options={typeOptions}
              placeholder="Filter type..."
              value={selectedTypeOptions}
              onChange={setSelectedTypeOptions}
            />
          </Box>,
        ]}
      />

      <FilterNotice />

      <Skeleton isLoaded={!isLoading} minHeight="md">
        <Grid templateColumns="repeat(auto-fit, minmax(var(--chakra-sizes-xs), 1fr))" gap={4}>
          {data?.results?.map((team) => (
            <GridItem key={team.id} minWidth={0}>
              <TeamCard team={team} location="teams" editable={isHRAdmin} />
            </GridItem>
          ))}
          {isHRAdmin && <NewTeamModal location="teams" />}
        </Grid>
      </Skeleton>

      {unassignedUsers.length > 0 && (
        <Box mt={12}>
          <Header title="Unassigned People" />
          <Wrap mb={16}>
            {unassignedUsers.map((user) => (
              <LinkBox key={user.id} as={RouterLink} to={`/users/${user.id}`}>
                <Avatar name={user.name} src={user.picture_uri} />
              </LinkBox>
            ))}
          </Wrap>
        </Box>
      )}
    </Box>
  );
};
