import React from "react";
import {
  Box,
  HStack,
  Text,
  useColorModeValue,
  useTheme,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  StatHelpText,
  Flex,
} from "@chakra-ui/react";
import MultiProgress from "react-multi-progress";
import Big from "big.js";
import { MoneyText } from "../../../MoneyText";
import { Money } from "../../../../helpers/Money";
import { BudgetItemSpendByModelData, BudgetItemSummary } from "../../../../api/budget_items";
import { BiSolidCircle } from "react-icons/bi";
import { BarElement, CategoryScale, Chart, LinearScale, Title, Tooltip, Legend } from "chart.js";
import { DateTime } from "luxon";

Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const BudgetActivityLinearBar = ({
  summary,
  spendByModel,
}: {
  summary?: BudgetItemSummary;
  spendByModel?: BudgetItemSpendByModelData;
}) => {
  const currency = "USD";
  const theme = useTheme();

  // Colors
  const gray = useColorModeValue(theme.colors.gray[100], theme.colors.gray[400]);
  const orange = useColorModeValue(theme.colors.orange[200], theme.colors.orange[500]);
  const teal = useColorModeValue(theme.colors.teal[500], theme.colors.teal[300]);
  const pink = useColorModeValue(theme.colors.pink[500], theme.colors.pink[400]);
  const blue = useColorModeValue(theme.colors.blue[500], theme.colors.blue[400]);

  // Summary Data
  const totalSpent = summary?.spent_amount || Money.zero(currency);
  const totalCommitted = summary?.committed_amount || Money.zero(currency);
  const totalPendingApproval = summary?.pending_approval_amount || Money.zero(currency);
  const totalFunded = summary?.funding_amount || Money.zero(currency);

  // Expenditure Data
  const totalPurchases = spendByModel?.purchases.total_spent || Money.zero(currency);
  const totalContracts = spendByModel?.contracts.total_spent || Money.zero(currency);
  const totalServiceRequests = spendByModel?.service_requests.total_spent || Money.zero(currency);
  const totalReimbursements = spendByModel?.reimbursements.total_spent || Money.zero(currency);

  // Calculating percentages
  const totalSpentPercentage = Money.pct(
    totalSpent,
    totalFunded || Money.zero(currency)
  ).toNumber();
  const totalCommittedPercentage = Money.pct(
    totalCommitted,
    totalFunded || Money.zero(currency)
  ).toNumber();

  const totalPurchasesPercentage = Money.pct(
    totalPurchases,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const totalContractsPercentage = Money.pct(
    totalContracts,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const totalServiceRequestsPercentage = Money.pct(
    totalServiceRequests,
    totalSpent || Money.zero(currency)
  ).toNumber();
  const totalReimbursementsPercentage = Money.pct(
    totalReimbursements,
    totalSpent || Money.zero(currency)
  ).toNumber();

  const remainingBudgetPercentage = Math.max(
    0,
    new Big(100).sub(totalSpentPercentage).sub(totalCommittedPercentage).toNumber()
  );

  const spendingProgressBarElements = [
    { value: totalSpentPercentage, color: teal },
    { value: totalCommittedPercentage, color: orange },
    { value: remainingBudgetPercentage, color: gray },
  ];

  const expenditureTypeBreakdown = [
    { value: totalPurchasesPercentage, color: teal, label: "Purchases" },
    { value: totalContractsPercentage, color: orange, label: "Contracts" },
    { value: totalServiceRequestsPercentage, color: blue, label: "Service Requests" },
    { value: totalReimbursementsPercentage, color: pink, label: "Reimbursements" },
  ];
  return (
    <Tabs colorScheme="teal">
      <TabList>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          w="100%"
          gap={{ base: "4", md: "none" }}>
          <HStack w="100%">
            <Tab>Overview</Tab>
            <Tab whiteSpace="nowrap">By Expenditure</Tab>
          </HStack>
          <HStack>
            <Icon as={BiSolidCircle} color={gray} mr="1" boxSize={2} />
            <Text
              textColor={useColorModeValue("gray.500", "auto")}
              display={{ base: "none", md: "inline" }}>
              Balance
            </Text>
            <MoneyText
              fontWeight="bold"
              money={summary?.remaining_amount}
              formatOptions={{ compact: "never" }}
            />
            <Text>/</Text>
            <MoneyText money={summary?.funding_amount} formatOptions={{ compact: "never" }} />
            <Text
              textColor={useColorModeValue("gray.500", "auto")}
              display={{ base: "none", md: "inline" }}>
              Funded
            </Text>
          </HStack>
        </Flex>
      </TabList>

      <TabPanels>
        {/* Overview Tab */}
        <TabPanel>
          <VStack width="100%" align="start" position="relative" spacing={6}>
            {/* Progress Bar */}
            <Box h="100%" w="100%" position="relative">
              <MultiProgress
                height={60}
                elements={spendingProgressBarElements}
                backgroundColor={gray}
                border="none"
                round={false}
              />
            </Box>

            {/* Stat Group for Overview */}
            <StatGroup w="100%" py={4}>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={teal} mr="1" boxSize={2} />
                  Total Spend
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalSpent} formatOptions={{ compact: "never" }} />
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={orange} mr="1" boxSize={2} />
                  Committed
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalCommitted} formatOptions={{ compact: "never" }} />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <Text>
                    over the next{" "}
                    {DateTime.now().endOf("year").diff(DateTime.now(), "months").months.toFixed()}{" "}
                    months
                  </Text>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={pink} mr="1" boxSize={2} />
                  Pending Approval
                </StatLabel>
                <StatNumber>
                  <MoneyText money={totalPendingApproval} formatOptions={{ compact: "never" }} />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <Text>for {summary?.pending_approval_records_count} expenditures</Text>
                </StatHelpText>
              </Stat>
            </StatGroup>
          </VStack>
        </TabPanel>

        {/* By Expenditure Type Tab */}
        <TabPanel>
          <VStack width="100%" align="start" position="relative" spacing={6}>
            {/* Progress Bar */}
            <Box h="100%" w="100%" position="relative">
              <MultiProgress
                height={60}
                elements={expenditureTypeBreakdown}
                backgroundColor={gray}
                border="none"
                round={false}
                roundLastElement={false}
              />
            </Box>

            {/* Stat Group for By Expenditure Type */}
            <StatGroup w="100%" py={4}>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={teal} mr="1" boxSize={2} />
                  Purchases
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.purchases.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.purchases.total_committed || Money.zero(currency)}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={orange} mr="1" boxSize={2} />
                  Contracts
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.contracts.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.contracts.total_committed}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={blue} mr="1" boxSize={2} />
                  Service Requests
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.service_requests.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.service_requests.total_committed || Money.zero(currency)}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>
                  <Icon as={BiSolidCircle} color={pink} mr="1" boxSize={2} />
                  Reimbursements
                </StatLabel>
                <StatNumber>
                  <MoneyText
                    money={spendByModel?.reimbursements.total_spent || Money.zero(currency)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
                <StatHelpText fontSize="small" textColor={useColorModeValue("gray.500", "auto")}>
                  <HStack>
                    <Text>Committed</Text>
                    <MoneyText
                      money={spendByModel?.reimbursements.total_committed || Money.zero(currency)}
                      formatOptions={{ compact: "never" }}
                    />
                  </HStack>
                </StatHelpText>
              </Stat>
            </StatGroup>
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
