import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import { UserMinimalData } from "../../api/user";
import { useDeleteMeetingAttendee } from "../../api/meeting_attendees";

type Props = {
  attendee: UserMinimalData;
  current_user_id: number;
  meeting_user_id: number;
  editable: boolean;
};

export const AttendeeCard: React.FC<Props> = ({
  attendee,
  current_user_id,
  meeting_user_id,
  editable,
}) => {
  const { mutateAsync: removeMeetingAttendee } = useDeleteMeetingAttendee();
  return (
    <LinkBox as={Card} variant="outline" border="none" width="100%" bg="transparent">
      <CardBody paddingInlineStart={0} p={2} paddingInlineEnd={1}>
        <Flex alignItems="center" justify="space-around">
          <Flex flex="1" gap="4" alignItems="center">
            <Avatar
              name={attendee.name}
              src={attendee.picture_uri}
              borderColor={"none"}
              borderWidth={0}
              boxSize={10}
            />
            <VStack align="start" spacing={1}>
              <LinkOverlay as={RouterLink} to={`/users/${attendee.id}`}>
                <Heading size="sm" color={useColorModeValue("gray.700", "gray.100")}>
                  {attendee.name}
                </Heading>
              </LinkOverlay>
            </VStack>
          </Flex>
          {current_user_id !== attendee.id && (
            <Menu>
              <MenuButton
                isDisabled={!editable}
                as={IconButton}
                aria-label="Options"
                icon={<BsThreeDotsVertical size={20} />}
                size="sm"
                variant="ghost"
              />
              <MenuList alignItems="center">
                <MenuItem
                  color={useColorModeValue("red.500", "red.300")}
                  icon={<CloseIcon />}
                  onClick={() => {
                    removeMeetingAttendee(meeting_user_id);
                  }}>
                  Remove Attendee
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </CardBody>
    </LinkBox>
  );
};
