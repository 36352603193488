import _ from "lodash";
import React from "react";
import {
  Text,
  Box,
  Flex,
  useColorModeValue,
  GridItem,
  Heading,
  IconButton,
  HStack,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

interface ListCardProps {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  description?: string;
  countBadge?: React.ReactNode;
  nonScrollingArea?: React.ReactNode | React.ReactNode[];
  onAdd?: () => void;
  ariaLabel?: string;
}

export const ListCard: React.FC<ListCardProps> = ({
  children,
  title,
  description,
  onAdd,
  ariaLabel,
  countBadge,
  nonScrollingArea,
}) => {
  const areaBg = useColorModeValue("gray.100", "gray.700");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");
  return (
    <Flex
      justifyContent={"space-between"}
      direction={"column"}
      w={"100%"}
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      p={6}
      gap={4}
      borderRadius="md">
      <Flex width={"100%"} flexDirection={{ base: "column", md: "row" }} gap={4} paddingBottom={4}>
        <Box pr={2} flexGrow={1}>
          <Flex alignItems={"center"}>
            {title ? (
              <Heading size={"md"} fontWeight={"semibold"}>
                {title}
              </Heading>
            ) : (
              <></>
            )}
            <Box> {countBadge} </Box>
          </Flex>
          {description ? (
            <Text fontSize={"xs"} color={descriptionColor} pt={1}>
              {description}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Flex>
          {onAdd ? (
            <Button
              leftIcon={<AddIcon />}
              variant="solid"
              colorScheme="teal"
              aria-label={ariaLabel || "Add"}
              onClick={onAdd}>
              Add Checkpoint
            </Button>
          ) : (
            <Box width="40px" height="40px" flexGrow={1}></Box>
          )}
        </Flex>
      </Flex>
      <Box>{nonScrollingArea ? nonScrollingArea : <></>}</Box>
      <Box flexGrow={1} overflowY={"scroll"}>
        {children}
      </Box>
    </Flex>
  );
};
