import { AxiosHeaders, AxiosResponse } from "axios";
import { getBrowser } from "./browser";

export type IsNewDeploymentVersionProps = {
  result?: AxiosResponse<any, any>;
};

let firstKnownDeploymentVersion: string | null = null;

export const isNewDeploymentVersion = ({ result }: IsNewDeploymentVersionProps): boolean => {
  const browser = getBrowser();
  if (browser === "Firefox") {
    return false;
  }
  const headers = result?.headers;
  if (!headers) {
    return false;
  }

  if (!(headers instanceof AxiosHeaders) || !headers.has("Deployment-Version")) {
    return false;
  }

  const deploymentVersion = headers.get("Deployment-Version")?.toString();
  if (!deploymentVersion) {
    return false;
  }

  if (!firstKnownDeploymentVersion) {
    firstKnownDeploymentVersion = deploymentVersion;
    return false;
  }

  if (firstKnownDeploymentVersion !== deploymentVersion) {
    return true;
  }

  return false;
};
