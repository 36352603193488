import React, { useState } from "react";
import { Box, Heading, Stack, HStack, InputGroup, Input, FormLabel, Flex } from "@chakra-ui/react";

import { HrWarning } from "../warning";
import { SurveyLayout } from "./components/SurveyLayout";
import { firstInfo, secondInfo } from "./util";

export const SurveyResults = () => {
  const [startFilter, setStartFilter] = useState<string>("2023-07-20");
  const [endFilter, setEndFilter] = useState<string>("");

  return (
    <Box>
      <HrWarning />
      <HStack width="100%" justify="space-between">
        <Heading>Survey Results</Heading>
        <InputGroup
          display="flex"
          alignItems={{ base: "flex-end", md: "center" }}
          maxW="max-content"
          flexDirection={{ base: "column", md: "row" }}
          gap={2}>
          <Flex>
            <FormLabel>From: </FormLabel>
            <Input
              size={{ base: "sm", md: "md" }}
              type="date"
              min="2023-07-20"
              value={startFilter}
              onChange={(e) => setStartFilter(e.target.value)}
            />
          </Flex>
          <Flex>
            <FormLabel>To: </FormLabel>
            <Input
              size={{ base: "sm", md: "md" }}
              type="date"
              min={startFilter}
              value={endFilter}
              onChange={(e) => setEndFilter(e.target.value)}
            />
          </Flex>
        </InputGroup>
      </HStack>
      <Stack direction={{ base: "column", lg: "row" }} width="100%" mt={6} spacing={10}>
        <SurveyLayout
          prompt_id={1}
          startDate={startFilter}
          endDate={endFilter}
          labels={firstInfo.labels}
          colors={firstInfo.colors}
        />
        <SurveyLayout
          prompt_id={2}
          startDate={startFilter}
          endDate={endFilter}
          labels={secondInfo.labels}
          colors={secondInfo.colors}
        />
      </Stack>
    </Box>
  );
};
