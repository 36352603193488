import React, { useState } from "react";
import {
  Checkbox,
  HStack,
  Text,
  Button,
  useColorModeValue,
  Spacer,
  Stack,
  IconButton,
  Icon,
  Box,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { NotificationContent } from "../util";
import { getRecordLink } from "./ModelRecordLink";

import { useUpdateNotification } from "../../../api/notifications";
import { LuCheckCheck } from "react-icons/lu";
import { MdOutlineStarBorder, MdStar } from "react-icons/md";
import { ImUndo } from "react-icons/im";
import { NotificationItemProps } from "../util";

export const NotificationItem = ({
  notification,
  currentUser,
  setSelectedNotifications,
  selectedNotifications,
}: NotificationItemProps) => {
  const now = DateTime.now();
  const createdDateFromISO = DateTime.fromISO(notification.created_at);

  const diffText = createdDateFromISO.toRelative({ base: now, style: "long" });
  const { mutate: updateNotification } = useUpdateNotification();

  const handleNotificationSelection = () => {
    const index = selectedNotifications.findIndex((n) => n.id === notification.id);

    if (index > -1) {
      const newSelectedNotifications = [...selectedNotifications];
      newSelectedNotifications.splice(index, 1);
      setSelectedNotifications(newSelectedNotifications);
    } else {
      // Notification is not selected, add it
      setSelectedNotifications([...selectedNotifications, notification]);
    }
  };

  const bgColor = selectedNotifications.some((n) => n.id === notification.id)
    ? useColorModeValue("teal.50", "teal.800")
    : notification.read
    ? useColorModeValue("gray.50", "gray.600")
    : useColorModeValue("white", "gray.700");

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      justify="space-between"
      align={{ lg: "center" }}
      width="100%"
      spacing={{ base: 1, lg: 2 }}
      borderLeft={notification.read ? "none" : "4px"}
      bg={bgColor}
      borderBottom="1px"
      borderLeftColor="red.500"
      py={3}
      px={6}
      borderBottomColor="chakra-border-color">
      <HStack py={3} align="start" w={{ base: "100%", md: "40%" }}>
        <Checkbox
          colorScheme="teal"
          isChecked={selectedNotifications.some((n) => n.id === notification.id)}
          onChange={handleNotificationSelection}
        />
        <NotificationContent notification={notification} currentUser={currentUser} />
      </HStack>
      <Spacer />
      <Box alignSelf="center" mb={2}>
        {getRecordLink(notification)}
      </Box>
      <Spacer />
      <Stack
        w={{ base: "100%", lg: "30%" }}
        justify="space-between"
        direction="row"
        align={{ base: "end", lg: "center" }}>
        <Text fontSize="sm" color={useColorModeValue("gray.500", "gray.400")} fontWeight="medium">
          {diffText}
        </Text>
        {notification.read ? (
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<ImUndo />}
            onClick={() => updateNotification({ id: notification.id, read: false })}>
            Mark As Unread
          </Button>
        ) : (
          <Button
            variant="ghost"
            colorScheme="teal"
            size="sm"
            leftIcon={<LuCheckCheck />}
            onClick={() => updateNotification({ id: notification.id, read: true })}>
            Mark As Read
          </Button>
        )}
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="star"
          icon={
            notification.saved ? (
              <Icon as={MdStar} color="yellow.400" />
            ) : (
              <Icon as={MdOutlineStarBorder} />
            )
          }
          onClick={() => updateNotification({ id: notification.id, saved: !notification.saved })}
        />
      </Stack>
    </Stack>
  );
};
