import { useQuery } from "@tanstack/react-query";
import Big from "big.js";
import { z } from "zod";
import { api } from ".";
import { dateTimeSchema } from "../helpers/dateTime";
import { zodParse } from "./zodParse";

export const EXCHANGE_RATES_BASE = "exchange_rates";

export const exchangeRateSchema = z.object({
  from_currency: z.string(),
  to_currency: z.string(),
  rate: z.string().transform((val) => new Big(val)),
  created_at: dateTimeSchema,
});

export type ExchangeRate = z.infer<typeof exchangeRateSchema>;

export const getMostRecentExchangeRates = async (): Promise<ExchangeRate[]> => {
  const result = await api.get(`${EXCHANGE_RATES_BASE}/recent`);
  return zodParse(exchangeRateSchema.array(), result.data);
};

export const useMostRecentExchangeRates = () => {
  return useQuery({
    queryKey: [EXCHANGE_RATES_BASE, "recent"],
    queryFn: () => getMostRecentExchangeRates(),
  });
};

export const getMostRecentExchangeRate = async (
  from: string,
  to: string
): Promise<ExchangeRate> => {
  const result = await api.get(`${EXCHANGE_RATES_BASE}/from/${from}/to/${to}`);
  return zodParse(exchangeRateSchema, result.data);
};

export const useMostRecentExchangeRate = (
  from: string | undefined | null,
  to: string | undefined | null
) => {
  return useQuery({
    queryKey: [EXCHANGE_RATES_BASE, from, to],
    queryFn: () => getMostRecentExchangeRate(from!, to!),
    enabled: !!from && !!to,
  });
};
