import React, { useMemo } from "react";
import { CollectionTable, EditableText } from "@sciencecorp/helix-components";
import { Flex, HStack, Heading, IconButton, Skeleton, Tag, Box, Tooltip } from "@chakra-ui/react";
import { TruncatedLocationPopover, findSelectedOption } from "./StockLocations";
import {
  InventoryItemShowData,
  useSearchInventoryItems,
  useUpdateInventoryItems,
} from "../../../../api/inventory_item";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  InventoryShowData,
  InventoryLocationsWithArrowData,
  InventoryLocationsWithInventoryData,
} from "../../../../api/inventory";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";

type InventoryItemsTableProps = {
  inventory: InventoryShowData;
  location: InventoryLocationsWithInventoryData;
  tree: InventoryLocationsWithArrowData[];
  arrowData: InventoryLocationsWithArrowData[];
  setSelectedLocation: (location: InventoryLocationsWithInventoryData | null) => void;
};

export const InventoryItemsTable = ({
  inventory,
  location,
  tree,
  arrowData,
  setSelectedLocation,
}: InventoryItemsTableProps) => {
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const { mutate: updateInventoryItem } = useUpdateInventoryItems();
  const { data: inventoryItems, isLoading } = useSearchInventoryItems({
    term: "*",
    filters: {
      inventory_location_id: location.id,
      inventory_id: inventory.id,
      has_remaining_quantity: true,
    },
    pagination: { per_page: -1, page: 1 },
    order: { created_at: "desc" },
  });

  const path = findSelectedOption(arrowData, location.id);
  const columns = useMemo(
    () => [
      {
        label: "Id",
        render: (inventoryItem: InventoryItemShowData) => inventoryItem.id,
      },
      {
        label: "Lot Number",
        render: (inventoryItem: InventoryItemShowData) => inventoryItem.lot_number || "N/A",
      },
      {
        label: "Expiration Date",
        render: (inventoryItem: InventoryItemShowData) =>
          inventoryItem.expiration_date?.toFormat("LLL dd yyyy") ?? "N/A",
      },
      {
        label: "Quantity Remaining",
        render: (inventoryItem: InventoryItemShowData) => {
          const fullQuantity =
            inventoryItem.total_quantity ===
              inventoryItem.consumable_summary?.remaining_consumable_quantity ||
            !inventoryItem.inventory.is_consumable;
          const disabled =
            !isInventoryManager || !fullQuantity || !inventoryItem.inventory.is_consumable;
          return (
            <Tooltip
              label="Only use the edit button to fix errors in stock. Do not use this to remove quantity from stock."
              isDisabled={disabled}>
              <Box>
                <EditableText
                  defaultValue={inventoryItem.total_quantity.toString()}
                  onSubmit={(value) =>
                    updateInventoryItem({ id: inventoryItem.id, total_quantity: Number(value) })
                  }
                  preview={`${
                    inventoryItem.consumable_summary?.remaining_consumable_quantity || 1
                  } ${inventoryItem.inventory.unit_of_measurement || "units"}`}
                  disabled={disabled}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    ],
    [inventoryItems, isInventoryManager]
  );

  return (
    <Flex direction="column" gap={4}>
      <Flex direction={[`column`, `row`]} justify="space-between" gap={4}>
        <HStack align="center">
          <IconButton
            variant="ghost"
            aria-label="back-to-map"
            icon={<ArrowBackIcon />}
            onClick={() => setSelectedLocation(null)}
          />
          <Heading size="md" fontWeight="semibold" mr={6}>
            Viewing
          </Heading>
        </HStack>
        <HStack>
          {path?.map((location, idx) => (
            <HStack>
              {location.isTruncated ? (
                <TruncatedLocationPopover location={location} options={tree} drawArrows={false} />
              ) : (
                <Tag>{location.name}</Tag>
              )}
              {idx < path.length - 1 && <ArrowForwardIcon />}
            </HStack>
          ))}
        </HStack>
      </Flex>
      <Skeleton isLoaded={!isLoading}>
        <CollectionTable items={inventoryItems?.results || []} columns={columns} />
      </Skeleton>
    </Flex>
  );
};
