import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  useColorModeValue,
  FormHelperText,
} from "@chakra-ui/react";
import { Select, GroupBase, OptionBase } from "chakra-react-select";
import { FormModal, Select as HelixSelect } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateProject } from "../../../api/planning/projects";
import { createProjectEdge } from "../../../api/planning/project_edges";
import { useNavigate } from "react-router";
import { useArcOptions } from "../../../api/options";

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectOptions?: { label: string; value: string }[];
}

interface ProjectOption extends OptionBase {
  label: string;
  value: string;
}

interface ProjectFormValues {
  title: string;
  startDate: string | null;
  minEndDateDuration: number;
  expectedEndDateDuration: number;
  maxEndDateDuration: number;
  arc_id: number | null;
  ancestors: { label: string; value: string }[];
}

const renderEditableDate = (label: string) => {
  return (data) => {
    const field = data.field;
    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>

        <Input
          type="date"
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              const date = DateTime.fromISO(value).toISODate();
              if (date) {
                field.onChange(date);
              }
            }
          }}
          value={field.value}
        />
      </FormControl>
    );
  };
};

const renderEditableNumber = (label: string, min?: number) => {
  return (data) => {
    const field = data.field;
    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <NumberInput {...field} min={min}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    );
  };
};

const isFormValid = (formValues: ProjectFormValues): boolean => {
  return (
    formValues.title !== "" &&
    Number(formValues.minEndDateDuration) <= Number(formValues.expectedEndDateDuration) &&
    Number(formValues.expectedEndDateDuration) <= Number(formValues.maxEndDateDuration)
  );
};

export const ProjectModal: React.FC<ProjectModalProps> = ({
  isOpen,
  onClose,
  projectOptions = [],
}: ProjectModalProps) => {
  const { mutate: createProject } = useCreateProject();
  const navigate = useNavigate();
  const defaultDate = DateTime.now().toISODate();
  const arcOptions = useArcOptions();
  const defaultFormValues = {
    title: "",
    startDate: null,
    minEndDate: 1,
    expectedEndDate: 1,
    maxEndDate: 1,
    ancestors: [],
    arc_id: -1,
  };

  const projectSelectOptions: GroupBase<ProjectOption>[] = [
    {
      label: "Projects",
      options: projectOptions.map((project) => ({
        label: project.label,
        value: project.value,
      })),
    },
  ];

  if (defaultDate === null) {
    throw new Error("Could not get default date");
  }

  const { handleSubmit, control, reset, watch } = useForm<ProjectFormValues>({
    defaultValues: defaultFormValues,
  });
  const formValues = watch();

  const handleClose = () => {
    onClose();
    reset(defaultFormValues);
  };

  const onSubmit = (projectData: ProjectFormValues) => {
    const startDate = projectData.startDate ? DateTime.fromISO(projectData.startDate) : null;
    const parentArcId = projectData.arc_id === -1 ? null : projectData.arc_id;
    createProject(
      {
        title: projectData.title,
        start_date: startDate,
        arc_id: parentArcId,
        relative_min_end_date_months: projectData.minEndDateDuration,
        relative_expected_end_date_months: projectData.expectedEndDateDuration,
        relative_max_end_date_months: projectData.maxEndDateDuration,
        is_public: false,
      },
      {
        onSuccess: (data) => {
          const creationPromises = projectData.ancestors.map((ancestor) => {
            return createProjectEdge({
              ancestor_id: parseInt(ancestor.value, 10),
              descendant_id: data.id,
            });
          });
          Promise.all(creationPromises).then(() => {
            navigate(`/planning/projects/${data.id}`);
            reset();
            handleClose();
          });
        },
      }
    );
  };

  return (
    <FormModal
      title="Create Project"
      handleSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      submitButtonDisabled={!isFormValid(formValues)}
      onClose={handleClose}>
      <Flex width={"100%"} flexDirection={"column"} alignItems={"start"} gap={4}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormLabel>Project Title</FormLabel>
                <Input {...field} placeholder="Type Project Title" />
              </FormControl>
            );
          }}
        />
        <Controller
          name="arc_id"
          control={control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormLabel>Parent Group</FormLabel>
                <HelixSelect options={arcOptions} {...field} placeholder="Type Parent Arc" />
              </FormControl>
            );
          }}
        />
        <Controller name="startDate" control={control} render={renderEditableDate("Start Date")} />

        <Controller
          name="ancestors"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Dependencies</FormLabel>
              <FormHelperText mb={3}>
                Select the project(s) that must be completed before this project can be initiated.
              </FormHelperText>
              <Select
                isMulti
                useBasicStyles
                options={projectSelectOptions || []}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            </FormControl>
          )}
        />

        <Flex
          justifyContent={"space-between"}
          direction={"column"}
          w={"100%"}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          p={6}
          gap={4}
          borderRadius="md">
          <Text>Estimated Duration (Months)</Text>
          <Flex gap={4}>
            <Controller
              name="minEndDateDuration"
              control={control}
              render={renderEditableNumber("Min")}
            />
            <Controller
              name="expectedEndDateDuration"
              control={control}
              render={renderEditableNumber("Most Likely", formValues.minEndDateDuration)}
            />
            <Controller
              name="maxEndDateDuration"
              control={control}
              render={renderEditableNumber("Max", formValues.minEndDateDuration)}
            />
          </Flex>
        </Flex>
      </Flex>
    </FormModal>
  );
};
