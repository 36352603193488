import React from "react";
import { FiDownload } from "react-icons/fi";
import { Button, Box } from "@chakra-ui/react";
import { set } from "zod";

type CsvDownloadButtonProps = {
  title: string;
  apiCall: () => Promise<any>;
  buttonName: string;
};

export const CsvDownload: React.FC<CsvDownloadButtonProps> = ({ title, apiCall, buttonName }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleDownload = async (apiCall: () => Promise<any>, title: string) => {
    try {
      setIsLoading(true);
      const response = await apiCall();
      const url = window.URL.createObjectURL(new Blob([response], { type: "text/csv" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Button
        leftIcon={<FiDownload />}
        onClick={() => handleDownload(apiCall, title)}
        isLoading={isLoading}>
        {buttonName}
      </Button>
    </Box>
  );
};
