import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { BudgetItemSummary } from "../../../../../api/budget_items";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Legend,
  Tooltip
);

export const BudgetSpentChart = ({
  budgetGroupSummary,
  budgetSummary,
}: {
  budgetGroupSummary: BudgetItemSummary;
  budgetSummary: BudgetItemSummary;
}) => {
  const backgroundColor = useColorModeValue(
    ["#319795", "#EDF2F7"],
    ["#319795", "#A0AEC0"] // dark mode
  );

  const fillText = useColorModeValue("black", "white");
  const percentageBudget =
    ((budgetSummary.item_spent_amount?.majorUnits.toNumber() || 0) /
      budgetGroupSummary.funding_amount.majorUnits.toNumber()) *
    100;

  const leftoverMoney = Math.max(
    budgetGroupSummary.funding_amount.majorUnits.toNumber() -
      (budgetSummary.item_spent_amount?.majorUnits.toNumber() || 0),
    0
  );

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height, ctx } = chart;
      ctx.restore();
      ctx.textBaseline = "middle";
      const fontSize = 8;
      ctx.font = `${fontSize}px inherit`;

      ctx.fillStyle = fillText;

      const text = `${percentageBudget.toFixed()}%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = useMemo(
    () => ({
      labels: ["Spent"],
      datasets: [
        {
          data: [budgetSummary.item_spent_amount?.majorUnits.toNumber(), leftoverMoney],
          backgroundColor,
          hoverOffset: 4,
          borderColor: "transparent",
        },
      ],
    }),
    [budgetGroupSummary, budgetSummary]
  );

  return (
    <Flex h="40px" w="40px">
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          cutout: "80%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
        plugins={[centerTextPlugin]}
      />
    </Flex>
  );
};
