import {
  Box,
  Button,
  HStack,
  Heading,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Stack,
  Tr,
  useDisclosure,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { ReviewFeedbackPartialData } from "../../api/review_feedback";
import { titleize } from "inflection";

import { PaginationControls } from "@sciencecorp/helix-components";
import { FeedbackModal } from "./components/FeedbackModal";
import { OnboardingFeedbackModal } from "./components/OnboardingFeedbackModal";
import { PaginationData } from "../../api/collection_types";
import { UserLoggedInData } from "../../api/user";

type FeedbackProps = {
  reviewFeedbacks: ReviewFeedbackPartialData[] | undefined;
  reviewFeedbackQuery: any;
  paginationPage: PaginationData;
  setPaginationPage: (pagination: PaginationData) => void;
  currentUser?: UserLoggedInData;
};

export const Feedback = ({
  reviewFeedbacks,
  reviewFeedbackQuery,
  paginationPage,
  setPaginationPage,
  currentUser,
}: FeedbackProps) => {
  const [feedback, setFeedback] = useState<ReviewFeedbackPartialData | null>(null);

  const {
    isOpen: isOpenFeedbackModal,
    onOpen: onOpenFeedbackModal,
    onClose: onCloseFeedbackModal,
  } = useDisclosure();
  const {
    isOpen: isOpenOnboardingFeedbackModal,
    onOpen: onOpenOnboardingFeedbackModal,
    onClose: onCloseOnboardingFeedbackModal,
  } = useDisclosure();

  return (
    <>
      <Stack width={{ base: "100%", lg: "50%" }} spacing={8}>
        <Box>
          <Heading size="md" fontWeight="semibold" mb={3}>
            Feedback For You
          </Heading>
          <Text color={useColorModeValue("gray.700", "gray.400")}>
            You will periodically receive formal feedback from Science, including a summary of the
            feedback left for you by your colleagues.
          </Text>
        </Box>

        <Table
          variant="simple"
          size="sm"
          border={reviewFeedbackQuery.data?.results.length ? ["1px", "none"] : "none"}
          borderColor={useColorModeValue("gray.200", "gray.600")}>
          <Thead display={["none", "table-header-group"]}>
            <Tr>
              <Th>Generated</Th>
              <Th>From</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {reviewFeedbacks && reviewFeedbacks.length ? (
              orderBy(reviewFeedbacks, ["viewed", "created_at"], ["asc", "desc"]).map(
                (reviewFeedback) => {
                  return (
                    <Tr
                      key={reviewFeedback.id}
                      fontWeight={reviewFeedback.viewed ? "normal" : "bold"}
                      bg={
                        reviewFeedback.viewed
                          ? useColorModeValue("gray.50", "gray.800")
                          : useColorModeValue("white", "gray.700")
                      }
                      display={["flex", "table-row"]}
                      gap={1.5}
                      flexDirection={["column", "row"]}
                      justifyContent={"flex-start"}
                      flexWrap="wrap"
                      borderBottom="1px"
                      borderColor={useColorModeValue("gray.200", "gray.600")}
                      paddingBlock={3}>
                      <Td flexBasis="25%" borderBottom="none" py={[0, 4]}>
                        <Flex flexWrap={["wrap", "nowrap"]} justifyContent="space-between">
                          <Text
                            display={["block", "none"]}
                            textTransform={"uppercase"}
                            fontSize="xs"
                            color="gray.500"
                            letterSpacing={"wider"}
                            fontWeight={"bold"}>
                            Generated
                          </Text>
                          <Text>
                            {DateTime.fromISO(reviewFeedback.created_at).toFormat("MMM dd yyyy")}
                          </Text>
                        </Flex>
                      </Td>
                      <Td flexBasis="25%" borderBottom="none">
                        <Flex flexWrap={["wrap", "nowrap"]} justifyContent="space-between">
                          <Text
                            display={["block", "none"]}
                            textTransform={"uppercase"}
                            fontSize="xs"
                            color="gray.500"
                            letterSpacing={"wider"}
                            fontWeight={"bold"}>
                            From
                          </Text>
                          {reviewFeedback.review_feedback_type !== "onboarding" ? (
                            <Text>{titleize(reviewFeedback.review_feedback_type)}</Text>
                          ) : (
                            <Text>
                              {reviewFeedback.occasion} Review{" "}
                              {reviewFeedback.onboarding_user?.id !== currentUser?.id &&
                                `for ${reviewFeedback.onboarding_user?.name}`}
                            </Text>
                          )}
                        </Flex>
                      </Td>
                      <Td flexBasis="25%" borderBottom="none" py={0}>
                        <Flex
                          flexWrap={["wrap", "nowrap"]}
                          justifyContent="space-between"
                          align="center">
                          <Text
                            display={["block", "none"]}
                            textTransform={"uppercase"}
                            fontSize="xs"
                            color="gray.500"
                            letterSpacing={"wider"}
                            fontWeight={"bold"}>
                            Review
                          </Text>
                          <HStack width={["auto", "100%"]} justify={"flex-end"}>
                            {reviewFeedback.viewed !== undefined && !reviewFeedback.viewed && (
                              <Tag colorScheme="green">Unread</Tag>
                            )}
                            <Button
                              size="sm"
                              p={[0, 2]}
                              onClick={() => {
                                setFeedback(reviewFeedback);
                                reviewFeedback.review_feedback_type === "onboarding"
                                  ? onOpenOnboardingFeedbackModal()
                                  : onOpenFeedbackModal();
                              }}
                              color="teal"
                              bg="transparent">
                              View
                            </Button>
                          </HStack>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                }
              )
            ) : (
              <Tr>
                <Td colSpan={3} borderBottom="none">
                  <Text opacity="0.5" pt={3} lineHeight="shorter" fontWeight="semibold">
                    No feedback has been generated for you yet, check back later!
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {paginationPage.n_pages > 1 ? (
          <PaginationControls
            pagination={paginationPage}
            onPagination={(newPagination) => setPaginationPage(newPagination)}
          />
        ) : null}
      </Stack>
      <FeedbackModal
        isOpen={isOpenFeedbackModal}
        onClose={onCloseFeedbackModal}
        encryptedFeedback={feedback}
        reviewFeedbackQuery={reviewFeedbackQuery}
        currentUser={currentUser}
      />
      <OnboardingFeedbackModal
        isOpen={isOpenOnboardingFeedbackModal}
        onClose={onCloseOnboardingFeedbackModal}
        encryptedFeedback={feedback}
        reviewFeedbackQuery={reviewFeedbackQuery}
      />
    </>
  );
};
