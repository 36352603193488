import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { aggregationSchema, searchParams, SearchParams } from "./collection_types";
import { invalidateCredentials } from "./credentials";
import { zodParse } from "./zodParse";

export const USER_CREDENTIALS_BASE_URL = "user_credentials";

export const userCredentialSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  user_name: z.string(),
  credential_id: z.number(),
  expiration_date: z.string().nullable(),
  days_until_expiration: z.number().optional(),
  expiration_percentage: z.number().optional(),
  currency_lapse: z.number().optional(),
  currency_percentage: z.number().optional(),
  last_used: z.string().nullable(),
  status: z.string(),
  date_issued: z.string().nullable().optional(),
  credential_name: z.string(),
  user_training_sessions_for_trainings: z
    .object({
      id: z.number(),
      name: z.string(),
      status: z.string(),
      expiration_date: z.string().nullable(),
    })
    .array(),
  trainings: z.array(z.object({ id: z.number(), name: z.string() })),
});

export const userCredentialIndexSchema = z.object({
  active_credentials: z.array(userCredentialSchema),
  unfulfilled_credentials: z.array(userCredentialSchema),
  aggregations: aggregationSchema.nullable(),
});

export const UserCredentialCreateParamsSchema = z.object({
  user_ids: z.number().array(),
  credential_id: z.number(),
  status: z.string(),
  date_issued: z.string().nullable(),
});

export type UserCredentialData = z.infer<typeof userCredentialSchema>;
export type UserCredentialIndexData = z.infer<typeof userCredentialIndexSchema>;
export type UserCredentialCreateParams = z.infer<typeof UserCredentialCreateParamsSchema>;

// api queries

export const getUserCredentials = async () => {
  const result = await api.get(USER_CREDENTIALS_BASE_URL);
  return zodParse(userCredentialIndexSchema, result.data);
};

export const getUserCredential = async (id: number) => {
  const result = await api.get(`${USER_CREDENTIALS_BASE_URL}/${id}`);
  return zodParse(userCredentialSchema, result.data);
};

export const searchUserCredentials = async ({ aggs, bodyOptions, filters, term }: SearchParams) => {
  const path = [USER_CREDENTIALS_BASE_URL, "search"];
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...search });
  return result.data;
};

export const newUserCredential = async (user_credential: UserCredentialCreateParams) => {
  const result = await api.post(USER_CREDENTIALS_BASE_URL, { user_credential });
  return result.data;
};

export const updateUserCredential = async (user_credential: Partial<UserCredentialData>) => {
  const result = await api.put(`${USER_CREDENTIALS_BASE_URL}/${user_credential.id}`, {
    user_credential,
  });
  return result.data;
};

export const deleteUserCredential = async (id: number) => {
  await api.delete(`${USER_CREDENTIALS_BASE_URL}/${id}`);
};

// query hooks

export const invalidateUserCredentials = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [USER_CREDENTIALS_BASE_URL, id],
    });
};

export const useGetUserCredentials = () => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL],
    queryFn: () => getUserCredentials(),
  });
};

export const useGetUserCredential = (id: number) => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL, id],
    queryFn: () => getUserCredential(id),
  });
};

export const useSearchUserCredentials = (params: SearchParams) => {
  return useQuery({
    queryKey: [USER_CREDENTIALS_BASE_URL, params],
    queryFn: () => searchUserCredentials(params),
  });
};

export const useNewUserCredential = (credentialId: number) => {
  return useMutation({
    mutationFn: newUserCredential,
    onSuccess: invalidateCredentials(credentialId),
  });
};

export const useUpdateUserCredential = () => {
  return useMutation({
    mutationFn: updateUserCredential,
    onSuccess: invalidateCredentials(),
  });
};

export const useDeleteUserCredential = (credentialId: number) => {
  return useMutation({
    mutationFn: deleteUserCredential,
    onSuccess: invalidateCredentials(credentialId),
  });
};
