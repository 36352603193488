import React from "react";
import { createIcon, useColorModeValue } from "@chakra-ui/react";

export const OnboardingReviewTalkImage = createIcon({
  displayName: "OnboardingReviewTalkImage",
  viewBox: "0 0 169 97",
  path: (
    <svg
      width="169"
      height="97"
      viewBox="0 0 169 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.7629 0.62183C95.6543 -0.816725 112.377 10.7846 128.57 19.573C145.784 28.9162 171.911 38.2285 168.107 51.827C164.301 65.4357 131.349 66.3271 113.078 74.9638C98.5911 81.8113 92.2638 95.4098 73.7629 96.4222C54.7042 97.4652 39.6719 87.8172 26.3868 79.7079C13.4257 71.7964 -0.0281573 62.8082 0.704411 51.827C1.42044 41.0938 18.2293 34.2787 29.9662 26.0526C43.6759 16.4437 52.5743 2.0142 73.7629 0.62183Z"
        fill="#B2F5EA"
      />

      <path
        opacity="0.7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.7629 0.62183C95.6543 -0.816725 112.377 10.7846 128.57 19.573C145.784 28.9162 171.911 38.2285 168.107 51.827C164.301 65.4357 131.349 66.3271 113.078 74.9638C98.5911 81.8113 92.2638 95.4098 73.7629 96.4222C54.7042 97.4652 39.6719 87.8172 26.3868 79.7079C13.4257 71.7964 -0.0281573 62.8082 0.704411 51.827C1.42044 41.0938 18.2293 34.2787 29.9662 26.0526C43.6759 16.4437 52.5743 2.0142 73.7629 0.62183Z"
        fill="#B2F5EA"
      />
      <rect x="72.8125" y="14.0198" width="83.0525" height="62.0125" rx="8" fill="#D6BCFA" />
      <path
        opacity="0.39"
        d="M73.6164 19.4653H88.8364C94.1047 19.4653 98.3754 23.7361 98.3754 29.0044V66.5978V76.0372C89.817 76.0372 86.306 76.0372 74.4108 76.0372C69.5637 61.403 70.5429 19.4654 73.6164 19.4653Z"
        fill="#9F7AEA"
      />
      <rect x="12.1328" y="21.8457" width="83.0525" height="62.0125" rx="8" fill="#4FD1C5" />
      <path d="M31.7969 96.5001V83.8584H46.1948L31.7969 96.5001Z" fill="#4FD1C5" />
      <g clip-path="url(#clip0_7013_407999)">
        <path
          d="M53.6603 53.2881C59.0777 53.2881 63.4684 48.9506 63.4684 43.5986C63.4684 38.2467 59.0777 33.9092 53.6603 33.9092C48.2428 33.9092 43.8522 38.2467 43.8522 43.5986C43.8522 48.9506 48.2428 53.2881 53.6603 53.2881ZM60.526 55.7105H59.2463C57.5452 56.4826 55.6525 56.9216 53.6603 56.9216C51.668 56.9216 49.783 56.4826 48.0743 55.7105H46.7946C41.109 55.7105 36.4961 60.2675 36.4961 65.8844V69.0335C36.4961 71.0395 38.1435 72.667 40.1741 72.667H67.1464C69.177 72.667 70.8245 71.0395 70.8245 69.0335V65.8844C70.8245 60.2675 66.2116 55.7105 60.526 55.7105Z"
          fill="#319795"
        />
      </g>
      <circle cx="116.742" cy="36.5846" r="8.44141" fill="#9F7AEA" />
      <path
        d="M116.707 58.1106L109.361 45.2266C108.678 44.0298 107.264 43.4423 105.939 43.8217C102.707 44.7476 102.42 45.1162 100.972 48.4203C100.14 50.3203 100.108 53.6301 100.3 56.5532C100.523 59.953 103.462 62.4078 106.869 62.4078H125.941C129.658 62.4078 133.014 59.5259 133.225 55.8145C133.362 53.4055 133.286 50.7755 132.568 48.4203C131.492 44.8914 129.425 44.0494 127.491 43.641C126.153 43.3583 124.739 44.0201 124.062 45.2086L116.707 58.1106Z"
        fill="#9F7AEA"
      />
      <path
        d="M119.859 45.7483H113.629L115.563 48.1994L114.684 52.7173L116.794 56.156L118.803 52.7173L117.949 48.1994L119.859 45.7483Z"
        fill="#9F7AEA"
      />
      <path d="M120.124 14.8979L132.766 14.8979L132.766 0.50001L120.124 14.8979Z" fill="#D6BCFA" />
      <defs>
        <clipPath id="clip0_7013_407999">
          <rect x="36.4961" y="33.9092" width="34.3284" height="38.7578" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});

export const OnboardingScale = createIcon({
  displayName: "OnboardingReviewTalkImage",
  viewBox: "0 0 110 97",
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1595_160261)">
        <path
          d="M775 110H-665V112H775V110Z"
          fill="#E2E8F0"
          mask="url(#path-3-inside-1_1595_160261)"
        />
        <g clip-path="url(#clip1_1595_160261)">
          <rect x="-169" y="-67.5" width="448" height="527" rx="6" fill="transparent" />
          <path
            opacity="0.7"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53.4511 2.17501C67.2129 1.59018 82.1655 -2.8267 93.0522 5.6119C104.742 14.673 110.473 30.4988 108.974 45.213C107.578 58.9258 95.8566 68.1408 85.7303 77.4923C76.1956 86.2974 66.4244 96.1201 53.4511 96.4877C40.2163 96.8627 28.7734 88.6133 19.289 79.375C9.64813 69.9842 1.23247 58.665 0.8109 45.213C0.37842 31.4127 6.38943 17.5799 17.0786 8.84053C26.9988 0.729907 40.6489 2.71906 53.4511 2.17501Z"
            fill="#B2F5EA"
          />
          <path
            d="M19.6063 19.1741C28.7201 10.9642 41.4634 5.85072 53.7266 5.56616V24.0741C45.8907 24.256 38.1717 27.4809 32.3482 32.7269L19.6063 19.1741Z"
            fill="#F6AD55"
          />
          <path
            d="M89.3945 19.2248C80.2807 11.0148 67.5126 5.85072 55.2495 5.56616V24.0741C63.0854 24.256 70.5965 27.2406 76.42 32.4866L89.3945 19.2248Z"
            fill="#4FD1C5"
          />
          <path
            d="M105.766 56.8315C105.766 50.0974 104.439 43.4293 101.862 37.2078C99.2852 30.9863 95.1472 24.9718 90.3855 20.2101L77.5086 33.3998C80.5512 36.4425 83.1118 40.317 84.7585 44.2924C86.4051 48.2678 87.2527 52.5286 87.2527 56.8315H105.766Z"
            fill="#319795"
          />
          <path
            d="M3.21031 56.8318C3.21031 50.0977 4.53669 43.4295 7.11371 37.208C9.69074 30.9865 13.8629 25.0364 18.6247 20.2747L31.3203 33.6624C28.2777 36.7051 25.8641 40.3172 24.2175 44.2926C22.5708 48.268 21.7233 52.5288 21.7233 56.8318H3.21031Z"
            fill="#F56565"
          />
          <path
            d="M67.7507 55.2488L68.8252 55.4553L68.9344 54.3666L73.7294 6.57187L71.8127 6.08401L52.8375 51.1537L52.3587 52.2909L53.5704 52.5237L67.7507 55.2488Z"
            fill="#319795"
            stroke="#C9F8F0"
            stroke-width="2"
          />
          <circle
            cx="56.313"
            cy="71.7244"
            r="19.8443"
            fill="#B2F5EA"
            stroke="#319795"
            stroke-width="3"
          />
          <g clip-path="url(#clip2_1595_160261)">
            <path
              d="M56.3086 73.8318C61.2008 73.8318 65.1657 70.0267 65.1657 65.3318C65.1657 60.6369 61.2008 56.8318 56.3086 56.8318C51.4164 56.8318 47.4515 60.6369 47.4515 65.3318C47.4515 70.0267 51.4164 73.8318 56.3086 73.8318ZM62.5086 75.9568H61.353C59.8169 76.6341 58.1077 77.0193 56.3086 77.0193C54.5095 77.0193 52.8073 76.6341 51.2642 75.9568H50.1086C44.9742 75.9568 40.8086 79.9544 40.8086 84.8818L41.4511 85.8616C41.4511 87.6213 46.1328 90.8318 47.9665 90.8318L58.2896 92.377L66.6666 89.2885C68.5003 89.2885 70.8973 87.6213 70.8973 85.8616L71.8086 84.8818C71.8086 79.9544 67.643 75.9568 62.5086 75.9568Z"
              fill="#319795"
            />
          </g>
        </g>
      </g>
      <path
        d="M-7149 -646H9519V-648H-7149V-646ZM9520 -645V936H9522V-645H9520ZM9519 937H-7149V939H9519V937ZM-7150 936V-645H-7152V936H-7150ZM-7149 937C-7149.55 937 -7150 936.552 -7150 936H-7152C-7152 937.657 -7150.66 939 -7149 939V937ZM9520 936C9520 936.552 9519.55 937 9519 937V939C9520.66 939 9522 937.657 9522 936H9520ZM9519 -646C9519.55 -646 9520 -645.552 9520 -645H9522C9522 -646.657 9520.66 -648 9519 -648V-646ZM-7149 -648C-7150.66 -648 -7152 -646.657 -7152 -645H-7150C-7150 -645.552 -7149.55 -646 -7149 -646V-648Z"
        fill="white"
        fill-opacity="0.1"
      />
      <defs>
        <filter
          id="filter0_dd_1595_160261"
          x="-181"
          y="-69.5"
          width="472"
          height="551"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1595_160261"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1595_160261" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1595_160261"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1595_160261"
            result="effect2_dropShadow_1595_160261"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1595_160261"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1595_160261">
          <rect width="1440" height="1024" fill="white" transform="translate(-665 -316)" />
        </clipPath>
        <clipPath id="clip1_1595_160261">
          <rect x="-169" y="-67.5" width="448" height="527" rx="6" fill="white" />
        </clipPath>
        <clipPath id="clip2_1595_160261">
          <rect x="40.8125" y="56.8318" width="31" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
