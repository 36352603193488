import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spacer,
  Text,
  ToastId,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { UserSurveyPromptData, completeSurvey, useSampleUserSurveyQuery } from "../../api/surveys";

const SliderThumbWithTooltip = (props: {
  onChange: (value: string) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
}) => {
  const [sliderValue, setSliderValue] = React.useState(5);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const updateValue = (value: number) => {
    setSliderValue(value);
    props.onChange(value.toString());
  };

  return (
    <Slider
      id="slider"
      defaultValue={props.defaultValue ? props.defaultValue : 50}
      min={props.min ? props.min : 0}
      max={props.max ? props.max : 100}
      colorScheme="teal"
      onChange={updateValue}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}>
      <SliderMark value={25} mt="1" ml="-2.5" fontSize="sm">
        25%
      </SliderMark>
      <SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
        50%
      </SliderMark>
      <SliderMark value={75} mt="1" ml="-2.5" fontSize="sm">
        75%
      </SliderMark>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="teal.500"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={`${sliderValue}%`}>
        <SliderThumb />
      </Tooltip>
    </Slider>
  );
};

const RadioButtons = (props: {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  defaultValue?: string;
}) => {
  return (
    <RadioGroup
      onChange={(value) => {
        props.onChange(value.toString());
      }}>
      <Grid templateColumns="repeat(4, 1fr)">
        {props.options.map((option) => (
          <Radio value={option.value}>{option.label}</Radio>
        ))}
      </Grid>
    </RadioGroup>
  );
};

const SelectForm = (props: {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  defaultValue?: string;
}) => {
  return (
    <Select placeholder="Select an answer...">
      {props.options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </Select>
  );
};

export const SurveyToaster = () => {
  const surveyQuery = useSampleUserSurveyQuery();

  const toast = useToast({
    position: "bottom-right",
  });
  const toastIdRef = React.useRef() as React.MutableRefObject<ToastId>;

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  useEffect(() => {
    if (surveyQuery.data && surveyQuery.data.ready) {
      createToast(surveyQuery.data);
    }
  }, [surveyQuery.data]);

  const createToast = (prompt_: UserSurveyPromptData) => {
    const prompt = prompt_.prompt;

    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
    toastIdRef.current = toast({
      duration: null,
      render: () => {
        const [answer, setAnswer] = React.useState<string>();

        const onUpdateCallback = (value: string) => {
          setAnswer(value);
        };

        const saveResponse = (isSkipped: boolean) => {
          if (answer === undefined) {
            completeSurvey({
              user_survey_prompt_id: prompt?.id,
              answer: null,
              is_skipped: isSkipped,
            });
            close();
            return;
          } else {
            completeSurvey({
              user_survey_prompt_id: prompt?.id,
              answer: parseInt(answer),
              is_skipped: isSkipped,
            });
            close();
          }
        };

        return (
          <Card variant={"elevated"}>
            <CardBody>
              <Text mb={7}>{prompt?.question}</Text>
              {prompt?.answer_type === "slider" && (
                <SliderThumbWithTooltip onChange={onUpdateCallback} />
              )}
              {prompt?.answer_type === "radio" && (
                <RadioButtons
                  options={prompt?.answer_options ? prompt?.answer_options : []}
                  onChange={onUpdateCallback}
                />
              )}
              {prompt?.answer_type === "select" && (
                <SelectForm options={[]} onChange={onUpdateCallback} />
              )}
            </CardBody>
            <CardFooter>
              <Flex flex={1}>
                <Spacer />
                <Button
                  variant="text"
                  onClick={() => {
                    setAnswer("");
                    saveResponse(true);
                  }}>
                  Skip
                </Button>
                <Button colorScheme="teal" onClick={() => saveResponse(false)}>
                  Submit
                </Button>
              </Flex>
            </CardFooter>
          </Card>
        );
      },
    });
  };

  return <></>;
};
