import { z } from "zod";
import { dateTimeSchema } from "../../helpers/dateTime";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "..";
import { PROJECT_BASE_URL } from "./projects";
import { zodParse } from "../zodParse";

const PROJECT_CHECKPOINTS_QUERY_KEY = ["projects", "checkpoints"];

const invalidateCache = () => {
  const queryClient = useQueryClient();
  return (_, { project_id }) => {
    queryClient.invalidateQueries({
      queryKey: [...PROJECT_CHECKPOINTS_QUERY_KEY, project_id],
    });
  };
};

export const previousCheckpointSchema = z.object({
  id: z.number().nullable(),
  title: z.string().nullable(),
});

export const projectCheckpointSchema = z.object({
  id: z.number(),
  done: z.boolean(),
  title: z.string(),
  offset_days: z.number().nullable(),
  previous_checkpoint: previousCheckpointSchema.nullable(),
  scheduled_date: dateTimeSchema.nullable(),
  completed_at: dateTimeSchema.nullable(),
  project_id: z.number(),
  has_dependencies: z.boolean(),
});
export type PreviousCheckpoint = z.infer<typeof previousCheckpointSchema>;
export type ProjectCheckpoint = z.infer<typeof projectCheckpointSchema>;
export type UpdateProjectCheckpoint = Omit<
  Partial<ProjectCheckpoint>,
  "scheduled_at" | "has_dependencies"
> & {
  id: number;
  project_id: number;
  previous_checkpoint_id?: number | null;
};
export type CreateProjectCheckpoint = Omit<
  ProjectCheckpoint,
  "id" | "scheduled_date" | "previous_checkpoint" | "done" | "has_dependencies" | "completed_at"
> & {
  previous_checkpoint_id?: number | null;
};
const getProjectCheckpoints = async (projectId: number): Promise<ProjectCheckpoint[]> => {
  const response = await api.get(`${PROJECT_BASE_URL}/${projectId}/checkpoints`);
  return zodParse(z.array(projectCheckpointSchema), response.data);
};
export const useGetProjectCheckpoints = (projectId: number) => {
  return useQuery({
    queryKey: [...PROJECT_CHECKPOINTS_QUERY_KEY, projectId],
    queryFn: () => getProjectCheckpoints(projectId),
  });
};
const addProjectCheckpoint = async (
  checkpoint: CreateProjectCheckpoint
): Promise<ProjectCheckpoint> => {
  const response = await api.post(
    `${PROJECT_BASE_URL}/${checkpoint.project_id}/checkpoints`,
    checkpoint
  );
  return zodParse(projectCheckpointSchema, response.data);
};
export const useAddProjectCheckpoint = () => {
  return useMutation({
    mutationFn: addProjectCheckpoint,
    onSuccess: invalidateCache(),
  });
};
const updateProjectCheckpoint = async (
  checkpoint: UpdateProjectCheckpoint
): Promise<ProjectCheckpoint> => {
  const response = await api.put(
    `${PROJECT_BASE_URL}/${checkpoint.project_id}/checkpoints/${checkpoint.id}`,
    checkpoint
  );
  return zodParse(projectCheckpointSchema, response.data);
};
export const useUpdateProjectCheckpoint = () => {
  return useMutation({
    mutationFn: updateProjectCheckpoint,
    onSuccess: invalidateCache(),
  });
};
const removeProjectCheckpoint = async (checkpoint: UpdateProjectCheckpoint): Promise<void> => {
  await api.delete(`${PROJECT_BASE_URL}/${checkpoint.project_id}/checkpoints/${checkpoint.id}`);
};
export const useRemoveProjectCheckpoint = () => {
  return useMutation({
    mutationFn: removeProjectCheckpoint,
    onSuccess: invalidateCache(),
  });
};
