import { z } from "zod";
import { api } from ".";
import { useQuery, UseQueryResult, useQueryClient, useMutation } from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { aggregationSchema, indexParams, searchParams, SearchParams } from "./collection_types";
import { userMinimalSchema } from "./user";
import {
  partialKeyringMessageSchema,
  keyringMessageSchema,
  userPublicKeySchema,
} from "./keyring_message";
import { dateTimeSchema } from "../helpers/dateTime";
import { zodParse } from "./zodParse";
import { Money, moneySchema } from "../helpers/Money";

export const CANDIDATES_BASE_URL = "candidates";
export const CANDIDATE_REFERENCES_BASE_URL = "candidate_references";
export const CANDIDATE_TIMELINE_EVENT_BASE_URL = "candidate_timeline_events";

export const candidatePartialSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number().nullable(),
  role: z.string(),
  email: z.string(),
  hiring_manager_id: z.number().nullable(),
  budget_headcount_id: z.number().nullable(),
  budget_headcount: z
    .object({
      id: z.number(),
      archetype: z.object({
        id: z.number(),
        name: z.string(),
      }),
      budgetable: z.object({
        id: z.number(),
        name: z.string(),
      }),
    })
    .nullable(),
  team: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  decision: z.string(),
  locked: z.boolean(),
  completed_candidate_feedbacks_count: z
    .object({ completed_feedbacks: z.number(), total_feedbacks: z.number() })
    .optional(),
  has_references: z.boolean(),
  candidate_feedback: z
    .object({ id: z.number(), locked: z.boolean(), keyring_message_id: z.number().nullable() })
    .nullable()
    .optional(),
  keyring_message: keyringMessageSchema.nullable().optional(),
  keyring_user_keys: z.array(userPublicKeySchema),
});

export const candidateRoles = z.array(
  z.object({
    role: z.string(),
  })
);

export const candidateIndexSchema = z.object({
  results: z.array(candidatePartialSchema),
  pagination: paginationSchema,
});

export const candidateReferenceSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  phone_number: z.string().nullable(),
  email: z.string().nullable(),
  relationship: z.string().nullable(),
  candidate_id: z.number(),
  meeting_notes: z.string().nullable(),
  impression: z.string().nullable(),
  additional_information: z.string(),
  company: z.string(),
  title: z.string(),
});

export const candidateTimelineEventSchema = z.object({
  id: z.number(),
  candidate_id: z.number(),
  user_id: z.number(),
  user: userMinimalSchema,
  notes: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const candidateShowSchema = candidatePartialSchema.extend({
  candidate_feedbacks: z
    .object({
      id: z.number(),
      candidate_id: z.number(),
      user_id: z.number(),
      user: z.object({
        id: z.number(),
        name: z.string(),
        picture_uri: z.string(),
      }),
      keyring_message: partialKeyringMessageSchema.nullable(),
      keyring_user_keys: z.array(userPublicKeySchema),
    })
    .array(),
  hiring_manager_name: z.string().nullable(),
  test_score: z.number().nullable(),
  summary: z.string(),
  notes: z.string(),
  candidate_references: z.array(candidateReferenceSchema),
  education: z.string().nullable(),
  employment_history: z.string().nullable(),
  exceptional_ability: z.string().nullable(),
  offer_letter: z.string().nullable(),
  start_date: z.string().nullable(),
  salary: z
    .object({
      salary_per_unit: moneySchema,
      unit: z.string(),
      equity: z.number(),
    })
    .nullable(),
  candidate_timeline_events: z.array(candidateTimelineEventSchema),
  keyring_message: partialKeyringMessageSchema.nullable(),
  keyring_user_keys: z.array(userPublicKeySchema),
});

export const candidateAdminSchema = candidateShowSchema.extend({
  candidate_feedbacks: z
    .object({
      id: z.number(),
      candidate_id: z.number(),
      user_id: z.number(),
      overall_score: z.number(),
    })
    .array(),
});

export const candidateAdminIndexSchema = z.object({
  results: z.array(candidateAdminSchema),
  pagination: paginationSchema,
});

export const CandidateSearchIndexSchema = z.object({
  results: z.array(candidateAdminSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable(),
});

export const keyringCandidateTimelineEventSchema = z.object({
  candidate_id: z.number().optional(),
  user_id: z.number(),
  notes: z.string(),
  created_at: dateTimeSchema,
});

export const candidateKeyringMessageSchemaV1 = z.object({
  ccat_score: z.number().nullable().optional(),
  summary: z.string().nullable().optional(),
  test_score: z.number().nullable().optional(),
  notes: z.string().nullable().optional(),
  education: z.string().nullable().optional(),
  employment_history: z.string().nullable().optional(),
  exceptional_ability: z.string().nullable().optional(),
  offer_letter: z.string().nullable().optional(),
  start_date: dateTimeSchema.optional().nullable().optional(),
  salary: z
    .object({
      salary_per_unit: z
        .number()
        .transform((v) => Money.fromMinorUnits(v, "USD"))
        .or(moneySchema),
      unit: z.string(),
      equity: z.number(),
    })
    .nullable()
    .optional(),
  candidate_timeline_events: z.array(keyringCandidateTimelineEventSchema).nullable().optional(),
});

export const candidateKeyringMessageSchemaV2 = z.object({
  ccat_score: z.number().nullable().optional(),
  summary: z.string().nullable().optional(),
  test_score: z.number().nullable().optional(),
  notes: z.string().nullable().optional(),
  education: z.string().nullable().optional(),
  employment_history: z.string().nullable().optional(),
  exceptional_ability: z.string().nullable().optional(),
  offer_letter: z.string().nullable().optional(),
  start_date: dateTimeSchema.optional().nullable().optional(),
  salary: z
    .object({
      salary_per_unit: moneySchema,
      unit: z.string(),
      equity: z.number(),
    })
    .nullable()
    .optional(),
  candidate_timeline_events: z.array(keyringCandidateTimelineEventSchema).nullable().optional(),
});

const convertV1ToV2CandidateKeyringMessageSchema = (
  data: z.infer<typeof candidateKeyringMessageSchemaV1>
): z.infer<typeof candidateKeyringMessageSchemaV2> => {
  if (typeof data.salary?.salary_per_unit == "number") {
    return {
      ...data,
      salary: {
        ...data.salary,
        salary_per_unit: zodParse(moneySchema, {
          amount: data.salary.salary_per_unit,
          currency: "USD",
        }),
      },
    };
  } else {
    return data as z.infer<typeof candidateKeyringMessageSchemaV2>;
  }
};

export const candidateKeyringMessageSchema = candidateKeyringMessageSchemaV1.transform(
  convertV1ToV2CandidateKeyringMessageSchema
);

export type CandidateKeyringMessageData = z.infer<typeof candidateKeyringMessageSchema>;

export type CandidateIndexItem = z.infer<typeof candidatePartialSchema>;
export type CandidateAdminItem = z.infer<typeof candidateAdminSchema>;
export type CandidatePartialData = z.infer<typeof candidatePartialSchema>;
export type CandidateCreateParams = Pick<
  CandidatePartialData,
  "name" | "budget_headcount_id" | "hiring_manager_id" | "email"
>;
export type CandidateData = z.infer<typeof candidateShowSchema>;
export type CandidateIndexData = z.infer<typeof CandidateSearchIndexSchema>;
export type CandidateAdminIndex = z.infer<typeof candidateAdminIndexSchema>;
export type CandidateReferenceData = z.infer<typeof candidateReferenceSchema>;
export type CandidateReferenceCreateParams = Pick<CandidateReferenceData, "candidate_id">;
export type CandidateTimelineEventData = z.infer<typeof candidateTimelineEventSchema>;
export type CanidateTimelineEventCreateParams = Pick<
  CandidateTimelineEventData,
  "candidate_id" | "user_id" | "notes"
>;

//api queries
export const getCandidateRoles = async () => {
  const result = await api.get(`${CANDIDATES_BASE_URL}/roles`);
  return result.data;
};

export const getCandidatesAdmin = async (params?: object | null) => {
  const result = await api.get(`${CANDIDATES_BASE_URL}/admin`, { params });
  return zodParse(candidateAdminIndexSchema, result.data);
};

export const getCandidate = async (id: string) => {
  const result = await api.get(`${CANDIDATES_BASE_URL}/${id}`);
  return zodParse(candidateShowSchema, result.data);
};

export const searchCandidates = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [CANDIDATES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return result.data;
};

export const newCandidate = async (candidate: CandidateCreateParams) => {
  const result = await api.post(CANDIDATES_BASE_URL, { candidate });
  return result.data;
};

export const updateCandidate = async (candidate: Partial<CandidateData>) => {
  const result = await api.put(`${CANDIDATES_BASE_URL}/${candidate.id}`, { candidate });
  return result.data;
};

export type UpdateCandidateSummaryParams = {
  overall_feedback: string;
  strengths: string;
  concerns: string;
};
export const updateCandidateSummary = async (
  params: UpdateCandidateSummaryParams
): Promise<{ summary: string }> => {
  const response = await api.put(`${CANDIDATES_BASE_URL}/summary`, params);
  return response.data;
};

export const deleteCandidate = async (id: number) => {
  await api.delete(`${CANDIDATES_BASE_URL}/${id}`);
};

export const getCandidateReferences = async (id: number) => {
  const result = await api.get(`${CANDIDATE_REFERENCES_BASE_URL}/${id}`);
  return result.data;
};

export const createCandidateReference = async (reference: CandidateReferenceCreateParams) => {
  const result = await api.post(CANDIDATE_REFERENCES_BASE_URL, { candidate_reference: reference });
  return result.data;
};

export const updateCandidateReference = async (reference: Partial<CandidateReferenceData>) => {
  await api.put(`${CANDIDATE_REFERENCES_BASE_URL}/${reference.id}`, {
    candidate_reference: reference,
  });
};

export const deleteCandidateReference = async (id: number) => {
  await api.delete(`${CANDIDATE_REFERENCES_BASE_URL}/${id}`);
};

export const createCandidateTimelineEvent = async (event: CanidateTimelineEventCreateParams) => {
  await api.post(`${CANDIDATE_TIMELINE_EVENT_BASE_URL}`, { candidate_timeline_event: event });
};

export const updateCandidateTimelineEvent = async (event: Partial<CandidateTimelineEventData>) => {
  await api.put(`${CANDIDATE_TIMELINE_EVENT_BASE_URL}/${event.id}`, {
    candidate_timeline_event: event,
  });
};

export const generateHiringCSV = async () => {
  const result = await api.get(`${CANDIDATES_BASE_URL}/csv`, {
    responseType: "blob",
  });

  return result.data;
};

// query hooks
export const invalidateCandidate = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [CANDIDATES_BASE_URL],
    });
};

export const useCandidateAdminQuery = (
  indexParams?: Object | null,
  opts?: any
): UseQueryResult<CandidateAdminIndex> => {
  return useQuery({
    queryKey: [CANDIDATES_BASE_URL, indexParams],
    queryFn: () => getCandidatesAdmin(indexParams),
    ...opts,
  });
};

export const useCandidatesSearchQuery = (
  params: SearchParams,
  opts?: any
): UseQueryResult<CandidateIndexData> => {
  return useQuery({
    queryKey: [CANDIDATES_BASE_URL, params],
    queryFn: () => searchCandidates(params),
    ...opts,
  });
};

export const useCandidateRolesQuery = () => {
  return useQuery({
    queryKey: [CANDIDATES_BASE_URL, "roles"],
    queryFn: () => getCandidateRoles(),
  });
};

export const useGetCandidateQuery = (id: string) => {
  return useQuery({
    queryKey: [CANDIDATES_BASE_URL, Number(id)],
    queryFn: () => getCandidate(id),
  });
};

export const useCreateCandidate = () => {
  return useMutation({
    mutationFn: newCandidate,
    onSuccess: invalidateCandidate(),
  });
};

export const useUpdateCandidate = (id?: number) => {
  return useMutation({
    mutationFn: updateCandidate,
    onSuccess: invalidateCandidate(),
  });
};

export const useUpdateCandidateSummary = () => {
  return useMutation({
    mutationFn: updateCandidateSummary,
    onSuccess: invalidateCandidate(),
  });
};

export const useDeleteCandidate = () => {
  return useMutation({
    mutationFn: deleteCandidate,
  });
};

export const useGetCandidateReferences = (id: number) => {
  return useQuery({
    queryKey: [CANDIDATES_BASE_URL, id, "references"],
    queryFn: () => getCandidateReferences(id),
  });
};

export const useCreateCandidateReference = () => {
  return useMutation({
    mutationFn: createCandidateReference,
    onSuccess: invalidateCandidate(),
  });
};

export const useUpdateCandidateReference = () => {
  return useMutation({
    mutationFn: updateCandidateReference,
    onSuccess: invalidateCandidate(),
  });
};

export const useDeleteCandidateReference = () => {
  return useMutation({
    mutationFn: deleteCandidateReference,
    onSuccess: invalidateCandidate(),
  });
};

export const useCreateCandidateTimelineEvent = () => {
  return useMutation({
    mutationFn: createCandidateTimelineEvent,
    onSuccess: invalidateCandidate(),
  });
};

export const useUpdateCandidateTimelineEvent = () => {
  return useMutation({
    mutationFn: updateCandidateTimelineEvent,
    onSuccess: invalidateCandidate(),
  });
};
