import React from "react";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme } from "victory";
import { useColorModeValue } from "@chakra-ui/react";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const generateMonthRange = (start: Date, end: Date) => {
  const dates: Date[] = [];
  const current = new Date(start);
  dates.push(new Date(current));

  if (current.getDate() !== 1) {
    current.setDate(1);
    current.setMonth(current.getMonth() + 1);
  }
  while (
    current.getFullYear() < end.getFullYear() ||
    (current.getFullYear() === end.getFullYear() && current.getMonth() <= end.getMonth())
  ) {
    dates.push(new Date(current));
    current.setMonth(current.getMonth() + 1);
  }
  return dates;
};

export const SkipLineChart = ({ data }) => {
  const startDate = new Date(Object.keys(data)[0]);
  const endDate = new Date(Object.keys(data)[Object.keys(data).length - 1]);
  const dates = generateMonthRange(startDate, endDate);
  const tickLabels = dates.map((date) => monthNames[date.getMonth()]);
  const graphData = Object.entries(data).map(([date, count]) => ({
    x: new Date(date),
    y: count,
  }));

  const labelText = useColorModeValue("#718096", "#CBD5E0");

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      width={600}
      height={400}
      domainPadding={{ y: 15, x: 2 }}>
      <VictoryAxis
        tickValues={dates}
        tickFormat={tickLabels}
        style={{
          axisLabel: { padding: 30, fontSize: 12, fill: labelText },
          tickLabels: { fontSize: 10, fill: labelText },
          grid: { stroke: "transparent" },
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axisLabel: { padding: 30, fontSize: 12, fill: labelText },
          tickLabels: { fontSize: 9, fill: labelText },
          grid: { stroke: "transparent" },
        }}
        tickFormat={(tick) => `${tick}`}
        label="Percentage of Surveys Skipped"
      />
      <VictoryLine
        data={graphData}
        x="x"
        y="y"
        style={{
          data: { stroke: "#ED8936" },
        }}
      />
    </VictoryChart>
  );
};
