import React, { useMemo } from "react";
import {
  Collection,
  EditableDate,
  EditableSelect,
  EditableText,
  Searchbar,
  useCollection,
} from "@sciencecorp/helix-components";
import { Avatar, Flex, Heading, HStack, Box, Link, Text, VStack, Badge } from "@chakra-ui/react";
import {
  InventoryItemShowData,
  useUpdateInventoryItems,
  useSearchInventoryItems,
} from "../../../../api/inventory_item";
import { useUserOptions } from "../../../../api/options";
import { Link as RouterLink } from "react-router-dom";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { SpendingAuthorityPreview } from "../../../Purchasing/SpendingAuthoritySelectTree";
import { EmptyState } from "../../../shared/EmptyState";
import { DateTime } from "luxon";

export const AssignmentTable = ({ inventoryId }: { inventoryId: number }) => {
  const { pagination, onPagination, order, onOrder } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();
  const userOptions = useUserOptions();
  const { mutate: updateInventoryItem } = useUpdateInventoryItems();
  const { data: inventoryItems, isLoading } = useSearchInventoryItems({
    term: search || "*",
    pagination,
    filters: { inventory_id: inventoryId },
    order: order,
  });

  const columns = useMemo(
    () => [
      {
        label: "User",
        orderOptions: { orderKey: "user_name" },
        render: (inventoryItem: InventoryItemShowData) => {
          return (
            <EditableSelect
              persistentEdit={true}
              preview={
                inventoryItem.user ? (
                  <Link as={RouterLink} to={`/users/${inventoryItem.user.id}`}>
                    <HStack>
                      <Avatar size="sm" src={inventoryItem.user.picture_uri} />
                      <Text>{inventoryItem.user.name}</Text>
                    </HStack>
                  </Link>
                ) : (
                  <Text as="i" opacity="0.7">
                    Select User
                  </Text>
                )
              }
              options={[...userOptions, { label: "Unassigned", value: -1 }]}
              onSubmit={(value) => {
                if (value === -1) {
                  updateInventoryItem({ id: inventoryItem.id, user_id: null });
                } else if (value) {
                  updateInventoryItem({ id: inventoryItem.id, user_id: Number(value) });
                }
              }}
            />
          );
        },
      },
      {
        label: "Spending Authority",
        render: (inventoryItem: InventoryItemShowData) =>
          inventoryItem.spending_authority ? (
            <SpendingAuthorityPreview
              spendingAuthorityId={inventoryItem.spending_authority.id}
              spendingAuthorityType={inventoryItem.spending_authority.type}
              maxWidth="14ch"
            />
          ) : (
            <Text>N/A</Text>
          ),
      },

      {
        label: "Unique Identifier",
        render: (inventoryItem: InventoryItemShowData) => (
          <VStack align="start">
            <EditableText
              defaultValue={inventoryItem.unique_id || "N/A"}
              onSubmit={(value) => {
                if (value) updateInventoryItem({ id: inventoryItem.id, unique_id: value });
              }}
            />
          </VStack>
        ),
      },
      {
        label: "Assignment Date",
        orderOptions: { orderKey: "assignment_date" },
        render: (inventoryItem: InventoryItemShowData) => (
          <EditableDate
            persistentEdit={true}
            defaultValue={
              inventoryItem.assignment_date
                ? DateTime.fromISO(inventoryItem.assignment_date.toString()).toISODate() ||
                  undefined
                : undefined
            }
            placeholder="Assignment Date"
            onSubmit={(value) => {
              if (value) {
                updateInventoryItem({
                  id: inventoryItem.id,
                  assignment_date: DateTime.fromISO(value),
                });
              } else if (value === null) {
                updateInventoryItem({ id: inventoryItem.id, assignment_date: null });
              }
            }}
          />
        ),
      },
    ],
    [inventoryItems, userOptions]
  );

  return (
    <Flex
      minH="xs"
      direction="column"
      p={4}
      gap={4}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      w="100%">
      <HStack justify="space-between">
        <Heading size="md" fontWeight="semibold">
          Item Assignments
          <Badge colorScheme="teal" variant="subtle" ml={4}>
            Inventory Manager/Owner
          </Badge>
        </Heading>
        <Box>
          <Searchbar size="sm" onChange={(value) => debouncedSearch(value)} onSelect={() => {}} />
        </Box>
      </HStack>
      {isLoading || (inventoryItems?.results?.length || 0) > 0 ? (
        <Collection
          items={inventoryItems?.results || []}
          isLoading={isLoading}
          order={order}
          onOrder={onOrder}
          pagination={inventoryItems?.pagination || pagination}
          onPagination={onPagination}
          columns={columns}
        />
      ) : (
        <EmptyState title="No inventory items found." />
      )}
    </Flex>
  );
};
