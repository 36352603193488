import { Box, Button, Icon } from "@chakra-ui/react";
import { OmnisearchBar } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useOminiSearch } from "../../api/omni_search/omni_search";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { AiOutlineSearch } from "react-icons/ai";
import { OminisearchResults } from "./OmnisearchResults";
import { OmnisearchFilterBar } from "./OmnisearchFilterBar";
import { OmniSearchbarFilterItem } from "./types";
import { omnisearchBarFilterItems } from "./utils";

export interface OmnisearchContentProps {
  initialRef?: any;
  onClose: () => void;
}
export const OminiSearchContent = (props: OmnisearchContentProps) => {
  const { debouncedSearch, search } = useDebouncedSearch();
  const [modelApp, setModelApp] = useState<OmniSearchbarFilterItem | null>(null);

  const { data, isLoading } = useOminiSearch({
    term: search || "",
    model_app: modelApp ? [modelApp.value] : undefined,
    order: { model_type: "desc" },
  });

  return (
    <>
      <OmnisearchBar
        initialRef={props.initialRef}
        onClose={props.onClose}
        onChange={debouncedSearch}
        filterItem={
          modelApp && {
            active: true,
            label: modelApp.label,
            leftIcon: modelApp.icon,
            onClick: () => setModelApp(null),
          }
        }
      />
      <OmnisearchFilterBar
        setModelApp={setModelApp}
        omnisearchBarFilterItems={omnisearchBarFilterItems}
      />
      <OminisearchResults
        isLoading={isLoading}
        results={data?.results?.slice(0, 5)}
        itemsPerPage={5}
      />
      <Box marginTop={[10]}>
        <Link to={`/omnisearch?term=${search}`}>
          <Button
            variant="ghost"
            textAlign="left"
            leftIcon={<Icon as={AiOutlineSearch} boxSize="6" />}
            justifyContent="start"
            onClick={props.onClose}
            width="full"
            size={["sm", "md"]}>
            Advanced Search
          </Button>
        </Link>
      </Box>
    </>
  );
};
