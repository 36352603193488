import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { Money, MoneyFormatOptions } from "../helpers/Money";

export interface MoneyTextProps extends Omit<TextProps, "children"> {
  as?: "span" | "div" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  money?: Money;
  formatOptions?: Partial<MoneyFormatOptions>;
}
export const MoneyText = ({
  money,
  formatOptions = {},
  as = "p",
  ...restProps
}: MoneyTextProps): JSX.Element => {
  if (money === null || money === undefined) {
    return <>&mdash;</>;
  }
  return (
    <Text data-testid={"money-text"} as={as} {...restProps}>
      {money.format(formatOptions)}
    </Text>
  );
};
