import React from "react";
import {
  Project,
  projectComparator,
  useGetProjectSummaries,
  useSearchProjects,
} from "../../../api/planning/projects";
import { Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { SidebarList } from "../../Credentials/util";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { AddIcon } from "@chakra-ui/icons";
import { ProjectModal } from "./ProjectModal";
import _ from "lodash";
import { DateTime } from "luxon";

interface ProjectListProps {
  selectedProject: Project;
  arcOptions: { label: string; value: number }[];
}

export const ProjectList: React.FC<ProjectListProps> = ({ selectedProject, arcOptions }) => {
  const { debouncedSearch, search } = useDebouncedSearch();
  const { data, isLoading } = useSearchProjects({
    term: search || "*",
    pagination: { per_page: -1 },
    filters: {},
    endDate: DateTime.now().endOf("year"),
  });

  const { data: projectSummaries, isLoading: projectSummariesIsLoading } = useGetProjectSummaries();

  const groupedAndSortedProjects = React.useMemo(() => {
    if (isLoading || !data) return [];

    const projects = data.results || [];
    // Group projects by 'arc_id' and sort within each group alphabetically by project title
    const grouped = _.groupBy(projects, "arc_id");
    const sortedGroups = Object.keys(grouped).map((arcId) => {
      const sortedProjects = grouped[arcId].sort(projectComparator);
      return {
        arcId,
        projects: sortedProjects,
      };
    });

    return sortedGroups;
  }, [data, isLoading, selectedProject]);

  const currentUserQuery = useCurrentUserQuery();
  const isUserProjectManager = userHasRole(currentUserQuery, "project_manager");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SidebarList
        title={
          <Flex>
            <Text flexGrow={1}>Projects</Text>
            {isUserProjectManager && (
              <IconButton
                aria-label="Add Project"
                variant="solid"
                icon={<AddIcon />}
                onClick={onOpen}
              />
            )}
          </Flex>
        }
        url="/planning/projects"
        breadcrumbTitle="Back to Planning"
        breadcrumbUrl="/planning"
        debouncedSearch={debouncedSearch}
        data={groupedAndSortedProjects
          .flatMap((group) => group.projects)
          .map((project) => ({
            id: project.id,
            name: project.title,
            description: `${
              arcOptions.find((option) => option.value === project.arc_id)?.label ||
              "No associated arc"
            } \n Project Spend: ${projectSummaries?.[project.id].spent_amount.format()}`,
            status: project.status,
          }))}
        selectedItemId={selectedProject.id}
      />
      <ProjectModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
