import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { userMinimalSchema } from "./user";
import { dateTimeSchema } from "../helpers/dateTime";
import { meetingAttendeeShowSchema } from "./meeting_attendees";

export const MEETING_NOTES_BASE_URL = "/meeting_notes";

const meetingNoteSchema = z.object({
  id: z.number(),
  content: z.record(z.unknown()).nullable(),
  title: z.string().nullable(),
  meeting_time: dateTimeSchema,
  user_id: z.number(),
});

const meetingNoteSchemaWithAttendees = meetingNoteSchema.omit({ user_id: true }).extend({
  meeting_attendees: z.array(meetingAttendeeShowSchema),
  user: z.lazy(() => userMinimalSchema),
});

export type MeetingNoteData = z.infer<typeof meetingNoteSchema>;
export type MeetingNoteDataWithAttendees = z.infer<typeof meetingNoteSchemaWithAttendees>;
export type MeetingNoteCreateParams = Pick<
  MeetingNoteData,
  "title" | "meeting_time" | "user_id"
> & {
  user_id: number;
};

export const getMeetingNote = async (id: number) => {
  const result = await api.get(`${MEETING_NOTES_BASE_URL}/${id}`);
  return zodParse(meetingNoteSchemaWithAttendees, result.data);
};

const searchMeetingNotes = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [MEETING_NOTES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(meetingNoteSchemaWithAttendees), result.data);
};

export const createMeetingNotes = async (meeting_note: MeetingNoteCreateParams) => {
  const result = await api.post(MEETING_NOTES_BASE_URL, { meeting_note });
  return zodParse(meetingNoteSchemaWithAttendees, result.data);
};
export const updateMeetingNotes = async (meeting_note: Partial<MeetingNoteData>) => {
  const result = await api.put(`${MEETING_NOTES_BASE_URL}/${meeting_note.id}`, { meeting_note });
  return zodParse(meetingNoteSchemaWithAttendees, result.data);
};

export const deleteMeetingNotes = async (meeting_notes_id: number) => {
  await api.delete(`${MEETING_NOTES_BASE_URL}/${meeting_notes_id}`);
};

/** hooks */

export const invalidateMeetingNotes = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [MEETING_NOTES_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useGetMeetingNote = (id: number) => {
  const result = useQuery({
    queryKey: [MEETING_NOTES_BASE_URL, id],
    queryFn: () => getMeetingNote(id),
  });
  return result;
};

export const useSearchMeeting = (params: SearchParams) => {
  return useQuery({
    queryKey: [MEETING_NOTES_BASE_URL, params],
    queryFn: () => searchMeetingNotes(params),
  });
};

export const useCreateMeetingNotes = () => {
  return useMutation({
    mutationFn: createMeetingNotes,
    onSuccess: invalidateMeetingNotes(),
  });
};
export const useUpdateMeetingNotes = (meetingNotesId: number) => {
  return useMutation({
    mutationFn: updateMeetingNotes,
    onSuccess: invalidateMeetingNotes(meetingNotesId),
  });
};
export const useDeleteMeetingNotes = () => {
  return useMutation({
    mutationFn: deleteMeetingNotes,
    onSuccess: invalidateMeetingNotes(),
  });
};
