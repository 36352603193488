import {
  Avatar,
  Box,
  Flex,
  HStack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Collection, RecordLink, StatusSelect } from "@sciencecorp/helix-components";

import {
  VacancyData,
  invalidateBudgetHeadcount,
  updateBudgetHeadcount,
} from "../../api/budget_headcounts";
import { titleize } from "inflection";
import { useUpdateBudgetHeadcount } from "../../api/budget_headcounts";

import { EditableLink } from "../Purchasing/Purchase/components/EditableLink";

export const VacanciesTab = ({ data, isLoading, onPagination, pagination, onOrder, order }) => {
  const { mutate: updateVacancy, isLoading: isUpdateLoading } = useUpdateBudgetHeadcount(() =>
    invalidateBudgetHeadcount()
  );
  const [loadingField, setLoadingField] = useState("");
  const isLoadingField = (field: string) => {
    return loadingField === field && isUpdateLoading;
  };

  const toast = useToast();

  const columns = useMemo(
    () => [
      {
        label: "Role",
        weight: 0.5,
        render: (vacancy: VacancyData) => (
          <RecordLink
            link={`/hiring/vacancies/${vacancy.id}`}
            identifier={vacancy.archetype.name}
          />
        ),
      },
      {
        label: "Team",
        weight: 0.5,
        render: (vacancy: VacancyData) => (
          <RecordLink
            type={vacancy.budgetable.type}
            link={`/teams/${vacancy.budgetable.id}/readme`}
            identifier={vacancy.budgetable.name}
          />
        ),
      },
      {
        label: "Hired",
        weight: 0.25,
        render: (vacancy: VacancyData) => `${vacancy.hired_count} / ${vacancy.total_people}`,
      },
      {
        label: "Level",
        weight: 0.25,
        render: (vacancy: VacancyData) => vacancy.level || "N/A",
      },
      {
        label: "Priority",
        weight: 0.25,

        render: (vacancy: VacancyData) => titleize(vacancy.priority),
      },
      {
        label: "Description",
        weight: 0.25,
        render: (vacancy: VacancyData) => (
          <EditableLink
            url={vacancy.job_description || ""}
            onSubmit={(value) => {
              if (value)
                updateBudgetHeadcount({
                  id: vacancy.id,
                  job_description: value,
                });
            }}
            defaultValue={vacancy.job_description || ""}
          />
        ),
      },

      {
        label: "Status",
        weight: 0.25,
        render: (vacancy: VacancyData) => (
          <StatusSelect
            variant="tag"
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            status={
              vacancy.active
                ? { value: "active", label: "Active", color: "green" }
                : { value: "inactive", label: "Inactive", color: "red" }
            }
            onSubmit={(value) => {
              const isActive = value === "active";
              updateVacancy({ id: vacancy.id, active: isActive });
            }}
          />
        ),
      },
    ],
    [data]
  );

  return (
    <Collection
      key={"vacancies"}
      columns={columns}
      isLoading={isLoading}
      items={data?.results || []}
      pagination={data?.pagination || pagination}
      order={order}
      onPagination={onPagination}
      onOrder={onOrder}
    />
  );
};
