import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { TreeOptionValue, TreeSelectOption } from "../components/shared/TreeSelect";
import { BUDGETS_BASE_URL } from "./budget";
import { zodParse } from "./zodParse";

// From the API
export const nonRecursiveBudgetTreeSchema = z.object({
  budget_id: z.number(),
  budgetable_type: z.enum(["Project", "Team", "User", "CapitalEquipment"]),
  budgetable_name: z.string(),
});

export const budgetTreeSchema = nonRecursiveBudgetTreeSchema.extend({
  nested_budgets: z.array(z.lazy(() => budgetTreeSchema)).optional(),
});

export type BudgetTree = z.infer<typeof nonRecursiveBudgetTreeSchema> & {
  nested_budgets: BudgetTree[] | undefined;
};

export type BudgetKey = number;
export type BudgetOption = TreeSelectOption<BudgetKey> | { label: string; value: BudgetValue };
export type BudgetValue = TreeOptionValue<BudgetKey>;

export const buildBudgetOptions = (tree: BudgetTree, emptyOptionText?: string): BudgetOption => {
  const value: BudgetValue = tree.budget_id;
  return {
    label: tree.budgetable_name,
    value: value,
    type: tree.budgetable_type,
    children: tree.nested_budgets
      ? [
          ...(emptyOptionText
            ? [
                {
                  label: emptyOptionText,
                  value: value,
                },
              ]
            : []),
          ...tree.nested_budgets.map(
            (item) => buildBudgetOptions(item) as TreeSelectOption<number>
          ),
        ]
      : undefined,
  };
};

export const getBudgetTree = async (): Promise<BudgetTree[]> => {
  const result = await api.get(`${BUDGETS_BASE_URL}/tree`);
  return zodParse(budgetTreeSchema.array(), result.data);
};

export const useBudgetTreeOptions = (searchTerm?: string | undefined, emptyOptionText?: string) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, "tree", searchTerm],
    queryFn: async (): Promise<BudgetOption[]> => {
      const tree = await getBudgetTree();
      return tree.map((budgetTree) => buildBudgetOptions(budgetTree, emptyOptionText));
    },
  });
};

export const getUnassociatedBudgetTree = async (): Promise<BudgetTree[]> => {
  const result = await api.get(`${BUDGETS_BASE_URL}/unassociated_budget_tree`);
  return zodParse(budgetTreeSchema.array(), result.data);
};

export const useGetUnassociatedBudgetTree = (
  searchTerm?: string | undefined,
  emptyOptionText?: string
) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, "tree", searchTerm],
    queryFn: async (): Promise<BudgetOption[]> => {
      const tree = await getUnassociatedBudgetTree();
      return tree.map((budgetTree) => buildBudgetOptions(budgetTree, emptyOptionText));
    },
  });
};
