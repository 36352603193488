import { IconButton, InputGroup, InputRightElement, Spinner, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import ResizeTextarea from "react-textarea-autosize";

export const ChatInput = (props: { sendUserInput: (input: string) => void; thinking: boolean }) => {
  const [chatInput, setChatInput] = useState("");

  const submitPrompt = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (chatInput && !props.thinking) {
      props.sendUserInput(chatInput);
      setChatInput("");
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      submitPrompt(e);
    }
  };

  return (
    <form onSubmit={submitPrompt} style={{ width: "100%" }}>
      <InputGroup>
        <Textarea
          autoFocus
          as={ResizeTextarea}
          minH="unset"
          overflow="hidden"
          w="100%"
          resize="none"
          value={chatInput}
          onKeyDown={onKeyDown}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder="Ask me anything..."
          minRows={1}
        />
        <InputRightElement style={{ top: "auto", bottom: 0 }}>
          {props.thinking ? (
            <Spinner />
          ) : (
            <IconButton
              h="1.75rem"
              size="sm"
              aria-label="Ask Chimaera"
              icon={<RiSendPlaneFill />}
              variant="ghost"
              onClick={submitPrompt}
            />
          )}
        </InputRightElement>
      </InputGroup>
    </form>
  );
};
