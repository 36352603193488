import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { EditableText } from "@sciencecorp/helix-components";
import React from "react";
import { useCreateServiceRequestEvent } from "../../../api/service_request_events";
import { ServiceRequestShowData, useUpdateServiceRequest } from "../../../api/service_requests";
import { UserLoggedInData } from "../../../api/user";
import { MoneyText } from "../../MoneyText";
import { ActionRequired } from "./ActionRequired";
import { PurchaseRequestAction } from "./PurchaseRequestAction";
import { Money } from "../../../helpers/Money";
import { EditableMoney } from "../../EditableMoney";

type ServiceRequestStatusProps = {
  serviceRequest: ServiceRequestShowData;
  purchaseCost: Money;
  currentUser: UserLoggedInData;
  isCeo?: boolean;
};

export const ServiceRequestStatus = ({
  serviceRequest,
  purchaseCost,
  currentUser,
}: ServiceRequestStatusProps) => {
  const { mutate: createServiceRequestEvent } = useCreateServiceRequestEvent(serviceRequest.id);
  const { mutate: updateServiceRequest } = useUpdateServiceRequest(serviceRequest.id);
  const editable = serviceRequest.user_in_fulfilling_team;

  const showPurchaseRequestAction = () => {
    // fulfilling team user view to request approval
    if (
      serviceRequest.status === "new" &&
      serviceRequest.service_cost.isPos() &&
      serviceRequest.user_in_fulfilling_team
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <VStack
        width="100%"
        align="start"
        p={6}
        spacing={3}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="lg">
        <HStack justify="space-between" width="100%">
          <Text>Cost of Purchases:</Text>
          <MoneyText fontWeight="medium" money={purchaseCost} />
        </HStack>
        <Divider />
        <HStack justify="space-between" width="100%">
          <Text>Service Cost:</Text>
          {editable ? (
            <EditableMoney
              persistentEdit={true}
              defaultValue={serviceRequest.service_cost}
              onSubmit={(value) => {
                if (currentUser && value) {
                  //TODO: This event should be created on the backend to prevent partial updates.
                  createServiceRequestEvent(
                    {
                      service_request_id: serviceRequest.id,
                      user_id: currentUser.id,
                      event_type: "cost",
                      event_info: {
                        description: "updated the service cost to",
                        cost: value,
                      },
                      slack_message: `updated the service cost to ${value.format}`,
                    },
                    {
                      onSuccess: () => {
                        updateServiceRequest({
                          id: serviceRequest.id,
                          service_cost: value,
                        });
                      },
                    }
                  );
                }
              }}
            />
          ) : (
            <MoneyText fontWeight="medium" money={serviceRequest.service_cost} />
          )}
        </HStack>

        {serviceRequest.requires_approval && serviceRequest.current_user_can_approve && (
          <ActionRequired serviceRequest={serviceRequest} />
        )}

        <Divider />
        <HStack justify="space-between" width="100%">
          <Text>Total Cost:</Text>
          <MoneyText fontWeight="medium" money={purchaseCost.add(serviceRequest.service_cost)} />
        </HStack>
        <Divider />
      </VStack>
      {showPurchaseRequestAction() && (
        <PurchaseRequestAction
          serviceRequest={serviceRequest}
          cost={serviceRequest.service_cost}
          currentUser={currentUser}
        />
      )}
    </>
  );
};
