import React from "react";
import { SpendingAuthoritySelect } from "../../../Purchasing/SpendingAuthoritySelectTree";
import { FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { FormValuesType } from "../CheckoutModal";

type SelectSpendingAuthorityProps = {
  control: Control<FormValuesType, any>;
  setValue: UseFormSetValue<FormValuesType>;
  formValues: FormValuesType;
};

export const SelectSpendingAuthority = ({
  control,
  setValue,
  formValues,
}: SelectSpendingAuthorityProps) => {
  return formValues.purpose === "spending_authority" ? (
    <Controller
      name="spending_authority"
      control={control}
      rules={{ required: true }}
      render={() => (
        <FormControl>
          <FormLabel>Select Spending Authority</FormLabel>

          <SpendingAuthoritySelect
            onChange={(spendingAuthority) => {
              setValue("spending_authority", spendingAuthority);
            }}
            spendingAuthority={formValues.spending_authority}
          />
        </FormControl>
      )}
    />
  ) : (
    <Text>Are you sure you want to remove these items from inventory?</Text>
  );
};
