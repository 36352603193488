import { z } from "zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidateMeetingNotes } from "./meeting_notes";
import { userMinimalSchema } from "./user";

export const ATTENDEE_NOTES_BASE_URL = "/meeting_attendees";

const meetingAttendeeSchema = z.object({
  id: z.number(),
  meeting_note_id: z.number(),
  user_id: z.number(),
});

export const meetingAttendeeShowSchema = meetingAttendeeSchema.omit({ user_id: true }).extend({
  user: userMinimalSchema,
});

export type MeetingAttendeeData = z.infer<typeof meetingAttendeeSchema>;
export type MeetingAttendeeShowData = z.infer<typeof meetingAttendeeShowSchema>;
export type MeetingAttendeeCreateParams = Pick<MeetingAttendeeData, "meeting_note_id" | "user_id">;

export const createMeetingAttendee = async (meeting_attendees: MeetingAttendeeCreateParams) => {
  const result = await api.post(ATTENDEE_NOTES_BASE_URL, { meeting_attendees });
  return zodParse(meetingAttendeeShowSchema, result.data);
};

export const deleteMeetingAttendee = async (attendee_id: number) => {
  await api.delete(`${ATTENDEE_NOTES_BASE_URL}/${attendee_id}`);
};

/** hooks */

export const useCreateMeetingAttendee = () => {
  return useMutation({
    mutationFn: createMeetingAttendee,
    onSuccess: invalidateMeetingNotes(),
  });
};

export const useDeleteMeetingAttendee = () => {
  return useMutation({
    mutationFn: deleteMeetingAttendee,
    onSuccess: invalidateMeetingNotes(),
  });
};
