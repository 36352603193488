import React, { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { InventoryShowData } from "../../../../api/inventory";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  useUpdateInventoryVendor,
  useCreateInventoryVendor,
} from "../../../../api/inventory_vendor";
import { useVendorOptions } from "../../../../api/options";

type InventoryVendorModalProps = {
  inventory: InventoryShowData;
};

export const InventoryVendorModal = ({ inventory }: InventoryVendorModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: createInventoryVendor } = useCreateInventoryVendor(inventory.id);
  const { mutate: updateInventoryVendor } = useUpdateInventoryVendor(inventory.id);
  const vendorOptions = useVendorOptions();

  const [selectedVendor, setSelectedVendor] = useState<string | null>(null);
  const [link, setLink] = useState<string>("");

  const handleClose = () => {
    setSelectedVendor(null);
    setLink("");
    onClose();
  };

  const handleSubmit = () => {
    createInventoryVendor({
      vendor_id: Number(selectedVendor),
      inventory_id: inventory.id,
      link: link || null,
    });

    handleClose();
  };

  return (
    <>
      <IconButton aria-label="add inventory vendor" icon={<AddIcon />} size="xs" onClick={onOpen} />

      <FormModal
        isOpen={isOpen}
        onClose={handleClose}
        title="Add Supplier"
        handleSubmit={handleSubmit}
        submitButtonColorSchema="teal"
        submitButtonDisabled={!selectedVendor}>
        <Flex direction="column" w="100%" gap={4}>
          <FormControl>
            <FormLabel>Vendor</FormLabel>
            <Select
              options={vendorOptions}
              value={selectedVendor?.toString()}
              onChange={(value) => setSelectedVendor(value.toString())}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Add Link to Item (optional)</FormLabel>
            <Input placeholder="Type here" value={link} onChange={(e) => setLink(e.target.value)} />
          </FormControl>
        </Flex>
      </FormModal>
    </>
  );
};
