import React, { useMemo, useState } from "react";

import { InfoIcon } from "@chakra-ui/icons";
import {
  Center,
  Tooltip as ChakraTooltip,
  Flex,
  Heading,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import { useSecureMode, useSecureModeModal } from "@sciencecorp/helix-components";
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js";
import _ from "lodash";
import { DateTime } from "luxon";
import { Bar } from "react-chartjs-2";
import { useGetCashBalanaceSummary } from "../../../../api/finance";
import { useCurrentUserQuery } from "../../../../api/user";
import {
  Compensation,
  HourlyCompensation,
  SalaryCompensation,
  useGetUserCompensations,
  userCompensationMessageSchema,
} from "../../../../api/user_compensation";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { KeyringZodHelper } from "../../../../helpers/KeyringZodHelper";
import { Money } from "../../../../helpers/Money";
import { useSecureModeEffect } from "../../../Encryption/hooks";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const CashBalanceChart = () => {
  const currency = useCurrency();
  const userQuery = useCurrentUserQuery();
  const {
    data: userCompensations,
    isLoading: isLoadingUserCompensations,
    isSuccess: isSuccessUserCompensations,
  } = useGetUserCompensations();
  const { data: currentUser, isSuccess: currentUserIsSuccess } = useCurrentUserQuery();
  const { data, isLoading, isError } = useGetCashBalanaceSummary();
  const compensationKeyringHelper = new KeyringZodHelper(userCompensationMessageSchema);
  const [decryptedCompensation, setDecryptedCompensation] = useState<Compensation[]>([]);

  const [_smState, smDispatch] = useSecureMode();

  useSecureModeModal(
    "Enter your PIN to use employee compensation in cash balance predictions.",
    userQuery.data?.encrypted_private_key
  );

  useSecureModeEffect(
    (privateKey) => {
      if (isSuccessUserCompensations && currentUserIsSuccess) {
        const encryptedToUser = userCompensations.filter((item) =>
          item.keyring_message.matching_users.includes(currentUser.id)
        );

        encryptedToUser.forEach((comp) => {
          if (comp.keyring_message) {
            const decryptedFeedback = compensationKeyringHelper.decrypt(
              comp.keyring_message.content,
              privateKey,
              smDispatch
            );
            setDecryptedCompensation((prev) => [...prev, decryptedFeedback.data]);
          }
        });
      }
    },
    [isSuccessUserCompensations]
  );

  const teal500 = useToken("colors", "teal.500");
  const gray = useColorModeValue(useToken("colors", "gray.300"), useToken("colors", "gray.500"));
  const axisColor = useColorModeValue("white", "black");
  const gridLineColor = useColorModeValue(
    useToken("colors", "gray.100"),
    useToken("colors", "gray.400")
  );

  const { monthlySalary, monthlyHourly } = useMemo(
    () =>
      decryptedCompensation.length > 0
        ? processUserCompensations(decryptedCompensation)
        : { monthlySalary: Money.zero(currency), monthlyHourly: Money.zero(currency) },
    [decryptedCompensation]
  );

  const textColor = useColorModeValue(useToken("colors", "black"), useToken("colors", "white"));
  const tooltipText = useMemo(
    () =>
      decryptedCompensation.length > 0
        ? "Prediction includes current balance, monthly payments, and employee compensation (increased by 17% for benefits and taxes)."
        : "Prediction includes current balance and monthly payments",
    [decryptedCompensation]
  );

  if (isLoading) return <Spinner />;
  if (isError) return <Center>Error loading data</Center>;

  const monthlyBalanceData = data.balances;
  if (data) {
    let startDate = DateTime.now();
    const endDate = DateTime.now().endOf("year");
    let startingBalance = data.current_balance;

    while (startDate <= endDate) {
      const date = startDate.toFormat("yyyy-MM");
      if (data.monthly_payments[date]) {
        const newBalance = startingBalance.subtract(
          data.monthly_payments[date],
          monthlySalary.add(monthlyHourly).times(1.17)
        );

        monthlyBalanceData[date] = newBalance;
        startingBalance = newBalance;
      }
      startDate = startDate.plus({ months: 1 });
    }
  }

  const months = Object.keys(monthlyBalanceData);

  const chartValues = _.values(monthlyBalanceData).map((balance) => balance.majorUnits.toNumber());

  const chartData = {
    labels: months.map((date) => DateTime.fromISO(date).toFormat("LLL")),
    datasets: [
      {
        label: "Balance ($)",
        data: chartValues,
        backgroundColor: months.map((date) =>
          DateTime.fromISO(date).endOf("month") < DateTime.now() ? teal500 : gray
        ),
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: false,
        },
        ticks: {
          padding: 10,
          color: textColor,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Balance ($)",
          color: textColor,
        },
        ticks: {
          callback: (value) => Money.fromMajorUnitString(value.toString(), currency).format(),
          axisColor: axisColor,
          color: textColor,
        },
        grid: {
          color: gridLineColor,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom" as "bottom",
        labels: {
          fontColor: textColor,
          generateLabels: () => [
            {
              text: "Historical Balance",
              fillStyle: "teal",
              strokeStyle: "teal",
              fontColor: textColor,
            },
            {
              text: "Balance After Committments",
              fillStyle: gray,
              strokeStyle: gray,
              fontColor: textColor,
            },
          ],
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => Money.fromMajorUnitString(context.raw.toString(), currency).format(),
        },
      },
    },
  };

  return (
    <Flex direction="column" border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
      <HStack align="center" mb={4}>
        <Heading as="h3" size="md">
          Closing Cash Balance
        </Heading>
        <ChakraTooltip label={tooltipText} closeDelay={500}>
          <InfoIcon />
        </ChakraTooltip>
        {decryptedCompensation.length > 0 && (
          <Text as={"i"} fontSize="sm">
            includes user compensation
          </Text>
        )}
      </HStack>
      <Bar data={chartData} options={options} />
    </Flex>
  );
};

// TODO Add currency conversion
const processUserCompensations = (userCompensations: Compensation[]) => {
  const currency = useCurrency();
  const salaryCompensations = userCompensations.filter(
    (comp) => comp.payType === "salary_v2"
  ) as SalaryCompensation[];
  const hourlyCompensations = userCompensations.filter(
    (comp) => comp.payType === "hourly_v2"
  ) as HourlyCompensation[];

  const salaryCompensationMonthly = Money.sum(
    Money.zero(currency),
    ...salaryCompensations.map((comp) => comp.yearlySalary)
  ).div(12);

  const hourlyCompensationMonthly = Money.sum(
    Money.zero(currency),
    ...hourlyCompensations.map((comp) => {
      const hourlyRate = comp.hourlyRate;
      const averageHours = (comp.maxHoursPerWeek! + comp.minHoursPerWeek!) / 2;
      return hourlyRate!.times(averageHours).times(4.33);
    })
  );

  return { monthlySalary: salaryCompensationMonthly, monthlyHourly: hourlyCompensationMonthly };
};
