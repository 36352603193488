import { useMemo } from "react";
import { useCurrentUserQuery } from "../../api/user";

export const useCurrentUserRoles = () => {
  const { data, ...rest } = useCurrentUserQuery();
  const roles = useMemo(() => data?.roles?.map(({ name }) => name), [data]);
  return { roles, ...rest };
};
export const useIsCurrentUserHRAdmin = () => {
  const { roles } = useCurrentUserRoles();
  return roles?.some((value) => value === "hr_admin");
};
export const useIsCurrentUserPurchasingAdmin = () => {
  const { roles } = useCurrentUserRoles();
  return roles?.some((value) => value === "purchasing_admin");
};
export const useIsCurrentFinanceAdmin = () => {
  const { roles } = useCurrentUserRoles();
  return roles?.some((value) => value === "finance_admin");
};
