import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfirmationModal } from "@sciencecorp/helix-components";
import React from "react";
import { PurchaseShowData, useApprovePurchase, useDeclinePurchase } from "../../../api/purchase";
import {
  ServiceRequestShowData,
  invalidateServiceRequestPurchasesAndDetails,
  useApproveServiceRequest,
  useDeclineServiceRequest,
} from "../../../api/service_requests";
import { MoneyText } from "../../MoneyText";

type ActionRequiredProps = {
  serviceRequest: ServiceRequestShowData;
  purchase?: PurchaseShowData;
};

export const ActionRequired = ({ serviceRequest, purchase }: ActionRequiredProps) => {
  const { mutate: approvePurchase } = useApprovePurchase();
  const { mutate: declinePurchase } = useDeclinePurchase();
  const { mutateAsync: approveServiceRequest } = useApproveServiceRequest(serviceRequest.id);
  const { mutateAsync: declineServiceRequest } = useDeclineServiceRequest(serviceRequest.id);

  const { isOpen: isOpenReject, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();
  const { isOpen: isOpenApprove, onOpen: onOpenApprove, onClose: onCloseApprove } = useDisclosure();
  const invalidate = invalidateServiceRequestPurchasesAndDetails(serviceRequest.id);
  const handleApprove = () => {
    if (purchase) {
      approvePurchase(purchase.id, {
        onSuccess: () => {
          invalidate();
        },
      });
    } else {
      approveServiceRequest().then(() => {
        invalidate();
      });
    }
  };

  const handleDecline = () => {
    if (purchase) {
      declinePurchase(purchase.id, {
        onSuccess: () => {
          invalidateServiceRequestPurchasesAndDetails(serviceRequest.id);
        },
      });
    } else {
      declineServiceRequest();
    }
  };

  return (
    <>
      <Stack
        bg={useColorModeValue("orange.50", "gray.500")}
        width="100%"
        py={2}
        px={4}
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        borderRadius="lg"
        border="1px"
        borderColor={useColorModeValue("orange.200", "chakra-border-color")}>
        <HStack>
          <InfoIcon color={useColorModeValue("orange.500", "gray.200")} />
          <Text fontSize="sm">Action Required</Text>
        </HStack>
        <ButtonGroup size="xs">
          {/* TODO(le): Temporarily disabled until we have a better way to handle this */}
          {/* <Button variant="ghost" colorScheme="red" onClick={onOpenReject}>
            Decline
          </Button> */}

          <Button colorScheme="teal" onClick={onOpenApprove}>
            {purchase ? `Approve Purchase Cost` : `Approve Service Cost`}
          </Button>
        </ButtonGroup>
      </Stack>
      <ConfirmationModal
        isOpen={isOpenReject}
        onClose={onCloseReject}
        header="Decline Purchase"
        colorScheme="red"
        children={
          purchase ? (
            <Text>
              You are about to decline{" "}
              <Box as="span" fontWeight="semibold">
                Purchase #{purchase?.id}
              </Box>
              . Should you change your mind, you would need to create a new request
            </Text>
          ) : (
            <Text>
              You are about to decline{" "}
              <Box as="span" fontWeight="semibold">
                the service cost of{" "}
                <MoneyText
                  as={"span"}
                  money={serviceRequest?.service_cost}
                  formatOptions={{ compact: "never" }}
                />
              </Box>
              . Should you change your mind, you would need to create a new request.
            </Text>
          )
        }
        confirmText="Continue"
        onClick={handleDecline}
      />

      <ConfirmationModal
        isOpen={isOpenApprove}
        onClose={onCloseApprove}
        header="Approve Cost"
        colorScheme="teal"
        confirmText="Approve"
        children={`Are you sure you want to approve this ${
          purchase ? `purchase` : `service cost`
        }? Once confirmed, this action cannot be reversed.`}
        onClick={handleApprove}
      />
    </>
  );
};
