import React from "react";
import { Alert, Spinner, Flex } from "@chakra-ui/react";

interface SpinIfLoadingProps {
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  children: React.ReactNode | React.ReactNode[];
}
export const SpinIfLoading: React.FC<SpinIfLoadingProps> = ({
  isLoading,
  isError,
  children,
  errorMessage,
}) => {
  if (isLoading) {
    return (
      <Flex
        id="spinner-box"
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}>
        <Spinner size={"lg"} />
      </Flex>
    );
  } else if (isError) {
    return <Alert>{errorMessage ? errorMessage : "Unable to load"}</Alert>;
  } else {
    return <>{children}</>;
  }
};
