import React from "react";
import { Grid, Heading, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { CredentialCard } from "./CredentialCard";
import { Search2Icon } from "@chakra-ui/icons";
import { UnfulfilledCredentialCard } from "./UnfulfilledCredentialCard";

export const MyCredentialsPage = ({ data }) =>
  data?.active_credentials.length || data?.unfulfilled_credentials.length ? (
    <VStack width="100%" align="start" spacing={8}>
      <VStack width="100%" align="start" spacing={4}>
        <Heading size="md">Awarded to You</Heading>
        <Grid
          templateColumns="repeat(auto-fit, minmax(var(--chakra-space-72), 1fr))"
          gap={4}
          width="100%">
          {data?.active_credentials.map((userCredential) => (
            <CredentialCard key={userCredential.id} userCredential={userCredential} />
          ))}
        </Grid>
      </VStack>
      {data?.unfulfilled_credentials.length && (
        <VStack width="100%">
          <VStack width="100%" align="start" spacing={1}>
            <Heading size="md">Unfulfilled</Heading>
            <Text fontSize="sm">
              By virtue of your role, you are required to complete certain trainings to gain these
              credentials.
            </Text>
          </VStack>
          <Grid
            templateColumns="repeat(auto-fit, minmax(var(--chakra-space-72), 1fr))"
            gap={3}
            width="100%">
            {data?.unfulfilled_credentials.map((userCredential) => (
              <UnfulfilledCredentialCard key={userCredential.id} userCredential={userCredential} />
            ))}
          </Grid>
        </VStack>
      )}
    </VStack>
  ) : (
    <VStack
      width="100%"
      minHeight="xs"
      justify="center"
      borderRadius="md"
      bg={useColorModeValue("gray.50", "gray.700")}
      opacity="0.7"
      spacing={5}>
      <Search2Icon boxSize={6} />
      <VStack spacing={0}>
        <Text fontWeight="semibold" fontSize="sm">
          It’s empty here.
        </Text>
        <Text fontWeight="medium" fontSize="xs">
          There are no credentials.
        </Text>
      </VStack>
    </VStack>
  );
