import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { invalidatePurchases } from "./purchase";
import { purchasePaymentShowSchema } from "./purchase_payment";

export const PAYMENT_SCHEDULE_BASE_URL = "payment_schedules";

export const paymentScheduleSchema = z.object({
  id: z.number(),
  schedule_type: z.string(),
  due_date: z.string().nullable(),
  base_amount: moneySchema,
  advance_amount: moneySchema,
  payment_frequency: z.string().nullable(),
  payment_term_weeks: z.number().nullable(),
  start_date: z.string().nullable(),
  is_variable_interest_rate: z.boolean(),
  interest_rate: z.number(),
  compounding_period: z.string().nullable(),
  additional_fees: moneySchema,
  pay_additional_fees_upfront: z.boolean(),
  end_of_lease_option: z.string().nullable(),
  has_early_termination_penalty: z.boolean(),
  early_termination_penalty: moneySchema.nullable(),
});

export const paymentScheduleShowSchema = paymentScheduleSchema.extend({
  purchase_payments: z.array(purchasePaymentShowSchema),
});

export type PaymentScheduleData = z.infer<typeof paymentScheduleSchema>;
export type PaymentScheduleShowData = z.infer<typeof paymentScheduleShowSchema>;
export type PaymentScheduleCreateParams = Pick<PaymentScheduleData, "schedule_type"> & {
  purchase_id?: number;
  contract_id?: number;
};

export const createPaymentSchedule = async (payment_schedule: PaymentScheduleCreateParams) => {
  const result = await api.post(PAYMENT_SCHEDULE_BASE_URL, { payment_schedule });
  return result.data;
};

export const updatePaymentSchedule = async (payment_schedule: Partial<PaymentScheduleData>) => {
  const result = await api.put(`${PAYMENT_SCHEDULE_BASE_URL}/${payment_schedule.id}`, {
    payment_schedule,
  });
  return result.data;
};

export const deletePaymentSchedule = async (id: number) => {
  await api.delete(`${PAYMENT_SCHEDULE_BASE_URL}/${id}`);
};

export const useCreatePaymentSchedule = (purchaseId: number) => {
  return useMutation({
    mutationFn: createPaymentSchedule,
    onSuccess: invalidatePurchases(purchaseId, "financials"),
  });
};

export const useUpdatePaymentSchedule = (purchaseId: number) => {
  return useMutation({
    mutationFn: updatePaymentSchedule,
    onSuccess: invalidatePurchases(purchaseId, "financials"),
  });
};

export const useDeletePaymentSchedule = (purchaseId: number) => {
  return useMutation({
    mutationFn: deletePaymentSchedule,
    onSuccess: invalidatePurchases(purchaseId, "financials"),
  });
};
