import React from "react";
import { Project, useUpdateProject } from "../../../api/planning/projects";
import { Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import {
  AttributesTable,
  ConfirmationButton,
  EditableDate,
  EditableNumber,
} from "@sciencecorp/helix-components";
import { DateTime, Duration } from "luxon";
import { humanize, titleize } from "inflection";
import { DeleteIcon } from "@chakra-ui/icons";

interface ProjectAttributesTableProps {
  project: Project;
}

export const ProjectAttributesTable: React.FC<ProjectAttributesTableProps> = ({ project }) => {
  const { mutate: updateProject } = useUpdateProject();

  const duration = project.start_date
    ? project.start_date
        .plus({ months: project.relative_max_end_date_months })
        .diff(project.start_date, ["years", "months", "days"])
        .toObject()
    : { years: 0, months: 0, days: 0 };
  for (const key of ["years", "months", "days"]) {
    if (duration[key] === 0) {
      delete duration[key];
    }
  }
  const relativeTimeDescription = Duration.fromObject(duration).toHuman({ listStyle: "narrow" });
  const toast = useToast();

  const onSubmit = (key: string, minMonths: number) => {
    return async (value): Promise<void> => {
      if (value) {
        if (Number(value) > minMonths) {
          updateProject({ id: project.id, [key]: value });
        } else {
          toast({
            title: "Invalid date",
            description: `${titleize(humanize(key))} must be larger than ${minMonths}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
  };

  return (
    <AttributesTable
      attributes={[
        {
          label: "estimated duration",
          value: relativeTimeDescription,
        },
        {
          label: "start date",
          value: (
            <Flex gap={1}>
              <EditableDate
                defaultValue={project.start_date?.toISODate() || ""}
                onSubmit={(value) => {
                  updateProject({
                    id: project.id,
                    start_date: value ? DateTime.fromISO(value) : null,
                  });
                }}
                persistentEdit={true}
              />
            </Flex>
          ),
        },
        {
          label: "min end date",
          value: (
            <EditableNumber
              defaultValue={project.relative_min_end_date_months}
              preview={<Text>{project.relative_min_end_date_months} months</Text>}
              onSubmit={onSubmit("relative_min_end_date_months", 0)}
              persistentEdit={true}
            />
          ),
        },
        {
          label: "most likely end date",
          value: (
            <EditableNumber
              defaultValue={project.relative_expected_end_date_months}
              preview={<Text>{project?.relative_expected_end_date_months} months</Text>}
              onSubmit={onSubmit(
                "relative_expected_end_date_months",
                project.relative_min_end_date_months
              )}
              persistentEdit={true}
            />
          ),
        },
        {
          label: "max end date",
          value: (
            <EditableNumber
              defaultValue={project.relative_max_end_date_months}
              preview={<Text>{project.relative_max_end_date_months} months</Text>}
              onSubmit={onSubmit(
                "relative_max_end_date_months",
                project.relative_expected_end_date_months
              )}
              persistentEdit={true}
            />
          ),
        },
      ]}
    />
  );
};
