import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Flex,
  HStack,
  Heading,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import * as inflection from "inflection";
import { FiArchive } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import { TeamData, useDeleteTeam, TeamMembershipPartialData } from "../../api/team";
import { ConfirmationModal } from "@sciencecorp/helix-components";
import { DeleteIcon } from "@chakra-ui/icons";

type Props = {
  team: TeamData;
  location: string;
  editable?: boolean;
};

export const TeamCard: React.FC<Props> = ({ team, editable, location }) => {
  const { mutate: deleteTeam } = useDeleteTeam(team.parent_team_id ? team.parent_team_id : team.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cardRef = useRef<HTMLDivElement>(null);
  const [avatarGroupSize, setAvatarGroupSize] = useState(0);

  const handleResize = () => {
    setTimeout(() => {
      const containerWidth = cardRef.current!.offsetWidth - 52; // card padding
      const avatarWidth = 36; // width of medium size avatar - overlap
      const maxNumAvatars = Math.floor(containerWidth / avatarWidth) - 1; // "and X more" avatar
      setAvatarGroupSize(maxNumAvatars);
    }, 0);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const leads = (team.all_memberships || []).filter((membership) => membership.is_lead);
  const leadIds = new Set(leads.map((lead) => lead.user.id));

  const members = (team.all_memberships || []).reduce(
    (uniqueMembers: TeamMembershipPartialData[], membership) => {
      if (
        !membership.is_lead &&
        !leadIds.has(membership.user.id) &&
        !uniqueMembers.some((member) => member.user.id === membership.user.id)
      ) {
        uniqueMembers.push(membership);
      }
      return uniqueMembers;
    },
    []
  );

  return (
    <LinkBox
      as={Box}
      w="100%"
      h="100%"
      display="flex"
      flexDirection="column"
      ref={cardRef}
      bg={location === "teams" ? useColorModeValue("gray.50", "gray.700") : "transparent"}
      paddingBlockEnd={location === "subteams" ? 5 : 0}
      border={location === "teams" ? "1px" : "none"}
      borderBottom={location === "teams" || location === "subteams" ? "1px" : "none"}
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderRadius={location === "teams" ? "lg" : ""}>
      <VStack align="start" width="100%" p={location === "teams" ? 5 : 0} spacing={3} flex="1">
        <HStack justify="space-between" width="100%" align="start">
          <Stack spacing={location === "teams" ? 2 : 1}>
            <LinkOverlay as={RouterLink} to={`/teams/${team.id}/readme`}>
              <Heading size={location === "teams" ? "md" : "sm"}>{team.name}</Heading>
            </LinkOverlay>
            <Text color={useColorModeValue("gray.600", "gray.400")}>
              {team.description || "No team objective yet"}
            </Text>
          </Stack>

          {editable && (
            <Menu>
              <MenuButton
                as={IconButton}
                isDisabled={team.has_sub_teams ? true : false}
                aria-label="Options"
                icon={<BsThreeDotsVertical size={20} />}
                size="sm"
                variant="ghost"
              />
              <MenuList alignItems="center" zIndex={"popover"}>
                {team.can_be_deleted ? (
                  <MenuItem icon={<DeleteIcon />} onClick={() => onOpen()}>
                    Delete Team
                  </MenuItem>
                ) : (
                  <MenuItem icon={<FiArchive />} onClick={() => onOpen()}>
                    Archive Team
                  </MenuItem>
                )}
                <ConfirmationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  header={team.can_be_deleted ? "Delete Team" : "Archive Team"}
                  confirmText="Yes"
                  colorScheme="red"
                  children={"Are you sure? You can’t undo this action afterwards."}
                  onClick={() => {
                    team.can_be_deleted;
                    deleteTeam(team.id);
                    onClose();
                  }}
                />
              </MenuList>
            </Menu>
          )}
        </HStack>

        <Flex width="100%" position="relative" overflow="hidden">
          {leads.map((lead) => (
            <LinkBox
              marginInlineEnd={-2}
              key={lead.id}
              as={RouterLink}
              to={`/users/${lead.user.id}`}>
              <Avatar
                name={lead.user.name}
                src={lead.user.picture_uri}
                borderColor="teal.400"
                borderWidth={3}
              />
            </LinkBox>
          ))}
          {members.map((membership) => (
            <LinkBox
              marginInlineEnd={-4}
              key={membership.id}
              as={RouterLink}
              to={`/users/${membership.user.id}`}>
              <Avatar name={membership.user.name} src={membership.user.picture_uri} />
            </LinkBox>
          ))}
          <Flex
            zIndex={1}
            width="min-content"
            height="100%"
            align="center"
            right={0}
            background={`linear-gradient(to right, var(--chakra-colors-transparent) 0, var(--chakra-colors-gray-${useColorModeValue(
              "50",
              "700"
            )}) 80%)`}
            lineHeight="shorter"
            pointerEvents="none"
            paddingInlineStart={32}
            position="absolute">
            <Stat pointerEvents="all" size="sm" textAlign="end">
              <StatNumber>{team.all_memberships?.length}</StatNumber>
              <StatLabel color="gray.500">Total</StatLabel>
            </Stat>
          </Flex>
        </Flex>
      </VStack>
      {location === "teams" && (
        <Box mb={4} ml={4}>
          <Badge variant="outline" borderRadius="md">
            {inflection.capitalize(team.team_type!)}
          </Badge>
        </Box>
      )}
    </LinkBox>
  );
};
