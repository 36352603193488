import React, { useState, useEffect } from "react";

import {
  Heading,
  Badge,
  Tag,
  Flex,
  HStack,
  Text,
  VStack,
  Grid,
  GridItem,
  useColorModeValue,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react";
import { ArcherContainer, ArcherElement } from "react-archer";
import {
  InventoryShowData,
  InventoryLocationsWithArrowData,
  InventoryLocationsWithInventoryData,
  TruncatedInventoryLocationsWithInventoryData,
  useInventoryLocationsWithInventoryMap,
} from "../../../../api/inventory";
import { ArrowDownIcon } from "@chakra-ui/icons";
import * as _ from "lodash";
import { InventoryItemsTable } from "./InventoryItemsTable";
import { EmptyState, Searchbar } from "@sciencecorp/helix-components";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

export const StockLocations = ({ inventory }: { inventory: InventoryShowData }) => {
  const { search, debouncedSearch } = useDebouncedSearch();
  const { data, isLoading } = useInventoryLocationsWithInventoryMap(inventory.id, search);
  const [selectedLocation, setSelectedLocation] =
    useState<InventoryLocationsWithInventoryData | null>(null);

  const { tree, arrowData } = data || {};

  return (
    <Flex
      minH="xs"
      direction="column"
      p={4}
      gap={4}
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      w="100%">
      {!selectedLocation ? (
        <>
          <Flex direction={["column", "row"]} gap={2} w="100%" justify="space-between">
            <HStack align="center">
              <Heading size="md" fontWeight="medium">
                Stock Locations
              </Heading>
              <Tooltip label="Has Items" closeDelay={500}>
                <Box p={1} bg="teal.500" borderRadius="full" />
              </Tooltip>
            </HStack>
            <Box>
              <Searchbar
                onSelect={() => {}}
                size="sm"
                placeholder="Search for location"
                onChange={(value) => debouncedSearch(value)}
              />
            </Box>
          </Flex>
          <Skeleton isLoaded={!isLoading}>
            {arrowData?.length ? (
              <ArcherContainer>
                <Flex direction="column" w="100%">
                  {arrowData.map((location) => (
                    <StockLocationRow
                      key={`location${location.id}-${location.name}`}
                      tree={tree || []}
                      location={location}
                      setSelectedLocation={setSelectedLocation}
                    />
                  ))}
                </Flex>
              </ArcherContainer>
            ) : (
              <EmptyState title="No inventory yet." />
            )}
          </Skeleton>
        </>
      ) : (
        <InventoryItemsTable
          key={`location${selectedLocation.id}-table`}
          inventory={inventory}
          location={selectedLocation}
          tree={tree || []}
          arrowData={arrowData || []}
          setSelectedLocation={setSelectedLocation}
        />
      )}
    </Flex>
  );
};

type StockLocationRowProps = {
  tree: (InventoryLocationsWithInventoryData | TruncatedInventoryLocationsWithInventoryData)[];
  location: InventoryLocationsWithArrowData;
  depth?: number;
  setSelectedLocation: (location: InventoryLocationsWithArrowData) => void;
};

export const StockLocationRow = ({
  tree,
  location,
  depth = 0,
  setSelectedLocation,
}: StockLocationRowProps) => {
  const children = location.children.map((child, index) => (
    <Flex direction="column" mb={5} key={`location${child.id}-${index}`} w="100%">
      <StockLocationRow
        location={child}
        depth={index + 1}
        tree={tree}
        setSelectedLocation={setSelectedLocation}
      />
    </Flex>
  ));

  let locationName: JSX.Element;
  if (location.isTruncated) {
    locationName = (
      <TruncatedLocationPopover options={tree} location={location} drawArrows={true} />
    );
  } else if (location.has_stock) {
    locationName = (
      <ArcherElement id={`location${location.id}`} relations={location.arrowInfo}>
        <Badge mt={-1} colorScheme="teal">
          {location.name}
        </Badge>
      </ArcherElement>
    );
  } else if (depth === 0) {
    locationName = (
      <ArcherElement id={`location${location.id}`} relations={location.arrowInfo}>
        <Tag>{location.name}</Tag>
      </ArcherElement>
    );
  } else {
    locationName = (
      <ArcherElement id={`location${location.id}`} relations={location.arrowInfo}>
        <Badge mt={-1}>{location.name}</Badge>
      </ArcherElement>
    );
  }

  return (
    <Grid templateColumns="1.25fr 2fr 1fr" w="100%" alignSelf="center">
      <Box
        ml={`${depth * 20}px`}
        h={"max-content"}
        w="max-content"
        _hover={{ cursor: location.has_stock ? "pointer" : "auto" }}
        onClick={location.has_stock ? () => setSelectedLocation(location) : undefined}>
        {locationName}
      </Box>

      <GridItem w="100%" colSpan={2}>
        {children}
      </GridItem>
    </Grid>
  );
};

type InventoryLocationPopoverProps = {
  options: InventoryLocationsWithInventoryData[];
  location: InventoryLocationsWithArrowData;
  drawArrows: boolean;
};

export const TruncatedLocationPopover = ({
  options,
  location,
  drawArrows,
}: InventoryLocationPopoverProps) => {
  const path = findSelectedOption(options, location.targetLocation!.id);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        {drawArrows ? (
          <Box
            textAlign="center"
            alignSelf="baseline"
            border="1px"
            bg={useColorModeValue("gray.100", "gray.700")}
            borderColor="chakra-border-color"
            boxSize={7}
            borderRadius="full">
            <ArcherElement id={`location${location.id}`} relations={location.arrowInfo}>
              <Text fontSize="sm">{path.length - 4}</Text>
            </ArcherElement>
          </Box>
        ) : (
          <Tag>
            <Text fontSize="sm">{path.length - 4} more</Text>
          </Tag>
        )}
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <ArcherContainer>
          <PopoverBody>
            <VStack align="center" w="100%" gap={0} h="100%">
              {path.map((location, idx) => (
                <VStack gap={0} key={`location${location.id}-popover`}>
                  <Badge colorScheme={location.has_stock ? "teal" : "gray"} mb={1}>
                    {location.name}
                  </Badge>
                  {idx !== path.length - 1 && <ArrowDownIcon boxSize={4} />}
                </VStack>
              ))}
            </VStack>
          </PopoverBody>
        </ArcherContainer>
      </PopoverContent>
    </Popover>
  );
};

export const findSelectedOption = (
  options: InventoryLocationsWithInventoryData[],
  value: number
): InventoryLocationsWithInventoryData[] => {
  return options.map((option) => _findSelectedOption(option, value)).filter(Boolean)[0];
};

const _findSelectedOption = (
  option: InventoryLocationsWithInventoryData,
  value: number,
  path: (InventoryLocationsWithInventoryData | TruncatedInventoryLocationsWithInventoryData)[] = []
) => {
  if (option.id === value) {
    return [...path, option];
  } else if (option.children.length) {
    return option.children
      .map((child) => _findSelectedOption(child, value, [...path, option]))
      .filter(Boolean)[0];
  } else {
    return undefined;
  }
};
