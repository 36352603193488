import { FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { useCreateBudgetItemQuery } from "../../../../api/budget_items";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyInput } from "../../../MoneyInput";

export const AddExpenditureModal = ({
  isOpen,
  onClose,
  budgetId,
}: {
  isOpen: boolean;
  onClose: () => void;
  budgetId: number;
}) => {
  const currency = useCurrency();
  const [expenseName, setExpenseName] = useState("");
  const [expenseCost, setExpenseCost] = useState(Money.zero(currency));
  const [isError, setIsError] = useState(false);

  const { mutateAsync: newBudgetItem, isLoading: isCreatingNewBudgetItem } =
    useCreateBudgetItemQuery(budgetId);

  const handleClose = () => {
    setExpenseName("");
    setExpenseCost(Money.zero(currency));
    setIsError(false);
    onClose();
  };

  const formIncomplete = !expenseName;

  const handleAddItem = () => {
    newBudgetItem({
      budget_id: budgetId,
      item_name: expenseName,
      amount: expenseCost,
    }).then(() => handleClose());
  };

  return (
    <FormModal
      title={`Add New Expenditure Item`}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isCreatingNewBudgetItem}
      submitButtonDisabled={formIncomplete}
      submitButtonColorSchema="teal"
      handleSubmit={handleAddItem}>
      <VStack align={"start"} spacing={4} w={"100%"}>
        <FormControl isRequired isInvalid={!expenseName && isError}>
          <FormLabel>Item Name</FormLabel>
          <Input
            placeholder={"Item name"}
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
          />
          <FormErrorMessage>Item name is required</FormErrorMessage>
        </FormControl>
      </VStack>
    </FormModal>
  );
};
