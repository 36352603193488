import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  HStack,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

export const SubmitPinModal = ({ decryptionError, submitPin, setPin, pin }) => (
  <FormControl>
    {decryptionError && (
      <Alert status="error" mb={4}>
        <AlertIcon />
        Failed to decrypt
      </Alert>
    )}
    <HStack mb={4}>
      <Input
        type="password"
        placeholder="Enter your PIN code"
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        autoFocus
      />
      <Button
        bg="teal.500"
        type="submit"
        color={useColorModeValue("white", "gray.200")}
        onClick={submitPin}>
        Submit
      </Button>
    </HStack>
  </FormControl>
);
