import React, { useEffect, useRef, useState } from "react";

import { Box, Button, Center, Spinner } from "@chakra-ui/react";
import { OmniResponseObject } from "../../api/omni_search/schema";
import { ObjectCard } from "@sciencecorp/helix-components";

interface OmnisearchResultsProps {
  isLoading: boolean;
  results?: OmniResponseObject[];
  itemsPerPage: number;
  setShowingCount?: (newState) => void;
}
export const OminisearchResults = (props: OmnisearchResultsProps) => {
  const { isLoading, results, itemsPerPage, setShowingCount } = props;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const newItemRef = useRef(null);

  const handleShowMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  useEffect(() => {
    setVisibleItems(itemsPerPage);
  }, [results]);

  useEffect(() => {
    if (newItemRef.current) {
      // @ts-ignore
      newItemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [visibleItems]);

  useEffect(() => {
    if (results) {
      setShowingCount?.(visibleItems < results?.length ? visibleItems : results?.length);
    }
  }, [results, visibleItems]);
  return (
    <>
      <Box overflow={["scroll"]}>
        {isLoading ? (
          <Center py={[10]}>
            <Spinner />
          </Center>
        ) : (
          results?.slice(0, visibleItems)?.map((obj, i) => (
            <div
              {...(i === visibleItems - itemsPerPage ? { ref: newItemRef } : {})}
              key={`${obj.index}-${obj?.id}`}>
              <ObjectCard object={{ ...obj, helixName: obj.helix_name, model: obj.model_name }} />
            </div>
          ))
        )}
      </Box>
      {results && visibleItems < results?.length && (
        <Box textAlign="center" mt={"2"}>
          <Button onClick={handleShowMore}>Load More</Button>
        </Box>
      )}
    </>
  );
};
