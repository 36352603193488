import { UnlockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ConfirmationButton, ConfirmationModal } from "@sciencecorp/helix-components";
import { Select } from "chakra-react-select";
import React, { useState } from "react";
import { MdApproval } from "react-icons/md";
import {
  CandidateData,
  UpdateCandidateSummaryParams,
  useUpdateCandidate,
} from "../../../api/candidates";

const postInterviewOptions: { label: string; value: string }[] = [
  { label: "Unlock Submissions", value: "unlock_submissions" },
  { label: "References", value: "references" },
  { label: "Request to Hire", value: "request_to_hire" },
  { label: "Reject Candidate", value: "rejected" },
];

const referencesStageOptions: { label: string; value: string }[] = [
  { label: "Reject Candidate", value: "rejected" },
  { label: "Manager Approved", value: "manager_approved" },
  { label: "Manager Rejected", value: "manager_rejected" },
];

const nextStepOptions: { label: string; value: string }[] = [
  { label: "Request to Hire", value: "request_to_hire" },
  { label: "Reject Candidate", value: "rejected" },
];

const offerNextStepOptions: { label: string; value: string }[] = [
  { label: "Offer Accepted", value: "offer_accepted" },
  { label: "Offer Declined", value: "offer_declined" },
];

const terminalDecisions = [
  "rejected",
  "manager_rejected",
  "ceo_rejected",
  "offer_accepted",
  "offer_declined",
];

interface NextStepsWidgetProps {
  candidate: CandidateData;
  isCeo: boolean;
  overallFeedback: string[];
  strengths: string[];
  concerns: string[];
  updateCandidateSummary: (data: UpdateCandidateSummaryParams, opts?: any) => void;
}

export const NextStepsWidget = ({
  candidate,
  isCeo,
  overallFeedback,
  strengths,
  concerns,
  updateCandidateSummary,
}: NextStepsWidgetProps) => {
  const { mutate: updateCandidate } = useUpdateCandidate();
  const [nextStep, setNextStep] = useState<{ label: string; value: string } | null>(null);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleNextStep = () => {
    if (nextStep) {
      switch (nextStep.value) {
        case "unlock_submissions":
          updateCandidate(
            { id: candidate.id, locked: false },
            { onSuccess: () => setNextStep(null) }
          );
          break;
        case "rejected":
          onOpen();
          break;
        case "references":
          updateCandidate({ id: candidate.id, decision: "references" });
          toast({
            title: "Candidate moved to References.",
            position: "bottom-left",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          break;
        default:
          updateCandidate({ id: candidate.id, decision: nextStep.value });
          break;
      }
    }
  };

  const component = () => {
    if (candidate.decision === "request_to_hire") {
      // ceo view
      if (isCeo) {
        return (
          <Stack direction={{ base: "column", md: "row" }} spacing={5}>
            <Box display={{ base: "none", md: "flex" }}>
              <Icon
                as={MdApproval}
                color={useColorModeValue("green.500", "green.400")}
                boxSize={7}
              />
            </Box>
            <Flex width="100%" direction="column" align="start" gap={{ base: 2, md: 0 }}>
              <Heading size="sm">Executive Decision</Heading>
              <Text fontSize="sm">Your approval was requested to hire this candidate.</Text>
            </Flex>
            <HStack>
              <ConfirmationButton
                minWidth="fit-content"
                label="Decline Candidate"
                variant="Button"
                size={{ base: "sm", md: "md" }}
                buttonVariant="outline"
                confirmationButtonLabel="Decline"
                colorScheme="red"
                children="Are you sure you want to decline this candidate?"
                confirmationHeader="Decline Candidate"
                onConfirm={() => updateCandidate({ id: candidate.id, decision: "ceo_rejected" })}
              />
              <ConfirmationButton
                minWidth="fit-content"
                label="Approve Hire"
                size={{ base: "sm", md: "md" }}
                variant="Button"
                confirmationButtonLabel="Continue"
                colorScheme="teal"
                children="Approve the hiring of this candidate."
                confirmationHeader="Approve Hire"
                onConfirm={() => updateCandidate({ id: candidate.id, decision: "ceo_approved" })}
              />
            </HStack>
          </Stack>
        );
      } else {
        // recruiting admin view after requesting executive decision
        return (
          <HStack>
            <Icon as={MdApproval} color={useColorModeValue("green.500", "green.400")} boxSize={7} />
            <VStack width="100%" align="start" spacing={0}>
              <Heading size="sm">Executive Approval Requested</Heading>
              <Text>Executive approval has been requested to hire the candidate.</Text>
            </VStack>
          </HStack>
        );
      }
    }
    // catch all when candidate is in intermediary steps
    else
      return (
        <>
          <HStack spacing={4}>
            <Icon as={MdApproval} color="green.500" boxSize={5} />
            <Heading size={{ base: "xs", md: "sm" }}>
              {candidate.decision === "ceo_approved" ? "Candidate's Decision" : "Next Steps"}
            </Heading>
          </HStack>
          <HStack spacing={3}>
            <Box
              width={{ base: "150x", md: "200px" }}
              bg={useColorModeValue("white", "gray.700")}
              borderRadius="md">
              <Select
                useBasicStyles
                size={{ base: "sm", md: "md" }}
                options={
                  !candidate.decision
                    ? postInterviewOptions
                    : candidate.decision === "references"
                    ? referencesStageOptions
                    : candidate.decision === "manager_approved"
                    ? nextStepOptions
                    : candidate.decision === "ceo_approved"
                    ? offerNextStepOptions
                    : []
                }
                value={nextStep}
                menuPlacement="top"
                onChange={(value) => {
                  if (value) setNextStep(value);
                }}
              />
            </Box>
            <Button
              size={{ base: "sm", md: "md" }}
              colorScheme="teal"
              onClick={() => handleNextStep()}
              isDisabled={!nextStep}>
              Continue
            </Button>
          </HStack>
        </>
      );
  };

  // only show floating widget if candidate is not in terminal decision (+ references if manager has not yet made a decision)
  return !terminalDecisions.includes(candidate.decision) ? (
    <HStack position="fixed" bottom="30px" justify="center" width="100%" zIndex={"overlay"}>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg={useColorModeValue("teal.50", "teal.600")}
        p={4}
        justify="space-between"
        borderRadius="md"
        boxShadow="lg"
        minWidth={{ md: "xl", lg: "2xl" }}>
        {!candidate.locked ? (
          <>
            <HStack spacing={4}>
              <Icon as={UnlockIcon} boxSize={5} />
              <Heading size="sm">Lock Feedback Submissions</Heading>
            </HStack>
            <Button
              colorScheme="teal"
              onClick={() =>
                updateCandidate(
                  { id: candidate.id, locked: true },
                  {
                    onSuccess: () =>
                      updateCandidateSummary({
                        overall_feedback: overallFeedback.join(" "),
                        strengths: strengths.join(" "),
                        concerns: concerns.join(" "),
                      }),
                  }
                )
              }>
              Lock
            </Button>
          </>
        ) : (
          component()
        )}
        <ConfirmationModal
          confirmText="Reject"
          colorScheme="red"
          header="Reject Candidate"
          isOpen={isOpen}
          onClick={() => updateCandidate({ id: candidate.id, decision: "rejected" })}
          onClose={() => onClose()}
          children="Are you sure you want to reject this candidate?"
        />
      </Stack>
    </HStack>
  ) : null;
};
