import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  Text,
  Heading,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Grid,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import type { ReviewFeedbackPartialData } from "../../../api/review_feedback";

import { OnboardingFeedbackModal } from "./OnboardingFeedbackModal";

type OccasionDate = {
  occasion: string;
  date: string;
  complete: boolean;
  feedback?: ReviewFeedbackPartialData;
};

export const OnboardingTimeline = ({ joinDate, reviewFeedbacks, reviewFeedbackQuery }) => {
  const [completed, setCompleted] = useState<boolean>(false);
  const [occasionDates, setOccasionDates] = useState<OccasionDate[]>([]);
  const [percentage, setPercentage] = useState<string>("0%");

  useEffect(() => {
    if (reviewFeedbacks) {
      const onboardingFeedbacks = reviewFeedbacks.filter(
        (feedback: ReviewFeedbackPartialData) => feedback.review_feedback_type === "onboarding"
      );

      const joinDateObj = DateTime.fromISO(joinDate);
      if (joinDateObj.plus({ months: 3 }) <= DateTime.now()) setCompleted(true);
      else {
        const calcDates: { occasion: string; date: string; complete: boolean }[] = [];
        for (let i = 0; i < 4; i++) {
          const title: string = i === 0 ? "Start Date" : `${30 * i} Day Review`;
          const occasion = i !== 0 && `${30 * i} Day`;
          const feedback = onboardingFeedbacks.find((feedback) => feedback.occasion === occasion);
          const occasionDate = joinDateObj.plus({ month: 1 * i });

          const complete = DateTime.now() >= occasionDate ? true : false;

          const date: OccasionDate = {
            occasion: title,
            date: occasionDate.toFormat("LLL dd"),
            complete,
          };
          feedback ? (date.feedback = feedback) : null;
          calcDates.push(date);
        }
        const difference: number = joinDateObj
          .plus({ months: 3 })
          .diff(DateTime.now(), ["days"]).days;
        setPercentage(`${(((90 - difference) / 90) * 100).toString()}%`);
        setOccasionDates(calcDates);
      }
    }
  }, [reviewFeedbacks]);

  return (
    <Box
      bg={
        completed
          ? useColorModeValue("green.50", "green.700")
          : useColorModeValue("white", "gray.700")
      }
      p={8}
      borderRadius="lg"
      border={completed ? "1px" : 0}
      borderColor={useColorModeValue("green.200", "green.900")}>
      {completed ? (
        <>
          <Heading size="md">Congrats on Completing your Onboarding!</Heading>
          <Text fontSize="sm" color={useColorModeValue("gray.700", "gray.300")} marginBlockEnd={8}>
            You made quite the impression on your manager and peers.
          </Text>
        </>
      ) : (
        <>
          <Heading size="md">Your Onboarding</Heading>
          <Text fontSize="sm" color={useColorModeValue("gray.700", "gray.300")} marginBlockEnd={8}>
            Track your progress from day one to day ninety
          </Text>
        </>
      )}
      <Grid position="relative" gridTemplateColumns={"0.5fr 1fr 1fr 0.5fr"}>
        {occasionDates.map((checkpointDate: OccasionDate) => (
          <TimelineDate
            key={checkpointDate.occasion}
            checkpoint={checkpointDate.occasion}
            date={checkpointDate.date}
            complete={checkpointDate.complete}
            feedback={checkpointDate.feedback}
            reviewFeedbackQuery={reviewFeedbackQuery}
          />
        ))}
        <Box
          bottom={0}
          zIndex={0}
          position={"absolute"}
          marginBlockStart={4}
          borderInline={"5px solid"}
          borderColor={
            completed
              ? useColorModeValue("green.50", "green.700")
              : useColorModeValue("white", "gray.700")
          }
          bg={useColorModeValue("gray.100", "blackAlpha.500")}
          height={5}
          width={"100%"}>
          <Box
            width={percentage}
            height={"100%"}
            position="absolute"
            top={0}
            left={0}
            bg="teal.500"></Box>
        </Box>
      </Grid>
    </Box>
  );
};

type TimelineDateProps = {
  checkpoint: string;
  date: string;
  complete: boolean;
  reviewFeedbackQuery: () => void;
  feedback?: ReviewFeedbackPartialData;
};
const TimelineDate: React.FC<TimelineDateProps> = ({
  checkpoint,
  date,
  complete,
  feedback,
  reviewFeedbackQuery,
}) => {
  const alignment =
    checkpoint === "Start Date" ? "left" : checkpoint === "90 Day Review" ? "right" : "center";
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      minHeight={24}
      textAlign={alignment}>
      <Box>
        <Text
          fontSize={"xs"}
          letterSpacing="wider"
          lineHeight={"shorter"}
          fontWeight="bold"
          color={useColorModeValue("gray.600", "gray.400")}
          textTransform="uppercase">
          {checkpoint}
        </Text>
        <Text fontSize={"xs"} color={useColorModeValue("gray.600", "gray.400")}>
          {date}
        </Text>
      </Box>
      <TimelineEvent
        alignment={alignment}
        complete={complete}
        feedback={feedback}
        reviewFeedbackQuery={reviewFeedbackQuery}
      />
    </Flex>
  );
};

type TimelineEventProps = {
  alignment: "center" | "left" | "right";
  complete: boolean;
  reviewFeedbackQuery: () => void;
  feedback?: ReviewFeedbackPartialData;
};

const TimelineEvent: React.FC<TimelineEventProps> = ({
  alignment,
  complete,
  reviewFeedbackQuery,
  feedback = null,
}) => {
  const inlineMargin = alignment === "center" ? "auto" : alignment === "left" ? "0 auto" : "auto 0";
  const text = alignment === "left" ? "🎉" : "✅";
  const { isOpen, onOpen, onClose } = useDisclosure();
  return complete ? (
    <Box
      borderRadius="full"
      position="relative"
      top={3}
      zIndex={1}
      marginInline={inlineMargin}
      background={useColorModeValue("green.100", "gray.50")}
      border="1px"
      borderColor={useColorModeValue("green.500", "gray.500")}
      boxSize={12}
      display="flex"
      justifyContent="center"
      alignItems="center">
      {text === "✅" && feedback ? (
        <>
          <Popover trigger="hover">
            <PopoverTrigger>
              <Text fontSize="xl">{text}</Text>
            </PopoverTrigger>
            <PopoverContent width="100%" mt={2}>
              <PopoverBody>
                <Button colorScheme="teal" rightIcon={<ChevronRightIcon />} onClick={onOpen}>
                  View Result
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <OnboardingFeedbackModal
            isOpen={isOpen}
            onClose={onClose}
            encryptedFeedback={feedback}
            reviewFeedbackQuery={reviewFeedbackQuery}
          />
        </>
      ) : (
        <Text fontSize="xl">{text}</Text>
      )}
    </Box>
  ) : (
    <Box
      position="relative"
      zIndex={1}
      marginInline={inlineMargin}
      background="teal.500"
      width={1}
      right={alignment === "right" ? 1 : undefined}
      height={5}></Box>
  );
};
