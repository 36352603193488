import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RichTextEditor } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import {
  TimelineEventData,
  useDeleteTimelineEvent,
  useUpdateTimelineEvent,
} from "../../api/timeline_events";
import { useCurrentUserQuery } from "../../api/user";
import { useCurrency } from "../../contexts/CurrencyContext";
import { TimelineEventTextProps } from "./TimelineEventText";

const emptyRichText = JSON.stringify({
  root: {
    type: "root",
    format: "",
    indent: 0,
    version: 1,
    children: [
      {
        type: "paragraph",
        format: "",
        indent: 0,
        version: 1,
        children: [
          {
            mode: "normal",
            text: "",
            type: "text",
            style: "",
            detail: 0,
            format: 0,
            version: 1,
          },
        ],
        direction: "ltr",
      },
    ],
    direction: "ltr",
  },
});

export const TimelineCommentEvent: React.FC<TimelineEventTextProps> = ({ event, onComment }) => {
  const menuButtonBg = useColorModeValue("gray.400", "gray.700");
  const [edit, setEdit] = useState(false);
  const { mutateAsync: deleteTimelineEvent } = useDeleteTimelineEvent();
  const { mutateAsync: updateTimelineEvent } = useUpdateTimelineEvent();

  const currentUser = useCurrentUserQuery();

  const defaultValue = event.event_data?.rich_text
    ? JSON.stringify(event.event_data?.rich_text)
    : event.content || "";

  return (
    <Box width="100%" maxW="3xl">
      <Flex
        bg={useColorModeValue("gray.50", "gray.400")}
        p={2}
        mb={-2.5}
        borderTopRadius="lg"
        border="1px"
        justifyContent={"space-between"}
        borderColor="chakra-border-color"
        borderBottom="0px">
        <TimelineCommentText event={event} />
        {currentUser.data?.id === event.user?.id && (
          <Menu placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BsThreeDots size={20} />}
              size="xs"
              color={menuButtonBg}
              variant="ghost"
            />
            <MenuList alignItems="center">
              <MenuItem
                icon={<EditIcon />}
                onClick={() => {
                  setEdit(true);
                }}>
                Edit comment
              </MenuItem>
              <MenuItem
                icon={<DeleteIcon color="red.500" />}
                onClick={() => {
                  deleteTimelineEvent(event.id).then(() => onComment && onComment("deleted"));
                }}>
                Delete comment
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <RichTextEditor
        width="100%"
        editable={edit}
        defaultValue={defaultValue !== "{}" ? defaultValue : emptyRichText}
        onSave={(rich, plainText) => {
          updateTimelineEvent({
            id: event.id,
            content: rich ?? "",
            slack_message: plainText ?? "",
            event_data: { rich_text: JSON.parse(rich) ?? "", plain_text: plainText ?? "" },
          }).then(() => {
            setEdit(false);
            onComment && onComment("updated");
          });
        }}
      />
    </Box>
  );
};

export const TimelineCommentText = ({
  event,
  templateType = "timeline",
}: {
  event: TimelineEventData;
  templateType?: string;
}) => {
  const currency = useCurrency();
  const now = DateTime.now();
  const diffText = DateTime.fromISO(event.created_at).toRelative({ base: now, style: "long" });
  const textColor =
    templateType === "timeline" ? useColorModeValue("gray.800", "gray.600") : "auto";
  const userNameTextColor =
    templateType === "timeline" ? useColorModeValue("gray.800", "gray.800") : "auto";

  return (
    <Text fontWeight="medium" color={textColor} fontSize="sm">
      <Link as={RouterLink} to={`/users/${event.user?.id}`}>
        <Box as="span" fontWeight="semibold" color={userNameTextColor}>
          {" "}
          {event.user?.name}
        </Box>
      </Link>{" "}
      commented
      {templateType === "timeline" && ` | ${diffText}`}
    </Text>
  );
};

export const TimelineEventWithMemo = ({
  event,
  templateType = "timeline",
}: {
  event: TimelineEventData;
  templateType?: string;
}) => {
  const content = event.event_data?.content as string;

  return templateType === "timeline" ? (
    <Box width="100%" maxW="3xl">
      <Flex
        bg={useColorModeValue("gray.50", "gray.400")}
        p={2}
        borderTopRadius="lg"
        borderBottomRadius={content?.length ? "none" : "lg"}
        border="1px"
        justifyContent={"space-between"}
        borderColor="chakra-border-color"
        borderBottom={content?.length ? "0px" : ""}>
        {<TimelineCommentText event={event} />}
      </Flex>
      {content?.length ? (
        <Flex
          p={2}
          borderBottomRadius="lg"
          border="1px"
          justifyContent={"space-between"}
          borderColor="chakra-border-color">
          <Text w="100%" px={2} fontSize="sm">
            {content}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
    </Box>
  ) : (
    <Box width="100%" maxW="3xl">
      <Flex>{<TimelineCommentText event={event} templateType={templateType} />}</Flex>
      {content?.length ? (
        <Flex
          p={2}
          borderBottomRadius="lg"
          border="1px"
          justifyContent={"space-between"}
          borderColor="chakra-border-color">
          <Text w="100%" px={2} fontSize="sm">
            {content}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
    </Box>
  );
};
