import {
  Box,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { Header } from "@sciencecorp/helix-components";
import React from "react";
import { MdCheck } from "react-icons/md";
import { MilestoneCard } from "./MilestoneCard";
import { useMilestonesByStatus } from "./lib/useMilestonesByStatus";

export const Progress = () => {
  const {
    milestonesByStatus: { draft, active, completed },
    isLoading,
  } = useMilestonesByStatus();

  const ActiveMilestones = () => {
    if (!active?.length) {
      return (
        <Heading as="h2" size="md">
          No active milestones
        </Heading>
      );
    }
    return (
      <>
        <Heading as="h2" size="sm">
          Active Milestones
        </Heading>
        {active.map((milestone) => (
          <MilestoneCard key={milestone.id} milestone={milestone} />
        ))}
      </>
    );
  };
  const DraftMilestones = () => {
    if (!draft?.length) return null;

    return (
      <>
        <Heading as="h2" size="sm">
          Draft Milestones
        </Heading>
        {draft.map((milestone) => (
          <MilestoneCard key={milestone.id} milestone={milestone} />
        ))}
      </>
    );
  };
  const CompletedMilestones = () => {
    if (!completed?.length) return null;
    return (
      <Box flexBasis={"33%"}>
        <Heading as="h2" marginBlockEnd={3} size="sm">
          Recently Completed
        </Heading>
        <List spacing={2}>
          {completed.map(({ id, name }) => (
            <ListItem fontSize="sm" key={id}>
              <ListIcon color="green.500" as={MdCheck} />
              {name}
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  if (isLoading)
    return (
      <Flex justifyContent="center">
        <Spinner size="lg" />
      </Flex>
    );
  return (
    <Box>
      <Header title="Milestones" />

      <Stack direction={["column", "column", "row"]} spacing={4} align="start" mt={4}>
        <VStack spacing={4} flex={1} width="100%" align="start">
          <ActiveMilestones />
          <DraftMilestones />
        </VStack>
        <CompletedMilestones />
      </Stack>
    </Box>
  );
};
