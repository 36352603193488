import React, { useMemo } from "react";
import {
  CollectionPage,
  Header,
  RecordLink,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { CapitalEquipmentShowData, useSearchCapitalEquipments } from "../../api/capital_equipment";
import { Flex, Text } from "@chakra-ui/react";
import { NewCapitalEquipment } from "./components/NewCapitalEquipmentModal";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";

export const CapitalEquipments = () => {
  const { search, debouncedSearch } = useDebouncedSearch();
  const { pagination, onPagination, order, onOrder, filters, facets, onFacets } = useCollection();
  const capitalEquipmentBodyOptions = {
    Acquisition_Cost: {
      range: {
        field: "acquisition_cost",
        ranges: [
          { from: 0, to: 100000, key: "$0 to $1000" },
          { from: 100001, to: 500000, key: "$1000 to $5000" },
          { from: 500001, to: 1000000, key: "$5000 to $10000" },
          { from: 1000001, to: 1500000, key: "$10000 to $15000" },
          { from: 1500001, key: "More than $15000" },
        ],
      },
    },
    Total_Cost: {
      range: {
        field: "total_cost",
        ranges: [
          { from: 0, to: 100000, key: "$0 to $1000" },
          { from: 100001, to: 500000, key: "$1000 to $5000" },
          { from: 500001, to: 1000000, key: "$5000 to $10000" },
          { from: 1000001, to: 1500000, key: "$10000 to $15000" },
          { from: 1500001, key: "More than $15000" },
        ],
      },
    },
  };

  const { data, isLoading } = useSearchCapitalEquipments({
    term: search || "*",
    aggs: ["team_name", "wafer_services_tool_id"],
    bodyOptions: capitalEquipmentBodyOptions,
    pagination,
    order,
    filters,
  });

  const columns = useMemo(
    () => [
      {
        label: "Name",
        render: (tool: CapitalEquipmentShowData) => (
          <RecordLink link={`/equipment/${tool.id}`} identifier={tool.name} />
        ),
      },
      {
        label: "Team",
        render: (tool: CapitalEquipmentShowData) => (
          <RecordLink link={`/teams/${tool.team.id}/readme`} identifier={tool.team.name} />
        ),
      },
      {
        label: "Wafer Services Tool ID",
        render: (tool: CapitalEquipmentShowData) => (
          <Text>{tool.wafer_services_tool_id || "-"}</Text>
        ),
      },
    ],
    []
  );

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);
  return (
    <Flex width="100%" direction="column">
      <Header title="Equipment" actions={[<NewCapitalEquipment />]} />
      <CollectionPage
        items={data?.results || []}
        columns={columns}
        facetsProps={{ search: true, background: true, debouncedSearch: debouncedSearch }}
        isLoading={isLoading}
        facets={populatedFacets}
        onFacets={onFacets}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        order={order}
        onOrder={onOrder}
      />
    </Flex>
  );
};
