import React, { useRef } from "react";
import { IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useUploadFile } from "../../api/blob_files";

type BlobUploadButtonProps = {
  fileableColumn: string;
  recordId: number;
  recordType: string;
  onSuccessCallback: (id?: number | undefined) => () => Promise<void>;
  isDisabled?: boolean;
};

export const BlobUploadButton = ({
  fileableColumn,
  recordId,
  recordType,
  onSuccessCallback,
  isDisabled = false,
}: BlobUploadButtonProps) => {
  // Function to trigger the file input dialog
  const { mutate: uploadFile, isLoading } = useUploadFile(() => onSuccessCallback());
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => {
          encodedFileObjects.forEach((file) => {
            uploadFile({
              content_data_uri: file.file,
              filename: file.name,
              fileable_type: recordType,
              fileable_id: recordId,
              fileable_field: fileableColumn,
            });
          });
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <>
      <IconButton
        size="xs"
        icon={<AddIcon />}
        onClick={handleClick}
        aria-label="Upload file"
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        multiple={true}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
};
