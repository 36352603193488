import React from "react";
import { VStack } from "@chakra-ui/react";
import { TeamShowData } from "../../../../api/team";
import { MilestoneCard } from "../../../Progress/MilestoneCard";
import { NewMilestoneModal } from "../../../Progress/newMilestone";

type Props = {
  team: TeamShowData;
  editable?: boolean;
};

export const TeamMilestones: React.FC<Props> = ({ team, editable }) => (
  <VStack align="start" spacing={4} width="100%">
    {(team.milestones.length > 0 || editable) && (
      <VStack spacing={4} w="100%">
        {team.milestones.map((milestone) => (
          <MilestoneCard key={milestone.id} milestone={{ ...milestone, team }} />
        ))}
        {editable && !team.is_archived && <NewMilestoneModal team={team} />}
      </VStack>
    )}
    {team.milestones.length === 0 && !editable && <p>This team has no milestones yet.</p>}
  </VStack>
);
