import {
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Collection,
  Column,
  EmptyState,
  Facets,
  RecordLink,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TeamServiceModal,
  TeamServiceRequestModal,
} from "../../../Requests/components/TeamServiceModal";
import { UserRequestPill, serviceStatusMap } from "../../../Requests/utils";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";

import { humanize, titleize } from "inflection";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import { FiArchive } from "react-icons/fi";
import { useSearchServiceRequestsQuery } from "../../../../api/service_requests";
import {
  ServicePartialData,
  useDeleteService,
  useGetTeamServicesQuery,
  useUpdateService,
} from "../../../../api/services";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { DeleteModal } from "../../../Hiring/components/DeleteModal";
import { LinkifyText } from "../../../Purchasing/util";

const priorityMap = {
  "3": "red",
  "2": "orange",
  "1": "yellow",
};

export const TeamServices = ({ team }) => {
  const [showArchived, setShowArchived] = useState(false);
  const [activeServices, setActiveServices] = useState<ServicePartialData[]>([]);
  const [archivedServices, setArchivedServices] = useState<ServicePartialData[]>([]);
  const { debouncedSearch, search } = useDebouncedSearch();
  const { onPagination, onOrder, order, pagination, facets, onFacets, filters } = useCollection();

  const toast = useToast();
  const {
    isOpen: isNewServiceOpen,
    onOpen: onNewServiceOpen,
    onClose: onNewServiceClose,
  } = useDisclosure();
  const {
    isOpen: isServiceRequestOpen,
    onOpen: onServiceRequestOpen,
    onClose: onServiceRequestClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteServiceOpen,
    onOpen: onDeleteServiceOpen,
    onClose: onDeleteServiceClose,
  } = useDisclosure();

  const { data: teamServices } = useGetTeamServicesQuery(team.id);
  const { mutate: deleteService } = useDeleteService();

  const AGGREGATION_QUERY = [
    "status",
    "assigned_user_name",
    "service_name",
    "requesting_user_name",
  ];
  const { data, isLoading } = useSearchServiceRequestsQuery({
    aggs: AGGREGATION_QUERY,
    term: search || "*",
    filters: {
      ...filters,
      service_team_id: team.id,
    },
    pagination,
    order,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);
  const currentUser = useCurrentUserQuery();
  const servicesAdmin = userHasRole(currentUser, "services_admin");

  const [serviceModal, setServiceModal] = useState<ServicePartialData | null>(null);

  const [requestModal, setRequestModal] = useState<ServicePartialData | null>(null);

  useEffect(() => {
    if (teamServices) {
      setActiveServices(teamServices.active_services);
      setArchivedServices(teamServices.archived_services);
      if (!teamServices.archived_services?.length) setShowArchived(false);
    }
  }, [teamServices]);

  const columns: Column[] = [
    {
      label: "Requests",
      orderOptions: { orderKey: "id" },
      render: (item) => (
        <Box>
          <RecordLink link={`/services/requests/${item.id}`} identifier={item.id} type="REQUEST" />
        </Box>
      ),
    },
    {
      label: "Requested By",
      render: (item) => (
        <Link to={`/users/${item.id}`}>
          <UserRequestPill user={item.requesting_user} />
        </Link>
      ),
    },
    {
      label: "Service",
      orderOptions: { orderKey: "service_name" },
      render: (item) => item.service_name,
    },
    {
      label: "Details",
      weight: 3,
      render: (item) => (
        <LinkifyText
          text={item.request_description || ""}
          maxWidth={["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"]}
          linkProps={{
            maxWidth: ["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"],
          }}
          isTruncated={true}
        />
      ),
    },

    {
      label: "Created",
      orderOptions: { orderKey: "created_at" },
      render: (item) => DateTime.fromISO(item.created_at).toFormat("LLL dd yyyy"),
    },

    {
      label: "Priority",
      orderOptions: { orderKey: "priority_level" },
      render: (item) => (
        <Badge
          colorScheme={item.priority_level ? priorityMap[item.priority_level] : ""}
          variant="solid">
          {item.priority_level}
        </Badge>
      ),
    },
    {
      label: "Status",
      render: (item) => (
        <VStack>
          <Tag
            colorScheme={
              serviceStatusMap.find((ele) => ele.value === item.status)?.color || undefined
            }>
            {titleize(humanize(item.status))}
          </Tag>
        </VStack>
      ),
    },
  ];
  return (
    <Stack spacing={8} width="100%">
      <VStack align="start" spacing={6} marginBottom={10}>
        <Stack
          width="100%"
          justify="space-between"
          direction={{ base: "column", md: "row" }}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          p={6}>
          <Heading size="lg">Services</Heading>
          {(team.can_act_as_lead || servicesAdmin) && (
            <HStack justify="end">
              <HStack>
                <Text>Show Archive</Text>
                <Switch
                  id="archived"
                  colorScheme="teal"
                  onChange={() => setShowArchived((prev) => !prev)}
                  isChecked={showArchived}
                  isDisabled={archivedServices.length === 0}
                />
              </HStack>

              {!team.is_archived && (
                <Button colorScheme="teal" onClick={onNewServiceOpen}>
                  New Service
                </Button>
              )}
            </HStack>
          )}
        </Stack>
        {showArchived && (
          <>
            <Heading size="md">Archived Services</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={3} width="100%">
              {archivedServices?.map((service) => (
                <ServiceItem
                  service={service}
                  onServiceOpen={onServiceRequestOpen}
                  onNewServiceOpen={onNewServiceOpen}
                  onDeleteOpen={onDeleteServiceOpen}
                  location="team"
                  archived={true}
                  setServiceModal={setServiceModal}
                  setRequestModal={setRequestModal}
                  editable={team.can_act_as_lead || servicesAdmin}
                />
              ))}
            </Grid>
          </>
        )}
        <>
          {showArchived && <Heading size="md">Active Services</Heading>}
          <Grid templateColumns="repeat(3, 1fr)" gap={3} width="100%">
            {activeServices && activeServices.length ? (
              activeServices.map((service) => (
                <ServiceItem
                  service={service}
                  onServiceOpen={onServiceRequestOpen}
                  onNewServiceOpen={onNewServiceOpen}
                  onDeleteOpen={onDeleteServiceOpen}
                  location="team"
                  archived={false}
                  setServiceModal={setServiceModal}
                  setRequestModal={setRequestModal}
                  editable={team.can_act_as_lead || servicesAdmin}
                />
              ))
            ) : (
              <GridItem colSpan={3}>
                <EmptyState title="This team doesn't have any services yet." size="2xs" />
              </GridItem>
            )}
          </Grid>
        </>
        {serviceModal ? (
          <TeamServiceModal
            isOpen={isNewServiceOpen}
            onClose={onNewServiceClose}
            service={serviceModal}
            team={team}
            setActiveService={setServiceModal}
          />
        ) : (
          <TeamServiceModal isOpen={isNewServiceOpen} onClose={onNewServiceClose} team={team} />
        )}
        {requestModal && (
          <TeamServiceRequestModal
            service={requestModal}
            isOpen={isServiceRequestOpen}
            onClose={onServiceRequestClose}
            setActiveService={setRequestModal}
          />
        )}
        <DeleteModal
          title="Delete Service?"
          isOpen={isDeleteServiceOpen}
          onClose={onDeleteServiceClose}
          onSubmit={() =>
            serviceModal &&
            deleteService(serviceModal.id, {
              onSuccess: () => {
                onDeleteServiceClose();
                setServiceModal(null);
                toast({
                  title: "Delete Successful.",
                  description: `You have deleted ${serviceModal.name} successfully.`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              },
            })
          }
        />
      </VStack>
      <HStack
        width="100%"
        justifyContent="space-between"
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={6}>
        <Heading size="lg">Request Queue</Heading>
        <Facets
          facets={populatedFacets}
          onChange={onFacets}
          variant={"button"}
          search
          debouncedSearch={debouncedSearch}
        />
      </HStack>
      {data?.results.length ? (
        <Collection
          columns={columns}
          isLoading={isLoading}
          items={data?.results || []}
          pagination={data?.pagination || pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
        />
      ) : (
        <VStack
          width="100%"
          minHeight="xs"
          justify="center"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}
          opacity="0.7"
          spacing={5}>
          <Search2Icon boxSize={6} />
          <VStack spacing={0}>
            <Text fontWeight="semibold" fontSize="sm">
              It’s empty here.
            </Text>
            <Text fontWeight="medium" fontSize="xs">
              There are no requests.
            </Text>
          </VStack>
        </VStack>
      )}
    </Stack>
  );
};

type ServiceRequestItemProps = {
  service: ServicePartialData;
  onServiceOpen: () => void;
  setRequestModal: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
  location: string;
  archived: boolean;
  setServiceModal?: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
  onNewServiceOpen?: () => void;
  editable?: boolean;
  onDeleteOpen?: () => void;
};

export const ServiceItem = ({
  service,
  onServiceOpen,
  onDeleteOpen,
  location,
  archived,
  setServiceModal,
  setRequestModal,
  onNewServiceOpen,
  editable,
}: ServiceRequestItemProps) => {
  const toast = useToast();
  const { mutate: updateService } = useUpdateService();
  const handleServiceOpen = () => {
    if (setServiceModal) setServiceModal(service);
    if (onNewServiceOpen) onNewServiceOpen();
  };

  const handleRequestOpen = () => {
    if (service.external_url) {
      window.open(service.external_url, "_blank");
    } else {
      setRequestModal(service);
      onServiceOpen();
    }
  };

  return (
    <GridItem colSpan={1} width="100%">
      <Card
        as={Button}
        p={0}
        width="100%"
        height="100%"
        variant="outline"
        bg={useColorModeValue("gray.50", "gray.700")}
        onClick={handleRequestOpen}
        isDisabled={archived}>
        <CardBody width="100%" p={5}>
          <HStack alignItems="center" justify="space-between" height="100%">
            <VStack
              height="100%"
              width="90%"
              alignItems="flex-start"
              justifyContent={"space-between"}
              textAlign="left">
              <Heading size="sm">{service.name}</Heading>
              <Text
                isTruncated
                fontSize="sm"
                fontWeight="medium"
                opacity="0.7"
                whiteSpace="normal"
                noOfLines={2}>
                {service.description}
              </Text>
              {location === "services" && (
                <>
                  <Divider my={2} />
                  <Tag variant="outline" colorScheme="gray">
                    {service.team.name}
                  </Tag>
                </>
              )}
            </VStack>
            {editable && onDeleteOpen && onNewServiceOpen ? (
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<BsThreeDotsVertical size={20} />}
                  size="sm"
                  variant="ghost"
                  onClick={(e) => e.stopPropagation()}
                />
                <MenuList alignItems="center">
                  {!archived ? (
                    <>
                      <MenuItem
                        icon={<EditIcon />}
                        onClick={(e) => {
                          handleServiceOpen();
                          e.stopPropagation();
                        }}>
                        Edit
                      </MenuItem>
                      {service.service_request_count ? (
                        <MenuItem
                          icon={<FiArchive />}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateService(
                              { id: service.id, archived: true },
                              {
                                onSuccess: () => {
                                  toast({
                                    title: "Done",
                                    description: `You have archived ${service.name}.`,
                                    status: "success",
                                    duration: 2000,
                                    position: "bottom-left",
                                    isClosable: true,
                                  });
                                },
                              }
                            );
                          }}>
                          Archive
                        </MenuItem>
                      ) : null}
                      {!service.service_request_count && (
                        <MenuItem
                          opacity="1"
                          icon={<DeleteIcon color="red.500" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (setServiceModal) setServiceModal(service);
                            onDeleteOpen();
                          }}>
                          Delete
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <MenuItem
                      icon={<FaUndo />}
                      isDisabled={false}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateService({ id: service.id, archived: false });
                      }}>
                      Unarchive
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            ) : service.external_url ? (
              <ExternalLinkIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </HStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};
