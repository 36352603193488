import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
  useDisclosure,
  Link,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ConfirmationButton,
  EditableSelect,
  Header,
  SplitPage,
  StatusSelect,
  AttributesTable,
  EditableText,
} from "@sciencecorp/helix-components";
import _ from "lodash";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Project,
  ProjectIndex,
  useGetProject,
  useGetProjectBudgetItems,
  useGetProjects,
  useRemoveProject,
  useSearchProjects,
  useUpdateProject,
} from "../../../api/planning/projects";
import { useActiveUsersQuery, useCurrentUserQuery, userHasRole } from "../../../api/user";
import { SpendingCard } from "../../Budgets/SpendingCard";
import { Headcount } from "../../Budgets/Headcount";
import { CheckpointList } from "./CheckpointList";
import { ProjectDescriptionEditor } from "./ProjectDescriptionEditor";
import { ProjectList } from "./ProjectList";
import { ProjectModal } from "./ProjectModal";
import { invalidateBudget, useBudgetHeadcount } from "../../../api/budget";
import { ProjectDurationBar } from "./ProjectDurationBar";
import { DependenciesTable } from "./DependenciesTable";
import { DeleteIcon } from "@chakra-ui/icons";
import { humanize } from "inflection";
import { useUpdateBudgetHeadcountUsers } from "../../../api/budget_headcounts";
import { useArcOptions } from "../../../api/options";
import { LuUsers } from "react-icons/lu";
import { TbPigMoney } from "react-icons/tb";
import { DateTime } from "luxon";

const draftOptions = [
  { label: "Needs Approval", value: "needs_approval" },
  { label: "Suspended", value: "suspended" },
];
const revertOptions = [
  { label: "Draft", value: "draft" },
  { label: "Suspended", value: "suspended" },
];
const ceoOptions = [
  { label: "Approved", value: "approved" },
  { label: "Declined", value: "declined" },
  { label: "Suspended", value: "suspended" },
];
const activeProjectOptions = [
  { label: "Abandoned", value: "abandoned" },
  { label: "Completed", value: "completed" },
  { label: "Suspended", value: "suspended" },
];

const determineStatusOptions = (status: string, isCeo: boolean) => {
  switch (status) {
    case "draft":
      return draftOptions;
    case "needs_approval":
      if (isCeo) {
        return [...ceoOptions, ...revertOptions];
      } else {
        return revertOptions;
      }
    case "approved":
      return activeProjectOptions;
    case "suspended":
      return [{ label: "Draft", value: "draft" }];
    default:
      return [];
  }
};

const statusMap = [
  { label: "Draft", value: "draft", color: "gray" },
  { label: "Active", value: "active", color: "green" },
  { label: "Approved", value: "approved", color: "blue" },
  { label: "Abandoned", value: "abandoned", color: "orange" },
  { label: "Completed", value: "completed", color: "green" },
  { label: "Suspended", value: "suspended", color: "yellow" },
  { label: "Needs Approval", value: "needs_approval", color: "red" },
  { label: "Declined", value: "declined", color: "red" },
];

const visibilityOptions = [
  { label: "Public", value: "true", color: "orange" },
  { label: "Private", value: "false", color: "blue" },
];

type OverviewTabProps = {
  project: Project;
  allProjects: ProjectIndex[];
  editable: boolean;
};

const OverviewTab = ({ project, allProjects, editable }: OverviewTabProps) => {
  const usersQuery = useActiveUsersQuery();

  return (
    <Flex direction="column" gap={4}>
      <ProjectDescriptionEditor project={project} />
      <DependenciesTable selectedProject={project} sortedProjects={allProjects} />
      <Headcount
        budgetId={project.budget_id}
        users={usersQuery.data || []}
        editable={editable}
        onSuccessCallback={() => invalidateBudget(project.budget_id)}
      />
      <ProjectDurationBar project={project} />
      <CheckpointList project={project} />
    </Flex>
  );
};

export const ProjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const buttonLabel = useBreakpointValue({ base: "Archive", md: "Archive Project" }) || "";

  const { id: paramsProjectId, tab } = useParams();

  const { data: selectedProject, isLoading, isError } = useGetProject(Number(paramsProjectId));
  const arcOptions = useArcOptions();

  const {
    data: allProjects,
    isLoading: allProjectsLoading,
    isError: allProjectsError,
  } = useSearchProjects({
    endDate: DateTime.now().endOf("year"),
    term: "*",
    pagination: { per_page: -1 },
    filters: {},
  });
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const projectBudgetItemsQuery = useGetProjectBudgetItems(selectedProject?.id ?? 0);
  const projectHeadcountQuery = useBudgetHeadcount(selectedProject?.budget_id ?? 0);

  const currentUserQuery = useCurrentUserQuery();

  const isCeo = userHasRole(currentUserQuery, "ceo") || false;
  const editable =
    userHasRole(currentUserQuery, "finance_admin") || selectedProject?.can_act_as_lead || false;
  const sortedProjects = _.sortBy(allProjects?.results || [], (p) => p.id);

  const { mutate: updateProject, mutateAsync: updateProjectAsync } = useUpdateProject();
  const { mutate: updateBudgetHeadcountUser } = useUpdateBudgetHeadcountUsers(() =>
    invalidateBudget(selectedProject?.budget_id)
  );
  const { data: budgetHeadcount } = useBudgetHeadcount(Number(selectedProject?.budget_id));
  const budgetHeadcountUsers = budgetHeadcount?.results
    .map((ele) => ele.budget_headcount_users)
    .flat();
  const projectLead = budgetHeadcountUsers?.find(
    (budget_headcount_user) => budget_headcount_user.is_lead
  );
  const toast = useToast();

  const [activeTab, setActiveTab] = useState(tab || "overview");

  const tabConfig = [
    { label: "Overview", value: "overview", icon: <LuUsers /> },
    {
      label: "Budget",
      value: "budget",
      icon: <TbPigMoney />,
    },
  ];

  useEffect(() => {
    if (!tab) setActiveTab("overview");

    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab]);

  if (isLoading || allProjectsLoading) {
    return (
      <Flex alignItems={"center"}>
        <Spinner />
      </Flex>
    );
  } else if (isError || allProjectsError) {
    return (
      <>
        <Alert status="error">Error loading projects or arcs</Alert>
      </>
    );
  } else if (sortedProjects.length === 0 || !selectedProject) {
    return (
      <Flex width={"100%"} justifyContent={"center"}>
        <ProjectModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
        <VStack>
          <Text>There are no projects, create one!</Text>
          <Button onClick={onCreateModalOpen}>Create a project</Button>
        </VStack>
      </Flex>
    );
  } else {
    return (
      <>
        <Header
          title={selectedProject.title}
          crumbs={[{ label: "Projects", url: "/planning" }]}
          crumbsColor="teal"
          badge={{
            label: humanize(selectedProject.status),
            colorScheme: statusMap.find((ele) => ele.value === selectedProject.status)?.color,
          }}
          actions={[
            <ConfirmationButton
              buttonVariant="outline"
              leftIcon={<DeleteIcon />}
              isDisabled={projectBudgetItemsQuery.isLoading || projectHeadcountQuery.isLoading}
              label={buttonLabel}
              variant="Button"
              confirmationButtonLabel="Yes"
              colorScheme="red"
              children={
                <span>
                  Are you sure you want to archive <strong>{selectedProject.title}</strong>? You
                  cannot undo this action afterwards.
                </span>
              }
              confirmationHeader="Archive Project"
              onConfirm={() =>
                updateProjectAsync({ id: selectedProject.id, archived_at: DateTime.now() })
                  .then(() => navigate("/planning"))
                  .catch((e) => console.error(e))
              }
            />,
          ]}
        />
        <Box overflow="scroll">
          <SplitPage
            key={`project-page-${selectedProject.id}`}
            sidebarWidth="min-content"
            sidebarWidthXL="450px"
            breakpoint="lg"
            sidebar={
              <VStack height="100%" spacing={3}>
                <VStack spacing={2} width="100%">
                  {tabConfig.map(({ label, value, icon }) => (
                    <Button
                      key={value}
                      size={"md"}
                      width="100%"
                      justifyContent="start"
                      colorScheme={activeTab === value ? "teal" : "gray"}
                      bg={activeTab === value ? "teal.500" : "transparent"}
                      leftIcon={icon}
                      onClick={() => {
                        navigate(`/planning/projects/${selectedProject.id}/${value}`);
                        setActiveTab(value);
                      }}>
                      {label}
                    </Button>
                  ))}
                </VStack>
                <VStack
                  bg={useColorModeValue("gray.50", "gray.700")}
                  border="1px"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  px={6}
                  py={5}
                  borderRadius="md"
                  width="100%">
                  <AttributesTable
                    title="Project Details"
                    attributes={[
                      {
                        label: "Title",
                        value: (
                          <EditableText
                            multiline
                            defaultValue={selectedProject.title}
                            onSubmit={(value) => {
                              if (value) updateProject({ id: selectedProject.id, title: value });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Status",
                        value: (
                          <StatusSelect
                            variant="tag"
                            options={determineStatusOptions(selectedProject.status, isCeo)}
                            isDisabled={
                              selectedProject.status === "abandoned" ||
                              selectedProject.status === "completed"
                            }
                            status={statusMap.find((ele) => ele.value === selectedProject.status)}
                            onSubmit={(option) => {
                              option &&
                                updateProject({
                                  id: selectedProject.id,
                                  status: option.toString(),
                                });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Group",
                        value: (
                          <EditableSelect
                            options={arcOptions}
                            selectedValue={selectedProject.arc_id}
                            onSubmit={(arcId) => {
                              const newArcId = arcId === -1 ? null : Number(arcId);
                              updateProjectAsync({
                                id: selectedProject.id,
                                arc_id: newArcId,
                              })
                                .then(() =>
                                  toast({
                                    title: "Success!",
                                    description: "Arc updated",
                                    status: "success",
                                  })
                                )
                                .catch(() =>
                                  toast({
                                    title: "Error!",
                                    description: "Failed to update Arc",
                                    status: "error",
                                  })
                                );
                            }}
                            persistentEdit={true}
                          />
                        ),
                      },
                      {
                        label: "Project Lead",
                        value: (
                          <EditableSelect
                            options={[
                              { label: "No Lead", value: "-1" },
                              ...(budgetHeadcountUsers?.map((user) => ({
                                label: user.user.name,
                                value: user.id,
                              })) || []),
                            ]}
                            selectedValue={projectLead ? projectLead.id : "-1"}
                            onSubmit={(headcountUserId) => {
                              if (headcountUserId !== (projectLead?.id || "-1")) {
                                if (projectLead) {
                                  updateBudgetHeadcountUser({ id: projectLead.id, is_lead: false });
                                }
                                if (headcountUserId !== "-1") {
                                  updateBudgetHeadcountUser({
                                    id: Number(headcountUserId),
                                    is_lead: true,
                                  });
                                }
                              }
                            }}
                            persistentEdit={true}
                            preview={
                              projectLead ? (
                                <Link as={RouterLink} to={`/users/${projectLead?.user.id}`}>
                                  <HStack>
                                    <Avatar
                                      size="sm"
                                      name={projectLead?.user.name}
                                      src={projectLead?.user.picture_uri}
                                    />
                                    <Text>{projectLead?.user.name}</Text>
                                  </HStack>
                                </Link>
                              ) : (
                                <Text as="i" opacity="0.7">
                                  Select Project Lead
                                </Text>
                              )
                            }
                          />
                        ),
                      },
                      {
                        label: "Visibility",
                        value: (
                          <StatusSelect
                            variant="tag"
                            options={visibilityOptions}
                            status={visibilityOptions.find(
                              (option) => option.value === selectedProject.is_public.toString()
                            )}
                            onSubmit={(option) => {
                              const isPublicValue = option === "true";
                              updateProject({
                                id: selectedProject.id,
                                is_public: isPublicValue,
                              });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Autonomous Spending",
                        value: (
                          <StatusSelect
                            isDisabled={
                              !currentUserQuery.isSuccess || !userHasRole(currentUserQuery, "ceo")
                            }
                            variant="tag"
                            options={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                            ]}
                            status={
                              selectedProject.autonomous_spending
                                ? { label: "Yes", value: "yes", color: "green" }
                                : { label: "No", value: "no", color: "red" }
                            }
                            onSubmit={(value) => {
                              updateProject({
                                id: selectedProject.id,
                                autonomous_spending: value === "yes",
                              });
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </VStack>
                <ProjectList selectedProject={selectedProject} arcOptions={arcOptions} />
              </VStack>
            }
            main={
              <Box>
                {activeTab === "overview" && (
                  <OverviewTab
                    project={selectedProject}
                    allProjects={allProjects?.results}
                    editable={editable || userHasRole(currentUserQuery, "project_manager")}
                  />
                )}
                {activeTab === "budget" && (
                  <SpendingCard
                    projectId={selectedProject.id}
                    budgetId={selectedProject.budget_id}
                    editable={editable}
                  />
                )}
              </Box>
            }
          />
        </Box>
      </>
    );
  }
};
