import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  HStack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { inflect } from "inflection";
import React from "react";
import { SpendingAuthorityPreview } from "../../../Purchasing/SpendingAuthoritySelectTree";
import { BudgetItemBody } from "../../../shared/BudgetItemBody";

import { CheckedOutInventory } from "..";
import { BudgetItemWithSummaryData } from "../../../../api/budget_items";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";
import { FormValuesType } from "../CheckoutModal";

type CheckoutSummaryProps = {
  selectedInventory: CheckedOutInventory[];
  sum: Money;
  formValues: FormValuesType;
  budgetItemSummary?: BudgetItemWithSummaryData;
};

export const CheckoutSummary = ({
  selectedInventory,
  sum,
  formValues,
  budgetItemSummary,
}: CheckoutSummaryProps) => {
  const currency = useCurrency();
  return (
    <Flex direction="column" gap={6}>
      <HStack
        width={"100%"}
        align="center"
        justify="center"
        gap={4}
        textAlign="center"
        bg={useColorModeValue("gray.50", "gray.600")}
        borderRadius="md"
        p={6}>
        <VStack spacing={2} align="start" h="100%">
          <Text>You are taking</Text>
          <Tag size="lg">
            {selectedInventory.length} inventory {inflect("item", selectedInventory.length)}
          </Tag>
        </VStack>
        <ArrowForwardIcon boxSize={10} color="green.500" />
        <VStack spacing={2} align="start" h="100%">
          <Text>with a total cost of</Text>
          <Heading size="lg">
            <MoneyText money={sum} formatOptions={{ compact: "never" }} />
          </Heading>
        </VStack>
      </HStack>
      <VStack width="100%" spacing={4} ml={2}>
        <Text>for the spending authority</Text>
        <SpendingAuthorityPreview
          spendingAuthorityId={formValues.spending_authority!.id}
          spendingAuthorityType={formValues.spending_authority!.type}
          outsideOfAttributesTable
        />
      </VStack>
      {(budgetItemSummary?.remaining_amount || Money.zero(currency)).lt(sum) && (
        <Alert status="warning">
          <AlertIcon />
          This budget item does not have sufficient funding to cover the cost of this checkout.
        </Alert>
      )}
      {formValues.spending_authority ? (
        <BudgetItemBody
          spendingAuthority={formValues.spending_authority}
          pendingAmount={sum || Money.zero(currency)}
        />
      ) : (
        <Tag>No Spending Authority</Tag>
      )}
    </Flex>
  );
};
