import _ from "lodash";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from ".";
import { zodParse } from "./zodParse";

const BLOB_FILE_BASE_URL = `/blob_files`;
const BLOB_FILE_CACHE_ROOT = ["uploaded_files"];

export const blobFileSchema = z.object({
  app_href: z.string(),
  filename: z.string(),
  data_uri: z.string().optional(),
  mime_type: z.string(),
  fileable_type: z.string().optional(),
  fileable_id: z.number().optional(),
  fileable_field: z.string().optional(),
  id: z.number(),
});

export type BlobFile = z.infer<typeof blobFileSchema>;
export type CreateBlobFileParams = Omit<BlobFile, "id" | "app_href" | "mime_type"> & {
  content_data_uri: string;
};

type FileProps = {
  file: string;
  filename: string;
};
export const toFileProps = (file: BlobFile): FileProps => {
  return {
    file: file.app_href,
    filename: file.filename,
  };
};

export const invalidateFileCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: BLOB_FILE_CACHE_ROOT,
    });
  };
};

const getFile = async (id: number): Promise<BlobFile> => {
  const response = await api.get(`${BLOB_FILE_BASE_URL}/${id}`);
  return zodParse(blobFileSchema, response.data);
};
export const useGetFile = (id: number) => {
  return useQuery({
    queryKey: [...BLOB_FILE_CACHE_ROOT, "file", id],
    queryFn: _.partial(getFile, id),
  });
};

const uploadFile = async (body: CreateBlobFileParams): Promise<BlobFile> => {
  const response = await api.post(BLOB_FILE_BASE_URL, body);
  return zodParse(blobFileSchema, response.data);
};

export const useUploadFile = (onSuccessCallback?: (id?: number) => () => Promise<void>) => {
  const invFn = invalidateFileCache();
  const cbFn = onSuccessCallback && onSuccessCallback();
  return useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      invFn();
      cbFn && cbFn();
    },
  });
};

const deleteFile = async (id: number): Promise<void> => {
  await api.delete(`${BLOB_FILE_BASE_URL}/${id}`);
};

export const useDeleteFile = (onSuccessCallback?: (id?: number) => () => Promise<void>) => {
  const invFn = invalidateFileCache();
  const cbFn = onSuccessCallback && onSuccessCallback();

  return useMutation({
    mutationFn: deleteFile,
    onSuccess: () => {
      invFn();
      cbFn && cbFn();
    },
  });
};
