import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { indexParams, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";

export const USER_SURVEYS_BASE_URL = "user_surveys";

const userSurveyPromptSchema = z.object({
  ready: z.boolean(),
  prompt: z
    .object({
      id: z.number().optional(),
      question: z.string().optional(),
      answer_type: z.string().optional(),
      answer_options: z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .optional(),
    })
    .optional(),
});

const userSurveyResponseSchema = z.object({
  user_survey_prompt_id: z.number(),
  user: z.object({
    id: z.number(),
    name: z.string(),
    role: z.string(),
    team: z.string(),
  }),
  answer: z.number().nullable(),
  is_skipped: z.boolean(),
  created_at: z.string(),
});

export type UserSurveyPromptData = z.infer<typeof userSurveyPromptSchema>;
export type UserSurveyResponseData = z.infer<typeof userSurveyResponseSchema>;

/** queries */
export const getSampleUserSurvey = async () => {
  const result = await api.get(USER_SURVEYS_BASE_URL);
  return zodParse(userSurveyPromptSchema, result.data);
};
export const completeSurvey = async (user_survey_response: Partial<UserSurveyResponseData>) => {
  const result = await api.post(USER_SURVEYS_BASE_URL, { user_survey_response });
  return result.data;
};
export const getAllSurveyReponses = async () => {
  const result = await api.get(`${USER_SURVEYS_BASE_URL}/admin`);
  return result.data;
};

export const getMostRecentSurveyResponses = async ({
  start_date,
  end_date,
}: {
  start_date?: string;
  end_date?: string;
}) => {
  const result = await api.post(`${USER_SURVEYS_BASE_URL}/results`, { start_date, end_date });
  return result.data;
};

export const searchSurveyResponses = async ({
  user_survey_prompt_id,
  start_date,
  end_date,
  aggs,
  bodyOptions,
  filters,
  pagination,
  order,
  term,
}) => {
  const path = [USER_SURVEYS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), {
    user_survey_prompt_id,
    start_date,
    end_date,
    ...index,
    ...search,
  });
  return result.data;
};

/** hooks */
export const useSampleUserSurveyQuery = () => {
  return useQuery({
    queryKey: [USER_SURVEYS_BASE_URL + "me"],
    queryFn: getSampleUserSurvey,
  });
};
export const useCompleteSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: completeSurvey,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USER_SURVEYS_BASE_URL + "me"] }),
  });
};
export const useGetAllSurveyResponses = () => {
  return useQuery({
    queryKey: [USER_SURVEYS_BASE_URL],
    queryFn: getAllSurveyReponses,
  });
};

export const useGetRecentSurveyResponses = (params) => {
  return useQuery({
    queryKey: [USER_SURVEYS_BASE_URL, params],
    queryFn: () => getMostRecentSurveyResponses(params),
  });
};

export const useUserSurveyResponseSearchQuery = (params) => {
  return useQuery({
    queryKey: [USER_SURVEYS_BASE_URL, params],
    queryFn: () => searchSurveyResponses(params),
  });
};
