import React, { useState } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { AddIcon } from "@chakra-ui/icons";
import { UserMinimalData } from "../../../../api/user";
import {
  useCreateBudgetHeadcount,
  useCreateBudgetHeadcountUsers,
} from "../../../../api/budget_headcounts";
import { priorityOptions } from "../../../Budgets/Headcount";
import { useArchetypeOptions } from "../../../../api/options";

export type TeamMemberOption = { label: string; value: number; user: UserMinimalData };
type AddBudgetHeadcountModalProps = {
  budgetId: number;
  userOptions: TeamMemberOption[];
  isHrAdmin: Boolean;
  onSuccessCallback: (id?: number) => () => Promise<void>;
};

export const AddBudgetHeadcountModal = ({
  budgetId,
  userOptions,
  isHrAdmin,
  onSuccessCallback,
}: AddBudgetHeadcountModalProps) => {
  const { mutate: createBudgetHeadcount, isLoading: isLoadingBudgetHeadcount } =
    useCreateBudgetHeadcount(() => onSuccessCallback());
  const { mutate: createBudgetHeadcountUser, isLoading: isLoadingBudgetHeadcountUser } =
    useCreateBudgetHeadcountUsers(() => onSuccessCallback());

  const archetypeOptions = useArchetypeOptions();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [role, setRole] = useState<number | null>(null);
  const [priority, setPriority] = useState("");
  const [totalPeople, setTotalPeople] = useState(0);
  const [people, setPeople] = useState<readonly TeamMemberOption[]>([]);

  const handleClose = () => {
    setRole(null);
    setPriority("");
    setTotalPeople(0);
    setPeople([]);
    onClose();
  };

  const handleSubmit = () => {
    const usersForRole = people.map((p) => p.value);
    if (budgetId && role) {
      createBudgetHeadcount(
        {
          budget_id: budgetId,
          archetype_id: Number(role),
          total_people: totalPeople,
          priority: priority,
        },
        {
          onSuccess: (data) => {
            createBudgetHeadcountUser({
              budget_headcount_id: data.id,
              user_ids: usersForRole,
            });
            handleClose();
          },
        }
      );
    }
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        onClick={() => onOpen()}
        colorScheme="teal"
        isDisabled={!isHrAdmin || !budgetId}>
        Add Role
      </Button>

      <FormModal
        isOpen={isOpen}
        onClose={onClose}
        submitButtonDisabled={
          role === null || priority === "" || totalPeople === 0 || people.length > totalPeople
        }
        title="Add Role"
        isLoading={isLoadingBudgetHeadcount || isLoadingBudgetHeadcountUser}
        submitButtonColorSchema="teal"
        handleSubmit={handleSubmit}>
        <Flex direction="column" width="100%" gap={4}>
          <FormControl isRequired>
            <FormLabel>Role</FormLabel>
            <Select
              useBasicStyles
              options={archetypeOptions}
              onChange={(value) => {
                if (value) setRole(value.value);
              }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Priority</FormLabel>
            <Select
              useBasicStyles
              options={priorityOptions}
              onChange={(value) => {
                if (value) setPriority(value.value);
              }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Total Headcount for Role</FormLabel>
            <Input type="number" onChange={(e) => setTotalPeople(Number(e.target.value))} />
          </FormControl>
          <FormControl isInvalid={people.length > totalPeople}>
            <FormLabel>Hired Employees</FormLabel>
            <Select
              useBasicStyles
              isDisabled={totalPeople === 0}
              isMulti
              options={userOptions}
              onChange={(value) => {
                if (value) {
                  setPeople(value);
                }
              }}
            />
            <FormErrorMessage>Not enough headcount for this role</FormErrorMessage>
          </FormControl>
        </Flex>
      </FormModal>
    </>
  );
};
