import React from "react";
import { ArrowUpIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import { Header } from "@sciencecorp/helix-components";

import { useNavigate } from "react-router";

export const CandidateFeedbackFormSuccess = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Header
        title="Submit Interview Feedback"
        crumbs={[{ label: "Hiring", url: "/hiring" }]}
        crumbsColor="teal.500"
      />
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          width="md"
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          borderRadius="md"
          p={8}
          gap={8}
          bg={useColorModeValue("white", "gray.700")}>
          <Box
            bg={useColorModeValue("#EDF2F7", "gray.400")}
            width={36}
            height={36}
            borderRadius="md">
            <VStack display="flex" justify="center" align="center">
              <ArrowUpIcon color="gray.500" w={8} h={8} mt={1} />
              <Box>
                <VStack display="flex" align="start">
                  <Box bg="white" boxShadow="xl" width={28} height={7} borderRadius="lg"></Box>
                  <Box bg="white" width={28} height={7} borderRadius="lg"></Box>
                  <HStack>
                    <Box bg="white" width={16} height={4} borderRadius="lg"></Box>
                    <Box bg="teal.400" height={5} width={5} borderRadius="full">
                      <Flex height="100%" width="100%" align="center" justify="center">
                        <CheckIcon color="white" height={3.5} width={3.5} />
                      </Flex>
                    </Box>
                  </HStack>
                </VStack>
              </Box>
            </VStack>
          </Box>
          <VStack>
            <Heading size="md">Done!</Heading>
            <Text>Your feedback has been submitted.</Text>
          </VStack>
          <Button
            width={["80%", "80%", "auto"]}
            onClick={() => navigate("../hiring/completed_interviews")}
            bg="teal.400"
            color="white">
            Okay
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
