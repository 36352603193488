import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const WeekDaysHeader = () => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekendColor = useColorModeValue("gray.400", "gray.400");
  const weekdayColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Grid marginBlockStart={6} templateColumns="repeat(7, 1fr)">
      {daysOfWeek.map((day) => (
        <Flex
          key={"days-of-week-header-" + day}
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="40px"
          position="relative">
          <Text
            fontWeight={"medium"}
            key={day}
            color={day === "Sun" || day === "Sat" ? weekendColor : weekdayColor}>
            {day}
          </Text>
        </Flex>
      ))}
    </Grid>
  );
};

export default WeekDaysHeader;
