import React from "react";
import {
  Box,
  useColorModeValue,
  Text,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useCalendarEventsQuery } from "../../../api/calendar_event";
import { DateTime } from "luxon";
import { CalendarIcon } from "@chakra-ui/icons";
interface EventsListProps {
  onShowCalendar: () => void;
  selectedDate: DateTime;
}

const EventsList: React.FC<EventsListProps> = ({ onShowCalendar, selectedDate }) => {
  const { data: events } = useCalendarEventsQuery();

  const upcomingEvents =
    events
      ?.filter(
        (event) =>
          event.start >= selectedDate.startOf("day") && event.start <= selectedDate.endOf("day")
      )
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
      .slice(0, 4) ?? [];

  return (
    <Flex direction="column" mt={4}>
      <Flex direction="column">
        <Flex justify="space-between" align="baseline" w="100%">
          <Heading
            color={useColorModeValue("gray.600", "default")}
            fontSize="md"
            fontWeight="medium"
            mb={4}>
            Scheduled Events
          </Heading>
          <Button
            color={useColorModeValue("gray.500", "gray.200")}
            onClick={onShowCalendar}
            variant="ghost">
            <CalendarIcon />
          </Button>
        </Flex>
        {upcomingEvents?.length > 0 ? (
          <Flex direction="column" mt={4} gap={2}>
            {upcomingEvents.map((event, index) => (
              <LinkBox key={index} as="article">
                <Box
                  p={4}
                  borderWidth="1px"
                  borderRadius="0.25rem 0rem 0rem 0.25rem"
                  border="1px transparent"
                  borderLeft="4px solid"
                  borderColor="gray.600"
                  backgroundColor={useColorModeValue("gray.100", "gray.700")}
                  _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
                  cursor="pointer"
                  flex={1}>
                  <HStack spacing={4}>
                    <VStack align={"start"} spacing={0}>
                      <Text fontSize={"xl"} fontWeight={"semibold"}>
                        {event.start.toFormat("dd")}
                      </Text>
                      <Text fontSize={"xl"} fontWeight={"semibold"}>
                        {event.start.toFormat("LLL")}
                      </Text>
                    </VStack>
                    <VStack align={"start"} spacing={0}>
                      <LinkOverlay
                        href={event.hangout_link || "#"}
                        isExternal={!!event.hangout_link}>
                        <Text fontSize={"md"} fontWeight={"bold"}>
                          {event.summary}
                        </Text>
                      </LinkOverlay>
                      <Text fontSize={"sm"}>
                        {event.start.toFormat("t")}-{event.end.setZone("local").toFormat("t")}
                      </Text>
                      <Text fontSize={"sm"}>{event.location || "No Location Set"}</Text>
                    </VStack>
                  </HStack>
                </Box>
              </LinkBox>
            ))}
          </Flex>
        ) : (
          <Text
            color={useColorModeValue("gray.600", "gray.200")}
            fontSize="lg"
            mt={4}
            textAlign="center">
            No Events Scheduled
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default EventsList;
