import React from "react";
import { Select } from "@sciencecorp/helix-components";
import {
  Input,
  Switch,
  FormControl,
  FormLabel,
  FormHelperText,
  Flex,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  HStack,
  useColorModeValue,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { Select as MultiSelect } from "chakra-react-select";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { InfoIcon } from "@chakra-ui/icons";
import { InventoryShowData } from "../../../api/inventory";
import { useVendorOptions } from "../../../api/options";
import { InventoryCategoryOptions } from "../util";
import {
  InventoryFormValues,
  unitOfMeasurementOptions,
} from "../Inventories/components/RestockModalComponents/util";

type NewInventoryDetailsProps = {
  inventory?: InventoryShowData;
  formValues: InventoryFormValues;
  control: Control<InventoryFormValues, any>;
  setValue: UseFormSetValue<InventoryFormValues>;
};

export const NewInventoryDetails = ({
  inventory,
  formValues,
  control,
}: NewInventoryDetailsProps) => {
  const vendorOptions = useVendorOptions();

  return (
    <Flex direction="column" w="100%" gap={5}>
      <Stack direction={{ base: "column", md: "row" }} width="100%">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Item Name</FormLabel>
              <Input placeholder="Type here" {...field} />
            </FormControl>
          )}
        />
      </Stack>
      <Stack direction={{ base: "column", md: "row" }} width="100%">
        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Category</FormLabel>
              <Select {...field} options={InventoryCategoryOptions} placeholder="Choose" />
            </FormControl>
          )}
        />
        <Controller
          name="is_consumable"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Type</FormLabel>
              <Select
                {...field}
                options={[
                  { label: "Consumable", value: "consumable" },
                  { label: "Durable", value: "durable" },
                ]}
                placeholder="Choose"
              />
            </FormControl>
          )}
        />
      </Stack>
      <Stack direction={{ base: "column", md: "row" }} width="100%">
        <Controller
          name="sku"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Item ID/SKU</FormLabel>
              <Input {...field} placeholder="Type here" />
            </FormControl>
          )}
        />
        <Controller
          name="unit_of_measurement"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>
                Unit of Measurement{" "}
                <Tooltip label="This unit that will be used to measure the quantity of this item when removing or adding to its stock.">
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <Select {...field} options={unitOfMeasurementOptions} placeholder="Choose" />
            </FormControl>
          )}
        />
      </Stack>

      {!inventory && (
        <Controller
          name="vendors"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Vendor(s)</FormLabel>
              <MultiSelect useBasicStyles {...field} options={vendorOptions} isMulti />
            </FormControl>
          )}
        />
      )}

      <Box
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        bg={useColorModeValue("gray.50", "gray.600")}>
        <Stack direction={{ base: "column", md: "row" }} width="100%">
          <Controller
            name="track_lots"
            control={control}
            render={({ field }) => (
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={5}
                borderRight="1px"
                borderColor="chakra-border-color">
                <VStack align="start" gap={0}>
                  <FormLabel m={0}>Track Lot Numbers</FormLabel>
                  <FormHelperText fontSize="xs">Turn on to monitor lot numbers.</FormHelperText>
                </VStack>
                <Switch
                  isChecked={field.value === true}
                  onChange={field.onChange}
                  colorScheme="teal"
                />
              </FormControl>
            )}
          />
          <Controller
            name="track_expiration_dates"
            control={control}
            render={({ field }) => (
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={5}>
                <VStack align="start" gap={0}>
                  <FormLabel m={0}>Track Expiration</FormLabel>
                  <FormHelperText fontSize="xs">
                    Turn on to monitor expiration dates for each lot.
                  </FormHelperText>
                </VStack>
                <Switch
                  isChecked={field.value === true}
                  onChange={field.onChange}
                  colorScheme="teal"
                />
              </FormControl>
            )}
          />
        </Stack>

        <Controller
          name="track_reorder_threshold"
          control={control}
          render={({ field }) => (
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              p={5}
              borderTop="1px"
              borderColor="chakra-border-color">
              <VStack align="start" gap={0} maxW="65%">
                <FormLabel>Track Reorder Threshold</FormLabel>
                <FormHelperText fontSize="xs">
                  Turn on to manage low stock levels and reorder alerts.
                </FormHelperText>
              </VStack>
              <HStack gap={6}>
                <Switch
                  isChecked={field.value === true}
                  onChange={field.onChange}
                  colorScheme="teal"
                />

                <Box w={32}>
                  <Controller
                    name="reorder_threshold"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        isDisabled={!formValues.track_reorder_threshold}
                        value={field.value}
                        onChange={(value) => field.onChange(Number(value))}>
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                </Box>
              </HStack>
            </FormControl>
          )}
        />
      </Box>
    </Flex>
  );
};
