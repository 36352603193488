import {
  HStack,
  VStack,
  IconButton,
  Icon,
  Text,
  Heading,
  useColorModeValue,
  LinkOverlay,
  LinkBox,
} from "@chakra-ui/react";
import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { NewsPostPreviewData } from "../../api/news_post";
import { Link as RouterLink } from "react-router-dom";

export const NewsPostCard = ({ post }: { post: NewsPostPreviewData }) => {
  return (
    <LinkBox
      mx={-4}
      px={4}
      _hover={{
        bg: useColorModeValue("gray.100", "gray.700"),
        borderRadius: "md",
      }}>
      <LinkOverlay as={RouterLink} to={`/news/${post.id}`}>
        <HStack justify="space-between" py={2}>
          <VStack align="start">
            <Heading
              color={useColorModeValue("gray.800", "gray.200")}
              fontWeight="medium"
              size="sm">
              {post.title}
            </Heading>
            <Text
              color={useColorModeValue("gray.600", "gray.400")}
              fontSize="sm"
              lineHeight="shorter"
              whiteSpace="normal"
              overflowWrap="break-word"
              wordBreak="break-word"
              noOfLines={3}>
              {post.content}
            </Text>
          </VStack>
          <Icon as={FiChevronRight} />
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
