import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";

export const FEATURE_FLAGS_BASE_URL = "feature_flags";

export const featureFlagPartialSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
});
export const featureFlagIndexSchema = z.array(featureFlagPartialSchema);

export type FeatureFlagPartialData = z.infer<typeof featureFlagPartialSchema>;
export type FeatureFlagIndexData = z.infer<typeof featureFlagIndexSchema>;
export type FeatureFlagCreateParams = Pick<FeatureFlagPartialData, "name" | "description">;

/** queries */
export const getFeatureFlags = async () => {
  const result = await api.get(FEATURE_FLAGS_BASE_URL);
  return zodParse(featureFlagIndexSchema, result.data);
};
export const newFeatureFlag = async (feature_flag: FeatureFlagCreateParams) => {
  const result = await api.post(FEATURE_FLAGS_BASE_URL, { feature_flag });
  return zodParse(featureFlagPartialSchema, result.data);
};
export const deleteFeatureFlag = async (id: number) => {
  await api.delete(`${FEATURE_FLAGS_BASE_URL}/${id}`);
};

/** hooks */
export const useFeatureFlagsQuery = () => {
  return useQuery({
    queryKey: [FEATURE_FLAGS_BASE_URL],
    queryFn: getFeatureFlags,
  });
};
export const useNewFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: newFeatureFlag,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [FEATURE_FLAGS_BASE_URL] }),
  });
};
export const useDeleteFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteFeatureFlag,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [FEATURE_FLAGS_BASE_URL] }),
  });
};
