import { Box, HStack, Heading, Tag, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import {
  CollectionPage,
  RecordLink,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { capitalize } from "inflection";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import { EmployeePartialData, getEmployeeCSV, useEmployeeSearchQuery } from "../../api/hr";
import { useCurrentUserQuery, useUserQuery, userHasRole } from "../../api/user";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import TriggerReviewPromptsModal from "./triggerReviewPrompts";
import { HrWarning } from "./warning";
import { CsvDownload } from "../shared/csvDownloadButton";

export const EmployeeTable = () => {
  const { onPagination, onOrder, order, filters, pagination, onFacets, facets } = useCollection({
    pagination: { per_page: 100 },
  });
  const currentUser = useCurrentUserQuery();
  const isHRAdmin = userHasRole(currentUser, "hr_admin");
  const { search, debouncedSearch } = useDebouncedSearch();

  const AGGREGATION_QUERY = ["employee_title", "team_name", "employee_status", "onboarding_lead"];

  const { data, isLoading } = useEmployeeSearchQuery({
    term: search || "*",
    aggs: AGGREGATION_QUERY,
    filters,
    pagination,
    order,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns = React.useMemo(
    () => [
      {
        label: "Employee",
        render: (user: EmployeePartialData) => (
          <RecordLink type="" identifier={user.name} link={`/hr/employees/${user.id}`} />
        ),
      },
      {
        label: "Role",
        orderOptions: { orderKey: "employee_title" },
        render: (user: EmployeePartialData) => user.employee_title,
      },
      {
        label: "Leads",
        render: (user: EmployeePartialData) => (
          <VStack width="100%" align="start">
            {user.leads.length
              ? user.leads.map((lead) => <Link to={`/hr/employees/${lead.id}`}>{lead.name}</Link>)
              : null}
          </VStack>
        ),
      },
      {
        label: "Onboarding Lead",
        orderOptions: { orderKey: "onboarding_lead_id" },
        render: (user: EmployeePartialData) => user.onboarding_lead?.name,
      },
      {
        label: "Teams",
        render: (user: EmployeePartialData) => (
          <VStack align="start">
            {user.teams?.map((team) => (
              <HStack>
                <Link key={team.id} to={`/teams/${team.id}`}>{`${team.name} `}</Link>
              </HStack>
            ))}
          </VStack>
        ),
      },
      {
        label: "Status",
        render: (user: EmployeePartialData) =>
          user.employee_status && (
            <Tag colorScheme={user.employee_status === "active" ? "green" : "gray"}>
              {capitalize(user.employee_status)}
            </Tag>
          ),
      },
      {
        label: "Date Joined",
        render: (user: EmployeePartialData) => (
          <Text>
            {user.joined_at ? (
              DateTime.fromISO(user.joined_at).toFormat("LLL dd yyyy")
            ) : (
              <Text>Unknown</Text>
            )}
          </Text>
        ),
      },
      {
        label: "Last Feedback",
        orderOptions: { orderKey: "last_review_feedback_date" },
        render: (user: EmployeePartialData) =>
          user.last_review_feedback_date
            ? DateTime.fromISO(user.last_review_feedback_date).toFormat("LLL dd yyyy")
            : "-",
      },
    ],
    [data]
  );

  return isHRAdmin ? (
    <>
      <HrWarning />
      <HStack justify="space-between" mb={2}>
        <Heading size="lg">
          All Employees{" "}
          <Box as="span" fontWeight="light" color={useColorModeValue("gray.500", "gray.400")}>
            ({data?.total})
          </Box>
        </Heading>
        <HStack>
          <TriggerReviewPromptsModal />
          <CsvDownload title="employees.csv" apiCall={getEmployeeCSV} buttonName="Employees" />
        </HStack>
      </HStack>
      <CollectionPage
        columns={columns}
        isLoading={isLoading}
        items={data?.results || []}
        facets={populatedFacets}
        onFacets={onFacets}
        facetsProps={{ debouncedSearch: debouncedSearch, background: true, search: true }}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        order={order}
        onOrder={onOrder}
      />
    </>
  ) : (
    <Heading>Not Authorized</Heading>
  );
};

const OnboardingLead = ({ userId }) => {
  const { data } = useUserQuery(userId);
  return <Link to={`/hr/employees/${userId}`}>{data?.name}</Link>;
};
