import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { ImLink } from "react-icons/im";

interface LinkToPurchaseProps {
  purchaseId: number;
}

export const LinkToPurchase = ({ purchaseId }: LinkToPurchaseProps) => {
  return (
    <Flex
      direction={"column"}
      border={"1px"}
      borderRadius={"md"}
      borderColor={useColorModeValue("gray.200", "gray.600")}
      gap={2}
      marginBottom={"3"}
      padding={"4"}>
      <Box
        bgColor={useColorModeValue("green.100", "green.500")}
        height={"100%"}
        width={"max-content"}
        color={useColorModeValue("green.700", "green.100")}
        p={"4"}
        borderRadius={"md"}>
        <ImLink size={"40px"} />
      </Box>
      <Text fontSize={"sm"}>This request is linked to:</Text>
      <RecordLink
        link={`/services/purchasing/${purchaseId}`}
        type="Purchase"
        identifier={`${purchaseId}`}
        justifyContent="align-start"
        width="max-content"
      />
    </Flex>
  );
};
