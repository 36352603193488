import React, { useState } from "react";
import { Button, Stack, VStack, Text, ButtonGroup, HStack, Spacer, Box } from "@chakra-ui/react";
import {
  NotificationData,
  useUpdateNotificationsBulk,
  useMarkAllNotificationsRead,
} from "../../api/notifications";
import { ActionNotificationItem } from "./components/ActionNotificationItem";
import { NotificationTabsProps } from "./util";
import { EmptyState } from "../shared/EmptyState";
import { LuCheck, LuCheckCheck } from "react-icons/lu";
import { PaginationControls } from "@sciencecorp/helix-components";

export const ActionRequiredTab = ({
  notifications,
  currentUser,
  pagination,
  onPagination,
}: NotificationTabsProps) => {
  const [selectedNotifications, setSelectedNotifications] = useState<NotificationData[]>([]);
  const { mutate: updateNotificationsBulk } = useUpdateNotificationsBulk();
  const { mutate: markAllNotificationsRead } = useMarkAllNotificationsRead();
  const handleReadUnread = () => {
    updateNotificationsBulk(
      {
        notification_ids: selectedNotifications.map((notification) => notification.id),
        read: true,
      },
      { onSuccess: () => setSelectedNotifications([]) }
    );
  };

  return notifications.length ? (
    <VStack w="100%" align="start">
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        width="100%"
        border="1px"
        borderRadius="md"
        borderColor="chakra-border-color"
        px={6}
        py={3}>
        {selectedNotifications.length ? (
          <HStack>
            <Text fontSize="sm">{selectedNotifications.length} Items Selected</Text>
            <ButtonGroup variant="ghost" size="sm">
              <Button leftIcon={<LuCheck />} onClick={handleReadUnread}>
                Read
              </Button>
            </ButtonGroup>
          </HStack>
        ) : (
          <Spacer />
        )}
        <HStack justify="space-between">
          <ButtonGroup variant="ghost" colorScheme="teal" size="sm">
            <Button
              leftIcon={<LuCheckCheck />}
              onClick={() => {
                markAllNotificationsRead({ action_required: true, saved: false });
              }}>
              Mark All As Read
            </Button>
          </ButtonGroup>
        </HStack>
      </Stack>
      <VStack width="100%" align="start" spacing={0} mb={3}>
        {notifications.map((notification: NotificationData) => (
          <ActionNotificationItem
            key={notification.id}
            notification={notification}
            currentUser={currentUser}
            selectedNotifications={selectedNotifications}
            setSelectedNotifications={setSelectedNotifications}
          />
        ))}
        <Box width={"100%"}>
          {notifications && (
            <PaginationControls pagination={pagination} onPagination={onPagination} />
          )}
        </Box>
      </VStack>
    </VStack>
  ) : (
    <EmptyState title="No action required notifications" />
  );
};
