import React from "react";
import {
  Button,
  VStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Box,
  ModalHeader,
  useDisclosure,
  ModalOverlay,
  Text,
  Heading,
  ButtonGroup,
  useColorModeValue,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useUpdateNotification } from "../../../api/notifications";

export const UserCredentialIssuedModal = ({ notification }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { mutate: updateNotification } = useUpdateNotification();
  return (
    <>
      <Button colorScheme="teal" size="sm" minW={20} onClick={() => onOpen()}>
        View
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalHeader>Great Work!</ModalHeader>
          <ModalBody mb={4}>
            <VStack>
              <Text
                mb={9}
                fontSize="5xl"
                bg={useColorModeValue("green.50", "gray.400")}
                px={6}
                py={2}
                border="1px"
                borderColor={useColorModeValue("green.200", "gray.600")}
                borderRadius="full">
                🎉
              </Text>
              <Heading size="sm">All Trainings Have Been Completed</Heading>
              <Text>
                You have been awarded the{" "}
                <Box as="span" fontWeight="semibold">
                  {notification.content}{" "}
                </Box>
                credential.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%">
              <Button
                width="50%"
                onClick={() => {
                  navigate(`/credentials/me/${notification.notifiable_id}`);
                  updateNotification({ id: notification.id, read: true });
                }}>
                View Credential
              </Button>
              <Button
                width="50%"
                colorScheme="teal"
                onClick={() => {
                  updateNotification({ id: notification.id, read: true });
                  onClose();
                }}>
                Okay
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
