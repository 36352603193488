import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import { FiChevronRight } from "react-icons/fi";
import { UserLoggedInData } from "../../../api/user";

interface PersonalProjectListProps {
  userData: UserLoggedInData;
}

export const PersonalProjectList: React.FC<PersonalProjectListProps> = ({ userData }) => {
  const userInfo = userData;
  const navigate = useNavigate();

  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box>
      {userInfo.projects.length > 0 &&
        userInfo.projects.map((project) => (
          <LinkBox
            key={"project-" + project.id}
            _hover={{
              bg: hoverBackgroundColor,
              borderRadius: "md",
            }}
            px={4}
            mx={-4}>
            <LinkOverlay as={RouterLink} to={`/planning/projects/${project.id}`}>
              <HStack justify="space-between" align="center" py={4}>
                <Box>
                  <Text fontSize="xs">{project?.arc_title}</Text>
                  <Heading size="md" fontWeight="bold" mr={2}>
                    {project.title}
                  </Heading>
                </Box>
                <Spacer />
                <IconButton
                  icon={<Icon as={FiChevronRight} />}
                  aria-label="Select milestone"
                  bg="none"
                  onClick={() => navigate(`/planning/projects/${project.id}`)}
                />
              </HStack>
              <Divider size="lg" />
            </LinkOverlay>
          </LinkBox>
        ))}
    </Box>
  );
};
