import React from "react";
import { Box, HStack, VStack, Text, Tag, useColorModeValue, Link } from "@chakra-ui/react";
import { Collection, Column, Order, Pagination, RecordLink } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import { UserRequestPill, serviceStatusMap } from "./utils";
import { Search2Icon, InfoOutlineIcon } from "@chakra-ui/icons";
import { LinkifyText } from "../Purchasing/util";
import { ServiceRequestIndexItemData, ServiceRequestSearchData } from "../../api/service_requests";
import { humanize, titleize } from "inflection";

interface YourRequestsTabProps {
  data: ServiceRequestSearchData;
  isLoading: boolean;
  pagination: Pagination;
  onPagination: (newPagination: Pagination) => void;
  order: Order;
  onOrder: (newOrder: Order) => void;
}

const YourRequestsTab: React.FC<YourRequestsTabProps> = ({
  data,
  isLoading,
  pagination,
  onPagination,
  order,
  onOrder,
}) => {
  const columns: Column[] = [
    {
      label: "Requests",
      orderOptions: { orderKey: "id" },
      render: (item: ServiceRequestIndexItemData) => (
        <RecordLink link={`/services/requests/${item?.id}`} identifier={item?.id} type="REQUEST" />
      ),
    },
    {
      label: "Requested By",
      render: (item: ServiceRequestIndexItemData) =>
        item?.requesting_user && (
          <Link href={`/users/${item.requesting_user?.id}`} _hover={{ textDecoration: "none" }}>
            <UserRequestPill user={item.requesting_user} />
          </Link>
        ),
    },
    {
      label: "Service",
      render: (item: ServiceRequestIndexItemData) => item?.service_name || "No Service Specified",
    },
    {
      label: "Details",
      render: (item: ServiceRequestIndexItemData) => (
        <Text noOfLines={[1, 2, 3]}>
          <LinkifyText
            text={item?.request_description || "No description available"}
            maxLinkChars={50}
          />
        </Text>
      ),
    },
    {
      label: "Created",
      render: (item: ServiceRequestIndexItemData) =>
        DateTime.fromISO(item.created_at).toFormat("LLL dd yyyy"),
    },
    {
      label: "Status",
      render: (item: ServiceRequestIndexItemData) => (
        <Tag
          colorScheme={serviceStatusMap.find((ele) => ele.value === item?.status)?.color || "gray"}>
          {titleize(humanize(item.status))}
        </Tag>
      ),
    },
  ].filter(Boolean) as Column[];

  return (
    <Box>
      <HStack
        p={3}
        spacing={4}
        mb={6}
        borderRadius="md"
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.500")}>
        <InfoOutlineIcon color="gray.500" />
        <Text fontSize="sm">
          These are the service requests you're subscribed to, assigned to, and created by you and
          your team members.
        </Text>
      </HStack>
      {isLoading || data?.results?.length ? (
        <Collection
          columns={columns}
          isLoading={isLoading}
          items={data?.results || []}
          pagination={data?.pagination || pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
        />
      ) : (
        <VStack
          width="100%"
          minHeight="sm"
          justify="center"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}
          opacity="0.7"
          spacing={5}>
          <Search2Icon boxSize={6} />
          <VStack spacing={0}>
            <Text fontWeight="semibold" fontSize="sm">
              It’s empty here.
            </Text>
            <Text fontWeight="medium" fontSize="xs">
              There are no requests.
            </Text>
          </VStack>
        </VStack>
      )}
    </Box>
  );
};

export default YourRequestsTab;
