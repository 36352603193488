import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  HStack,
  Button,
  Text,
  VStack,
  Avatar,
  Box,
  Flex,
  Divider,
  useColorModeValue,
  Heading,
  Tag,
  LinkBox,
  Spinner,
  Center,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { ConfirmationButton, Header, RichTextEditor } from "@sciencecorp/helix-components";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useNavigate, useParams } from "react-router";
import { useDeleteNewsPost, useNewsPostQuery, useNewsPostsQuery } from "../../api/news_post";
import { dateTimeWithWeekDayHandler } from "../../helpers/dateTime";
import { NewsCard } from "./NewsCard";

export function NewsPage() {
  const { id } = useParams();
  if (!id) return null;
  const { data: newsPost } = useNewsPostQuery(+id);
  const userQuery = useCurrentUserQuery();
  const { data: newsPosts } = useNewsPostsQuery();
  const navigate = useNavigate();
  const { mutate: deleteNewsPost, isLoading: isLoadingDelete } = useDeleteNewsPost();

  const handleDelete = () => {
    deleteNewsPost(+id, { onSuccess: () => navigate("/news") });
  };

  const promotedPosts = newsPosts?.filter((post) => post.id !== +id).slice(0, 3);
  const actions =
    userHasRole(userQuery, "hr_admin") || userHasRole(userQuery, "news_writer")
      ? [
          <ConfirmationButton
            leftIcon={<DeleteIcon />}
            label="Delete"
            children="Are you sure? You can't undo this action afterwards."
            onConfirm={handleDelete}
            variant="Button"
            buttonVariant="outline"
            size={"sm"}
            colorScheme="red"
            confirmationHeader="Delete Post?"
          />,
          <Button
            size="sm"
            colorScheme="teal"
            leftIcon={<EditIcon />}
            onClick={() => navigate(`/news/${id}/edit`)}>
            Edit
          </Button>,
        ]
      : [];

  return (
    <>
      <Box
        mx={-4}
        marginBlockStart={-3}
        p={4}
        pos={"relative"}
        _before={{
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: useColorModeValue(
            "linear-gradient(to top, #cbd5e0, #c4d1ea, #c3ccf3, #c9c5f8, #d6bcfa)",
            "linear-gradient(to top, #2d3748, #2b3857, #2d3864, #363770, #44337a)"
          ),
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.2,
          zIndex: -1,
        }}>
        {newsPost ? (
          <Flex
            justify={["flex-start", "center"]}
            paddingLeft={{ base: 5, sm: 5 }}
            marginInline={-4}
            paddingBlockStart={[16, 36]}
            paddingBlockEnd={5}>
            <Box w="50rem">
              <VStack align="start">
                <Heading fontWeight={["regular", "light"]} size={["xl", "3xl"]}>
                  {newsPost?.title}
                </Heading>

                <Stack direction={["column", "row"]} marginBlockStart={2}>
                  <LinkBox as={RouterLink} to={`/users/${newsPost?.user.id}`}>
                    <HStack>
                      <Avatar
                        size="xs"
                        name={newsPost?.user?.name}
                        src={newsPost?.user?.picture_uri}
                        colorScheme="gray"
                      />

                      <Text fontWeight="regular" fontSize={"sm"}>
                        {newsPost?.user?.name}
                      </Text>
                    </HStack>
                  </LinkBox>
                  <Tag
                    maxW={"max-content"}
                    variant="outline"
                    textTransform="capitalize"
                    borderColor="gray.500">
                    {newsPost?.category?.split("_").join(" ")}
                  </Tag>

                  <Text color="gray.500" fontSize="sm">
                    {dateTimeWithWeekDayHandler(newsPost?.created_at!)}
                  </Text>
                </Stack>
              </VStack>
            </Box>
          </Flex>
        ) : (
          <Center paddingBlock={24}>
            <Spinner size="lg" />
          </Center>
        )}
      </Box>

      <Flex paddingBlockStart={6} justify={["flex-start", "center"]} w="100%">
        <VStack align="start" w="3xl" paddingBlockEnd={12}>
          {newsPost?.raw_text && (
            <RichTextEditor
              editable={false}
              defaultValue={JSON.stringify(newsPost?.raw_text)}
              border={false}
            />
          )}
          <HStack width="100%" justify="end">
            {actions}
          </HStack>
          {promotedPosts?.length ? (
            <VStack align="start" paddingBlockStart={12} w="100%">
              <Heading size="sm">More to Read</Heading>
              <Divider />
              {promotedPosts?.map((newsPost) => (
                <NewsCard newsPost={newsPost} />
              ))}
            </VStack>
          ) : null}
        </VStack>
      </Flex>
    </>
  );
}
