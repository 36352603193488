import { newChatMessage, newChatThread } from "../../api/chats";
import { ChatObject, WebsocketMessage } from "./types";

export interface ChatState {
  threadId?: number;
  chatObjects: ChatObject[];
  streamingTextContent: string;
  currentPlugin?: ChatObject;
  thinking: boolean;
}

const appendChatObject = (state: ChatState, newChatObject?: ChatObject): ChatState => {
  if (!newChatObject) return state;

  // save every new message to the server, starting a new thread if we don't already have one
  if (state.threadId) {
    newChatMessage(state.threadId, newChatObject);
  } else {
    newChatThread(newChatObject.content || "").then((threadId) => {
      newChatMessage(threadId, newChatObject);
    });
  }

  return { ...state, chatObjects: [...state.chatObjects, newChatObject] };
};

const appendStreamingTextContent = (state: ChatState, content?: string): ChatState => {
  if (!content) return state;
  return {
    ...state,
    streamingTextContent: (state.streamingTextContent + content)
      .split(/<%\*\?\?\*%>.*?<%\*\?\?\*%>/) // remove pairs of plugin indicators
      .join(""),
  };
};

const setCurrentPlugin = (state: ChatState, message?: WebsocketMessage["message"]): ChatState => {
  if (!message || !message.plugin) return state;
  return {
    ...state,
    currentPlugin: {
      sender: "agent",
      type: "plugin",
      content:
        (state.currentPlugin?.content || "") + (message.content ? `\n${message.content}` : ""),
      plugin: {
        ...message.plugin,
        icon: "https://placekitten.com/75/75",
      },
    },
  };
};

export const chatReducer = (
  state: ChatState,
  data:
    | WebsocketMessage
    | { type: "set-thread-id"; threadId?: number }
    | { type: "set-chat-messages"; messages?: ChatObject[] }
    | { type: "user-message"; object: ChatObject }
): ChatState => {
  switch (data.type) {
    case "set-thread-id":
      return { ...state, threadId: data.threadId };
    case "set-chat-messages":
      return {
        ...state,
        chatObjects: data.messages || [],
        streamingTextContent: "",
        currentPlugin: undefined,
      };
    case "user-message":
      return appendChatObject(state, data.object);
    case "agent-start":
      return { ...state, thinking: true };
    case "agent-stream-delta":
      return appendStreamingTextContent(state, data.message?.content);
    case "agent-message":
      return appendStreamingTextContent(state, data.message?.content);
    case "agent-finish":
      return {
        ...appendChatObject(state, {
          sender: "agent",
          type: "text",
          content: state.streamingTextContent,
        }),
        streamingTextContent: "",
        thinking: false,
      };
    case "agent-error":
      return {
        ...appendChatObject(state, { content: data.error, type: "error", sender: "agent" }),
        thinking: false,
      };
    case "plugin-start":
      return setCurrentPlugin(state, data.message);
    case "plugin-message":
      return setCurrentPlugin(state, data.message);
    case "plugin-finish":
      return { ...appendChatObject(state, state.currentPlugin), currentPlugin: undefined };
    case "plugin-error":
      return {
        ...appendChatObject(state, { error: data.error, type: "plugin", sender: "agent" }),
        currentPlugin: undefined,
      };
    default:
      return state;
  }
};
