import React, { useEffect, useMemo } from "react";

import * as _ from "lodash";
import { useCurrentUserQuery } from "../../../api/user";
import {
  Header,
  Collection,
  Column,
  DeleteWithConfirmation,
  EditableText,
  ToggleButton,
  useCollection,
  Facet,
  Facets,
  buildFacets,
  SplitPage,
  Order,
  Pagination,
  EmptyState,
} from "@sciencecorp/helix-components";
import { useLineItemsSearchQuery, LineItem } from "../../../api/finance";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Heading,
  Select,
  Spacer,
  Stack,
  Flex,
  Table,
  Text,
  IconButton,
  useToken,
  useColorModeValue,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  VStack,
  Accordion,
  Collapse,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  ThemingProps,
} from "@chakra-ui/react";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { DateTime } from "luxon";
import { MoneyText } from "../../MoneyText";
import { Filter, Filters, FilterValue } from "@sciencecorp/helix-components/dist/types";
import { InitialPagination } from "@sciencecorp/helix-components/dist/hooks/useCollection";

function ExternalTypeBadge(props: { externalType: string }): JSX.Element {
  const typeToColor = {
    Payment: "red",
    Deposit: "green",
    Transfer: "pink",
    Purchase: "yellow",
    Bill: "orange",
    Billpayment: "red",
  };
  const colorScheme: string | undefined = typeToColor[props.externalType];

  return (
    <Badge p={2} colorScheme={colorScheme}>
      <Text>{props.externalType}</Text>
    </Badge>
  );
}

function Amount(props: { lineItem: LineItem }): JSX.Element {
  const direction = props.lineItem.direction;
  const classification = props.lineItem.account.classification;
  let color: string | undefined;
  const isLER = ["liability", "equity", "revenue"].includes(classification);
  const isCredit = direction === "credit";
  //   if ((isLER && isCredit) || (!isLER && !isCredit)) {
  //     color = "green";
  //   }
  return <MoneyText color={color} money={props.lineItem.amount} />;
}

type ColumnKey =
  | "name"
  | "memo"
  | "date"
  | "account_info"
  | "account_name"
  | "account_number"
  | "account_classification"
  | "type"
  | "team"
  | "sub_team"
  | "direction"
  | "amount";

type ColumnWithId = Column & { id: ColumnKey };
function ledgerTableColumns(): ColumnWithId[] {
  return [
    {
      id: "name",
      label: "Name",
      orderOptions: { orderKey: "vendor_name" },
      render: (lineItem: LineItem) => <Text>{lineItem.vendor && lineItem.vendor.name}</Text>,
    },
    {
      id: "memo",
      label: "Memo",
      render: (lineItem: LineItem) => {
        if (lineItem.memo === "REDACTED") {
          return <Text>[Hidden due to sensitive information]</Text>;
        }
        return (
          <Text isTruncated={true} width={"40ch"}>
            {lineItem.memo}
          </Text>
        );
      },
    },
    {
      id: "date",
      label: "Date",
      orderOptions: { orderKey: "occurred_at" },
      render: (lineItem: LineItem) => <Text>{lineItem.occurred_at.toFormat("MMM d yyyy")}</Text>,
    },
    {
      id: "account_info",
      label: "Account",
      orderOptions: { orderKey: "account_info" },
      render: (lineItem: LineItem) => (
        <Text>
          {lineItem.account.account_number} {lineItem.account.name}
        </Text>
      ),
    },
    {
      id: "account_number",
      label: "Acc. Number",
      orderOptions: { orderKey: "account_number" },
      render: (lineItem: LineItem) => <Text>{lineItem.account.account_number}</Text>,
    },
    {
      id: "account_name",
      label: "Acc. Name",
      orderOptions: { orderKey: "account_name" },
      render: (lineItem: LineItem) => <Text>{lineItem.account.name}</Text>,
    },
    {
      id: "account_classification",
      label: "Acc. Classification",
      render: (lineItem: LineItem) => <Text>{lineItem.account.classification}</Text>,
    },
    {
      id: "type",
      label: "Type",
      orderOptions: { orderKey: "transaction_external_type" },
      render: (lineItem: LineItem) => (
        <ExternalTypeBadge externalType={lineItem.accounting_transaction.external_type} />
      ),
    },
    {
      id: "team",
      label: "Team",
      orderOptions: { orderKey: "top_level_team_name" },
      render: (lineItem: LineItem) => (
        <Text>{lineItem.top_level_team && lineItem.top_level_team.name}</Text>
      ),
    },
    {
      id: "sub_team",
      label: "Sub Team",
      orderOptions: { orderKey: "team_name" },
      render: (lineItem: LineItem) => <Text>{lineItem.team && lineItem.team.name}</Text>,
    },
    {
      id: "direction",
      label: "Debit/Credit",
      orderOptions: { orderKey: "direction" },
      render: (lineItem: LineItem) => (
        <Text>{lineItem.direction === "debit" ? "Debit" : "Credit"}</Text>
      ),
    },
    {
      id: "amount",
      label: "Amount",
      orderOptions: { orderKey: "amount_cents" },
      render: (lineItem: LineItem) => <Amount lineItem={lineItem} />,
    },
  ];
}

const allAggregations: Record<string, Facet> = {
  transaction_external_type: { label: "Type" },
  account_info: { label: "Account" },
  account_classification: { label: "Acc. Classification" },
  team_name: { label: "Sub Team" },
  top_level_team_name: { label: "Team" },
};
type AggregationKey = keyof typeof allAggregations;

const bodyOptions = {
  Amount: {
    range: {
      field: "amount",
      ranges: [
        { from: 0, to: 5000, key: "$0 to $50" },
        { from: 5001, to: 10000, key: "$50 to $100" },
        { from: 10001, to: 20000, key: "$100 to $200" },
        { from: 20001, to: 50000, key: "$200 to $500" },
        { from: 50001, to: 100000, key: "$500 to $1000" },
        { from: 100001, key: "More than $1000" },
      ],
    },
  },
  Date: {
    range: {
      field: "occurred_at",
      ranges: _.range(0, 12).map((i: number) => {
        const start = DateTime.now().startOf("year").plus({ months: i });
        const end = DateTime.now()
          .startOf("year")
          .plus({ months: i + 1 });
        return {
          from: start.toISODate(),
          to: end.toISODate(),
          key: start.toISODate(),
        };
      }),
    },
  },
};

interface LedgerTableProps {
  initialColumns?: ColumnKey[];
  excludeAggregations?: AggregationKey[];
  extraFilters?: Filters;
  initialPagination?: InitialPagination;
}

interface LedgerCollectionProps {
  lineItemsQuery: ReturnType<typeof useLineItemsSearchQuery>;
  columnSelector: ColumnSelectorProps;
  populatedFacets: Facets;
  selectedIndecies: number[];
  debouncedSearch: _.DebouncedFunc<(search: string) => void>;
  onFacets: (facets: Facets) => void;
  onOrder: (order: Order) => void;
  onPagination: (pagination: Pagination) => void;
  pagination: Pagination;
  order: Order;
}
export const useLedger = ({
  initialColumns = ["name", "memo", "date", "account_info", "type", "team", "amount"],
  excludeAggregations = [],
  extraFilters = {},
  initialPagination = { per_page: 20 },
}: LedgerTableProps): LedgerCollectionProps => {
  const columnSelector = useColumnSelector(initialColumns);

  const {
    pagination,
    onPagination,
    filters: collectionFilters,
    order,
    onOrder,
    facets,
    onFacets,
  } = useCollection({
    order: { occurred_at: "desc" },
    pagination: initialPagination,
  });

  const filters = useMemo(
    () => ({ ...extraFilters, ...collectionFilters }),
    [extraFilters, collectionFilters]
  );

  useEffect(() => {
    onOrder({ occurred_at: "desc" });
  }, [columnSelector.selectedColumns]);

  const { debouncedSearch, search } = useDebouncedSearch();
  const aggregations = useMemo(
    () => _.omit(allAggregations, excludeAggregations),
    [allAggregations, excludeAggregations]
  );
  const lineItemsQuery = useLineItemsSearchQuery({
    term: search || "*",
    aggs: _.keys(_.omit(aggregations, excludeAggregations)),
    bodyOptions: bodyOptions,
    filters,
    pagination,
    order,
  });
  const populatedFacets = _.merge(
    buildFacets(lineItemsQuery.data?.aggregations || {}, facets),
    aggregations
  );
  const sortedFacetKeys = _.keys(populatedFacets).sort();
  const selectedIndecies = _.keys(filters)
    .map((key) => sortedFacetKeys.indexOf(key))
    .sort();
  return {
    lineItemsQuery,
    columnSelector,
    populatedFacets,
    selectedIndecies,
    debouncedSearch,
    onFacets,
    onOrder,
    onPagination,
    pagination,
    order,
  };
};

type LedgerCollectionSubProps = Pick<
  LedgerCollectionProps,
  "columnSelector" | "lineItemsQuery" | "pagination" | "onPagination" | "order" | "onOrder"
>;
export const LedgerCollection = ({
  columnSelector,
  lineItemsQuery,
  pagination,
  onPagination,
  order,
  onOrder,
}: LedgerCollectionSubProps) => {
  return lineItemsQuery.data?.results.length ? (
    <Collection
      columns={columnSelector.selectedColumns}
      isLoading={lineItemsQuery.isLoading}
      items={lineItemsQuery.data?.results || []}
      pagination={lineItemsQuery.data?.pagination || pagination}
      onPagination={onPagination}
      order={order}
      onOrder={onOrder}
    />
  ) : (
    <EmptyState title="No Quickbooks Transactions" size="3xs" />
  );
};

export function GeneralLedgerTable({
  initialColumns = ["name", "memo", "date", "account_info", "type", "team", "amount"],
  excludeAggregations = [],
  extraFilters = {},
  initialPagination = { per_page: 20 },
}: LedgerTableProps): JSX.Element {
  const ledgerData = useLedger({
    initialColumns,
    excludeAggregations,
    extraFilters,
    initialPagination,
  });
  const {
    lineItemsQuery,
    columnSelector,
    populatedFacets,
    selectedIndecies,
    debouncedSearch,
    onFacets,
  } = ledgerData;
  if (lineItemsQuery.isError) {
    return <p>Unable to retreive data</p>;
  }
  return (
    <>
      <Box display={["flex", "flex", "none"]} justifyContent="flex-end" gap={4}>
        <Facets
          defaultIndex={selectedIndecies}
          facets={populatedFacets}
          onChange={onFacets}
          variant="button"
          search
          background
          debouncedSearch={debouncedSearch}
        />
        <ColumnSelector {...columnSelector} variant={"popover"} />
      </Box>
      <SplitPage
        sidebarWidthXL="250px"
        sidebar={
          <Box display={["none", "none", "flex"]} gap={4} flexDir={"column"} w="max-content">
            <Facets
              defaultIndex={selectedIndecies}
              facets={populatedFacets}
              onChange={onFacets}
              search
              background
              debouncedSearch={debouncedSearch}
            />
            <ColumnSelector {...columnSelector} variant={"accordion"} />
          </Box>
        }
        main={
          <Box overflowX="auto">
            <LedgerCollection {...ledgerData} />
          </Box>
        }
      />
    </>
  );
}

export const allColumns: ColumnKey[] = [
  "name",
  "memo",
  "date",
  "account_info",
  "type",
  "team",
  "sub_team",
  "amount",
  "direction",
];

const filterColumns = (columns: ColumnWithId[], excludeColumns: ColumnKey[]): ColumnWithId[] => {
  return columns.filter((c) => excludeColumns.includes(c.id));
};

export interface ColumnSelectorProps {
  allColumns: ColumnWithId[];
  selectedColumns: ColumnWithId[];
  setSelectedColumns: (columns: ColumnWithId[]) => void;
  variant?: "popover" | "accordion";
}

export const useColumnSelector = (initialColumns?: ColumnKey[]): ColumnSelectorProps => {
  const allColumns = useMemo(() => ledgerTableColumns(), []);
  if (!initialColumns) {
    initialColumns = allColumns.map((c) => c.id);
  }
  const [selectedColumns, setSelectedColumns] = React.useState<ColumnWithId[]>(
    filterColumns(allColumns, initialColumns)
  );
  return { allColumns, selectedColumns, setSelectedColumns };
};
interface ColumnSelectorCheckboxProps {
  allColumns: ColumnWithId[];
  column: ColumnWithId;
  selectedColumnIds: string[];
  setSelectedColumns: (columns: ColumnWithId[]) => void;
}
const ColumnSelectorCheckbox = ({
  allColumns,
  column,
  selectedColumnIds,
  setSelectedColumns,
}: ColumnSelectorCheckboxProps): JSX.Element => {
  return (
    <Checkbox
      key={column.id}
      isChecked={selectedColumnIds.includes(column.id)}
      disabled={selectedColumnIds.length === 1 && selectedColumnIds.includes(column.id)}
      onChange={(e) => {
        if (e.target.checked && !selectedColumnIds.includes(column.id)) {
          setSelectedColumns(
            allColumns.filter((c) => c.id === column.id || selectedColumnIds.includes(c.id))
          );
        } else {
          if (selectedColumnIds.length > 1) {
            setSelectedColumns(
              allColumns.filter((c) => c.id !== column.id && selectedColumnIds.includes(c.id))
            );
          }
        }
      }}>
      {column.label}
    </Checkbox>
  );
};

export const ColumnSelector = ({
  allColumns,
  selectedColumns,
  setSelectedColumns,
  variant = "popover",
}: ColumnSelectorProps) => {
  const bg = useColorModeValue("gray.50", "gray.700");
  const selectedColumnIds = useMemo(() => selectedColumns.map((c) => c.id), [selectedColumns]);
  if (variant === "popover") {
    return (
      <Popover>
        <PopoverTrigger>
          <Button>Show/Hide Columns</Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Stack>
              {allColumns.map((column) => (
                <ColumnSelectorCheckbox
                  column={column}
                  allColumns={allColumns}
                  selectedColumnIds={selectedColumnIds}
                  setSelectedColumns={setSelectedColumns}
                />
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  } else {
    return (
      <Box bg={bg} borderRadius="md" p={4}>
        <Text fontSize="sm" fontWeight="semibold" pb={4}>
          Show/Hide Columns:
        </Text>
        <Stack>
          {allColumns.map((column) => (
            <ColumnSelectorCheckbox
              column={column}
              allColumns={allColumns}
              selectedColumnIds={selectedColumnIds}
              setSelectedColumns={setSelectedColumns}
            />
          ))}
        </Stack>
      </Box>
    );
  }
};

export const GeneralLedger = () => {
  return (
    <>
      <Box>
        <Header title="General Ledger" />
        <GeneralLedgerTable />
      </Box>
    </>
  );
};
