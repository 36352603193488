import { StatGroup, useColorModeValue, Flex, Heading, Alert } from "@chakra-ui/react";
import React from "react";
import { useBudgetItemSpendByModel } from "../../../api/budget_items";
import { humanize, titleize } from "inflection";
import { DateTime } from "luxon";
import { StatInfo } from "../../shared/StatInfo";
interface BudgetItemSpendByModelStatsProps {
  budgetItemId: number;
}

export const BudgetItemSpendByModelStats = ({ budgetItemId }: BudgetItemSpendByModelStatsProps) => {
  const endDate = DateTime.now().endOf("year");
  const {
    data: spendByModel,
    isLoading,
    isError,
  } = useBudgetItemSpendByModel(budgetItemId, endDate);

  return (
    <Flex
      direction="column"
      p={4}
      gap={4}
      borderRadius={"md"}
      borderWidth={"1px"}
      borderColor={useColorModeValue("gray.200", "gray.600")}>
      <Heading size="md">Spend By Expenditure Type</Heading>
      {!isError ? (
        <StatGroup w="100%" gap={{ base: 4, md: 6, lg: 16 }} justifyContent={"space-around"}>
          {Object.entries(spendByModel || {}).map(([key, value]) => (
            <StatInfo
              key={key}
              isLoading={isLoading}
              label={titleize(humanize(key))}
              statMoney={value.total_spent}
              subText={`committed ${
                value.total_committed ? value.total_committed.format({ compact: "never" }) : "$0.00"
              }`}
            />
          ))}
        </StatGroup>
      ) : (
        <Alert status="error">Failed to load spend by model data</Alert>
      )}
    </Flex>
  );
};
