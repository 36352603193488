import React, { useMemo } from "react";
import {
  Box,
  Divider,
  Heading,
  Spinner,
  Stat,
  useColorModeValue,
  VStack,
  StatGroup,
  StatLabel,
  StatNumber,
  Flex,
} from "@chakra-ui/react";
import { SurveyBarChart } from "./SurveyBarChart";
import { SkipLineChart } from "./SkipLineChart";
import { createVictoryData } from "../util";
import { useCollection, Collection, RecordLink } from "@sciencecorp/helix-components";
import { getSentimentBucket } from "../util";

import { UserSurveyResponseData, useUserSurveyResponseSearchQuery } from "../../../../api/surveys";

type SurveyLayoutProps = {
  prompt_id: number;
  startDate: string;
  endDate: string;
  labels: any;
  colors: string[];
};

export const SurveyLayout = ({
  prompt_id,
  startDate,
  endDate,
  colors,
  labels,
}: SurveyLayoutProps) => {
  const { onPagination, onOrder, onFacets, pagination, order, facets, filters } = useCollection({
    pagination: { page: 1, per_page: 10, total: 0, n_pages: 0 },
  });

  const { data: surveyData, isLoading } = useUserSurveyResponseSearchQuery({
    user_survey_prompt_id: prompt_id,
    start_date: startDate.length ? startDate : undefined,
    end_date: endDate.length ? endDate : undefined,
    term: "*",
    filters,
    pagination,
    order,
  });

  const counter =
    surveyData?.prompt.answer_type === "slider"
      ? {
          "0-20%": 0,
          "21-40%": 0,
          "41-60%": 0,
          "61-80%": 0,
          "81-100%": 0,
        }
      : {
          "Strong No": 0,
          No: 0,
          Yes: 0,
          "Strong Yes": 0,
        };

  const barChartData = useMemo(
    () => surveyData && createVictoryData(surveyData.responses, prompt_id, counter),
    [surveyData]
  );

  const columns = useMemo(
    () => [
      {
        label: "Name",
        render: (response: UserSurveyResponseData) => (
          <RecordLink
            link={`/hr/employees/${response.user.id}`}
            type=""
            identifier={response.user.name}
          />
        ),
      },
      {
        label: "Role",
        render: (response: UserSurveyResponseData) => response.user.role,
      },
      { label: "Team", render: (response: UserSurveyResponseData) => response.user.team },
      {
        label: "Answer",
        render: (response: UserSurveyResponseData) =>
          response.user_survey_prompt_id !== 1
            ? getSentimentBucket(response.answer)
            : response.answer,
      },
    ],
    [surveyData]
  );

  return (
    <VStack
      width={{ base: "100%", lg: "50%" }}
      align={{ base: "center", lg: "start" }}
      spacing={8}
      justify="stretch">
      <VStack
        width="100%"
        align="start"
        spacing={6}
        p={5}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Heading size="md">{labels.heading}</Heading>
        {surveyData ? (
          <>
            <StatGroup width="100%" px={3}>
              <Stat>
                <StatLabel>No of Times Seen</StatLabel>
                <StatNumber>{surveyData.analytics.total_responses}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Responses</StatLabel>
                <StatNumber>
                  {surveyData.analytics.total_responses - surveyData.analytics.skipped_responses}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Skip Rate</StatLabel>
                <StatNumber>
                  {(
                    (surveyData.analytics.skipped_responses /
                      surveyData.analytics.total_responses) *
                    100
                  ).toFixed()}
                  %
                </StatNumber>
              </Stat>
            </StatGroup>
            <SurveyBarChart
              data={barChartData}
              xAxisLabels={labels.xAxisLabels}
              colors={colors}
              label={labels.graphLabel}
            />

            <Divider color="chakra-border-color" />
            <VStack align="start" width="100%">
              <Heading size="sm">
                Number of Skips{" "}
                <Box as="span" fontWeight="light" color={useColorModeValue("gray.500", "gray.300")}>
                  ({surveyData.analytics.skipped_responses})
                </Box>
              </Heading>
              <SkipLineChart data={surveyData.skipped_responses} />
            </VStack>
          </>
        ) : (
          <Flex justify="center" align="center" width="100%">
            <Spinner size="xl" />
          </Flex>
        )}
      </VStack>
      <VStack
        width="100%"
        py={{ base: 2, lg: 4 }}
        p={5}
        flex="1"
        spacing={3}
        align="space-between"
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Heading size="md">{labels.tableHeading}</Heading>
        <Collection
          isLoading={isLoading}
          items={surveyData?.results || []}
          columns={columns}
          pagination={surveyData?.pagination || pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
        />
      </VStack>
    </VStack>
  );
};
