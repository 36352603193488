import {
  Divider,
  Flex,
  Heading,
  Stack,
  Tag,
  Text,
  HStack,
  useDisclosure,
  Tooltip,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { PurchaseShowData } from "../../api/purchase";
import { MinimalSpendingAuthority, SpendingAuthorityShowData } from "../../api/spending_authority";
import { SpendingAuthorityChangeRequestData } from "../../api/spending_authority_change_requests";
import { ChangeSpendingAuthorityModal } from "../Purchasing/Purchase/ChangeSpendingAuthorityModal";
import { SpendingAuthorityPreview } from "../Purchasing/SpendingAuthoritySelectTree";
import { BudgetItemBody } from "./BudgetItemBody";
import { RecordLink } from "@sciencecorp/helix-components";
import { Money } from "../../helpers/Money";

export interface ApprovalFlowSpendingAuthorityInfoProps {
  spendingAuthority?: MinimalSpendingAuthority | null;
  purchase: PurchaseShowData;
  currentUser: any;
  userCanSubmit?: boolean;
  pendingAmount: Money;
  rootSpendingAuthority?: SpendingAuthorityShowData | null;
}

export const toShowApprovalFlow = (spendingAuthorityRequest) => {
  return (
    spendingAuthorityRequest &&
    spendingAuthorityRequest.approval_state !== "declined" &&
    spendingAuthorityRequest.approval_state !== "approved"
  );
};
export const ApprovalFlowSpendingAuthorityInfo: React.FC<
  ApprovalFlowSpendingAuthorityInfoProps
> = ({ spendingAuthority, pendingAmount, rootSpendingAuthority, purchase, currentUser }) => {
  const spendingAuthorityRequest: SpendingAuthorityChangeRequestData =
    purchase.spending_authority_change_requests[
      purchase.spending_authority_change_requests.length - 1
    ];

  const approvedStates = ["approved", "awaiting_payment", "awaiting_purchase", "delivered"];
  const pastEditableState = useMemo(
    () => approvedStates.includes(purchase.approval_state),
    [purchase.approval_state]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderSpendingAuthorityChanges = () => {
    let spendingAuthorityType, spendingAuthorityId;

    if (!toShowApprovalFlow(spendingAuthorityRequest)) {
      return (
        purchase.spending_authority &&
        purchase.root_spending_authority && (
          <Box marginTop={4}>
            <SpendingAuthorityPreview
              spendingAuthorityType={purchase.spending_authority.type}
              spendingAuthorityId={purchase.spending_authority.id}
              rootSpendingAuthorityId={purchase.root_spending_authority.id}
              rootSpendingAuthorityType={purchase.root_spending_authority.type}
              // maxWidth={"27ch"}
              outsideOfAttributesTable
            />
          </Box>
        )
      );
    } else if (toShowApprovalFlow(spendingAuthorityRequest)) {
      if (spendingAuthorityRequest.spending_authority) {
        spendingAuthorityType = spendingAuthorityRequest.spending_authority.type;
        spendingAuthorityId = spendingAuthorityRequest.spending_authority.id;
        return (
          <Flex direction={"column"} width={"100%"}>
            <HStack marginTop={"4"}>
              <Text color={useColorModeValue("gray.500", "auto")} fontSize={"xs"}>
                From
              </Text>
              {purchase.spending_authority ? (
                <SpendingAuthorityPreview
                  spendingAuthorityType={purchase.spending_authority.type}
                  spendingAuthorityId={purchase.spending_authority.id}
                  maxWidth={"27ch"}
                  outsideOfAttributesTable
                />
              ) : (
                <RecordLink
                  type=""
                  disabled
                  identifier={"No previous spending authority"}></RecordLink>
              )}
            </HStack>
            <HStack marginTop={"4"}>
              <Text color={useColorModeValue("gray.500", "auto")} fontSize={"xs"}>
                To
              </Text>
              <SpendingAuthorityPreview
                spendingAuthorityType={spendingAuthorityType}
                spendingAuthorityId={spendingAuthorityId}
                maxWidth={"27ch"}
                outsideOfAttributesTable
              />
            </HStack>
          </Flex>
        );
      }
    }
  };

  return (
    <>
      <Flex
        flex="1"
        direction="column"
        p={6}
        border={"1px"}
        width={"100%"}
        borderColor="chakra-border-color"
        borderRadius="md">
        <HStack justifyContent={"space-between"}>
          <Flex>
            <Heading size="md" marginRight={spendingAuthorityRequest ? "2" : "0"}>
              Spending Authority
            </Heading>
            {toShowApprovalFlow(spendingAuthorityRequest) && (
              <Tag>
                {" "}
                <InfoOutlineIcon
                  color={useColorModeValue("gray.800", "auto")}
                  marginRight={"1.5"}
                />{" "}
                <Text color={useColorModeValue("gray.800", "auto")}>Changes Requested</Text>
              </Tag>
            )}
          </Flex>
          {purchase.status === "declined" ? (
            <Tooltip label="This purchase has been declined and the spending authority can no longer be changed.">
              <EditIcon cursor={"none"} opacity={0.5} />
            </Tooltip>
          ) : (
            <EditIcon cursor={"pointer"} onClick={onOpen} />
          )}
        </HStack>
        <Stack spacing={4}>
          {renderSpendingAuthorityChanges()}
          <Divider />
          {spendingAuthority?.type === "BudgetItem" ? (
            <BudgetItemBody
              spendingAuthority={spendingAuthority}
              pendingAmount={pendingAmount}
              approvalFlowBox
            />
          ) : rootSpendingAuthority && rootSpendingAuthority.type === "BudgetItem" ? (
            <BudgetItemBody
              spendingAuthority={rootSpendingAuthority}
              pendingAmount={pendingAmount}
              approvalFlowBox
            />
          ) : rootSpendingAuthority && rootSpendingAuthority.type === "RevenueItem" ? (
            <Tag colorScheme="green">Attributed to Revenue Item</Tag>
          ) : (
            <Tag colorScheme="red">Root spending authority not found</Tag>
          )}
        </Stack>
      </Flex>
      <ChangeSpendingAuthorityModal
        isOpen={isOpen}
        onClose={onClose}
        pastEditableState={pastEditableState}
        expenditure_id={purchase.id}
        spending_authority={spendingAuthority || null}
        currentUser={currentUser}
        amount={purchase.amount}
      />
    </>
  );
};
