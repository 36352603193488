import React from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { SpendingCard } from "../Budgets/SpendingCard";
import { UserShowData } from "../../api/user";
import { UserInventory } from "./components/UserInventory";

export const UserBudgetTab = ({
  user,
  isFinanceAdmin,
}: {
  user: UserShowData;
  isFinanceAdmin: boolean;
}) => {
  return (
    <Flex direction="column" gap={4}>
      {user.budget_id && (
        <SpendingCard
          budgetId={user.budget_id}
          editable={isFinanceAdmin}
          userId={user.id}
          location="user"
        />
      )}
      <UserInventory user={user} />
    </Flex>
  );
};
