import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  ButtonGroup,
  useColorModeValue,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { RichTextEditor } from "@sciencecorp/helix-components";
import { TeamShowData } from "../../../../api/team";
import { useUpdateTeam } from "../../../../api/team";

type Props = {
  team: TeamShowData;
  isTeamLead: boolean;
};

export const TeamReadme: React.FC<Props> = ({ team, isTeamLead }) => {
  const [edit, setEdit] = useState(false);
  const [rich, setRich] = useState("");
  const { mutate: updateTeam } = useUpdateTeam(team.id);
  const breakpoint = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" });

  const handleSave = () => {
    setEdit(false);
    updateTeam({ id: team.id, readme: JSON.parse(rich) });
  };
  return (
    <Flex
      gap={8}
      width="100%"
      justifyContent={{ base: "none", md: "space-between" }}
      alignItems={{ base: "flex-end", md: "flex-start" }}
      flexDirection={[
        "column-reverse", // 0-30em
        "column-reverse", // 30em-48em
        "row", // 48em-62em
        "row", // 62em+
      ]}>
      {breakpoint === "base" && isTeamLead && !edit && (
        <Button size="md" alignSelf="flex-end" onClick={() => setEdit(true)}>
          Edit README
        </Button>
      )}

      <Box
        width={[
          "100%", // 0-30em
          "100%", // 30em-48em
          "90%", // 48em-62em
          "75%", // 62em+
        ]}>
        {team.readme || rich || edit ? (
          <>
            <RichTextEditor
              editable={edit && isTeamLead}
              defaultValue={JSON.stringify(team.readme)}
              onChange={(rich) => setRich(rich)}
              placeholder="Tell us about this team!"
              minHeight="100px"
              border={edit}
            />
            {edit && (
              <ButtonGroup justifyContent="flex-end" width="100%" spacing={4}>
                <Button variant="outline" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
                <Button colorScheme="teal" onClick={handleSave}>
                  Save
                </Button>
              </ButtonGroup>
            )}
          </>
        ) : (
          <Text mt={3} fontSize="lg" color={useColorModeValue("gray.500", "gray.400")}>
            This team has not added a README yet.
          </Text>
        )}
      </Box>

      {breakpoint !== "base" && isTeamLead && !edit && (
        <Button size="md" onClick={() => setEdit(true)} isDisabled={team.is_archived}>
          Edit README
        </Button>
      )}
    </Flex>
  );
};
