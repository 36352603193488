import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { api } from ".";
import { zodParse } from "./zodParse";

export const API_KEYS_BASE_URL = "api_keys";

export const apiKeyPartialSchema = z.object({
  id: z.number(),
  client_id: z.string().optional(),
  token: z.string().optional(),
  created_at: z.string().optional(),
});

export const apiKeyIndexSchema = z.array(apiKeyPartialSchema);

export type ApiKeyPartialData = z.infer<typeof apiKeyPartialSchema>;
export type ApiKeyIndexData = z.infer<typeof apiKeyIndexSchema>;

/** queries */
export const getApiKeys = async () => {
  const result = await api.get(API_KEYS_BASE_URL);
  return zodParse(apiKeyIndexSchema, result.data);
};
export const newApiKey = async (clientId: string) => {
  const result = await api.post(API_KEYS_BASE_URL, {
    api_key: {
      client_id: clientId,
    },
  });
  return zodParse(apiKeyPartialSchema, result.data);
};
export const deleteApiKey = async (id: number) => {
  await api.delete(`${API_KEYS_BASE_URL}/${id}`);
};

/** hooks */
export const useApiKeysQuery = () => {
  return useQuery({
    queryKey: [API_KEYS_BASE_URL],
    queryFn: getApiKeys,
  });
};
