import React from "react";
import { CompanySpendSummary } from "./components/CompanySpendChart";
import { Flex } from "@chakra-ui/react";
import { DashboardSummary } from "./components/DashboardSummary";
import { SpendingVelocityChart } from "./components/SpendingVelocityChart";
import { CashBalanceChart } from "../ScheduledPayments/components/CashBalanceChart";

export const Dashboard = () => {
  return (
    <Flex direction="column" gap={4}>
      <CompanySpendSummary />
      <SpendingVelocityChart />
      <DashboardSummary />
      <CashBalanceChart />
    </Flex>
  );
};
