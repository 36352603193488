import React, { useMemo } from "react";
import { Box, Heading, Text, Tag, Center, Spinner } from "@chakra-ui/react";
import { MoneyText } from "../../../MoneyText";
import { DateTime } from "luxon";
import { titleize, humanize } from "inflection";
import { Collection, EmptyState, useCollection } from "@sciencecorp/helix-components";
import { SubscriptionPaymentData, useGetSubscriptionPayments } from "../../../../api/subscription";
import { subscriptionPaymentStatusColor } from "../util";

export const SubscriptionPayments = ({ subscription }) => {
  const { pagination, onPagination, order, onOrder } = useCollection({
    pagination: { per_page: 4 },
  });
  const { data, isLoading, isError } = useGetSubscriptionPayments(subscription.id, {
    params: { pagination, order },
  });

  const columns = useMemo(
    () => [
      {
        label: "Date",
        orderOptions: { orderKey: "created_at" },
        render: (payment: SubscriptionPaymentData) =>
          DateTime.fromISO(payment.created_at).toFormat("LLL dd yyyy"),
      },
      {
        label: "Amount",
        render: (payment: SubscriptionPaymentData) => (
          <MoneyText money={payment.amount} formatOptions={{ compact: "never" }} />
        ),
      },
      {
        label: "Status",
        render: (payment: SubscriptionPaymentData) => (
          <Tag colorScheme={subscriptionPaymentStatusColor(payment.status)}>
            {titleize(humanize(payment.status))}
          </Tag>
        ),
      },
    ],
    [data]
  );

  if (isLoading)
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  if (isError) return <Text>Failed to load payments</Text>;

  return data?.results.length ? (
    <Collection
      items={data?.results || []}
      isLoading={isLoading}
      columns={columns}
      pagination={data?.pagination || pagination}
      onPagination={onPagination}
      order={order}
      onOrder={onOrder}
    />
  ) : (
    <EmptyState title="There are no payments yet" size="xs" />
  );
};
