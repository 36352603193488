import { z } from "zod";
import { api } from ".";
import { useQuery } from "@tanstack/react-query";
export const ROLES_BASE_URL = "roles";

export const rolePartialSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const getAllRoles = async () => {
  const result = await api.get(ROLES_BASE_URL);
  return result.data;
};

export const useGetAllRoles = () => {
  return useQuery({
    queryKey: [ROLES_BASE_URL],
    queryFn: getAllRoles,
  });
};
