import { z } from "zod";

export const omniResponseObjectSchema = z.object({
  id: z.string(),
  model_name: z.string(),
  helix_name: z.string(),
  index: z.string(),
  href: z.string().optional(),
  data: z
    .object({
      id: z.number(),
      name: z.string().optional().nullable(),
    })
    .catchall(z.any()),

  matched_attributes: z.record(z.any()).nullable().optional(),
  highlights: z.record(z.any()).nullable().optional(),
});

export const omniResponseSchema = z.object({
  results: z.array(omniResponseObjectSchema),
});

export const omniSearchParamsSchema = z.object({
  term: z.string(),
  model_type: z.array(z.string()).optional(),
  model_app: z.array(z.string()).optional(),
  order: z.record(z.string()).optional(),
});

export type OmniResponseObject = z.infer<typeof omniResponseObjectSchema>;
export type OmniResponse = z.infer<typeof omniResponseSchema>;
export type OmniSearchParams = z.infer<typeof omniSearchParamsSchema>;
