import { HStack, Link, Text } from "@chakra-ui/react";
import React from "react";
import { BsGithub, BsSlack } from "react-icons/bs";
import { SiAsana } from "react-icons/si";

export const AsanaLink = (props: { asanaLink: string | null | undefined }) => {
  return props.asanaLink ? (
    <Link isExternal href={props.asanaLink}>
      <HStack>
        <SiAsana />
        <Text>Asana Team</Text>
      </HStack>
    </Link>
  ) : null;
};

export const SlackLink = (props: { slackChannel: string | null | undefined }) => {
  return props.slackChannel ? (
    <Link
      isExternal
      href={`https://science-sig6580.slack.com/app_redirect?channel=${props.slackChannel}`}>
      <HStack>
        <BsSlack />
        <Text>#{props.slackChannel}</Text>
      </HStack>
    </Link>
  ) : null;
};

export const GithubLink = (props: { githubTeam: string | null | undefined }) => {
  return props.githubTeam ? (
    <Link isExternal href={`https://github.com/orgs/sciencecorp/teams/${props.githubTeam}`}>
      <HStack>
        <BsGithub />
        <Text>GitHub Team</Text>
      </HStack>
    </Link>
  ) : null;
};
