import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { ChatThreadIndexData } from "../../api/chats";

export const ChatThreadSelector = (props: {
  chatThreads?: ChatThreadIndexData;
  selectedThreadId?: number;
  setThreadId: (threadId?: number) => void;
  onNewThread: () => void;
}) => {
  const { chatThreads, selectedThreadId, setThreadId, onNewThread } = props;

  const currentThread = chatThreads?.find((chatThread) => chatThread.id === selectedThreadId);
  const threadButtonLabel = currentThread
    ? currentThread?.summary
      ? currentThread.summary
      : `Thread #${currentThread?.id}`
    : "Threads";

  return chatThreads ? (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        {threadButtonLabel}
      </MenuButton>
      <MenuList>
        <MenuItem fontSize="md" onClick={() => onNewThread()}>
          Start new thread
        </MenuItem>
        {chatThreads.length > 0 && <MenuDivider />}
        {chatThreads.map((chatThread) => (
          <MenuItem key={chatThread.id} fontSize="md" onClick={() => setThreadId(chatThread.id)}>
            Thread #{chatThread.id}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : (
    <Spinner size="sm" />
  );
};
