import { useEffect } from "react";

export const useKeyPress = (callback, keyCodes) => {
  const handler = (e) => {
    const { code, ctrlKey, metaKey } = e;
    if ((metaKey || ctrlKey) && keyCodes?.includes(code)) {
      e.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, []);
};
