import {
  Button,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { generateUserKeyPair, updateUser, UserLoggedInData } from "../../api/user";

const PinModal = (props: { user: UserLoggedInData; onUpdate: () => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pin, setPin] = useState("");

  const handleSubmit = async () => {
    const { publicKey, encryptedPrivateKey } = generateUserKeyPair(pin);
    await updateUser(props.user, {
      create_key_pair: {
        public_key: publicKey,
        encrypted_private_key: encryptedPrivateKey,
      },
    });
    props.onUpdate();
    onClose();
  };

  return (
    <>
      <Link onClick={onOpen}>{props.user.public_key ? "Change PIN" : "Create PIN"}</Link>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set PIN Code</ModalHeader>
          <ModalBody>
            <Text fontWeight="bold" mb={2}>
              Warning: This will re-generate your encryption keys. You will lose access to sensitive
              data such as past performance reviews until HR re-encrypts your data to your new key.
            </Text>
            <form onSubmit={handleSubmit}>
              <Input
                type="password"
                placeholder="Enter a PIN code"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                isRequired
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" ml={3} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PinModal;
