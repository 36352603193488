import { Divider, Flex, Heading, Stack, Tag } from "@chakra-ui/react";
import React from "react";
import { MinimalSpendingAuthority, SpendingAuthorityShowData } from "../../api/spending_authority";
import { BudgetItemBody } from "./BudgetItemBody";
import { SpendingAuthorityPreview } from "../Purchasing/SpendingAuthoritySelectTree";
import { Money } from "../../helpers/Money";

interface SpendingAuthorityInfoProps {
  spendingAuthority: MinimalSpendingAuthority | null;
  pendingAmount: Money;
  session?: number;
  rootSpendingAuthority: SpendingAuthorityShowData | null;
}

export const SpendingAuthorityInfo: React.FC<SpendingAuthorityInfoProps> = ({
  spendingAuthority,
  pendingAmount,
  session,
  rootSpendingAuthority,
}) => {
  return (
    <Flex
      flex="1"
      direction="column"
      p={6}
      gap={4}
      border={"1px"}
      borderColor="chakra-border-color"
      borderRadius="md">
      <Heading size="md">Spending Authority</Heading>
      {spendingAuthority && rootSpendingAuthority ? (
        <Stack spacing={4}>
          <SpendingAuthorityPreview
            spendingAuthorityType={spendingAuthority.type}
            spendingAuthorityId={spendingAuthority.id}
            rootSpendingAuthorityId={rootSpendingAuthority.id}
            rootSpendingAuthorityType={rootSpendingAuthority.type}
            maxWidth={"27ch"}
            outsideOfAttributesTable
          />
          <Divider />
          {spendingAuthority?.type === "BudgetItem" ? (
            <BudgetItemBody
              session={session}
              spendingAuthority={spendingAuthority}
              pendingAmount={pendingAmount || 0}
            />
          ) : rootSpendingAuthority && rootSpendingAuthority.type === "BudgetItem" ? (
            <BudgetItemBody
              session={session}
              spendingAuthority={rootSpendingAuthority}
              pendingAmount={pendingAmount || 0}
            />
          ) : rootSpendingAuthority && rootSpendingAuthority.type === "RevenueItem" ? (
            <Tag colorScheme="green">Attributed to Revenue Item</Tag>
          ) : (
            <Tag colorScheme="red">Root spending authority not found</Tag>
          )}
        </Stack>
      ) : (
        <Tag colorScheme="red">No spending authority</Tag>
      )}
    </Flex>
  );
};
