import React from "react";
import _ from "lodash";
import { PayrollRow, payrollMessageContentSchema, payrollRowSchema } from "./types";
import { Box, Button, Flex, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react";
import { parse as csvParse } from "csv-parse/browser/esm";
import {
  useCurrentUserQuery,
  userHasRole,
  useActiveUsersWithRolesQuery,
  UserPartialData,
  UserLoggedInData,
} from "../../../api/user";
import { KeyringMessageData, useUpsertKeyringMessage } from "../../../api/keyring_message";
import { KeyringZodHelper } from "../../../helpers/KeyringZodHelper";
import { useSearchKeyringMessagesQuery } from "../../../api/keyring_message";
import { zodParse } from "../../../api/zodParse";

const csvHeaders = {
  Employee: "employee",
  "Work email": "workEmail",
  "Pay run name": "payRunName",
  "Pay run check date": "payRunCheckDate",
  "Pay run status": "payRunStatus",
  "End date": "endDate",
  "Start date": "startDate",
  "Pay period": "payPeriod",
  "Total employer costs - Currency": "totalEmployerCostsCurrency",
  "Total employer costs": "totalEmployerCosts",
  "Employee gross pay - Currency": "employeeGrossPayCurrency",
  "Employee gross pay": "employeeGrossPay",
  "Employee net pay - Currency": "employeeNetPayCurrency",
  "Employee net pay": "employeeNetPay",
};

function readFile(file: File): Promise<string> {
  const reader = new FileReader();
  const contentPromise: Promise<string> = new Promise((resolve, reject) => {
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject("No file content");
      }
    };
    reader.onerror = () => {
      reject(reader.error);
    };
  });
  reader.readAsText(file);
  return contentPromise;
}

function parseCsvFile(fileContent: string): Promise<PayrollRow[]> {
  return new Promise((resolve, reject) => {
    csvParse(fileContent, (err, output) => {
      if (err) {
        reject(err);
      } else {
        const headers = output[0];
        output = output.slice(1);
        resolve(
          output.map((row) => {
            const obj = {};
            headers.forEach((header, i) => {
              obj[csvHeaders[header]] = row[i];
            });
            const result = zodParse(payrollRowSchema, obj);
            result.employeeGrossPay = (result.employeeGrossPay || 0) * 100;
            result.employeeNetPay = (result.employeeNetPay || 0) * 100;
            result.totalEmployerCosts = (result.totalEmployerCosts || 0) * 100;
            return result;
          })
        );
      }
    });
  });
}

function MessageBox(props: { message: MessageState }) {
  if (!props.message.message) return null;
  const backgroundColor = props.message.error ? "red.400" : "blue.400";
  return (
    <Flex backgroundColor={backgroundColor} padding={3} mb={5}>
      <Box textAlign={"center"} width={"100%"}>
        <Text fontWeight={700} fontSize="sm" color={"white"}>
          {props.message.message}
        </Text>
      </Box>
    </Flex>
  );
}

type MessageState = {
  error: boolean;
  message?: string;
};

function publicKeysFromUsers(
  users?: UserPartialData[] | null,
  currentUser?: UserLoggedInData | null
): string[] {
  if (!currentUser || !currentUser?.public_key || !users || users.length == 0) return [];
  return users
    .filter((user) => !!user.public_key)
    .map((user) => user.public_key!)
    .concat([currentUser.public_key]);
}

function excludeAllRow(rows: PayrollRow[]): PayrollRow[] {
  return rows.filter((row) => row.employee?.toLowerCase() !== "all");
}

export function UploadPayrollPage(props: {}): JSX.Element {
  const keyringZodHelper = new KeyringZodHelper(payrollMessageContentSchema);
  const currentUserQuery = useCurrentUserQuery();
  const usersQuery = useActiveUsersWithRolesQuery("ceo", "hr_admin");
  const { mutate: upsertKeyringMessage } = useUpsertKeyringMessage();
  const [message, setMessage] = React.useState<MessageState>({ error: false });
  const [salaryFileContent, setSalaryFileContent] = React.useState<PayrollRow[]>([]);
  const [hourlyFileContent, setHourlyFileContent] = React.useState<PayrollRow[]>([]);
  const publicKeys: string[] = publicKeysFromUsers(usersQuery.data, currentUserQuery.data);

  const userCanViewPage =
    userHasRole(currentUserQuery, "ceo") || userHasRole(currentUserQuery, "hr_admin");

  const { data: kmPayrolls, isLoading: kmPayrollIsLoading } = useSearchKeyringMessagesQuery({
    term: "*",
    filters: { message_type: ["payroll"] },
    order: { created_at: "desc" },
  });

  const handleFileChange = (setFn: React.Dispatch<React.SetStateAction<PayrollRow[]>>) => {
    return async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (!file) return;
      const fileContent = await readFile(file);
      const parsedContent = await parseCsvFile(fileContent);
      setFn(excludeAllRow(parsedContent));
      return null;
    };
  };

  const onSubmitHandler = async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (salaryFileContent && hourlyFileContent) {
      let messageContent = { hourly: hourlyFileContent, salary: salaryFileContent };
      let existingId: number | undefined = kmPayrolls?.results?.[0]?.id;

      const message = {
        id: existingId,
        content: keyringZodHelper.encrypt(messageContent, publicKeys),
        message_type: "payroll",
        unlock_users: [],
      };
      const resultPromise = new Promise<Partial<KeyringMessageData>>((resolve, reject) => {
        upsertKeyringMessage(message, {
          onSuccess: (response, _variables, _context) => {
            resolve(response.data);
          },
          onError: (error, _variables, _context) => {
            reject(error);
          },
        });
      });
      try {
        const result = await resultPromise;
        setMessage({
          error: false,
          message: `Successfully uploaded encrypted payroll (Keyring Message: #${
            existingId ?? result.id
          })`,
        });
        return result;
      } catch (e) {
        console.error(e);
        setMessage({
          error: true,
          message: "An error occurred while up loading the encrypted payroll files",
        });
      }
    }
  };

  if (!userCanViewPage) {
    return <Text>You must be a CEO or HR Admin to view this page</Text>;
  }
  if (kmPayrollIsLoading || usersQuery.isLoading || currentUserQuery.isLoading) {
    return <Text>Loading...</Text>;
  }
  return (
    <VStack>
      <MessageBox message={message} />
      <FormControl>
        <Flex>
          <FormLabel>Salary</FormLabel>
          <Input
            data-id="salary-file-input"
            height="auto"
            padding="1"
            type="file"
            accept=".csv"
            onChange={handleFileChange(setSalaryFileContent)}
          />
        </Flex>
      </FormControl>
      <FormControl>
        <Flex>
          <FormLabel>Hourly</FormLabel>
          <Input
            height="auto"
            padding="1"
            type="file"
            accept=".csv"
            data-id="hourly-file-input"
            onChange={handleFileChange(setHourlyFileContent)}
          />
        </Flex>
      </FormControl>
      <Button
        type="submit"
        colorScheme="blue"
        data-id="submit-button"
        isDisabled={salaryFileContent.length === 0 && hourlyFileContent.length === 0}
        onClick={onSubmitHandler}>
        Submit
      </Button>
    </VStack>
  );
}
