import React, { useState, useEffect } from "react";
import { CheckIcon, SmallCloseIcon, EditIcon } from "@chakra-ui/icons";
import { Stack, HStack, IconButton, Text, Avatar, Tooltip } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Option } from "@sciencecorp/helix-components";

import { useCreateInventoryUser, useDeleteInventoryUser } from "../../../../api/inventory";

type EditableAssociationsProps = {
  associationOptions: Option[];
  inventoryId: number;
  currentAssociations: { user: { id: number; name: string; picture_uri: string }; id: number }[];
};

export const EditableInventoryAssociation = ({
  associationOptions,
  inventoryId,
  currentAssociations,
}: EditableAssociationsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAssociations, setSelectedAssociations] = useState<Option[]>([]);
  const { mutate: createUser } = useCreateInventoryUser(Number(inventoryId));
  const { mutate: deleteInventoryUser } = useDeleteInventoryUser(Number(inventoryId));
  const [availableOptions, setAvailableOptions] = useState<Option[]>([]);

  useEffect(() => {
    const currentIds = new Set(currentAssociations.map((assoc) => assoc.user.id.toString()));
    const filteredOptions = associationOptions.filter(
      (option) => !currentIds.has(option.value.toString())
    );
    setSelectedAssociations(
      currentAssociations.map((assoc) => ({
        label: assoc.user.name,
        value: assoc.user.id.toString(),
      }))
    );
    setAvailableOptions(filteredOptions);
  }, [currentAssociations, associationOptions]);

  const handleCancelEdit = () => {
    setSelectedAssociations(
      currentAssociations.map((assoc) => ({ label: assoc.user.name, value: assoc.id.toString() }))
    );

    setIsEditing(false);
  };

  const handleSaveChanges = () => {
    const currentSelectedUserIds = selectedAssociations.map((option: Option) =>
      Number(option.value)
    );

    const toAdd = currentSelectedUserIds.filter(
      (id: number) => !currentAssociations.some((assoc) => assoc.user.id === id)
    );

    const toRemove = currentAssociations
      .filter(
        (assoc) =>
          !selectedAssociations.map((selected) => selected.value).includes(assoc.user.id.toString())
      )
      .map((assoc) => assoc.id);

    if (toAdd.length > 0) {
      createUser({
        inventory_id: inventoryId,
        user_ids: [...toAdd],
      });
    }

    if (toRemove.length > 0) {
      deleteInventoryUser({ ids: toRemove });
    }

    setIsEditing(false);
  };

  return (
    <HStack minH={30} w="100%">
      {isEditing ? (
        <Select
          useBasicStyles
          isMulti
          options={availableOptions}
          value={selectedAssociations}
          onChange={(selected) => setSelectedAssociations([...selected])}
          placeholder="Select users"
          isDisabled={!isEditing}
          size="sm"
        />
      ) : (
        <Stack direction="row" wrap="wrap">
          {currentAssociations.length > 0 ? (
            currentAssociations.map((assoc) => (
              <Tooltip key={assoc.user.name} label={assoc.user.name} closeDelay={500}>
                <Avatar
                  key={assoc.user.name}
                  size="sm"
                  name={assoc.user.name}
                  src={assoc.user.picture_uri}
                />
              </Tooltip>
            ))
          ) : (
            <Text as="i" opacity="0.7">
              No users selected
            </Text>
          )}
        </Stack>
      )}

      {isEditing ? (
        <HStack>
          <IconButton
            size="xs"
            icon={<CheckIcon />}
            onClick={() => {
              handleSaveChanges();
            }}
            aria-label="Save Changes"
          />
          <IconButton
            size="xs"
            icon={<SmallCloseIcon />}
            onClick={handleCancelEdit}
            aria-label="Cancel Edit"
          />
        </HStack>
      ) : (
        <IconButton
          size="xs"
          icon={<EditIcon />}
          onClick={() => setIsEditing(true)}
          aria-label="Start Edit"
        />
      )}
    </HStack>
  );
};
