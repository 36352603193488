import React, { useMemo, useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { AttributesTable, EditableText, SplitPage } from "@sciencecorp/helix-components";
import {
  Avatar,
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Button,
  Link,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import { DateTime } from "luxon";
import { OrcidIcon } from "./OrcidIcon";
import { useUserQuery, useCurrentUserQuery, userHasRole, useUpdateUser } from "../../api/user";
import { LuUsers } from "react-icons/lu";
import { TbPigMoney } from "react-icons/tb";
import { IoCodeSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ProfileTab } from "./ProfileTab";
import { Settings } from "./settings";
import { SpendingCard } from "../Budgets/SpendingCard";
import { UserBudgetTab } from "./UserBudgetTab";

export const UserProfile = () => {
  const { id, tab } = useParams();
  const { data: user, isLoading, isError } = useUserQuery(Number(id));
  const currentUserQuery = useCurrentUserQuery();
  const { mutate: updateUser } = useUpdateUser(Number(id));
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tab || "profile");

  const currentUser = currentUserQuery?.data;
  const isFinanceAdmin = userHasRole(currentUserQuery, "finance_admin");

  const tabConfig = useMemo(
    () =>
      [
        { label: "Profile", value: "profile", icon: <LuUsers />, disabled: false },
        {
          label: "Settings",
          value: "settings",
          icon: <IoCodeSharp />,
          disabled: !(currentUser?.id === user?.id),
        },
        {
          label: "Budget",
          value: "budget",
          icon: <TbPigMoney />,
          disabled: !isFinanceAdmin && !(currentUser?.id === user?.id),
        },
      ].filter((tab) => !tab.disabled),
    [currentUser, user]
  );

  useEffect(() => {
    if (!tab) setActiveTab("profile");

    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <Text>Error loading user</Text>;
  }

  return (
    <Box overflow="scroll">
      <SplitPage
        sidebarWidth="350px"
        sidebarWidthXL="400px"
        breakpoint="lg"
        sidebar={
          <VStack height="100%" spacing={3}>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              px={6}
              py={5}
              borderRadius="md"
              width="100%">
              <Avatar size="xl" src={user?.picture_uri} name={user?.name} />
              <Heading size="lg" w="max-content">
                {user?.name}
              </Heading>
              <Text
                fontWeight="medium"
                fontSize="sm"
                color={useColorModeValue("gray.500", "gray.400")}>
                {user?.employee_title}
              </Text>
            </VStack>
            <VStack spacing={2} width="100%">
              {tabConfig.map(({ label, value, icon }) => (
                <Button
                  size={"md"}
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === value ? "teal" : "gray"}
                  bg={activeTab === value ? "teal.500" : "transparent"}
                  leftIcon={icon}
                  onClick={() => {
                    navigate(`/users/${user.id}/${value}`);
                    setActiveTab(value);
                  }}>
                  {label}
                </Button>
              ))}
            </VStack>
            <Box
              width="100%"
              bg={useColorModeValue("gray.50", "gray.700")}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              p={4}
              borderRadius="md">
              <AttributesTable
                title="Basic Information"
                attributes={[
                  { label: "Email", value: user?.email },
                  {
                    label: "Joined",
                    value:
                      user &&
                      user.joined_at &&
                      DateTime.fromISO(user?.joined_at).toFormat("LLLL dd yyyy"),
                  },
                  {
                    label: "Birthday",
                    value:
                      user &&
                      user.birth_date &&
                      DateTime.fromISO(user?.birth_date).toFormat("LLLL d"),
                  },
                  {
                    label: "Status",
                    value: user && user.employee_status && humanize(user.employee_status),
                  },
                  {
                    label: "Leads",
                    value:
                      user?.leads && user?.leads.length > 0 ? (
                        <Flex direction="column" gap={2}>
                          {user?.leads.map((lead) => (
                            <Link as={RouterLink} to={`/users/${lead.id}`}>
                              <Flex align="center" gap={2} fontWeight="semibold">
                                <Avatar size="xs" src={lead.picture_uri} name={lead.name} />
                                <Text>{lead.name}</Text>
                              </Flex>
                            </Link>
                          ))}
                        </Flex>
                      ) : (
                        "Policy source"
                      ),
                  },
                  {
                    label: "ORCID",
                    value:
                      currentUser?.id === user.id ? (
                        <EditableText
                          defaultValue={user?.orcid || undefined}
                          preview={
                            user?.orcid && (
                              <Link href={`https://orcid.org/${user?.orcid}`} isExternal>
                                <Flex alignItems="center" gap="1">
                                  <OrcidIcon />
                                  <Box as="span">{user.orcid}</Box>
                                </Flex>
                              </Link>
                            )
                          }
                          onSubmit={(newOrcid) => {
                            updateUser({ id: user.id, orcid: newOrcid });
                          }}
                          placeholder="Enter ORCID"
                        />
                      ) : user?.orcid ? (
                        <Link href={`https://orcid.org/${user.orcid}`} isExternal>
                          <Flex alignItems="center" gap="1">
                            <OrcidIcon />
                            <Box as="span">{user.orcid}</Box>{" "}
                          </Flex>
                        </Link>
                      ) : (
                        "No ORCID provided"
                      ),
                  },
                  {
                    label: "Direct Reports",
                    labelVerticalAlign: "top",
                    value: (
                      <VStack align="start">
                        {user.direct_reports.length ? (
                          user.direct_reports.map((user) => (
                            <Link as={RouterLink} to={`/users/${user.id}`} key={user.id}>
                              <Flex gap={2} align="center" fontWeight="semibold">
                                <Avatar size="xs" src={user.picture_uri} name={user.name} />
                                <Text>{user.name}</Text>
                              </Flex>
                            </Link>
                          ))
                        ) : (
                          <Text>No direct reports</Text>
                        )}
                      </VStack>
                    ),
                  },
                ]}
              />
            </Box>
          </VStack>
        }
        main={
          <Box>
            {activeTab === "profile" && <ProfileTab user={user} currentUser={currentUser} />}
            {activeTab === "settings" && <Settings userQuery={currentUserQuery} />}
            {activeTab === "budget" && (
              <UserBudgetTab user={user} isFinanceAdmin={isFinanceAdmin} />
            )}
          </Box>
        }
      />
    </Box>
  );
};
