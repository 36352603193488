import { Box, Heading, Link, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

import { useGetAuthUrl, useUpdateOAuthToken } from "../../../api/finance";

interface SetTokenProps {
  authCode: string;
  realmId: string;
}
const SetToken: React.FC<SetTokenProps> = ({ authCode, realmId }: SetTokenProps) => {
  const { mutate: updateToken, isLoading, isSuccess, isError, error } = useUpdateOAuthToken();
  useEffect(() => {
    updateToken({ authCode, realmId });
  }, []);
  if (isLoading) {
    return <Text>Loading...</Text>;
  } else if (isError) {
    console.error(error);
    return (
      <Box>
        <Text>Error...</Text>
        <Link href={"/qb_auth"}>Click here to try again</Link>
      </Box>
    );
  } else {
    return (
      <Box>
        <Text>Token updated successfully!</Text>
      </Box>
    );
  }
};

export const QBAuth: React.FC = () => {
  const location = useLocation();
  const authUrlQuery = useGetAuthUrl();
  const searchParams = new URLSearchParams(location.search);
  const authCode = searchParams.get("code");
  const realmId = searchParams.get("realmId");
  if (authUrlQuery.isLoading) {
    return <Text>Loading...</Text>;
  } else if (!authUrlQuery.isSuccess) {
    console.error(authUrlQuery.error);
    return <Text>Error...</Text>;
  } else if (!authCode || !realmId) {
    return (
      <Box>
        <Heading size="xl" paddingBottom={10}>
          Quick Books Auth Config
        </Heading>
        <Link href={authUrlQuery.data.url}>Click here to configure QB OAuth</Link>
      </Box>
    );
  } else {
    return (
      <Box>
        <Heading size="xl" paddingBottom={10}>
          Quick Books Auth Config
        </Heading>
        <SetToken authCode={authCode} realmId={realmId} />
      </Box>
    );
  }
};
