import React from "react";
import { UserLoggedInData } from "../../api/user";
import {
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  IconButton,
  Button,
  Box,
} from "@chakra-ui/react";
import { deleteApiKey, newApiKey, useApiKeysQuery } from "../../api/api_keys";
import { DateTime } from "luxon";
import { RiFileCopyLine, RiDeleteBin4Line } from "react-icons/ri";

const ApiKeys = (props: { user: UserLoggedInData }) => {
  const apiKeysQuery = useApiKeysQuery();

  const handleCreateApiKey = async () => {
    await newApiKey(`Token ${apiKeysQuery?.data?.length}`);
    apiKeysQuery.refetch();
  };

  const handleDeleteApiKey = async (id: number) => {
    await deleteApiKey(id);
    apiKeysQuery.refetch();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box>
      <Table size="sm">
        {apiKeysQuery.data && apiKeysQuery.data.length > 0 && (
          <Thead>
            <Tr>
              <Th>Client ID</Th>
              <Th>Token</Th>
              <Th>Created</Th>
              <Th></Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {apiKeysQuery.isLoading ? (
            <Tr>
              <Td colSpan={4}>
                <Spinner />
              </Td>
            </Tr>
          ) : apiKeysQuery.isError ? (
            <Tr>
              <Td colSpan={4}>Error loading API keys</Td>
            </Tr>
          ) : apiKeysQuery.data && apiKeysQuery.data.length > 0 ? (
            apiKeysQuery.data.map((apiKey) => (
              <Tr key={apiKey.id}>
                <Td>{apiKey.client_id}</Td>
                <Td>
                  <HStack>
                    <Text>{apiKey.token?.slice(0, 8)}...</Text>
                    <IconButton
                      size={"sm"}
                      aria-label="Copy token"
                      icon={<RiFileCopyLine />}
                      onClick={() => copyToClipboard(apiKey.token!)}
                    />
                  </HStack>
                </Td>
                <Td>{DateTime.fromISO(apiKey.created_at!).toRelative()}</Td>
                <Td>
                  <IconButton
                    size={"sm"}
                    aria-label="Delete token"
                    icon={<RiDeleteBin4Line />}
                    onClick={() => handleDeleteApiKey(apiKey.id)}
                  />
                </Td>
              </Tr>
            ))
          ) : null}
        </Tbody>
      </Table>
      <Button
        size="sm"
        onClick={handleCreateApiKey}
        mt={apiKeysQuery?.data?.length ?? 0 > 0 ? 4 : 0}>
        Generate new API key
      </Button>
    </Box>
  );
};

export default ApiKeys;
