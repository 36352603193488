import {
  Accordion,
  Center,
  Flex,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useSearchPurchases } from "../../../api/purchase";
import { PurchasePaymentScorecards } from "../../../api/purchase_payment";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { MoneyText } from "../../MoneyText";
import { PaymentTableItem } from "./components/PaymentTableItem";
import { UnfulfilledPurchases } from "./components/UnfulfilledPurchases";

export interface ScheduledPaymentsProps {
  purchasePaymentScorecards: PurchasePaymentScorecards;
  isLoadingPurchasePayments: boolean;
  setPurchasingTab: (tab: "paid_at" | "due_date") => void;
  purchasingTab: "paid_at" | "due_date";
}

export const ScheduledPayments: React.FC<ScheduledPaymentsProps> = ({
  purchasePaymentScorecards,
  isLoadingPurchasePayments,
  setPurchasingTab,
  purchasingTab,
}) => {
  const currency = useCurrency();
  const { data: unallocatedPurchases } = useSearchPurchases({
    term: "*",
    pagination: { per_page: -1 },
    filters: {
      status: ["awaiting_delivery", "awaiting_payment"],
      paid: ["false"],
      scheduled_all_payments: ["false"],
    },
    order: { amount_cents: "desc" },
    currency: currency,
  });

  const sumPurchases = useMemo(
    () =>
      Money.sum(
        Money.zero(currency),
        ...(unallocatedPurchases?.results || []).map((purchase) => purchase.amount)
      ) || Money.zero(currency),
    [unallocatedPurchases, currency]
  );
  const totalPaid = useMemo(
    () =>
      Money.sum(
        Money.zero(currency),
        ...(unallocatedPurchases?.results || []).map((purchase) => purchase.purchase_payments_paid)
      ),
    [unallocatedPurchases, currency]
  );

  const sortedPurchasePaymentScorecards = useMemo(() => {
    if (!purchasePaymentScorecards) return null;

    return purchasePaymentScorecards.scorecards
      .map((scorecard) => ({
        ...scorecard,
        results: [...scorecard.results].sort(
          (a, b) => b.amount.cents.toNumber() - a.amount.cents.toNumber()
        ),
      }))
      .sort((a, b) => DateTime.fromISO(a.month).toMillis() - DateTime.fromISO(b.month).toMillis());
  }, [purchasePaymentScorecards]);

  return (
    <Flex direction="column" gap={10}>
      <StatGroup
        w="100%"
        flexWrap="wrap"
        borderRadius="md"
        p={6}
        gap={3}
        justifyContent="space-between"
        flexDirection={{ base: "column", xl: "row" }}
        border="1px"
        bg={useColorModeValue("gray.50", "gray.700")}
        borderColor="chakra-border-color">
        <StatGroup
          flex="1"
          w="100%"
          gap={3}
          justifyContent="space-between"
          flexDirection={{ base: "column", lg: "row" }}>
          <Stat maxW="max-content">
            <StatLabel>Total Committed</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={(
                  purchasePaymentScorecards?.stats.total_committed || Money.zero(currency)
                ).add(sumPurchases.subtract(totalPaid))}
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
          <Stat maxW="max-content">
            <StatLabel>FY2024</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={purchasePaymentScorecards?.stats.fy2024_total || Money.zero(currency)}
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
          <Stat maxW="max-content">
            <StatLabel> Next 12 Months</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={
                  purchasePaymentScorecards?.stats.next_12_months_total || Money.zero(currency)
                }
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
          <Stat maxW="max-content">
            <StatLabel>Next 24 Months</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={
                  purchasePaymentScorecards?.stats.next_24_months_total || Money.zero(currency)
                }
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
          <Stat maxW="max-content">
            <StatLabel>Total Undated</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={purchasePaymentScorecards?.stats.total_undated || Money.zero(currency)}
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
          <Stat maxW="max-content">
            <StatLabel>Unallocated Purchases</StatLabel>
            <StatNumber>
              <MoneyText
                fontSize="2xl"
                fontWeight="semibold"
                money={sumPurchases.subtract(totalPaid)}
                formatOptions={{ compact: "never" }}
              />
            </StatNumber>
          </Stat>
        </StatGroup>
      </StatGroup>

      {isLoadingPurchasePayments ? (
        <Center h="200px">
          <Spinner />
        </Center>
      ) : (
        sortedPurchasePaymentScorecards &&
        unallocatedPurchases && (
          <Tabs
            colorScheme="teal"
            index={purchasingTab === "paid_at" ? 0 : 1}
            onChange={(index) => setPurchasingTab(index === 0 ? "paid_at" : "due_date")}>
            <TabList>
              <Tab onClick={() => setPurchasingTab("paid_at")}>By Paid at</Tab>
              <Tab onClick={() => setPurchasingTab("due_date")}>By Due Date</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Accordion
                  allowMultiple
                  border="1px"
                  borderColor="chakra-border-color"
                  borderRadius="md">
                  {sortedPurchasePaymentScorecards.map((scorecard) => (
                    <PaymentTableItem key={scorecard.month} scorecard={scorecard} />
                  ))}
                </Accordion>
              </TabPanel>
              <TabPanel>
                <Accordion
                  allowMultiple
                  border="1px"
                  borderColor="chakra-border-color"
                  borderRadius="md">
                  {sortedPurchasePaymentScorecards.map((scorecard) => (
                    <PaymentTableItem key={scorecard.month} scorecard={scorecard} />
                  ))}
                  <UnfulfilledPurchases
                    data={unallocatedPurchases}
                    sumPurchases={sumPurchases}
                    totalPaid={totalPaid}
                  />
                </Accordion>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )
      )}
    </Flex>
  );
};
