import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { ChatMessageCard, ErrorMessage, PluginMessage } from "./components";
import { ChatState } from "./state";
import { ChatObject } from "./types";

export const ChatMessages = (props: { chatState: ChatState }) => {
  const { chatState } = props;

  // remove pairs of plugin indicators
  let displayText = chatState.streamingTextContent;
  let displayPlugin = chatState.currentPlugin;

  // if there's still one indicator left, grab everything after it and try to parse out a plugin message
  const splitText = displayText.split("<%*??*%>");
  if (splitText.length > 1) {
    displayText = splitText[0];

    const pluginParts = splitText[1].split(":");
    displayPlugin = {
      sender: "agent",
      type: "plugin",
      plugin: {
        name: pluginParts[0],
        action: pluginParts.length > 1 ? pluginParts[1].trim() : "",
        input: pluginParts.length > 2 ? pluginParts[2].trim() : "",
        icon: "https://placekitten.com/75/75",
      },
    };
  }

  const fullChatObjects = [
    ...chatState.chatObjects,
    displayText ? { sender: "agent", type: "text", content: displayText } : undefined,
    displayPlugin,
  ].filter((x) => x) as ChatObject[];

  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatState.chatObjects, chatState.streamingTextContent, chatState.currentPlugin]);

  return (
    <>
      {fullChatObjects.map((msg, index) => {
        switch (msg.type) {
          case "text":
            return <ChatMessageCard key={index} chatObject={msg} />;
          case "plugin":
            return <PluginMessage key={index} chatObject={msg} />;
          case "error":
            return <ErrorMessage key={index} chatObject={msg} />;
        }
      })}
      <Box ref={endOfMessagesRef} />
    </>
  );
};
