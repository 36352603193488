import { ZodDiscriminatedUnion, ZodEffects, ZodObject, z } from "zod";
import * as _ from "lodash";
import { InferredZodType } from "../api/shared";
import { Keyring } from "../keyring";
import { KeyringMessageData } from "../api/keyring_message";

import forge from "node-forge";
import { SecureModeAction } from "@sciencecorp/helix-components";
import { zodParse } from "../api/zodParse";

export const candidateFeedbackMessageSchema = z.object({
  overall_score: z.number(),
});

export type EncryptableKeyringMessageData = Partial<KeyringMessageData> & {
  content: string;
};

export type KRZod = ZodObject<any> | ZodDiscriminatedUnion<any, any> | ZodEffects<any>;

export class KeyringZodHelper<
  Schema extends ZodObject<any> | ZodDiscriminatedUnion<any, any> | ZodEffects<any>
> {
  private _messageSchema: Schema;

  constructor(messageSchema: Schema) {
    this._messageSchema = messageSchema;
  }

  public decrypt(
    content: string,
    privateKey: forge.pki.rsa.PrivateKey,
    smDispatch: React.Dispatch<SecureModeAction>
  ): InferredZodType<Schema> {
    const keyring = new Keyring([]);

    const success = keyring.decrypt(privateKey, content);
    if (!success) {
      smDispatch({ type: "exit", errorMessage: "Unable decrypt keyring message" });
      throw new Error("Could not decrypt message.");
    }
    try {
      return zodParse(this._messageSchema, JSON.parse(keyring.getContent()));
    } catch (e) {
      if (e instanceof z.ZodError) {
        console.error(
          "Could not parse decrypted json into zod schema!",
          this._messageSchema,
          JSON.parse(keyring.getContent()),
          e
        );
      }
      throw e;
    }
  }

  public encrypt(
    decryptedKeyringMessage: InferredZodType<Schema>,
    publicKeys: string[] = []
  ): string {
    if (publicKeys.length === 0) throw new Error("Cannot encrypt without public keys");
    const keyring = new Keyring(publicKeys);
    keyring.setContent(JSON.stringify(decryptedKeyringMessage));
    return keyring.getSealedMessage();
  }
}
