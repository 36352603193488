import {
  Alert,
  Box,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import { Option, Select } from "@sciencecorp/helix-components";
import * as _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { useCompanyWideSpendingVelocitySummaries } from "../../../../api/spending_velocity";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import Big from "big.js";

type SpendingLinePoint = {
  date: DateTime;
  annualized_spend_cents: number;
};

type WindowLinePoints = {
  window: number;
  points: SpendingLinePoint[];
};

const DateLabel = (props: VictoryLabelProps): JSX.Element => {
  const labelColor = useToken("colors", useColorModeValue("gray.900", "gray.50"));
  return (
    <VictoryLabel
      {...props}
      text={props.datum?.toFormat("MMM")}
      style={{ fill: labelColor, fontFamily: "inherit" }}
    />
  );
};
const MoneyLabel = (props: VictoryLabelProps): JSX.Element => {
  const currency = useCurrency();
  const labelColor = useToken("colors", useColorModeValue("gray.900", "gray.50"));
  return (
    <VictoryLabel
      {...props}
      text={Money.fromMinorUnits(new Big(Number(props.datum) || 0).round(), currency).format()}
      style={{ fill: labelColor, fontFamily: "inherit" }}
    />
  );
};

export type SpendingVelocityChartProps = {};
export const SpendingVelocityChart: React.FC<SpendingVelocityChartProps> = ({}) => {
  const currency = useCurrency();
  const chartRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(100);
  const [width, setWidth] = useState(100);
  const spendingVelocitySummaries = useCompanyWideSpendingVelocitySummaries();

  const [selectedTimeframe, setSelectedTimeframe] = useState(6);

  const labelColor = useToken("colors", useColorModeValue("gray.900", "gray.50"));
  const axisLineColor = useToken("colors", useColorModeValue("gray.900", "gray.50"));
  const gridLineColor = useToken("colors", useColorModeValue("gray.200", "gray.700"));

  const windowToLineColor = {
    1: useToken("colors", useColorModeValue("teal.500", "teal.500")),
    2: useToken("colors", useColorModeValue("blue.500", "blue.500")),
    4: useToken("colors", useColorModeValue("yellow.500", "yellow.500")),
    8: useToken("colors", useColorModeValue("red.500", "red.500")),
  };

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { width, height } = entry.contentRect;
      setHeight(height);
      setWidth(width);
    });
  });

  useEffect(() => {
    if (chartRef.current) {
      const size = chartRef.current.getBoundingClientRect();
      setHeight(size.height);
      setWidth(size.width);
      resizeObserver.observe(chartRef.current);
    }
  }, [chartRef]);

  if (spendingVelocitySummaries.isLoading) {
    return (
      <Box border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
        <Box ref={chartRef} height={["400px", "50vh"]}>
          <Spinner />
        </Box>
      </Box>
    );
  } else if (spendingVelocitySummaries.isSuccess) {
    const timeOptions: Option[] = [
      { label: "All Time", value: -2 },
      { label: "Year to Date", value: -1 },
      { label: "Previous 3 Months", value: 3 },
      { label: "Previous 6 Months", value: 6 },
      { label: "Previous 12 Months", value: 12 },
    ];
    let startDate: DateTime<true | false> = DateTime.now().minus({ months: selectedTimeframe });
    if (selectedTimeframe === -1) {
      startDate = DateTime.now().startOf("year");
    } else if (selectedTimeframe === -2) {
      startDate = DateTime.fromISO("2023-03-01");
    }

    const summariesInDateRange = spendingVelocitySummaries.data.filter((s) => s.date >= startDate);
    const windows = _.uniq(
      spendingVelocitySummaries.data.flatMap((s) => s.spending.map((w) => w.window))
    );
    const linePoints: WindowLinePoints[] = windows.map((window) => {
      return {
        window: window,
        points: summariesInDateRange.map((summary) => ({
          date: summary.date,
          annualized_spend_cents:
            summary.spending.find((s) => s.window === window)?.annualized_spend.cents.toNumber() ??
            0,
        })),
      };
    });

    const numberOfMonthsIncluded = DateTime.now().diff(startDate, "months").months;

    return (
      <Box border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
        <Box ref={chartRef} height={["300px", "40vh"]} pb={8}>
          <HStack justifyContent={"space-between"}>
            <Heading as="h2" size="md" pb={6}>
              Spending Velocity
            </Heading>
            <Select
              size="sm"
              options={timeOptions}
              onChange={(value) => setSelectedTimeframe(Number(value))}
              value={selectedTimeframe}
            />
          </HStack>
          <VictoryChart
            height={height}
            width={width}
            padding={{ top: 10, left: 75, bottom: 80, right: 20 }}
            containerComponent={<VictoryVoronoiContainer />}>
            <VictoryAxis
              key="spending-velocity-date-axis"
              tickValues={_.range(0, numberOfMonthsIncluded + 1).map((i) =>
                startDate.plus({ months: i })
              )}
              tickLabelComponent={<DateLabel />}
              style={{
                axis: { stroke: axisLineColor },
                tickLabels: { fontFamily: "inherit", fill: labelColor },
              }}
            />

            <VictoryAxis
              dependentAxis
              key="spending-velocity-money-axis"
              tickLabelComponent={<MoneyLabel />}
              style={{
                axis: { stroke: axisLineColor },
                tickLabels: { fontFamily: "inherit", fill: labelColor },
                grid: { stroke: gridLineColor },
              }}
            />
            {linePoints.map(({ window, points }) => {
              return (
                <VictoryLine
                  key={`line-${window}`}
                  data={points}
                  x="date"
                  y="annualized_spend_cents"
                  labels={({ datum }) =>
                    `${datum.date.toFormat(
                      "LLL dd"
                    )}\n${window} week annualized\n${Money.fromMinorUnits(
                      datum.annualized_spend_cents,
                      currency
                    ).format()}`
                  }
                  labelComponent={
                    <VictoryTooltip pointerLength={2} style={{ fontFamily: "inherit" }} />
                  }
                  style={{
                    data: { stroke: windowToLineColor[window] },
                    labels: { fontFamily: "inherit", fill: labelColor },
                  }}
                />
              );
            })}
          </VictoryChart>
        </Box>
        <Stack spacing={4} direction="row" justifyContent="center" flexWrap="wrap">
          {[1, 2, 4, 8].map((window) => (
            <HStack key={`legend-${window}`}>
              <Box boxSize={3} bg={windowToLineColor[window]} mr={2} mt={1} />
              <Text fontSize="sm">{window} week annualized</Text>
            </HStack>
          ))}
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
        <Box ref={chartRef} height={["400px", "50vh"]}>
          <Alert status="error">Failed to load data</Alert>
        </Box>
      </Box>
    );
  }
};
