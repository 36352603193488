import { Box, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import { Collection } from "@sciencecorp/helix-components";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import type {
  CandidateFeedbackEmployee,
  CandidateFeedbackIndexItem,
} from "../../../api/candidate_feedback";

export const CandidateFeedbackTable = ({
  data,
  isLoading,
  onPagination,
  pagination,
  onOrder,
  order,
  currentUser,
}) => {
  const [adjustedData, setAdjustedData] = useState<CandidateFeedbackEmployee | null>(null);

  const columns = useMemo(
    () => [
      {
        label: "Candidate",
        render: (candidateFeedback: CandidateFeedbackIndexItem) => (
          <Text>{candidateFeedback.candidate.name}</Text>
        ),
      },
      {
        label: "Team",
        render: (candidateFeedback: CandidateFeedbackIndexItem) =>
          candidateFeedback.candidate.budget_headcount?.budgetable.name ||
          candidateFeedback.candidate.team?.name ||
          "N/A",
      },
      {
        label: "Role",
        render: (candidateFeedback: CandidateFeedbackIndexItem) =>
          candidateFeedback.candidate.budget_headcount?.archetype.name ||
          candidateFeedback.candidate.role ||
          "N/A",
      },
      {
        label: "Actions",
        render: (candidateFeedback: CandidateFeedbackIndexItem) => {
          // debrief has happened, feedback is locked
          if (candidateFeedback.locked) {
            // if candidate has references, show link to hiring manager
            if (
              candidateFeedback.candidate.decision === "references" &&
              candidateFeedback.has_references &&
              currentUser?.data.id === candidateFeedback.candidate.hiring_manager_id
            ) {
              return (
                <Link
                  to={`/hiring/completed_interviews/${candidateFeedback.candidate.id}/references/`}>
                  <Text color={useColorModeValue("teal.600", "teal.300")}>Submit References</Text>
                </Link>
              );
              // if references for candidate have been completed
            } else if (candidateFeedback.keyring_message) {
              return (
                <Tag borderRadius="md" px={2} fontWeight="semibold" colorScheme="green">
                  Complete
                </Tag>
              );
              // if user did not complete candidate feedback, show locked tag
            } else {
              return (
                <Tag borderRadius="md" px={2} fontWeight="semibold">
                  Locked
                </Tag>
              );
            }
          } else
            return (
              <Link to={`/hiring/feedback/${candidateFeedback.id}`}>
                <Text color={useColorModeValue("teal.600", "teal.300")}>
                  {candidateFeedback.keyring_message ? "Edit Feedback" : "Submit Feedback"}
                </Text>
              </Link>
            );
        },
      },
    ],
    [data]
  );

  useEffect(() => {
    if (data) {
      data.results.forEach((ele) => {
        Object.assign(ele, { locked: ele.locked });
      });
      setAdjustedData(data);
    }
  }, [data]);

  return (
    <Box width="100%">
      <Collection
        key={"candidate-feedback"}
        columns={columns}
        isLoading={isLoading}
        items={adjustedData?.results || []}
        pagination={adjustedData?.pagination || pagination}
        order={order}
        onPagination={onPagination}
        onOrder={onOrder}
      />
    </Box>
  );
};
