import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
  Heading,
  HStack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useGetRecentUsersQuery } from "../../../api/user";

const NewHiresComponent = () => {
  const { data: recentUsers, isLoading, isError } = useGetRecentUsersQuery();
  const [page, setPage] = useState(0);

  const backgroundColor = useColorModeValue("gray.50", "gray.700");
  const buttonBackgroundColor = useColorModeValue("gray.200", "gray.600");
  const hoverBackgroundColor = useColorModeValue("gray.100", "gray.600");
  const arrowButtonColor = useColorModeValue("gray.500", "gray.200");
  const pageSize = useBreakpointValue({ base: 1, md: 3 }) || 1;

  const totalNumberOfPages = Math.ceil((recentUsers?.length ?? 0) / pageSize);
  const hiresToShow = recentUsers?.slice(page * pageSize, (page + 1) * pageSize);

  const goToPage = (increment) => {
    setPage((prevPage) => (prevPage + increment + totalNumberOfPages) % totalNumberOfPages);
  };

  if (isLoading) return <Text>Loading...</Text>;
  if (isError) return <Text>Error loading new hires</Text>;
  if (!recentUsers?.length) return null;

  return (
    <Box
      borderRadius="md"
      borderWidth="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p={6}
      width="100%">
      <Flex width="100%" align="center" justify="space-between">
        <Flex justify="space-between" align="baseline" w="100%">
          <Heading size="sm" fontWeight="semibold">
            New Hires
          </Heading>
          {totalNumberOfPages > 1 && (
            <Flex>
              <Button color={arrowButtonColor} onClick={() => goToPage(-1)} variant="ghost">
                <FiChevronLeft />
              </Button>
              <Button color={arrowButtonColor} onClick={() => goToPage(1)} variant="ghost">
                <FiChevronRight />
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex pt={4} width="100%">
        <HStack spacing={8} width="100%">
          {hiresToShow?.map((hire) => (
            <LinkBox
              key={hire.id}
              bg={backgroundColor}
              borderRadius="md"
              width="100%"
              height="100%"
              _hover={{ bg: hoverBackgroundColor }}>
              <LinkOverlay as={RouterLink} to={`/users/${hire.id}`} width="100%">
                <Flex direction="row" align="start" borderRadius="lg" p={5}>
                  <Avatar size="md" src={hire.picture_uri} />
                  <Flex direction="column" ml={4} justify="center" align="start" width="100%">
                    <Flex direction="column">
                      <Text fontWeight="semibold">{hire.name}</Text>
                      <Text color={useColorModeValue("gray.600", "gray.400")} fontSize="xs">
                        {hire.employee_title}
                      </Text>
                    </Flex>
                    <Button
                      size="xs"
                      width="max-content"
                      mt={2}
                      fontWeight="semibold"
                      bg={buttonBackgroundColor}>
                      {hire.team_memberships[0]?.team.name || "No Team Yet"}
                    </Button>
                  </Flex>
                </Flex>
              </LinkOverlay>
            </LinkBox>
          ))}
        </HStack>
      </Flex>
    </Box>
  );
};

export default NewHiresComponent;
