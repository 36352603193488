import { Box, Heading, Stack } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useReviewFeedbacksQuery } from "../../api/review_feedback";
import { useCurrentUserQuery } from "../../api/user";
import { OnboardingTimeline } from "./components/Timeline";
import { Feedback } from "./feedback";
import { YourReviews } from "./reviews";

export const Reviews = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const [paginationPage, setPaginationPage] = useState({
    page: 1,
    per_page: 10,
    n_pages: 1,
    total: 0,
  });

  const reviewFeedbackQuery = useReviewFeedbacksQuery(paginationPage);

  useEffect(() => {
    if (reviewFeedbackQuery.isSuccess) {
      setPaginationPage(reviewFeedbackQuery.data.pagination);
    }
  }, [reviewFeedbackQuery]);

  return (
    <Box>
      <Heading size="lg">Performance Reviews</Heading>
      {currentUser?.joined_at &&
        DateTime.fromISO(currentUser?.joined_at).plus({ month: 3 }) > DateTime.now() && (
          <Box
            borderBottom={"1px solid"}
            borderColor={"chakra-border-color"}
            mx={[0, -4]}
            paddingInline={[0, 14]}
            pt={[2, 4]}
            pb={[2, 12]}>
            <OnboardingTimeline
              joinDate={currentUser?.joined_at}
              reviewFeedbacks={reviewFeedbackQuery.data?.results}
              reviewFeedbackQuery={() => reviewFeedbackQuery.refetch()}
            />
          </Box>
        )}
      <Stack
        width="100%"
        py={4}
        direction={{ base: "column", lg: "row" }}
        spacing={20}
        justify="center">
        <YourReviews reviewFeedbackQuery={reviewFeedbackQuery.refetch} />
        <Feedback
          reviewFeedbacks={reviewFeedbackQuery.data?.results}
          reviewFeedbackQuery={() => reviewFeedbackQuery.refetch()}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          currentUser={currentUser}
        />
      </Stack>
    </Box>
  );
};
