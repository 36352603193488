import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Text,
  Box,
  Heading,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { TbCalendarPlus } from "react-icons/tb";
import {
  useUpdateUserTrainingSession,
  useNewUserTrainingSession,
} from "../../../../api/user_training_sessions";
import { NextSessionData, invalidateTraining } from "../../../../api/trainings";

import { CalendarImage } from "./TrainingImages";
import { DateTime } from "luxon";
import { UserLoggedInData } from "../../../../api/user";

type RSVPModalProps = {
  isOpen: boolean;
  onClose: () => void;
  training: any;
  nextSession: NextSessionData;
  currentUser: UserLoggedInData;
};

export const RSVPModal = ({
  isOpen,
  onClose,
  training,
  nextSession,
  currentUser,
}: RSVPModalProps) => {
  const { mutate: newUserTrainingSession } = useNewUserTrainingSession(invalidateTraining);
  const { mutate: updateUserTrainingSession } = useUpdateUserTrainingSession(invalidateTraining);

  const handleRSVP = () => {
    nextSession.user_training_session
      ? updateUserTrainingSession({
          id: nextSession.user_training_session.id,
          status: "assigned",
        })
      : nextSession.training_session &&
        newUserTrainingSession({
          training_session_id: nextSession.training_session.id,
          user_id: currentUser.id,
        });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Register For Training</ModalHeader>
        <ModalBody p={6}>
          <ModalCloseButton />
          <VStack width="100%">
            <CalendarImage boxSize={40} />
            <VStack width="100%" align="start" spacing={6} pb={4}>
              <Heading size="sm">{training.name}</Heading>
              <Text>
                By proceeding, you will be enrolled in the training session. Please note that
                registration signifies your commitment to attend, as your attendance will contribute
                to the achievement of your{" "}
                <Box as="span" fontWeight="medium">
                  {training.credential_name}
                </Box>{" "}
                credential.
              </Text>
              <HStack width="100%" justify="space-between" spacing={4}>
                <Text
                  flex="1"
                  textAlign="center"
                  fontSize="sm"
                  p={3}
                  border="1px"
                  borderColor="chakra-border-color"
                  bg={useColorModeValue("gray.50", "gray.600")}
                  borderRadius="md">
                  Date:{" "}
                  <Box as="span" fontWeight="medium">
                    {nextSession.training_session &&
                      DateTime.fromISO(nextSession.training_session.scheduled_date).toFormat(
                        "LLL dd yyyy"
                      )}
                  </Box>
                </Text>
                <Text
                  flex="1"
                  textAlign="center"
                  fontSize="sm"
                  p={3}
                  border="1px"
                  borderColor="chakra-border-color"
                  bg={useColorModeValue("gray.50", "gray.600")}
                  borderRadius="md">
                  Time:{" "}
                  <Box as="span" fontWeight="medium">
                    {nextSession.training_session &&
                      DateTime.fromISO(nextSession.training_session.scheduled_date).toFormat(
                        "hh:mm a"
                      )}
                  </Box>
                </Text>
              </HStack>
            </VStack>
            <Button
              leftIcon={<Icon as={TbCalendarPlus} />}
              onClick={handleRSVP}
              width="100%"
              colorScheme="teal">
              Enroll in Training
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
