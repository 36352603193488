import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TbAtom2 } from "react-icons/tb";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

import "katex/dist/katex.min.css";
import { ReadyState } from "react-use-websocket";
import { ChatObject } from "./types";

export const MarkdownComponent = (props: { children: string }) => (
  <ReactMarkdown
    children={props.children}
    remarkPlugins={[remarkMath]}
    rehypePlugins={[rehypeKatex]}
    components={{
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        const language = match ? match[1] : "text";
        return !inline ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, "")}
            style={okaidia}
            customStyle={{ fontSize: "13px", margin: "15px 0" }}
            language={language}
            PreTag="div"
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
      p: (props) => (
        <Text
          style={{
            margin: "1em 0",
            ...props.style,
          }}
          {...props}>
          {props.children}
        </Text>
      ),
      a: (props) => (
        <a
          style={{
            color: "#0070f3",
            textDecoration: "underline",
            ...props.style,
          }}
          target="_blank"
          {...props}>
          {props.children}
        </a>
      ),
      img: (props) => (
        <img
          style={{
            maxWidth: "40%",
            margin: "1em 0",
            ...props.style,
          }}
          {...props}
        />
      ),
      ol: (props) => (
        <ol
          style={{
            margin: "1em 0",
            padding: "0 2em",
            listStyleType: "decimal",
            ...props.style,
          }}>
          {props.children}
        </ol>
      ),
      ul: (props) => (
        <ul
          style={{
            margin: "1em 0",
            padding: "0 2em",
            listStyleType: "disc",
            ...props.style,
          }}>
          {props.children}
        </ul>
      ),
      li: (props) => (
        <li
          style={{
            margin: "0.5em 0",
            ...props.style,
          }}>
          {props.children}
        </li>
      ),
    }}
  />
);

export const ChatMessageCard = (props: { chatObject: ChatObject }) => (
  <Card mb={2}>
    <CardHeader p={3} pb={0} color={"#888"}>
      {props.chatObject.sender == "agent" ? <Icon as={TbAtom2} /> : <p>You</p>}
    </CardHeader>
    <CardBody p={3} pt={0}>
      <Box>
        <MarkdownComponent children={props.chatObject.content ? props.chatObject.content : ""} />
      </Box>
    </CardBody>
  </Card>
);

export const PluginMessage = (props: { chatObject: ChatObject }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <Alert status={props.chatObject.error ? "error" : "info"} mb={2}>
      <AlertDescription width={"100%"}>
        <VStack>
          <Flex flex="1" width={"100%"}>
            <Image
              src={props.chatObject.plugin?.icon}
              alt={props.chatObject.plugin?.name}
              boxSize={"30px"}
              mr={4}
            />
            <Text mt={0.5}>Using {props.chatObject.plugin?.name}</Text>
            <Spacer />
            {!show && <ChevronDownIcon onClick={handleToggle} mt={2} />}
            {show && <ChevronUpIcon onClick={handleToggle} mt={2} />}
          </Flex>
          {show && (
            <Box width={"100%"} pb={0} textAlign={"left"}>
              <span style={{ fontSize: "14px" }}>
                <MarkdownComponent
                  children={`Invoking ${props.chatObject.plugin?.name}: {
                    props.chatObject.plugin?.action
                  } with input "${
                    props.chatObject.plugin?.input ? props.chatObject.plugin?.input : ""
                  }":
                  ${props.chatObject.content ? props.chatObject.content : ""}`}
                />
              </span>
            </Box>
          )}
          {props.chatObject.error && (
            <Box width={"100%"} pb={0} textAlign={"left"}>
              <span style={{ fontSize: "14px" }}>
                <MarkdownComponent
                  children={props.chatObject.error ? props.chatObject.error : ""}
                />
              </span>
            </Box>
          )}
        </VStack>
      </AlertDescription>
    </Alert>
  );
};

export const ErrorMessage = (props: { chatObject: ChatObject }) => (
  <Alert status={"error"} mb={2}>
    <AlertIcon />
    <AlertDescription>{props.chatObject.content}</AlertDescription>
  </Alert>
);

export const ConnectionStatus = (props: { readyState: ReadyState }) => {
  const colorLookup = {
    [ReadyState.CONNECTING]: "yellow.500",
    [ReadyState.OPEN]: "green.500",
    [ReadyState.CLOSING]: "yellow.200",
    [ReadyState.CLOSED]: "red.500",
    [ReadyState.UNINSTANTIATED]: "gray.500",
  };
  const ariaLookup = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  };

  return (
    <Icon
      viewBox="0 0 250 250"
      color={colorLookup[props.readyState]}
      aria-label={ariaLookup[props.readyState]}
      style={{ marginTop: "8px" }}>
      <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
    </Icon>
  );
};
