import React from "react";
import { HStack, IconButton, useColorModeValue } from "@chakra-ui/react";
import { FileDownloadButton } from "@sciencecorp/helix-components";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { BlobFile } from "../../api/blob_files";

type DeleteableFileDownloadProps = {
  file: BlobFile;
  deleteFile: (id: number) => void;
  maxChar?: number;
  isDisabled?: boolean;
};

export const DeleteableFileDownload = ({
  file,
  deleteFile,
  maxChar = 15,
  isDisabled = false,
}: DeleteableFileDownloadProps) => {
  return (
    <HStack
      maxW="100%"
      bg={useColorModeValue("gray.100", "gray.600")}
      borderRadius="md"
      spacing={2}>
      <FileDownloadButton
        colorScheme="gray"
        variant="ghost"
        key={file.id}
        maxW="100%"
        _hover={{ bg: useColorModeValue("gray.200", "gray.500") }}
        file={file.app_href}
        filename={file.filename}
        maxChars={maxChar}
      />
      <IconButton
        variant="ghost"
        size="2xs"
        aria-label="delete-file"
        _hover={{ bg: useColorModeValue("gray.200", "gray.500") }}
        p={1.5}
        icon={<SmallCloseIcon />}
        onClick={() => deleteFile(file.id)}
        isDisabled={isDisabled}
      />
    </HStack>
  );
};
