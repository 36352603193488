import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { blobFileSchema } from "./blob_files";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { expenditureSchema } from "./expenditures";
import { paymentScheduleShowSchema } from "./payment_schedule";
import { createSearchResponseSchema } from "./shared";
import { SpendingAuthorityReference } from "./spending_authority";
import { timelineEventSchema } from "./timeline_events";
import { zodParse } from "./zodParse";

export const CONTRACTS_BASE_URL = "/contracts";

export const contractSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number().nullable(),
  project_id: z.number().nullable(),
  vendor_id: z.number(),
  status: z.string(),
  description: z.string(),
  wafer_services_tool: z.string().nullable(),
  accounting_category: z.string().nullable(),
  created_at: z.string(),
  next_due_date: z.string().nullable(),
  number_paid_installments: z.number(),
  number_total_installments: z.number(),
  total_cost: moneySchema,
  total_paid: moneySchema,
});

export const contractIndexSchema = contractSchema
  .omit({
    team_id: true,
    vendor_id: true,
  })
  .extend({
    team: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable(),
    vendor: z.object({
      id: z.number(),
      name: z.string(),
      status: z.string(),
    }),
  });

export const contractSearchSchema = createSearchResponseSchema(contractIndexSchema);

export const contractShowSchema = contractIndexSchema.extend(expenditureSchema.shape).extend({
  payment_schedule: paymentScheduleShowSchema,
  uploaded_files: z.array(blobFileSchema),
});

export type ContractData = z.infer<typeof contractSchema>;
export type ContractIndexData = z.infer<typeof contractIndexSchema>;
export type ContractShowData = z.infer<typeof contractShowSchema>;
export type ContractCreateParams = Pick<
  ContractData,
  "name" | "vendor_id" | "description" | "status"
> &
  Partial<SpendingAuthorityReference> & {
    files: { name: string; file: string }[];
  };
export type ContractSearchData = z.infer<typeof contractSearchSchema>;

export const generateContractCSV = async (): Promise<any> => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/csv`, {
    responseType: "blob",
  });
  return result.data;
};

/** queries */
export const getContract = async (id: number) => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/${id}`);
  return zodParse(contractShowSchema, result.data);
};

export const getContracts = async () => {
  const result = await api.get(CONTRACTS_BASE_URL);
  return zodParse(contractShowSchema.array(), result.data);
};

export const getContractTimelineEvents = async (id: number) => {
  const result = await api.get(`${CONTRACTS_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const searchContracts = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [CONTRACTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(contractIndexSchema), result.data);
};

export const createContract = async (contract: ContractCreateParams) => {
  const result = await api.post(CONTRACTS_BASE_URL, { contract });
  return result.data;
};

export const updateContract = async (
  contract: Partial<ContractData> & Partial<SpendingAuthorityReference>
) => {
  await api.put(`${CONTRACTS_BASE_URL}/${contract.id}`, { contract });
};

export const deleteContract = async (id: number) => {
  await api.delete(`${CONTRACTS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const invalidateContracts = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CONTRACTS_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetContracts = () => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL],
    queryFn: () => getContracts(),
  });
};

export const useSearchContracts = (params: SearchParams) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, params],
    queryFn: () => searchContracts(params),
  });
};

export const useContractOptions = () => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL],
    queryFn: () => {
      const searchResults = searchContracts({
        aggs: [],
        pagination: { page: 1, per_page: -1 },
        filters: {},
        term: "*",
      });
      return searchResults.then((data) => ({
        ...data,
        options: [
          { label: "No Contract", value: -1 },
          ...data.results.map((c) => ({ label: c.name, value: c.id })),
        ],
      }));
    },
  });
};

export const useGetContract = (id: number) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, id],
    queryFn: () => getContract(id),
  });
};

export const useGetContractTimelineEvents = (id: number) => {
  return useQuery({
    queryKey: [CONTRACTS_BASE_URL, id, "events"],
    queryFn: () => getContractTimelineEvents(id),
  });
};

export const useCreateContract = () => {
  return useMutation({
    mutationFn: createContract,
    onSuccess: invalidateContracts(),
  });
};

export const useUpdateContract = (id?: number) => {
  return useMutation({
    mutationFn: updateContract,
    onSuccess: invalidateContracts(id),
  });
};

export const useDeleteContract = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: deleteContract,
    onSuccess: (id: number) => {
      onSuccess && onSuccess();
      invalidateContracts(id);
    },
  });
};
