import React from "react";
import { VStack, useColorModeValue, Text } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export const EmptyState = ({ title, size = "sm" }) => (
  <VStack
    width="100%"
    minHeight={size}
    justify="center"
    textAlign="center"
    borderRadius="md"
    bg={useColorModeValue("gray.50", "gray.700")}
    opacity="0.7"
    spacing={5}>
    <Search2Icon boxSize={6} />
    <Text fontWeight="semibold" fontSize="sm">
      It’s empty here.
    </Text>
    <Text fontWeight="medium" fontSize="xs" textAlign="center">
      {title}
    </Text>
  </VStack>
);
