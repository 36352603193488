import React from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel } from "victory";

export const SurveyBarChart = ({ data, xAxisLabels, label, colors }) => {
  const labelText = useColorModeValue("#718096", "#CBD5E0");
  return (
    <VictoryChart
      padding={{ bottom: 45, left: 50, right: 50 }}
      domainPadding={{ x: 25 }}
      theme={VictoryTheme.material}
      width={600}
      height={400}>
      <VictoryAxis
        tickFormat={xAxisLabels}
        style={{
          axis: { stroke: "transparent" },
          axisLabel: { padding: 30, fontSize: 12, fill: labelText },
          tickLabels: { fontSize: 9, fill: labelText },
          grid: { stroke: "transparent" },
        }}
        label={label}
      />
      <VictoryAxis
        style={{
          grid: { stroke: "gray", strokeWidth: 1 },
          axis: { stroke: "transparent" },
          axisLabel: { padding: 40, fontSize: 10, fill: labelText },
          tickLabels: { fontSize: 10, fill: labelText },
        }}
        label="No. of Employees"
        dependentAxis
        tickFormat={(x) => x}
      />
      <VictoryBar
        barWidth={100}
        data={data}
        x="x"
        y="y"
        labels={({ datum }) => datum.y}
        labelComponent={<VictoryLabel dy={11} />}
        style={{
          data: {
            // @ts-ignore
            fill: (datum) => colors[datum.index],
          },
          labels: { fill: "white", fontSize: 10 },
        }}
      />
    </VictoryChart>
  );
};
