import React from "react";
import { Heading, Text, VStack, HStack, Divider, LinkOverlay, LinkBox } from "@chakra-ui/react";
import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";

import { Link } from "react-router-dom";
import { useGetCredential } from "../../../api/credentials";

export const UnfulfilledCredentialCard = ({ userCredential }) => {
  const { data: credential } = useGetCredential(userCredential.credential_id);
  return (
    <LinkBox border="1px" borderColor="chakra-border-color" borderRadius="md" p={6}>
      <LinkOverlay as={Link} to={`/credentials/me/${userCredential.id}`}>
        <HStack align="start" spacing={4} width="100%">
          <VStack spacing={3} width="100%" align="start">
            <HStack justify="space-between" width="100%">
              <VStack width="100%" align="start" spacing={1}>
                <Heading size="sm">{credential?.name}</Heading>
                <Text noOfLines={2} fontSize="sm">
                  {credential?.description}
                </Text>
              </VStack>
              <ChevronRightIcon alignSelf="center" />
            </HStack>
            <Divider />
            {userCredential.training_name ? (
              <HStack width="100%">
                <InfoOutlineIcon />
                <Text fontSize="xs">Awarded by {userCredential.training_name}</Text>
              </HStack>
            ) : null}
          </VStack>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
