import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from "..";
import { userMinimalSchema } from "../user";
import { dateTimeSchema } from "../../helpers/dateTime";
import { zodParse } from "../zodParse";

export const PROJECT_EDGE_BASE_URL = `/project_edges`;
export const PROJECT_EDGE_QUERY_KEY = ["project_edges"];
export const PROJECT_QUERY_KEY = ["projects"];

function invalidateCache() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: PROJECT_QUERY_KEY,
    });
    queryClient.invalidateQueries({
      queryKey: PROJECT_EDGE_QUERY_KEY,
    });
  };
}

export const projectEdgeSchema = z.object({
  id: z.number(),
  ancestor_id: z.number(),
  descendant_id: z.number(),
});

export const minimalProjectShowSchema = z.object({
  id: z.number(),
  title: z.string(),
  status: z.string(),
  start_date: dateTimeSchema.nullable(),
  end_date: dateTimeSchema.nullable(),
  users: z.array(z.lazy(() => userMinimalSchema)),
});

export const projectEdgeShowSchema = z.object({
  id: z.number(),
  ancestor: minimalProjectShowSchema,
  descendant: minimalProjectShowSchema,
});

export type ProjectEdge = z.infer<typeof projectEdgeSchema>;
export type ProjectEdgeShowData = z.infer<typeof projectEdgeShowSchema>;
export type MinimalProjectShowData = z.infer<typeof minimalProjectShowSchema>;
type CreateProjectEdge = Omit<ProjectEdge, "id">;

export const getProjectEdgesForProject = async (
  projectId: number
): Promise<ProjectEdgeShowData[]> => {
  const response = await api.get(`${PROJECT_EDGE_BASE_URL}/ancestors/${projectId}`);
  return zodParse(z.array(projectEdgeShowSchema), response.data);
};

export const createProjectEdge = async (edge: CreateProjectEdge): Promise<ProjectEdgeShowData> => {
  const response = await api.post(`${PROJECT_EDGE_BASE_URL}`, edge);
  return zodParse(projectEdgeShowSchema, response.data);
};

export const deleteProjectEdge = async (id: number): Promise<number> => {
  await api.delete(`${PROJECT_EDGE_BASE_URL}/${id}`);
  return id;
};

export const useGetProjectEdgesForProject = (projectId: number | null | undefined) => {
  return useQuery({
    queryKey: ["project_edges", projectId],
    queryFn: () => getProjectEdgesForProject(projectId as number),
    enabled: projectId !== null && projectId !== undefined,
  });
};

export const useCreateProjectEdge = () => {
  return useMutation({
    mutationFn: createProjectEdge,
    onSuccess: invalidateCache(),
  });
};

export const useDeleteProjectEdge = () => {
  return useMutation({
    mutationFn: deleteProjectEdge,
    onSuccess: invalidateCache(),
  });
};
