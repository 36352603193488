import React from "react";
import {
  KeyringMessageData,
  useGetStaleKeyringMessagesQuery,
  useUpdateKeyringMessage,
} from "../../../api/keyring_message";
import { Keyring } from "../../../keyring";

import forge from "node-forge";
import { useCurrentUserQuery, UserLoggedInData } from "../../../api/user";
import { useSecureModeEffect, useSecureModeModalWithUser } from "../../Encryption/hooks";

function refreshStaleKeyrings(
  updateKeyringMessage: (keyringMessage: Partial<KeyringMessageData>) => void,
  { staleKeyringMessages: staleKeyrings }: UseBackgroundTasksData,
  privateKey: forge.pki.rsa.PrivateKey
): boolean {
  const keyring = new Keyring([]);
  for (const message of staleKeyrings) {
    const staleUsers = message.stale_users;
    const success = keyring.decrypt(privateKey, message.content);
    if (!success) {
      return false;
    }
    staleUsers.forEach((user) => {
      keyring.removePublicKey(user.stale_public_key);
      keyring.addPublicKey(user.current_public_key);
    });

    const newMessage = keyring.getSealedMessage();

    updateKeyringMessage({
      id: message.id,
      content: newMessage,
      is_stale: false,
      unlock_users: [],
    });
  }
  return true;
}

export interface UseBackgroundTasksData {
  staleKeyringMessages: KeyringMessageData[];
  currentUser: UserLoggedInData;
}
export function useBackgroundTasksData(): UseBackgroundTasksData | undefined {
  const staleKeyringsQuery = useGetStaleKeyringMessagesQuery();
  const currentUserQuery = useCurrentUserQuery();

  if (currentUserQuery.isSuccess && staleKeyringsQuery.isSuccess) {
    const staleKeyringMessages = staleKeyringsQuery.data;
    if (staleKeyringMessages.length > 0) {
      return {
        staleKeyringMessages,
        currentUser: currentUserQuery.data,
      };
    }
  }
}

interface BackgroundTasksProps {
  backgroundTasksData: UseBackgroundTasksData;
}

function RunBackgroundTasks({ backgroundTasksData }: BackgroundTasksProps): JSX.Element {
  const { mutate: updateKeyringMessage } = useUpdateKeyringMessage();

  useSecureModeModalWithUser("Hey! Some background tasks are pending. Please enter your PIN.");

  useSecureModeEffect((privateKey) => {
    refreshStaleKeyrings(updateKeyringMessage, backgroundTasksData, privateKey);
    return "noRetry";
  }, []);
  return <></>;
}

export const BackgroundTasks: React.FC<BackgroundTasksProps> = ({
  backgroundTasksData,
}: BackgroundTasksProps) => {
  const currentUser = backgroundTasksData.currentUser;

  if (
    currentUser.roles.some((r) => r.name === "hr_admin") &&
    backgroundTasksData.staleKeyringMessages.length > 0
  ) {
    return <RunBackgroundTasks backgroundTasksData={backgroundTasksData} />;
  }
  return <></>;
};
