import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  Stack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import InputFormControl from "../shared/form/InputFormControl";
import { Controller, useForm } from "react-hook-form";
import { required } from "../../helpers/validation";
import SelectFormControl from "../shared/form/SelectFormControl";
import { Header, RichTextEditor } from "@sciencecorp/helix-components";
import {
  NewsPostPartialData,
  NewsPostShowData,
  useCreateNewsPost,
  useUpdateNewsPost,
} from "../../api/news_post";
import { useCurrentUserQuery } from "../../api/user";
import { useNavigate } from "react-router";
import { NetworkError } from "../types";

const CATEGORIES = [
  { label: "News", value: "news" },
  { label: "Meeting Note", value: "meeting_note" },
];

type NewsPostFormTypes = {
  title: string;
  content: string;
  category: string | null;
  raw_text: string;
};

export const NewPost = (props: { newPost?: NewsPostShowData }) => {
  const toast = useToast();
  const userQuery = useCurrentUserQuery();
  const { mutate: createNewsPost, isLoading: isLoadingCreateNewsPosts } = useCreateNewsPost();
  const { mutate: updateNewsPost, isLoading: isLoadingUpdateNewsPosts } = useUpdateNewsPost();
  const defaultValues: NewsPostFormTypes = {
    title: props?.newPost?.title ?? "",
    content: props?.newPost?.content ?? "",
    category: props?.newPost?.category ?? null,
    raw_text: JSON.stringify(props?.newPost?.raw_text) ?? "",
  };

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { dirtyFields, errors },
  } = useForm<NewsPostFormTypes>({ mode: "all", defaultValues });
  const navigate = useNavigate();

  const createNewPostHandler = (defaultValues: NewsPostFormTypes, data) => {
    const { raw_text, ...rest } = data;
    const parsedText = JSON.parse(raw_text);
    if (userQuery.data)
      createNewsPost(
        { raw_text: parsedText, user_id: userQuery.data.id, ...rest },
        {
          onSuccess: () => {
            toast({ title: "News Post created successfully" });
            navigate("/news");
            reset(defaultValues);
          },
          onError: (err: unknown) => {
            toast({
              title:
                (err as NetworkError).response?.data.error.message ?? "Error creating news Post",
              status: "error",
            });
          },
        }
      );
  };

  const updateNewsPostHandler = (defaultValues, data) => {
    const { raw_text, ...rest } = data;
    const parsedText = JSON.parse(raw_text);
    updateNewsPost(
      { raw_text: parsedText, ...rest, id: props?.newPost?.id },
      {
        onSuccess: () => {
          toast({ title: "News Post updated successfully" });
          reset(defaultValues);

          navigate("/news");
        },
        onError: (err: unknown) => {
          toast({
            title: (err as NetworkError).response?.data.error.message ?? "Error creating news Post",
            status: "error",
          });
        },
      }
    );
  };
  const formHandler = () => {
    handleSubmit((data) => {
      if (props?.newPost?.id) {
        updateNewsPostHandler(defaultValues, data);
      } else {
        createNewPostHandler(defaultValues, data);
      }
    })();
  };

  const formValues = watch();

  const allFieldsDirty = () => {
    return formValues.content && formValues.content && formValues.category;
  };
  return (
    <Box>
      <Header
        title={props?.newPost?.id ? "Edit Post" : "New Post"}
        crumbs={[
          { label: "Homepage", url: "/" },
          { label: "News", url: "/news" },
        ]}
        crumbsColor="teal.500"
      />
      <Flex align="center" w="100%" justify={"center"}>
        <Card
          border="1px"
          p={6}
          borderColor={useColorModeValue("gray.200", "gray.600")}
          w="50rem"
          boxShadow="md">
          <Stack direction={["column", "row"]} mb={6}>
            <Controller
              name="title"
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <InputFormControl
                  label="Post Name"
                  placeholder="Type Post Name"
                  error={errors?.title?.message}
                  {...field}
                  value={field.value}
                />
              )}
            />

            <Controller
              name="category"
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <SelectFormControl
                  {...field}
                  label="Category"
                  placeholder="e.g News, Meeting Notes"
                  error={errors?.category?.message}
                  options={CATEGORIES}
                  value={field.value}
                />
              )}
            />
          </Stack>
          <RichTextEditor
            onChange={(rich, raw) => {
              setValue("content", raw ?? "");
              setValue("raw_text", rich);
            }}
            defaultValue={defaultValues.raw_text || undefined}
            minHeight="200px"
          />
          <Flex justifyContent="center" mt="1rem">
            <HStack mt="1.5rem">
              <Button onClick={() => navigate("/news")}>Cancel</Button>
              <Button
                onClick={formHandler}
                colorScheme="teal"
                isDisabled={!allFieldsDirty()}
                isLoading={isLoadingCreateNewsPosts || isLoadingUpdateNewsPosts}>
                {props.newPost ? "Save Changes" : "Publish"}
              </Button>
            </HStack>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};
