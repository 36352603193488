import React, { useState } from "react";
import { Flex, Button, Heading, useColorModeValue, Divider, HStack, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import WeekDaysHeader from "./WeekDaysHeader";
import DaysGrid from "./DaysGrid";
import { useCalendarEventsQuery } from "../../../api/calendar_event";
import { BsDot } from "react-icons/bs";

const Calendar: React.FC<{ onDayClick: (date: DateTime) => void }> = ({ onDayClick }) => {
  const { data: events } = useCalendarEventsQuery();
  const [currentMonth, setCurrentMonth] = useState(DateTime.now());

  const prevMonth = () => setCurrentMonth(currentMonth.minus({ months: 1 }));
  const nextMonth = () => setCurrentMonth(currentMonth.plus({ months: 1 }));

  return (
    <Flex direction="column" mt={4}>
      <Flex justify="space-between" align="baseline" w="100%">
        <Heading
          color={useColorModeValue("gray.600", "gray.200")}
          fontSize="md"
          fontWeight="medium"
          mb={2}>
          {currentMonth.toFormat("MMMM yyyy")}
        </Heading>
        <Flex>
          <Button
            color={useColorModeValue("gray.500", "gray.200")}
            onClick={prevMonth}
            variant="ghost">
            <FiChevronLeft />
          </Button>
          <Button
            color={useColorModeValue("gray.500", "gray.200")}
            onClick={nextMonth}
            variant="ghost">
            <FiChevronRight />
          </Button>
        </Flex>
      </Flex>
      <WeekDaysHeader />
      {events && <DaysGrid currentMonth={currentMonth} events={events} onDayClick={onDayClick} />}
      <Divider color={"gray.200"} mt={4} mb={4} />
      <HStack mt={2}>
        <BsDot color="teal" />
        <Text
          fontSize={"xs"}
          color={useColorModeValue("gray.600", "gray.400")}
          fontWeight={"light"}>
          Scheduled Event
        </Text>
      </HStack>
    </Flex>
  );
};

export default Calendar;
