import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";

export type TextareaFormControlProps = TextareaProps & {
  label?: string;
  error?: string;
  isRequired?: boolean;
};

const TextareaFormControl: React.FC<TextareaFormControlProps> = ({
  label,
  error,
  isRequired,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Textarea {...props} />
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default TextareaFormControl;
