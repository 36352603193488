import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { userMinimalSchema } from "./user";
import { reimbursementLineItemShowSchema } from "./reimbursement_line_items";
import { SpendingAuthorityReference } from "./spending_authority";
import { createSearchResponseSchema } from "./shared";
import { approvableSchema } from "./approvals";
import { zodParse } from "./zodParse";
import { BUDGET_ITEMS_BASE_URL } from "./budget_items";
import { expenditureSchema } from "./expenditures";
import { timelineEventSchema } from "./timeline_events";
import { dateTimeSchema } from "../helpers/dateTime";
import { moneySchema } from "../helpers/Money";

export const REIMBURSEMENT_BASE_URL = "reimbursements";
export const VIRTUAL_CARDS_BASE_URL = "virtual_cards";
export const CARD_INFORMATION_REQUESTS_BASE_URL = "card_information_requests";

/** reimbursement */
const reimbursementSchema = z.object({
  id: z.number(),
  created_at: dateTimeSchema,
  reimbursement_type: z.string(),
  approval_status: z.string(),
  submitted_at: dateTimeSchema.nullable(),
  user_id: z.number(),
  paid: z.boolean(),
  paid_at: dateTimeSchema.nullable(),
});

export const reimbursementShowSchema = reimbursementSchema
  .omit({ user_id: true })
  .extend(expenditureSchema.shape)
  .extend(approvableSchema.shape)
  .extend({
    user: z.lazy(() => userMinimalSchema),
    amount: moneySchema,
    reimbursement_within_budget: z.boolean(),
  });

export const reimbursementIndexItemSchema = reimbursementSchema.omit({ user_id: true }).extend({
  user: z.lazy(() => userMinimalSchema),
  amount: moneySchema,
});

export const reimbursementSearchResponseSchema = createSearchResponseSchema(
  reimbursementIndexItemSchema
);

export type ReimbursementData = z.infer<typeof reimbursementSchema>;
export type ReimbursementShowData = z.infer<typeof reimbursementShowSchema>;
export type ReimbursementIndexItemData = z.infer<typeof reimbursementIndexItemSchema>;
export type ReimbursementSearchResponse = z.infer<typeof reimbursementSearchResponseSchema>;

export type ReimbursementCreateParams = Pick<
  ReimbursementData,
  "reimbursement_type" | "approval_status" | "user_id"
> &
  Partial<SpendingAuthorityReference>;

/** queries */
export const approveReimbursement = async (reimbursementId: number) => {
  const result = await api.post(`${REIMBURSEMENT_BASE_URL}/${reimbursementId}/approve`);
  return zodParse(reimbursementShowSchema, result.data);
};

export const declineReimbursement = async (reimbursementId: number) => {
  const result = await api.post(`${REIMBURSEMENT_BASE_URL}/${reimbursementId}/decline`);
  return zodParse(reimbursementShowSchema, result.data);
};

export const getReimbursement = async (id: number) => {
  const result = await api.get(`${REIMBURSEMENT_BASE_URL}/${id}`);
  return zodParse(reimbursementShowSchema, result.data);
};

export const listReimbursements = async () => {
  const result = await api.get(REIMBURSEMENT_BASE_URL);
  return zodParse(z.array(reimbursementShowSchema), result.data);
};

export const getReimbursementLineItems = async (id: number | null) => {
  const result = await api.get(`${REIMBURSEMENT_BASE_URL}/${id}/line_items`);
  return zodParse(z.array(reimbursementLineItemShowSchema), result.data);
};

const searchReimbursementRequests = async ({
  aggs,
  bodyOptions,
  filters,
  pagination,
  order,
  term,
  currency,
}: SearchParams & { currency?: string }) => {
  const path = [REIMBURSEMENT_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { currency, ...index, ...search });

  return zodParse(createSearchResponseSchema(reimbursementIndexItemSchema), result.data);
};

export const newReimbursement = async (reimbursement: ReimbursementCreateParams) => {
  const result = await api.post(REIMBURSEMENT_BASE_URL, { reimbursement });
  return result.data.id;
};

export const updateReimbursement = async (
  reimbursement: Partial<ReimbursementData> & Partial<SpendingAuthorityReference>
) => {
  const result = await api.put(`${REIMBURSEMENT_BASE_URL}/${reimbursement.id}`, { reimbursement });
  return result.data;
};

export const updatePaidReimbursement = async ({ id, paid }: { id: number; paid: boolean }) => {
  await api.put(`${REIMBURSEMENT_BASE_URL}/${id}/paid`, { paid });
};

export const updateUnPaidReimbursement = async ({ id, paid }: { id: number; paid: boolean }) => {
  await api.put(`${REIMBURSEMENT_BASE_URL}/${id}/unpaid`, { paid });
};

export const deleteReimbursement = async (reimbursementId: number) => {
  await api.delete(`${REIMBURSEMENT_BASE_URL}/${reimbursementId}`);
};

export const getReimbursementTimelineEvents = async (id: number) => {
  const result = await api.get(`${REIMBURSEMENT_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const postComment = async (reimbursementId: number, richText: string, plainText: string) => {
  return await api.post(`${REIMBURSEMENT_BASE_URL}/${reimbursementId}/comments`, {
    rich_text: richText,
    plain_text: plainText,
  });
};

/** hooks */

export const invalidateReimbursement = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [REIMBURSEMENT_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [BUDGET_ITEMS_BASE_URL],
    });
  };
};

export const useGetReimbursement = (id: number) => {
  const result = useQuery({
    queryKey: [REIMBURSEMENT_BASE_URL, id],
    queryFn: () => getReimbursement(id),
  });
  return result;
};

export const useListReimbursements = () => {
  const result = useQuery({
    queryKey: [REIMBURSEMENT_BASE_URL],
    queryFn: () => listReimbursements(),
  });
  return result;
};

export const useGetReimbursementLineItems = (id: number | null) => {
  return useQuery({
    queryKey: [REIMBURSEMENT_BASE_URL, id, "line_items"],
    queryFn: () => getReimbursementLineItems(id),
    enabled: !!id,
  });
};

export const useSearchReimbursements = (params: SearchParams & { currency?: string }) => {
  return useQuery({
    queryKey: [REIMBURSEMENT_BASE_URL, params],
    queryFn: () => searchReimbursementRequests(params),
  });
};

export const useApproveReimbursement = () => {
  return useMutation({
    mutationFn: approveReimbursement,
    onSuccess: invalidateReimbursement(),
  });
};

export const useDeclineReimbursement = () => {
  return useMutation({
    mutationFn: declineReimbursement,
    onSuccess: invalidateReimbursement(),
  });
};

export const usePostComment = (reimbursementId: number) => {
  return useMutation({
    mutationFn: ({ richText, plainText }: { richText: string; plainText: string }) =>
      postComment(reimbursementId, richText, plainText),
    onSuccess: invalidateReimbursement(reimbursementId, "events"),
  });
};

export const useGetReimbursementTimelineEvents = (id: number) => {
  return useQuery({
    queryKey: [REIMBURSEMENT_BASE_URL, id, "events"],
    queryFn: () => getReimbursementTimelineEvents(id),
  });
};

export const useNewReimbursement = () => {
  return useMutation({
    mutationFn: newReimbursement,
    onSuccess: invalidateReimbursement(),
  });
};
export const useUpdateReimbursement = (reimbursementId: number) => {
  return useMutation({
    mutationFn: updateReimbursement,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};
export const useUpdatePaidReimbursement = (reimbursementId: number) => {
  return useMutation({
    mutationFn: updatePaidReimbursement,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};
export const useUpdateUnPaidReimbursement = (reimbursementId: number) => {
  return useMutation({
    mutationFn: updateUnPaidReimbursement,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};
export const useDeleteReimbursement = (reimbursementId: number) => {
  return useMutation({
    mutationFn: deleteReimbursement,
    onSuccess: invalidateReimbursement(reimbursementId),
  });
};
