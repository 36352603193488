import {
  Box,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { AttributesTable, Header, SplitPage } from "@sciencecorp/helix-components";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import { TimelineTable } from "../../shared/TimelineTable";
import { BudgetItemSpendByModelStats } from "./BudgetItemSpendByModelStats";
import {
  invalidateBudgetItem,
  updateBudgetItem,
  useBudgetItemMonthlySpend,
  useGetBudgetItem,
  useGetBudgetItemEvents,
} from "../../../api/budget_items";
import { EditableLink } from "../../Purchasing/Purchase/components/EditableLink";
import { useGetBudgetGroup } from "../../../api/budget_groups";
import { BudgetSpentChart } from "../../Finance/Budgets/BudgetGroup/components/BudgetSpentGraph";
import { TransactionTable } from "../../Finance/Budgets/BudgetGroup/components/TransactionTable";
import BudgetActivityBarGraph from "../../Finance/Budgets/components/BudgetActivityBarGraph";

export const BudgetItemPage = () => {
  const backgroundGray = useColorModeValue("gray.50", "gray.700");
  const borderGray = useColorModeValue("gray.200", "gray.600");
  const { id } = useParams();

  const { data, isSuccess } = useGetBudgetItem(Number(id));
  const { data: budgetGroup } = useGetBudgetGroup(data?.budget_group?.id);
  const { data: events } = useGetBudgetItemEvents(Number(id));

  const monthlySpendQuery = useBudgetItemMonthlySpend(Number(id));

  const budgetBreadcrumb = () => {
    switch (data?.budgetable?.type) {
      case "Team":
        return {
          label: `${data.budgetable.name} Budget`,
          url: `/teams/${data.budgetable?.id}/budget`,
        };
      case "Project":
        return {
          label: `${data.budgetable.name} Budget`,
          url: `/planning/projects/${data.budgetable?.id}/budget`,
        };
      case "CapitalEquipment":
        return {
          label: `${data.budgetable.name} Budget`,
          url: `/equipment/${data.budgetable?.id}`,
        };
      case "User":
        return {
          label: `${data.budgetable.name} Budget`,
          url: `/users/${data.budgetable?.id}/budget`,
        };
      default:
        return { label: "Budget", url: "/budget" };
    }
  };

  return isSuccess ? (
    <>
      <Header title={data?.item_name || ""} crumbs={[budgetBreadcrumb()]} crumbsColor="teal" />
      <SplitPage
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        sidebar={
          <VStack height="100%" spacing={3}>
            <Box
              width="100%"
              bg={backgroundGray}
              border="1px"
              borderColor={borderGray}
              p={4}
              borderRadius="md">
              <AttributesTable
                attributes={[
                  {
                    label: "Budget Group",
                    value: <Text>{data?.name}</Text>,
                  },
                  {
                    label: "% of Group Budget Used",
                    value:
                      budgetGroup && budgetGroup?.summary && data.summary ? (
                        <BudgetSpentChart
                          budgetGroupSummary={budgetGroup.summary}
                          budgetSummary={data.summary}
                        />
                      ) : (
                        <Text>N/A</Text>
                      ),
                  },
                  {
                    label: "Link",
                    value: (
                      <EditableLink
                        iconSize={4}
                        url={data?.supporting_evidence}
                        onSubmit={(value) => {
                          if (value)
                            updateBudgetItem({
                              id: data.id,
                              supporting_evidence: value,
                            });
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          </VStack>
        }
        main={
          <Tabs colorScheme="teal">
            <TabList>
              <Tab>Spending</Tab>
              <Tab>Timeline</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex direction="column" gap={4}>
                  <>
                    <VStack
                      spacing={4}
                      w={"100%"}
                      border="1px"
                      borderColor="chakra-border-color"
                      p={6}
                      align="start"
                      borderRadius="md">
                      <Heading size="md">Activity</Heading>
                      {monthlySpendQuery.isLoading ? (
                        <Spinner />
                      ) : monthlySpendQuery.isError ? (
                        <Text>Error loading monthly spend</Text>
                      ) : (
                        <BudgetActivityBarGraph monthlySpend={monthlySpendQuery?.data} />
                      )}
                    </VStack>
                    <BudgetItemSpendByModelStats budgetItemId={Number(id)} />
                  </>
                  <TransactionTable
                    queryFilters={{
                      spending_authority_type: "BudgetItem",
                      spending_authority_id: Number(id),
                    }}
                  />
                </Flex>
              </TabPanel>
              <TabPanel>
                {events ? (
                  <TimelineTable
                    events={events || []}
                    timelineable_id={Number(id)}
                    timelineable_type="BudgetItem"
                    onComment={invalidateBudgetItem(Number(id), "events")}
                  />
                ) : (
                  <Spinner />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        }
      />
    </>
  ) : (
    <Spinner />
  );
};
