import { z } from "zod";
import { api } from ".";
import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useMutation,
  UseQueryOptions,
} from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { aggregationSchema, indexParams, searchParams, SearchParams } from "./collection_types";
import { userMinimalSchema, userPartialSchema } from "./user";
import {
  keyringMessageSchema,
  updateKeyringMessageSchema,
  userPublicKeySchema,
} from "./keyring_message";
import { CANDIDATES_BASE_URL } from "./candidates";
import { zodParse } from "./zodParse";

export const CANDIDATES_FEEDBACK_BASE_URL = "candidate_feedbacks";

export const candidateFeedbackCreateSchema = z.object({
  candidate_id: z.number(),
  user_id: z.number(),
});

export const CandidateFeedbackUpdateSchema = candidateFeedbackCreateSchema.extend({
  id: z.number(),
  keyring_message: updateKeyringMessageSchema.nullable(),
});

export const CandidatePartialFeedbackSchema = z.object({
  id: z.number(),
  candidate_id: z.number(),
  user_id: z.number(),
});

export const CandidateDisplayFeedbackSchema = z.object({
  candidate_id: z.number(),
  candidate: z.object({
    id: z.number(),
    name: z.string(),
    team_id: z.number().nullable(),
    role: z.string(),
    team: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable(),
    budget_headcount_id: z.number().nullable(),
    budget_headcount: z
      .object({
        id: z.number(),
        archetype: z.object({
          id: z.number(),
          name: z.string(),
        }),
        budgetable: z.object({
          id: z.number(),
          name: z.string(),
        }),
      })
      .nullable(),
    decision: z.string(),
    hiring_manager_id: z.number().nullable(),
  }),

  user_id: z.number(),
  id: z.number(),

  locked: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  has_references: z.boolean(),

  keyring_message: keyringMessageSchema.nullable(),
  keyring_user_keys: z.array(userPublicKeySchema),
});

export const CandidateCompletedFeedbackSchema = CandidateFeedbackUpdateSchema.extend({
  user: userMinimalSchema,
  locked: z.boolean(),
  candidate: z.object({ name: z.string(), id: z.number() }),
  keyring_message: updateKeyringMessageSchema.nullable(),
});

export const GetCandidateCompletedFeedbackSchema = CandidateCompletedFeedbackSchema.extend({
  keyring_user_keys: z.array(userPublicKeySchema),
});

export const CandidateFeedbackIndexParams = z.object({
  results: z.array(CandidateDisplayFeedbackSchema),
  pagination: paginationSchema,
});

export const CandidateFeedbackSearchIndexSchema = z.object({
  results: z.array(CandidateDisplayFeedbackSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable(),
});

export const candidateFeedbackEncryptedDataSchema = z.object({
  technical_score: z.number(),
  culture_score: z.number(),
  mission_score: z.number(),
  communication_score: z.number(),
  overall_score: z.number(),
  overall_feedback: z.string(),
  strengths: z.string(),
  concerns: z.string(),
  interviews_attended: z.array(z.string()).nullable().optional(),
});

export type CandidateFeedbackEmployee = z.infer<typeof CandidateFeedbackIndexParams>;
export type CandidatePartialData = z.infer<typeof CandidatePartialFeedbackSchema>;
export type CandidateFeedbackCreateParams = z.infer<typeof candidateFeedbackCreateSchema>;
export type CandidateFeedbackUpdateSchema = z.infer<typeof CandidateFeedbackUpdateSchema>;
export type CandidateFeedbackIndexItem = z.infer<typeof CandidateDisplayFeedbackSchema>;
export type CandidateIndexData = z.infer<typeof CandidateFeedbackSearchIndexSchema>;
export type GetCandidateCompletedFeedback = z.infer<typeof GetCandidateCompletedFeedbackSchema>;
export type CandidateFeedbackEncryptedData = z.infer<typeof candidateFeedbackEncryptedDataSchema>;

export const getCandidateFeedback = async (id: string) => {
  const result = await api.get(`${CANDIDATES_FEEDBACK_BASE_URL}/${id}`);
  return zodParse(GetCandidateCompletedFeedbackSchema, result.data);
};

export const getAllAdminCandidateFeedback = async (params: { only_unencrypted?: boolean }) => {
  const result = await api.get(`${CANDIDATES_FEEDBACK_BASE_URL}/admin`, {
    params,
  });
  return zodParse(GetCandidateCompletedFeedbackSchema.array(), result.data);
};

export const getAssignedCandidateFeedback = async () => {
  const result = await api.get(`${CANDIDATES_FEEDBACK_BASE_URL}`);
  return zodParse(CandidateFeedbackIndexParams, result.data);
};

export const searchCandidateFeedbacks = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [CANDIDATES_FEEDBACK_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(CandidateFeedbackSearchIndexSchema, result.data);
};

export const createCandidateFeedback = async (candidateFeedback: CandidateFeedbackCreateParams) => {
  const result = await api.post(CANDIDATES_FEEDBACK_BASE_URL, {
    candidate_feedback: candidateFeedback,
  });
  return result.data;
};

export const updateCandidateFeedback = async (candidateFeedback: CandidateFeedbackUpdateSchema) => {
  const result = await api.put(`${CANDIDATES_FEEDBACK_BASE_URL}/${candidateFeedback.id}`, {
    candidate_feedback: candidateFeedback,
  });
  return result.data;
};

export const updateBatchCandidateFeedback = async (
  candidateFeedbacks: CandidateFeedbackUpdateSchema[]
) => {
  const result = await api.put(`${CANDIDATES_FEEDBACK_BASE_URL}/batch_update_keyrings`, {
    candidate_feedback_keyrings: candidateFeedbacks.map((candidateFeedback) => {
      return {
        id: candidateFeedback.id,
        keyring_message: candidateFeedback.keyring_message,
      };
    }),
  });
  return result.data;
};

export const deleteCandidateFeedback = async (id: number) => {
  await api.delete(`${CANDIDATES_FEEDBACK_BASE_URL}/${id}`);
};

export const invalidateCandidateFeedback = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: [CANDIDATES_FEEDBACK_BASE_URL],
    });
    queryClient.invalidateQueries({
      // For some reason setting the queryKey to [CANDIDATES_BASE_URL] should
      // invalidate [CANDIDATES_BASE_URL, candidateId] but it doesn't. So we
      // have to manually invalidate the query via predicate.
      predicate: (query) => {
        return query.queryKey[0] === CANDIDATES_BASE_URL;
      },
    });
  };
};

export const useCandidateFeedbacksSearchQuery = (
  params: SearchParams,
  opts?: any
): UseQueryResult<CandidateIndexData> => {
  return useQuery({
    queryKey: [CANDIDATES_FEEDBACK_BASE_URL, params],
    queryFn: () => searchCandidateFeedbacks(params),
    ...opts,
  });
};

export const useGetCandidateFeedback = (
  id: string,
  opts?: Omit<UseQueryOptions<GetCandidateCompletedFeedback>, "queryFn">
) => {
  return useQuery({
    queryKey: [CANDIDATES_FEEDBACK_BASE_URL, id],
    queryFn: () => getCandidateFeedback(id),
    ...opts,
  });
};

export const useGetAllAdminCandidateFeedback = (params: { only_unencrypted?: boolean }) => {
  return useQuery({
    queryKey: [CANDIDATES_FEEDBACK_BASE_URL, "admin", params.only_unencrypted],
    queryFn: () => getAllAdminCandidateFeedback(params),
  });
};

export const useGetAssignedCandidateFeedback = (id: number) => {
  return useQuery({
    queryKey: [CANDIDATES_FEEDBACK_BASE_URL, id],
    queryFn: () => getAssignedCandidateFeedback(),
  });
};

export const useCreateCandidateFeedback = () => {
  return useMutation({
    mutationFn: createCandidateFeedback,
    onSuccess: invalidateCandidateFeedback(),
  });
};

export const useUpdateCandidateFeedback = () => {
  return useMutation({
    mutationFn: updateCandidateFeedback,
    onSuccess: invalidateCandidateFeedback(),
  });
};

export const useBatchUpdateCandidateFeedback = () => {
  return useMutation({
    mutationFn: updateBatchCandidateFeedback,
    onSuccess: invalidateCandidateFeedback(),
  });
};

export const useDeleteCandidateFeedback = () => {
  return useMutation({
    mutationFn: deleteCandidateFeedback,
    onSuccess: invalidateCandidateFeedback(),
  });
};
