import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { MilestonePartialData, newMilestonePrediction } from "../../api/milestone";

export const NewPredictionModal = (props: {
  milestone: MilestonePartialData;
  onCreate: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [completionDate, setCompletionDate] = React.useState("");

  return (
    <>
      <Card as={Button} w="100%" h="100%" textAlign="start" onClick={onOpen}>
        <CardBody w="100%">
          <HStack spacing={8} h="100%">
            <AddIcon />
            <Text>Add Prediction</Text>
          </HStack>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Prediction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Predicted Completion Time</FormLabel>
              <Input
                placeholder="Select Time"
                size="md"
                type="datetime-local"
                value={completionDate}
                onChange={(e) => setCompletionDate(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              isDisabled={!completionDate}
              onClick={async () => {
                await newMilestonePrediction({
                  milestone_id: props.milestone.id,
                  completion_date: completionDate,
                });
                props.onCreate();
                onClose();
              }}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
