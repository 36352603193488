import { Box, MenuItem, useDisclosure, Text } from "@chakra-ui/react";

import React, { ReactNode, useEffect, useState } from "react";
import { Footer, Link, Navbar } from "@sciencecorp/helix-components";

import { handleLogin, handleLogout } from "../../api";
import { useCurrentUserQuery, userHasFeatureFlag, userHasRole } from "../../api/user";

import { BackgroundTasks, useBackgroundTasksData } from "../HR/Encryption/BackgroundTasks";
import type { NavItem } from "@sciencecorp/helix-components";
import { ChatDrawer } from "../Chat";
import { SurveyToaster } from "../Surveys/surveyToaster";
import { OminiSearchModal } from "../OminiSearch/OmnisearchModal";
import { useKeyPress } from "../hooks/useKeyPress.ts";
import "../../../assets/stylesheets/application.css";
import { SecureModeProvider, useSecureModeReducer } from "@sciencecorp/helix-components";
import { SecureModeModal } from "../Encryption/SecureModeModal";
import RefreshPageToast from "../RefreshPageToast";
import { MeetingNotesNavBar } from "../MeetingNotes/MeetingNotesNavBar";
import { UserCurrencyProvider } from "../../contexts/CurrencyContext";

export const App = (props: { children: ReactNode }) => {
  const { children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const backgroundTasksData = useBackgroundTasksData();
  const [smState, smDispatch] = useSecureModeReducer();
  const userQuery = useCurrentUserQuery(true);
  const userData = userQuery.data;
  const [showRefreshPageToast, setShowRefreshPageToast] = useState(
    userData?.detectedNewDeploymentVersion ?? false
  );

  const isTopLevelTeamLead = userQuery.data?.team_memberships.some(
    (tm) => tm.is_lead && tm.team.parent_team_id === null
  );

  useEffect(() => {
    if (userData?.detectedNewDeploymentVersion) {
      setShowRefreshPageToast(true);
    }
  }, [userData?.detectedNewDeploymentVersion]);

  const isPurchasingAdmin = userHasRole(userQuery, "purchasing_admin");

  const navItems: NavItem[] = [
    {
      label: "Hiring",
      href: "/hiring/completed_interviews",
    },
    {
      label: "Projects",
      href: "/planning?facet=status%3Aapproved",
    },
    {
      label: "Teams",
      href: "/teams",
    },

    {
      label: "Requests",
      href: "/services/all",
      sub: [
        { label: "Requests", href: "/services/all" },
        { label: "Your Requests", href: "/services/your_requests" },
        { label: "Purchases", href: "/services/purchasing" },
        ...(isPurchasingAdmin || isTopLevelTeamLead
          ? [
              {
                label: "Contracts",
                href: "/services/contracts",
              },
              {
                label: "Subscriptions",
                href: "/services/subscriptions",
              },
            ]
          : []),
        { label: "Reimbursements", href: "/services/reimbursements" },
        { label: "Vendors", href: "/services/vendors" },
      ],
    },
    {
      label: "Inventory",
      href: "/inventory/items",
    },
    {
      label: "Equipment",
      href: "/equipment",
    },
  ];

  const userMenu = {
    ...userData,
    url: `/users/${userData?.id}/profile`,
    children: (
      <>
        <MenuItem
          as={Link}
          _hover={{ textDecoration: "none " }}
          to={`/users/${userData?.id}/settings`}>
          Settings
        </MenuItem>
        <MenuItem as={Link} _hover={{ textDecoration: "none " }} to="/reviews">
          Performance Reviews
        </MenuItem>
        <MenuItem as={Link} _hover={{ textDecoration: "none " }} to="/credentials/me">
          Credentials
        </MenuItem>
      </>
    ),
  };

  const hrNavItem: NavItem[] = userHasRole(userQuery, "hr_admin")
    ? [
        {
          label: "HR",
          href: "/hr",
          sub: [
            { label: "Encryption", href: "/hr/encryption" },
            { label: "Employees", href: "/hr/employees" },
            { label: "Survey Results", href: "/hr/survey_results" },
            { label: "Sites", href: "/hr/sites" },
          ],
        },
      ]
    : [];

  const financeNavItem =
    userHasRole(userQuery, "finance_admin") ||
    userHasRole(userQuery, "purchasing_admin") ||
    userQuery.data?.budget_or_budget_group_lead
      ? [
          {
            label: "Finance",
            href: "/finance",
          },
        ]
      : [];

  useKeyPress(() => onOpen(), ["Slash", "IntlRo", "ForwardSlash"]);

  return (
    <>
      <UserCurrencyProvider>
        <SecureModeProvider stateValue={smState} dispatchValue={smDispatch}>
          <SecureModeModal />
          <Navbar
            homeUrl={"/"}
            inboxUrl={"/inbox/all"}
            items={[...navItems, ...hrNavItem, ...financeNavItem]}
            title={"Helix"}
            user={userMenu}
            onLogin={handleLogin}
            onLogout={handleLogout}
            omnisearch={{ onClick: () => onOpen() }}
            showInbox
            meetingNotesChildren={<MeetingNotesNavBar />}
            showMeetingNotes
          />
          <OminiSearchModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          {showRefreshPageToast ? <RefreshPageToast /> : <></>}
          <Box data-testid={"app"} p={4}>
            {children}
          </Box>
          <Footer />
          <SurveyToaster />
          {backgroundTasksData && <BackgroundTasks backgroundTasksData={backgroundTasksData} />}
          {userHasFeatureFlag(userQuery, "assistant") && userData?.assistant_url && (
            <ChatDrawer socketUrl={userData.assistant_url} />
          )}
        </SecureModeProvider>
      </UserCurrencyProvider>
    </>
  );
};
