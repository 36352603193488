import React from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { EditableText, ConfirmationButton, Editable } from "@sciencecorp/helix-components";
import {
  useUpdateCandidateReference,
  useDeleteCandidateReference,
  CandidateReferenceData,
} from "../../../api/candidates";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";

type CandidateReferenceCardProps = {
  reference: CandidateReferenceData;
  location: string;
};

export const CandidateReferenceCard = ({ reference, location }: CandidateReferenceCardProps) => {
  const { mutate: updateCandidateReference } = useUpdateCandidateReference();
  const { mutate: deleteCandidateReference } = useDeleteCandidateReference();
  const userQuery = useCurrentUserQuery();
  const recruitingAdmin = userHasRole(userQuery, "recruiting_admin");

  return (
    <VStack
      width="100%"
      p={6}
      spacing={6}
      borderRadius="md"
      align="start"
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}>
      <VStack width="100%" align="start">
        <VStack width="100%" align="start">
          <HStack w={"100%"} justify={"space-between"}>
            <EditableText
              preview={<Heading size="md">{reference.name ? reference.name : `Add Name`}</Heading>}
              persistentEdit={recruitingAdmin}
              disabled={!recruitingAdmin}
              defaultValue={reference.name ? reference.name : undefined}
              onSubmit={(value) => {
                if (value) updateCandidateReference({ id: reference.id, name: value });
              }}
              minWidth={2}
            />
            {recruitingAdmin && (
              <ConfirmationButton
                label="Delete Reference"
                variant="IconButton"
                aria-label="delete reference"
                buttonVariant="outline"
                icon={<DeleteIcon />}
                confirmationButtonLabel="Delete"
                colorScheme="red"
                children="Are you sure you want to delete this information?"
                confirmationHeader="Delete Reference Info"
                onConfirm={() => deleteCandidateReference(reference.id)}
              />
            )}
          </HStack>
          <Stack spacing={{ base: 4, lg: 9 }} w={"100%"} direction={{ base: "column", lg: "row" }}>
            <EditableText
              persistentEdit={recruitingAdmin}
              disabled={!recruitingAdmin}
              defaultValue={reference.phone_number ? reference.phone_number : undefined}
              placeholder="Add phone number"
              onSubmit={(value) => {
                if (value) updateCandidateReference({ id: reference.id, phone_number: value });
              }}
            />

            <EditableText
              persistentEdit={recruitingAdmin}
              disabled={!recruitingAdmin}
              defaultValue={reference.email ? reference.email : undefined}
              placeholder="Add email address"
              onSubmit={(value) => {
                if (value) updateCandidateReference({ id: reference.id, email: value });
              }}
            />
          </Stack>
        </VStack>
      </VStack>
      <VStack width="100%" align="start">
        <Stack spacing={5} direction={["column", "column", "column", "row"]}>
          <VStack align="start">
            <Heading size="sm">Company</Heading>
            <EditableText
              persistentEdit={recruitingAdmin}
              disabled={!recruitingAdmin}
              defaultValue={reference.company ? reference.company : undefined}
              placeholder="Reference's Company"
              onSubmit={(value) => {
                if (value) updateCandidateReference({ id: reference.id, company: value });
              }}
            />
          </VStack>
          <VStack align="start">
            <Heading size="sm">Title</Heading>
            <EditableText
              persistentEdit={recruitingAdmin}
              disabled={!recruitingAdmin}
              defaultValue={reference.title ? reference.title : undefined}
              placeholder="Reference's Title"
              onSubmit={(value) => {
                if (value) updateCandidateReference({ id: reference.id, title: value });
              }}
            />
          </VStack>
        </Stack>
      </VStack>
      <VStack width="100%" align="start">
        <Heading size="sm">Relationship</Heading>
        <EditableText
          persistentEdit={recruitingAdmin}
          disabled={!recruitingAdmin}
          defaultValue={reference.relationship ? reference.relationship : undefined}
          placeholder="Add relationship to candidate"
          onSubmit={(value) => {
            if (value) updateCandidateReference({ id: reference.id, relationship: value });
          }}
        />
      </VStack>
      {location === "debrief" || reference.additional_information ? (
        <VStack width="100%" align="start">
          <Heading size="sm">Additional Information</Heading>
          <EditableText
            persistentEdit={recruitingAdmin}
            disabled={!recruitingAdmin}
            defaultValue={reference.additional_information}
            placeholder="Add additional information"
            multiline
            onSubmit={(value) => {
              if (value)
                updateCandidateReference({ id: reference.id, additional_information: value });
            }}
          />
        </VStack>
      ) : null}

      <VStack width="100%" align="start">
        <Heading size="sm">Notes from meeting</Heading>
        <Box width="100%">
          <EditableText
            persistentEdit={location === "hiringManager"}
            defaultValue={reference.meeting_notes ? reference.meeting_notes : undefined}
            multiline={true}
            placeholder="Add notes"
            onSubmit={(value) => {
              if (value) updateCandidateReference({ id: reference.id, meeting_notes: value });
            }}
          />
        </Box>
      </VStack>

      <VStack width="100%" align="start">
        <Heading size="sm">Post Call Feelings</Heading>
        <>
          <Text fontSize="sm" color={useColorModeValue("gray.500", "gray.400")}>
            After your conversation, how would you vote for the candidate’s hire?
          </Text>
          <Editable
            persistentEdit={true}
            defaultValue={reference.impression ? reference.impression : undefined}
            preview={
              reference.impression ? (
                <Text>{reference.impression}</Text>
              ) : (
                <Text as="i" color="gray.400">
                  Add impression
                </Text>
              )
            }
            onSubmit={(value) => updateCandidateReference({ id: reference.id, impression: value })}
            renderInput={(value, setValue) => (
              <RadioGroup value={value.toString() || ""} onChange={(value) => setValue(value)}>
                <HStack spacing={4}>
                  <Radio value="Strong Yes">Strong Yes</Radio>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                  <Radio value="Strong No">Strong No</Radio>
                </HStack>
              </RadioGroup>
            )}
          />
        </>
      </VStack>
    </VStack>
  );
};
