import React, { useMemo } from "react";
import { Collection, useCollection } from "@sciencecorp/helix-components";
import { Link, Tag, VStack, useColorModeValue, Text } from "@chakra-ui/react";
import {
  useGetUserAttendanceHistory,
  TrainingSessionAttendanceData,
  useGetUserFacilitatorTrainingHistory,
} from "../../../../api/trainings";
import { TrainingSessionData } from "../../../../api/training_sessions";
import { FacilitatorCard, attendanceHistoryColorScheme } from "../../util";
import { DateTime } from "luxon";
import { titleize } from "inflection";
import { Link as RouterLink } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";

export const AttendanceHistoryTable = ({ trainingId }: { trainingId: number }) => {
  const { data: attendanceHistory, isLoading } = useGetUserAttendanceHistory(trainingId);
  const { pagination, onPagination, onOrder, order } = useCollection();

  const columns = useMemo(
    () => [
      {
        label: "Date",
        render: (trainingSession: TrainingSessionAttendanceData) =>
          DateTime.fromISO(trainingSession.scheduled_date).toFormat("LLL dd yyyy"),
      },
      {
        label: "Time",
        render: (trainingSession: TrainingSessionAttendanceData) =>
          DateTime.fromISO(trainingSession.scheduled_date).toLocal().toFormat("hh:mm a"),
      },
      {
        label: "Facilitated By",
        render: (trainingSession: TrainingSessionAttendanceData) => (
          <FacilitatorCard facilitatorId={trainingSession.facilitator_id} />
        ),
      },
      {
        label: "Status",
        render: (trainingSession: TrainingSessionAttendanceData) => (
          <Tag
            colorScheme={attendanceHistoryColorScheme(trainingSession.user_training_status || "")}>
            {titleize(trainingSession.user_training_status)}
          </Tag>
        ),
      },
    ],
    [attendanceHistory]
  );

  return attendanceHistory?.results.length ? (
    <Collection
      isLoading={isLoading}
      items={attendanceHistory?.results || []}
      columns={columns}
      pagination={attendanceHistory?.pagination || pagination}
      onPagination={onPagination}
      onOrder={onOrder}
      order={order}
    />
  ) : (
    <VStack
      width="100%"
      minHeight="2xs"
      justify="center"
      borderRadius="md"
      bg={useColorModeValue("gray.50", "gray.700")}
      opacity="0.7"
      spacing={5}>
      <Search2Icon boxSize={6} />
      <VStack spacing={0}>
        <Text fontWeight="semibold" fontSize="sm">
          It’s empty here.
        </Text>
        <Text fontWeight="medium" fontSize="xs">
          You have not attended this training.
        </Text>
      </VStack>
    </VStack>
  );
};

export const FacilitatorHistoryTable = ({ trainingId }) => {
  const { data, isLoading } = useGetUserFacilitatorTrainingHistory(trainingId);
  const { pagination, onPagination, onOrder, order } = useCollection();

  const columns = useMemo(
    () => [
      {
        label: "Date",
        render: (trainingSession: TrainingSessionData) =>
          DateTime.fromISO(trainingSession.scheduled_date).toFormat("LLL dd yyyy"),
      },
      {
        label: "Time",
        render: (trainingSession: TrainingSessionData) =>
          DateTime.fromISO(trainingSession.scheduled_date).toLocal().toFormat("hh:mm a"),
      },
      {
        label: "Attendees",
        render: (trainingSession: TrainingSessionData) => trainingSession.attendee_count,
      },
      {
        label: "Actions",
        render: (trainingSession: TrainingSessionData) => (
          <Link
            as={RouterLink}
            color="teal.500"
            to={`/trainings/${trainingId}/training_sessions/${trainingSession.id}`}>
            View Session
          </Link>
        ),
      },
    ],
    [data]
  );

  return (
    <Collection
      isLoading={isLoading}
      items={data?.results || []}
      columns={columns}
      pagination={data?.pagination || pagination}
      onPagination={onPagination}
      onOrder={onOrder}
      order={order}
    />
  );
};
