import React, { useMemo, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Collection, ConfirmationModal, useCollection } from "@sciencecorp/helix-components";
import { useGetVendorContacts } from "../../../../api/vendor";
import { VendorContactShowData, useDeleteVendorContact } from "../../../../api/vendor_contacts";
import { NewVendorContactModal } from "./NewVendorContactModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import { EmptyState } from "../../../shared/EmptyState";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

export const VendorContactsTable = ({ vendor }) => {
  const { data: vendorContacts, isLoading } = useGetVendorContacts(vendor.id);
  const { mutate: deleteVendorContact } = useDeleteVendorContact(vendor.id);

  const { pagination, onPagination, order, onOrder } = useCollection();
  const {
    isOpen: isOpenEditContact,
    onOpen: onOpenEditContact,
    onClose: onCloseEditContact,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleteContact,
    onOpen: onOpenDeleteContact,
    onClose: onCloseDeleteContact,
  } = useDisclosure();

  const [activeContact, setActiveContact] = useState<VendorContactShowData | null>(null);

  const columns = useMemo(
    () => [
      {
        label: "Name",
        render: (vendorContact: VendorContactShowData) => vendorContact.name || "",
      },
      {
        label: "Phone Number",
        render: (vendorContact: VendorContactShowData) => vendorContact.phone_number || "",
      },
      {
        label: "Email",
        render: (vendorContact: VendorContactShowData) => vendorContact.email || "",
      },
      {
        label: "Remittance Address",
        render: (vendorContact: VendorContactShowData) => vendorContact.remittance_address || "",
      },
      {
        label: "Additional Information",
        weight: 2,
        render: (vendorContact: VendorContactShowData) =>
          vendorContact.additional_information || "",
      },
      {
        label: "",
        weight: 0.5,
        render: (vendorContact: VendorContactShowData) => (
          <ActionsButton
            contact={vendorContact}
            onDeleteOpen={onOpenDeleteContact}
            onEditOpen={onOpenEditContact}
            setActiveContact={setActiveContact}
          />
        ),
      },
    ],
    [vendorContacts]
  );

  return vendorContacts ? (
    vendorContacts.results.length ? (
      <Box width="100%">
        <Collection
          items={vendorContacts.results}
          columns={columns}
          isLoading={isLoading}
          pagination={vendorContacts.pagination || pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
        />
        {activeContact && (
          <>
            <NewVendorContactModal
              isOpen={isOpenEditContact}
              onClose={onCloseEditContact}
              vendor={vendor}
              contact={activeContact}
            />
            <ConfirmationModal
              colorScheme="red"
              isOpen={isOpenDeleteContact}
              onClose={onCloseDeleteContact}
              header="Delete Contact"
              children="Are you sure you want to delete this contact?"
              confirmText="Delete"
              onClick={() => deleteVendorContact(activeContact.id)}
            />
          </>
        )}
      </Box>
    ) : (
      <EmptyState title="Use the “Add New” button above to add new contacts." size="3xs" />
    )
  ) : (
    <Spinner />
  );
};

const ActionsButton = ({ contact, onDeleteOpen, onEditOpen, setActiveContact }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            setActiveContact(contact);
            onEditOpen();
          }}>
          <Icon as={EditIcon} />
          Edit Contact
        </MenuItem>
        <MenuItem
          gap={3}
          color="red.500"
          onClick={() => {
            setActiveContact(contact);
            onDeleteOpen();
          }}>
          <Icon as={DeleteIcon} />
          Delete Contact
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
