import React, { useState } from "react";
import {
  VStack,
  Box,
  Text,
  HStack,
  Heading,
  Flex,
  UnorderedList,
  ListItem,
  Tag,
  Stack,
  useColorModeValue,
  Button,
  SkeletonText,
  Avatar,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { CandidateData, CandidateKeyringMessageData } from "../../../api/candidates";
import { EditableText, RecordLink } from "@sciencecorp/helix-components";
import { sortBy } from "lodash";
import { Tooltip } from "@chakra-ui/react";

type SummaryCandidateFeedbackProps = {
  candidate: CandidateData;
  section: "Summary";
  question?: string;
  editable?: boolean;
  finishedFeedback: CompletedCandidateFeedback[];
  onSummaryUpdatePressed: () => void;
  updateCandidate: (data: Partial<CandidateKeyringMessageData>) => void;
  isLoading: boolean;
  summary?: string;
};

type OtherCandidateFeedbackProps = {
  candidate: CandidateData;
  section: "Explanations" | "Strengths" | "Opportunity for Growth/Areas of Concern";
  question?: string;
  editable?: boolean;
  finishedFeedback: CompletedCandidateFeedback[];
  isLoading?: boolean;
  onSummaryUpdatePressed?: undefined;
  updateCandidate: (data: Partial<CandidateKeyringMessageData>) => void;
  summary?: undefined;
};

type CandidateFeedbackProps = SummaryCandidateFeedbackProps | OtherCandidateFeedbackProps;

export type CompletedCandidateFeedback = {
  id: number;
  candidate_id: number;
  user_id: number;
  user: { id: number; name: string; picture_uri: string };
  technical_score: number;
  culture_score: number;
  mission_score: number;
  communication_score: number;
  overall_score: number;
  overall_feedback: string;
  strengths: string;
  concerns: string;
};

export type PendingCandidateFeedback = {
  id: number;
  candidate_id: number;
  user_id: number;
  user: { id: number; name: string; picture_uri: string };
};

const sectionMap = {
  Explanations: "overall_feedback",
  Strengths: "strengths",
  "Opportunity for Growth/Areas of Concern": "concerns",
};

const colors = () => ({
  4: useColorModeValue("green.100", "green.700"),
  3: useColorModeValue("blue.100", "blue.700"),
  2: useColorModeValue("orange.100", "orange.700"),
  1: useColorModeValue("red.100", "red.700"),
});

export const CandidateFeedback: React.FC<CandidateFeedbackProps> = ({
  candidate,
  section,
  question,
  editable,
  finishedFeedback,
  onSummaryUpdatePressed,
  updateCandidate: updateCandidateKRData,
  isLoading = false,
  summary,
}) => {
  const sortedFeedback = sortBy(finishedFeedback, ["overall_score"]).reverse();
  return (
    <Box
      width={["100%", "100%", "50%"]}
      bg={useColorModeValue("white", "gray.700")}
      p={4}
      border="1px"
      borderRadius="md"
      borderColor={useColorModeValue("gray.200", "gray.600")}>
      <Box
        minH={400}
        height="100%"
        width="100%"
        pt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between">
        <Box>
          <Heading size="md">{section}</Heading>
          {section === "Summary" && (
            <>
              <Stack
                direction={{ base: "column", md: "row" }}
                mt={3}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={{ base: 3, md: 6 }}>
                <Text>
                  <Flex alignItems="center" direction="row" gap={2}>
                    <Box as="span" fontWeight="bold">
                      Role:
                    </Box>{" "}
                    {candidate.budget_headcount ? (
                      <RecordLink
                        size="medium"
                        link={`/hiring/vacancies/${candidate.budget_headcount?.id}`}
                        type={candidate.budget_headcount?.budgetable.name}
                        identifier={
                          candidate.budget_headcount?.archetype.name || candidate.role
                        }></RecordLink>
                    ) : (
                      <Text>{candidate.role}</Text>
                    )}
                  </Flex>
                </Text>
                <Text>
                  <Flex alignItems="center" direction="row" gap={2}>
                    <Box as="span" fontWeight="bold">
                      Hiring Manager:{" "}
                    </Box>{" "}
                    <Text as="span">{candidate.hiring_manager_name}</Text>
                  </Flex>
                </Text>
                <Flex alignItems="center" direction="row" gap={2}>
                  <Text fontWeight="bold" fontSize="md">
                    Test Score:
                  </Text>
                  <EditableText
                    persistentEdit={true}
                    preview={
                      <Text fontSize="md">
                        {candidate.test_score ? `${candidate.test_score}%` : "N/A"}
                      </Text>
                    }
                    defaultValue={candidate.test_score ? candidate.test_score.toString() : ""}
                    onSubmit={(value) => {
                      updateCandidateKRData({ test_score: Number(value) });
                    }}
                  />
                </Flex>
              </Stack>
              <Stack
                direction={{ base: "column", md: "row" }}
                mt={2}
                spacing={{ base: 3, md: 6 }}></Stack>
              <Flex direction="column" w="100%" mt={2}>
                <Text fontWeight="bold" fontSize="md">
                  Evidence of Exceptional Ability:
                </Text>
                <Box flex="1">
                  <EditableText
                    persistentEdit={editable}
                    disabled={!editable}
                    multiline={true}
                    defaultValue={candidate.exceptional_ability ?? undefined}
                    placeholder="Add evidence of exceptional ability"
                    onSubmit={(value) => {
                      if (value) updateCandidateKRData({ exceptional_ability: value });
                    }}
                  />
                </Box>
              </Flex>
            </>
          )}
        </Box>
        {question && <Text my={2}>{question}</Text>}

        <Flex direction="column" flexGrow={1} height="100%">
          {section === "Summary" ? (
            <Flex direction="column" justify="space-between" height="100%" mt={6} mr={4}>
              {summary && summary.length /* Summary already exists */ ? (
                isLoading ? (
                  <SkeletonText noOfLines={5} skeletonHeight={6} />
                ) : (
                  <Text>{summary}</Text>
                )
              ) : isLoading /* no summary */ ? (
                <SkeletonText noOfLines={5} skeletonHeight={6} />
              ) : (
                <EmptyFeedback />
              )}
              {editable && (
                <Button
                  onClick={() => {
                    if (finishedFeedback.length > 0) {
                      onSummaryUpdatePressed();
                    }
                  }}
                  isDisabled={isLoading || !finishedFeedback.length}>
                  Generate Summary
                </Button>
              )}
            </Flex>
          ) : sortedFeedback.length && section === "Explanations" ? (
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <Flex gap={3} flexWrap="wrap">
                {sortedFeedback.map((ele, idx) => (
                  <FeedbackQuotes key={idx} info={ele.overall_feedback} score={ele.overall_score} />
                ))}
              </Flex>
              <FeedbackQuotesKey />
            </Box>
          ) : finishedFeedback.length ? (
            <FeedbackList info={finishedFeedback.map((ele) => ele[sectionMap[section]])} />
          ) : (
            <EmptyFeedback />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export const EmptyFeedback = () => (
  <Box opacity="0.7" display="flex" height="100%" width="100%" justifyContent="center">
    <VStack height="100%" justify="center" align="center">
      <Box bg={"RGBA(0, 0, 0, 0.08)"} p={2} borderRadius="full">
        <Search2Icon boxSize={6} />
      </Box>
      <Heading size="sm">It's Empty Here</Heading>
      <Text align="center" maxWidth={80}>
        This section will be populated after a few submissions have been made.
      </Text>
    </VStack>
  </Box>
);

export const FeedbackQuotes = ({ info, score }) => {
  const c = colors();
  return (
    <Box bg={c[score]} minW={["50%", "30%"]} flexGrow={1} borderRadius="md" p={2.5}>
      <Text color={useColorModeValue("gray.700", "gray.300")}>"{info}"</Text>
    </Box>
  );
};

export const FeedbackList = ({ info }) => {
  const hasContent = info.some((str: string) => str.length > 0);
  return hasContent ? (
    <UnorderedList mt={2}>
      {info.map((bullet: string) => {
        return bullet.length ? <ListItem key={bullet}>{bullet}</ListItem> : null;
      })}
    </UnorderedList>
  ) : (
    <EmptyFeedback />
  );
};

const FeedbackQuotesKey = () => {
  const colorKey: JSX.Element[] = [];
  const c = colors();
  for (const key in c) {
    colorKey.push(
      <HStack key={key}>
        <Box
          bg={c[key]}
          my={2}
          borderRadius="md"
          p={2.5}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}></Box>
        <Text>{Number(key)}</Text>
      </HStack>
    );
  }

  return (
    <HStack>
      <Text>Overall Score: </Text>
      {colorKey.reverse()}
    </HStack>
  );
};
