import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  InventoryItemData,
  useGetInventoryItem,
  useTransferInventoryItem,
  useUpdateInventoryItems,
  useUsedInventoryItem,
} from "../../api/inventory_item";
import { LocationTreeValue } from "../../api/inventory_location";
import { MinimalSpendingAuthority } from "../../api/spending_authority";
import { SelectPurpose } from "./InventoryItemModal/SelectPurpose";
import { InventoryItemSummary } from "./InventoryItemModal/InventoryItemSummary";
import { SelectSpendingAuthority } from "./InventoryItemModal/SelectSpendingAuthority";
import { LuPackageMinus } from "react-icons/lu";
import { MinusIcon } from "@chakra-ui/icons";

export type FormValues = {
  quantity: number;
  inventory_location_id: number | null;
  inventory_item: InventoryItemData | null;
  option: LocationTreeValue | null;
  lot_number: string;
  expiration_date: string;
  spending_authority: MinimalSpendingAuthority | null;
  occurred_at: string;
  purpose: string;
  confirm_remove: string;
  user_id: number | null;
  quantity_used?: string;
};

const defaultFormValues = {
  quantity: 0,
  inventory_location_id: null,
  inventory_item: null,
  option: null,
  lot_number: "",
  expiration_date: "",
  spending_authority: null,
  occurred_at: DateTime.now().toISODate(),
  purpose: "",
  confirm_remove: "",
  user_id: null,
  quantity_used: "0.0",
};

export const InventoryItemPage = () => {
  const { mutate: transferInventoryItems, isLoading } = useTransferInventoryItem();
  const { mutate: usedInventoryItem, isLoading: usedInventoryItemIsLoading } =
    useUsedInventoryItem();
  const { handleSubmit, control, reset, watch, setValue } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });
  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const params = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const occurredAt = DateTime.fromISO(watch("occurred_at"));
  const [currentStep, setCurrentStep] = useState(0);
  const toast = useToast();

  const { id, inventoryItemId } = params;
  const {
    data: inventoryItem,
    isLoading: isLoadingInventoryItem,
    isError: isErrorInventoryItem,
  } = useGetInventoryItem(Number(inventoryItemId));
  const { mutate: updateInventoryItem } = useUpdateInventoryItems();

  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
    setCurrentStep(0);
  };

  const onSubmit = (data: FormValues) => {
    if (formValues.purpose === "spending_authority") {
      transferInventoryItems(
        {
          id: Number(inventoryItemId),
          spending_authority_id: Number(data.spending_authority?.id),
          spending_authority_type: String(data.spending_authority?.type),
          occurred_at: occurredAt.startOf("day"),
          user_id: data.user_id,
          quantity_used: parseFloat(data.quantity_used || "0.0"),
        },
        {
          onSuccess: () => {
            toast({
              title: "Inventory item successfully transfered.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            navigate(`/inventory/items/${id}`);
          },
          onError: (error: any) => {
            toast({
              title: "Error transferring inventory item.",
              description: error.message || "Unknown error occurred",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } else if (formValues.purpose === "waste") {
      updateInventoryItem(
        { id: Number(inventoryItem?.id), archived_at: DateTime.now(), inventory_location_id: null },
        {
          onSuccess: () => {
            toast({
              title: "Inventory item successfully deleted.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            navigate(`/inventory/items/${id}`);
          },
        }
      );
    } else if (formValues.purpose === "used") {
      usedInventoryItem(
        {
          id: Number(inventoryItemId),
          quantity_used: parseFloat(data.quantity_used || "0.0"),
        },
        {
          onSuccess: () => {
            toast({
              title: "Inventory item successfully used.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            navigate(`/inventory/items/${id}`);
          },
        }
      );
    }
    handleClose();
  };

  const disableProceedButton = (formValues: FormValues) => {
    if (currentStep === 0) return !formValues.purpose;
    if (currentStep === 1)
      if (formValues.purpose === "spending_authority") return !formValues.spending_authority?.id;
      else if (formValues.purpose === "used") return Number(formValues.quantity_used) <= 0;
  };

  const handleNextButtonClick = () => {
    if (currentStep === 2) handleSubmit(() => onSubmit(formValues))();
    else setCurrentStep((prev) => ++prev);
  };

  if (isLoadingInventoryItem || isLoading) {
    return <Spinner />;
  }

  if (isErrorInventoryItem) {
    return <Text>This inventory item does not exist</Text>;
  }

  return (
    <>
      <Flex align="center" justify="center" direction="column">
        <Flex
          direction="column"
          gap={8}
          justify={"center"}
          mt={10}
          borderRadius="md"
          p={8}
          border="1px"
          borderColor="chakra-border-color">
          <Icon as={LuPackageMinus} boxSize={10} color="gray.600" alignSelf="center" />
          <Flex direction="column" gap={0}>
            <Text
              textAlign="center"
              size="md"
              fontWeight={"medium"}
              textColor={useColorModeValue("gray.600", "gray.300")}>
              Helix ID: {inventoryItem.id}
            </Text>
            <Heading textAlign="center">{inventoryItem.inventory.name}</Heading>
          </Flex>
          <Button leftIcon={<MinusIcon />} onClick={onOpen} colorScheme="red" variant="outline">
            Remove from Stock
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove from Stock</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4} w="100%">
              {currentStep === 0 && (
                <SelectPurpose
                  inventoryItem={inventoryItem}
                  control={control}
                  setValue={setValue}
                />
              )}
              {currentStep === 1 &&
                (formValues.purpose !== "waste" ? (
                  <SelectSpendingAuthority
                    formValues={formValues}
                    control={control}
                    setValue={setValue}
                    inventoryItem={inventoryItem}
                    isInventoryManager={isInventoryManager}
                  />
                ) : (
                  <Stack spacing={8}>
                    <FormControl>
                      <Text>
                        Are you sure you want to remove this inventory item from stock? This action
                        cannot be undone.
                      </Text>
                    </FormControl>
                  </Stack>
                ))}

              {currentStep === 2 && (
                <InventoryItemSummary formValues={formValues} inventoryItem={inventoryItem} />
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%" justifyContent={currentStep === 0 ? "end" : "space-between"}>
              {currentStep !== 0 && (
                <Button onClick={() => setCurrentStep((prev) => --prev)} variant="ghost">
                  Back
                </Button>
              )}
              <Button
                justifySelf="flex-end"
                colorScheme="teal"
                onClick={handleNextButtonClick}
                isLoading={currentStep === 1 && isLoadingInventoryItem}
                isDisabled={disableProceedButton(formValues)}>
                {currentStep === 2 ? "Confirm" : "Next"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
