import React, { useState } from "react";
import {
  SplitPage,
  RecordLink,
  EditableText,
  EditableSelect,
  AttributesTable,
  Header,
  ConfirmationButton,
} from "@sciencecorp/helix-components";
import { Center, Flex, Spinner, Text, Box, useColorModeValue } from "@chakra-ui/react";
import {
  useDeleteCapitalEquipment,
  useGetCapitalEquipment,
  useSearchCapitalEquipments,
  useUpdateCapitalEquipment,
} from "../../../api/capital_equipment";
import { useParams, useNavigate } from "react-router";
import { useTeamOptions } from "../../../api/options";
import { SpendingCard } from "../../Budgets/SpendingCard";
import { DeleteIcon } from "@chakra-ui/icons";

export const CapitalEquipmentDetails = () => {
  const { id } = useParams();

  const { data: capitalEquipment, isLoading, isError } = useGetCapitalEquipment(Number(id));
  const { mutate: updateTool, isLoading: isLoadingUpdateTool } = useUpdateCapitalEquipment(
    Number(id)
  );
  const { mutate: deleteCapEquip, isLoading: isLoadingDeleteCapEquip } =
    useDeleteCapitalEquipment();
  const [loadingField, setLoadingField] = useState<string | null>(null);
  const teamOptions = useTeamOptions();
  const navigate = useNavigate();
  if (isLoading)
    return (
      <Center h="90vh">
        <Spinner />
      </Center>
    );

  if (isError) return <Text>Failed to load capital equipment</Text>;

  const handleUpdate = (field: string, value) => {
    setLoadingField(field);
    updateTool(
      { id: capitalEquipment?.id, [field]: value },
      { onSuccess: () => setLoadingField(null) }
    );
  };

  return (
    <Flex width="100%" direction="column" gap={4}>
      <Header
        title={capitalEquipment.name}
        crumbs={[{ label: "Equipment", url: "/equipment" }]}
        crumbsColor="teal"
        actions={[
          <ConfirmationButton
            label="Delete Equipment"
            confirmationButtonLabel="Delete"
            variant="Button"
            isLoading={isLoadingDeleteCapEquip}
            buttonVariant="outline"
            colorScheme="red"
            isDisabled={!capitalEquipment.can_be_deleted}
            leftIcon={<DeleteIcon />}
            children="Are you sure you want to delete this equipment? This action cannot be undone."
            onConfirm={() =>
              deleteCapEquip(Number(id), { onSuccess: () => navigate("/equipment") })
            }
          />,
        ]}
      />
      <Box overflowX="scroll">
        <SplitPage
          sidebarWidth="350px"
          sidebarWidthXL="450px"
          sidebar={
            <Box
              border="1px"
              p={4}
              borderColor="chakra-border-color"
              borderRadius="md"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <AttributesTable
                title="Tool Details"
                attributes={[
                  {
                    label: "Name",
                    value: (
                      <EditableText
                        defaultValue={capitalEquipment.name}
                        onSubmit={(value) => handleUpdate("name", value)}
                        isLoading={loadingField === "name" && isLoadingUpdateTool}
                        persistentEdit={loadingField === "name"}
                      />
                    ),
                  },
                  {
                    label: "Team",
                    value: (
                      <EditableSelect
                        preview={
                          <RecordLink
                            link={`/teams/${capitalEquipment.team.id}/budget`}
                            identifier={capitalEquipment.team.name}
                          />
                        }
                        options={teamOptions}
                        selectedValue={capitalEquipment.team.id}
                        onSubmit={(value) => handleUpdate("team_id", value)}
                      />
                    ),
                  },
                  {
                    label: "Wafer Services Tool ID",
                    value: (
                      <EditableText
                        defaultValue={capitalEquipment.wafer_services_tool_id || undefined}
                        onSubmit={(value) => handleUpdate("wafer_services_tool_id", value)}
                        isLoading={loadingField === "wafer_services_tool_id" && isLoadingUpdateTool}
                        persistentEdit={loadingField === "wafer_services_tool_id"}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          }
          main={
            <SpendingCard
              budgetId={capitalEquipment.budget_id}
              editable={true}
              location="capitalEquipment"
            />
          }
        />
      </Box>
    </Flex>
  );
};
