import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";
import { App } from "./App";
import { ChatTranscript } from "./Chat/transcript";
import { HR } from "./HR";
import { EmployeeDetail } from "./HR/EmployeeDetail";
import { SurveyResults } from "./HR/SurveyResults";
import { Home } from "./Home";
import { Progress } from "./Progress";
import { Milestone } from "./Progress/milestone";
import "@sciencecorp/helix-components/dist/style.css";
import { Credentials } from "./Credentials";
import { ArchivePage } from "./Credentials/ArchivePage";
import { CredentialPage } from "./Credentials/CredentialPage";
import { TrainingPage } from "./Credentials/Trainings/TrainingPage";
import { TrainingSessionPage } from "./Credentials/Trainings/TrainingSessionPage";
import { UserCredentialPage } from "./Credentials/UserCredentialPage";
import { Finance } from "./Finance";
import { GeneralLedger } from "./Finance/GeneralLedger";
import { QBAuth } from "./Finance/QBAuth";
import { Encryption } from "./HR/Encryption";
import { UploadPayrollPage } from "./HR/Payroll/upload";
import { EmployeeTable } from "./HR/employeeTable";
import { Hiring } from "./Hiring";
import { CandidateFeedbackForm } from "./Hiring/CandidateFeedbackForm";
import { CandidateFeedbackFormSuccess } from "./Hiring/CandidateFeedbackSuccess";
import { CandidatePage } from "./Hiring/CandidatePage";
import { CandidateReferencesForm } from "./Hiring/CandidateReferencesPage";
import { News } from "./News";
import { EditNewsPost } from "./News/EditNewsPost";
import { NewPost } from "./News/NewPost";
import { NewsPage } from "./News/NewsPage";
import { Notifications } from "./Notifications";
import { OmniSearch } from "./OminiSearch";
import { PlanningPage } from "./Projects";
import { PurchasePage } from "./Purchasing/Purchase";
import { Vendor } from "./Purchasing/Vendor";
import { VendorArchive } from "./Purchasing/Vendor/VendorArchive";
import { Requests } from "./Requests";
import { ServiceRequestDetails } from "./Requests/ServiceRequestDetails";
import { Reviews } from "./Reviews";
import { Sites } from "./Sites";
import { Teams } from "./Teams";
import { Team } from "./Teams/Team";
import { ContractPage } from "./Purchasing/Contracts";
import { SubscriptionPage } from "./Purchasing/Subscriptions";
import { Archetype } from "./Hiring/Archetypes";
import { Inventory } from "./Inventory";
import { ReimbursementDetailsPage } from "./Purchasing/Reimbursements/components/ReimbursementDetailsPage";
import { InventoryPage } from "./Inventory/Inventories/Inventory";
import { ArchivedInventoryPage } from "./Inventory/Inventories/ArchivedInventoryPage";
import { UserProfile } from "./Users";
import { InventoryItemPage } from "./InventoryItems/inventoryItem";
import { CapitalEquipments } from "./CapitalEquipments";
import { CapitalEquipmentDetails } from "./CapitalEquipments/CapitalEquipmentDetails";
import { ProjectsPage } from "./Projects/components";
import { VacancyPage } from "./Hiring/VacancyPage";
import { InventoryCheckout } from "./Inventory/Checkout";
import { ListMeetingNotes } from "./MeetingNotes/ListMeetingNotes";
import { MeetingNotesPage } from "./MeetingNotes";
import { BudgetGroup } from "./Finance/Budgets/BudgetGroup";
import { BudgetItemPage } from "./Budgets/BudgetItemPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const chakraTheme = extendTheme({
  config: {
    useSystemColorMode: true,
    initialColorMode: "system",
  },
});

const RedirectWithParams = ({ to }) => {
  const params = useParams();
  const newPath = Object.keys(params).reduce(
    (path, param) => path.replace(`:${param}`, params[param]),
    to
  );

  return <Navigate to={newPath} replace />;
};

const Main = () => (
  <QueryClientProvider client={queryClient}>
    <ColorModeScript />
    <ChakraProvider
      theme={chakraTheme}
      toastOptions={{ defaultOptions: { position: "top", status: "success", duration: 3000 } }}>
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chat/threads/:id" element={<ChatTranscript />} />
            <Route path="/credentials/archive/:tab" element={<ArchivePage />} />
            <Route path="/credentials/:tab" element={<Credentials />} />
            <Route path="/credentials/all/:id" element={<CredentialPage />} />
            <Route path="/credentials/me/:id" element={<UserCredentialPage />} />
            <Route path="/finance/:tab?" element={<Finance />} />
            <Route path="/finance/ledger" element={<GeneralLedger />} />
            <Route path="/finance/budgets/budget_group/:id" element={<BudgetGroup />} />
            <Route path="/hiring/:tab" element={<Hiring />} />
            <Route path="/hiring/roles/:id" element={<Archetype />} />
            <Route path="/hiring/completed_interviews/:id" element={<CandidatePage />} />
            <Route
              path="/hiring/completed_interviews/:id/references"
              element={<CandidateReferencesForm />}
            />
            <Route path="/hiring/feedback/:id" element={<CandidateFeedbackForm />} />
            <Route path="/hiring/feedback/success" element={<CandidateFeedbackFormSuccess />} />
            <Route path="/hiring/vacancies/:id" element={<VacancyPage />} />
            <Route path="/hr" element={<HR />} />
            <Route path="/hr/encryption" element={<Encryption />} />
            <Route path="/hr/employees" element={<EmployeeTable />} />
            <Route path="/hr/employees/:id" element={<EmployeeDetail />} />
            <Route path="hr/payroll/upload" element={<UploadPayrollPage />} />
            <Route path="/hr/sites" element={<Sites />} />
            <Route path="hr/survey_results" element={<SurveyResults />} />
            <Route path="/milestones/:id" element={<Milestone />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsPage />} />
            <Route path="/news/create" element={<NewPost />} />
            <Route path="/news/:id/edit" element={<EditNewsPost />} />
            <Route path="/inventory/:tab?" element={<Inventory />} />
            <Route path="/inventory/items/checkout" element={<InventoryCheckout />} />
            <Route
              path="/inventory/locations/checkout"
              element={<RedirectWithParams to="/inventory/items/checkout" />}
            />
            <Route path="/inventory/items/:id" element={<InventoryPage />} />
            <Route
              path="/inventory/items/:id/inventory_items/:inventoryItemId"
              element={<InventoryItemPage />}
            />
            <Route path="/inventory/archived" element={<ArchivedInventoryPage />} />
            <Route path="/inbox/:tab" element={<Notifications />} />
            <Route path="/omnisearch" element={<OmniSearch />} />
            <Route path="/planning" element={<PlanningPage />} />

            <Route path="/planning/projects" element={<ProjectsPage />} />
            <Route path="/planning/projects/:id" element={<ProjectsPage />} />
            <Route path="/planning/projects/:id/:tab" element={<ProjectsPage />} />
            <Route path="/progress" element={<Progress />} />
            <Route path="/purchasing/:tab" element={<RedirectWithParams to="/services/:tab" />} />
            <Route path="/purchasing/" element={<RedirectWithParams to="/services/purchasing" />} />
            <Route
              path="/purchasing/purchases/:id"
              element={<RedirectWithParams to="/services/purchasing" />}
            />
            <Route
              path="/purchasing/purchases/"
              element={<RedirectWithParams to="/services/purchasing" />}
            />
            <Route path="/services/purchasing/:id" element={<PurchasePage />} />
            <Route path="/services/vendors/:id" element={<Vendor />} />
            <Route path="/services/contracts/:id" element={<ContractPage />} />
            <Route path="/services/subscriptions/:id" element={<SubscriptionPage />} />
            <Route path="/services/vendors/archive" element={<VendorArchive />} />
            <Route path="/services/reimbursements/:id" element={<ReimbursementDetailsPage />} />
            <Route path="/qb_auth" element={<QBAuth />} />
            <Route path="/services/:tab" element={<Requests />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/services/requests/:id" element={<ServiceRequestDetails />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/:id/:tab?" element={<Team />} />
            <Route path="/equipment" element={<CapitalEquipments />} />
            <Route path="equipment/:id" element={<CapitalEquipmentDetails />} />
            <Route
              path="/trainings/:id/training_sessions/:sessionId"
              element={<TrainingSessionPage />}
            />
            <Route path="/trainings/:id" element={<TrainingPage />} />
            <Route path="/users/:id/:tab?" element={<UserProfile />} />
            <Route path="/qb_auth" element={<QBAuth />} />
            <Route path="/meeting_notes" element={<ListMeetingNotes />} />
            <Route path="/meeting_notes/:id" element={<MeetingNotesPage />} />
            <Route path="/budgets/:budgetId/budget_item/:id" element={<BudgetItemPage />} />
          </Routes>
        </App>
      </BrowserRouter>
    </ChakraProvider>
  </QueryClientProvider>
);

export default Main;
