import React from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Flex,
  Avatar,
  VStack,
  Tooltip,
  useColorModeValue,
  Text,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { BudgetIndexItemData } from "../../../../api/budget";
import { Link } from "react-router-dom";
interface UnassignedBudgetsCardProps {
  budget: BudgetIndexItemData;
  selectedBudget: boolean;
}

export const UnassignedBudgetsCard: React.FC<UnassignedBudgetsCardProps> = ({
  budget,
  selectedBudget,
}) => {
  const tealColor = useColorModeValue("teal.50", "teal.700");
  const grayColor = useColorModeValue("gray.50", "auto");
  const backgroundGray = useColorModeValue("gray.100", "gray.700");
  if (budget.budgetable.type === "User") {
    return (
      <VStack
        alignItems="center"
        border="1px"
        borderColor={selectedBudget ? "teal.400" : "chakra-border-color"}
        borderRadius="md"
        p={3}
        height={"100%"}
        _hover={{ cursor: "pointer" }}
        bgColor={selectedBudget ? tealColor : grayColor}>
        <Checkbox boxSize={"8"} colorScheme="teal" alignSelf={"start"} isChecked={selectedBudget} />
        <Flex
          as={Link}
          to={budget.budgetable.app_href}
          justifyContent={"space-between"}
          alignItems={"center"}
          bgColor={backgroundGray}
          borderColor={"chakra-border-color"}
          borderRadius={"md"}
          width={"100%"}
          p={"2"}>
          <HStack width={"100%"}>
            <Avatar
              size="xs"
              name={budget?.budgetable.name}
              src={budget?.budgetable.picture_uri || ""}
            />
            <Text fontWeight={"semibold"}>{budget.budgetable.name}</Text>
          </HStack>
          <ArrowForwardIcon color={"gray.500"} />
        </Flex>

        {budget.team_name && (
          <HStack justifyContent={"space-between"} width={"100%"} p={"2"}>
            <Text>Team:</Text>
            <Tooltip label={budget.team_name} aria-label={budget.team_name}>
              <Text fontWeight={"semibold"} noOfLines={1}>
                {budget.team_name}
              </Text>
            </Tooltip>
          </HStack>
        )}
      </VStack>
    );
  } else {
    return (
      <VStack
        alignItems="center"
        border="1px"
        borderColor={selectedBudget ? "teal.400" : "chakra-border-color"}
        borderRadius="md"
        p={3}
        height={"100%"}
        _hover={{ cursor: "pointer" }}
        bgColor={selectedBudget ? tealColor : grayColor}>
        <Checkbox boxSize={"8"} colorScheme="teal" alignSelf={"start"} isChecked={selectedBudget} />

        <Flex
          as={Link}
          to={budget.budgetable.app_href}
          justifyContent={"space-between"}
          alignItems={"center"}
          bgColor={backgroundGray}
          borderColor={"chakra-border-color"}
          borderRadius={"md"}
          width={"100%"}
          p={"2"}>
          <Tooltip label={budget.budgetable.name} aria-label="A tooltip">
            <Text fontWeight="semibold" noOfLines={1}>
              {budget.budgetable.name}
            </Text>
          </Tooltip>
          <ArrowForwardIcon color={"gray.500"} />
        </Flex>
        {budget.budgetable.type !== "CapitalEquipment" ? (
          <HStack justifyContent={"space-between"} width={"100%"} p={"2"}>
            <Text>Lead:</Text>
            {budget.lead ? (
              <Tooltip label={budget.lead.name} aria-label={budget.lead.name}>
                <Avatar size="sm" name={budget.lead.name} src={budget.lead.picture_uri} />
              </Tooltip>
            ) : (
              <Text>No lead</Text>
            )}
          </HStack>
        ) : (
          <HStack justifyContent={"space-between"} width={"100%"} p={"2"}>
            <Text>Team:</Text>
            <Text>{budget.team_name}</Text>
          </HStack>
        )}
      </VStack>
    );
  }
};
