import {
  Avatar,
  Box,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { humanize, titleize, underscore } from "inflection";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Changes,
  TemplateValue,
  TimelineEventData,
  isTemplateElement,
} from "../../api/timeline_events";
import { UserMinimalData } from "../../api/user";
import { Money } from "../../helpers/Money";
import { MoneyText } from "../MoneyText";
import { TimelineCommentEvent } from "./TimelineCommentEvents";

const statusTagColor = (status: string) => {
  switch (status) {
    case "new":
      return "purple";
    case "declined":
    case "needs_approval":
      return "red";
    case "approved":
      return "blue";
    case "awaiting_purchase":
      return "orange";
    case "awaiting_payment":
      return "blue";
    case "awaiting_delivery":
      return "teal";
    case "completed":
    case "delivered":
      return "green";
    default:
      "gray";
  }
};

function getValueAtPath<T>(value: any, path: string[]): T | undefined {
  if (!path.length) {
    return value;
  } else if (path[0].startsWith("#")) {
    const index = parseInt(path[0].slice(1));
    return getValueAtPath(value[index], path.slice(1));
  } else {
    return getValueAtPath(value[path[0]], path.slice(1));
  }
}

const coerceDate = (value: any): DateTime => {
  if (value instanceof DateTime) {
    return value;
  } else if (typeof value === "number" || !value.includes("-")) {
    throw new Error("Invalid date value:", value);
  } else if (DateTime.fromISO(value).isValid) {
    return DateTime.fromISO(value);
  } else {
    throw new Error("Invalid date value:", value);
  }
};

const coerceMoney = (value: any): Money => {
  if (_.has(value, "cents") && (_.has(value, "currency_iso") || _.has(value, "currency"))) {
    return Money.fromMinorUnits(value.cents, value.currency || value.currency_iso || "USD");
  } else {
    throw new Error("Invalid money value:", value);
  }
};

const coerce = (value: any, type: "string" | "date" | "money"): string => {
  try {
    if (type === "date") {
      return coerceDate(value).toISO()!;
    } else if (type === "money") {
      return coerceMoney(value).format();
    } else {
      return humanize(value.toString());
    }
  } catch (e) {
    console.error("Error coercing value", value, "to type", type, e);
    return "ERROR";
  }
};

const displayChanges = (oldValue, newValue, type: "string" | "date" | "money") => {
  if (_.isEmpty(oldValue) && !_.isNil(newValue)) {
    return (
      <Text as={"span"}>
        was set to{" "}
        <Text as={"span"} fontWeight={"semibold"}>
          {coerce(newValue, type)}
        </Text>
      </Text>
    );
  } else if (!_.isEmpty(oldValue) && _.isEmpty(newValue)) {
    return <Text as={"span"}>was unset</Text>;
  } else if (typeof oldValue === "object" || typeof newValue === "object") {
    return "was changed";
  } else
    return (
      <>
        was changed from{" "}
        <Text as={"span"} fontWeight={"semibold"}>
          {coerce(oldValue, type)}
        </Text>{" "}
        to{" "}
        <Text as={"span"} fontWeight={"semibold"}>
          {" "}
          {coerce(newValue, type)}
        </Text>
      </>
    );
};

type MoneyRaw = { cents: string; currency_iso: string; currency?: never };
type MoneyClean = { cents: string; currency_iso?: never; currency: string };
type MoneyValue = MoneyRaw | MoneyClean;

export const RenderTemplateValue = ({
  event,
  avatarSize = "sm",
  templateValue,
  timelineableType,
  timelineableId,
  shouldCapitalize,
}: {
  event: TimelineEventData;
  templateValue: TemplateValue;
  timelineableId?: number;
  avatarSize?: "xs" | "sm" | "md" | "lg";
  timelineableType?: string;
  shouldCapitalize: boolean;
}): JSX.Element => {
  try {
    if (!isTemplateElement(templateValue)) {
      return (
        <Text as={"span"} fontSize="sm">
          {" "}
          {templateValue.value}{" "}
        </Text>
      );
    } else if (templateValue.elementType === "UserLink") {
      const user = getValueAtPath<UserMinimalData>(event, templateValue.valuePath!);
      return (
        <Link as={RouterLink} to={`/users/${user?.id}`}>
          <Avatar src={user?.picture_uri} size={avatarSize} />
          <Text as={"span"} fontWeight={"semibold"} fontSize="sm">
            {" "}
            {user?.name}{" "}
          </Text>
        </Link>
      );
    } else if (templateValue.elementType === "Text") {
      return (
        <Text
          as={"span"}
          fontWeight={templateValue.options?.fontWeight}
          fontSize="sm"
          textTransform={templateValue.options?.textTransform}>
          {humanize(
            getValueAtPath<string>(event, templateValue.valuePath!) || "",
            !shouldCapitalize
          )}
        </Text>
      );
    } else if (templateValue.elementType === "Tag") {
      const value = getValueAtPath<string>(event, templateValue.valuePath!) || "";
      return (
        <Tag colorScheme={statusTagColor(value)} size="sm">
          {humanize(value, !shouldCapitalize)}
        </Tag>
      );
    } else if (templateValue.elementType === "Ref") {
      const refType = templateValue.options?.refType;
      const refId = Number(getValueAtPath<string>(event, templateValue.valuePath!) || -1);
      const humanFriendlyType = refType && titleize(underscore(refType));
      if (refType === timelineableType && refId === timelineableId) {
        return (
          <>
            <Text as={"span"} fontSize="sm">
              {shouldCapitalize ? "This" : "this"}{" "}
            </Text>
            <Text as={"span"} fontSize="sm">
              {humanFriendlyType}
            </Text>
          </>
        );
      } else {
        return (
          <Text as={"span"} fontWeight={templateValue.options?.fontWeight || "bold"} fontSize="sm">
            {humanFriendlyType} #{refId}
          </Text>
        );
      }
    } else if (templateValue.elementType === "Self") {
      const refType = event.timelineable_type;
      const refId = +event.timelineable_id;
      const humanFriendlyType = refType && titleize(underscore(refType));
      if (refType === timelineableType && refId === timelineableId) {
        return (
          <>
            <Text as={"span"} fontSize="sm">
              {shouldCapitalize ? "This" : "this"}{" "}
            </Text>
            <Text as={"span"} fontSize="sm">
              {humanFriendlyType}
            </Text>
          </>
        );
      } else {
        return (
          <Text as={"span"} fontWeight={templateValue.options?.fontWeight || "bold"} fontSize="sm">
            {humanFriendlyType} #{refId}
          </Text>
        );
      }
    } else if (templateValue.elementType === "Money") {
      const amount = getValueAtPath<MoneyValue>(event, templateValue.valuePath!);
      return (
        <MoneyText
          as={"span"}
          fontWeight={templateValue.options?.fontWeight || "bold"}
          fontSize="sm"
          money={coerceMoney(amount) || undefined}
          formatOptions={{ compact: "never" }}
        />
      );
    } else if (templateValue.elementType === "Timestamp") {
      const time = DateTime.fromISO(getValueAtPath<string>(event, templateValue.valuePath!)!);
      return (
        <Tooltip label={time.toISO()}>
          <Text as={"span"} fontWeight={"semibold"} fontSize="sm">
            {time.toFormat("LLL dd yyyy")}
          </Text>
        </Tooltip>
      );
    } else if (templateValue.elementType === "ChangeSummary") {
      const changes = getValueAtPath<Changes>(event, templateValue.valuePath!)!;
      const label = templateValue.options?.label ?? "changed ";
      return (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text as={"span"} fontWeight={"semibold"} fontSize="sm">
              {label}
            </Text>
          </PopoverTrigger>
          <PopoverContent width={"fit-content"}>
            <PopoverBody width={"fit-content"}>
              <Box width={"fit-content"}>
                {Object.entries(changes).map(([key, value]) => {
                  return (
                    <Text width={"fit-content"} fontSize="sm">
                      Field:{" "}
                      <Text as={"span"} fontWeight={"semibold"} fontSize="sm">
                        {key}
                      </Text>{" "}
                      {displayChanges(
                        value[0],
                        value[1],
                        templateValue.options?.fieldTypes?.[key] || "string"
                      )}
                    </Text>
                  );
                })}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    }
  } catch (e) {
    console.error("Error rendering template value", { error: e, templateValue, event });
    return (
      <Text as={"span"} backgroundColor={"red.400"} color={"black"}>
        Template Error
      </Text>
    );
  }
  return <></>;
};

export type TimelineEventTextProps = {
  event: TimelineEventData;
  avatarSize?: "xs" | "sm" | "md" | "lg";
  timelineableId?: number;
  timelineableType?: string;
  onComment?: (action: "created" | "updated" | "deleted") => void;
  templateType?: "timeline" | "notification" | "call_to_action";
};

export const TimelineEventText = ({
  event,
  timelineableId,
  timelineableType,
  avatarSize = "sm",
  onComment,
  templateType = "timeline",
}: TimelineEventTextProps) => {
  let template = event.timeline_template;
  const bgColor = useColorModeValue("gray.50", "gray.400");

  if (templateType === "notification") {
    template = event.notification_template;
  } else if (templateType === "call_to_action") {
    template = event.call_to_action_template;
  }
  if (!template) {
    return <></>;
  }
  if (template[template.length - 1].elementType === "Memo") {
    const content = getValueAtPath<string>(event, _.last(template)?.valuePath!);
    let templates = template.slice(0, template.length - 1);
    return (
      <Box width="100%" maxW="3xl">
        <Text
          alignItems={"center"}
          bg={content?.length ? bgColor : ""}
          p={2}
          borderTopRadius="lg"
          borderBottomRadius={content?.length ? "none" : "lg"}
          border="1px"
          justifyContent={"space-between"}
          borderColor="chakra-border-color"
          borderBottom={content?.length ? "0px" : ""}>
          {templates.map((templateValue, idx) => (
            <RenderTemplateValue
              key={`${event.id}-template-${idx}`}
              shouldCapitalize={false}
              event={event}
              avatarSize={"xs"}
              templateValue={templateValue}
              timelineableId={timelineableId}
              timelineableType={timelineableType}
            />
          ))}
        </Text>
        {content?.length ? (
          <Flex
            p={2}
            borderBottomRadius="lg"
            border="1px"
            justifyContent="space-between"
            borderColor="chakra-border-color">
            <Text w="100%" px={2} fontSize="md">
              {content}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
      </Box>
    );
  }

  return (
    <Text as="span" gap={0.5} w="100%" fontSize="sm">
      {event.event_type === "comment" ? (
        <TimelineCommentEvent
          event={event}
          timelineableId={timelineableId}
          timelineableType={timelineableType}
          onComment={onComment}
        />
      ) : (
        template?.map((templateValue, idx) => (
          <Text as="span" key={`${event.id}-template-${idx}`}>
            <RenderTemplateValue
              shouldCapitalize={idx === 0}
              event={event}
              avatarSize={avatarSize}
              templateValue={templateValue}
              timelineableId={timelineableId}
              timelineableType={timelineableType}
            />
          </Text>
        ))
      )}
    </Text>
  );
};
