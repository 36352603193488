import { z } from "zod";
import { api } from ".";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { invalidateTraining } from "./trainings";

export const TRAINING_MATERIALS_BASE_URL = "training_materials";

export const trainingMaterialSchema = z.object({
  id: z.number(),
  training_id: z.number(),
  title: z.string(),
  link: z.string(),
});

export type TrainingMaterialData = z.infer<typeof trainingMaterialSchema>;
export type TrainingMaterialCreateParams = Pick<
  TrainingMaterialData,
  "training_id" | "title" | "link"
>;

// api queries

export const createTrainingMaterial = async (training_material: TrainingMaterialCreateParams) => {
  const result = await api.post(TRAINING_MATERIALS_BASE_URL, { training_material });
  return result.data;
};

export const updateTrainingMaterial = async (training_material: Partial<TrainingMaterialData>) => {
  const result = await api.put(`${TRAINING_MATERIALS_BASE_URL}/${training_material.id}`, {
    training_material,
  });
  return result.data;
};

export const deleteTrainingMaterial = async (id: number) => {
  const result = await api.delete(`${TRAINING_MATERIALS_BASE_URL}/${id}`);
  return result.data;
};

// react-query hooks

export const invalidateTrainingMaterial = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [TRAINING_MATERIALS_BASE_URL, id] : [TRAINING_MATERIALS_BASE_URL],
    });
};

export const useCreateTrainingMaterial = (trainingId: number) => {
  return useMutation({
    mutationFn: createTrainingMaterial,
    onSuccess: invalidateTraining(trainingId),
  });
};

export const useUpdateTrainingMaterial = (trainingId: number) => {
  return useMutation({
    mutationFn: updateTrainingMaterial,
    onSuccess: invalidateTraining(trainingId),
  });
};

export const useDeleteTrainingMaterial = (trainingId: number) => {
  return useMutation({
    mutationFn: deleteTrainingMaterial,
    onSuccess: invalidateTraining(trainingId),
  });
};
