import React, { useMemo } from "react";
import { useSearchInventoryItems } from "../../../api/inventory_item";
import { UserShowData } from "../../../api/user";
import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { RecordLink, useCollection, Collection, EmptyState } from "@sciencecorp/helix-components";
import { InventoryItemShowData } from "../../../api/inventory_item";
import { SpendingAuthorityPreview } from "../../Purchasing/SpendingAuthoritySelectTree";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { DateTime } from "luxon";

export const UserInventory = ({ user }: { user: UserShowData }) => {
  const { order, onOrder, pagination, onPagination } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();

  const { data, isLoading } = useSearchInventoryItems({
    term: search || "*",
    pagination,
    order,
    filters: { user_id: user.id },
  });
  const columns = useMemo(
    () => [
      {
        label: "Inventory Item",
        render: (item: InventoryItemShowData) => (
          <RecordLink
            identifier={item.inventory.name}
            link={`/inventory/items/${item.inventory.id}`}
          />
        ),
      },
      {
        label: "Spending Authority",
        render: (item: InventoryItemShowData) =>
          item.spending_authority ? (
            <SpendingAuthorityPreview
              spendingAuthorityId={item.spending_authority.id}
              spendingAuthorityType={item.spending_authority.type}
            />
          ) : (
            <Text>No Spending Authority</Text>
          ),
      },
      {
        label: "Unique Identifier",
        render: (item: InventoryItemShowData) => item.unique_id || "N/A",
      },
      {
        label: "Assignment Date",
        orderOptions: { orderKey: "assignment_date" },
        render: (inventoryItem: InventoryItemShowData) => {
          return inventoryItem.assignment_date ? (
            <Text>
              {DateTime.fromISO(inventoryItem.assignment_date.toString()).toFormat("LLL dd yyyy")}
            </Text>
          ) : (
            "N/A"
          );
        },
      },
    ],
    []
  );
  return (
    <Flex
      direction="column"
      gap={4}
      w={"100%"}
      border="1px"
      borderColor={"chakra-border-color"}
      p={6}
      borderRadius="md">
      <Heading size="md">Checked Out Inventory</Heading>
      <Divider />
      {data?.results.length ? (
        <Collection
          items={data?.results || []}
          isLoading={isLoading}
          columns={columns}
          pagination={data?.pagination || pagination}
          onPagination={onPagination}
          onOrder={onOrder}
          order={order}
        />
      ) : (
        <EmptyState title="No checked out Inventory Items" size="xs" />
      )}
    </Flex>
  );
};
