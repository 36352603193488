import React from "react";
import { useForm, Controller } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  useColorModeValue,
  Card,
  CardBody,
  Flex,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { useNavigate } from "react-router-dom";
import { useNewTeam, useNewTeamMembership, TeamData } from "../../api/team";
import { useTeamOptions, useTeamTypeOptions, useUserOptions } from "../../api/options";
import { TeamCreateParams } from "../../api/team";
import InputFormControl from "../shared/form/InputFormControl";
import SelectFormControl from "../shared/form/SelectFormControl";
import { required } from "../../helpers/validation";
import * as Errors from "../../helpers/errors";

type FormData = TeamCreateParams;

export const NewTeamModal: React.FC<{ parentTeam?: TeamData; location: string }> = ({
  parentTeam,
  location,
}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const teamTypeOptions = useTeamTypeOptions();
  const userOptions = useUserOptions();
  const teamOptions = useTeamOptions();
  const { mutate: newTeam, isLoading: isLoadingNewTeam, isError: isErrorNewTeam } = useNewTeam();

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<FormData>({
    mode: "all",
    defaultValues: { team_type: parentTeam?.team_type || null },
  });

  /** helpers */
  const onSubmit = handleSubmit(({ name, team_type }) => {
    newTeam(
      {
        name,
        team_type,
        parent_team_id: parentTeam?.id || null,
      },
      {
        onSuccess: (team_id: number) => {
          navigate(`/teams/${team_id}/readme`);
        },
      }
    );
  });
  const validateName = (name: string) => {
    const isTaken = teamOptions.some(
      ({ label }) => label.toLowerCase() === name.toLowerCase().trim()
    );
    return isTaken ? "Name has already been taken" : undefined;
  };
  const onCloseModal = () => {
    reset();
    onClose();
  };
  /** helpers */

  return (
    <>
      {location === "teams" ? (
        <Card
          as={Button}
          _hover={{ background: "green.500", color: "white" }}
          w="100%"
          h="100%"
          textAlign="start"
          onClick={onOpen}>
          <CardBody w="100%">
            <HStack spacing={4} h="100%">
              <AddIcon />
              <Text>Add Team</Text>
            </HStack>
          </CardBody>
        </Card>
      ) : (
        <Button
          textAlign="start"
          onClick={onOpen}
          variant="link"
          color="ButtonText"
          sx={{
            "&>div": {
              transition: "background-color, color",
              transitionDuration: "fast",
            },
          }}
          _hover={{
            "&>div": {
              backgroundColor: "green.500",
            },
            "& svg path": {
              fill: "white",
            },
          }}>
          <Flex
            boxSize={10}
            alignItems="center"
            justifyContent="center"
            background={useColorModeValue("blackAlpha.100", "gray.400")}
            marginInlineEnd={4}
            borderRadius={"full"}>
            <AddIcon color={useColorModeValue("blackAlpha.500", "gray.100")} />
          </Flex>
          Add Team
        </Button>
      )}

      <FormModal
        size="xl"
        submitButtonColorSchema="teal"
        title="New Team"
        isOpen={isOpen}
        onClose={onCloseModal}
        submitButtonTitle="Create"
        isLoading={isLoadingNewTeam}
        handleSubmit={onSubmit}
        errorMessage={isErrorNewTeam ? Errors.generic : undefined}
        isDirty={isDirty}>
        <Controller
          name="name"
          control={control}
          rules={{ required, validate: !parentTeam?.id ? validateName : undefined }}
          render={({ field }) => (
            <InputFormControl
              label="Team Name"
              placeholder="Team name"
              error={typeof errors?.name?.message === "string" ? errors?.name?.message : undefined}
              {...field}
            />
          )}
        />
        <Controller
          name="team_type"
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <SelectFormControl
              label="Team Type"
              options={teamTypeOptions}
              error={
                typeof errors?.team_type?.message === "string"
                  ? errors?.team_type?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </FormModal>
    </>
  );
};
