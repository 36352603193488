import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  VStack,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { useUpdateCandidate, useGetCandidateReferences } from "../../api/candidates";
import { Select } from "chakra-react-select";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useParams } from "react-router";
import { CandidateReferenceCard } from "./components/CandidateReferenceCard";
import { MdApproval } from "react-icons/md";

const managerOptions: { label: string; value: string }[] = [
  { label: "Hire Candidate", value: "manager_approved" },
  { label: "Reject Candidate", value: "manager_rejected" },
];

export const CandidateReferencesForm = () => {
  const { id } = useParams();
  if (!id) return null;
  const { data } = useGetCandidateReferences(Number(id));
  const { mutate: updateCandidate } = useUpdateCandidate();
  const [nextStep, setNextStep] = useState<{ label: string; value: string } | null>(null);

  return (
    <Box bg={useColorModeValue("gray.50", "gray.800")} mx={-4} px={4} pb={6} mb={-6}>
      <Heading size="lg" pt={4} mb={8}>
        References for {data?.candidate_name}
      </Heading>
      <VStack width="100%" align="center">
        <VStack
          minW="xl"
          bg={useColorModeValue("white", "gray.700")}
          borderRadius="md"
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          p={8}
          spacing={6}
          align="start">
          <Heading size="sm">References</Heading>
          {data?.results.map((reference) => (
            <CandidateReferenceCard
              key={reference.id}
              reference={reference}
              location="hiringManager"
            />
          ))}
        </VStack>
      </VStack>
      <HStack position="fixed" bottom="30px" justify="center" width="100%">
        {data?.candidate_decision === "references" ? (
          <HStack
            bg={useColorModeValue("teal.50", "teal.700")}
            p={4}
            justify="space-between"
            borderRadius="md"
            boxShadow="lg"
            minWidth={{ md: "xl", lg: "2xl" }}>
            <HStack>
              <Icon as={MdApproval} color={useColorModeValue("teal.500", "teal.400")} boxSize={7} />
              <Heading size="sm">Manager's Decision</Heading>
            </HStack>
            <HStack>
              <Box
                width={{ base: "150x", md: "200px" }}
                bg={useColorModeValue("white", "gray.700")}>
                <Select
                  useBasicStyles
                  size={{ base: "sm", md: "md" }}
                  options={managerOptions}
                  value={nextStep}
                  menuPlacement="top"
                  onChange={(value) => {
                    if (value) setNextStep(value);
                  }}
                />
              </Box>
              <Button
                size={{ base: "sm", md: "md" }}
                colorScheme="teal"
                onClick={() =>
                  nextStep && updateCandidate({ id: Number(id), decision: nextStep.value })
                }>
                Continue
              </Button>
            </HStack>
          </HStack>
        ) : data?.candidate_decision === "manager_approved" ? (
          <HStack
            bg={useColorModeValue("green.50", "green.600")}
            p={4}
            spacing={4}
            border="1px"
            borderRadius="md"
            borderColor={useColorModeValue("gray.200", "gray.600")}>
            <Icon as={MdApproval} color={useColorModeValue("green.500", "green.300")} boxSize={7} />
            <Flex width="100%" align="start" direction="column">
              <Heading size="sm">Hire Requested</Heading>
              <Text fontSize="sm">You requested to hire the candidate.</Text>
            </Flex>
          </HStack>
        ) : data?.candidate_decision === "manager_rejected" ? (
          <HStack
            bg={useColorModeValue("red.50", "red.600")}
            p={4}
            spacing={4}
            border="1px"
            borderRadius="md"
            borderColor={useColorModeValue("gray.200", "gray.600")}>
            <InfoOutlineIcon color={useColorModeValue("red.500", "red.200")} boxSize={7} />
            <Flex width="100%" align="start" direction="column">
              <Heading size="sm">Rejected</Heading>
              <Text>You rejected the candidate.</Text>
            </Flex>
          </HStack>
        ) : null}
      </HStack>
    </Box>
  );
};
