import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateArc } from "../../../api/planning/arcs";
import { FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";

interface ArcModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ArcFormValues {
  title: string;
}

export const ArcModal: React.FC<ArcModalProps> = ({ isOpen, onClose }) => {
  const { mutateAsync: createArc } = useCreateArc();
  const { control, handleSubmit, reset, watch } = useForm<ArcFormValues>();
  const defaultFormValues = {
    title: "",
  };
  const onSubmit = async (values: ArcFormValues) => {
    await createArc(values)
      .then(() => {
        toast({ title: "Success!", description: "You added a group.", status: "success" });
      })
      .catch((e) => {
        console.error(e);
      });
    onClose();
  };

  const formValues = watch();

  const handleClose = () => {
    reset(defaultFormValues);
    onClose();
  };

  const toast = useToast();
  return (
    <FormModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Add Group"
      submitButtonTitle="Add Group"
      submitButtonColorSchema="teal"
      submitButtonDisabled={formValues.title === ""}
      handleSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="title"
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <FormControl>
              <FormLabel>Group Name</FormLabel>
              <Input {...field} placeholder="e.g Test New Opsins" />
            </FormControl>
          );
        }}
      />
    </FormModal>
  );
};
