import React, { useMemo, useState } from "react";
import {
  HStack,
  Icon,
  Button,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  InputProps,
  PopoverContentProps,
  useColorModeValue,
  useDisclosure,
  Tooltip,
  Link,
  Box,
  BoxProps,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { LinkIcon, SmallCloseIcon } from "@chakra-ui/icons";

type EditableLinkProps = BoxProps & {
  url?: string | null;
  iconSize?: number;
  onChange?: (url: string) => void;
  onSubmit?: (url: string) => void;
  disabled?: boolean;
  inputProps?: Omit<InputProps, "onChange" | "onSubmit">;
  editAreaProps?: Omit<PopoverContentProps, "onChange" | "onSubmit">;
};

export const EditableLink = ({
  url,
  onSubmit,
  disabled,
  inputProps,
  editAreaProps,
  iconSize = 5,
  ...boxProps
}: EditableLinkProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const hasLinkColor = useColorModeValue("teal.500", "teal.500");
  const noLinkColor = useColorModeValue("gray.500", "gray.500");
  const [link, setLink] = useState<string | null>(url || null);
  const hasLink = useMemo(() => link && link.length > 0, [link]);

  const handleSubmit = () => {
    if (link && !(link.startsWith("http://") || link.startsWith("https://"))) {
      setLink("https://" + link);
    }
    onSubmit && link && onSubmit(link);
    onClose();
  };

  if (disabled) {
    return (
      <Tooltip label={link}>
        <Link href={link || undefined} isExternal={true}>
          <Icon as={LinkIcon} color={hasLink ? hasLinkColor : noLinkColor} boxSize={4} />
        </Link>
      </Tooltip>
    );
  }
  return (
    <Box role={"group"} {...boxProps} minWidth="max-content">
      <Popover isOpen={isOpen} onClose={onClose} placement={"bottom-end"}>
        <PopoverTrigger>
          <HStack>
            {hasLink ? (
              <HStack spacing={0}>
                <Tooltip label={link}>
                  <Link href={link || undefined} isExternal={true}>
                    <Icon as={LinkIcon} color={hasLinkColor} boxSize={iconSize} />
                  </Link>
                </Tooltip>
                <Text
                  color={hasLinkColor}
                  _groupHover={{ display: "block" }}
                  display={"none"}
                  onClick={onToggle}
                  paddingLeft={2}
                  textDecor={"underline"}
                  cursor="pointer">
                  Edit
                </Text>
              </HStack>
            ) : (
              <Text color={noLinkColor} onClick={onToggle} cursor={"pointer"}>
                Add Link
              </Text>
            )}
          </HStack>
        </PopoverTrigger>
        <PopoverContent width={"50ch"} {...editAreaProps}>
          <PopoverArrow />
          <PopoverBody>
            <HStack>
              <Input
                size="sm"
                placeholder="Enter Link to Item"
                width={"50ch"}
                value={link || ""}
                {...inputProps}
                type={"url"}
                onChange={(e) => setLink(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && link && link.length != 0) {
                    handleSubmit();
                  }
                }}
              />
              <Button px={6} mx={2} size={"sm"} colorScheme={"teal"} onClick={handleSubmit}>
                Save
              </Button>
              <IconButton
                aria-label="close popover"
                variant="ghost"
                size="sm"
                icon={<SmallCloseIcon />}
                onClick={onClose}
              />
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
