import React, { useState, useMemo } from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  VStack,
  Text,
  Icon,
  Link,
  useColorModeValue,
  HStack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Collection,
  PaginationControls,
  RecordLink,
  ConfirmationModal,
  useCollection,
  Column,
  type Pagination,
} from "@sciencecorp/helix-components";
import { EditIcon, Search2Icon } from "@chakra-ui/icons";
import { TbPresentationAnalytics } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiArchive } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { TrainingData, TrainingIndexData, useUpdateTraining } from "../../../api/trainings";
import { useGetMyTrainingSessions } from "../../../api/training_sessions";
import { Link as RouterLink } from "react-router-dom";
import { NewTrainingModal } from "./components/NewTrainingModal";
import { calendarColors, attendanceHistoryColorScheme } from "../util";
import { DateTime } from "luxon";
import { humanize, titleize } from "inflection";

const backgroundColor = (idx: number) =>
  useColorModeValue(
    calendarColors[idx % calendarColors.length].backgroundLight,
    calendarColors[idx % calendarColors.length].backgroundDark
  );

type TrainingsProps = {
  data: TrainingIndexData;
  isLoading: boolean;
  onPagination: (pagination: Pagination) => void;
  pagination: Pagination;
  isCredentialsAdmin: boolean;
};

export const Trainings = ({
  data,
  isLoading,
  onPagination,
  pagination,
  isCredentialsAdmin,
}: TrainingsProps) => {
  const { onOrder, order } = useCollection();
  const { onPagination: trainingSessionOnPagination, pagination: trainingSessionPagination } =
    useCollection({ pagination: { per_page: 3 } });

  const { data: myTrainingSessions } = useGetMyTrainingSessions(trainingSessionPagination);
  const { mutate: updateTraining } = useUpdateTraining();
  const [activeTraining, setActiveTraining] = useState<TrainingData | null>(null);

  const {
    isOpen: isOpenTraining,
    onOpen: onOpenTraining,
    onClose: onCloseTraining,
  } = useDisclosure();

  const { isOpen: isOpenArchive, onOpen: onOpenArchive, onClose: onCloseArchive } = useDisclosure();

  const columns = useMemo(
    () =>
      [
        {
          label: "Training",
          render: (training: TrainingData) => (
            <RecordLink
              type=""
              icon={<Icon as={TbPresentationAnalytics} ml={2} />}
              identifier={training.name}
              link={`/trainings/${training.id}`}
            />
          ),
        },
        {
          label: "Awarded Credential",
          render: (training: TrainingData) => (
            <HStack>
              <Tag>{training.credential_trainings[0]?.credential.name} </Tag>
              {training.credential_trainings.length > 1 && (
                <Text fontWeight="medium" fontSize="sm">
                  + {training.credential_trainings.length - 1} others
                </Text>
              )}
            </HStack>
          ),
        },
        {
          label: "Status",
          render: (training: TrainingData) => (
            <Tag
              colorScheme={attendanceHistoryColorScheme(
                training.user_training_session_status || ""
              )}>
              {titleize(humanize(training.user_training_session_status || "Not Assigned"))}
            </Tag>
          ),
        },
        {
          label: "Next Session",
          render: (training: TrainingData) =>
            training.next_session
              ? DateTime.fromISO(training.next_session).toFormat("LLL dd yyyy")
              : "N/A",
        },
        isCredentialsAdmin && {
          label: "Completed Sessions",
          render: (training: TrainingData) => training.completed_training_sessions,
        },
        {
          label: "",
          render: (training: TrainingData) =>
            training.has_next_session && (
              <Link as={RouterLink} color="teal.500" to={`/trainings/${training.id}`}>
                Register
              </Link>
            ),
        },
        isCredentialsAdmin && {
          label: "",
          render: (training: TrainingData) => (
            <ActionsMenu
              training={training}
              setActiveTraining={setActiveTraining}
              onOpenTraining={onOpenTraining}
              onOpenArchive={onOpenArchive}
            />
          ),
        },
      ].filter(Boolean) as Column[],
    [data, isCredentialsAdmin]
  );

  return (
    <Box width="100%">
      <VStack width="100%" align="start" spacing={4}>
        {myTrainingSessions?.results?.length ? (
          <VStack
            align="start"
            width="100%"
            p={5}
            border="1px"
            borderColor="chakra-border-color"
            bg={useColorModeValue("gray.50", "gray.700")}
            borderRadius="md">
            <HStack justify="space-between" width="100%">
              <Heading size="sm">You're Attending</Heading>
              <Flex justify="flex-end" textAlign="end">
                <PaginationControls
                  showPerPage={false}
                  showPage={false}
                  pagination={myTrainingSessions?.pagination || trainingSessionPagination}
                  onPagination={trainingSessionOnPagination}
                />
              </Flex>
            </HStack>
            <Stack width="100%" direction={{ base: "column", md: "row" }}>
              {myTrainingSessions?.results?.map((session, idx) => (
                <HStack
                  py={3}
                  px={5}
                  bg={backgroundColor(idx)}
                  borderLeft="4px"
                  width={{ base: "100%", md: "33%" }}
                  borderColor={calendarColors[idx % 3].border}
                  borderLeftRadius="base">
                  <VStack align="start" spacing={0.5} fontWeight="semibold">
                    <Text>{DateTime.fromISO(session.scheduled_date).toFormat("dd")}</Text>
                    <Text>{DateTime.fromISO(session.scheduled_date).toFormat("LLL")}</Text>
                  </VStack>
                  <VStack align="start" spacing={1}>
                    <Text fontSize="sm" fontWeight="semibold">
                      {session.training_name}
                    </Text>
                    <Text fontSize="sm" fontWeight="medium">
                      {DateTime.fromISO(session.scheduled_date).toFormat("hh:mm a")}
                    </Text>
                  </VStack>
                </HStack>
              ))}
            </Stack>
          </VStack>
        ) : null}
        {isCredentialsAdmin && (
          <Stack width="100%" direction={{ base: "column", lg: "row" }}>
            <StatGroup
              py={3}
              px={6}
              flexDir={{ base: "column", md: "row" }}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              flex="3">
              <Stat>
                <StatLabel>No. of Trainings</StatLabel>
                <StatNumber>{data?.training_count}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Completed Sessions</StatLabel>
                <StatNumber>{data?.total_completed_sessions}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Trained Employees</StatLabel>
                <StatNumber>{data?.total_completed_user_training_sessions}</StatNumber>
              </Stat>
            </StatGroup>

            <HStack
              py={3}
              px={6}
              border="1px"
              borderColor="chakra-border-color"
              borderRadius="md"
              spacing={4}
              flex="1"
              justify="space-between"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <Stat>
                <StatLabel>Archived Trainings</StatLabel>
                <StatNumber>{data?.archived_trainings}</StatNumber>
              </Stat>
              <Link
                as={RouterLink}
                fontSize="sm"
                color="teal.500"
                to="/credentials/archive/trainings">
                View
              </Link>
            </HStack>
          </Stack>
        )}

        {data?.results.length ? (
          <Box width="100%">
            <Collection
              columns={columns}
              items={data?.results || []}
              pagination={data?.pagination || pagination}
              isLoading={isLoading}
              order={order}
              onPagination={onPagination}
              onOrder={onOrder}
            />

            {activeTraining && (
              <>
                <NewTrainingModal
                  isOpen={isOpenTraining}
                  onClose={onCloseTraining}
                  training={activeTraining}
                />
                <ConfirmationModal
                  colorScheme="red"
                  isOpen={isOpenArchive}
                  onClose={onCloseArchive}
                  header="Archive Training"
                  children="Archiving the training will remove it from the current list, but you can retrieve it by going to Trainings >> Archive."
                  onClick={() => {
                    updateTraining(
                      { id: activeTraining.id, is_archived: true },
                      { onSuccess: () => onCloseArchive() }
                    );
                  }}
                />
              </>
            )}
          </Box>
        ) : (
          <VStack
            width="100%"
            minHeight="xs"
            justify="center"
            borderRadius="md"
            bg={useColorModeValue("gray.50", "gray.700")}
            opacity="0.7"
            spacing={5}>
            <Search2Icon boxSize={6} />
            <VStack spacing={0}>
              <Text fontWeight="semibold" fontSize="sm">
                It’s empty here.
              </Text>
              <Text fontWeight="medium" fontSize="xs">
                There are no trainings.
              </Text>
            </VStack>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export const ActionsMenu = ({ training, setActiveTraining, onOpenTraining, onOpenArchive }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        <MenuItem
          gap={3}
          onClick={() => {
            setActiveTraining(training);
            onOpenTraining();
          }}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem
          gap={3}
          color="red.500"
          onClick={() => {
            setActiveTraining(training);
            onOpenArchive();
          }}>
          <FiArchive />
          Archive
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
