import React, { useState } from "react";
import {
  HStack,
  Avatar,
  Text,
  IconButton,
  useColorModeValue,
  VStack,
  Heading,
  Input,
  LinkBox,
  LinkOverlay,
  Tag,
  Box,
  Table,
  Tbody,
  Tr,
  Link,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import { Facets } from "@sciencecorp/helix-components";
import { CloseIcon } from "@chakra-ui/icons";
import { TbCopy } from "react-icons/tb";
import { useUsersQuery } from "../../api/user";
import { Link as RouterLink } from "react-router-dom";
import { humanize, titleize } from "inflection";
import { VirtualCardData } from "../../api/purchase";

export const serviceStatusOption = [
  { label: "New", value: "new" },
  { label: "In Progress", value: "in_progress" },
  { label: "Review", value: "review" },
];

export const serviceStatusMap = [
  { label: "new", value: "new", color: "purple" },
  { label: "Needs Approval", value: "needs_approval", color: "red" },
  { label: "Approved", value: "approved", color: "blue" },
  { label: "Declined", value: "declined", color: "red" },
  { label: "In Progress", value: "in_progress", color: "orange" },
  { label: "Review", value: "review", color: "teal" },
  { label: "Complete", value: "complete", color: "green" },
  { label: "Abandoned", value: "abandoned", color: "red" },
];

export const purchaseStatusColor = (status: string) => {
  switch (status) {
    case "draft":
      return "gray";
    case "new":
      return "purple";
    case "needs_approval":
      return "red";
    case "approved":
      return "blue";
    case "declined":
      return "red";
    case "awaiting_purchase":
      return "orange";
    case "awaiting_delivery":
      return "teal";
    case "awaiting_payment":
      return "blue";
    case "complete":
      return "green";
  }
  return "gray";
};

export type UserOptionType = {
  id: number;
  name: string;
  picture_uri: string;
};

type UserServiceRequestPillProps = {
  user: { id: number; user?: UserOptionType; picture_uri?: string; name?: string };
  location?: string;
  handleDelete?: (user) => void;
};

export const UserServiceRequestPill = ({
  user,
  location,
  handleDelete,
}: UserServiceRequestPillProps) => {
  const data = user.user ? user.user : user;
  return (
    <HStack width={handleDelete ? "100%" : ""} justify="space-between">
      {handleDelete ? (
        <Link as={RouterLink} to={`/users/${data.id}`}>
          <HStack>
            <Avatar src={data.picture_uri} size={location ? "sm" : "xs"} name={data.picture_uri} />
            <Text fontWeight="semibold" fontSize="sm">
              {data.name}
            </Text>
          </HStack>
        </Link>
      ) : (
        <HStack>
          <Avatar src={data.picture_uri} size={location ? "sm" : "xs"} name={data.picture_uri} />
          <Text fontWeight="semibold" fontSize="sm">
            {data.name}
          </Text>
        </HStack>
      )}
      {handleDelete && user && (
        <IconButton
          size="xs"
          bg="transparent"
          color="red.500"
          icon={<CloseIcon />}
          aria-label={"delete_user"}
          onClick={() => {
            handleDelete(user);
          }}
        />
      )}
    </HStack>
  );
};

type UserRequestPillProps = {
  user: { id: number; assigned_user?: UserOptionType; picture_uri?: string; name?: string };
  handleDelete?: (user) => void;
};

export const UserRequestPill = ({ user, handleDelete }: UserRequestPillProps) => {
  const data = user.assigned_user ? user.assigned_user : user;
  return (
    <HStack justify="space-between" width="100%">
      <Link as={RouterLink} to={`/users/${data.id}`}>
        <HStack>
          <Avatar src={data.picture_uri} size="sm" />
          <Text
            fontSize="sm"
            fontWeight="semibold"
            color={useColorModeValue("gray.700", "gray.300")}>
            {data.name}
          </Text>
        </HStack>
      </Link>
      {handleDelete && (
        <IconButton
          size="sm"
          bg="transparent"
          color="red.500"
          icon={<CloseIcon />}
          aria-label={"delete_user"}
          onClick={() => {
            handleDelete(user);
          }}
        />
      )}
    </HStack>
  );
};

export const SubscriberOptions = () => {
  const { data, isSuccess } = useUsersQuery();

  return {
    data: data?.map((user) => ({
      value: user.id,
      label: user.name,
      user: { id: user.id, name: user.name, picture_uri: user.picture_uri },
    })),
    isSuccess,
  };
};

type SidebarListProps = {
  title: string;
  data: any[];
  url: string;
  onFacets: (facets: Facets) => void;
  facets: Facets;
  debouncedSearch: (value: string) => void;
  selectedItemId?: number;
};

export const SidebarList = ({
  title,
  data,
  url,
  debouncedSearch,
  onFacets,
  facets,
  selectedItemId,
}: SidebarListProps) => (
  <VStack
    width="100%"
    align="start"
    spacing={4}
    py={6}
    px={4}
    border="1px"
    borderColor="chakra-border-color"
    borderRadius="md">
    <HStack justify="space-between" width="100%">
      <Heading size="md">{title}</Heading>
      <Facets variant="button" facets={facets} onChange={onFacets} />
    </HStack>
    <Input placeholder="Search..." onChange={(e) => debouncedSearch(e.target.value)} />
    <VStack width="100%" align="start" spacing={0} maxH="lg" overflowY="scroll">
      {data.map((item) => (
        <LinkBox
          key={item.id}
          width="100%"
          _hover={{
            bg: useColorModeValue("gray.100", "gray.700"),
            borderRadius: "md",
          }}>
          <LinkOverlay as={RouterLink} to={`${url}/${item.id}`}>
            <VStack
              align="start"
              p={4}
              borderRadius={item.id === selectedItemId ? "md" : ""}
              borderBottom={item.id === selectedItemId ? "none" : "1px"}
              borderColor="chakra-border-color"
              bg={item.id === selectedItemId ? useColorModeValue("gray.100", "gray.700") : ""}>
              <Heading size="sm" key={item.id}>
                {item.service_name} <Box as="span">#{item.id}</Box>
              </Heading>
              <Text fontSize="sm" noOfLines={2}>
                {item.request_description.length > 75
                  ? `${item.request_description.slice(0, 75)}...`
                  : item.request_description}
              </Text>
              <HStack justify="space-between" width="100%">
                <Tag
                  colorScheme={
                    serviceStatusMap.find((ele) => ele.value === item.status)?.color || "gray"
                  }>
                  {titleize(humanize(item.status))}
                </Tag>
              </HStack>
            </VStack>
          </LinkOverlay>
        </LinkBox>
      ))}
    </VStack>
  </VStack>
);

export const CardDetailsTable = ({ virtualCard }: { virtualCard: VirtualCardData }) => (
  <Box border="1px" width="100%" borderRadius="md" borderColor="chakra-border-color">
    <Table size="sm">
      <Tbody>
        <Tr>
          <Td textTransform="uppercase" fontWeight="bold" fontSize="sm">
            Card no.
          </Td>
          <Td>
            <HStack justify="flex-end">
              <Text fontSize="sm">************ {virtualCard.last4}</Text>
              <CopyClipboardButton text={virtualCard.number} />
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td textTransform="uppercase" fontWeight="bold" fontSize="sm">
            Exp.
          </Td>
          <Td>
            <HStack justify="flex-end">
              <Text fontSize="sm">
                {virtualCard.exp_month} / {virtualCard.exp_year}
              </Text>
              <CopyClipboardButton text={`${virtualCard.exp_month}/${virtualCard.exp_year}`} />
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td textTransform="uppercase" fontWeight="bold" fontSize="sm">
            CVC
          </Td>
          <Td>
            <HStack justify="flex-end">
              <Text fontSize="sm">{virtualCard.cvc}</Text>
              <CopyClipboardButton text={virtualCard.cvc} />
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td textTransform="uppercase" fontWeight="bold" fontSize="sm">
            Cardholder
          </Td>
          <Td>
            <HStack justify="flex-end">
              <Text fontSize="sm">{virtualCard.cardholder.name}</Text>
              <CopyClipboardButton text={virtualCard.cardholder.name} />
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td textTransform="uppercase" fontWeight="bold" fontSize="sm">
            Address
          </Td>
          <Td>
            <HStack justify="flex-end">
              <Text fontSize="sm">{virtualCard.cardholder.billing_address.line1}</Text>
              <CopyClipboardButton text={virtualCard.cardholder.billing_address.line1} />
            </HStack>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

export const CopyClipboardButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <Tooltip label="Copied!" isOpen={copied}>
      <IconButton
        size="xs"
        aria-label="copy button"
        icon={<TbCopy />}
        onClick={() => handleCopyClick(text)}
      />
    </Tooltip>
  );
};
