import React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  LinkBox,
  Flex,
  Avatar,
  VStack,
  LinkOverlay,
  Heading,
  useColorModeValue,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useDeleteBudgetGroupApprover } from "../../../../api/budget_group_approvers";
import { BsThreeDotsVertical } from "react-icons/bs";

export const BudgetGroupUserCard = ({ budgetGroupUser }) => {
  const { mutate: deleteBudgetGroupApprover } = useDeleteBudgetGroupApprover();
  const { user, id, role } = budgetGroupUser;

  return (
    <Flex alignItems="center" justify="space-around">
      <LinkBox width="100%">
        <LinkOverlay as={RouterLink} to={`/users/${user.id}`} w="100%">
          <Flex flex="1" gap="4" alignItems="center">
            <Avatar
              name={user.name}
              src={user.picture_uri}
              borderColor={"none"}
              borderWidth={0}
              boxSize={10}
            />
            <VStack align="start" spacing={1}>
              <Heading
                size="sm"
                fontWeight="semibold"
                color={useColorModeValue("gray.700", "gray.100")}>
                {user.name}
              </Heading>
            </VStack>
          </Flex>
        </LinkOverlay>
      </LinkBox>

      {role !== "lead" && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<BsThreeDotsVertical size={20} />}
            size="sm"
            variant="ghost"
          />
          <MenuList alignItems="center">
            <MenuItem
              color={useColorModeValue("red.500", "red.300")}
              icon={<CloseIcon />}
              onClick={() => {
                deleteBudgetGroupApprover(id);
              }}>
              Remove Approver
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
