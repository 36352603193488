import { Center, Flex, Text, Button, useToast } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import React, { useEffect } from "react";

const RefreshPageToast = () => {
  const toast = useToast();
  const toastID = "refresh-page-toast";

  useEffect(() => {
    if (toast.isActive(toastID)) return;

    toast({
      id: toastID,
      title: "Refresh Page",
      status: "warning",
      duration: null,
      isClosable: true,
      render: () => (
        <Center>
          <Flex
            direction={{ base: "column", md: "row" }}
            color="black"
            p={3}
            bg="orange.100"
            borderRadius="md"
            justifyContent="space-between"
            width={{ base: "250px", md: "400px" }}
            alignItems="center"
            border={"1.5px solid"}
            borderColor={"orange.500"}>
            <Flex direction={"row"} alignItems={"center"}>
              <WarningIcon boxSize={"5"} mb={{ base: 2, md: 0 }} color={"orange.500"} />
              <Text mb={{ base: 2, md: 0 }} marginLeft={"2"} fontWeight={"bold"} color={"gray.700"}>
                New updates available!
              </Text>
            </Flex>
            <Button
              onClick={() => {
                window.location.reload();
              }}
              colorScheme="teal">
              Refresh Page
            </Button>
          </Flex>
        </Center>
      ),
    });
  }, [toast]);

  return <></>;
};

export default RefreshPageToast;
