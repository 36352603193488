import React from "react";
import { Header, useCollection, CollectionPage, buildFacets } from "@sciencecorp/helix-components";
import { Box, Stack, Tag } from "@chakra-ui/react";
import { KeyringMessageData, useSearchKeyringMessagesQuery } from "../../../api/keyring_message";
import { StaleUserCard, UserCard } from "./UserCard";
import { HrWarning } from "../warning";

export const Encryption = () => {
  const { pagination, order, onOrder, onPagination, filters, facets, onFacets } = useCollection();

  const { data, isLoading } = useSearchKeyringMessagesQuery({
    term: "*",
    aggs: ["message_type", "is_stale"],
    filters: {
      ...filters,
    },
    pagination,
    order,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns = [
    {
      label: "ID",
      orderOptions: { orderKey: "id" },
      render: (message: KeyringMessageData) => message.id,
    },
    {
      label: "Type",
      render: (message: KeyringMessageData) => message.message_type,
    },
    {
      label: "Assignees",
      render: (message: KeyringMessageData) =>
        !message.stale_users.length ? (
          <Stack direction="column">
            {message.matching_users.map((user) => (
              <UserCard key={user} userId={user} />
            ))}
          </Stack>
        ) : (
          <Stack direction="column">
            {message.matching_users
              .filter((user) => !message.stale_users.some((ele) => ele.user_id === user))
              .map((user) => (
                <UserCard key={user} userId={user} />
              ))}
            {message.stale_users.map((user) => (
              <StaleUserCard key={user.user_id} userId={user.user_id} />
            ))}
          </Stack>
        ),
    },
    {
      label: "Status",
      orderOptions: { orderKey: "stale" },
      render: (message: KeyringMessageData) =>
        message.is_stale ? (
          <Tag colorScheme="red">Key Expired</Tag>
        ) : (
          <Tag colorScheme="green">Secure</Tag>
        ),
    },
  ];
  return (
    <Box>
      <HrWarning />
      <Header title="Encryption" />

      <CollectionPage
        isLoading={isLoading}
        items={data?.results || []}
        columns={columns}
        facets={populatedFacets}
        facetsProps={{ background: true }}
        onFacets={onFacets}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        order={order}
        onOrder={onOrder}
      />
    </Box>
  );
};
