import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useCheckboxGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { OmniOrderKey } from "./types";

interface OmniFiltersProps {
  modelTypeFilters: string[];
  setModelTypeFilters: (newVal) => void;
  omniOrderKey: OmniOrderKey;
  setOmniOrderKey: (newVal) => void;
}
export const OmniFilters = (props: OmniFiltersProps) => {
  const { modelTypeFilters, setModelTypeFilters, omniOrderKey, setOmniOrderKey } = props;
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: modelTypeFilters,
  });

  useEffect(() => {
    setModelTypeFilters(value);
  }, [value]);

  const homeTypes: { label: string; value: string }[] = [
    { label: "Employee", value: "User" },
    { label: "Team", value: "Team" },
    { label: "Milestone", value: "Milestone" },
    { label: "News Post", value: "NewsPost" },
    { label: "Purchases", value: "Purchase" },
    { label: "Service Request", value: "ServiceRequest" },
  ];

  const foundryTypes: { label: string; value: string }[] = [
    { label: "Workflow", value: "Workflow" },
    { label: "Job", value: "Run" },
    { label: "Protocol", value: "Protocol" },
    { label: "Tool", value: "Tool" },
    { label: "Squawk", value: "Squawk" },
    { label: "Wafer Lot", value: "WaferLot" },
    { label: "Inventory Part", value: "InventoryPart" },
    { label: "Document", value: "Document" },
  ];

  const researchTypes: { label: string; value: string }[] = [
    { label: "Animal", value: "Animal" },
    { label: "Surgery", value: "Surgery" },
    { label: "Cell Line", value: "CellLine" },
    { label: "Culture", value: "Culture" },
    { label: "Well Plate", value: "WellPlate" },
    { label: "Tissue Sample", value: "TissueSample" },
    { label: "Tissue Storage", value: "TissueStorageItem" },
    { label: "Data Object", value: "DataObject" },
    { label: "Device Batch", value: "DeviceProcess" },
    { label: "Device Wafer", value: "DeviceWafer" },
    { label: "Workflow", value: "Workflow" },
    { label: "Workflow Run", value: "WorkflowRun" },
    { label: "Location", value: "Location" },
    { label: "Inventory", value: "Compound" },
    { label: "Tag", value: "TagDetail" },
  ];
  return (
    <Box
      h={["full", "fit-content"]}
      background={useColorModeValue("gray.50", "gray.900")}
      width={["full", "400px"]}
      py="4"
      position={["relative", "sticky"]}
      top={["-moz-initial", "0"]}>
      <Text fontWeight="bold" px="4" pb="3" lineHeight="5" fontSize="sm">
        Home Filters:
      </Text>
      <VStack maxH={["300px", "full", "full"]} overflow="scroll">
        {homeTypes.map((type) => (
          <Flex key={type.value} alignItems="center" width="100%" px="5">
            <Checkbox {...getCheckboxProps({ value: type.value })}>
              <Text fontSize="sm">{type.label}</Text>
            </Checkbox>
          </Flex>
        ))}
      </VStack>
      <Box px="5" my="3">
        <Divider />
      </Box>
      <Text fontWeight="bold" px="4" pb="3" lineHeight="5" fontSize="sm">
        Foundry Filters:
      </Text>
      <VStack maxH={["300px", "full", "full"]} overflow="scroll">
        {foundryTypes.map((type) => (
          <Flex key={type.value} alignItems="center" width="100%" px="5">
            <Checkbox {...getCheckboxProps({ value: type.value })}>
              <Text fontSize="sm">{type.label}</Text>
            </Checkbox>
          </Flex>
        ))}
      </VStack>
      <Box px="5" my="3">
        <Divider />
      </Box>
      <Text fontWeight="bold" px="4" pb="3" lineHeight="5" fontSize="sm">
        Research Filters:
      </Text>
      <VStack maxH={["300px", "full", "full"]} overflow="scroll">
        {researchTypes.map((type) => (
          <Flex key={type.value} alignItems="center" width="100%" px="5">
            <Checkbox {...getCheckboxProps({ value: type.value })}>
              <Text fontSize="sm">{type.label}</Text>
            </Checkbox>
          </Flex>
        ))}
      </VStack>
      <Box px="5" my="3">
        <Divider />
      </Box>
      <Text lineHeight="5" fontSize="sm" fontWeight="bold" px="4" mb="3">
        Order by:
      </Text>

      <RadioGroup onChange={setOmniOrderKey} value={omniOrderKey}>
        <Stack direction="column" px="5">
          <Radio value="_score">
            <Text fontSize="sm">Most Relevant</Text>
          </Radio>
          <Radio value="model_type">
            <Text fontSize="sm">Type</Text>
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
};
