import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { invalidateTraining } from "./trainings";
import { zodParse } from "./zodParse";

export const TRAINING_SESSIONS_BASE_URL = "training_sessions";

export const trainingSessionSchema = z.object({
  id: z.number(),
  training_id: z.number(),
  training_name: z.string(),
  cutoff_score: z.number().nullable(),
  facilitator_id: z.number(),
  started_at: z.string().nullable(),
  ended_at: z.string().nullable(),
  scheduled_date: z.string(),
  average_score: z.number().nullable(),
  max_people: z.number().nullable().optional(),
  attendee_count: z.number(),
  spots_remaining: z.number().nullable(),
});

export const trainingSessionIndexSchema = z.object({
  results: z.array(trainingSessionSchema),
  pagination: paginationSchema,
});

export type TrainingSessionData = z.infer<typeof trainingSessionSchema>;
export type TrainingSessionCreateParams = Pick<
  TrainingSessionData,
  "training_id" | "facilitator_id" | "scheduled_date" | "max_people"
>;

// api queries

export const getMyTrainingSessions = async (params?: object | null) => {
  const result = await api.get(TRAINING_SESSIONS_BASE_URL, { params });
  return zodParse(trainingSessionIndexSchema, result.data);
};

export const getTrainingSession = async (id: number) => {
  const result = await api.get(`${TRAINING_SESSIONS_BASE_URL}/${id}`);
  return zodParse(trainingSessionSchema, result.data);
};

export const getAssociatedUserTrainingSessions = async (id: number) => {
  const result = await api.get(`${TRAINING_SESSIONS_BASE_URL}/${id}/users`);
  return result.data;
};

export const newTrainingSession = async (training_session: TrainingSessionCreateParams) => {
  const result = await api.post(TRAINING_SESSIONS_BASE_URL, { training_session });
  return result.data;
};

export const updateTrainingSession = async (training_session: Partial<TrainingSessionData>) => {
  const result = await api.put(`${TRAINING_SESSIONS_BASE_URL}/${training_session.id}`, {
    training_session,
  });
  return result.data;
};

export const deleteTrainingSession = async (id: number) => {
  await api.delete(`${TRAINING_SESSIONS_BASE_URL}/${id}`);
};

export const deleteOptionalUserTrainingSessions = async (id: number) => {
  await api.delete(`${TRAINING_SESSIONS_BASE_URL}/${id}/delete_optional_users`);
};

// query hooks

export const invalidateTrainingSession = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [TRAINING_SESSIONS_BASE_URL, id] : [TRAINING_SESSIONS_BASE_URL],
    });
};

export const useGetMyTrainingSessions = (params: object) => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, params],
    queryFn: () => getMyTrainingSessions(params),
  });
};

export const useGetTrainingSession = (id: number) => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, id],
    queryFn: () => getTrainingSession(id),
  });
};

export const useGetAssociatedUserTrainingSessions = (id: number) => {
  return useQuery({
    queryKey: [TRAINING_SESSIONS_BASE_URL, id, "user"],
    queryFn: () => getAssociatedUserTrainingSessions(id),
  });
};

export const useNewTrainingSession = () => {
  return useMutation({
    mutationFn: newTrainingSession,
    onSuccess: invalidateTraining(),
  });
};

export const useUpdateTrainingSession = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateTrainingSession,
    onSuccess: onSuccessCallback(),
  });
};

export const useDeleteTrainingSession = (trainingId: number) => {
  return useMutation({
    mutationFn: deleteTrainingSession,
    onSuccess: invalidateTraining(trainingId),
  });
};

export const useDeleteOptionalUserTrainingSessions = (id: number) => {
  return useMutation({
    mutationFn: deleteOptionalUserTrainingSessions,
    onSuccess: invalidateTrainingSession(id),
  });
};
