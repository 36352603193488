const getPercentageBucket = (answer) => {
  if (answer <= 20) return "0-20%";
  if (answer <= 40) return "21-40%";
  if (answer <= 60) return "41-60%";
  if (answer <= 80) return "61-80%";
  return "81-100%";
};

export const getSentimentBucket = (answer) => {
  if (answer === 0) return "Strong No";
  if (answer === 1) return "No";
  if (answer === 2) return "Yes";
  return "Strong Yes";
};

export const UserSurveyMap = {
  "1": getPercentageBucket,
  "2": getSentimentBucket,
};

export const createVictoryData = (surveyResponses, surveyId, counter) => {
  const bucketFunction = UserSurveyMap[surveyId];
  surveyResponses.forEach((response) => {
    counter[bucketFunction(response.answer)]++;
  });

  const data = Object.entries(counter).map(([x, y]) => ({ x, y }));

  return data;
};

type SurveyInfo = {
  labels: { [key: string]: string | string[] };
  colors: string[];
};
export const firstInfo: SurveyInfo = {
  labels: {
    xAxisLabels: ["0-20%", "21-40%", "41-60%", "61-80%", "81-100%"],
    graphLabel: "Percentage of Time Spent on High Impact Work",
    heading: "Time Spent on High Impact Work",
    tableHeading: "'0-40%' Responses",
  },
  colors: ["#38B2AC", "#319795", "#2C7A7B", "#285E61", "#234E52"],
};

export const secondInfo: SurveyInfo = {
  labels: {
    xAxisLabels: ["Strong No", "No", "Yes", "Strong Yes"],
    graphLabel: "Distribution of employees are working on their most impactful tasks.",
    heading: "Working on Most Impactful Task",
    tableHeading: "'Strong No/No' Responses",
  },
  colors: ["#81E6D9", "#38B2AC", "#2C7A7B", "#234E52"],
};
