import { z } from "zod";
import { TIMEZONE } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";

export const dateTimeSchema = z.string().transform((val) => DateTime.fromISO(val));

export function toDateTimeLocalString(datetime?: Date): string {
  if (!datetime) return "";
  // It just so happens Swedish locale produces almost the exact string we want
  return datetime.toLocaleString("sv").replace(" ", "T");
}

export const dateTimeWithWeekDayHandler = (date: Date) => {
  return DateTime.fromISO(toDateTimeLocalString(date), {
    zone: TIMEZONE,
  }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
};
