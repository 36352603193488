import React, { useMemo } from "react";
import {
  Link,
  Text,
  Box,
  VStack,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { Collection, Column, RecordLink, StatusSelect } from "@sciencecorp/helix-components";
import { BiStore } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";
import { VendorData, useUpdateVendor } from "../../api/vendor";
import { vendorStatusMap, vendorStatusOptions } from "./util";

export const VendorTab = ({
  data,
  isLoading,
  isPurchasingAdmin,
  pagination,
  onPagination,
  order,
  onOrder,
}) => {
  const { mutate: updateVendor } = useUpdateVendor();

  const columns: Column[] = useMemo(
    () => [
      {
        label: "Vendor",
        orderOptions: { orderKey: "name" },
        weight: 2,
        columnContainerProps: {
          isTruncated: true,
        },
        render: (vendor: VendorData) => (
          <RecordLink
            link={`/services/vendors/${vendor.id}`}
            identifier={`${vendor.name}`}
            size="small"
            icon={
              <Box ml={3}>
                <BiStore />
              </Box>
            }
          />
        ),
      },
      {
        label: "Address",
        weight: 2,
        columnContainerProps: {
          isTruncated: true,
        },
        render: (vendor: VendorData) =>
          vendor.address ? (
            <Text isTruncated={true} overflow={"hidden"} textOverflow={"ellipsis"}>
              {vendor.address}
            </Text>
          ) : (
            <Text as="i" opacity="0.5">
              Unknown
            </Text>
          ),
      },
      {
        label: "Website",
        weight: 2,
        orderOptions: { orderKey: "website" },
        columnContainerProps: {
          isTruncated: true,
        },
        render: (vendor: VendorData) =>
          vendor.website ? (
            <Link
              href={vendor.website}
              color={"teal.500"}
              isExternal
              isTruncated={true}
              overflow={"hidden"}
              textOverflow={"ellipsis"}>
              {vendor.website}
            </Link>
          ) : (
            <Text as="i" opacity="0.5">
              Unknown
            </Text>
          ),
      },
      {
        label: "Status",
        weight: 1,
        orderOptions: { orderKey: "approved" },
        render: (vendor: VendorData) => (
          <StatusSelect
            isDisabled={!isPurchasingAdmin}
            variant="tag"
            options={vendorStatusOptions}
            status={vendorStatusMap.find((ele) => ele.value === vendor.status)}
            onSubmit={(option) => {
              option && updateVendor({ id: vendor.id, status: option.toString() });
            }}
          />
        ),
      },
    ],
    [data]
  );

  return (
    <VStack align="start" width="100%">
      <Stack
        flexDir={{ base: "column", md: "row" }}
        width="100%"
        justify="space-between"
        spacing={6}
        mb={8}>
        <StatGroup
          flexDir={{ base: "column", md: "row" }}
          flex="3"
          py={3}
          px={6}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md">
          <Stat>
            <StatLabel>No. of Vendors</StatLabel>
            <StatNumber>{data?.total_vendors}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Card Enabled Vendors</StatLabel>
            <StatNumber>{data?.card_enabled_vendors}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Vendors With No Transactions</StatLabel>
            <StatNumber>{data?.vendors_with_no_transactions}</StatNumber>
          </Stat>
        </StatGroup>
        <HStack
          py={3}
          px={6}
          flex="1"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Stat>
            <StatLabel>Archived Vendors</StatLabel>
            <StatNumber>{data?.archived_vendors}</StatNumber>
          </Stat>
          <Link as={RouterLink} to="/services/vendors/archive" color="teal.500">
            View
          </Link>
        </HStack>
      </Stack>
      <Box width="100%">
        <Collection
          columns={columns}
          items={data?.results || []}
          isLoading={isLoading}
          onPagination={onPagination}
          pagination={data?.pagination || pagination}
          order={order}
          onOrder={onOrder}
          tableProps={{ layout: "fixed" }}
        />
      </Box>
    </VStack>
  );
};
