import React from "react";
import {
  Box,
  Stack,
  ButtonGroup,
  HStack,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import { useUpdateSubscriptionApproval, useUpdateSubscription } from "../../../../api/subscription";
import { DateTime } from "luxon";
import { MoneyText } from "../../../MoneyText";

export const ApprovalFlow = ({ subscription, currentUser }) => {
  const { mutate: updateSubscriptionApproval } = useUpdateSubscriptionApproval(subscription.id);
  const subscriptionApproval = subscription.subscription_approval;

  if (!subscriptionApproval) return null;
  if (subscriptionApproval.user) return null;
  return (
    <Stack
      direction={{ base: "column", sm: "row" }}
      justify="space-between"
      align="center"
      p={4}
      border="1px"
      borderRadius="md"
      borderColor={useColorModeValue("orange.200", "gray.600")}
      bg={useColorModeValue("orange.50", "gray.700")}>
      <Text>
        <InfoIcon color={useColorModeValue("orange.500", "gray.200")} mr={2} />
        Action Required
      </Text>

      <ButtonGroup>
        <ConfirmationButton
          variant="Button"
          buttonVariant="outline"
          colorScheme="red"
          label="Decline"
          confirmationButtonLabel="Decline"
          confirmationHeader="Decline Subscription"
          children={`Are you sure you want to decline this subscription?`}
          onConfirm={() =>
            updateSubscriptionApproval({
              id: subscriptionApproval.id,
              declined_at: DateTime.now().toISO(),
              user_id: currentUser.id,
            })
          }
        />
        <ConfirmationButton
          variant="Button"
          colorScheme="teal"
          label="Approve"
          confirmationButtonLabel="Approve"
          confirmationHeader="Approve Subscription"
          onConfirm={() =>
            updateSubscriptionApproval({
              id: subscriptionApproval.id,
              approved_at: DateTime.now().toISO(),
              user_id: currentUser.id,
            })
          }>
          <Text>
            Are you sure you want to approve the total amount of{" "}
            <MoneyText
              as={"span"}
              money={subscription.max_amount_per_payment.times(subscription.payments_per_interval)}
              formatOptions={{ compact: "never" }}
            />{" "}
            per {subscription.interval === "monthly" ? "month" : "year"} for this subscription?
          </Text>
        </ConfirmationButton>
      </ButtonGroup>
    </Stack>
  );
};
