import { z } from "zod";
import { userMinimalSchema } from "./user";
import { api } from ".";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { zodParse } from "./zodParse";
import { BUDGET_GROUPS_BASE_URL } from "./budget_groups";

export const BUDGET_GROUP_APPROVERS_BASE_URL = "budget_group_approvers";

export const budgetGroupApproverSchema = z.object({
  id: z.number(),
  budget_group_id: z.number(),
  user_id: z.number(),
  role: z.string(),
});

export const budgetGroupApproverShowSchema = budgetGroupApproverSchema
  .omit({ user_id: true, budget_group_id: true })
  .extend({
    user: z.lazy(() => userMinimalSchema),
  });

export type BudgetGroupApproverData = z.infer<typeof budgetGroupApproverSchema>;
export type BudgetGroupApproverShowData = z.infer<typeof budgetGroupApproverShowSchema>;
export type BudgetGroupApproverCreateParams = Pick<
  BudgetGroupApproverData,
  "user_id" | "role" | "budget_group_id"
>;

export const createBudgetGroupApprover = async (
  budget_group_approver: BudgetGroupApproverCreateParams
) => {
  try {
    const result = await api.post(`${BUDGET_GROUP_APPROVERS_BASE_URL}`, { budget_group_approver });

    return zodParse(budgetGroupApproverShowSchema, result.data);
  } catch (e) {
    console.error("Error creating budget headcount panelist:", e);
    throw e;
  }
};

export const updateBudgetGroupApprover = async (
  budget_group_approver: Partial<BudgetGroupApproverData>
) => {
  try {
    const result = await api.put(`${BUDGET_GROUP_APPROVERS_BASE_URL}/${budget_group_approver.id}`, {
      budget_group_approver,
    });

    return zodParse(budgetGroupApproverShowSchema, result.data);
  } catch (e) {
    console.error("Error creating budget headcount panelist:", e);
    throw e;
  }
};

export const deleteBudgetGroupApprover = async (id: number) => {
  try {
    await api.delete(`${BUDGET_GROUP_APPROVERS_BASE_URL}/${id}`);
  } catch (e) {
    console.error("Error deleting budget headcount panelist:", e);
    throw e;
  }
};

export const invalidateBudgetGroupApprovers = (id?: number) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([BUDGET_GROUPS_BASE_URL]);
};

export const useCreateBudgetGroupApprover = () => {
  return useMutation({
    mutationFn: createBudgetGroupApprover,
    onSuccess: invalidateBudgetGroupApprovers(),
  });
};

export const useUpdateBudgetGroupApprover = () => {
  return useMutation({
    mutationFn: updateBudgetGroupApprover,
    onSuccess: invalidateBudgetGroupApprovers(),
  });
};

export const useDeleteBudgetGroupApprover = () => {
  return useMutation({
    mutationFn: deleteBudgetGroupApprover,
    onSuccess: invalidateBudgetGroupApprovers(),
  });
};
