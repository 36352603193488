import axios from "axios";

export async function getWeather(lat, lon, timezone) {
  try {
    const response = await axios.get("https://api.open-meteo.com/v1/forecast", {
      params: {
        latitude: lat,
        longitude: lon,
        timezone,
        current_weather: true,
        weather_codes: true,
        temperature_unit: "fahrenheit",
        max_temperature_unit: "fahrenheit",
        hourly_temperature_2m: true,
        temperature_2m_max: true,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch weather data", error);
    return null;
  }
}
