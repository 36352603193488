export const toLabel = (value: string) => {
  if (!value) return "";
  return value
    .replace("_", " ")
    .split(" ")
    .map((str) => `${str[0].toUpperCase()}${str.slice(1)}`)
    .join(" ");
};

export function denull<T>(value?: T | null, errorMessage: string = "Unexpected null value"): T {
  if (value === null || value === undefined) {
    throw new Error(errorMessage);
  } else {
    return value;
  }
}
