import React, { useMemo } from "react";
import { Flex, Text, Box, HStack, Stack, useColorModeValue, Divider, Icon } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { RestockFormValues } from "./util";
import { InventoryData } from "../../../../../api/inventory";
import { LuPackagePlus } from "react-icons/lu";
import { useTreeSelectInventoryLocations } from "../../../../../api/inventory_location";
import { findSelectedOption } from "../../../../shared/TreeSelect";
import { initial } from "lodash";
import { humanize, inflect } from "inflection";

type RestockSummaryProps = {
  formValues: RestockFormValues;
  inventory: InventoryData;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  treeSelectInventoryLocationsQuery: ReturnType<typeof useTreeSelectInventoryLocations>;
};

export const RestockSummary = ({
  formValues,
  inventory,
  treeSelectInventoryLocationsQuery,
}: RestockSummaryProps) => {
  const location = useMemo(() => formValues.option, [formValues.option]);
  const locationPath = useMemo(
    () =>
      treeSelectInventoryLocationsQuery.data
        ? initial(findSelectedOption(treeSelectInventoryLocationsQuery.data, location))
        : [],
    [treeSelectInventoryLocationsQuery.data, location]
  );

  return (
    <>
      <HStack alignSelf="center" gap={3} p={4}>
        <Text fontSize="lg" fontWeight="medium">
          You are about to create an inventory item with the following details:
        </Text>
      </HStack>

      <Flex
        justify="center"
        align="center"
        p={8}
        direction="column"
        gap={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Flex direction="column" width="100%" align={"center"} gap={1}>
          <Box mb={4}>
            <Icon as={LuPackagePlus} boxSize={20} color="gray.600" />
          </Box>
          <Stack
            direction={{ base: "column", md: "row" }}
            w={"100%"}
            justify={"space-between"}
            mb={2}>
            <Text color={useColorModeValue("gray.600", "auto")}>Inventory to restock</Text>
            <Text fontWeight="semibold">{inventory.name}</Text>
          </Stack>
          <Divider />
          <Stack
            direction={{ base: "column", md: "row" }}
            w={"100%"}
            justify={"space-between"}
            mb={2}>
            <Text color={useColorModeValue("gray.600", "auto")}>No of Items</Text>
            <Text fontWeight="semibold">{formValues.quantity}</Text>
          </Stack>
          <Divider />

          {inventory.is_consumable && (
            <>
              <Stack
                direction={{ base: "column", md: "row" }}
                w={"100%"}
                justify={"space-between"}
                mb={2}>
                <Text color={useColorModeValue("gray.600", "auto")}>
                  {humanize(inventory.unit_of_measurement || "Quantity")} per Item
                </Text>
                <Text fontWeight="semibold">{formValues.total_quantity} </Text>
              </Stack>
              <Divider />

              <Stack
                direction={{ base: "column", md: "row" }}
                w={"100%"}
                justify={"space-between"}
                mb={2}>
                <Text color={useColorModeValue("gray.600", "auto")}>Total Quantity</Text>
                <Text fontWeight="semibold">
                  {(Number(formValues.total_quantity) * formValues.quantity).toFixed(2)}{" "}
                  {inventory.unit_of_measurement || "units"}
                </Text>
              </Stack>
              <Divider />
            </>
          )}

          <Stack
            direction={{ base: "column", md: "row" }}
            w={"100%"}
            justify={"space-between"}
            mb={2}>
            <Text color={useColorModeValue("gray.600", "auto")}>Location</Text>
            <Stack direction="row" flexWrap="wrap">
              {locationPath?.map((location, idx) => {
                const isLast = idx === locationPath?.length - 1;
                return (
                  <Box key={`destination-${location.label}-${location.value?.id}`}>
                    <HStack>
                      <Text
                        color={isLast ? "teal.500" : "auto"}
                        fontWeight={isLast ? "semibold" : "normal"}>
                        {location.label}
                      </Text>
                      {idx < (locationPath?.length || 1) - 1 && <ArrowForwardIcon />}
                    </HStack>
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};
