import { Avatar, Flex, Heading, IconButton } from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { useUserQuery } from "../../../api/user";

type InterviewerCardProps = {
  id: number;
  handleDelete?: (id: number, name: string) => void;
};

export const InterviewerCard: React.FC<InterviewerCardProps> = ({ id, handleDelete }) => {
  const { data: user } = useUserQuery(id);
  return (
    <Flex margin={0} flexDirection="row" align={"center"} gap={1}>
      <Avatar name={user?.name} src={user?.picture_uri} size="sm" />
      <Heading size="xs">{user?.name}</Heading>
      {handleDelete && user && (
        <IconButton
          aria-label="delete interviewer"
          icon={<CloseIcon boxSize={2} />}
          size="xs"
          onClick={() => handleDelete(user.id, user.name)}
          bg="none"
        />
      )}
    </Flex>
  );
};
