import React, { useState } from "react";
import { Option } from "@sciencecorp/helix-components";
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Divider,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { InventoryShowData } from "../../../../api/inventory";
import { SelectPurchaseSource } from "./RestockModalComponents/SelectPurchaseSource";
import { AddStockAndLocation } from "./RestockModalComponents/AddStockAndLocation";
import { RestockSummary } from "./RestockModalComponents/RestockSummary";
import { RestockFormValues, useRestockInventoryModal } from "./RestockModalComponents/util";

type NewInventoryItemModalProps = {
  inventory: InventoryShowData;
};

export const NewInventoryItemModal = ({ inventory }: NewInventoryItemModalProps) => {
  const [purchaseResults, setPurchaseResults] = useState<Option[]>([]);

  const [purchaseId, setPurchaseId] = useState<string | null>(null);

  const handleClose = () => {
    setPurchaseId(null);
    setPurchaseResults([]);
  };
  const restockInventoryProps = useRestockInventoryModal(inventory, handleClose);

  const disableProceedButton = (formValues: RestockFormValues) => {
    if (restockInventoryProps.page === 0) return !formValues.purchase_line_item?.id;
    if (restockInventoryProps.page === 1) {
      const hasMinimumValues =
        formValues.quantity > 0 && formValues.inventory_location_id && formValues.amount.isPos();
      let validExpirationDate = true;
      let validLotNumber = true;
      if (inventory.track_expiration_dates) {
        validExpirationDate = formValues.expiration_date.length > 0;
      }
      if (inventory.track_lots) {
        validLotNumber = formValues.lot_number.length > 0;
      }
      return !(hasMinimumValues && validExpirationDate && validLotNumber);
    }
  };

  const handleNextButtonClick = () => {
    if (restockInventoryProps.page === 0) restockInventoryProps.setPage(1);
    else if (restockInventoryProps.page === 1) restockInventoryProps.setPage(2);
    else if (restockInventoryProps.page === 2) restockInventoryProps.handleSubmit();
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        onClick={restockInventoryProps.onOpen}
        colorScheme="teal"
        isDisabled={inventory.is_archived}>
        Restock
      </Button>

      <Modal
        isOpen={restockInventoryProps.isOpen}
        onClose={restockInventoryProps.handleClose}
        size="2xl"
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Restock Inventory Item</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Flex direction="column" gap={4} w="100%">
              {restockInventoryProps.page === 0 && (
                <SelectPurchaseSource
                  inventory={inventory}
                  setValue={restockInventoryProps.setValue}
                  control={restockInventoryProps.control}
                  purchaseId={purchaseId}
                  setPurchaseId={setPurchaseId}
                  purchaseResults={purchaseResults}
                  setPurchaseResults={setPurchaseResults}
                />
              )}
              {restockInventoryProps.page === 1 && (
                <AddStockAndLocation
                  inventory={inventory}
                  setPage={restockInventoryProps.setPage}
                  setValue={restockInventoryProps.setValue}
                  control={restockInventoryProps.control}
                  formValues={restockInventoryProps.formValues}
                  showPreview={restockInventoryProps.showPreview}
                  pathToLocation={restockInventoryProps.pathToLocation}
                  setShowPreview={restockInventoryProps.setShowPreview}
                  setPathToLocation={restockInventoryProps.setPathToLocation}
                  setSearch={restockInventoryProps.setSearch}
                  treeSelectInventoryLocationsQuery={
                    restockInventoryProps.treeSelectInventoryLocationsQuery
                  }
                  purchaseId={purchaseId}
                />
              )}
              {restockInventoryProps.page === 2 && (
                <RestockSummary
                  inventory={inventory}
                  formValues={restockInventoryProps.formValues}
                  setPage={restockInventoryProps.setPage}
                  treeSelectInventoryLocationsQuery={
                    restockInventoryProps.treeSelectInventoryLocationsQuery
                  }
                />
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup justifyContent="space-between" w="100%">
              {restockInventoryProps.page === 0 && (
                <Button
                  variant="ghost"
                  colorScheme="teal"
                  px={1}
                  onClick={() => {
                    restockInventoryProps.setPage(1);
                    setPurchaseId(null);
                  }}>
                  Proceed without Purchase
                </Button>
              )}
              {restockInventoryProps.page !== 0 && (
                <Button
                  variant="ghost"
                  colorScheme="teal"
                  onClick={() => restockInventoryProps.setPage(restockInventoryProps.page - 1)}>
                  Back
                </Button>
              )}
              <Button
                colorScheme="teal"
                onClick={handleNextButtonClick}
                isLoading={
                  restockInventoryProps.page === 2 && restockInventoryProps.isLoadingInventoryItem
                }
                isDisabled={disableProceedButton(restockInventoryProps.formValues)}>
                {restockInventoryProps.page === 2 ? "Confirm" : "Next"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
