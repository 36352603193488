import { z } from "zod";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { userMinimalSchema, userPartialSchema } from "./user";
import { api } from ".";
import { SearchParams, aggregationSchema, indexParams, searchParams } from "./collection_types";
import { paginationSchema } from "./shared";
import { zodParse } from "./zodParse";

export const newsPostSchema = z.object({
  id: z.number(),
  title: z.string(),
  content: z.string().nullable(),
  user_id: z.number(),
  category: z.enum(["news", "meeting_note"]).nullable(),
  raw_text: z.record(z.unknown()),
  created_at: z.coerce.date(),
});

export const newsPostPreviewSchema = newsPostSchema.omit({ raw_text: true, user_id: true }).extend({
  user: userMinimalSchema,
});

export const newsPostShowSchema = newsPostSchema.omit({ user_id: true }).extend({
  user: userMinimalSchema,
});

export const newsPostIndexSchema = z.object({
  results: z.array(newsPostPreviewSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable(),
});

export type NewsPostPartialData = z.infer<typeof newsPostSchema>;
export type NewsPostShowData = z.infer<typeof newsPostShowSchema>;
export type NewsPostPreviewData = z.infer<typeof newsPostPreviewSchema>;
export type NewsPostIndexData = z.infer<typeof newsPostIndexSchema>;

export const NEWS_POST_BASE_URL = "news_posts";

export const getNewsPosts = async () => {
  const result = await api.get(`${NEWS_POST_BASE_URL}`);
  return zodParse(z.array(newsPostShowSchema), result.data);
};

export const getNewsPostsPreviews = async () => {
  const result = await api.get(`${NEWS_POST_BASE_URL}/preview_posts`);
  return zodParse(z.array(newsPostPreviewSchema), result.data);
};

export const searchNewsPosts = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [NEWS_POST_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(newsPostIndexSchema, result.data);
};

export const getNewsPost = async (id: number) => {
  const result = await api.get(`${NEWS_POST_BASE_URL}/${id}`);
  return zodParse(newsPostShowSchema, result.data);
};

export const updateNewsPost = async (params: Omit<NewsPostPartialData, "user">) => {
  const result = await api.put(`${NEWS_POST_BASE_URL}/${params.id}`, {
    news_post: {
      ...params,
    },
  });
  return result.data;
};

export const deleteNewsPost = async (id: number) => {
  await api.delete(`${NEWS_POST_BASE_URL}/${id}`);
};

export const createNewsPost = async (params: Omit<NewsPostPartialData, "user">) => {
  const result = await api.post(NEWS_POST_BASE_URL, { news_post: { ...params } });
  return result.data;
};

const invalidateNewsPosts = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [NEWS_POST_BASE_URL] });
};

export const useCreateNewsPost = () => {
  return useMutation({
    mutationFn: createNewsPost,
    onSuccess: invalidateNewsPosts(),
  });
};

export const useUpdateNewsPost = () => {
  return useMutation({
    mutationFn: updateNewsPost,
    onSuccess: invalidateNewsPosts(),
  });
};

export const useDeleteNewsPost = () => {
  return useMutation({
    mutationFn: deleteNewsPost,
  });
};

export const useNewsPostsQuery = () => {
  return useQuery({
    queryKey: [NEWS_POST_BASE_URL],
    queryFn: getNewsPosts,
  });
};

export const useNewsPostsPreviewsQuery = () => {
  return useQuery({
    queryKey: [NEWS_POST_BASE_URL],
    queryFn: getNewsPostsPreviews,
  });
};

export const useNewsPostQuery = (id: number) => {
  return useQuery({
    queryKey: [NEWS_POST_BASE_URL, id],
    queryFn: () => getNewsPost(id),
  });
};

export const useSearchNewsPostsQuery = (
  params: SearchParams,
  opts?: any
): UseQueryResult<NewsPostIndexData> => {
  return useQuery({
    queryKey: [NEWS_POST_BASE_URL, params],
    queryFn: () => searchNewsPosts(params),
    ...opts,
  });
};
