import { dateTimeSchema } from "../../../helpers/dateTime";
import { z } from "zod";
import _ from "lodash";

export const payrollRowSchema = z.object({
  employee: z.string().optional(),
  workEmail: z.string().optional(),
  payRunName: z.string().optional(),
  payRunCheckDate: dateTimeSchema.nullable().optional(),
  payRunStatus: z.string().optional(),
  endDate: dateTimeSchema.nullable().optional(),
  startDate: dateTimeSchema.nullable().optional(),
  payPeriod: z.string().optional(),
  totalEmployerCosts: z.coerce.string().transform((val) => parseFloat(val)),
  totalEmployerCostsCurrency: z.string().optional(),
  employeeGrossPay: z.coerce
    .string()
    .optional()
    .transform((val) => (!!val ? parseFloat(val) : 0)),
  employeeGrossPayCurrency: z.string().optional(),
  employeeNetPay: z.coerce
    .string()
    .optional()
    .transform((val) => (!!val ? parseFloat(val) : 0)),
  employeeNetPayCurrency: z.string().optional(),
});
export type PayrollRow = z.infer<typeof payrollRowSchema>;

export const payrollMessageContentSchema = z.object({
  hourly: z.array(payrollRowSchema),
  salary: z.array(payrollRowSchema),
});
export type PayrollMessageContent = z.infer<typeof payrollMessageContentSchema>;
