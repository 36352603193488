import React from "react";
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverHeader,
  PopoverContent,
  PopoverCloseButton,
  IconButton,
  PopoverTrigger,
  ButtonProps,
} from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { DeleteableFileDownload } from "./DeleteableFileDownload";
import { BlobUploadButton } from "./BlobUploadButton";
import { BlobFile } from "../../api/blob_files";

type AttachmentPopoverProps = {
  files: BlobFile[];
  recordId: number;
  recordType: string;
  fileableColumn: string;
  onSuccessCallback: (id?: number | undefined) => () => Promise<void>;
  deleteFile: (id: number) => void;
  buttonProps?: ButtonProps;
};

export const AttachmentPopover = ({
  files,
  recordId,
  recordType,
  fileableColumn,
  onSuccessCallback,
  deleteFile,
  buttonProps,
}: AttachmentPopoverProps) => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton
          {...buttonProps}
          aria-label="Attachments"
          icon={<AttachmentIcon />}
          colorScheme={files.length > 0 ? "teal" : "gray"}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader fontSize="lg" fontWeight="semibold">
          Attachments
        </PopoverHeader>
        <PopoverBody>
          <Flex flexWrap="wrap" gap={2} align="center">
            {files.map((file) => (
              <DeleteableFileDownload
                key={`${file.id}-${file.filename}`}
                file={file}
                deleteFile={deleteFile}
              />
            ))}
            <BlobUploadButton
              onSuccessCallback={onSuccessCallback}
              recordId={recordId}
              recordType={recordType}
              fileableColumn={fileableColumn}
            />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
