import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { paginationSchema } from "./shared";
import { invalidateTeams, teamPartialSchema } from "./team";
import { userPartialSchema } from "./user";
import { zodParse } from "./zodParse";

export const MILESTONE_PREDICTION_BASE_URL = "milestone_predictions";
export const MILESTONE_BASE_URL = "milestones";

/** milestone_prediction */
export const milestonePredictionSchema = z.object({
  id: z.number(),
  milestone_id: z.number(),
  user_id: z.number(),
  completion_date: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});
export const milestonePredictionPartialSchema = milestonePredictionSchema
  .pick({
    id: true,
    completion_date: true,
    created_at: true,
  })
  .extend({
    user: z.lazy(() => userPartialSchema),
  });

export type MilestonePredictionData = z.infer<typeof milestonePredictionSchema>;
export type MilestonePredictionPartialData = z.infer<typeof milestonePredictionPartialSchema>;
export type MilestonePredictionCreateParams = Pick<
  MilestonePredictionData,
  "milestone_id" | "completion_date"
>;

/** milestone */
export const milestoneStatusSchema = z.enum(["draft", "active", "completed", "abandoned"]);
export const milestoneSchema = z.object({
  id: z.number(),
  name: z.string(),
  completed_at: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  team_id: z.number(),
  description: z.string().nullable(),
  bounty: z.number().nullable(),
  status: milestoneStatusSchema.nullable(),
  is_active: z.boolean(),
  approved_by_id: z.number().nullable(),
  approved_at: z.string().nullable(),
  abandoned_at: z.string().nullable(),
});
export const milestonePartialSchema = milestoneSchema
  .pick({
    id: true,
    name: true,
    description: true,
    bounty: true,
    status: true,
    approved_by_id: true,
    approved_at: true,
    abandoned_at: true,
    completed_at: true,
    created_at: true,
    updated_at: true,
    is_active: true,
  })
  .extend({
    milestone_predictions: z.array(
      milestonePredictionPartialSchema
        .pick({
          id: true,
          completion_date: true,
          created_at: true,
        })
        .extend({
          user: z.lazy(() =>
            userPartialSchema.pick({
              id: true,
              first_name: true,
              last_name: true,
              email: true,
              name: true,
              picture_uri: true,
            })
          ),
        })
    ),
    team: z.lazy(() =>
      teamPartialSchema.pick({
        can_act_as_lead: true,
        name: true,
      })
    ),
  });

export const milestoneShowSchema = milestonePartialSchema.extend({
  team: z.lazy(() =>
    teamPartialSchema.pick({
      can_act_as_lead: true,
      name: true,
      team_memberships: true,
    })
  ),
});
export const milestoneIndexSchema = z.object({
  results: z.array(milestonePartialSchema),
  pagination: paginationSchema,
});

export type MilestoneData = z.infer<typeof milestoneSchema>;
export type MilestonePartialData = z.infer<typeof milestonePartialSchema>;
export type MilestoneShowData = z.infer<typeof milestoneShowSchema>;
export type MilestoneIndexData = z.infer<typeof milestoneIndexSchema>;
export type MilestoneCreateParams = Pick<MilestoneData, "name" | "team_id">;
export type MilestoneStatus = z.infer<typeof milestoneStatusSchema>;

/** api-queries */
export const newMilestonePrediction = async (
  milestone_prediction: MilestonePredictionCreateParams
) => {
  await api.post(MILESTONE_PREDICTION_BASE_URL, { milestone_prediction });
};
export const updateMilestonePrediction = async (
  milestone_prediction: Partial<MilestonePredictionData>
) => {
  await api.put(`${MILESTONE_PREDICTION_BASE_URL}/${milestone_prediction.id}`, {
    milestone_prediction,
  });
};
export const getMilestone = async (id: number) => {
  const result = await api.get(`${MILESTONE_BASE_URL}/${id}`);
  return result.data;
};
export const getMilestones = async (params?: Object | null) => {
  const result = await api.get(MILESTONE_BASE_URL, { params });
  return zodParse(milestoneIndexSchema, result.data);
};
export const newMilestone = async (milestone: MilestoneCreateParams) => {
  const result = await api.post(MILESTONE_BASE_URL, { milestone });
  return result.data.id;
};
export const updateMilestone = async (milestone: Partial<MilestonePartialData>) => {
  const result = await api.put(`${MILESTONE_BASE_URL}/${milestone.id}`, { milestone });
  return result.data.id;
};
export const deleteMilestone = async (id: number) => {
  await api.delete(`${MILESTONE_BASE_URL}/${id}`);
};
export const activateMilestone = async (id: number) => {
  return await api.post(`${MILESTONE_BASE_URL}/${id}/activate`);
};
export const completeMilestone = async (id: number) => {
  return await api.post(`${MILESTONE_BASE_URL}/${id}/complete`);
};
export const abandonMilestone = async (id: number) => {
  return await api.post(`${MILESTONE_BASE_URL}/${id}/abandon`);
};

/** api-query-hooks */
const invalidateMilestone = (milestone_id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [MILESTONE_BASE_URL + milestone_id || ""],
    });
};
export const useNewMilestonePrediction = (milestone_id: number) => {
  return useMutation({
    mutationFn: newMilestonePrediction,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
export const useUpdateMilestonePrediction = (milestone_id: number) => {
  return useMutation({
    mutationFn: updateMilestonePrediction,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
export const useMilestoneQuery = (id: number) => {
  return useQuery({
    queryKey: [MILESTONE_BASE_URL + id],
    queryFn: () => getMilestone(id),
    cacheTime: 0,
  });
};
export const useMilestonesQuery = (indexParams?: Object | null) => {
  return useQuery({
    queryKey: [MILESTONE_BASE_URL, indexParams],
    queryFn: () => getMilestones(indexParams),
  });
};
export const useNewMilestone = (team_id?: number) => {
  return useMutation({
    mutationFn: newMilestone,
    onSuccess: invalidateMilestone() && invalidateTeams(team_id),
  });
};
export const useUpdateMilestone = (milestone_id: number) => {
  return useMutation({
    mutationFn: updateMilestone,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
export const useDeleteMilestone = () => {
  return useMutation({
    mutationFn: deleteMilestone,
    onSuccess: invalidateMilestone(),
  });
};
export const useActivateMilestone = (milestone_id: number) => {
  return useMutation({
    mutationFn: activateMilestone,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
export const useCompleteMilestone = (milestone_id: number) => {
  return useMutation({
    mutationFn: completeMilestone,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
export const useAbandonMilestone = (milestone_id: number) => {
  return useMutation({
    mutationFn: abandonMilestone,
    onSuccess: invalidateMilestone(milestone_id),
  });
};
