import _ from "lodash";
import React, { useState } from "react";
import { Project } from "../../../api/planning/projects";
import {
  ProjectCheckpoint,
  useGetProjectCheckpoints,
  useRemoveProjectCheckpoint,
  useUpdateProjectCheckpoint,
} from "../../../api/planning/project_checkpoints";
import {
  Badge,
  Box,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EmptyState } from "@sciencecorp/helix-components";
import { CheckpointFormModal } from "./CheckpointFormModal";
import { ListCard } from "./ListCard";
import { SpinIfLoading } from "./SpinIfLoading";
import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { FiMoreHorizontal } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";

interface CheckpointListProps {
  project: Project;
}
export const CheckpointList: React.FC<CheckpointListProps> = ({ project }) => {
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<ProjectCheckpoint | null>(null);

  const areaBg = useColorModeValue("gray.100", "gray.700");
  const finishedCheckpointBg = useColorModeValue("teal.50", "teal.900");
  const defaultCheckpointBg = useColorModeValue("gray.50", "gray.700");
  const defaultCheckpointBorder = useColorModeValue("gray.200", "gray.600");

  const checkpointsQuery = useGetProjectCheckpoints(project.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateCheckpoint } = useUpdateProjectCheckpoint();
  const { mutate: deleteCheckpoint } = useRemoveProjectCheckpoint();
  const checkpoints = checkpointsQuery.data || [];

  const toast = useToast();

  const closeUpdateModal = () => {
    setSelectedCheckpoint(null);
    onClose();
  };

  return (
    <ListCard
      title="Checkpoints"
      countBadge={
        <Badge marginLeft={2}>
          {`${checkpoints.filter((cp) => cp.done).length} of ${checkpoints.length} Completed `}
        </Badge>
      }
      nonScrollingArea={<Divider mb={2} />}
      onAdd={onOpen}>
      <CheckpointFormModal
        project={project}
        checkpoints={checkpoints}
        isOpen={isOpen}
        onClose={closeUpdateModal}
        checkpoint={selectedCheckpoint}
      />
      <SpinIfLoading isLoading={checkpointsQuery.isLoading} isError={checkpointsQuery.isError}>
        {checkpoints.length === 0 ? (
          <EmptyState title=" No checkpoints have been added yet." />
        ) : (
          <Flex flexDirection={"column"} width={"100%"} gap={2}>
            {_.sortBy(checkpoints, (cp) => cp.scheduled_date).map((checkpoint) => {
              const offsetFrom = checkpoint.previous_checkpoint?.id
                ? checkpoint.previous_checkpoint?.title || "offset point"
                : "project start";
              return (
                <Flex
                  key={`checkpoint-${checkpoint.id}`}
                  alignItems={"center"}
                  gap={4}
                  width={"100%"}
                  border={1}
                  borderStyle={"solid"}
                  borderColor={areaBg}
                  borderRadius={"md"}
                  backgroundColor={checkpoint.done ? finishedCheckpointBg : defaultCheckpointBg}
                  padding={4}
                  justify={"center"}>
                  <Flex
                    width={"100%"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignContent={"center"}>
                    <Box flexGrow={1}>
                      <Flex gap={2} alignItems={"center"}>
                        <Text fontSize={"md"} fontWeight={"semibold"} noOfLines={1}>
                          {checkpoint.title}
                        </Text>
                        <Menu>
                          <MenuButton as={IconButton} icon={<FiMoreHorizontal />} variant="ghost" />
                          <MenuList>
                            <MenuItem
                              icon={<FaRegEdit />}
                              onClick={() => {
                                setSelectedCheckpoint(checkpoint);
                                onOpen();
                              }}>
                              Edit Checkpoint
                            </MenuItem>
                            <MenuItem
                              icon={<DeleteIcon />}
                              isDisabled={checkpoint.has_dependencies}
                              onClick={() => {
                                deleteCheckpoint(
                                  {
                                    id: checkpoint.id,
                                    project_id: checkpoint.project_id,
                                  },
                                  {
                                    onError: (error) => {
                                      toast({
                                        title: "Cannot delete checkpoints which have dependencies",
                                        position: "bottom",
                                        status: "error",
                                      });
                                    },
                                  }
                                );
                              }}>
                              Delete Checkpoint
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                      <Text fontSize="sm" noOfLines={1}>
                        {`${
                          checkpoint.offset_days
                        } days after ${offsetFrom} | est. ${checkpoint.scheduled_date?.toFormat(
                          "LLL d, yyyy"
                        )}`}
                      </Text>
                    </Box>
                    <Flex
                      alignItems="center"
                      justify="center"
                      onClick={() => {
                        updateCheckpoint({
                          id: checkpoint.id,
                          project_id: checkpoint.project_id,
                          done: !checkpoint.done,
                        });
                      }}>
                      <Tooltip label={checkpoint.done ? "Mark incomplete" : "Mark complete"}>
                        <IconButton
                          isRound={true}
                          variant="solid"
                          colorScheme={checkpoint.done ? "teal" : "gray"}
                          borderColor={checkpoint.done ? "teal" : defaultCheckpointBorder}
                          borderWidth={"1px"}
                          aria-label="Complete"
                          fontSize="sm"
                          size="sm"
                          icon={checkpoint.done ? <CheckIcon /> : undefined}
                          onClick={() => {
                            {
                              !!checkpoint.done;
                            }
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </SpinIfLoading>
    </ListCard>
  );
};
