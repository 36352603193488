import React from "react";
import {
  Heading,
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  LinkOverlay,
  LinkBox,
  useColorModeValue,
  Icon,
  Progress,
} from "@chakra-ui/react";
import { IoMedalOutline } from "react-icons/io5";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { Link } from "react-router-dom";
import { useGetCredential } from "../../../api/credentials";
import { TimeIcon } from "@chakra-ui/icons";
import { credentialColorScheme } from "../util";

export const CredentialCard = ({ userCredential }) => {
  const { data: credential } = useGetCredential(userCredential.credential_id);

  return (
    <LinkBox
      border="1px"
      borderColor={credentialColorScheme(userCredential.status).border}
      borderRadius="lg"
      bg={credentialColorScheme(userCredential.status).background}
      width="100%"
      p={4}>
      <LinkOverlay as={Link} to={`/credentials/me/${userCredential.id}`}>
        <HStack align="start" spacing={4} width="100%">
          <Icon as={IoMedalOutline} boxSize={6} />
          <VStack spacing={2} width="100%" align="start">
            <HStack justify="space-between" width="100%">
              <VStack width="100%" align="start" spacing={0}>
                <Heading size="sm" fontWeight="bold">
                  {credential?.name}
                </Heading>
                {credential?.description ? (
                  <Text
                    noOfLines={2}
                    fontSize="sm"
                    color={useColorModeValue("gray.600", "gray.300")}>
                    {credential.description}
                  </Text>
                ) : (
                  <Text as="i" fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                    No description yet
                  </Text>
                )}
              </VStack>
              <ChevronRightIcon alignSelf="center" boxSize={5} color="gray.500" />
            </HStack>

            {userCredential.status === "expired" ? (
              <>
                <Divider borderColor={credentialColorScheme(userCredential.status).divider} />
                <HStack width="100%">
                  <TimeIcon color={useColorModeValue("red.400", "gray.200")} />
                  <Text fontSize="xs" color={useColorModeValue("red.400", "gray.200")}>
                    This credential has expired.
                  </Text>
                </HStack>
              </>
            ) : userCredential.currency_lapse ? (
              <>
                <Divider borderColor={credentialColorScheme(userCredential.status).divider} />
                <HStack width="100%">
                  <TimeIcon />
                  <Text fontSize="xs" color="gray.600">
                    Currency lapses in:{" "}
                    <Box as="span" fontWeight="medium">
                      {userCredential.currency_lapse} days
                    </Box>
                  </Text>
                  <Progress
                    colorScheme={credentialColorScheme(userCredential.status).bar}
                    size="sm"
                    hasStripe={true}
                    value={userCredential.currency_percentage}
                    width={20}
                  />
                </HStack>
              </>
            ) : userCredential.expiration_date ? (
              <>
                <Divider borderColor={credentialColorScheme(userCredential.status).divider} />
                <HStack width="100%">
                  <TimeIcon />
                  <Text fontSize="xs" color="gray.600">
                    Expires in:{" "}
                    <Box as="span" fontWeight="medium">
                      {userCredential.days_until_expiration} Days
                    </Box>
                  </Text>
                  <Progress
                    colorScheme={credentialColorScheme(userCredential.status).bar}
                    size="sm"
                    hasStripe={true}
                    value={userCredential.expiration_percentage}
                    width={20}
                  />
                </HStack>
              </>
            ) : null}
          </VStack>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};
