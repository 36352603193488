import { ArrowForwardIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FormModal, FormModalProps } from "@sciencecorp/helix-components";
import { default as React } from "react";
import { useMostRecentExchangeRate } from "../api/exchange_rate";
import { Money } from "../helpers/Money";
import { CurrencySelect } from "./CurrencySelect";
import { MoneyText } from "./MoneyText";

interface ExchangeRateExplainerProps {
  to?: string;
  total: Money;
  model: string;
}

const ExchangeRateExplainer: React.FC<ExchangeRateExplainerProps> = ({ to, total, model }) => {
  const exchangeRate = useMostRecentExchangeRate(total.currency.code, to);
  const bgColor = useColorModeValue("gray.50", "gray.700");
  if (to === undefined) {
    return <Text>Please select a currency</Text>;
  } else if (exchangeRate.isLoading) {
    return <Spinner />;
  } else if (exchangeRate.isSuccess) {
    const fxRate = exchangeRate.data;
    return (
      <VStack width={"100%"} align="center" gap={4} textAlign="center" bg={bgColor} p={6}>
        <Heading size={"md"}>Changing this {model}'s currency will affect the total</Heading>
        <VStack>
          <HStack width={"100%"}>
            <Text width={"100%"}>from</Text>
            <Text width={"100%"}>to</Text>
          </HStack>
          <HStack width={"100%"}>
            <Heading as={"h1"} size={"lg"}>
              <MoneyText as={"span"} money={total} />
            </Heading>
            <ArrowForwardIcon boxSize={10} color="green.500" />
            <Heading size={"lg"}>
              <MoneyText as={"span"} money={total.convert(fxRate)} />
            </Heading>
          </HStack>
        </VStack>
        <Text size={"sm"}>(Estimated exchange rate: {fxRate.rate.toFixed(6)})</Text>
      </VStack>
    );
  } else {
    return (
      <Text>
        Unable to get the most recent exchange rate from {total.currency.code} to {to}
      </Text>
    );
  }
};

export interface UpdateCurrencyButtonProps
  extends Omit<
    FormModalProps,
    "title" | "isOpen" | "onOpen" | "onClose" | "children" | "handleSubmit"
  > {
  total: Money;
  model?: string;
  title?: string;
  previousCurrency: string;
  isOpen?: boolean;
  onClose?: () => void;
  handleSubmit: (newCurrency: string, onClose: () => void) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const UpdateCurrencyButton: React.FC<UpdateCurrencyButtonProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formModalProps: Omit<FormModalProps, "children" | "handleSubmit"> = {
    title: "Update Currency",
    size: "3xl",
    submitButtonTitle: "Confirm",
    isOpen,
    onClose,
    ...props,
  };
  const [newCurrency, setNewCurrency] = React.useState<string | undefined>();
  return (
    <>
      <FormModal
        {...formModalProps}
        isLoading={props.isLoading}
        handleSubmit={() => {
          if (newCurrency) {
            props.handleSubmit(newCurrency, () => {
              setNewCurrency(undefined);
              onClose();
            });
          }
        }}>
        <VStack width={"100%"}>
          <ExchangeRateExplainer
            to={newCurrency}
            total={props.total}
            model={props.model || "Record"}
          />
          <FormControl>
            <FormLabel>New Currency</FormLabel>
            <CurrencySelect
              value={newCurrency}
              excludedCurrencies={[props.total.currency.code]}
              onChange={(value) => {
                setNewCurrency(value);
              }}
            />
          </FormControl>
          <Alert status={"warning"}>
            <AlertIcon />
            <Text align="start">
              This {props.model}'s monetary values will be converted using an estimated exchange
              rate. You MUST verify the converted values after changing the currency to ensure
              accuracy.
            </Text>
          </Alert>
        </VStack>
      </FormModal>
      <HStack>
        <Text fontWeight={"medium"}>{props.total.currency.code}</Text>
        <IconButton
          isDisabled={props.isDisabled || false}
          size={"xs"}
          onClick={() => onOpen()}
          aria-label="Update Currency"
          icon={<EditIcon />}
        />
      </HStack>
    </>
  );
};
