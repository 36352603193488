import React from "react";
import { BsHouse } from "react-icons/bs";
import { FaSolarPanel } from "react-icons/fa";
import { MdOutlineScience } from "react-icons/md";

export const omnisearchBarFilterItems = [
  {
    label: "Home",
    value: "helix_company",
    icon: <BsHouse />,
  },
  {
    label: "Foundry",
    value: "helix_foundry",
    icon: <FaSolarPanel />,
  },
  {
    label: "Research",
    value: "science_app",
    icon: <MdOutlineScience />,
  },
];
