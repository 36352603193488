import { z } from "zod";
import { api } from ".";
import { useQuery, UseQueryResult, useQueryClient, useMutation } from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { userPartialSchema } from "./user";

export const SITES_BASE_URL = "sites";
export const SITE_USERS_BASE_URL = "site_users";

export const sitePartialSchema = z.object({
  id: z.number(),
  name: z.string(),
  site_address: z.string(),
  site_city: z.string(),
  site_state: z.string(),
  site_users: z.object({ id: z.number(), user_id: z.number() }).array(),
  site_user_count: z.number(),
});

export const siteUserCreateSchema = z.object({
  site_id: z.number(),
  user_id: z.number(),
});

export type SitePartialData = z.infer<typeof sitePartialSchema>;
export type SiteUserCreateParams = z.infer<typeof siteUserCreateSchema>;
export type SiteCreateParams = Pick<
  SitePartialData,
  "name" | "site_address" | "site_city" | "site_state"
>;

//api queries

export const getAllSites = async () => {
  const result = await api.get(`${SITES_BASE_URL}`);
  return result.data;
};

export const searchSites = async ({
  bodyOptions,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [SITES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ filters, term, bodyOptions });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return result.data;
};

export const createSite = async (site: SiteCreateParams) => {
  return await api.post(`${SITES_BASE_URL}`, { site });
};

export const updateSite = async (site: Partial<SitePartialData>) => {
  const result = await api.put(`${SITES_BASE_URL}/${site.id}`, { site });
  return result.data;
};

export const deleteSite = async (id: number) => {
  await api.delete(`${SITES_BASE_URL}/${id}`);
};

export const createSiteUser = async (siteUser: SiteUserCreateParams) => {
  const result = await api.post(`${SITE_USERS_BASE_URL}`, { site_user: siteUser });
  return result.data;
};

export const deleteSiteUser = async (id: number) => {
  await api.delete(`${SITE_USERS_BASE_URL}/${id}`);
};

//hooks

export const invalidateSite = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [SITES_BASE_URL] });
};

export const useGetAllSitesQuery = () => {
  return useQuery({
    queryKey: [SITES_BASE_URL],
    queryFn: () => getAllSites(),
  });
};

export const useSearchSitesQuery = (params: SearchParams) => {
  return useQuery({
    queryKey: [SITES_BASE_URL, params],
    queryFn: () => searchSites(params),
  });
};

export const useCreateSiteQuery = () => {
  return useMutation({
    mutationFn: createSite,
    onSuccess: invalidateSite(),
  });
};

export const useUpdateSiteQuery = () => {
  return useMutation({
    mutationFn: updateSite,
    onSuccess: invalidateSite(),
  });
};

export const useDeleteSiteQuery = () => {
  return useMutation({
    mutationFn: deleteSite,
    onSuccess: invalidateSite(),
  });
};

export const useCreateSiteUserQuery = () => {
  return useMutation({
    mutationFn: createSiteUser,
    onSuccess: invalidateSite(),
  });
};

export const useDeleteSiteUserQuery = () => {
  return useMutation({
    mutationFn: deleteSiteUser,
    onSuccess: invalidateSite(),
  });
};
