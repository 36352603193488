import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import React, { useMemo } from "react";
import { LuPackageMinus } from "react-icons/lu";
import { InventoryItemShowData } from "../../../api/inventory_item";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { MoneyText } from "../../MoneyText";
import { FormValues } from "../inventoryItem";

export const InventoryItemSummary = ({
  formValues,
  inventoryItem,
}: {
  formValues: FormValues;
  inventoryItem: InventoryItemShowData;
}) => {
  const currency = useCurrency();
  const unitCost: Money = useMemo(
    () =>
      inventoryItem.is_backfill
        ? Money.zero(currency)
        : inventoryItem.amount.div(inventoryItem.total_quantity || 1),
    [formValues]
  );

  const totalCost = useMemo(
    () =>
      inventoryItem.inventory.is_consumable
        ? unitCost.times(Number(formValues.quantity_used))
        : unitCost,
    [formValues]
  );
  return (
    <>
      <Text fontSize="lg" fontWeight="medium" textAlign="center">
        You are checking out the following inventory item:
      </Text>
      <Flex
        justify="center"
        align="center"
        p={8}
        direction="column"
        gap={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md">
        <Flex direction="column" width="100%" align={"center"} gap={1}>
          <Box mb={4}>
            <Icon as={LuPackageMinus} boxSize={20} color="gray.600" />
          </Box>
          <HStack w={"100%"} justify={"space-between"} mb={2}>
            <Text color={useColorModeValue("gray.600", "auto")}>Item Name</Text>
            <Text fontWeight="semibold">{inventoryItem.inventory.name}</Text>
          </HStack>
          <Divider />
          {inventoryItem.inventory.is_consumable && (
            <>
              <HStack w={"100%"} justify={"space-between"} mb={2}>
                <Text color={useColorModeValue("gray.600", "auto")}>Quantity </Text>
                <Text fontWeight="semibold">
                  {formValues.quantity_used} {inventoryItem.inventory.unit_of_measurement || "unit"}
                </Text>
              </HStack>
              <Divider />
            </>
          )}
          {inventoryItem.inventory.is_consumable && (
            <>
              <HStack w={"100%"} justify={"space-between"} mb={2}>
                <Text color={useColorModeValue("gray.600", "auto")}>
                  Cost per {humanize(inventoryItem.inventory.unit_of_measurement || "unit")}
                </Text>
                <Tooltip label="This item is $0.00 because it is a backfilled item">
                  <MoneyText fontWeight="semibold" money={unitCost} />
                </Tooltip>
              </HStack>
              <Divider />
            </>
          )}
          <HStack w={"100%"} justify={"space-between"} mb={2}>
            <Text color={useColorModeValue("gray.600", "auto")}>Total Cost</Text>
            <Tooltip label="This item is $0.00 because it is a backfilled item">
              <MoneyText fontWeight="semibold" money={totalCost} />
            </Tooltip>
          </HStack>
        </Flex>
      </Flex>
    </>
  );
};
