import React, { useMemo, useState } from "react";
import { useCollection, buildFacets, CollectionPage } from "@sciencecorp/helix-components";
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useSearchSitesQuery, useDeleteSiteQuery, SitePartialData } from "../../api/sites";
import { AddIcon } from "@chakra-ui/icons";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SiteModal } from "./SiteModal";

const bodyOptions = {
  "Number of Employees": {
    range: {
      field: "site_users_count",
      ranges: [
        { from: 1, to: 10, key: "1-10 Employees" },
        { from: 11, to: 50, key: "11-50 Employees" },
        { from: 51, to: 100, key: "51-100 Employees" },
        { from: 101, to: 500, key: "101-500 Employees" },
        { from: 501, key: "More than 500 Employees" },
      ],
    },
  },
};

export const Sites = () => {
  const currentUser = useCurrentUserQuery();
  const isAdmin = userHasRole(currentUser, "hr_admin");
  const { search, debouncedSearch } = useDebouncedSearch();
  const { onPagination, onOrder, order, pagination, filters, facets, onFacets } = useCollection();
  const [alertShow, setAlertShow] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeSite, setActiveSite] = useState<SitePartialData | null>(null);
  const { mutate: deleteSite } = useDeleteSiteQuery();

  const { data, isLoading } = useSearchSitesQuery({
    term: search || "*",
    pagination,
    order,
    bodyOptions,
    filters,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns = useMemo(
    () =>
      isAdmin
        ? [
            {
              label: "Sites",
              render: (site: SitePartialData) => <Text fontWeight="semibold">{site.name}</Text>,
            },
            {
              label: "Address",
              render: (site: SitePartialData) =>
                `${site.site_address}, ${site.site_city} ${site.site_state}`,
            },
            { label: "No. of Employees", render: (site: SitePartialData) => site.site_user_count },
            {
              label: "",
              render: (site: SitePartialData) => (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<BsThreeDotsVertical />}
                    size="sm"
                    bg="transparent"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setActiveSite(site);
                        onOpen();
                      }}>
                      Edit Site
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAlertShow(`You have successfully deleted ${site.name}`);
                        deleteSite(site.id);
                      }}>
                      Delete Site
                    </MenuItem>
                  </MenuList>
                </Menu>
              ),
            },
          ]
        : [
            {
              label: "Sites",
              render: (site: SitePartialData) => <Text fontWeight="semibold">{site.name}</Text>,
            },
            {
              label: "Address",
              render: (site: SitePartialData) =>
                `${site.site_address}, ${site.site_city} ${site.site_state}`,
            },
            { label: "No. of Employees", render: (site: SitePartialData) => site.site_user_count },
          ],
    [isAdmin, data]
  );

  return (
    <>
      <Flex justify="center">
        {alertShow && (
          <Alert status="success" width="max-content">
            <AlertIcon />
            {alertShow}
          </Alert>
        )}
      </Flex>
      <HStack width="100%" justify="space-between" py={4}>
        <Heading>Sites</Heading>
        {isAdmin && (
          <Button colorScheme="teal" leftIcon={<AddIcon />} onClick={onOpen}>
            Create Site
          </Button>
        )}
      </HStack>

      <CollectionPage
        facets={populatedFacets}
        facetsProps={{ debouncedSearch: debouncedSearch, background: true, search: true }}
        onFacets={onFacets}
        items={data?.results || []}
        isLoading={isLoading}
        pagination={data?.pagination || pagination}
        onOrder={onOrder}
        order={order}
        onPagination={onPagination}
        columns={columns}
      />
      <SiteModal
        isOpen={isOpen}
        onClose={onClose}
        site={activeSite}
        setAlertShow={setAlertShow}
        setActiveSite={setActiveSite}
      />
    </>
  );
};
