import Big from "big.js";
import _ from "lodash";
import { z } from "zod";
import { ExchangeRate } from "../api/exchange_rate";

export const currencySchema = z.object({
  name: z.string(),
  code: z.string(),
  symbol: z.string(),
  subunit_to_unit: z.number().transform((val) => new Big(val)),
  decimal_digits: z.number(),
});

export const moneySchema = z
  .object({
    currency_iso: z.string(),
    cents: z.number(),
  })
  .transform((val) => Money.fromJSON(val));

export type Currency = z.infer<typeof currencySchema>;

export const supportedCurrencies: Record<string, Currency> = {
  USD: {
    name: "US Dollars",
    code: "USD",
    symbol: "$",
    subunit_to_unit: new Big(100),
    decimal_digits: 2,
  },
  AUD: {
    name: "Australian Dollars",
    code: "AUD",
    symbol: "A$",
    subunit_to_unit: new Big(100),
    decimal_digits: 2,
  },
  EUR: { name: "Euro", code: "EUR", symbol: "€", subunit_to_unit: new Big(100), decimal_digits: 2 },
  NZD: {
    name: "New Zealand Dollars",
    code: "NZD",
    symbol: "NZ$",
    subunit_to_unit: new Big(100),
    decimal_digits: 2,
  },
  JPY: {
    name: "Japanese Yen",
    code: "JPY",
    symbol: "¥",
    subunit_to_unit: new Big(1),
    decimal_digits: 0,
  },
};

export type CurrencyInput = string | Currency | Money;

export type CompactOption = "always" | "never" | "over1000";
export type MoneyFormatOptions = {
  compact: CompactOption;
  hideCents: boolean;
};

type StringNumeric = `${number}` | "Infinity" | "-Infinity" | "+Infinity";

export class Money {
  private _currency: Currency;
  private _cents: Big;

  // Use fromMajorUnits or fromMinorUnits to create a new money object
  private constructor(cents: Big, currency: Currency) {
    this._cents = cents;
    this._currency = currency;
  }

  get cents(): Big {
    return this._cents;
  }

  /**
   * Don't use this unless you absolutely must.
   * Returns the amount in major units (e.g. dollars) as a Big.js object
   *
   * @returns Big - the amount in major units
   */
  get majorUnits(): Big {
    return this._cents.div(this._currency.subunit_to_unit);
  }

  get currency(): Currency {
    return this._currency;
  }

  majorUnitString(): StringNumeric {
    return this._cents
      .div(this._currency.subunit_to_unit)
      .toFixed(this._currency.decimal_digits) as StringNumeric;
  }

  format(opts?: Partial<MoneyFormatOptions>): string {
    const formatter = this.buildMoneyFormat(opts?.hideCents, opts?.compact);
    return formatter.format(this.majorUnits.toNumber());
  }

  isPos(): boolean {
    return this._cents.gt(0);
  }

  isNeg(): boolean {
    return this._cents.lt(0);
  }

  isZero(): boolean {
    return this._cents.eq(0);
  }

  eq(other: Money): boolean {
    return this._cents.eq(other._cents) && this._currency.code === other._currency.code;
  }

  gt(other: Money): boolean {
    this.assertCurrencyMatch(other);
    return this._cents.gt(other._cents);
  }

  gte(other: Money): boolean {
    this.assertCurrencyMatch(other);
    return this._cents.gte(other._cents);
  }

  lt(other: Money): boolean {
    this.assertCurrencyMatch(other);
    return this._cents.lt(other._cents);
  }

  lte(other: Money): boolean {
    this.assertCurrencyMatch(other);
    return this._cents.lte(other._cents);
  }

  add(...monies: Money[]): Money {
    return monies.reduce((sum, current) => {
      sum.assertCurrencyMatch(current);
      return new Money(sum._cents.plus(current._cents), sum._currency);
    }, this);
  }

  times(factor: number | Big): Money {
    return Money.fromMinorUnits(this._cents.times(factor).round(), this._currency.code);
  }

  div(divisor: number | Big): Money {
    return Money.fromMinorUnits(this._cents.div(divisor).round(), this._currency.code);
  }

  convert(fxRate: ExchangeRate): Money {
    if (fxRate.from_currency !== this._currency.code) {
      throw new Error(
        "Provided exchange rate does not match the currency of the money object being converted."
      );
    }
    const ogCurrency: Currency = this._currency;
    const currency: Currency = Money.currencyFromInput(fxRate.to_currency);

    const subUnitAdjustment = ogCurrency.subunit_to_unit.div(currency.subunit_to_unit);

    const minorUnits = this._cents.times(fxRate.rate).round();

    return Money.fromMinorUnits(minorUnits.div(subUnitAdjustment).round(), currency);
  }

  subtract(...monies: Money[]): Money {
    return monies.reduce((difference, current) => {
      difference.assertCurrencyMatch(current);
      return new Money(difference._cents.minus(current._cents), difference._currency);
    }, this);
  }

  toJSON(): { cents: number; currency_iso: string } {
    return {
      cents: this._cents.toNumber(),
      currency_iso: this._currency.code,
    };
  }

  static fromJSON({ cents, currency_iso }: { cents: number; currency_iso: string }): Money {
    return Money.fromMinorUnits(cents, currency_iso);
  }

  static max(...monies: Money[]): Money {
    return monies.reduce((max, current) => (current.gt(max) ? current : max));
  }

  static min(...monies: Money[]): Money {
    return monies.reduce((min, current) => (current.lt(min) ? current : min));
  }

  static sum(first: Money, ...monies: Money[]): Money {
    if (monies.length === 0) {
      return first;
    }
    return monies.reduce((sum, current) => {
      return sum.add(current);
    }, first);
  }

  static pct(
    top: Money,
    bottom: Money,
    config: { min?: Big | number; max?: Big | number } = {}
  ): Big {
    const bigMin = new Big(config.min || new Big(0));
    const bigMax = new Big(config.max || new Big(100));

    top.assertCurrencyMatch(bottom);
    if (bottom.isZero()) {
      return bottom._cents;
    }
    const percentage = top._cents.div(bottom._cents).times(100);
    if (percentage.lt(bigMin)) {
      return bigMin;
    } else if (percentage.gt(bigMax)) {
      return bigMax;
    } else {
      return percentage;
    }
  }

  private static currencyFromInput(currencyInput: CurrencyInput): Currency {
    let currency: Currency;
    if (typeof currencyInput === "string") {
      currency = Money.findCurrency(currencyInput);
    } else if (_.has(currencyInput, "currency")) {
      currency = (currencyInput as Money).currency;
    } else if (_.has(currencyInput, "_currency")) {
      currency = (currencyInput as Money).currency;
    } else if (_.has(currencyInput, "code")) {
      currency = supportedCurrencies[(currencyInput as Currency).code];
    } else {
      console.error(currencyInput);
      throw new Error(`Invalid currency input: ${currencyInput}`);
    }

    return currency;
  }

  static fromMajorUnitString(majorUnitString: string, currencyInput: CurrencyInput): Money {
    const currency: Currency = Money.currencyFromInput(currencyInput);

    const majorUnits = new Big(majorUnitString);
    const minorUnits = majorUnits.times(currency.subunit_to_unit);

    if (!minorUnits.eq(minorUnits.round())) {
      throw new Error(
        `Fractional subunits are not allowed. Input: ${majorUnitString} ${currencyInput}`
      );
    }

    return new Money(minorUnits, currency);
  }

  static fromMinorUnits(minorUnits: number | string | Big, currencyInput: CurrencyInput): Money {
    const currency: Currency = Money.currencyFromInput(currencyInput);
    const bigMinorUnits = new Big(minorUnits);

    if (!bigMinorUnits.eq(bigMinorUnits.round())) {
      throw new Error(`Fractional subunits are not allowed. Input: ${minorUnits} ${currency.code}`);
    }

    return new Money(bigMinorUnits, currency);
  }

  static zero(currencyInput: CurrencyInput = "USD"): Money {
    return Money.fromMinorUnits(0, currencyInput);
  }

  // Private methods
  private assertCurrencyMatch(other: Money): void {
    if (this._currency.code !== other._currency.code && !this.isZero() && !other.isZero()) {
      throw new Error(
        `Cannot operate on money of different currencies: ${this.format()} and ${other.format()}`
      );
    }
  }

  private buildMoneyFormat(
    hideCents: boolean = false,
    compact: CompactOption = "over1000"
  ): Intl.NumberFormat {
    let minimumFractionDigits: number;
    let maximumFractionDigits: number;
    let notation: "standard" | "compact" = "standard";
    if (
      compact === "always" ||
      (compact === "over1000" && this._cents.abs().gte(new Big(100000)))
    ) {
      minimumFractionDigits = 1;
      maximumFractionDigits = 1;
      notation = "compact";
    } else {
      minimumFractionDigits = this._currency.decimal_digits;
      maximumFractionDigits = this._currency.decimal_digits;
    }
    if (hideCents) {
      minimumFractionDigits = 0;
      maximumFractionDigits = 0;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this._currency.code,
      minimumFractionDigits,
      maximumFractionDigits,
      notation,
    });
  }

  private static findCurrency(currencyCode: string): Currency {
    const foundCurrency = supportedCurrencies[currencyCode];
    if (foundCurrency != undefined && foundCurrency != null) {
      return foundCurrency;
    } else {
      throw new Error(`Unable find a supported currency matching ${currencyCode}`);
    }
  }
}
