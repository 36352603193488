import React, { useState, useEffect } from "react";
import { Flex, Box, Spinner, FormControl, FormLabel, Divider } from "@chakra-ui/react";
import { Control, UseFormSetValue } from "react-hook-form";
import { useSearchPurchases } from "../../../../../api/purchase";
import { Select, Option } from "@sciencecorp/helix-components";
import { InventoryShowData } from "../../../../../api/inventory";
import { RestockFormValues } from "./util";
import { PurchaseLineItemsSource } from "./PurchaseLineItemsSource";

type SelectPurchaseSourceProps = {
  inventory: InventoryShowData;
  setValue: UseFormSetValue<RestockFormValues>;
  control: Control<RestockFormValues, any>;
  purchaseId: string | null;
  setPurchaseId: React.Dispatch<React.SetStateAction<string | null>>;
  purchaseResults: Option[];
  setPurchaseResults: React.Dispatch<React.SetStateAction<Option[]>>;
};

export const SelectPurchaseSource = ({
  inventory,
  setValue,
  control,
  purchaseId,
  setPurchaseId,
  purchaseResults,
  setPurchaseResults,
}: SelectPurchaseSourceProps) => {
  const [search, setSearch] = useState("");

  const { data, isSuccess, isLoading } = useSearchPurchases({
    term: search,
    pagination: { per_page: 20 },
    filters: { delivered: true },
  });

  useEffect(() => {
    if (isSuccess && data.results.length > 0) {
      setPurchaseResults(
        data.results.map((purchase) => ({
          value: purchase.id.toString(),
          label: `Purchase #${purchase.id.toString()}: ${
            purchase.vendor?.name
          } - ${purchase.description.slice(0, 40)}`,
        }))
      );
    }
  }, [search, isSuccess]);

  return (
    <Flex direction="column" width="100%">
      <Box p={4}>
        <FormControl>
          <FormLabel>Purchase Request Source for {inventory.name}</FormLabel>
          <Select
            options={purchaseResults}
            onChange={(value) => {
              setPurchaseId(value.toString());
              setSearch(value.toString());
              setValue("purchase_line_item", null);
            }}
            value={purchaseId}
            onInputChange={(value) => {
              setSearch(value);
            }}
            noOptionsMessage={() =>
              isLoading ? (
                <Spinner />
              ) : search ? (
                "No purchases found"
              ) : (
                "Type a purchase number to search"
              )
            }
          />
        </FormControl>
      </Box>
      <PurchaseLineItemsSource
        setValue={setValue}
        control={control}
        purchaseId={Number(purchaseId)}
      />
    </Flex>
  );
};
