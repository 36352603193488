import { Box, useColorModeValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter, VictoryTheme } from "victory";

import { MilestoneShowData } from "../../api/milestone";

interface TimeData {
  time: number;
  value: number;
}

export const PredictionsChart = (props: { milestone: MilestoneShowData }) => {
  const { milestone } = props;

  const teamMemberIds = useMemo(() => {
    return (milestone.team?.team_memberships || []).map((membership) => membership.user.id);
  }, [milestone]);

  const now = Date.now() / 1000;
  const teamSeries: TimeData[] = [];
  const nonTeamSeries: TimeData[] = [];

  milestone.milestone_predictions.forEach((prediction) => {
    const completion = Date.parse(prediction.completion_date) / 1000;
    const predictedAt = Date.parse(prediction.created_at) / 1000;

    if (teamMemberIds.includes(prediction.user.id)) {
      teamSeries.push({
        time: completion,
        value: now - predictedAt,
      });
    } else {
      nonTeamSeries.push({
        time: completion,
        value: now - predictedAt,
      });
    }
  });

  const axisStyle = {
    axis: { stroke: "#A0AEC0" },
    grid: { stroke: "transparent" },
    ticks: { stroke: "#A0AEC0", size: 3 },
    tickLabels: { fontSize: 6, fontFamily: "sans-serif" },
  };

  return milestone.milestone_predictions.length > 0 ? (
    <Box width="100%">
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 10 }}
        height={30}
        padding={{
          top: 10,
          bottom: 20,
          left: 2,
          right: 8,
        }}>
        <VictoryAxis
          scale="time"
          style={axisStyle}
          tickFormat={(t) => new Date(t * 1000).toLocaleDateString()}
          tickCount={8}
          tickLabelComponent={<VictoryLabel dy={-4} />}
        />
        <VictoryAxis
          dependentAxis
          style={{ ...axisStyle, ticks: { stroke: "transparent" } }}
          tickFormat={[]}
        />
        <VictoryScatter
          data={teamSeries}
          x="time"
          y="value"
          style={{
            data: {
              fill: useColorModeValue("white", "#1A202C"),
              stroke: "#48BB78",
              strokeWidth: 2,
            },
          }}
        />
        <VictoryScatter
          data={nonTeamSeries}
          x="time"
          y="value"
          style={{
            data: {
              fill: useColorModeValue("white", "#1A202C"),
              stroke: "#ECC94B",
              strokeWidth: 2,
            },
          }}
        />
      </VictoryChart>
    </Box>
  ) : null;
};
