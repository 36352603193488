import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  useDisclosure,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  StatGroup,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { ConfirmationModal, Header, SplitPage } from "@sciencecorp/helix-components";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { useGetInventory, useSearchInventories, useUpdateInventory } from "../../../api/inventory";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { useUserOptions } from "../../../api/options";
import { NewInventoryModal } from "../components/NewInventoryModal";
import { SidebarList } from "../../Credentials/util";
import { StockLocations } from "./components/StockLocations";
import { NewInventoryItemModal } from "./components/RestockModal";
import { inventoryStatusColor } from "../util";
import { humanize, titleize } from "inflection";
import { useParams } from "react-router-dom";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import _ from "lodash";
import { AssignmentTable } from "./components/AssignmentTable";
import { printBarcodes } from "./components/RestockModalComponents/util";
import { BsArchive } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import { InventoryDetailInformation } from "./components/InventoryDetailInformation";
interface QRCodeComponentProps {
  value: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value }) => {
  return (
    <div id="qrcode">
      <QRCodeSVG value={value} />
    </div>
  );
};

export default QRCodeComponent;

export const InventoryPage = () => {
  const { id } = useParams();

  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager");
  const { mutate: updateInventory } = useUpdateInventory();
  const { debouncedSearch, search } = useDebouncedSearch();
  const navigate = useNavigate();
  const {
    data: inventory,
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    isSuccess: isSuccessInventory,
  } = useGetInventory(Number(id));

  const userOptions = useUserOptions();
  const { data: inventoryOptions } = useSearchInventories({
    term: search || "*",
    pagination: { per_page: -1 },
    order: { name: "asc" },
    filters: {
      is_archived: false,
    },
  });

  const toast = useToast();

  const {
    isOpen: archiveConfirmationModalIsOpen,
    onOpen: archiveConfirmationModalOnOpen,
    onClose: archiveConfirmationModalOnClose,
  } = useDisclosure();

  const {
    isOpen: archiveDenyModalIsOpen,
    onOpen: archiveDenyModalOnOpen,
    onClose: archiveDenyModalOnClose,
  } = useDisclosure();

  const {
    isOpen: restoreConfirmationModalIsOpen,
    onOpen: restoreConfirmationModalOnOpen,
    onClose: restoreConfirmationModalOnClose,
  } = useDisclosure();

  const handleArchiveSuccess = () => {
    archiveConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} to the archive.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const handleRestoreSuccess = () => {
    restoreConfirmationModalOnClose();
    toast({
      title: "Successful!",
      description: `You sent ${inventory ? inventory.name : "the item"} back to the item list.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/inventory/items");
  };

  const qrCodesToPrint =
    inventory?.inventory_item_barcodes?.map((item) => ({
      barcode_code: item.barcode_code,
      id: item.id.toString(),
    })) || [];

  const handlePrintBarcodes = () => {
    if (qrCodesToPrint.length === 0) {
      toast({
        title: "Error Printing QR Codes",
        description: `There are no available items to print QR codes for`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      printBarcodes(qrCodesToPrint);
    }
  };

  if (isLoadingInventory) {
    return <Spinner />;
  }

  if (isErrorInventory) {
    return <Text>Error loading inventory item</Text>;
  }

  const actions = isInventoryManager
    ? inventory.stock > 0
      ? [
          <ButtonGroup>
            <NewInventoryModal inventory={inventory} />
            <Menu>
              <MenuButton as={IconButton} icon={<FaEllipsisV />} />
              <MenuList>
                <MenuItem icon={<FaPrint />} onClick={handlePrintBarcodes}>
                  Print QR Codes
                </MenuItem>
                <MenuItem
                  icon={<BsArchive />}
                  onClick={() => {
                    archiveConfirmationModalOnOpen();
                  }}>
                  Send to Archive
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>,
        ]
      : [
          <ButtonGroup variant="outline" isAttached={true}>
            <NewInventoryModal inventory={inventory} />
            <Menu>
              <MenuButton as={IconButton} icon={<FaEllipsisV />} />
              <MenuList>
                <MenuItem
                  icon={<BsArchive />}
                  onClick={() => {
                    archiveConfirmationModalOnOpen();
                  }}>
                  Send to Archive
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>,
        ]
    : [];

  const statusLabel = inventory.is_archived
    ? "Unavailable"
    : titleize(humanize(inventory.status || ""));
  const statusColorScheme = inventory.is_archived
    ? "red"
    : inventoryStatusColor(inventory.status || "");

  return isSuccessInventory ? (
    <SplitPage
      sidebar={
        <SidebarList
          data={inventoryOptions?.results || []}
          title="Inventory Items"
          breadcrumbTitle="Back to Inventory"
          debouncedSearch={debouncedSearch}
          url={"/inventory/items"}
          selectedItemId={inventory.id}
        />
      }
      sidebarWidth="350px"
      sidebarWidthXL="400px"
      breakpoint="md"
      main={
        <>
          {inventory.is_archived && (
            <Alert status="warning" variant="subtle" flexDirection="row" alignItems="center" mb={4}>
              <AlertIcon />
              <Box>
                <AlertTitle mr={2}>Archived Item</AlertTitle>
                <AlertDescription>
                  This item is in the inventory archive. Restore this item to place it back on the
                  item list.
                </AlertDescription>
              </Box>
              <Button onClick={restoreConfirmationModalOnOpen} colorScheme="orange" ml="auto">
                Restore Item
              </Button>
            </Alert>
          )}
          <Stack
            direction={{ base: "column", md: "column" }}
            width="100%"
            align="start"
            spacing={4}>
            {inventory && (
              <Flex direction="column" width="100%" justifyContent="space-between">
                <Header
                  title={inventory.name}
                  badge={{
                    label: statusLabel,
                    colorScheme: statusColorScheme,
                  }}
                  actions={actions}
                />
              </Flex>
            )}

            <Stack direction={{ base: "row", md: "column" }} justify="space-between" width="100%">
              <Flex
                flex="1"
                direction={["column", "row"]}
                w="100%"
                border={"1px"}
                borderColor="chakra-border-color"
                borderRadius="md"
                justifyContent="space-between">
                <StatGroup
                  p={6}
                  borderRightWidth={["0", "1px"]}
                  borderBottomWidth={["1px", "0"]}
                  w="100%">
                  {!inventory.is_consumable ? (
                    <>
                      <Stat p={2}>
                        <StatLabel w="max-content">Total Inventory Items</StatLabel>
                        <StatNumber fontWeight="medium" fontSize="xx-large">
                          {inventory.total_inventory_items} {inventory.unit_of_measurement || ""}
                        </StatNumber>
                      </Stat>
                      <Stat p={2}>
                        <StatLabel w="max-content">Available to Checkout</StatLabel>
                        <StatNumber fontWeight={"medium"} fontSize="xx-large">
                          {inventory.stock} {inventory.unit_of_measurement || ""}
                        </StatNumber>
                      </Stat>
                    </>
                  ) : (
                    <Stat p={2}>
                      <StatLabel w="max-content">In Stock</StatLabel>
                      <StatNumber fontWeight="medium" fontSize="xx-large">
                        {inventory.total_quantity_stock} {inventory.unit_of_measurement || "units"}
                      </StatNumber>
                    </Stat>
                  )}
                </StatGroup>
                <HStack p={6}>
                  <NewInventoryItemModal inventory={inventory} />
                </HStack>
              </Flex>
            </Stack>

            <InventoryDetailInformation
              inventory={inventory}
              isInventoryManager={isInventoryManager}
            />
            <StockLocations inventory={inventory} />
            {isInventoryManager && !inventory.is_consumable && (
              <AssignmentTable inventoryId={inventory.id} />
            )}
          </Stack>
          <ConfirmationModal
            colorScheme="teal"
            children={`Restoring this item will place it back on the item list.`}
            header="Restore Inventory Item"
            isOpen={restoreConfirmationModalIsOpen}
            onClick={() => {
              updateInventory(
                { id: inventory.id, is_archived: false },
                { onSuccess: () => handleRestoreSuccess() }
              );
            }}
            confirmText="Confirm"
            onClose={restoreConfirmationModalOnClose}></ConfirmationModal>
          <ConfirmationModal
            colorScheme="teal"
            children={
              <span>
                Are you sure you want to archive <strong>{inventory.name}</strong>? This inventory
                will no longer be available for checkout.
              </span>
            }
            header="Archive Inventory Item"
            isOpen={archiveConfirmationModalIsOpen}
            onClick={() => {
              updateInventory(
                { id: inventory.id, is_archived: true },
                { onSuccess: () => handleArchiveSuccess() }
              );
            }}
            confirmText="Confirm Archive"
            onClose={archiveConfirmationModalOnClose}></ConfirmationModal>
          <ConfirmationModal
            colorScheme="teal"
            children={`You can only send items with no stock and pending items to the archive.`}
            header="Send To Archive"
            isOpen={archiveDenyModalIsOpen}
            onClick={() => {
              archiveDenyModalOnClose();
            }}
            onClose={archiveDenyModalOnClose}></ConfirmationModal>
        </>
      }
    />
  ) : isLoadingInventory ? (
    <Spinner />
  ) : (
    <Text>Error loading inventory item</Text>
  );
};
