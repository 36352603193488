import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import {
  TeamMembershipPartialData,
  useDeleteTeamMembership,
  useUpdateTeamMembership,
} from "../../../../api/team";

type Props = {
  team_id: number;
  membership: TeamMembershipPartialData;
  editable?: boolean;
};

export const TeamMemberCard: React.FC<Props> = ({ team_id, membership, editable }) => {
  const { mutate: updateTeamMembership } = useUpdateTeamMembership(team_id);

  return (
    <LinkBox as={Card} variant="outline" border="none" width="100%" bg="transparent">
      <CardBody paddingInlineStart={0} p={2} paddingInlineEnd={1}>
        <Flex alignItems="center" justify="space-around">
          <Flex flex="1" gap="4" alignItems="center">
            <Avatar
              name={membership.user.name}
              src={membership.user.picture_uri}
              borderColor={membership.is_lead ? "teal.400" : "none"}
              borderWidth={membership.is_lead ? 3 : 0}
              boxSize={10}
            />
            <VStack align="start" spacing={1}>
              <LinkOverlay as={RouterLink} to={`/users/${membership.user.id}`}>
                <Heading size="sm" color={useColorModeValue("gray.700", "gray.100")}>
                  {membership.user.name}
                </Heading>
              </LinkOverlay>
              {membership.is_lead ? <Badge colorScheme="teal">Lead</Badge> : null}
            </VStack>
          </Flex>

          {editable && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BsThreeDotsVertical size={20} />}
                size="sm"
                variant="ghost"
              />
              <MenuList alignItems="center">
                {membership.is_lead ? (
                  <MenuItem
                    icon={<CloseIcon />}
                    onClick={() => updateTeamMembership({ ...membership, is_lead: false })}>
                    Remove Lead
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={<AddIcon />}
                    onClick={() => updateTeamMembership({ ...membership, is_lead: true })}>
                    Make Lead
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </CardBody>
    </LinkBox>
  );
};
