import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Stack,
  Flex,
  HStack,
  useColorModeValue,
  Spacer,
  Text,
  Box,
  Divider,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { SpendingAuthorityPreview } from "../../Purchasing/SpendingAuthoritySelectTree";
import { DeleteIcon } from "@chakra-ui/icons";
import { IoBagCheckOutline } from "react-icons/io5";
import { MdCurrencyExchange, MdDeleteOutline, MdOutlineDone } from "react-icons/md";

export const SelectPurpose = ({ inventoryItem, control, setValue }) => {
  const purposeOptions = [
    {
      label: "Check out to a Spending Authority",
      value: "spending_authority",
      icon: <MdCurrencyExchange />,

      enabled: true,
    },
    {
      label: "Mark as used",
      value: "used",
      icon: <MdOutlineDone />,

      enabled: inventoryItem.inventory.is_consumable,
    },
    { label: "Throw out as waste", value: "waste", icon: <MdDeleteOutline />, enabled: true },
  ].filter((option) => option.enabled);
  return (
    <Controller
      name="purpose"
      control={control}
      render={({ field }) => (
        <Stack direction="column" gap={3}>
          {inventoryItem.spending_authority_id && inventoryItem.spending_authority_type && (
            <Flex direction="column" gap={3}>
              <Text>
                <Box as="span" fontWeight="bold">
                  {inventoryItem.inventory.name}
                </Box>{" "}
                is currently checked out to:
              </Text>
              <Box>
                <SpendingAuthorityPreview
                  outsideOfAttributesTable
                  spendingAuthorityType={inventoryItem.spending_authority_type}
                  spendingAuthorityId={inventoryItem.spending_authority_id}
                  maxWidth="30ch"
                />
              </Box>
              <Divider />
            </Flex>
          )}
          <Text fontWeight="semibold">
            What would you like to do with {inventoryItem.inventory.name}?
          </Text>
          {purposeOptions.map((option) => (
            <HStack
              _hover={{ cursor: "pointer" }}
              p={3}
              bg={
                field.value === option.value
                  ? useColorModeValue("teal.50", "teal.500")
                  : "chakra-bg-color"
              }
              spacing={6}
              border="1px"
              borderRadius="md"
              borderColor={
                field.value === option.value
                  ? useColorModeValue("teal.200", "teal.400")
                  : "chakra-border-color"
              }
              onClick={() => {
                setValue("purpose", option.value);
              }}>
              {option.icon}
              <Text fontSize="sm" fontWeight="medium">
                {option.label}
              </Text>
              <Spacer />
              {field.value === option.value ? (
                <CheckCircleIcon color={useColorModeValue("teal.500", "teal.200")} boxSize={7} />
              ) : (
                <Box border="2px" borderRadius="full" borderColor="chakra-border-color" p={3} />
              )}
            </HStack>
          ))}
        </Stack>
      )}
    />
  );
};
