import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { currencySchema, moneySchema } from "../helpers/Money";
import { approvableSchema } from "./approvals";
import { blobFileSchema } from "./blob_files";
import { BUDGET_ITEMS_BASE_URL } from "./budget_items";
import {
  SearchParams,
  aggregationSchema,
  indexParams,
  paginationSchema,
  searchParams,
} from "./collection_types";
import { expenditureSchema } from "./expenditures";
import { paymentScheduleShowSchema } from "./payment_schedule";
import { PurchaseLineItemCreateParams, purchaseLineItemShowSchema } from "./purchase_line_item";
import { PURCHASE_PAYMENTS_BASE_URL, purchasePaymentShowSchema } from "./purchase_payment";
import { PURCHASE_RETURNS_BASE_URL, purchaseReturnsShowSchema } from "./purchase_returns";
import { SpendingAuthorityReference } from "./spending_authority";
import { spendingAuthorityChangeRequestSchema } from "./spending_authority_change_requests";
import { timelineEventArraySchema } from "./timeline_events";
import { userMinimalSchema } from "./user";
import { vendorSchema } from "./vendor";
import { virtualCardSchema } from "./virtual_cards";
import { zodParse } from "./zodParse";
import { PURCHASE_REFUNDS_BASE_URL } from "./purchase_refunds";

export const PURCHASES_BASE_URL = "purchases";
export const VIRTUAL_CARDS_BASE_URL = "virtual_cards";
export const CARD_INFORMATION_REQUESTS_BASE_URL = "card_information_requests";

const shippingPriority = z.enum(["standard", "expedited", "overnight", "n/a"]);

export const closedStatuses = [
  "complete",
  "awaiting_delivery",
  "delivered",
  "awaiting_purchase",
  "awaiting_payment",
  "declined",
];

/** purchase */
const purchaseSchema = z
  .object({
    id: z.number(),
    status: z.string(),
    vendor_id: z.number().optional(),
    description: z.string().optional(),
    order_number: z.string().nullable(),
    tracking_number: z.string().nullable(),
    estimated_ship_date: z.string().nullable(),
    accounting_category: z.string().nullable(),
    is_deleted: z.boolean(),
    created_at: z.string(),
    ordered_at: z.string().nullable(),
    completed_payment_at: z.string().nullable(),
    delivered_at: z.string().nullable(),
    estimated_delivery_date: z.string().nullable(),
    cost_review_status: z.string(),
    purchase_type: z.string(),
    team_id: z.number().nullable(),
    site_id: z.number().optional(),
    ops_treated: z.boolean(),
    user_id: z.number(),
    wafer_services_tool: z.string().optional(),
    shipping_priority: shippingPriority,
    project_id: z.number().nullable(),
    is_private: z.boolean(),
    currency: z.string(),
  })
  .extend(expenditureSchema.shape);

export const purchaseIndexItemSchema = z.object({
  id: z.number(),
  status: z.string(),
  purchase_type: z.string(),
  description: z.string(),
  ordered_at: z.string().nullable(),
  completed_payment_at: z.string().nullable(),
  delivered_at: z.string().nullable(),
  created_at: z.string(),
  estimated_ship_date: z.string().nullable(),
  user: z.lazy(() => userMinimalSchema.nullable()),
  team: z.object({ id: z.number(), name: z.string() }).nullable(),
  vendor: vendorSchema
    .pick({ id: true, name: true, website: true, status: true, payment_terms: true })
    .optional(),
  amount: moneySchema,
  cost_review_status: z.string(),
  purchase_payments_paid: moneySchema,
});

export const purchaseShowSchema = purchaseSchema
  .omit({
    vendor_id: true,
    user_id: true,
    team_id: true,
  })
  .extend(approvableSchema.shape)
  .extend({
    vendor: vendorSchema
      .pick({ id: true, name: true, website: true, status: true, payment_terms: true })
      .optional(),
    user: z.lazy(() => userMinimalSchema),
    can_act_as_lead: z.boolean(),
    team: z.object({ id: z.number(), name: z.string() }).nullable(),
    has_payment_schedule: z.boolean(),
    line_items_sum: moneySchema,
    purchase_payments_paid: moneySchema,
    total_refunded: moneySchema,
    pending_within_budget: z.boolean(),
    project_approved: z.boolean(),
    amount: moneySchema,
    last_purchase_id: z.number(),
    previous_active_purchase_id: z.number().nullable(),
    next_active_purchase_id: z.number().nullable(),
    user_can_submit: z.boolean(),
    spending_authority_change_requests: z.array(spendingAuthorityChangeRequestSchema),
    uploaded_invoices: z.array(blobFileSchema),
    uploaded_receipt: blobFileSchema.nullable(),
    uploaded_packing_slips: z.array(blobFileSchema),
    uploaded_files: z.array(blobFileSchema),
    uploaded_purchase_orders: z.array(blobFileSchema),
  });

export const purchaseIndexSchema = z.object({
  results: z.array(purchaseIndexItemSchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable().optional(),
});

export const purchasesWithStatsIndexSchema = purchaseIndexSchema.extend({
  total: moneySchema,
  total_spent: moneySchema,
  total_pending_approval: moneySchema,
});

export const cardInformationRequestSchema = z.object({
  id: z.number(),
  virtual_card_information: z.lazy(() => virtualCardSchema),
  amount: moneySchema,
  created_at: z.string().datetime(),
});

export const purchaseFinancialSchema = z.object({
  purchase_payments: z.array(purchasePaymentShowSchema),
  payment_schedule: paymentScheduleShowSchema.nullable(),
});

export type PurchaseData = z.infer<typeof purchaseSchema>;
export type PurchaseShowData = z.infer<typeof purchaseShowSchema>;
export type PurchaseIndexItemData = z.infer<typeof purchaseIndexItemSchema>;
export type PurchaseCreateParams = Pick<
  PurchaseData,
  | "vendor_id"
  | "site_id"
  | "purchase_type"
  | "ops_treated"
  | "description"
  | "user_id"
  | "wafer_services_tool"
  | "currency"
> &
  Partial<SpendingAuthorityReference> & {
    purchase_line_items?: Omit<PurchaseLineItemCreateParams, "purchase_id">[];
    files: { name: string; file: string }[];
  };

export type VirtualCardData = z.infer<typeof virtualCardSchema>;
export type CardInformationRequestData = z.infer<typeof cardInformationRequestSchema>;
export type PurchaseFinancialsData = z.infer<typeof purchaseFinancialSchema>;

export type ShippingPriority = z.infer<typeof shippingPriority>;

/** queries */
export const submitPurchase = async (purchaseId: number) => {
  const result = await api.post(`${PURCHASES_BASE_URL}/${purchaseId}/submit`);
  return zodParse(purchaseShowSchema, result.data);
};

export const approvePurchase = async (purchaseId: number) => {
  const result = await api.post(`${PURCHASES_BASE_URL}/${purchaseId}/approve`);
  return zodParse(purchaseShowSchema, result.data);
};

export const declinePurchase = async (purchaseId) => {
  const result = await api.post(`${PURCHASES_BASE_URL}/${purchaseId}/decline`);
  return zodParse(purchaseShowSchema, result.data);
};

export const getPurchase = async (id: number) => {
  const result = await api.get(`${PURCHASES_BASE_URL}/${id}`);
  return zodParse(purchaseShowSchema, result.data);
};

export const getPurchaseFinancials = async (id: number) => {
  const result = await api.get(`${PURCHASES_BASE_URL}/${id}/financials`);
  return zodParse(purchaseFinancialSchema, result.data);
};

export const getPurchaseTimelineEvents = async (id: number) => {
  const result = await api.get(`${PURCHASES_BASE_URL}/${id}/events`);
  return zodParse(timelineEventArraySchema, result.data);
};

export const getPurchaseReturns = async (id: number | null | undefined) => {
  const result = await api.get(`${PURCHASES_BASE_URL}/${id}/returns`);
  return zodParse(z.array(purchaseReturnsShowSchema), result.data);
};

export const postComment = async (purchaseId: number, richText: string, plainText: string) => {
  return await api.post(`${PURCHASES_BASE_URL}/${purchaseId}/comments`, {
    rich_text: richText,
    plain_text: plainText,
  });
};

export const getPurchaseLineItems = async (id: number | null) => {
  const result = await api.get(`${PURCHASES_BASE_URL}/${id}/line_items`);
  return zodParse(z.array(purchaseLineItemShowSchema), result.data);
};

export type PurchasesWithStatsIndex = z.infer<typeof purchasesWithStatsIndexSchema>;

export const searchPurchasesWithStats = async ({
  term,
  aggs,
  filters,
  pagination,
  order,
}: SearchParams): Promise<PurchasesWithStatsIndex> => {
  const search = searchParams({ aggs, filters, term });
  const index = indexParams({ pagination, order });
  const result = await api.post(`${PURCHASES_BASE_URL}/search/with_stats`, {
    ...index,
    ...search,
  });
  return zodParse(purchasesWithStatsIndexSchema, result.data);
};

export const searchPurchases = async ({
  aggs,
  bodyOptions,
  filters,
  pagination,
  order,
  term,
  currency,
}: SearchParams & { currency?: string }) => {
  const path = [PURCHASES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search, currency });
  return zodParse(purchaseIndexSchema, result.data);
};

export const newPurchase = async (purchase: PurchaseCreateParams) => {
  const result = await api.post(PURCHASES_BASE_URL, { purchase });
  return result.data.id;
};
export const updatePurchase = async (
  purchase: Partial<PurchaseData> & Partial<SpendingAuthorityReference>
) => {
  const result = await api.put(`${PURCHASES_BASE_URL}/${purchase.id}`, { purchase });
  return result.data;
};
export const updatePurchaseCurrency = async (purchase: Pick<PurchaseData, "id" | "currency">) => {
  const result = await api.put(`${PURCHASES_BASE_URL}/${purchase.id}/currency`, {
    currency: purchase.currency,
  });
  return result.data;
};
export const deletePurchase = async (purchaseId: number) => {
  await api.delete(`${PURCHASES_BASE_URL}/${purchaseId}`);
};

export const getVirtualCardInformation = async (id: number) => {
  const result = await api.post(`${VIRTUAL_CARDS_BASE_URL}/get_card/${id}`);
  return zodParse(virtualCardSchema, result.data);
};

export const getCardInformationRequests = async () => {
  const result = await api.get(`${CARD_INFORMATION_REQUESTS_BASE_URL}/me`);
  return zodParse(z.array(cardInformationRequestSchema), result.data);
};

/** hooks */

export const invalidatePurchases = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [PURCHASES_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [BUDGET_ITEMS_BASE_URL],
    });
    queryClient.invalidateQueries({
      queryKey: [PURCHASE_RETURNS_BASE_URL],
    });
    queryClient.invalidateQueries({
      queryKey: [PURCHASE_PAYMENTS_BASE_URL],
    });
    queryClient.invalidateQueries({
      queryKey: [PURCHASE_REFUNDS_BASE_URL],
    });
  };
};

export const useGetPurchase = (id: number) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, id],
    queryFn: () => getPurchase(id),
  });
};

export const useGetPurchaseTimelineEvents = (id: number) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, id, "events"],
    queryFn: () => getPurchaseTimelineEvents(id),
  });
};

export const useGetPurchaseFinancials = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, id, "financials"],
    queryFn: () => getPurchaseFinancials(id!),
    enabled: !!id,
  });
};

export const useGetPurchaseReturns = (id: number | undefined | null) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, id, "returns"],
    queryFn: () => getPurchaseReturns(id),
    enabled: !!id && id > 0,
  });
};

export const useSearchPurchasesWithStats = (params: SearchParams, enabled: boolean = true) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, "team_purchases", params],
    queryFn: () => searchPurchasesWithStats(params),
    enabled: enabled,
  });
};

export const useGetPurchaseLineItems = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, id, "line_items"],
    queryFn: () => getPurchaseLineItems(id!),
    enabled: !!id,
  });
};

export const useSearchPurchases = (
  params: SearchParams & { currency?: string },
  enabled: boolean = true
) => {
  return useQuery({
    queryKey: [PURCHASES_BASE_URL, params],
    queryFn: () => searchPurchases(params),
    enabled: enabled,
  });
};

export const useSubmitPurchase = () => {
  return useMutation({
    mutationFn: submitPurchase,
    onSuccess: invalidatePurchases(),
  });
};

export const useApprovePurchase = () => {
  return useMutation({
    mutationFn: approvePurchase,
    onSuccess: invalidatePurchases(),
  });
};

export const useDeclinePurchase = () => {
  return useMutation({
    mutationFn: declinePurchase,
    onSuccess: invalidatePurchases(),
  });
};

export const useNewPurchase = (onSuccessCallback?: (id?: number) => () => Promise<void>) => {
  const invFn = invalidatePurchases();
  const cbkFn = onSuccessCallback && onSuccessCallback();
  return useMutation({
    mutationFn: newPurchase,
    onSuccess: () => {
      invFn();
      cbkFn && cbkFn();
    },
  });
};
export const useUpdatePurchase = (onSuccessCallback?: (id?: number) => () => Promise<void>) => {
  return useMutation({
    mutationFn: updatePurchase,
    onSuccess: onSuccessCallback && onSuccessCallback(),
  });
};
export const useUpdatePurchaseCurrency = (
  onSuccessCallback?: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updatePurchaseCurrency,
    onSuccess: onSuccessCallback && onSuccessCallback(),
  });
};
export const useDeletePurchase = (onSuccessCallback: (id?: number) => () => Promise<void>) => {
  return useMutation({
    mutationFn: deletePurchase,
    onSuccess: onSuccessCallback(),
  });
};

export const usePostComment = (purchaseId: number) => {
  return useMutation({
    mutationFn: ({ richText, plainText }: { richText: string; plainText: string }) =>
      postComment(purchaseId, richText, plainText),
    onSuccess: invalidatePurchases(purchaseId, "events"),
  });
};

export const useGetCardInformationRequests = () => {
  return useQuery({
    queryKey: [CARD_INFORMATION_REQUESTS_BASE_URL],
    queryFn: getCardInformationRequests,
  });
};
