import React, { useRef } from "react";
import { IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

export const FileUploadButton = ({ record, updateFunction, isLoading }) => {
  // Function to trigger the file input dialog
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) =>
          updateFunction({ id: record.id, files: [...record.files, ...encodedFileObjects] })
        )
        .catch((error) => console.error(error));
    }
  };

  return (
    <>
      <IconButton
        size="sm"
        icon={<AddIcon />}
        onClick={handleClick}
        aria-label="Upload file"
        isLoading={isLoading}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        multiple={true}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
};
