import { z } from "zod";

interface AggregationQueryOption {
  where?: { [key: string]: FilterValue };
  limit?: number;
  order?: Order;
  ranges?: { from: number; to: number }[];
  min_doc_count?: number;
  source?: "string";
  date_histogram?: { field: string; interval: string };
}

export type AggregationQuery =
  | string[]
  | {
      [key: string]: AggregationQueryOption;
    };

export interface AggregationResults {
  [key: string]: {
    buckets: {
      key: string;
      doc_count: number;
    }[];
  };
}

type FilterScalar = string | number | boolean | null;
export type FilterValue = FilterScalar | string[] | number[] | boolean[] | null[];

type ConditionedFilterValue =
  | { not: FilterScalar[] }
  | { all: FilterScalar[] }
  | { like: string }
  | { ilike: string }
  | { prefix: string }
  | { exists: boolean }
  | { gt: string | number }
  | { gte: string | number }
  | { lt: string | number }
  | { lte: string | number };

export interface Filter {
  field: string;
  value: FilterValue | ConditionedFilterValue;
}

export interface Filters {
  [key: string]: FilterValue | ConditionedFilterValue | undefined | null;
}

export const orderDirectionSchema = z.enum(["asc", "desc"]);
export type OrderDirection = z.infer<typeof orderDirectionSchema>;

export const orderSchema = z.record(z.string(), orderDirectionSchema);
export type Order = z.infer<typeof orderSchema>;

// This type is for what the API accepts as params, not what it returns (see
// `paginationSchema` for that) - empty pagination is valid, but if you specify
// a page you must also specify a per_page.
export type Pagination = {
  per_page?: number;
  page?: number;
};

export interface IndexParams {
  order?: Order;
  pagination?: Pagination;
}

export interface SearchParams extends IndexParams {
  term: string;
  aggs?: AggregationQuery;
  filters?: Filters;
  bodyOptions?: any; // use elasticsearch api
}

export const indexParams = ({ order, pagination }: IndexParams) => {
  return {
    ...pagination,
    ...(order && { order }),
  };
};

export const searchParams = ({ aggs, bodyOptions, filters, term }: SearchParams) => {
  return {
    ...(aggs && { aggs }),
    ...(bodyOptions && { body_options: { aggs: bodyOptions } }),
    ...(filters && { where: filters }),
    term,
  };
};

export const aggregationSchema = z.object({
  buckets: z.array(
    z.object({
      key: z.union([z.string(), z.number()]),
      key_as_string: z.string().optional(),
      doc_count: z.number(),
    })
  ),
});
export type AggregationData = z.infer<typeof aggregationSchema>;

export const paginationSchema = z.object({
  total: z.number(),
  page: z.number(),
  per_page: z.number(),
  n_pages: z.number(),
});
export type PaginationData = z.infer<typeof paginationSchema>;
