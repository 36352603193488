import {
  Box,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Collection, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import type { CandidateIndexItem } from "../../../api/candidates";

import { CANDIDATE_DECISION_MAP } from "../utils";

export const CandidateTable = ({
  data,
  isLoading,
  onPagination,
  onOrder,
  order,
  pagination,
  currentUser,
  isRecruitingAdmin,
}) => {
  const columns = useMemo(
    () => [
      {
        label: "Candidate",
        render: (candidate: CandidateIndexItem) => (
          <HStack>
            <Link to={`/hiring/completed_interviews/${candidate.id}`}>
              <Text color={useColorModeValue("teal.600", "teal.300")}>{candidate.name}</Text>
            </Link>
            <Tag>{`${candidate.completed_candidate_feedbacks_count?.completed_feedbacks} / ${candidate.completed_candidate_feedbacks_count?.total_feedbacks}`}</Tag>
          </HStack>
        ),
      },
      {
        label: "Team",
        render: (candidate: CandidateIndexItem) =>
          candidate.budget_headcount
            ? candidate.budget_headcount.budgetable.name
            : candidate.team?.name,
      },
      {
        label: "Role",
        render: (candidate: CandidateIndexItem) =>
          candidate.budget_headcount ? candidate.budget_headcount.archetype.name : candidate.role,
      },
      {
        label: "Budget Headount",
        render: (candidate: CandidateIndexItem) =>
          candidate.budget_headcount ? (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box>
                  <RecordLink
                    identifier={candidate.budget_headcount.archetype.name}
                    link={`/hiring/roles/${candidate.budget_headcount.archetype.id}`}
                  />
                </Box>
              </PopoverTrigger>
              <PopoverContent w="max-content">
                <PopoverBody>
                  <RecordLink
                    type="Team"
                    identifier={candidate.budget_headcount.budgetable.name}
                    link={`/teams/${candidate.budget_headcount.budgetable.id}/readme`}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            "Not assigned"
          ),
      },
      {
        label: "Stage",
        render: (candidate: CandidateIndexItem) => {
          return candidate.decision ? (
            CANDIDATE_DECISION_MAP[candidate.decision] && (
              <Tag colorScheme={CANDIDATE_DECISION_MAP[candidate.decision][1]}>
                {CANDIDATE_DECISION_MAP[candidate.decision][0]}
              </Tag>
            )
          ) : (
            <Text opacity="0.7">Pending</Text>
          );
        },
      },
      {
        label: "Actions",
        render: (candidate: CandidateIndexItem) => {
          if (
            candidate.decision === "references" &&
            candidate.has_references &&
            (candidate.hiring_manager_id === currentUser?.data.id || isRecruitingAdmin)
          ) {
            return (
              <Link to={`/hiring/completed_interviews/${candidate.id}/references/`}>
                <Text color={useColorModeValue("teal.600", "teal.300")}>Submit References</Text>
              </Link>
            );
          } else if (candidate.candidate_feedback) {
            if (candidate.candidate_feedback.locked) {
              return (
                <Tag my={1} size="md" colorScheme="green">
                  Complete
                </Tag>
              );
            } else if (!candidate.candidate_feedback.keyring_message_id) {
              return (
                <Link to={`/hiring/feedback/${candidate.candidate_feedback.id}`}>
                  <Text color={useColorModeValue("teal.600", "teal.300")}>Submit Feedback</Text>
                </Link>
              );
            } else {
              return (
                <Link to={`/hiring/feedback/${candidate.candidate_feedback.id}`}>
                  <Text color={useColorModeValue("teal.600", "teal.300")}>Edit Feedback</Text>
                </Link>
              );
            }
          }
        },
      },
    ],
    [data]
  );

  return (
    <Box width="100%">
      <Collection
        key={"candidate-table"}
        columns={columns}
        isLoading={isLoading}
        items={data?.results || []}
        pagination={data?.pagination || pagination}
        order={order}
        onPagination={onPagination}
        onOrder={onOrder}
      />
    </Box>
  );
};
