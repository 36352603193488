import { Text } from "@chakra-ui/react";
import { Editable } from "@sciencecorp/helix-components";
import React, { useEffect, useState } from "react";
import { DefaultRenderOption, TreeOptionValue, TreeSelect, TreeSelectProps } from "./TreeSelect";

interface EditableTreeSelectProps<V> extends Omit<TreeSelectProps<V>, "onChange"> {
  onSubmit: (value: TreeOptionValue<V>) => void;
  preview?: (value: TreeOptionValue<V>) => JSX.Element;
  renderOptions?: (value: TreeOptionValue<V>) => JSX.Element;
  disabled?: boolean;
}

function defaultPreview<V>(value: TreeOptionValue<V>): JSX.Element {
  return <Text>{value?.toString()}</Text>;
}

export function EditableTreeSelect<V>({
  onSubmit,
  preview,
  disabled = false,
  renderOption = DefaultRenderOption,
  ...props
}: EditableTreeSelectProps<V>) {
  const [selectedValue, setSelectedValue] = useState<TreeOptionValue<V>>(
    props.defaultValue || null
  );

  useEffect(() => {
    if (props.defaultValue && !selectedValue) {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleSubmit = (value: TreeOptionValue<V>) => {
    onSubmit(value);
  };

  const handleChange = (value: TreeOptionValue<V>) => {
    if (value) {
      setSelectedValue(value);
    } else {
      setSelectedValue(null);
    }
  };

  return (
    <Editable
      // @ts-ignore
      minWidth={"100%"}
      onSubmit={() => {
        handleSubmit(selectedValue);
      }}
      disabled={disabled}
      preview={preview ? preview(selectedValue) : defaultPreview(selectedValue)}
      renderInput={(_value, _setValue, _submit) => {
        return (
          <TreeSelect
            {...props}
            onChange={handleChange}
            defaultValue={selectedValue}
            renderOption={renderOption}
          />
        );
      }}
    />
  );
}
