import React, { useEffect, useState } from "react";
import {
  Avatar,
  useColorModeValue,
  Text,
  Spinner,
  Alert,
  Flex,
  Button,
  HStack,
  Icon,
  Spacer,
  Heading,
  VStack,
  Box,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { useEventsQuery, UserEvents } from "../../../api/user";
import { MdCircle } from "react-icons/md";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { SearchIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

const NoUserEventsComponent: React.FC = ({}) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      padding={5}
      margin={[5, 5, 0]}
      borderRadius="md"
      width={["auto", "auto", "25%"]}
      backgroundColor={useColorModeValue("gray.100", "gray.700")}>
      <Heading size="sm" fontWeight="semibold">
        People
      </Heading>
      <Flex direction="column" height="100%" align="center" gap={4} justify="center">
        <Icon as={SearchIcon} fontSize="xl" color={useColorModeValue("gray.600", "gray.200")} />
        <Text
          padding="4"
          color={useColorModeValue("gray.600", "gray.400")}
          fontSize="sm"
          align="center">
          There are no birthdays or work anniversaries today.
        </Text>
      </Flex>
    </Flex>
  );
};

interface UserEventsComponentProps {
  eventsData: UserEvents;
  totalEvents: number;
}
const UserEventsComponent: React.FC<UserEventsComponentProps> = ({ eventsData, totalEvents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isBirthday = currentIndex < eventsData.birthdays.length;
  const currentEvent = isBirthday
    ? eventsData.birthdays[currentIndex]
    : eventsData.work_anniversaries[currentIndex - eventsData.birthdays.length];
  const eventType = isBirthday ? "Birthday" : "Work Anniversary";

  const goToNextUser = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % totalEvents);
  const goToPreviousUser = () =>
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalEvents) % totalEvents);

  const backgroundColor = isBirthday
    ? useColorModeValue("gray.100", "gray.700")
    : useColorModeValue("teal.50", "teal.600");

  return (
    <Flex
      direction="column"
      justify="space-between"
      padding={5}
      margin={[5, 5, 0]}
      borderRadius="md"
      width={["auto", "auto", "25%"]}
      backgroundColor={backgroundColor}>
      <Heading size="sm" fontWeight="semibold" alignSelf="flex-start" mb={4}>
        People
      </Heading>
      <VStack flex="1" justify="center" align="center">
        <Text fontWeight="semibold" mb={6}>{`Happy ${eventType}!`}</Text>
        <LinkBox>
          <LinkOverlay as={RouterLink} to={`/users/${currentEvent.id}`}>
            <VStack spacing={1}>
              <Avatar
                size="xl"
                src={currentEvent.picture_uri}
                name={`${currentEvent.name}`}
                mb={5}
              />
              <Text fontWeight="bold">{`${currentEvent.first_name} ${currentEvent.last_name}`}</Text>
              <Text
                fontWeight="medium"
                fontSize="sm"
                color={useColorModeValue("gray.500", "gray.400")}>
                {currentEvent.employee_title}
              </Text>
            </VStack>
          </LinkOverlay>
        </LinkBox>
      </VStack>
      {totalEvents > 1 && (
        <Flex width="100%" justify="center" align="center">
          <Button color={useColorModeValue("gray.500", "gray.200")} onClick={goToPreviousUser}>
            <Icon as={FiChevronLeft} />
          </Button>
          <Spacer />
          <HStack>
            {Array.from({ length: totalEvents }).map((_, index) => (
              <Icon
                as={MdCircle}
                key={index}
                onClick={() => setCurrentIndex(index)}
                color={currentIndex === index ? "teal.500" : "gray.200"}
                cursor="pointer"
                boxSize={3}
              />
            ))}
          </HStack>
          <Spacer />
          <Button color={useColorModeValue("gray.500", "gray.200")} onClick={goToNextUser}>
            <Icon as={FiChevronRight} />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const PeopleComponent: React.FC = () => {
  const { data: eventsData, isLoading, isError, error } = useEventsQuery();

  const totalEvents = eventsData
    ? eventsData.birthdays.length + eventsData.work_anniversaries.length
    : 0;

  if (isLoading) return <Spinner />;
  if (isError) return <Alert status="error">Error loading events</Alert>;
  if (totalEvents === 0) {
    return <NoUserEventsComponent />;
  }
  return <UserEventsComponent eventsData={eventsData} totalEvents={totalEvents} />;
};

export default PeopleComponent;
