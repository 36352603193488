import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  CardBody,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { MilestoneShowData } from "../../../api/milestone";
import { MilestoneStatusBadge } from "../util";
import * as S from "./styles";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router";

export const MilestoneCard: React.FC<{
  milestone: MilestoneShowData;
  displayArrow?: boolean;
  headingSize?: "sm" | "md";
  fontWeight?: "bold" | "semibold";
}> = ({ milestone, displayArrow = false, headingSize = "md", fontWeight = "bold" }) => {
  const navigate = useNavigate();
  return (
    <S.Card isDraft={milestone.status === "draft"}>
      <CardBody>
        <LinkOverlay as={RouterLink} to={`/milestones/${milestone.id}`}>
          <HStack justify="space-between" align="center">
            <Box>
              <Text fontSize="xs">{milestone?.team?.name}</Text>
              <Heading size={headingSize} fontWeight={fontWeight} mr={2}>
                {milestone.name}
              </Heading>
            </Box>
            <Spacer />
            {displayArrow ? (
              <IconButton
                icon={<Icon as={FiChevronRight} />}
                aria-label="Select milestone"
                bg="none"
                onClick={() => navigate(`/progress/${milestone.id}`)}
              />
            ) : (
              <MilestoneStatusBadge status={milestone.status ?? ""} />
            )}
          </HStack>
        </LinkOverlay>
      </CardBody>
    </S.Card>
  );
};
