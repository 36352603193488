import { Badge } from "@chakra-ui/react";
import React from "react";

export const MilestoneStatusColors = {
  draft: "gray",
  active: "teal",
  completed: "green",
  abandoned: "red",
};

export const MilestoneStatusBadge = (props: { status: string }) => {
  return (
    <Badge colorScheme={MilestoneStatusColors[props.status]} px={2} py={1} borderRadius={4}>
      {props.status}
    </Badge>
  );
};
