import React, { useEffect, useState } from "react";
import {
  Flex,
  useColorModeValue,
  Divider,
  Tag,
  Button,
  Switch,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { AttributesTable, EditableSelect } from "@sciencecorp/helix-components";
import { BudgetGroupShowData, useUpdateBudgetGroup } from "../../../../api/budget_groups";
import { MoneyText } from "../../../MoneyText";
import { useUserOptions } from "../../../../api/options";
import { useUpdateBudgetGroupApprover } from "../../../../api/budget_group_approvers";
import { BudgetGroupUserCard } from "./BudgetGroupUserCard";
import { EditAllocationModal } from "./components/EditAllocationModal";
import { RequestMoreFundingBudgetGroupModal } from "./RequestMoreFundingBudgetGroupModal";
import { EditableMoney } from "../../../EditableMoney";

export const BudgetGroupSidebar = ({
  budgetGroup,
  isFinanceAdmin,
  isBudgetGroupLead,
}: {
  budgetGroup: BudgetGroupShowData;
  isFinanceAdmin: boolean;
  isBudgetGroupLead: boolean;
}) => {
  const { mutate: updateBudgetGroup } = useUpdateBudgetGroup(budgetGroup.id);
  const { mutate: updateBudgetGroupApprover } = useUpdateBudgetGroupApprover();
  const userOptions = useUserOptions();

  const {
    isOpen: isOpenRequestMoreFundingBudgetGroupModal,
    onOpen: onOpenRequestMoreFundingBudgetGroupModal,
    onClose: onCloseRequestMoreFundingBudgetGroupModal,
  } = useDisclosure();

  return (
    <>
      <Flex direction="column" gap={3}>
        <Flex
          direction="column"
          border="1px"
          borderRadius="md"
          borderColor="chakra-border-color"
          p={4}
          gap={4}
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Text
            color={useColorModeValue("gray.600", "auto")}
            textTransform="uppercase"
            fontWeight="bold"
            fontSize="sm"
            letterSpacing="wide">
            Budget Control
          </Text>
          <Divider />
          <Flex direction="column" gap={1}>
            <Box>
              {budgetGroup.budget_group_schedule.schedule_type === "monthly" ? (
                <Tag colorScheme="blue">Recurring Payments</Tag>
              ) : (
                <Tag colorScheme="green">Single Payment</Tag>
              )}
            </Box>
            <Flex justify="space-between" w="100%">
              <Flex direction="column">
                <MoneyText
                  as="span"
                  fontSize="3xl"
                  fontWeight="semibold"
                  money={budgetGroup.budget_group_schedule.amount}
                  formatOptions={{ compact: "never", hideCents: true }}
                />
                {budgetGroup.budget_group_schedule.schedule_type === "one_time" ? (
                  <Text color={useColorModeValue("gray.500", "auto")}>
                    {budgetGroup.budget_group_schedule.start_date?.toFormat("LLL dd yyyy")} -{" "}
                    {budgetGroup.budget_group_schedule.end_date?.toFormat("LLL dd yyyy")}
                  </Text>
                ) : (
                  <Text color={useColorModeValue("gray.500", "auto")}>Monthly</Text>
                )}
              </Flex>
              {isFinanceAdmin && !budgetGroup.archived_at && (
                <EditAllocationModal budgetGroup={budgetGroup} />
              )}
            </Flex>
            {isBudgetGroupLead && (
              <Button
                isDisabled={!!budgetGroup.archived_at}
                colorScheme="teal"
                variant="outline"
                onClick={onOpenRequestMoreFundingBudgetGroupModal}>
                Request More Funding
              </Button>
            )}
          </Flex>
          <Divider />
          <AttributesTable
            attributes={[
              {
                label: "Approval Threshold",
                value: (
                  <EditableMoney
                    disabled={!isFinanceAdmin || !!budgetGroup.archived_at}
                    defaultValue={budgetGroup.purchasing_approval_threshold}
                    onSubmit={(value) => {
                      updateBudgetGroup({
                        id: budgetGroup.id,
                        purchasing_approval_threshold: value,
                      });
                    }}
                  />
                ),
              },
              {
                label: "Autonomous Spending",
                value: (
                  <Switch
                    size="sm"
                    disabled={!isFinanceAdmin || !!budgetGroup.archived_at}
                    colorScheme="teal"
                    isChecked={budgetGroup.autonomous_spending}
                    onChange={(e) =>
                      updateBudgetGroup({
                        id: budgetGroup.id,
                        autonomous_spending: e.target.checked,
                      })
                    }
                  />
                ),
              },
            ]}
          />
        </Flex>
        <Box
          p={5}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          bg={useColorModeValue("gray.50", "gray.700")}>
          <Text fontWeight="semibold" fontSize="xl" mb={4}>
            Budget Owner
          </Text>
          <EditableSelect
            persistentEdit
            options={userOptions}
            preview={
              <Box>
                <BudgetGroupUserCard budgetGroupUser={budgetGroup.lead} />
              </Box>
            }
            disabled={!isFinanceAdmin || !!budgetGroup.archived_at}
            selectedValue={budgetGroup.lead.user.id}
            onSubmit={(value) => {
              if (value) {
                updateBudgetGroupApprover({ id: budgetGroup.lead.id, user_id: Number(value) });
              }
            }}
          />
        </Box>
      </Flex>
      <RequestMoreFundingBudgetGroupModal
        isOpen={isOpenRequestMoreFundingBudgetGroupModal}
        onClose={onCloseRequestMoreFundingBudgetGroupModal}
        budgetGroup={budgetGroup}
      />
    </>
  );
};
