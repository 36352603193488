export const required = { value: true, message: "This is required" };
export const isValidEmail = (value: string) => {
  if (!value.trim()) return;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !regex.test(value) ? "Invalid email" : undefined;
};
export const isValidWebsite = (value: string) => {
  if (!value.trim()) return;
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/\w{0,})?\/?$/;
  return !regex.test(value) ? "Invalid website" : undefined;
};
