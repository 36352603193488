import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Facets, SplitPage, buildFacets, useCollection } from "@sciencecorp/helix-components";
import { FiInbox } from "react-icons/fi";
import { FaRegBell, FaRegStar } from "react-icons/fa";
import { useSearchNotifications } from "../../api/notifications";
import { useCurrentUserQuery } from "../../api/user";
import { ActionRequiredTab } from "./ActionRequiredTab";
import { AllNotificationsTab } from "./AllNotificationsTab";
import { useNavigate, useParams } from "react-router";

type Tab = {
  tabName: string;
  label: string;
  icon: JSX.Element;
  navigatePath: string;
  filters: { [key: string]: boolean };
};

const TABS: Tab[] = [
  {
    tabName: "action_required",
    label: "Action Required",
    icon: <FiInbox />,
    navigatePath: "/inbox/action_required",
    filters: { read: false, action_required: true },
  },
  {
    tabName: "all",
    label: "Notifications",
    icon: <FaRegBell />,
    navigatePath: "/inbox/all",
    filters: { action_required: false },
  },
  {
    tabName: "saved",
    label: "Saved",
    icon: <FaRegStar />,
    navigatePath: "/inbox/saved",
    filters: { action_required: false, saved: true },
  },
];

export const Notifications = () => {
  const { tab } = useParams();
  if (!tab) return null;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab);

  const { filters, facets, onFacets, onPagination, pagination, resetPagination, resetFacets } =
    useCollection();

  const [tabFilters, setTabFilters] = useState<any>(
    TABS.find((t) => t.tabName === tab)?.filters || filters
  );

  const { data: currentUser } = useCurrentUserQuery();

  const { data } = useSearchNotifications({
    term: "*",
    pagination: pagination,
    filters: { ...tabFilters, ...filters },
    order: { created_at: "desc" },
    aggs: ["read"],
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const handleTabChange = (tab: Tab) => {
    navigate(tab.navigatePath);
    setActiveTab(tab.tabName);
    setTabFilters(tab.filters);
  };

  useEffect(() => {
    setActiveTab(tab);
    setTabFilters(TABS.find((t) => t.tabName === tab)?.filters || filters);
    resetFacets();
    resetPagination();
  }, [tab]);

  return (
    <>
      <Heading mb={4}>Inbox</Heading>
      <SplitPage
        sidebarWidth="200"
        sidebarWidthXL="250px"
        breakpoint="lg"
        sidebar={
          <VStack width="100%" align="start">
            {TABS.map((tab) => (
              <Box width="100%" position="relative">
                <Button
                  key={tab.tabName}
                  leftIcon={tab.icon}
                  rightIcon={
                    data?.unread_counts[tab.tabName] > 0 ? (
                      <Badge
                        colorScheme={activeTab === tab.tabName ? "teal" : "red"}
                        variant={activeTab === tab.tabName ? "subtle" : "solid"}
                        borderRadius="sm">
                        {data?.unread_counts[tab.tabName]}
                      </Badge>
                    ) : undefined
                  }
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === tab.tabName ? "teal" : "gray"}
                  bg={activeTab === tab.tabName ? "teal.500" : "transparent"}
                  onClick={() => handleTabChange(tab)}>
                  {tab.label}
                </Button>
              </Box>
            ))}
            <Flex display={["flex", "flex", "none"]} alignSelf="end">
              <Facets variant="button" facets={populatedFacets} onChange={onFacets} background />
            </Flex>
            <Flex
              display={["none", "none", "flex"]}
              width="100%"
              bg={useColorModeValue("gray.50", "gray.700")}
              borderRadius={"md"}>
              <Box alignSelf={"start"}>
                <Facets facets={populatedFacets} onChange={onFacets} />
              </Box>
            </Flex>
          </VStack>
        }
        main={
          data && currentUser ? (
            <Box>
              {activeTab === "action_required" && (
                <ActionRequiredTab
                  notifications={data.results}
                  currentUser={currentUser}
                  pagination={data.pagination || pagination}
                  onPagination={onPagination}
                />
              )}
              {activeTab === "all" && (
                <AllNotificationsTab
                  notifications={data.results}
                  currentUser={currentUser}
                  savedTab={false}
                  pagination={data.pagination || pagination}
                  onPagination={onPagination}
                />
              )}
              {activeTab === "saved" && (
                <AllNotificationsTab
                  notifications={data.results}
                  currentUser={currentUser}
                  savedTab={true}
                  pagination={data.pagination || pagination}
                  onPagination={onPagination}
                />
              )}
            </Box>
          ) : (
            <Spinner />
          )
        }
      />
    </>
  );
};
