import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Header } from "@sciencecorp/helix-components";
import * as _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useNavigate, useParams } from "react-router";
import {
  candidateFeedbackEncryptedDataSchema,
  useGetCandidateFeedback,
  useUpdateCandidateFeedback,
} from "../../api/candidate_feedback";
import { useCurrentUserQuery } from "../../api/user";

import { radioSections, textSections } from "./utils";

import { Select as ChakraSelect } from "chakra-react-select";
import { KeyringZodHelper } from "../../helpers/KeyringZodHelper";

import { useSecureModeModal } from "@sciencecorp/helix-components";
import { denull } from "../../helpers/index";
import { useDecryptOnSecureMode } from "../Encryption/hooks";

type FormValues = {
  technical_score: string | null;
  mission_score: string | null;
  culture_score: string | null;
  communication_score: string | null;
  strengths: string;
  concerns: string;
  overall_feedback: string;
  interviews_attended: string[];
  overall_score: string | null;
};

export const CandidateFeedbackForm = () => {
  const { id } = useParams();
  if (!id) return null;
  const navigate = useNavigate();
  const getFeedback = useGetCandidateFeedback(id, {
    // Don't refetch automatically on this page.
    enabled: false,
  });
  const toast = useToast();

  const [adminView, setAdminView] = useState(false);
  const currentUserQuery = useCurrentUserQuery();
  const decryptedFeedbackResult = useDecryptOnSecureMode(
    candidateFeedbackEncryptedDataSchema,
    getFeedback.data?.keyring_message?.content ?? ""
  );

  useEffect(() => {
    getFeedback.refetch();
  }, []);

  useSecureModeModal(
    "Enter your PIN to view this feedback",
    getFeedback.data?.keyring_message ? currentUserQuery.data?.encrypted_private_key : undefined
  );

  useEffect(() => {
    if (currentUserQuery.isSuccess) {
      const currentUser = currentUserQuery.data;
      if (!currentUser.public_key) {
        toast({
          status: "error",
          title: "Before you can submit feedback, you must set a PIN.",
        });
        navigate("/settings");
      }
    }
  }, [currentUserQuery.isSuccess]);

  const { mutate: updateCandidateFeedback } = useUpdateCandidateFeedback();
  const defaultVal = {
    technical_score: null,
    mission_score: null,
    culture_score: null,
    communication_score: null,
    strengths: "",
    concerns: "",
    overall_feedback: "",
    interviews_attended: [],
    overall_score: null,
  };
  const formHook = useForm<FormValues>({
    defaultValues: defaultVal,
  });
  const { handleSubmit, control, formState, reset } = formHook;
  const dirtyFields = formState.dirtyFields;

  // viewing a completed feedback
  useEffect(() => {
    if (getFeedback.isSuccess && getFeedback.data.locked) setAdminView(true);
    if (decryptedFeedbackResult.isSuccess) {
      reset({
        strengths: decryptedFeedbackResult.data.strengths,
        concerns: decryptedFeedbackResult.data.concerns,
        overall_feedback: decryptedFeedbackResult.data.overall_feedback,
        interviews_attended: decryptedFeedbackResult.data.interviews_attended || undefined,
        overall_score: decryptedFeedbackResult.data.overall_score?.toString(),
        technical_score: decryptedFeedbackResult.data.technical_score?.toString(),
        mission_score: decryptedFeedbackResult.data.mission_score?.toString(),
        culture_score: decryptedFeedbackResult.data.culture_score?.toString(),
        communication_score: decryptedFeedbackResult.data.communication_score?.toString(),
      });
    }
  }, [getFeedback.isSuccess, decryptedFeedbackResult.isSuccess, decryptedFeedbackResult.isError]);

  const anyFieldDirty = useCallback(() => {
    return _.keys(dirtyFields).length > 0;
  }, [formState]);

  const isSet = (value: any | any[]) => {
    return value !== null && value !== undefined && value !== "";
  };

  const allRequiredFieldsSet = useCallback(() => {
    const formValues = formHook.watch();
    return (
      isSet(formValues.communication_score) &&
      isSet(formValues.interviews_attended) &&
      formValues.interviews_attended.length !== 0 &&
      isSet(formValues.mission_score) &&
      isSet(formValues.overall_feedback) &&
      isSet(formValues.overall_score) &&
      isSet(formValues.technical_score)
    );
  }, [formHook]);

  const radioForm: JSX.Element[] = [];

  for (const key in radioSections) {
    radioForm.push(
      <Controller
        name={radioSections[key][2]}
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <RadioSection
            onValue={field.value}
            onChange={field.onChange}
            idx={key}
            heading={key}
            scale={radioSections[key]}
            adminView={adminView}
          />
        )}
      />
    );
  }

  const textFeedback: JSX.Element[] = [];
  for (const key in textSections) {
    if (textSections[key] === "overall_feedback") {
      textFeedback.push(
        <Controller
          name={textSections[key]}
          control={control}
          render={({ field }) => (
            <FormControl
              key={key}
              borderTop="1px"
              borderColor={useColorModeValue("gray.200", "gray.500")}
              isRequired>
              <Box>
                <FormLabel mt={8}>{key}</FormLabel>
                <Textarea {...field} mt={2} placeholder="Type your answer" readOnly={adminView} />
              </Box>
            </FormControl>
          )}
        />
      );
    } else {
      textFeedback.push(
        <Controller
          name={textSections[key]}
          control={control}
          render={({ field }) => (
            <FormControl key={key}>
              <Box>
                <FormLabel mt={4}>{key}</FormLabel>
                <Textarea {...field} mt={2} placeholder="Type your answer" readOnly={adminView} />
              </Box>
            </FormControl>
          )}
        />
      );
    }
  }

  const onSubmit = (formValues: FormValues) => {
    if (currentUserQuery.isSuccess && getFeedback.isSuccess) {
      const currentUser = currentUserQuery.data;
      const feedback = denull(getFeedback.data);
      const keyringHelper = new KeyringZodHelper(candidateFeedbackEncryptedDataSchema);
      const publicKeys = feedback.keyring_user_keys.map((k) => k.public_key);
      if (!!currentUser.public_key && !publicKeys.includes(currentUser.public_key)) {
        publicKeys.push(currentUser.public_key);
      }

      const preparedFormValues = {
        ...formValues,
        communication_score: Number(formValues.communication_score),
        overall_score: Number(formValues.overall_score),
        technical_score: Number(formValues.technical_score),
        culture_score: Number(formValues.culture_score),
        mission_score: Number(formValues.mission_score),
      };

      const messageContent = keyringHelper.encrypt(preparedFormValues, publicKeys);
      updateCandidateFeedback(
        {
          id: Number(id),
          user_id: currentUser.id,
          candidate_id: feedback.candidate_id,
          keyring_message: {
            content: messageContent,
            message_type: "Candidate Feedback",
          },
        },
        {
          onSuccess: () => {
            navigate("/hiring/feedback/success");
          },
        }
      );
    }
  };

  const disabledButton = useMemo(() => {
    return !anyFieldDirty() || !allRequiredFieldsSet();
  }, [allRequiredFieldsSet(), anyFieldDirty(), getFeedback.isSuccess]);

  return (
    <>
      <Box
        height="100%"
        mb="-4"
        mx="-4"
        p="4"
        borderTop="1px"
        borderColor={useColorModeValue("gray.200", "gray.500")}
        bg={useColorModeValue("gray.50", "")}>
        <Header
          title={
            adminView
              ? `${getFeedback?.data?.user.name}'s Interview Feedback for ${getFeedback?.data?.candidate.name}`
              : "Submit Interview Feedback"
          }
          crumbs={[{ label: "Hiring", url: "/hiring/completed_interviews" }]}
          crumbsColor="teal.500"
        />
        <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
          <Flex
            bg={useColorModeValue("white", "gray.700")}
            direction="column"
            align="center"
            justify="center"
            width={["sm", "sm", "3xl"]}
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.500")}
            borderRadius="md"
            p={8}>
            <VStack spacing={8}>
              <FormControl>
                <FormLabel>Candidate Name</FormLabel>
                {getFeedback && (
                  <ChakraSelect
                    value={{
                      label: getFeedback?.data?.candidate.name,
                      value: getFeedback?.data?.candidate.id,
                    }}
                    options={[
                      {
                        label: getFeedback?.data?.candidate.name,
                        value: getFeedback?.data?.candidate.id,
                      },
                    ]}
                  />
                )}
              </FormControl>
              <Controller
                name="interviews_attended"
                control={control}
                render={({ field }) => (
                  <FormControl
                    borderTop="1px"
                    borderColor={useColorModeValue("gray.200", "gray.500")}
                    isRequired>
                    <FormLabel mt={8}>What interview were you present for?</FormLabel>
                    <CheckboxGroup
                      value={field.value}
                      onChange={(values) => {
                        const event = {
                          target: {
                            name: "interviews_attended",
                            value: values,
                          },
                        };
                        field.onChange(event.target.value);
                      }}>
                      <Stack
                        spacing={6}
                        direction={["column", "column", "row"]}
                        mt={{ md: 6 }}
                        wrap="wrap">
                        <Checkbox
                          key={"GP"}
                          ml={{ md: 6 }}
                          value="presentation"
                          readOnly={adminView}>
                          Group Presentation
                        </Checkbox>
                        <Checkbox key={"G1"} value="group_1" readOnly={adminView}>
                          Group 1
                        </Checkbox>
                        <Checkbox key={"G2"} value="group_2" readOnly={adminView}>
                          Group 2
                        </Checkbox>
                        <Checkbox key={"T"} value="tour" readOnly={adminView}>
                          Tour
                        </Checkbox>
                        <Checkbox key={"1:1"} value="one_on_one" readOnly={adminView}>
                          1:1 Interview
                        </Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  </FormControl>
                )}
              />
              {radioForm}
              {textFeedback}
            </VStack>
          </Flex>
        </Box>
        {!adminView && (
          <Center mt={8} mb={16}>
            <ButtonGroup>
              <Button onClick={() => reset(defaultVal)} variant="outline">
                Clear Answers
              </Button>
              <Button
                colorScheme="teal"
                width={32}
                onClick={handleSubmit(onSubmit)}
                isDisabled={disabledButton}>
                Submit
              </Button>
            </ButtonGroup>
          </Center>
        )}
      </Box>
    </>
  );
};

export const RadioSection = ({ onValue, onChange, adminView, heading, scale, idx }) => {
  const radioScale: any[] = [];
  for (let i = 1; i <= 4; i++) {
    radioScale.push(
      <Radio key={i} value={i.toString()}>
        {i}
      </Radio>
    );
  }

  return (
    <FormControl
      borderTop="1px"
      borderColor={useColorModeValue("gray.200", "gray.500")}
      isRequired
      key={idx}>
      <FormLabel mt={8}>{heading}</FormLabel>
      <Stack
        direction={["column", "column", "row"]}
        justify="space-around"
        align={{ md: "center" }}
        mt={6}>
        <Text size="sm" opacity="0.7">
          {scale[0]}
        </Text>
        <RadioGroup onChange={!adminView ? onChange : null} value={onValue}>
          <Stack key={heading} direction={["column", "column", "row"]} spacing={8}>
            {radioScale}
          </Stack>
        </RadioGroup>
        <Text size="sm" opacity="0.7">
          {scale[1]}
        </Text>
      </Stack>
    </FormControl>
  );
};
