import React, { useState } from "react";
import {
  Box,
  Stack,
  HStack,
  Text,
  Icon,
  VStack,
  Tag,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  Spinner,
  Center,
  Divider,
  Button,
  ring,
} from "@chakra-ui/react";
import { BsRecordCircle, BsThreeDots } from "react-icons/bs";
import { CheckCircleIcon, SmallAddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { RichTextEditor, Link } from "@sciencecorp/helix-components";
import { UserLoggedInData } from "../../../../api/user";
import { UserServiceRequestPill } from "../../../Requests/utils";
import { DateTime } from "luxon";

import { EmptyState } from "../../../shared/EmptyState";
import {
  TimelineEventData,
  TimelineEventShowData,
  useDeleteTimelineEvent,
  useUpdateTimelineEvent,
} from "../../../../api/timeline_events";
import { SubscriptionShowData, invalidateSubscription } from "../../../../api/subscription";
import { useCreateTimelineEvent } from "../../../../api/timeline_events";
import { TimelineEventContent } from "../util";

type PurchaseTimelineProps = {
  subscriptionEvents: TimelineEventData[];
  subscription: SubscriptionShowData;
  timelineable: string;
  currentUser?: UserLoggedInData;
};

export const SubscriptionTimeline = ({
  subscriptionEvents,
  timelineable,
  subscription,
  currentUser,
}: PurchaseTimelineProps) => {
  const [session, setSession] = useState(0);
  const [content, setContent] = useState("");
  const [plainText, setPlainText] = useState("");
  const { mutate: createTimelineEvent } = useCreateTimelineEvent(() =>
    invalidateSubscription(subscription.id, "events")
  );

  const handleComment = () => {
    if (currentUser) {
      setSession((prev) => ++prev);
      createTimelineEvent(
        {
          user_id: currentUser.id,
          timelineable_id: subscription.id,
          timelineable_type: timelineable,
          event_type: "comment",
          event_data: {
            rich_text: content,
          },
          content,
          slack_message: `has commented: "${plainText}"`,
        },
        {
          onSuccess: () => {
            setContent("");
            setPlainText("");
          },
        }
      );
    }
  };

  return (
    <Flex flexDir="column">
      {subscriptionEvents?.length ? (
        <Stack py={2} width="100%">
          <Stack position="relative" mb={3}>
            <Box
              position="absolute"
              height="100%"
              bg={useColorModeValue("gray.200", "gray.700")}
              width={0.5}
              left={3.5}
              top={2}
              zIndex={-1}
            />
            <VStack align="start" spacing={5}>
              {subscriptionEvents?.map((event: TimelineEventData) => (
                <TimelineEvent
                  key={event.id}
                  timelineEvent={event}
                  subscription={subscription}
                  currentUser={currentUser}
                />
              ))}
            </VStack>
          </Stack>
          <Divider />
        </Stack>
      ) : (
        <EmptyState title="No events added yet. Add a comment to get started" size="3xs" />
      )}
      <Divider />
      <VStack
        spacing={0}
        align="start"
        w={{ base: "100%", md: "50%" }}
        minW={{ base: "", md: "2xl" }}>
        <VStack spacing={0} w="100%">
          <Flex align="baseline" w="100%" gap={2}>
            <Avatar size="sm" src={currentUser?.picture_uri} />
            <Box w="100%">
              <RichTextEditor
                session={session}
                onChange={(raw, rich) => {
                  setContent(raw);
                  setPlainText(rich ?? "");
                }}
                placeholder="Leave a comment..."
              />
            </Box>
          </Flex>
          <Button onClick={handleComment} alignSelf="end">
            Comment
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};

const TimelineEvent = ({ timelineEvent, subscription, currentUser }) => {
  let icon: JSX.Element | null = null;

  if (timelineEvent.event_type.includes("created")) {
    icon = <Icon as={SmallAddIcon} color="gray.500" boxSize={5} />;
  } else {
    icon = <Icon as={BsRecordCircle} color="gray.500" boxSize={5} />;
  }

  return timelineEvent.event_type !== "comment" ? (
    <HStack alignItems="center" w={{ base: "100%", md: "50%" }} minW={{ base: "", md: "2xl" }}>
      <Box
        borderRadius="full"
        bg={useColorModeValue("gray.100", "gray.900")}
        boxSize={8}
        display="flex"
        justifyContent="center"
        alignItems="center">
        {icon}
      </Box>
      <TimelineEventContent
        timelineEvent={timelineEvent}
        currentUser={currentUser}
        onSuccessCallback={() => invalidateSubscription(subscription.id, "events")}
      />
    </HStack>
  ) : (
    <HStack align="start" w={{ base: "100%", md: "50%" }} minW={{ base: "", md: "2xl" }}>
      <Link href={`/users/${timelineEvent.user.id}`}>
        <Avatar src={timelineEvent.user.picture_uri} size="sm" />
      </Link>
      <TimelineEventContent
        timelineEvent={timelineEvent}
        currentUser={currentUser}
        onSuccessCallback={() => invalidateSubscription(subscription.id, "events")}
      />
    </HStack>
  );
};
