import {
  Box,
  Flex,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmptyState, useCollection } from "@sciencecorp/helix-components";
import React from "react";
import { useSearchPurchasesWithStats } from "../../../../api/purchase";
import { VendorData } from "../../../../api/vendor";
import { PurchasesTab } from "../../PurchasesTab";
import { MoneyText } from "../../../MoneyText";

export const VendorPurchasesTable = ({ vendor }: { vendor: VendorData }) => {
  const { filters, pagination, onPagination, order, onOrder } = useCollection();

  const { data: purchases, isLoading } = useSearchPurchasesWithStats({
    term: "*",
    aggs: [],
    pagination,
    order,
    filters: {
      ...filters,
      vendor_id: vendor.id,
    },
  });

  return (
    <Box w="100%">
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        wrap="wrap"
        mb={6}
        p={4}
        bg={useColorModeValue("gray.50", "gray.700")}
        borderRadius="md"
        border="1px"
        borderColor="chakra-border-color">
        <Stat>
          <StatLabel>Pending Approval</StatLabel>
          <StatNumber>
            <MoneyText
              money={purchases?.total_pending_approval}
              formatOptions={{ compact: "never" }}
            />
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Committed</StatLabel>
          <StatNumber>
            <MoneyText money={purchases?.total} formatOptions={{ compact: "never" }} />
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Spent</StatLabel>
          <StatNumber>
            <MoneyText money={purchases?.total_spent} formatOptions={{ compact: "never" }} />
          </StatNumber>
        </Stat>
      </Flex>
      {isLoading ? (
        <Spinner />
      ) : purchases?.results.length ? (
        <PurchasesTab
          data={purchases || []}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
          location="vendors"
        />
      ) : (
        <EmptyState title="No purchases yet" size="3xs" />
      )}
    </Box>
  );
};
