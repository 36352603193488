import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { ChangeEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetMeetingNote, useUpdateMeetingNotes } from "../../api/meeting_notes";
import { DateTime } from "luxon";

type EditmeetingModalProps = {
  meeting_note_id: number;
  isOpen: boolean;
  onClose: () => void;
};

type EditMeetingModalType = {
  title: string;
  meeting_time: DateTime<boolean>;
};

type EditMeetingModalFormValuesConversion = {
  id: number;
  title: string;
  meeting_time: DateTime<boolean>;
};

export const EditMeetingModal: React.FC<EditmeetingModalProps> = ({
  meeting_note_id,
  isOpen,
  onClose,
}) => {
  const { data: meetingNote } = useGetMeetingNote(meeting_note_id);
  const { mutateAsync: editMeetingNote, isLoading } = useUpdateMeetingNotes(meeting_note_id);
  const formattedMeetingTime = meetingNote?.meeting_time.toFormat("yyyy-MM-dd'T'HH:mm");

  const { setValue, control, reset, watch } = useForm<EditMeetingModalType>({
    defaultValues: {
      title: meetingNote?.title ?? "",
      meeting_time: formattedMeetingTime,
    },
  });
  const formValues = watch();

  useEffect(() => {
    reset({
      title: meetingNote?.title ?? "",
    });
  }, [isOpen]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const formIsValid = () => {
    return formValues.title !== "";
  };

  const onSubmit = (meetingData: EditMeetingModalType) => {
    const convertedMeetingData: EditMeetingModalFormValuesConversion = {
      title: meetingData.title,
      id: meeting_note_id,
      meeting_time: meetingData.meeting_time,
    };
    editMeetingNote(convertedMeetingData).then(() => {
      handleClose();
    });
  };

  return (
    <FormModal
      title="Edit Meeting Information"
      submitButtonColorSchema="teal"
      submitButtonTitle={"Save Changes"}
      size="2xl"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleClose}
      submitButtonDisabled={!formIsValid()}
      handleSubmit={() => onSubmit(formValues)}>
      <Controller
        name="title"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Type Here..."
              value={field.value}
              onChange={(e) => setValue("title", e.target.value)}
            />
          </FormControl>
        )}
      />
      <Controller
        name="meeting_time"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Date and Time</FormLabel>
            <Input
              type="datetime-local"
              defaultValue={formattedMeetingTime}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const newDate = event.target.value;
                setValue("meeting_time", DateTime.fromISO(newDate));
              }}></Input>
          </FormControl>
        )}
      />
    </FormModal>
  );
};
