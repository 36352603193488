import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { z } from "zod";
import { api } from ".";
import { dateTimeSchema } from "../helpers/dateTime";
import { zodParse } from "./zodParse";
import { Money, moneySchema } from "../helpers/Money";

export const windowSpendingSchema = z.object({
  window: z.number(),
  annualized_spend: moneySchema,
});

export type WindowSpendingData = z.infer<typeof windowSpendingSchema>;

export const spendingVelocitySummarySchema = z.object({
  budget_item_id: z.number(),
  budget_id: z.number(),
  budgetable_id: z.number(),
  budgetable_type: z.string(),
  date: dateTimeSchema,
  spending: z.array(windowSpendingSchema),
});

export const BASE_URL = "spending_velocity_summaries";

export type SpendingVelocitySummary = z.infer<typeof spendingVelocitySummarySchema>;
export type WindowSpending = z.infer<typeof windowSpendingSchema>;
export type CompanyWideSpendingVelocitySummary = Omit<
  SpendingVelocitySummary,
  "budget_id" | "budgetable_id" | "budget_id" | "budgetable_type" | "budget_item_id"
>;

export async function getSpendingVelocitySummaries(): Promise<SpendingVelocitySummary[]> {
  const result = await api.get(`${BASE_URL}`);
  return zodParse(z.array(spendingVelocitySummarySchema), result.data);
}

export const useSpendingVelocitySummaries = () => {
  return useQuery({
    queryKey: [BASE_URL],
    queryFn: getSpendingVelocitySummaries,
  });
};

const mergeSpendingArray = (left: WindowSpending[], right: WindowSpending[]): WindowSpending[] => {
  const rightByWindow: Record<number, WindowSpending | undefined> = _.keyBy(right, (s) => s.window);
  return left.map((leftWindow) => ({
    window: leftWindow.window,
    annualized_spend: leftWindow.annualized_spend.add(
      rightByWindow[leftWindow.window]?.annualized_spend ?? Money.zero()
    ),
  }));
};

export const useCompanyWideSpendingVelocitySummaries = () => {
  return useQuery({
    queryKey: [BASE_URL],
    queryFn: async (): Promise<CompanyWideSpendingVelocitySummary[]> => {
      const summaries = await getSpendingVelocitySummaries();
      const summariesByDate = _.groupBy(summaries, (s) => s.date);
      return _.keys(summariesByDate).flatMap((key) => {
        const result: null | CompanyWideSpendingVelocitySummary = summariesByDate[key].reduce(
          (acc: CompanyWideSpendingVelocitySummary | null, summary: SpendingVelocitySummary) => {
            if (acc === null) {
              return {
                date: summary.date,
                spending: summary.spending,
              };
            } else {
              return {
                date: acc.date,
                spending: mergeSpendingArray(acc.spending, summary.spending),
              };
            }
          },
          null
        );
        if (result) {
          return [result];
        } else {
          return [];
        }
      });
    },
  });
};
