import {
  Avatar,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableSelect,
  EditableText,
  Header,
  RecordLink,
  SplitPage,
  StatusSelect,
} from "@sciencecorp/helix-components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  invalidateBudgetHeadcount,
  useGetBudgetHeadcountVacancy,
  useUpdateBudgetHeadcount,
} from "../../api/budget_headcounts";
import { useCurrentUserQuery, useUsersQuery, userHasRole } from "../../api/user";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { EditableMoney } from "../EditableMoney";
import { EditableLink } from "../Purchasing/Purchase/components/EditableLink";
import { ArchetypeAttributes } from "./Archetypes/ArchetypeAttributes";
import { HiringTeamTable } from "./components/HiringTeamTable";

export const VacancyPage = () => {
  const currency = useCurrency();
  const { id } = useParams();
  const {
    data: budgetHeadcount,
    isLoading: isBudgetHeadcountLoading,
    isError: isBudgetHeadcountError,
  } = useGetBudgetHeadcountVacancy(Number(id));
  const navigate = useNavigate();
  const toast = useToast();

  const { data: allUsers } = useUsersQuery();
  const currentUserQuery = useCurrentUserQuery();
  const isRecruitingAdmin = userHasRole(currentUserQuery, "recruiting_admin");
  const isCeo = userHasRole(currentUserQuery, "ceo");

  const [availableUsers, setAvailableUsers] = useState<{ label: string; value: string }[]>([]);
  const [loadingField, setLoadingField] = useState("");

  const { mutate: updateBudgetHeadcount, isLoading: isUpdateLoading } = useUpdateBudgetHeadcount(
    () => invalidateBudgetHeadcount(Number(id))
  );

  useEffect(() => {
    if (allUsers) {
      setAvailableUsers(allUsers.map((user) => ({ label: user.name, value: user.id.toString() })));
    }
  }, [allUsers]);

  const isLoadingField = (field) => loadingField === field && isUpdateLoading;

  if (isBudgetHeadcountLoading) {
    return (
      <Center height="100vh">
        <Spinner size="lg" />
      </Center>
    );
  }

  if (isBudgetHeadcountError) {
    return <Text>Failed to find budget headcount vacancy</Text>;
  }

  if (currentUserQuery.isSuccess && !isRecruitingAdmin && !isCeo) {
    navigate("/hiring/completed_interviews");
    return <Text>Unauthorized. Redirecting...</Text>;
  }

  const handleHiringManagerChange = (value) => {
    setLoadingField("hiring_manager");
    updateBudgetHeadcount(
      {
        id: budgetHeadcount.id,
        hiring_manager_id: value,
      },
      {
        onSuccess: () => {
          setLoadingField("");
        },
      }
    );
  };

  return (
    <>
      <Header
        title={`${budgetHeadcount.archetype.name} - ${budgetHeadcount.budgetable.name}`}
        crumbsColor="teal"
        badge={{
          label: budgetHeadcount.active ? "Active" : "Inactive",
          colorScheme: budgetHeadcount.active ? "green" : "red",
          size: "lg",
        }}
        crumbs={[{ label: "Vacancies", url: "/hiring/vacancies" }]}
      />
      <SplitPage
        sidebarWidth="300px"
        sidebarWidthXL="450px"
        breakpoint="lg"
        sidebar={
          <Flex
            bg={useColorModeValue("gray.50", "gray.700")}
            border="1px"
            borderColor="chakra-border-color"
            px={6}
            py={5}
            borderRadius="md"
            width="100%">
            <ArchetypeAttributes archetypeId={budgetHeadcount.archetype.id} editable={false} />
          </Flex>
        }
        main={
          <>
            <Flex direction="column" gap={4}>
              <Flex direction={["column", "column", "row"]} gap={4}>
                <Flex
                  border="1px"
                  borderColor="chakra-border-color"
                  px={6}
                  py={5}
                  borderRadius="md"
                  width="100%">
                  <AttributesTable
                    title="Job Listing Information"
                    attributes={[
                      {
                        label: "Other Names",
                        value: (
                          <EditableText
                            defaultValue={budgetHeadcount.other_names ?? ""}
                            onSubmit={(value) => {
                              updateBudgetHeadcount({ id: budgetHeadcount.id, other_names: value });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Description",
                        value: (
                          <EditableLink
                            url={budgetHeadcount.job_description || ""}
                            onSubmit={(value) => {
                              if (value) {
                                updateBudgetHeadcount({
                                  id: budgetHeadcount.id,
                                  job_description: value,
                                });
                              }
                            }}
                          />
                        ),
                      },
                      {
                        label: "Reviewed for IP Issues?",
                        value: (
                          <StatusSelect
                            variant="tag"
                            options={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                            ]}
                            status={
                              budgetHeadcount.reviewed_ip_issues
                                ? { value: "yes", label: "Yes", color: "green" }
                                : { value: "no", label: "No", color: "red" }
                            }
                            onSubmit={(value) => {
                              updateBudgetHeadcount({
                                id: budgetHeadcount.id,
                                reviewed_ip_issues: value === "yes",
                              });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Listing Status",
                        value: (
                          <StatusSelect
                            variant="tag"
                            options={[
                              { label: "Active", value: "Active" },
                              { label: "Inactive", value: "Inactive" },
                            ]}
                            status={
                              budgetHeadcount.active
                                ? { value: "Active", label: "Active", color: "green" }
                                : { value: "Inactive", label: "Inactive", color: "red" }
                            }
                            onSubmit={(value) => {
                              updateBudgetHeadcount({
                                id: budgetHeadcount.id,
                                active: value === "Active",
                              });
                            }}
                          />
                        ),
                      },
                      {
                        label: "Listed on Greenhouse?",
                        value: (
                          <StatusSelect
                            variant="tag"
                            options={[
                              { label: "Yes", value: "yes" },
                              { label: "No", value: "no" },
                            ]}
                            status={
                              budgetHeadcount.in_greenhouse
                                ? { value: "yes", label: "Yes", color: "green" }
                                : { value: "no", label: "No", color: "red" }
                            }
                            onSubmit={(value) => {
                              updateBudgetHeadcount({
                                id: budgetHeadcount.id,
                                in_greenhouse: value === "yes",
                              });
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Flex>
                <Flex
                  border="1px"
                  borderColor="chakra-border-color"
                  px={6}
                  py={5}
                  borderRadius="md"
                  width="100%">
                  <AttributesTable
                    title="Team-Specific Requirements"
                    attributes={[
                      {
                        label: "Team Name",
                        value: (
                          <RecordLink
                            type={budgetHeadcount.budgetable.type}
                            link={`/teams/${budgetHeadcount.budgetable.id}/budget`}
                            identifier={budgetHeadcount.budgetable.name}
                            size="small"
                          />
                        ),
                      },
                      {
                        label: "Hiring Manager",
                        value: (
                          <EditableSelect
                            options={availableUsers}
                            selectedValue={budgetHeadcount.hiring_manager?.id.toString() ?? ""}
                            onSubmit={(value) => handleHiringManagerChange(value)}
                            isLoading={isLoadingField("hiring_manager")}
                            preview={
                              budgetHeadcount.hiring_manager ? (
                                <HStack>
                                  <Avatar size="sm" name={budgetHeadcount.hiring_manager.name} />
                                  <Text>{budgetHeadcount.hiring_manager.name}</Text>
                                </HStack>
                              ) : (
                                <Text>No Hiring Manager</Text>
                              )
                            }
                          />
                        ),
                      },
                      {
                        label: "Experience Level",
                        value: (
                          <EditableText
                            defaultValue={budgetHeadcount.level}
                            onSubmit={(value) => {
                              if (value) {
                                setLoadingField("level");
                                updateBudgetHeadcount(
                                  { id: budgetHeadcount.id, level: value },
                                  { onSuccess: () => setLoadingField("") }
                                );
                              }
                            }}
                            persistentEdit={isLoadingField("level")}
                            isLoading={isLoadingField("level")}
                          />
                        ),
                      },
                      {
                        label: "Salary Band",
                        value: (
                          <HStack w="100%">
                            <HStack w="100%" flex={1}>
                              <Text>Min:</Text>
                              <EditableMoney
                                onSubmit={(value) => {
                                  if (value) {
                                    if (
                                      !budgetHeadcount.salary_band_max.isZero() &&
                                      budgetHeadcount.salary_band_max.lt(value)
                                    ) {
                                      toast({
                                        title: "Minimum salary must be less than maximum salary",
                                        status: "error",
                                        duration: 3000,
                                        isClosable: true,
                                      });
                                      return;
                                    }
                                    setLoadingField("salaryBandMin");
                                    updateBudgetHeadcount(
                                      { id: budgetHeadcount.id, salary_band_min: value },
                                      { onSuccess: () => setLoadingField("") }
                                    );
                                  }
                                }}
                                min={Money.zero(
                                  budgetHeadcount.salary_band_min.currency.code || currency
                                )}
                                max={budgetHeadcount.salary_band_max || undefined}
                                defaultValue={budgetHeadcount.salary_band_min}
                                isLoading={isLoadingField("salaryBandMin")}
                                persistentEdit={isLoadingField("salaryBandMin")}
                              />
                            </HStack>
                            <HStack w="100%" flex={1}>
                              <Text>Max:</Text>
                              <EditableMoney
                                onSubmit={(value) => {
                                  if (value) {
                                    if (budgetHeadcount.salary_band_min.gt(value)) {
                                      toast({
                                        title: "Maximum salary must be more than minimum salary",
                                        status: "error",
                                        duration: 3000,
                                        isClosable: true,
                                      });
                                      return;
                                    }
                                    setLoadingField("salaryBandMax");
                                    updateBudgetHeadcount(
                                      { id: budgetHeadcount.id, salary_band_max: value },
                                      { onSuccess: () => setLoadingField("") }
                                    );
                                  }
                                }}
                                min={budgetHeadcount.salary_band_min || Money.zero(currency)}
                                defaultValue={budgetHeadcount.salary_band_max}
                                isLoading={isLoadingField("salaryBandMax")}
                                persistentEdit={isLoadingField("salaryBandMax")}
                              />
                            </HStack>
                          </HStack>
                        ),
                      },
                      {
                        label: "Take Home Test",
                        value: (
                          <EditableLink
                            url={budgetHeadcount.take_home_test}
                            onSubmit={(value) => {
                              if (value) {
                                updateBudgetHeadcount({
                                  id: budgetHeadcount.id,
                                  take_home_test: value,
                                });
                              }
                            }}
                          />
                        ),
                      },
                      {
                        label: "Criteria Assessment",
                        value: (
                          <EditableText
                            defaultValue={budgetHeadcount.assessment}
                            onSubmit={(value) => {
                              if (value) {
                                setLoadingField("criteriaAssessment");
                                updateBudgetHeadcount(
                                  { id: budgetHeadcount.id, assessment: value },
                                  { onSuccess: () => setLoadingField("") }
                                );
                              }
                            }}
                            persistentEdit={isLoadingField("criteriaAssessment")}
                            isLoading={isLoadingField("criteriaAssessment")}
                          />
                        ),
                      },
                      {
                        label: "Additional Notes",
                        value: (
                          <EditableText
                            defaultValue={budgetHeadcount.notes ?? ""}
                            multiline
                            onSubmit={(value) => {
                              updateBudgetHeadcount({ id: budgetHeadcount.id, notes: value });
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Flex>
              </Flex>
              <HiringTeamTable
                budgetHeadcountId={budgetHeadcount.id}
                editable={isRecruitingAdmin}
              />
            </Flex>
          </>
        }
      />
    </>
  );
};

export default VacancyPage;
