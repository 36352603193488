import React from "react";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select, SelectProps } from "@sciencecorp/helix-components";

export type SelectFormControlProps = SelectProps & {
  label: string;
  options: { label: string; value: string | number }[];
  error?: string;
  isRequired?: boolean;
};

const SelectFormControl: React.FC<SelectFormControlProps> = ({
  label,
  options,
  error,
  isRequired,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Select options={options} isInvalid={!!error} isClearable {...props} />
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default SelectFormControl;
