import { HStack, Box, Text, VStack, Tag, Heading } from "@chakra-ui/react";
import React from "react";
import { NewsPostPreviewData } from "../../api/news_post";
import { useNavigate } from "react-router";
import { TIMEZONE } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import { toDateTimeLocalString } from "../../helpers/dateTime";

type NewsCardProps = {
  newsPost: NewsPostPreviewData;
};
export const NewsCard = ({ newsPost }: NewsCardProps) => {
  const navigate = useNavigate();
  return (
    <HStack marginBlock={4} justify="space-between" w="100%">
      <VStack
        align="start"
        spacing={0}
        onClick={() => navigate(`/news/${newsPost.id}`)}
        cursor="pointer">
        <Box mb={3}>
          <Heading size="md" marginBlockEnd={2} fontWeight="medium">
            {newsPost.title}
          </Heading>
          <Text
            fontSize="sm"
            noOfLines={3}
            whiteSpace="normal"
            overflowWrap="break-word"
            wordBreak="break-word">
            {newsPost.content}
          </Text>
        </Box>

        <HStack>
          <Tag fontWeight="semibold" textTransform="capitalize" variant="outline">
            {newsPost.category?.split("_").join(" ")}
          </Tag>
          <Text color="gray.500" fontSize="xs">
            {DateTime.fromISO(toDateTimeLocalString(newsPost.created_at), {
              zone: TIMEZONE,
            }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
