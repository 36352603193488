import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";

export type TreeOptionValue<V> = V | null;
export interface TreeSelectOption<V> {
  value: TreeOptionValue<V>;
  label: string;
  type?: string;
  parentValue?: TreeOptionValue<V>;
  children?: TreeSelectOption<V>[] | undefined;
}

function selectedValueInTree<V>(
  option: TreeSelectOption<V>,
  value: TreeSelectOption<V> | undefined
): boolean {
  if (option.value === value?.value) {
    return true;
  } else {
    return option.children?.some((child) => selectedValueInTree(child, value)) || false;
  }
}

export function findSelectedOption<V>(
  options: TreeSelectOption<V>[],
  value: TreeOptionValue<V> | undefined
): TreeSelectOption<V>[] | undefined {
  return options.map((option) => _findSelectedOption(option, value)).filter(Boolean)[0];
}

function _findSelectedOption<V>(
  option: TreeSelectOption<V>,
  value: TreeOptionValue<V> | undefined,
  path: TreeSelectOption<V>[] = []
): TreeSelectOption<V>[] | undefined {
  if (option.children) {
    return option.children
      .map((child) => _findSelectedOption(child, value, [...path, option]))
      .filter(Boolean)[0];
  } else if (_.isEqual(option.value, value)) {
    return [...path, option];
  } else {
    return undefined;
  }
}

const lmDepthShading = [
  "transparent",
  "rgba(0, 0, 0, 0.05)",
  "rgba(0, 0, 0, 0.10)",
  "rgba(0, 0, 0, 0.15)",
  "rgba(0, 0, 0, 0.20)",
  "rgba(0, 0, 0, 0.25)",
];
const dmDepthShading = [
  "transparent",
  "rgba(0, 0, 0, 0.1)",
  "rgba(0, 0, 0, 0.2)",
  "rgba(0, 0, 0, 0.3)",
  "rgba(0, 0, 0, 0.4)",
  "rgba(0, 0, 0, 0.5)",
];

const useDepthShading = (depth: number): string => {
  return useColorModeValue(lmDepthShading[depth], dmDepthShading[depth]);
};

function hasLeaves<V>(option: TreeSelectOption<V>): boolean {
  if (option.children == undefined) {
    return true;
  } else if (option.children && option.children.length > 0) {
    return option.children.some((child) => hasLeaves(child));
  } else {
    return false;
  }
}

export function DefaultRenderOption<V>(option: TreeSelectOption<V>): JSX.Element {
  return (
    <Flex width={"100%"} height={"100%"} justifyContent={"start"} alignItems={"center"}>
      <Text fontWeight={"normal"} alignItems={"center"}>
        {option.type && `${option.type}: `}
        {option.label}
      </Text>
    </Flex>
  );
}

type SelectOptionRowProps<V> = {
  option: TreeSelectOption<V>;
  keyPrefix: number;
  expandedValues: TreeOptionValue<V>[];
  selectedOption: TreeSelectOption<V> | undefined;
  onClick: (option: TreeSelectOption<V>, path: TreeSelectOption<V>[]) => void;
  expand: (value: TreeOptionValue<V>) => void;
  depth?: number;
  path?: TreeSelectOption<V>[];
  collapse?: (value: TreeOptionValue<V>) => void;
  renderOption: (option: TreeSelectOption<V>) => JSX.Element;
};
function SelectOptionRow<V>({
  option,
  expandedValues,
  selectedOption,
  onClick,
  keyPrefix,
  expand,
  renderOption,
  depth = 0,
  path = [],
}: SelectOptionRowProps<V>): JSX.Element {
  const isChildSelected = useMemo(
    () => selectedValueInTree(option, selectedOption),
    [option, selectedOption]
  );
  const depthShading = useDepthShading(depth);
  const activeColor = useColorModeValue("teal.300", "teal.500");
  const hoverColor = useColorModeValue("teal.100", "teal.800");
  const isExpanded = useMemo(
    () => expandedValues.includes(option.value),
    [option, expandedValues, isChildSelected]
  );
  const isSelected = useMemo(
    () => option.value === selectedOption?.value,
    [option, selectedOption]
  );

  const icon = () => {
    if (isExpanded && option.children) {
      return (
        <Box width={4} ml={4 * depth} mr={2}>
          <MdOutlineArrowDropDown size={"1.5em"} />
        </Box>
      );
    } else if (option.children) {
      return (
        <Box width={4} ml={4 * depth} mr={2}>
          <MdOutlineArrowRight size={"1.5em"} />
        </Box>
      );
    } else {
      return <Box width={4} ml={4 * depth} mr={2}></Box>;
    }
  };
  if (!hasLeaves(option)) {
    return <></>;
  }
  return (
    <>
      <Flex width={"100%"} flexDir={"row"} bgColor={isSelected ? "transparent" : depthShading}>
        <Button
          py={4}
          width={"100%"}
          size={"lg"}
          lineHeight={"1.5em"}
          height={"1.5em"}
          borderRadius={"none"}
          variant={"ghost"}
          onClick={() => {
            onClick(option, path);
          }}
          isActive={isSelected || (isChildSelected && !isExpanded)}
          _active={{ bgColor: activeColor }}
          _hover={{ bgColor: hoverColor }}
          justifyContent={"flex-start"}
          leftIcon={icon()}>
          {renderOption(option)}
        </Button>
      </Flex>
      {isExpanded &&
        option.children &&
        option.children
          .filter((child) => hasLeaves(child))
          .map((child) => (
            <SelectOptionRow
              key={`treeselect-${keyPrefix}-${option.type}-${option.value}-${option.label}-${child.label}`}
              keyPrefix={keyPrefix}
              option={child}
              expandedValues={expandedValues}
              selectedOption={selectedOption}
              onClick={onClick}
              expand={expand}
              renderOption={renderOption}
              depth={depth + 1}
              path={[...path, option]}
            />
          ))}
    </>
  );
}

function allValues<V>(option: TreeSelectOption<V>): TreeOptionValue<V>[] {
  if (hasLeaves(option)) {
    if (option.children) {
      return [option.value, ...option.children.flatMap((child) => allValues(child))];
    }
  }
  return [];
}

export interface TreeSelectProps<V> {
  options: TreeSelectOption<V>[];
  defaultValue?: TreeOptionValue<V>;
  onChange: (value: TreeOptionValue<V>) => void;
  onSearchChanged?: (search: string) => void;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  renderOption?: (option: TreeSelectOption<V>) => JSX.Element;
}
export function TreeSelect<V>({
  isDisabled = false,
  options,
  onChange,
  onSearchChanged,
  placeholder = "Select...",
  defaultValue,
  isLoading = false,
  renderOption = DefaultRenderOption,
}: TreeSelectProps<V>): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const keyPrefix = React.useState(Math.ceil(_.random(9999)))[0];
  const [selectedOption, setSelectedOption] = React.useState<TreeSelectOption<V> | undefined>(
    defaultValue ? _.last(findSelectedOption(options, defaultValue)) : undefined
  );

  const [search, setSearch] = React.useState(selectedOption?.label || "");
  const [expandedValues, setExpandedValues] = React.useState<TreeOptionValue<V>[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedOption?.value !== defaultValue) {
      const newSelectedOption = defaultValue
        ? _.last(findSelectedOption(options, defaultValue))
        : undefined;
      setSelectedOption(newSelectedOption);
      if (newSelectedOption?.label) {
        setSearch(newSelectedOption.label);
      }
    }
  }, [defaultValue, isLoading]);

  const expand = (...values: TreeOptionValue<V>[]) => {
    setExpandedValues(_.union(expandedValues, values));
  };

  const collapse = (...values: TreeOptionValue<V>[]) => {
    setExpandedValues(_.difference(expandedValues, values));
  };

  const toggleExpanded = (value: TreeOptionValue<V>) => {
    if (expandedValues.includes(value)) {
      collapse(value);
    } else {
      expand(value);
    }
  };

  const handleClose = () => {
    setExpandedValues([]);
    onSearchChanged?.("");
    onClose();
  };

  const handleOpen = () => {
    const path = findSelectedOption(options, selectedOption?.value);
    if (path) {
      expand(...path.map((p) => p.value));
    }
    onOpen();
  };

  const debouncedOnSearchChanged = React.useCallback(
    _.debounce((search: string) => {
      onSearchChanged?.(search);
    }, 250),
    [onSearchChanged]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnSearchChanged(e.target.value);
    setSearch(e.target.value);
  };

  const onClick = (option: TreeSelectOption<V>, path: TreeSelectOption<V>[]) => {
    if (option.children) {
      toggleExpanded(option.value);
    } else if (option.value !== selectedOption?.value) {
      handleClose();
      setSelectedOption(option);
      setSearch(option.label);
      onChange(option.value);
    } else {
      setSelectedOption(undefined);
      onChange(null);
    }
  };

  useEffect(() => {
    if (search !== "" && selectedOption && search !== selectedOption?.label) {
      setExpandedValues(options.flatMap((o) => allValues(o)));
    }
  }, [options]);

  return (
    <>
      <Box w={"100%"}>
        <Popover
          autoFocus={false}
          isOpen={isOpen}
          onClose={handleClose}
          onOpen={() => {}}
          matchWidth>
          <PopoverAnchor>
            <Box>
              <HStack
                border={"1px solid"}
                borderColor={"inherit"}
                borderRadius={"md"}
                w={"100%"}
                bg={useColorModeValue("auto", "gray.800")}>
                <Box pl={2} width={"100%"} display={["none", "none", "flex"]}>
                  <Input
                    width={"100%"}
                    isDisabled={isDisabled}
                    ref={inputRef}
                    variant={"ghost"}
                    bg={useColorModeValue("auto", "gray.800")}
                    type="select"
                    onFocus={handleOpen}
                    placeholder={placeholder}
                    value={search}
                    onBlur={(e) => {
                      if (!popoverRef.current?.contains(e.relatedTarget)) {
                        handleClose();
                      }
                    }}
                    onChange={handleSearchChange}
                  />
                  <Flex
                    pr={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={() => {
                      inputRef.current?.select();
                    }}>
                    <ChevronDownIcon boxSize={4} />
                  </Flex>
                </Box>
                <Flex pl={2} width={"100%"} display={["flex", "flex", "none"]}>
                  <Button
                    width={"100%"}
                    variant={"ghost"}
                    bg={useColorModeValue("auto", "gray.800")}
                    _active={{}}
                    _hover={{}}
                    _pressed={{}}
                    onClick={handleOpen}
                    px={0}
                    justifyContent={"start"}
                    maxWidth={"24ch"}>
                    <Flex width={"100%"}>
                      <Text fontWeight={"light"} isTruncated textAlign={"left"}>
                        {search || placeholder}
                      </Text>
                      <Flex pr={2} justifyContent={"center"} alignItems={"center"}>
                        <ChevronDownIcon boxSize={4} />
                      </Flex>
                    </Flex>
                  </Button>
                </Flex>
              </HStack>
            </Box>
          </PopoverAnchor>
          <PopoverContent width={"max-content"} minWidth={"100%"} maxWidth={"90vw"}>
            <Box ref={popoverRef}>
              {isLoading ? (
                <Flex justifyContent={"center"} p={2}>
                  <Spinner />
                </Flex>
              ) : (
                <VStack
                  w={"100%"}
                  maxH={"50vh"}
                  py={2}
                  mt={2}
                  overflowY={"scroll"}
                  overflowX={"hidden"}
                  textOverflow={"ellipsis"}
                  gap={0}>
                  {options.length === 0 ? (
                    <Text>No results found</Text>
                  ) : (
                    options.map((option) => {
                      const valueStringForKey = JSON.stringify(option.value);
                      return (
                        <SelectOptionRow
                          key={`treeselect-${keyPrefix}-${option.type}-${valueStringForKey}-${option.label}`}
                          keyPrefix={keyPrefix}
                          option={option}
                          renderOption={renderOption}
                          expandedValues={expandedValues}
                          selectedOption={selectedOption}
                          onClick={onClick}
                          expand={expand}
                          collapse={collapse}
                        />
                      );
                    })
                  )}
                </VStack>
              )}
            </Box>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
}
