import React, { useMemo, useState } from "react";
import { Collection, RecordLink, ConfirmationButton, Select } from "@sciencecorp/helix-components";

import { Flex, Text, Box } from "@chakra-ui/react";
import { ArchetypeShowData, useConsolidateArchetype } from "../../api/archetype";
import { useArchetypeOptions } from "../../api/options";
import { useNavigate } from "react-router";
import { userHasRole, useCurrentUserQuery } from "../../api/user";

export const ArchetypesTablePage = ({
  data,
  isLoading,
  onPagination,
  pagination,
  onOrder,
  order,
  editable,
}) => {
  const navigate = useNavigate();
  const { mutate: consolidateArchetype } = useConsolidateArchetype();
  const currentUserQuery = useCurrentUserQuery();

  const isRecruitingAdmin = userHasRole(currentUserQuery, "recruiting_admin") || false;
  const isCeo = userHasRole(currentUserQuery, "ceo") || false;

  const [archetypeToMerge, setArchetypeToMerge] = useState<number | null>(null);

  const archetypeOptions = useArchetypeOptions();
  const columns = useMemo(
    () => [
      {
        label: "Archetype",
        render: (archetype: ArchetypeShowData) => (
          <RecordLink identifier={archetype.name} link={`/hiring/roles/${archetype.id}`} />
        ),
      },
      { label: "Hired Count", render: (archetype: ArchetypeShowData) => archetype.hired_count },
      {
        label: "Actions",
        render: (archetype: ArchetypeShowData) => (
          <ConfirmationButton
            label="Merge"
            size="xs"
            variant="Button"
            colorScheme="teal"
            confirmationHeader="Merge Archetype"
            confirmationButtonLabel="Merge"
            onConfirm={() => {
              if (archetypeToMerge) {
                consolidateArchetype(
                  { id: archetype.id, new_archetype_id: archetypeToMerge },
                  { onSuccess: () => setArchetypeToMerge(null) }
                );
              }
            }}>
            <Flex direction="column" gap={3}>
              <Text>
                Which archetype would you like to merge{" "}
                <Box as="span" fontWeight="semibold">
                  {archetype.name}{" "}
                </Box>
                into?
              </Text>
              <Select
                options={archetypeOptions}
                value={archetypeToMerge}
                onChange={(value) => {
                  if (value) setArchetypeToMerge(Number(value));
                }}
              />
            </Flex>
          </ConfirmationButton>
        ),
      },
    ],
    [data, archetypeOptions]
  );

  if (!editable) {
    navigate("/hiring/completed_interviews");
    return <Text>Unauthorized. Redirecting to feedback page</Text>;
  }

  return (
    <Flex direction="column">
      <Collection
        key="archetypes"
        items={data?.results || []}
        isLoading={isLoading}
        columns={columns}
        pagination={data?.pagination || pagination}
        onPagination={onPagination}
        order={order}
        onOrder={onOrder}
      />
    </Flex>
  );
};
