import { ChevronRightIcon } from "@chakra-ui/icons";
import { Stack, HStack, Text, Flex } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useCreateMeetingNotes, useSearchMeeting } from "../../api/meeting_notes";
import { useCurrentUserQuery } from "../../api/user";
import { DateTime } from "luxon";
import { MeetingNavBarItems } from "./MeetingItemInfo";

export const MeetingNotesNavBar = () => {
  const navigate = useNavigate();
  const { mutateAsync: createMeetingNotes } = useCreateMeetingNotes();
  const { data: currentUser } = useCurrentUserQuery();
  const { data: recentMeetingNotes } = useSearchMeeting({
    term: "*",
    order: { created_at: "desc" },
    pagination: { per_page: 5 },
  });

  const handleCreateMeetingNote = () => {
    if (!currentUser) return;

    createMeetingNotes({
      title: "Meeting",
      user_id: currentUser.id,
      meeting_time: DateTime.now(),
    })
      .then((data) => {
        navigate(`/meeting_notes/${data.id}`);
      })
      .catch((error) => {
        console.error("Error creating meeting notes:", error);
      });
  };

  return (
    <Stack direction={"column"} cursor={"pointer"}>
      {recentMeetingNotes?.results.map((meeting) => (
        <MeetingNavBarItems key={meeting.id} meeting={meeting} />
      ))}
      {recentMeetingNotes && (
        <HStack
          role="group"
          justifyContent={"space-between"}
          onClick={() => navigate("/meeting_notes")}>
          <Text>My Meeting Notes</Text>
          <Flex
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            align={"center"}
            flex={1}
            justify={"flex-end"}
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}>
            <ChevronRightIcon />
          </Flex>
        </HStack>
      )}
      <HStack role="group" justifyContent={"space-between"} onClick={handleCreateMeetingNote}>
        <Text>Start a meeting note</Text>
        <Flex
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          align={"center"}
          flex={1}
          justify={"flex-end"}
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}>
          <ChevronRightIcon />
        </Flex>
      </HStack>
    </Stack>
  );
};
