import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { CollectionTable, EmptyState, FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { BsBoxSeam } from "react-icons/bs";
import { useCreatePurchaseRefund } from "../../../../api/purchase_refunds";
import {
  PurchaseReturnLineItemsShowData,
  PurchaseReturnsShowData,
} from "../../../../api/purchase_returns";
import { useCurrentUserQuery } from "../../../../api/user";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyInput } from "../../../MoneyInput";
import { MoneyText } from "../../../MoneyText";

type FormValuesRefundModalType = {
  purchase_return_id: number;
  type_of_refund: string;
  amount_refunded: Money;
  total_cost: Money;
  index: number;
};

type RefundModalProps = {
  isOpen: boolean;
  onClose: () => void;
  returns: PurchaseReturnsShowData[];
};

export const RefundModal = ({ isOpen, onClose, returns }: RefundModalProps) => {
  const currency = useCurrency();
  const currentUser = useCurrentUserQuery();
  const { setValue, control, watch, reset } = useForm<FormValuesRefundModalType>({
    defaultValues: {
      type_of_refund: "",
      amount_refunded: Money.zero(currency),
      total_cost: Money.zero(currency),
      index: 0,
    },
  });
  const formValues = watch();
  const { mutate: createPurchaseRefund, isLoading: isCreatePurchaseLoading } =
    useCreatePurchaseRefund();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = (purchaseRefundFormData: FormValuesRefundModalType) => {
    const purchaseRefundData = {
      purchase_return_id: purchaseRefundFormData.purchase_return_id,
      type_of_refund: purchaseRefundFormData.type_of_refund,
      amount_refunded: purchaseRefundFormData.amount_refunded,
      purchase_id: returns[0].purchase_id,
    };
    createPurchaseRefund(purchaseRefundData, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const columns = [
    {
      label: "Name",
      render: (item: PurchaseReturnLineItemsShowData) => item.name,
    },
    {
      label: "Quanity",
      render: (item: PurchaseReturnLineItemsShowData) => {
        return item.quantity;
      },
    },
    {
      label: "Total Cost",
      render: (item: PurchaseReturnLineItemsShowData) => {
        return <MoneyText money={item.amount.times(item.quantity)} />;
      },
    },
  ];

  const disableSubmitButton =
    formValues.amount_refunded.isNeg() ||
    !formValues.type_of_refund ||
    !formValues.purchase_return_id;
  return (
    <FormModal
      title="Add Refund"
      submitButtonColorSchema="teal"
      submitButtonTitle="Add Refund"
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isCreatePurchaseLoading}
      handleSubmit={() => {
        currentUser?.data?.id && handleSubmit(formValues);
      }}
      submitButtonDisabled={disableSubmitButton || isCreatePurchaseLoading}>
      <Controller
        name="purchase_return_id"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Select all relevant items for the refund</FormLabel>
            <Flex direction="column" gap={4}>
              {returns.length > 0 ? (
                returns.map((lineItem, idx) => (
                  <>
                    <HStack
                      key={`line-item-${lineItem.id}`}
                      _hover={{ cursor: "pointer" }}
                      p={3}
                      bg={
                        lineItem.id === formValues.purchase_return_id
                          ? useColorModeValue("teal.50", "teal.500")
                          : "chakra-bg-color"
                      }
                      spacing={6}
                      border="1px"
                      borderRadius="md"
                      borderColor={
                        lineItem.id === formValues.purchase_return_id
                          ? useColorModeValue("teal.200", "teal.400")
                          : "chakra-border-color"
                      }
                      onClick={() => {
                        field.onChange(lineItem.id);
                        setValue("index", idx);
                        setValue("total_cost", returns[idx].total_returned);
                      }}>
                      <Icon as={BsBoxSeam} />
                      <Text noOfLines={1}>
                        {lineItem.purchase_return_line_items
                          .map((item) => `${item.name}`)
                          .join(", ")}
                      </Text>
                      <Spacer />
                      {lineItem.id === formValues.purchase_return_id ? (
                        <CheckCircleIcon
                          color={useColorModeValue("teal.500", "teal.200")}
                          boxSize={7}
                        />
                      ) : (
                        <Box
                          border="2px"
                          borderRadius="full"
                          borderColor="chakra-border-color"
                          p={3}
                        />
                      )}
                    </HStack>
                  </>
                ))
              ) : (
                <EmptyState size="2xs" title="No returns are available for a refund" />
              )}
            </Flex>
          </FormControl>
        )}
      />
      {returns.length > 0 && (
        <>
          {formValues.index >= 0 && (
            <>
              <VStack width={"100%"}>
                <CollectionTable
                  columns={columns}
                  items={returns[formValues.index]?.purchase_return_line_items}
                />
              </VStack>
              <HStack alignSelf={"baseline"}>
                <Text color={"blacks"} fontSize={"sm"}>
                  Total Cost:{" "}
                </Text>
                <MoneyText
                  money={formValues.total_cost}
                  formatOptions={{ compact: "never" }}
                  fontWeight={"bold"}
                  fontSize={"md"}
                />
              </HStack>
            </>
          )}

          <Controller
            name="amount_refunded"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl isRequired>
                <VStack width={"100%"} alignItems={"start"} spacing={".5"}>
                  <FormLabel justifyContent={"start"}>Amount Refunded</FormLabel>
                  <MoneyInput {...field} />
                </VStack>
              </FormControl>
            )}
          />

          <Controller
            name="type_of_refund"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>The item was</FormLabel>
                <RadioGroup
                  onChange={(value) => setValue("type_of_refund", value)}
                  value={formValues.type_of_refund}
                  colorScheme={"teal"}>
                  <VStack align="start" gap={1}>
                    <Radio value="refunded">Returned to vendor</Radio>
                    <Radio value="sold">Sold</Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>
            )}
          />
        </>
      )}
    </FormModal>
  );
};
