import {
  Avatar,
  Stack,
  Box,
  HStack,
  Table,
  Tag,
  Heading,
  Flex,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, PaginationControls } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { useGetOnboardingReviewFeedbacks } from "../../api/review_feedback";
import { useReviewMetaQuery, useReviewPromptsQuery } from "../../api/review";
import { UserPartialData, useCurrentUserQuery } from "../../api/user";
import { NewReview } from "./newReview";
import { OnboardingFeedbackModal } from "./components/OnboardingFeedbackModal";
import { humanize, titleize } from "inflection";

export const UserPill = (props: { user: { id: number; name: string; picture_uri: string } }) => {
  return (
    <HStack>
      <Avatar src={props.user.picture_uri} size="xs" name={props.user.name} ml={-1} mr={2} />
      <Text>{props.user.name}</Text>
    </HStack>
  );
};

export const YourReviews = ({ reviewFeedbackQuery }) => {
  const reviewMetaQuery = useReviewMetaQuery();
  const currentUserQuery = useCurrentUserQuery();
  const { data: onboardingReviewFeedbacks, refetch: onboardingReviewFeedbackRefetch } =
    useGetOnboardingReviewFeedbacks();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [feedback, setFeedback] = useState(null);

  const [paginationPage, setPaginationPage] = useState({
    page: 1,
    per_page: 10,
    n_pages: 1,
    total: 0,
  });
  const reviewPromptsQuery = useReviewPromptsQuery(paginationPage);

  useEffect(() => {
    if (reviewPromptsQuery.isSuccess) setPaginationPage(reviewPromptsQuery.data.pagination);
  }, [reviewPromptsQuery]);

  return (
    <Stack width={{ base: "100%", lg: "50%" }} spacing={8}>
      <Box>
        <Heading size="md" fontWeight="semibold" mb={3}>
          Review Requests
        </Heading>
        <Text color={useColorModeValue("gray.700", "gray.400")}>
          Share your insights and observations about a colleague's performance to foster growth,
          collaboration, and overall team success.
        </Text>
      </Box>
      {onboardingReviewFeedbacks &&
        onboardingReviewFeedbacks.length &&
        onboardingReviewFeedbacks.map((feedback) => (
          <Stack
            width="100%"
            justify="space-between"
            align={["none", "center"]}
            p={3}
            direction={["column", "row"]}
            bg={useColorModeValue("green.50", "gray.700")}
            border="1px"
            borderRadius="md"
            borderColor={useColorModeValue("green.200", "gray.500")}>
            <HStack spacing={3}>
              <Avatar size={["sm", "md"]} src={feedback.onboarding_user.picture_uri} />
              <VStack align="start" spacing={0}>
                <Text
                  color={useColorModeValue("green.500", "gray.200")}
                  fontWeight="semibold"
                  fontSize="sm">
                  Today is {feedback.onboarding_user.name}'s {feedback.occasion} Review
                </Text>
                <Text fontSize="xs">
                  Remember to have a conversation before revealing the result
                </Text>
              </VStack>
            </HStack>
            <Button
              size="sm"
              colorScheme="teal"
              onClick={() => {
                onOpen();
                setFeedback(feedback);
              }}>
              View Result
            </Button>
          </Stack>
        ))}
      {feedback && (
        <OnboardingFeedbackModal
          isOpen={isOpen}
          onClose={onClose}
          encryptedFeedback={feedback}
          reviewFeedbackQuery={reviewFeedbackQuery}
          onboardingReviewFeedbackRefetch={() => onboardingReviewFeedbackRefetch()}
        />
      )}

      <Table
        variant="simple"
        size="sm"
        border={reviewPromptsQuery.data?.results.length ? ["1px", "none"] : "none"}
        borderColor={useColorModeValue("gray.200", "gray.600")}>
        <Thead display={["none", "table-header-group"]}>
          <Tr>
            <Th>Reviewee</Th>
            <Th>Type</Th>
            <Th>Prompted</Th>
            <Th textAlign="end">Review</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reviewPromptsQuery.data &&
            reviewPromptsQuery.data.results.map((reviewPrompt) => {
              return (
                <Tr
                  key={reviewPrompt.id}
                  fontWeight={
                    reviewPrompt.review || reviewPrompt.declined_at || reviewPrompt.expired_at
                      ? "normal"
                      : "bold"
                  }
                  bg={
                    reviewPrompt.review || reviewPrompt.declined_at || reviewPrompt.expired_at
                      ? useColorModeValue("gray.50", "gray.800")
                      : useColorModeValue("white", "gray.700")
                  }
                  display={["flex", "table-row"]}
                  gap={1.5}
                  flexDirection={["column", "row"]}
                  justifyContent={"flex-start"}
                  flexWrap="wrap"
                  borderBottom="1px"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  paddingBlock={3}>
                  <Td flexBasis="25%" borderBottom="none" py={0}>
                    <Flex
                      flexWrap={["wrap", "nowrap"]}
                      justifyContent="space-between"
                      align="center">
                      <Text
                        display={["block", "none"]}
                        textTransform={"uppercase"}
                        fontSize="xs"
                        color="gray.500"
                        letterSpacing={"wider"}
                        fontWeight={"bold"}>
                        Reviewee
                      </Text>
                      <UserPill user={reviewPrompt.reviewee} />
                    </Flex>
                  </Td>
                  <Td flexBasis="25%" borderBottom="none" py={[0, 4]}>
                    <Flex flexWrap={["wrap", "nowrap"]} justifyContent="space-between">
                      <Text
                        display={["block", "none"]}
                        textTransform={"uppercase"}
                        fontSize="xs"
                        color="gray.500"
                        letterSpacing={"wider"}
                        fontWeight={"bold"}>
                        Type
                      </Text>
                      {titleize(humanize(reviewPrompt.prompt_type))}
                    </Flex>
                  </Td>
                  <Td flexBasis="25%" borderBottom="none" py={[0, 4]}>
                    <Flex flexWrap={["wrap", "nowrap"]} justifyContent="space-between">
                      <Text
                        display={["block", "none"]}
                        textTransform={"uppercase"}
                        fontSize="xs"
                        color="gray.500"
                        letterSpacing={"wider"}
                        fontWeight={"bold"}>
                        Prompted
                      </Text>
                      {DateTime.fromISO(reviewPrompt.created_at).toFormat("MMM dd yyyy")}
                    </Flex>
                  </Td>
                  <Td flexBasis="25%" borderBottom="none" py={0}>
                    <Flex
                      flexWrap={["wrap", "nowrap"]}
                      justifyContent={["space-between", "flex-end"]}
                      align="center">
                      <Text
                        display={["block", "none"]}
                        textTransform={"uppercase"}
                        fontSize="xs"
                        color="gray.500"
                        letterSpacing={"wider"}
                        fontWeight={"bold"}>
                        Review
                      </Text>
                      {reviewPrompt.declined_at ? (
                        <Tag colorScheme="red">Declined</Tag>
                      ) : reviewPrompt.expired_at ? (
                        <Tag colorScheme="orange">Expired</Tag>
                      ) : reviewPrompt.review ? (
                        <Tag>Complete</Tag>
                      ) : reviewMetaQuery.isSuccess && currentUserQuery.isSuccess ? (
                        currentUserQuery.data?.public_key ? (
                          <NewReview
                            reviewPrompt={reviewPrompt}
                            adminKeys={reviewMetaQuery.data}
                            currentUser={currentUserQuery.data}
                            reviewFeedbackQuery={reviewFeedbackQuery}
                            onCreate={() => reviewPromptsQuery.refetch()}
                          />
                        ) : (
                          <Link href={`/users/${currentUserQuery.data.id}/settings`}>
                            Configure PIN
                          </Link>
                        )
                      ) : (
                        "Loading..."
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          {reviewPromptsQuery.data && !reviewPromptsQuery.data.results.length && (
            <Tr borderBottom="none">
              <Td colSpan={3} borderBottom="none">
                <Text opacity="0.5" pt={3} lineHeight="shorter" fontWeight="semibold">
                  No review requests yet. These are automatically generated at various times; you
                  will receive a notification when a review request is waiting for you.
                </Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {reviewPromptsQuery.data && reviewPromptsQuery.data.pagination.n_pages > 1 ? (
        <PaginationControls
          pagination={paginationPage}
          onPagination={(newPagination) => setPaginationPage(newPagination)}
        />
      ) : null}
    </Stack>
  );
};
