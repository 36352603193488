import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { api } from ".";
import { decryptPrivateKey, Keyring } from "../keyring";
import { paginationSchema } from "./shared";
import { UserLoggedInData, userMinimalSchema, userPartialSchema } from "./user";
import { zodParse } from "./zodParse";

export const REVIEWS_BASE_URL = "reviews";
export const REVIEW_PROMPTS_BASE_URL = "review_prompts";
export const REVIEW_PROMPT_EDGES_BASE_URL = "review_prompt_edges";

export const reviewPartialSchema = z.object({
  id: z.number(),
  keyring_message: z.object({
    id: z.number(),
    content: z.string(),
    message_type: z.string(),
    created_at: z.string(),
  }),
  created_at: z.string(),
});
export const reviewPromptPartialSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  prompt_type: z.string(),
  occasion: z.string().nullable(),
  completed_at: z.string().nullable(),
  declined_at: z.string().nullable(),
  expired_at: z.string().nullable(),
  reviewer: userMinimalSchema,
  reviewee: userMinimalSchema,
  parent_prompt: z
    .object({
      id: z.number(),
      review: z.lazy(() => reviewPartialSchema),
    })
    .optional(),
});
export const reviewPromptEdgePartialSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  completed_at: z.string().nullable(),
  reviewee: z.lazy(() => userPartialSchema),
});
export const reviewIndexSchema = z.array(
  reviewPartialSchema.extend({
    review_prompt: reviewPromptPartialSchema,
  })
);
export const reviewPromptIndexItem = reviewPromptPartialSchema.extend({
  review: reviewPartialSchema.optional(),
});
export const reviewPromptEdgeIndexSchema = z.array(reviewPromptEdgePartialSchema);
export const reviewAdminStatsIndexSchema = z.array(
  userPartialSchema.extend({
    outstanding: z.number(),
    oldest: z.string().nullable(),
    total: z.number(),
  })
);
export const reviewMetaSchema = z.object({
  ceo_key: z.string(),
  hr_admin_keys: z.array(z.string()),
});

export const ReviewPromptsIndexSchema = z.object({
  results: z.array(reviewPromptIndexItem),
  pagination: paginationSchema,
});

export type ReviewPartialData = z.infer<typeof reviewPartialSchema>;
export type ReviewPromptPartialData = z.infer<typeof reviewPromptPartialSchema>;
export type ReviewPromptEdgePartialData = z.infer<typeof reviewPromptEdgePartialSchema>;

export type ReviewIndexData = z.infer<typeof reviewIndexSchema>;
export type ReviewPromptIndexData = z.infer<typeof reviewPromptIndexItem>;
export type ReviewPromptEdgeIndexData = z.infer<typeof reviewPromptEdgeIndexSchema>;
export type ReviewAdminStatsIndexData = z.infer<typeof reviewAdminStatsIndexSchema>;
export type ReviewMetaData = z.infer<typeof reviewMetaSchema>;

/** TODO: Review query-keys for all hooks here */

/** queries reviews */
export const getReviews = async () => {
  const result = await api.get(REVIEWS_BASE_URL);
  return zodParse(reviewIndexSchema, result.data);
};
export const getReviewAdminStats = async () => {
  const result = await api.get(`${REVIEWS_BASE_URL}/admin`);
  return zodParse(reviewAdminStatsIndexSchema, result.data);
};
export const getReviewsMeta = async () => {
  const result = await api.get(`${REVIEWS_BASE_URL}/meta`);
  return zodParse(reviewMetaSchema, result.data);
};

export type NewReviewParams = {
  reviewPromptId: number;
  score: number;
  anonymousFeedback: string;
  publicKeys: string[];
  type: string;
  technicalScore?: number;
  impactScore?: number;
  confidentialFeedback?: string;
};
export const newReview = async ({
  reviewPromptId,
  score,
  anonymousFeedback,
  publicKeys,
  type,
  technicalScore,
  impactScore,
  confidentialFeedback,
}: NewReviewParams) => {
  const content = JSON.stringify({
    score,
    technical_score: technicalScore,
    impact_score: impactScore,
    anonymous_feedback: anonymousFeedback,
    confidential_feedback: confidentialFeedback,
  });

  const keyring = new Keyring(publicKeys);
  keyring.setContent(content);
  const sealedReview = keyring.getSealedMessage();

  return await api.post(REVIEWS_BASE_URL, {
    review: {
      review_prompt_id: reviewPromptId,
      keyring_message: sealedReview,
      keyring_message_type: type,
    },
  });
};

export const getIncompleteReviewPrompts = async () => {
  const result = await api.get(`${REVIEW_PROMPTS_BASE_URL}/incomplete`);
  return result.data;
};

export const generateReviewPromptsForReviewee = async ({ revieweeEmail, reviewerEmails }) => {
  return api.post(`${REVIEW_PROMPTS_BASE_URL}/generate_for_reviewee`, {
    reviewee_email: revieweeEmail,
    reviewer_emails: reviewerEmails,
  });
};

export const generateReviewPromptsForAllPairs = async ({ emails }) => {
  return api.post(`${REVIEW_PROMPTS_BASE_URL}/generate_all_pairs`, {
    emails,
  });
};

/** hooks reviews */
export const useReviewsQuery = () => {
  return useQuery({
    queryKey: [REVIEWS_BASE_URL],
    queryFn: getReviews,
  });
};

export const useIncompleteReviewPromptsQuery = () => {
  return useQuery({
    queryKey: [REVIEW_PROMPTS_BASE_URL],
    queryFn: getIncompleteReviewPrompts,
  });
};
export const useReviewAdminStatsQuery = () => {
  return useQuery({
    queryKey: [REVIEWS_BASE_URL],
    queryFn: getReviewAdminStats,
  });
};
export const useReviewMetaQuery = () => {
  return useQuery({
    queryKey: [REVIEWS_BASE_URL],
    queryFn: getReviewsMeta,
  });
};
export const decryptReview = async (
  review: ReviewPartialData,
  user: UserLoggedInData,
  pin: string
) => {
  const keyring = new Keyring([]);
  if (!user.encrypted_private_key) return false;

  const privateKey = decryptPrivateKey(user.encrypted_private_key, pin);
  if (!privateKey) return false;
  const success = keyring.decrypt(privateKey, review.keyring_message.content);
  if (!success) return false;
  return JSON.parse(keyring.getContent());
};

/** queries review_prompts */
export const getReviewPrompts = async (params?: object | null) => {
  const result = await api.get(REVIEW_PROMPTS_BASE_URL, { params });
  return zodParse(ReviewPromptsIndexSchema, result.data);
};

export const updateReviewPrompt = async (reviewPrompt: Partial<ReviewPromptIndexData>) => {
  return api.put(`${REVIEW_PROMPTS_BASE_URL}/${reviewPrompt.id}`, { review_prompt: reviewPrompt });
};

/** hooks review_prompts */
export const invalidateReviewPrompts = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [REVIEW_PROMPTS_BASE_URL],
    });
};

export const useReviewPromptsQuery = (indexParams?: Object | null) => {
  return useQuery({
    queryKey: [REVIEW_PROMPTS_BASE_URL, indexParams],
    queryFn: () => getReviewPrompts(indexParams),
  });
};

export const useUpdateReviewPrompts = () => {
  return useMutation({
    mutationFn: updateReviewPrompt,
    onSuccess: invalidateReviewPrompts(),
  });
};

/** queries review_prompt_edges */
export const getReviewPromptEdges = async () => {
  const result = await api.get(REVIEW_PROMPT_EDGES_BASE_URL);
  return zodParse(reviewPromptIndexItem, result.data);
};
export const newReviewPromptEdge = async (reviewee_id: string | number) => {
  return api.post(REVIEW_PROMPT_EDGES_BASE_URL, { review_prompt_edge: { reviewee_id } });
};
export const deleteReviewPromptEdge = async (id: string | number) => {
  await api.delete(`${REVIEW_PROMPT_EDGES_BASE_URL}/${id}`);
};

/** hooks review_prompt_edges */
export const useReviewPromptEdgesQuery = () => {
  return useQuery({
    queryKey: [REVIEW_PROMPT_EDGES_BASE_URL],
    queryFn: getReviewPromptEdges,
  });
};
export const useNewReviewPromptEdge = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: newReviewPromptEdge,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [REVIEW_PROMPT_EDGES_BASE_URL] }),
  });
};
export const useDeleteReviewPromptEdge = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteReviewPromptEdge,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [REVIEW_PROMPT_EDGES_BASE_URL] }),
  });
};
