import { Flex, Heading, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useGetSpendByExpenseType } from "../../../../api/finance";
import { useGetPurchasePaymentScorecards } from "../../../../api/purchase_payment";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";

export const DashboardSummary = () => {
  const currency = useCurrency();
  const [financialData, setFinancialData] = useState({
    spentThisMonth: Money.zero(currency),
    committedSpending: Money.zero(currency),
    projectedYearlySpend: Money.zero(currency),
  });
  const [loading, setLoading] = useState(true);

  const today = useMemo(() => DateTime.local(), []);
  const firstDayOfMonth = useMemo(() => today.startOf("month"), [today]);
  const currentMonthName = today.toFormat("MMMM");

  const {
    data: scorecardsData,
    isLoading: isLoadingScorecards,
    error: isErrorScorecards,
  } = useGetPurchasePaymentScorecards(false);

  const {
    data: spendData,
    isLoading: isLoadingSpend,
    isError: isErrorSpend,
  } = useGetSpendByExpenseType(firstDayOfMonth, today);

  const {
    data: spendData2Weeks,
    isLoading: isLoadingSpend2Weeks,
    isError: isErrorSpend2Weeks,
  } = useGetSpendByExpenseType(today.minus({ days: 14 }), today);

  useEffect(() => {
    if (scorecardsData && spendData && spendData2Weeks) {
      const totalSpentThisMonth = Money.sum(
        spendData.contracts_total,
        spendData.purchases_total,
        spendData.subscriptions_total
      );

      const currentMonthScorecards = scorecardsData.scorecards.filter((card) =>
        DateTime.fromISO(card.month).hasSame(today, "month")
      );

      const committedSpending = currentMonthScorecards.reduce(
        (acc, card) => acc.add(card.total),
        Money.zero(currency)
      );

      const twoWeeksSpend = Money.sum(
        spendData2Weeks.contracts_total,
        spendData2Weeks.purchases_total,
        spendData2Weeks.subscriptions_total
      );

      const projectedYearlySpend = twoWeeksSpend.times(26);

      setFinancialData({
        spentThisMonth: totalSpentThisMonth,
        committedSpending,
        projectedYearlySpend,
      });
      setLoading(false);
    }
  }, [isLoadingScorecards, isLoadingSpend, isLoadingSpend2Weeks]);

  if (loading) {
    return <Spinner />;
  }
  if (isErrorScorecards || isErrorSpend || isErrorSpend2Weeks) {
    return <Text>Error loading data</Text>;
  }

  return (
    <Flex
      direction="column"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      w="100%"
      gap={4}
      p={6}>
      <Heading as="h2" size="md">
        {currentMonthName} Summary
      </Heading>
      <Text fontSize="md">
        Science has spent{" "}
        <Tooltip label="Sum of all completed transactions this month">
          <MoneyText
            as="span"
            fontWeight="semibold"
            textDecoration="underline dotted"
            textDecorationThickness={"from-font"}
            cursor="help"
            money={financialData.spentThisMonth}
          />
        </Tooltip>
        , and is committed to spending{" "}
        <Tooltip label="Sum of all unpaid, scheduled transactions for this month">
          <MoneyText
            as="span"
            fontWeight="semibold"
            textDecoration="underline dotted"
            textDecorationThickness={"from-font"}
            cursor="help"
            money={financialData.committedSpending}
          />
        </Tooltip>{" "}
        more by the end of the month. Based on spending trends from the last two weeks, the
        projected annual expenditure is estimated at{" "}
        <Tooltip label="Annual projection based on spending in the last two weeks">
          <MoneyText
            as="span"
            fontWeight="semibold"
            textDecoration="underline dotted"
            textDecorationThickness={"from-font"}
            cursor="help"
            money={financialData.projectedYearlySpend}
          />
        </Tooltip>
        .
      </Text>
    </Flex>
  );
};
