import React, { useEffect, useState } from "react";
import { BudgetOption, BudgetValue, useBudgetTreeOptions } from "../../api/budget_tree";
import { TreeSelect } from "../shared/TreeSelect";

interface BudgetSelectProps {
  onChange: (budgetId: BudgetValue) => void;
  budgetId: number;
  disabled?: boolean;
  emptyOptionText?: string;
}

export const BudgetSelect = ({ onChange, budgetId, emptyOptionText }: BudgetSelectProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [value, setValue] = useState<BudgetValue>(budgetId);
  const budgetTreeQuery = useBudgetTreeOptions(searchTerm, emptyOptionText);

  useEffect(() => {
    setValue(budgetId);
  }, [budgetId]);

  const options: BudgetOption[] = budgetTreeQuery.data || [];
  return (
    <TreeSelect
      isLoading={budgetTreeQuery.isLoading}
      options={options}
      onChange={onChange}
      defaultValue={value}
      onSearchChanged={(search) => {
        setSearchTerm(search);
      }}
    />
  );
};
