import { InfoOutlineIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { useColorModeValue, HStack, VStack, Text, Flex } from "@chakra-ui/react";
import React from "react";

export const ReimbursementInstructionsTable = () => {
  const instructions = [
    "To streamline processing, we recommend submitting one reimbursement request for each 7-day spending period.",
    "Please aim to submit requests within 30 days of the original event.",
    "Reimbursements will be processed only after all receipts are received and full approval is granted.",
  ];

  return (
    <Flex direction={"column"} maxWidth={"100%"}>
      <Flex
        direction={"column"}
        bg={useColorModeValue("gray.50", "gray.700")}
        borderTopRightRadius="md"
        borderTopLeftRadius="md"
        width={"100%"}
        border="1px"
        borderColor="chakra-border-color"
        align={"start"}
        p={4}>
        <HStack>
          <QuestionOutlineIcon boxSize={"15px"} />
          <Text fontWeight="semibold" fontSize={"lg"}>
            Guidelines
          </Text>
        </HStack>
      </Flex>
      {instructions.map((instruction, index) => (
        <Flex
          key={index}
          borderBottomLeftRadius={index === instructions.length - 1 ? "md" : "none"}
          borderBottomRightRadius={index === instructions.length - 1 ? "md" : "none"}
          alignItems={"flex-start"}
          width={"100%"}
          border="1px"
          borderColor="chakra-border-color"
          align={"start"}
          p={4}>
          <InfoOutlineIcon boxSize={"15px"} marginRight={4} marginTop={2} />
          <Text fontSize="md">{instruction}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
