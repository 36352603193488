import { Flex, useColorModeValue } from "@chakra-ui/react";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Legend,
  Tooltip
);

const SpendingDonutChart = ({
  remaining,
  committed,
  spent,
  total,
}: {
  remaining: Money;
  committed: Money;
  spent: Money;
  total?: Money;
}) => {
  const currency = useCurrency();
  const isRed = remaining.isNeg();
  const backgroundColor = useColorModeValue(
    isRed ? ["#FF0000"] : ["#EDF2F7", "#FBD38D", "#319795"],
    isRed ? ["#F56565"] : ["#A0AEC0", "#F6AD55", "#319795"] // dark mode
  );

  const fillText = useColorModeValue("black", "white");

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height, ctx } = chart;
      ctx.restore();
      ctx.textBaseline = "middle";

      const text = `${(
        (spent.majorUnits.toNumber() / (total?.majorUnits.toNumber() || 1)) *
        100
      ).toFixed()}%\nspent`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;
      ctx.fillStyle = fillText;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };

  const data = useMemo(() => {
    if (isRed) {
      return {
        labels: ["Remaining"],
        datasets: [
          {
            data: [remaining.majorUnits.toNumber()],
            backgroundColor,
            hoverOffset: 4,
            borderColor: "transparent",
          },
        ],
      };
    } else {
      return {
        labels: ["Remaining", "Committed", "Spent"],
        datasets: [
          {
            data: [
              remaining.majorUnits.toNumber(),
              committed.majorUnits.toNumber(),
              spent.majorUnits.toNumber(),
            ],
            backgroundColor,
            hoverOffset: 4,
            borderColor: "transparent",
          },
        ],
      };
    }
  }, [remaining, committed, spent, backgroundColor, isRed]);

  return (
    <Flex h="125px" w={["100px", "150px"]}>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.raw || 0;
                  const numericValue = Number(value);
                  return `${label}: $${(numericValue / 100).toLocaleString()}`;
                },
              },
            },
          },
        }}
        plugins={[centerTextPlugin]}
      />
    </Flex>
  );
};

export default SpendingDonutChart;
