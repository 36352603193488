import React from "react";
import {
  Heading,
  Text,
  VStack,
  HStack,
  Box,
  useColorModeValue,
  Progress,
  Flex,
} from "@chakra-ui/react";
import { SplitPage } from "@sciencecorp/helix-components";

import { SidebarList, TrainingLink } from "./util";

import { useGetCredential } from "../../api/credentials";
import { TimeIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useGetUserCredential, useSearchUserCredentials } from "../../api/user_credentials";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";

import { credentialColorScheme } from "./util";
import { DateTime } from "luxon";

export const UserCredentialPage = () => {
  const { id } = useParams();
  if (!id) return null;
  const { debouncedSearch, search } = useDebouncedSearch();
  const { data: userCredentialSearch } = useSearchUserCredentials({
    term: search || "*",
    pagination: { per_page: 10 },
  });
  const { data: userCredential } = useGetUserCredential(+id);
  const { data: credential } = useGetCredential(userCredential?.credential_id!);

  return (
    <SplitPage
      sidebar={
        <VStack
          align="start"
          width="100%"
          borderRadius="md"
          spacing={4}
          bg={useColorModeValue("gray.50", "gray.700")}>
          <SidebarList
            data={userCredentialSearch?.results || []}
            selectedItemId={+id}
            title="My Credentials"
            url="/credentials/me"
            breadcrumbTitle="Back to My Credentials"
            debouncedSearch={debouncedSearch}
          />
        </VStack>
      }
      sidebarWidth="300px"
      sidebarWidthXL="350px"
      breakpoint="lg"
      main={
        <>
          <VStack width="100%" align="start" spacing={4} maxW="4xl">
            <Heading size="lg">{credential?.name}</Heading>
            <Flex
              justify="space-between"
              width="100%"
              direction={{ base: "column", md: "row" }}
              gap={6}>
              <HStack spacing={6}>
                <Text fontSize="sm">
                  Date Issued:{" "}
                  <Box as="span" fontWeight="medium">
                    {userCredential?.date_issued
                      ? DateTime.fromISO(userCredential.date_issued).toFormat("LLL dd yyyy")
                      : "-"}
                  </Box>
                </Text>
                <Text fontSize="sm">
                  Expires:{" "}
                  <Box as="span" fontWeight="medium">
                    {userCredential?.expiration_date
                      ? DateTime.fromISO(userCredential.expiration_date).toFormat("LLL dd yyyy")
                      : "N/A"}
                  </Box>
                </Text>
                <Text fontSize="sm">
                  Currency:{" "}
                  <Box as="span" fontWeight="medium">
                    {credential?.currency_days ? credential?.currency_days : "N/A"}
                  </Box>
                </Text>
                <Text fontSize="sm">
                  Visibility:{" "}
                  <Box as="span" fontWeight="medium">
                    {credential?.is_public ? "Public" : "Private"}
                  </Box>
                </Text>
              </HStack>
              {(userCredential?.currency_lapse || userCredential?.expiration_date) && (
                <HStack
                  border="1px"
                  borderColor="chakra-border-color"
                  bg={useColorModeValue("gray.50", "gray.700")}
                  borderRadius="md"
                  p={3}>
                  <TimeIcon boxSize={3} />
                  {userCredential?.currency_lapse ? (
                    <HStack>
                      <Text fontSize="xs">
                        Currency lapses in:{" "}
                        <Box as="span" fontWeight="medium">
                          {userCredential?.currency_lapse} days
                        </Box>
                      </Text>
                      <Progress
                        colorScheme={credentialColorScheme(userCredential?.status).bar}
                        size="sm"
                        hasStripe={true}
                        value={userCredential?.currency_percentage}
                        width={20}
                      />
                    </HStack>
                  ) : userCredential?.expiration_date ? (
                    <HStack w="100%">
                      <Text fontSize="xs">
                        Expires in:{" "}
                        <Box as="span" fontWeight="medium">
                          {userCredential?.days_until_expiration} days
                        </Box>
                      </Text>
                      <Progress
                        colorScheme={credentialColorScheme(userCredential?.status).bar}
                        size="sm"
                        hasStripe={true}
                        value={userCredential?.expiration_percentage}
                        minWidth={20}
                        flexGrow="1"
                      />
                    </HStack>
                  ) : null}
                </HStack>
              )}
            </Flex>
            <VStack width="100%" align="start">
              <Heading size="md" fontWeight="semibold">
                Description
              </Heading>
              {credential?.description ? (
                <Text>{credential?.description}</Text>
              ) : (
                <Text fontSize="sm" as="i">
                  No description yet
                </Text>
              )}
            </VStack>
            {userCredential?.user_training_sessions_for_trainings.length && (
              <TrainingLink
                trainings={userCredential.user_training_sessions_for_trainings}
                status={userCredential?.status}
              />
            )}
          </VStack>
        </>
      }
    />
  );
};
