import React, { useState } from "react";
import { VStack, HStack, Button, Text, ButtonGroup, Spacer, Stack, Box } from "@chakra-ui/react";
import { LuCheckCheck, LuCheck } from "react-icons/lu";
import { FaRegStar } from "react-icons/fa";
import {
  NotificationData,
  useMarkAllNotificationsRead,
  useUpdateNotificationsBulk,
} from "../../api/notifications";
import { NotificationItem } from "./components/NotificationItem";
import { EmptyState } from "../shared/EmptyState";
import { NotificationTabsProps } from "./util";
import { PaginationControls } from "@sciencecorp/helix-components";

export const AllNotificationsTab = ({
  notifications,
  currentUser,
  savedTab,
  pagination,
  onPagination,
}: NotificationTabsProps) => {
  const [selectedNotifications, setSelectedNotifications] = useState<NotificationData[]>([]);

  const { mutate: updateNotificationsBulk } = useUpdateNotificationsBulk();
  const { mutate: markAllNotificationsRead } = useMarkAllNotificationsRead();

  const handleReadUnread = () => {
    const hasUnreadNotifications = selectedNotifications.some((notification) => !notification.read);

    updateNotificationsBulk(
      {
        notification_ids: selectedNotifications.map((notification) => notification.id),
        read: hasUnreadNotifications,
      },
      { onSuccess: () => setSelectedNotifications([]) }
    );
  };

  return (
    <VStack width="100%" align="start" spacing={4}>
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        width="100%"
        border="1px"
        borderRadius="md"
        borderColor="chakra-border-color"
        px={6}
        py={3}>
        {selectedNotifications.length ? (
          <HStack>
            <Text fontSize="sm">{selectedNotifications.length} Items Selected</Text>
            <ButtonGroup variant="ghost" size="sm">
              <Button leftIcon={<LuCheck />} onClick={handleReadUnread}>
                Read/Unread
              </Button>
              <Button colorScheme="orange" leftIcon={<FaRegStar />}>
                Save
              </Button>
            </ButtonGroup>
          </HStack>
        ) : (
          <Spacer />
        )}
        <HStack justify="space-between">
          <ButtonGroup variant="ghost" colorScheme="teal" size="sm">
            <Button
              leftIcon={<LuCheckCheck />}
              onClick={() => {
                markAllNotificationsRead(
                  { action_required: false, saved: savedTab },
                  { onSuccess: () => setSelectedNotifications([]) }
                );
              }}>
              Mark All As Read
            </Button>
          </ButtonGroup>
        </HStack>
      </Stack>

      {notifications.length ? (
        <VStack width="100%" align="start" spacing={0}>
          {notifications.map((notification: NotificationData) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              currentUser={currentUser}
              setSelectedNotifications={setSelectedNotifications}
              selectedNotifications={selectedNotifications}
            />
          ))}
          <Box width={"100%"}>
            {notifications && (
              <PaginationControls pagination={pagination} onPagination={onPagination} />
            )}
          </Box>
        </VStack>
      ) : (
        <EmptyState title="No notifications to show" />
      )}
    </VStack>
  );
};
