import { array, z } from "zod";
import { api } from ".";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { userMinimalSchema } from "./user";
import { invalidateBudget } from "./budget";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { zodParse } from "./zodParse";
import { moneySchema } from "../helpers/Money";

export const BUDGET_HEADCOUNTS_BASE_URL = "budget_headcounts";
export const BUDGET_HEADCOUNT_USERS_BASE_URL = "budget_headcount_users";

export const budgetHeadcountSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  archetype_id: z.number(),
  assessment: z.string(),
  budget_id: z.number(),
  in_greenhouse: z.boolean(),
  hiring_manager_id: z.number(),
  interview_content_test: z.string(),
  job_description: z.string(),
  level: z.string(),
  location: z.string().nullable(),
  notes: z.string().nullable(),
  priority: z.string(),
  posted_online: z.boolean(),
  salary_band_min: moneySchema,
  salary_band_max: moneySchema,
  status: z.string(),
  take_home_test: z.string(),
  team_availability: z.string(),
  total_people: z.number(),
  other_names: z.string().nullable(),
  reviewed_ip_issues: z.boolean(),
});

export const budgetHeadcountUserCreateSchema = z.object({
  budget_headcount_id: z.number(),
  user_ids: z.number().array(),
});

export const budgetHeadcountUserShowSchema = z.object({
  id: z.number(),
  is_lead: z.boolean(),
  user: z.lazy(() => userMinimalSchema),
});

export const budgetHeadcountShowSchema = budgetHeadcountSchema
  .omit({
    budget_id: true,
    hiring_manager_id: true,
    team_availability: true,
    posted_online: true,
    in_greenhouse: true,
    status: true,
  })
  .extend({
    vacancies: z.number(),
    budget_headcount_users: z.array(budgetHeadcountUserShowSchema),
    team: z.object({ id: z.number(), name: z.string() }).optional(),
  });

export const vacancySchema = budgetHeadcountSchema
  .omit({
    budget_id: true,
    archetype_id: true,
    hiring_manager_id: true,
    role: true,
  })
  .extend({
    archetype: z.object({ id: z.number(), name: z.string() }),
    budgetable: z.object({ id: z.number(), name: z.string(), type: z.enum(["Team", "Project"]) }),
    hired_count: z.number(),

    hiring_manager: z.lazy(() => userMinimalSchema).nullable(),
    other_names: z.string().nullable(),
  });

export type BudgetHeadcountData = z.infer<typeof budgetHeadcountSchema>;
export type BudgetHeadcountShowData = z.infer<typeof budgetHeadcountShowSchema>;
export type BudgetHeadcountCreateParams = Pick<
  BudgetHeadcountData,
  "budget_id" | "archetype_id" | "total_people" | "priority"
>;

export type VacancyData = z.infer<typeof vacancySchema>;

export type BudgetHeadcountUserCreateParams = z.infer<typeof budgetHeadcountUserCreateSchema>;
export type BudgetHeadcountUserUpdateParams = z.infer<typeof budgetHeadcountUserShowSchema>;
export type BudgetHeadcountUserShowData = z.infer<typeof budgetHeadcountUserShowSchema>;
//api queries

export const getBudgetHeadcountVacancy = async (
  id: number | null | undefined
): Promise<VacancyData> => {
  const result = await api.get(`${BUDGET_HEADCOUNTS_BASE_URL}/${id}`);
  return zodParse(vacancySchema, result.data);
};

export const generateVacancyCSV = async () => {
  const result = await api.get(`${BUDGET_HEADCOUNTS_BASE_URL}/vacancy_csv`, {
    responseType: "blob",
  });
  return result.data;
};

export const searchBudgetHeadcount = async ({
  team_ids,
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams & { team_ids?: number[] }) => {
  const path = [BUDGET_HEADCOUNTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search, team_ids });

  const schema = createSearchResponseSchema(budgetHeadcountShowSchema).extend({
    total_headcount: z.number(),
    vacancies: z.number(),
  });
  return zodParse(schema, result.data);
};

export const searchVacancies = async ({ aggs, filters, pagination, order, term }: SearchParams) => {
  const path = [BUDGET_HEADCOUNTS_BASE_URL, "search", "vacancies"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });

  return zodParse(
    createSearchResponseSchema(vacancySchema).extend({
      total_headcount: z.number(),
      total_requesting_teams_projects: z.number(),
      total_roles: z.number(),
    }),
    result.data
  );
};

export const createBudgetHeadcount = async (budgetHeadcount: BudgetHeadcountCreateParams) => {
  const result = await api.post(`${BUDGET_HEADCOUNTS_BASE_URL}`, {
    budget_headcount: budgetHeadcount,
  });
  return result.data;
};

export const updateBudgetHeadcount = async (budgetHeadcount: Partial<BudgetHeadcountData>) => {
  const result = await api.put(`${BUDGET_HEADCOUNTS_BASE_URL}/${budgetHeadcount.id}`, {
    budget_headcount: budgetHeadcount,
  });
  return result.data;
};

export const deleteBudgetHeadcount = async (id: number) => {
  await api.delete(`${BUDGET_HEADCOUNTS_BASE_URL}/${id}`);
};

export const createBudgetHeadcountUsers = async (
  budgetHeadcountUser: BudgetHeadcountUserCreateParams
) => {
  const result = await api.post(`${BUDGET_HEADCOUNT_USERS_BASE_URL}`, {
    budget_headcount_user: budgetHeadcountUser,
  });
  return result.data;
};

export const deleteBudgetHeadcountUsers = async (id: number) => {
  await api.delete(`${BUDGET_HEADCOUNT_USERS_BASE_URL}/${id}`);
};

export const updateBudgetHeadcountUsers = async (
  budgetHeadcountUser: Partial<BudgetHeadcountUserUpdateParams>
) => {
  const result = await api.put(`${BUDGET_HEADCOUNT_USERS_BASE_URL}/${budgetHeadcountUser.id}`, {
    is_lead: budgetHeadcountUser.is_lead,
  });
  return result.data;
};

//hooks

export const useGetBudgetHeadcountVacancy = (id: number | null | undefined) => {
  return useQuery<VacancyData>({
    queryKey: [BUDGET_HEADCOUNTS_BASE_URL, id],
    queryFn: () => getBudgetHeadcountVacancy(id),
    enabled: !!id,
  });
};

export const invalidateBudgetHeadcount = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [BUDGET_HEADCOUNTS_BASE_URL];
  if (id) {
    queryKey.push(id);
  }
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useCreateBudgetHeadcount = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: createBudgetHeadcount,
    onSuccess: onSuccessCallback(),
  });
};

export const useSearchBudgetHeadcountQuery = (params: SearchParams & { team_ids?: number[] }) => {
  return useQuery({
    queryKey: [BUDGET_HEADCOUNTS_BASE_URL, params, params.team_ids],
    queryFn: () => searchBudgetHeadcount(params),
  });
};

export const useSearchVacancies = (params: SearchParams) => {
  return useQuery({
    queryKey: [BUDGET_HEADCOUNTS_BASE_URL, params, "vacancies"],
    queryFn: () => searchVacancies(params),
  });
};

export const useUpdateBudgetHeadcount = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateBudgetHeadcount,
    onSuccess: onSuccessCallback(),
  });
};

export const useDeleteBudgetHeadcount = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: deleteBudgetHeadcount,
    onSuccess: onSuccessCallback(),
  });
};

export const useCreateBudgetHeadcountUsers = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: createBudgetHeadcountUsers,
    onSuccess: onSuccessCallback(),
  });
};

export const useUpdateBudgetHeadcountUsers = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateBudgetHeadcountUsers,
    onSuccess: onSuccessCallback(),
  });
};

export const useDeleteBudgetHeadcountUsers = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: deleteBudgetHeadcountUsers,
    onSuccess: onSuccessCallback(),
  });
};
