import { z } from "zod";
import { api } from ".";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import * as ct from "./collection_types";
import { zodParse } from "./zodParse";

export const ARCHETYPES_BASE_URL = "archetypes";

export const archetypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  source: z.string(),
  on_site_interview_process: z.string().optional(),
  role_type: z.string(),
});

export const archetypeShowSchema = archetypeSchema.extend({
  hired_count: z.number(),
  // upcoming_interview_count: z.number(),
});

export type ArchetypeData = z.infer<typeof archetypeSchema>;
export type ArchetypeShowData = z.infer<typeof archetypeShowSchema>;
export type ArchetypeCreateParams = Pick<ArchetypeData, "name">;

export const getArchetypes = async () => {
  const result = await api.get(ARCHETYPES_BASE_URL);
  return zodParse(archetypeSchema.array(), result.data);
};

export const getArchetype = async (id: number | null) => {
  const result = await api.get(`${ARCHETYPES_BASE_URL}/${id}`);
  return zodParse(archetypeShowSchema, result.data);
};

export const searchArchetypes = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [ARCHETYPES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });

  return zodParse(createSearchResponseSchema(archetypeShowSchema), result.data);
};

export const createArchetype = async (archetype: ArchetypeCreateParams) => {
  try {
    const result = await api.post(`${ARCHETYPES_BASE_URL}`, {
      archetype: archetype,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const consolidateArchetype = async (archetype: { id: number; new_archetype_id: number }) => {
  const result = await api.post(`${ARCHETYPES_BASE_URL}/${archetype.id}/consolidate`, {
    archetype: { new_archetype_id: archetype.new_archetype_id },
  });
  return result.data;
};

export const updateArchetype = async (archetype: Partial<ArchetypeData>) => {
  const result = await api.put(`${ARCHETYPES_BASE_URL}/${archetype.id}`, {
    archetype: archetype,
  });
  return result.data;
};

export const deleteArchetype = async (id: number) => {
  await api.delete(`${ARCHETYPES_BASE_URL}/${id}`);
};

export const invalidateArchetype = (id?: number | null) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [ARCHETYPES_BASE_URL];
  if (id) queryKey.push(id);
  return () => queryClient.invalidateQueries({ queryKey });
};

export const useGetArchetypes = () => {
  return useQuery({
    queryKey: [ARCHETYPES_BASE_URL],
    queryFn: () => getArchetypes(),
  });
};

export const useGetArchetype = (id: number | null) => {
  return useQuery({
    queryKey: [ARCHETYPES_BASE_URL, id],
    queryFn: () => getArchetype(id),
    enabled: !!id,
  });
};

export const useSearchArchetypes = (params: SearchParams) => {
  return useQuery({
    queryKey: [ARCHETYPES_BASE_URL, params],
    queryFn: () => searchArchetypes(params),
  });
};

export const useCreateArchetype = () => {
  return useMutation({
    mutationFn: createArchetype,
    onSuccess: invalidateArchetype(),
  });
};

export const useConsolidateArchetype = () => {
  return useMutation({
    mutationFn: consolidateArchetype,
    onSuccess: invalidateArchetype(),
  });
};

export const useUpdateArchetype = (id?: number | null) => {
  return useMutation({
    mutationFn: updateArchetype,
    onSuccess: invalidateArchetype(id),
  });
};
export const useDeleteArchetype = () => {
  return useMutation({
    mutationFn: deleteArchetype,
    onSuccess: invalidateArchetype(),
  });
};
