import React from "react";
import { useParams } from "react-router";
import { NewPost } from "./NewPost";
import { useNewsPostQuery } from "../../api/news_post";

export const EditNewsPost = () => {
  const { id } = useParams();
  if (!id) return <></>;
  const { data: newsPost } = useNewsPostQuery(+id);
  return newsPost?.title ? <NewPost newPost={newsPost} /> : <></>;
};
