import React from "react";

import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { OminiSearchContent } from "./OmnisearchContent";

interface OmnisearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}
export const OminiSearchModal = (props: OmnisearchModalProps) => {
  const { isOpen, onClose } = props;
  const initialRef = React.useRef(null);
  return (
    <Modal initialFocusRef={initialRef} size={["xs", "xl", "xl"]} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent paddingBlock={5}>
        <ModalBody>
          <OminiSearchContent initialRef={initialRef} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
