import React from "react";
import { FormControl, FormErrorMessage, FormLabel, Input, InputProps } from "@chakra-ui/react";

export type InputFormControlProps = InputProps & {
  label?: string;
  error?: string;
  isRequired?: boolean;
};

const InputFormControl: React.FC<InputFormControlProps> = ({
  label,
  error,
  isRequired,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Input {...props} />
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export default InputFormControl;
