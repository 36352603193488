import React, { useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Box,
  Text,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import {
  CredentialData,
  useNewCredential,
  useGetCredentialNames,
  useUpdateCredential,
} from "../../../api/credentials";
import { Controller, useForm } from "react-hook-form";

type FormValues = {
  name: string;
  is_public: string;
  training_location: string;
  expiration_months: number;
  currency_days: number;
  has_training: string;
};

type NewCredentialModalProps = {
  isOpen: boolean;
  onClose: () => void;
  credential?: CredentialData;
};

export const NewCredentialModal = ({ isOpen, onClose, credential }: NewCredentialModalProps) => {
  const [expires, setExpires] = useState<boolean>(credential?.expiration_months ? true : false);
  const [hasCurrency, setHasCurrency] = useState<boolean>(credential?.currency_days ? true : false);
  const { mutate: newCredential } = useNewCredential();
  const { mutate: updateCredential } = useUpdateCredential();
  const { data: credentialNames } = useGetCredentialNames();

  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: credential
      ? {
          name: credential.name,
          is_public: credential?.is_public ? "public" : "private",
          has_training: credential?.has_training ? "yes" : "no",
          training_location: credential?.training_location,
          expiration_months: credential?.expiration_months || 0,
          currency_days: credential?.currency_days || 0,
        }
      : {
          name: "",
          is_public: "public",
          has_training: "yes",
          training_location: "internal",
          expiration_months: 0,
          currency_days: 0,
        },
  });

  const isNameUnique = (name: string) => !credentialNames?.includes(name);

  const formValues = watch();

  const allFieldsFilledIn = () => {
    return (
      formValues.name &&
      formValues.is_public &&
      formValues.has_training &&
      formValues.training_location &&
      (formValues.expiration_months || !expires) &&
      (formValues.currency_days || !hasCurrency)
    );
  };

  const onSubmit = (data: FormValues) => {
    const newCredentialData = {
      ...data,
      is_public: data.is_public === "public" ? true : false,
      has_training: data.has_training === "yes" ? true : false,
      expiration_months: expires ? data.expiration_months : null,
      currency_days: hasCurrency ? data.currency_days : null,
    };
    credential
      ? updateCredential({ id: credential.id, ...newCredentialData })
      : newCredential(newCredentialData);
    setHasCurrency(false);
    setExpires(false);
    reset();
    onClose();
  };

  const handleClose = () => {
    reset();
    setHasCurrency(false);
    setExpires(false);
    onClose();
  };

  return (
    <FormModal
      title="New Credential"
      submitButtonColorSchema="teal"
      submitButtonTitle="Done"
      size="3xl"
      isOpen={isOpen}
      submitButtonDisabled={!allFieldsFilledIn()}
      onClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack width="100%" align="start" spacing={5}>
        <Text>Add a new credential for science employees.</Text>
        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl isInvalid={errors.name ? true : false}>
                <FormLabel>Credential Name</FormLabel>
                <Input
                  {...field}
                  {...register("name", { validate: credential ? undefined : isNameUnique })}
                  placeholder="Type Credential Name"
                />
                {errors.name && (
                  <Text color="red" mt={1} fontSize="sm">
                    This credential already exists.
                  </Text>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="training_location"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Training Location</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"internal"}>
                    Internal
                  </Radio>
                  <Radio {...field} value={"external"}>
                    External
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>
        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <Controller
            name="has_training"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Does the Credential have a training facilitated by Science?</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"yes"}>
                    Yes
                  </Radio>
                  <Radio {...field} value={"no"}>
                    No
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            name="is_public"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Credential Visibility</FormLabel>
                <RadioGroup display="flex" colorScheme="teal" value={field.value} gap={6}>
                  <Radio {...field} value={"public"}>
                    Public
                  </Radio>
                  <Radio {...field} value={"private"}>
                    Private
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>

        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel>Does Credential Expire?</FormLabel>
            <RadioGroup
              colorScheme="teal"
              display="flex"
              gap={6}
              onChange={(value) => setExpires(value === "yes" ? true : false)}
              value={expires ? "yes" : "no"}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioGroup>
          </FormControl>
          <Controller
            name="expiration_months"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>
                  Validity Period{" "}
                  <Box
                    as="span"
                    fontWeight="normal"
                    color={useColorModeValue("gray.500", "gray.300")}>
                    (months)
                  </Box>
                </FormLabel>
                <Input {...field} isDisabled={!expires} type="number" />
              </FormControl>
            )}
          />
        </Stack>
        <Stack width="100%" align="start" spacing={6} direction={{ base: "column", md: "row" }}>
          <FormControl>
            <FormLabel>Does Credential Require Currency?</FormLabel>
            <RadioGroup
              colorScheme="teal"
              display="flex"
              gap={6}
              onChange={(value) => setHasCurrency(value === "yes" ? true : false)}
              value={hasCurrency ? "yes" : "no"}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioGroup>
          </FormControl>
          <Controller
            name="currency_days"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>
                  Currency Timeframe{" "}
                  <Box
                    as="span"
                    fontWeight="normal"
                    color={useColorModeValue("gray.500", "gray.300")}>
                    (days)
                  </Box>
                </FormLabel>
                <Input {...field} disabled={!hasCurrency} type="number" />
              </FormControl>
            )}
          />
        </Stack>
      </VStack>
    </FormModal>
  );
};
