import { z } from "zod";
import { api } from ".";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { createPaginatedResponseSchema, createSearchResponseSchema } from "./shared";
import { Pagination, Order } from "@sciencecorp/helix-components";
import * as ct from "./collection_types";
import { dateTimeSchema } from "../helpers/dateTime";
import { budgetItemWithSummarySchema } from "./budget_items";
import {
  indexParams,
  searchParams,
  SearchParams,
  paginationSchema,
  aggregationSchema,
} from "./collection_types";
import { BudgetableTypeData, budgetTableSummarySchema } from "./budget";
import { zodParse } from "./zodParse";
import { moneySchema } from "../helpers/Money";

export const FINANCE_BASE_URL = "finance";

export const dailySpendSummarySchema = z.object({
  day: dateTimeSchema,
  expense: moneySchema,
  revenue: moneySchema,
  profit: moneySchema,
});

export const spendByExpenseTypeSchema = z.object({
  contracts_total: moneySchema,
  purchases_total: moneySchema,
  subscriptions_total: moneySchema,
});

export const monthlySpendSummarySchema = z.object({
  month: dateTimeSchema,
  daily_summaries: z.array(dailySpendSummarySchema),
});

export const spendSummarySchema = z.object({
  monthly_summaries: z.array(monthlySpendSummarySchema),
  current_balance: moneySchema,
});

export const teamSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const vendorSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const accountSchema = z.object({
  name: z.string(),
  account_number: z.string().nullable(),
  spend_type: z.string().nullable(),
  classification: z.string(),
});

export const accountingTransactionSchema = z.object({
  id: z.number(),
  external_id: z.string(),
  external_type: z.string(),
});

export const lineItemSchema = z.object({
  id: z.number(),
  external_id: z.string(),
  external_line_id: z.string(),
  external_type: z.string(),
  direction: z.string(),
  memo: z.string().nullable(),
  amount: moneySchema,
  occurred_at: dateTimeSchema,
  account: accountSchema,
  team: teamSchema.nullable(),
  top_level_team: teamSchema.nullable(),
  vendor: vendorSchema.nullable(),
  accounting_transaction: accountingTransactionSchema,
});

export const payrollPeriodSummarySchema = z.object({
  date: dateTimeSchema,
  credit: z.number(),
  debit: z.number(),
  team_id: z.string().nullable().optional(),
  team_name: z.string().nullable().optional(),
});

export const payrollPeriodSummaryResponseSchema = z.object({
  payroll_summary: z.array(payrollPeriodSummarySchema),
});

export const paginatedLineItemsResponseSchema = createPaginatedResponseSchema(lineItemSchema);
export const searchLineItemsResponseSchema = createSearchResponseSchema(lineItemSchema);

export const authUrlSchema = z.object({
  url: z.string(),
});

export const monthlyBalanceSchema = z
  .object({
    balances: z.record(moneySchema),
    current_balance: moneySchema,
    monthly_payments: z.record(moneySchema),
  })
  .describe("monthlyBalanceSchema");

export type MonthlyBalanceData = z.infer<typeof monthlyBalanceSchema>;

export type LineItem = z.infer<typeof lineItemSchema> & {
  direction: "debit" | "credit";
};
export type DailySpendSummary = z.infer<typeof dailySpendSummarySchema>;
export type MonthlySpendSummary = z.infer<typeof monthlySpendSummarySchema>;
export type SpendSummary = z.infer<typeof spendSummarySchema>;
export type PaginatedLineItemsResponse = z.infer<typeof paginatedLineItemsResponseSchema>;
export type SearchLineItemsResponse = z.infer<typeof searchLineItemsResponseSchema>;
export type PayrollPeriodSummaryResponse = z.infer<typeof payrollPeriodSummaryResponseSchema>;
export type PayrollPeriodSummary = z.infer<typeof payrollPeriodSummarySchema>;
export type SpendByExpenseTypeData = z.infer<typeof spendByExpenseTypeSchema>;

export type AuthUrl = z.infer<typeof authUrlSchema>;

export const budgetIndexSchema = z.object({
  results: z.array(budgetTableSummarySchema),
  pagination: paginationSchema,
  aggregations: z.record(aggregationSchema).nullable().optional(),
});

export async function getAuthUrl(): Promise<AuthUrl> {
  const result = await api.get(`${FINANCE_BASE_URL}/auth/url`, {});
  return zodParse(authUrlSchema, result.data);
}

export function useGetAuthUrl(): UseQueryResult<AuthUrl> {
  return useQuery({
    // Cache for 1 minute
    cacheTime: 60000,
    queryKey: [FINANCE_BASE_URL, "auth", "url"],
    queryFn: getAuthUrl,
  });
}

export const updateOAuthToken = async (authCode: string, realmId: string) => {
  const result = await api.post(`${FINANCE_BASE_URL}/auth/update_token`, {
    auth_code: authCode,
    realm_id: realmId,
  });
  return result.data;
};

interface UpdateOAuthTokenParams {
  authCode: string;
  realmId: string;
}
export const useUpdateOAuthToken = () => {
  return useMutation({
    mutationFn: ({ authCode, realmId }: UpdateOAuthTokenParams) => {
      return updateOAuthToken(authCode, realmId);
    },
  });
};

export async function getAllLineItems(
  pagination: Pagination,
  order: Order
): Promise<PaginatedLineItemsResponse> {
  const result = await api.get(`${FINANCE_BASE_URL}/line_items`, {
    params: {
      per_page: pagination.per_page,
      page: pagination.page,
      order: order,
    },
  });
  return result.data;
}

export function useGetAllLineItems(
  pagination: Pagination,
  order: Order
): UseQueryResult<PaginatedLineItemsResponse> {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "line_items", order, pagination],
    refetchOnMount: true,
    queryFn: () =>
      getAllLineItems(pagination, order).then((data) => {
        return zodParse(paginatedLineItemsResponseSchema, data);
      }),
  });
}

export async function searchLineItems({
  aggs,
  bodyOptions,
  filters,
  pagination,
  order,
  term,
}: ct.SearchParams): Promise<SearchLineItemsResponse> {
  const path = [FINANCE_BASE_URL, "line_items", "search"];
  const index = ct.indexParams({ pagination, order });
  const search = ct.searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(searchLineItemsResponseSchema, result.data);
}

export const useLineItemsSearchQuery = (
  params: ct.SearchParams,
  opts?: any
): UseQueryResult<SearchLineItemsResponse> => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, params],
    queryFn: () => searchLineItems(params),
    ...opts,
  });
};

export const getCompanySpendSummary: () => Promise<SpendSummary[]> = async () => {
  const result = await api.get(`${FINANCE_BASE_URL}/summaries/company_spend`);
  return result.data;
};

export const useGetCompanySpendSummary = () => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "summaries/company_spend"],
    queryFn: () =>
      getCompanySpendSummary().then((data) => {
        return zodParse(spendSummarySchema, data);
      }),
  });
};

export const getPayrollPeriodSummary: () => Promise<PayrollPeriodSummaryResponse> = async () => {
  const result = await api.get(`${FINANCE_BASE_URL}/summaries/payroll_period`);
  return result.data;
};

export const useGetPayrollPeriodSummary = (
  startTime?: string | DateTime | null,
  endTime?: string | DateTime | null
) => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "summaries/payroll_period", startTime, endTime],
    queryFn: () =>
      getPayrollPeriodSummary().then((data) => zodParse(payrollPeriodSummaryResponseSchema, data)),
  });
};

export const getSpendByExpenseType = async (
  startTime?: string | DateTime | null,
  endTime?: string | DateTime | null
): Promise<SpendByExpenseTypeData> => {
  const result = await api.get(`${FINANCE_BASE_URL}/summaries/spend_expense_type`, {
    params: { start_time: startTime, end_time: endTime },
  });
  return zodParse(spendByExpenseTypeSchema, result.data);
};

export const useGetSpendByExpenseType = (startTime: DateTime, endTime: DateTime) => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "summaries/spend_expense_type", [startTime, endTime]],
    queryFn: () => getSpendByExpenseType(startTime.toISO(), endTime.toISO()),
  });
};

export type ScorecardPeriod = DateTime | "allTime" | "yearToDate";

export const getScoreCardStartAndEndFromScorecardPeriod = (
  period: ScorecardPeriod
): [DateTime, DateTime] => {
  let startTime: DateTime | null = null;
  let endTime: DateTime | null = null;

  if (period === "yearToDate") {
    startTime = DateTime.local().startOf("year");
    endTime = DateTime.local().endOf("year");
  } else if (period === "allTime") {
    startTime = DateTime.fromISO("2020-01-01");
    endTime = DateTime.local().endOf("year");
  } else {
    startTime = period.startOf("month");
    endTime = period.endOf("month");
  }

  return [startTime, endTime];
};

export const getCashBalanceSummary = async () => {
  const result = await api.get(`${FINANCE_BASE_URL}/summaries/cash_balance_summary`);
  return zodParse(monthlyBalanceSchema, result.data);
};

export const useGetCashBalanaceSummary = () => {
  return useQuery({
    queryKey: [FINANCE_BASE_URL, "summaries/cash_balance_summary"],
    queryFn: () => getCashBalanceSummary(),
  });
};
