import { CollectionTable, Column, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { BudgetGroupIndexData } from "../../../../api/budget_groups";
import { MoneyText } from "../../../MoneyText";
import { BudgetItemProgressBar } from "../../../Budgets/BudgetItemProgressBar";
import { Skeleton, Stack, Tag, VStack } from "@chakra-ui/react";

type BudgetGroupListProps = {
  budgetGroups?: BudgetGroupIndexData[];
  isLoading: boolean;
};

export const BudgetGroupList = ({ budgetGroups, isLoading }: BudgetGroupListProps) => {
  const columns: Column[] = useMemo(
    () => [
      {
        label: "Group",
        render: (budgetGroup: BudgetGroupIndexData) => (
          <VStack align="start">
            <RecordLink
              identifier={budgetGroup.name}
              link={`/finance/budgets/budget_group/${budgetGroup.id}`}
            />
            {budgetGroup.archived_at && <Tag colorScheme="red">Archived</Tag>}
          </VStack>
        ),
      },
      {
        label: "Allocation",
        render: (budgetGroup: BudgetGroupIndexData) => {
          return (
            <Stack direction={["row", "column", "row"]}>
              <MoneyText
                as="span"
                money={budgetGroup.budget_group_schedule.amount}
                formatOptions={{ compact: "never" }}
              />
              {budgetGroup.budget_group_schedule.schedule_type === "monthly" ? (
                <Tag colorScheme="blue" size="sm">
                  Recurring
                </Tag>
              ) : (
                <Tag colorScheme="green" size="sm" w="max-content">
                  Single Payment
                </Tag>
              )}
            </Stack>
          );
        },
      },

      {
        label: "Total",
        render: (budgetGroup: BudgetGroupIndexData) => (
          <MoneyText
            as="span"
            money={budgetGroup.summary.funding_amount}
            formatOptions={{ compact: "never" }}
          />
        ),
      },
      {
        label: "",
        hideOnBreakpoint: ["base", "sm", "md"],
        render: (budgetGroup: BudgetGroupIndexData) => (
          <BudgetItemProgressBar summary={budgetGroup.summary} />
        ),
      },
    ],
    [budgetGroups]
  );

  return (
    <Skeleton isLoaded={!isLoading}>
      <CollectionTable items={budgetGroups || []} columns={columns} />
    </Skeleton>
  );
};
