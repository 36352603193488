import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { api } from ".";

export interface CalendarEvent {
  summary: string;
  start: DateTime;
  end: DateTime;
  location?: string;
  hangout_link?: string;
  organizer?: string;
  status: string;
  attendees?: { name: string }[];
}

export interface DayCellProps {
  day: DateTime;
  currentMonth: DateTime;
  events: CalendarEvent[];
}

//api calls

export const getCalendarEvents = async (): Promise<CalendarEvent[]> => {
  try {
    const response = await api.get("users/me/calendar");

    return response.data.map((event: any) => {
      return {
        summary: event.summary,
        start: DateTime.fromISO(event.start?.date_time || event.start?.date),
        end: DateTime.fromISO(event.end?.date_time || event.end?.date),
        location: event.location,
        hangout_link: event.hangout_link,
        organizer: event.organizer?.email,
        status: event.status,
        attendees: event.attendees?.map((attendee: any) => ({
          name: attendee.displayName || attendee.email,
        })),
      };
    });
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    return [];
  }
};

//react query hooks

export const useCalendarEventsQuery = (): UseQueryResult<CalendarEvent[]> => {
  return useQuery({
    queryKey: ["calendar_events"],
    queryFn: getCalendarEvents,
  });
};
