import React from "react";
import { Menu, MenuList, MenuItem, IconButton, MenuButton } from "@chakra-ui/react";
import {
  InventoryLocationData,
  useCreateInventoryLocation,
  useDeleteInventoryLocation,
} from "../../../../api/inventory_location";
import { BsThreeDotsVertical } from "react-icons/bs";
import { EditIcon, AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { PiGitMerge } from "react-icons/pi";

type LocationActionsMenuProps = {
  location: InventoryLocationData;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRowOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LocationActionsMenu = ({
  location,
  setEditing,
  setIsRowOpen,
}: LocationActionsMenuProps) => {
  const { mutate: createInventoryLocation } = useCreateInventoryLocation();
  const { mutate: deleteInventoryLocation } = useDeleteInventoryLocation();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<BsThreeDotsVertical />}
        aria-label="edit locations"
        variant="ghost"
        size="sm"
        onClick={(e) => e.stopPropagation()}
      />
      <MenuList>
        <MenuItem icon={<EditIcon />} onClick={() => setEditing(true)}>
          Rename
        </MenuItem>
        <MenuItem
          icon={<AddIcon />}
          onClick={() =>
            createInventoryLocation({
              parent_inventory_location_id: location.parent_inventory_location_id,
            })
          }>
          Insert New Location
        </MenuItem>
        <MenuItem
          icon={<PiGitMerge />}
          onClick={() => {
            createInventoryLocation(
              { parent_inventory_location_id: location.id },
              { onSuccess: setIsRowOpen }
            );
          }}>
          Insert Nested Location
        </MenuItem>
        <MenuItem
          color="red"
          icon={<DeleteIcon />}
          onClick={() => deleteInventoryLocation(location.id)}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
