import React from "react";
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  StepNumber,
  Divider,
  Box,
} from "@chakra-ui/react";
import { useGetInventory } from "../../../../../api/inventory";
import { AddStockAndLocation } from "../../../../Inventory/Inventories/components/RestockModalComponents/AddStockAndLocation";
import { PurchaseShowData } from "../../../../../api/purchase";
import { PurchaseLineItemsSource } from "../../../../Inventory/Inventories/components/RestockModalComponents/PurchaseLineItemsSource";
import {
  RestockFormValues,
  useRestockInventoryModal,
  useNewInventoryModal,
} from "../../../../Inventory/Inventories/components/RestockModalComponents/util";
import { SelectInventorySource } from "./SelectInventoryTarget";
import { LuPackagePlus } from "react-icons/lu";
import { RestockSummary } from "../../../../Inventory/Inventories/components/RestockModalComponents/RestockSummary";
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
} from "@chakra-ui/react";
import { useCurrentUserQuery, userHasRole } from "../../../../../api/user";

type InventoryItemFromPurchaseLineItemModalProps = {
  purchase: PurchaseShowData;
};
export const InventoryItemFromPurchaseLineItemModal = ({
  purchase,
}: InventoryItemFromPurchaseLineItemModalProps) => {
  const restockProps = useRestockInventoryModal();

  const currentUserQuery = useCurrentUserQuery();
  const isInventoryManager = userHasRole(currentUserQuery, "inventory_manager") || false;
  const { data: inventory } = useGetInventory(restockProps.formValues.inventory_id);

  const steps = [
    { title: "Select Purchase Item" },
    { title: "Select Inventory" },
    { title: "Add Stock" },
    { title: "Confirm" },
  ];

  const disableProceedButton = (formValues: RestockFormValues) => {
    if (restockProps.page === 0) return !formValues.purchase_line_item?.id;
    if (restockProps.page === 1) return !formValues.inventory_id;
    if (restockProps.page === 2) {
      const hasMinimumValues =
        formValues.quantity > 0 && formValues.inventory_location_id && formValues.amount.isPos();

      let validAmountPerUnit = true;
      if (inventory?.is_consumable) {
        validAmountPerUnit = Number(formValues.total_quantity) > 0;
      }
      let validExpirationDate = true;
      let validLotNumber = true;
      if (inventory?.track_expiration_dates) {
        validExpirationDate = formValues.expiration_date.length > 0;
      }
      if (inventory?.track_lots) {
        validLotNumber = formValues.lot_number.length > 0;
      }
      return !(hasMinimumValues && validExpirationDate && validLotNumber && validAmountPerUnit);
    }
  };

  const handleNextButtonClick = () => {
    if (restockProps.page < 3) {
      restockProps.setPage(restockProps.page + 1);
    } else {
      restockProps.handleSubmit();
    }
  };

  const handleNewInventoryCreated = (newInventory) => {
    restockProps.setValue("inventory_id", newInventory.id);
    handleNextButtonClick();
  };

  const newInventoryProps = useNewInventoryModal(undefined, handleNewInventoryCreated);

  return (
    <>
      <Button
        onClick={restockProps.onOpen}
        leftIcon={<LuPackagePlus />}
        variant={["solid", "ghost"]}
        size="sm">
        Add to Inventory
      </Button>
      <Modal
        isOpen={restockProps.isOpen}
        onClose={restockProps.handleClose}
        size="3xl"
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add to Stock</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4} w="100%">
              <Stepper index={restockProps.page} colorScheme="teal" size="sm">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepNumber />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>
                    <Box
                      flexShrink="0"
                      color={restockProps.page >= 0 ? "teal.500" : "gray.500"}
                      display={["none", "none", "inline"]}>
                      <StepTitle>{step.title}</StepTitle>
                    </Box>{" "}
                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <Divider />
              {restockProps.page === 0 && (
                <PurchaseLineItemsSource
                  setValue={restockProps.setValue}
                  control={restockProps.control}
                  purchaseId={purchase.id}
                />
              )}
              {restockProps.page === 1 && (
                <SelectInventorySource
                  control={restockProps.control}
                  restockFormValues={restockProps.formValues}
                  newInventoryFormValues={newInventoryProps.formValues}
                  newInventorySetValue={newInventoryProps.setValue}
                  newInventoryControl={newInventoryProps.control}
                  isInventoryManager={isInventoryManager}
                />
              )}
              {restockProps.page === 2 && inventory && (
                <AddStockAndLocation
                  treeSelectInventoryLocationsQuery={restockProps.treeSelectInventoryLocationsQuery}
                  inventory={inventory}
                  setPage={restockProps.setPage}
                  setValue={restockProps.setValue}
                  control={restockProps.control}
                  formValues={restockProps.formValues}
                  showPreview={restockProps.showPreview}
                  pathToLocation={restockProps.pathToLocation}
                  setShowPreview={restockProps.setShowPreview}
                  setPathToLocation={restockProps.setPathToLocation}
                  setSearch={restockProps.setSearch}
                  purchaseId={purchase.id}
                />
              )}
              {restockProps.page === 3 && inventory && (
                <RestockSummary
                  inventory={inventory}
                  formValues={restockProps.formValues}
                  setPage={restockProps.setPage}
                  treeSelectInventoryLocationsQuery={restockProps.treeSelectInventoryLocationsQuery}
                />
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup
              justifyContent={restockProps.page > 0 ? "space-between" : "flex-end"}
              w="100%">
              {restockProps.page > 0 && (
                <Button
                  variant="ghost"
                  colorScheme="teal"
                  onClick={() => restockProps.setPage(restockProps.page - 1)}>
                  Back
                </Button>
              )}
              <Button
                colorScheme="teal"
                onClick={
                  restockProps.page === 1 &&
                  (!restockProps.formValues.inventory_id ||
                    restockProps.formValues.inventory_id === -1)
                    ? newInventoryProps.handleSubmit
                    : handleNextButtonClick
                }
                isLoading={restockProps.page === 3 && restockProps.isLoadingInventoryItem}
                isDisabled={disableProceedButton(restockProps.formValues)}>
                {restockProps.page !== 3 ? "Next" : "Confirm"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
