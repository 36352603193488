import { z } from "zod";
import { api } from ".";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { invalidateServiceRequest } from "./service_requests";
import { moneySchema } from "../helpers/Money";

export const SERVICE_REQUEST_EVENTS_BASE_URL = "service_request_events";

export const serviceRequestEventSchema = z.object({
  id: z.number(),
  service_request_id: z.number(),
  user_id: z.number().nullable(),
  event_type: z.string(),
  event_info: z.object({
    description: z.string(),
    raw_text: z.any().optional(),
    status: z.string().optional(),
    cost: moneySchema.optional().nullable(),
    date: z.string().optional(),
    link: z.string().optional(),
    type: z.string().optional(),
    user_name: z.string().optional(),
    plain_text: z.string().optional(),
  }),
  user: z.object({
    id: z.number(),
    name: z.string(),
    picture_uri: z.string(),
  }),
  created_at: z.string(),
  slack_message: z.string(),
});

export type ServiceRequestEventData = z.infer<typeof serviceRequestEventSchema>;
export type ServiceRequestEventCreateParams = Pick<
  ServiceRequestEventData,
  "service_request_id" | "user_id" | "event_type" | "event_info" | "slack_message"
>;

export const createServiceRequestEvent = async (
  serviceRequestEvent: ServiceRequestEventCreateParams
) => {
  return await api.post(`${SERVICE_REQUEST_EVENTS_BASE_URL}`, {
    service_request_event: serviceRequestEvent,
  });
};

export const updateServiceRequestEvent = async (
  serviceRequestEvent: Partial<ServiceRequestEventData>
) => {
  return await api.put(`${SERVICE_REQUEST_EVENTS_BASE_URL}/${serviceRequestEvent.id}`, {
    service_request_event: serviceRequestEvent,
  });
};

export const deleteServiceRequestEvent = async (serviceRequestEventId: number) => {
  await api.delete(`${SERVICE_REQUEST_EVENTS_BASE_URL}/${serviceRequestEventId}`);
};

export const invalidateServiceRequestEvent = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_EVENTS_BASE_URL] });
};

export const useCreateServiceRequestEvent = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: createServiceRequestEvent,
    onSuccess: invalidateServiceRequest(serviceRequestId, "events"),
  });
};

export const useUpdateServiceRequestEvent = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: updateServiceRequestEvent,
    onSuccess: invalidateServiceRequest(serviceRequestId, "events"),
  });
};

export const useDeleteServiceRequestEvent = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestEvent,
    onSuccess: invalidateServiceRequest(serviceRequestId, "events"),
  });
};
