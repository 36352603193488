import { Select } from "@sciencecorp/helix-components";
import _ from "lodash";
import { forwardRef, default as React } from "react";
import { supportedCurrencies } from "../helpers/Money";

export interface CurrencySelectProps {
  value?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  placeholder?: string;
  excludedCurrencies?: string[];
  includeDefault?: boolean;
}

export const CurrencySelect: React.FC<CurrencySelectProps> = forwardRef(
  (
    {
      value,
      onChange,
      isDisabled,
      excludedCurrencies = [],
      placeholder = "Select a currency",
      includeDefault = false,
    },
    ref
  ) => {
    const options = _.values(supportedCurrencies)
      .map((currency) => ({
        label: `${currency.code} (${currency.symbol})`,
        value: currency.code,
      }))
      .filter((currency) => !excludedCurrencies?.includes(currency.value));

    return (
      <Select
        isDisabled={isDisabled || false}
        placeholder={placeholder}
        options={includeDefault ? [...options, { label: "Default", value: "default" }] : options}
        value={value}
        onChange={(value) => {
          // This should only ever be a string
          if (value && typeof value === "string") {
            onChange(value);
          }
        }}
      />
    );
  }
);
