import React from "react";
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { OmniSearchbarFilterItem } from "./types";

export const OmnisearchFilterBar = (props: {
  omnisearchBarFilterItems: OmniSearchbarFilterItem[];
  setModelApp: (newValue) => void;
  modelApp?: OmniSearchbarFilterItem | null;
}) => {
  return (
    <Box
      borderBlockEndWidth="1px"
      borderBlockEndColor={useColorModeValue("gray.200", "gray.600")}
      marginBlockStart={6}
      paddingBlockEnd={2}>
      <Text color={useColorModeValue("gray.600", "gray.400")} marginBlockEnd={2} fontSize="sm">
        Find results in...
      </Text>
      <Flex
        gap={2}
        marginInlineEnd={-6}
        sx={{
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
        flexWrap="nowrap"
        overflowX="scroll">
        {props.omnisearchBarFilterItems.map((item) => (
          <Button
            key={item.value}
            paddingInline={3}
            flexShrink={0}
            leftIcon={item.icon}
            size="sm"
            onClick={() => props.setModelApp(item)}>
            {item.label}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};
