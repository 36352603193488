import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";

export const CHAT_THREADS_BASE_URL = "chat_threads";
export const CHAT_MESSAGES_BASE_URL = "chat_messages";

const chatMessageSchema = z.object({
  id: z.number(),
  chat_thread_id: z.number().optional(),
  sender: z.union([z.literal("user"), z.literal("agent")]),
  type: z.union([z.literal("error"), z.literal("plugin"), z.literal("text"), z.literal("info")]),
  content: z.string(),
  error: z.string().optional(),
  plugin: z
    .object({
      name: z.string(),
      action: z.string(),
      input: z.string(),
      icon: z.string(),
    })
    .optional(),
  created_at: z.string(),
});

const chatThreadPartialSchema = z.object({
  id: z.number(),
  agent_id: z.string(),
  summary: z.string().nullable(),
  is_public: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
});

const chatThreadShowSchema = chatThreadPartialSchema.extend({
  messages: z.array(chatMessageSchema),
});

const chatThreadIndexSchema = z.array(chatThreadPartialSchema);

export type ChatThreadShowData = z.infer<typeof chatThreadShowSchema>;
export type ChatMessageData = z.infer<typeof chatMessageSchema>;
export type ChatThreadIndexData = z.infer<typeof chatThreadIndexSchema>;

/** queries */
export const getChatThread = async (id?: string | number) => {
  if (!id) return null;
  const result = await api.get(`${CHAT_THREADS_BASE_URL}/${id}`);
  return zodParse(chatThreadShowSchema, result.data);
};
export const getChatThreads = async () => {
  const result = await api.get(CHAT_THREADS_BASE_URL);
  return zodParse(chatThreadIndexSchema, result.data);
};
export const newChatThread = async (agentId: string) => {
  const result = await api.post(CHAT_THREADS_BASE_URL, {
    chat_thread: {
      agent_id: agentId,
      is_public: false,
    },
  });
  return result.data.id;
};
export const newChatMessage = async (
  threadId: string | number,
  chatMessage: Partial<ChatMessageData>
) => {
  const result = await api.post(CHAT_MESSAGES_BASE_URL, {
    chat_message: {
      ...chatMessage,
      chat_thread_id: threadId,
      message_type: chatMessage.type,
    },
  });
  return result.data.id;
};

/** hooks */
export function useChatThreadsQuery() {
  return useQuery({
    queryKey: [CHAT_THREADS_BASE_URL],
    queryFn: () => getChatThreads(),
  });
}
export function useChatThreadQuery(id?: string | number) {
  return useQuery({
    queryKey: [CHAT_THREADS_BASE_URL, id],
    queryFn: () => getChatThread(id),
  });
}
