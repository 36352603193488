import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { approvableSchema } from "./approvals";
import { blobFileSchema } from "./blob_files";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { expenditureSchema } from "./expenditures";
import { purchaseShowSchema } from "./purchase";
import { serviceRequestEventSchema } from "./service_request_events";
import { createSearchResponseSchema } from "./shared";
import { SpendingAuthorityReference, spendingAuthoritySchema } from "./spending_authority";
import { TEAMS_BASE_URL } from "./team";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";

export const SERVICE_REQUESTS_BASE_URL = "service_requests";
export const SERVICE_REQUESTS_ASSIGNED_USERS_BASE_URL = "service_requests_assigned_users";
export const SERVICE_REQUESTS_APPROVALS_BASE_URL = "service_request_approvals";
export const SERVICE_REQUESTS_SUBSCRIBERS_BASE_URL = "service_request_subscribers";

export const serviceRequestPartialSchema = z
  .object({
    id: z.number(),
    service_id: z.number(),
    requesting_user_id: z.number(),
    site_id: z.number(),
    requesting_team_id: z.number(),
    estimated_completion_date: z.string().nullable(),
    status: z.string(),
    request_description: z.string(),
    files: z.array(z.object({ name: z.string(), file: z.string() })),
    spending_authority: z.lazy(() => spendingAuthoritySchema).nullable(),
    priority_level: z.number().nullable(),
    app_href: z.string(),
    created_at: z.string(),
  })
  .extend(expenditureSchema.shape);

export const serviceRequestIndexItemSchema = serviceRequestPartialSchema
  .omit({
    requesting_user_id: true,
    requesting_team_id: true,
    service_id: true,
    site_id: true,
    files: true,
  })
  .extend({
    service_name: z.string(),
    service_href: z.string(),
    estimated_completion_date: z.string().nullable(),
    status: z.string(),
    requesting_user: z.lazy(() => userMinimalSchema),
    request_description: z.string(),
    requesting_team: z
      .object({
        id: z.number(),
        name: z.string(),
        can_act_as_lead: z.boolean(),
      })
      .nullable(),
    priority_level: z.number().nullable(),
    created_at: z.string(),
    total_cost: moneySchema,
  });

export const serviceRequestShowSchema = serviceRequestIndexItemSchema
  .extend(approvableSchema.shape)
  .extend({
    site: z.object({ id: z.number(), name: z.string() }),
    service_cost: moneySchema,
    purchase_id: z.number().nullable(),
    service_id: z.number(),
    uploaded_files: z.array(blobFileSchema),
    user_in_fulfilling_team: z.boolean(),
    is_requesting_user: z.boolean(),
    total_cost: moneySchema,
    top_level_team_id: z.number(),
    service_requests_assigned_users: z.array(
      z.object({
        id: z.number(),
        assigned_user: z.lazy(() => userMinimalSchema),
      })
    ),
    service_request_subscribers: z.array(
      z.object({
        id: z.number(),
        user: z.lazy(() => userMinimalSchema),
      })
    ),
  })
  .extend({
    budgetable: z
      .object({
        id: z.number(),
        type: z.enum(["Team", "Project", "User", "CapitalEquipment"]),
        name: z.string(),
        app_href: z.string(),
      })
      .optional(),
  });

export const ServiceRequestsAssignedUsersSchema = z.object({
  service_request_id: z.number(),
  assigned_user_id: z.number(),
});

export const ServiceRequestSubscribersSchema = z.object({
  service_request_id: z.number(),
  user_id: z.number(),
});

export const serviceRequestSearchIndexSchema = createSearchResponseSchema(
  serviceRequestIndexItemSchema
);

export type ServiceRequestPartialData = z.infer<typeof serviceRequestPartialSchema>;
export type ServiceRequestShowData = z.infer<typeof serviceRequestShowSchema>;
export type ServiceRequestIndexItemData = z.infer<typeof serviceRequestIndexItemSchema>;

export type ServiceRequestCreateParams = Pick<
  ServiceRequestPartialData,
  "service_id" | "requesting_user_id" | "request_description" | "site_id"
> &
  Partial<Pick<ServiceRequestPartialData, "files">> &
  Partial<SpendingAuthorityReference>;
export type AssignedUsersCreateParams = z.infer<typeof ServiceRequestsAssignedUsersSchema>;
export type ServiceRequestSearchData = z.infer<typeof serviceRequestSearchIndexSchema>;
export type SubscribersCreateParams = z.infer<typeof ServiceRequestSubscribersSchema>;
//api queries

export const getAllServiceRequests = async () => {
  const result = await api.get(`${SERVICE_REQUESTS_BASE_URL}`);
  return zodParse(z.array(serviceRequestPartialSchema), result.data);
};

export const getAllTeamServiceRequests = async (teamId: number) => {
  const result = await api.get(`${TEAMS_BASE_URL}/${teamId}/${SERVICE_REQUESTS_BASE_URL}`);
  return result.data;
};

export const searchAllRelatedServiceRequests = async ({
  bodyOptions,
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [SERVICE_REQUESTS_BASE_URL, "search", "related"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, bodyOptions, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return result.data;
};

export const searchServiceRequests = async ({
  filters,
  pagination,
  order,
  term,
  aggs,
  currency,
}: SearchParams & { currency?: string }) => {
  const path = [SERVICE_REQUESTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { currency, ...index, ...search });
  return zodParse(serviceRequestSearchIndexSchema, result.data);
};

export const searchAllTeamServiceRequests = async ({
  teamId,
  aggs,
  filters,
  pagination,
  order,
  term,
}) => {
  const path = [SERVICE_REQUESTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { team_id: teamId, ...index, ...search });
  return zodParse(serviceRequestSearchIndexSchema, result.data);
};

export const getServiceRequest = async (id: number) => {
  const result = await api.get(`${SERVICE_REQUESTS_BASE_URL}/${id}`);
  return zodParse(serviceRequestShowSchema, result.data);
};

export const getServiceRequestEvents = async (id: number) => {
  const result = await api.get(`${SERVICE_REQUESTS_BASE_URL}/${id}/events`);
  return zodParse(z.array(serviceRequestEventSchema), result.data);
};

export const getServiceRequestPurchases = async (id: number) => {
  const result = await api.get(`${SERVICE_REQUESTS_BASE_URL}/${id}/purchases`);
  return zodParse(z.array(purchaseShowSchema), result.data);
};

export const createServiceRequest = async (serviceRequest: ServiceRequestCreateParams) => {
  const result = await api.post(`${SERVICE_REQUESTS_BASE_URL}`, {
    service_request: serviceRequest,
  });
  return result.data;
};

export const updateServiceRequest = async (
  serviceRequest: Partial<ServiceRequestCreateParams> &
    Partial<Omit<ServiceRequestShowData, "spending_authority">> & { id: number }
) => {
  const result = await api.put(`${SERVICE_REQUESTS_BASE_URL}/${serviceRequest.id}`, {
    service_request: serviceRequest,
  });
  return result.data;
};

export const createAssignedUser = async (assignedUser: AssignedUsersCreateParams) => {
  return await api.post(`${SERVICE_REQUESTS_ASSIGNED_USERS_BASE_URL}`, {
    service_requests_assigned_users: assignedUser,
  });
};

export const deleteAssignedUser = async (id: number) => {
  await api.delete(`${SERVICE_REQUESTS_ASSIGNED_USERS_BASE_URL}/${id}`);
};

export const createServiceRequestSubscriber = async (
  serviceRequestSubscriber: SubscribersCreateParams
) => {
  return await api.post(`${SERVICE_REQUESTS_SUBSCRIBERS_BASE_URL}`, {
    service_request_subscribers: serviceRequestSubscriber,
  });
};

export const deleteServiceRequestSubscriber = async (id: number) => {
  return await api.delete(`${SERVICE_REQUESTS_SUBSCRIBERS_BASE_URL}/${id}`);
};

export const approveServiceRequest = async (serviceRequestId: number) => {
  const result = await api.post(`${SERVICE_REQUESTS_BASE_URL}/${serviceRequestId}/approve`);
  return zodParse(serviceRequestShowSchema, result.data);
};

export const declineServiceRequest = async (serviceRequestId: number) => {
  const result = await api.post(`${SERVICE_REQUESTS_BASE_URL}/${serviceRequestId}/decline`);
  return zodParse(serviceRequestShowSchema, result.data);
};

//query hooks
export const invalidateServiceRequest = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SERVICE_REQUESTS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const invalidateServiceRequestPurchasesAndDetails = (serviceRequestId: number) => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([SERVICE_REQUESTS_BASE_URL, serviceRequestId, "purchases"]);
    queryClient.invalidateQueries([SERVICE_REQUESTS_BASE_URL, serviceRequestId, "details"]);
  };
};

export const useGetAllServiceRequestsQuery = () => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL],
    queryFn: () => getAllServiceRequests(),
  });
};

export const useSearchServiceRequestsQuery = (params: SearchParams & { currency?: string }) => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL, params],
    queryFn: () => searchServiceRequests(params),
  });
};

export const useSearchAllRelatedServiceRequestsQuery = (params: SearchParams) => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL, params],
    queryFn: () => searchAllRelatedServiceRequests(params),
  });
};

export const useGetServiceRequestQuery = (id: number) => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL, id, "details"],
    queryFn: () => getServiceRequest(id),
  });
};

export const useGetServiceRequestEventsQuery = (id: number) => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL, id, "events"],
    queryFn: () => getServiceRequestEvents(id),
  });
};

export const useGetServiceRequestPurchases = (id: number) => {
  return useQuery({
    queryKey: [SERVICE_REQUESTS_BASE_URL, id, "purchases"],
    queryFn: () => getServiceRequestPurchases(id),
    enabled: !!id,
  });
};

export const useCreateServiceRequest = () => {
  return useMutation({
    mutationFn: createServiceRequest,
    onSuccess: invalidateServiceRequest(),
  });
};

export const useUpdateServiceRequest = (id: number) => {
  return useMutation({
    mutationFn: updateServiceRequest,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useCreateAssignedUser = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: createAssignedUser,
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};

export const useDeleteAssignedUser = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: deleteAssignedUser,
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};

export const useCreateServiceRequestSubscriber = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: createServiceRequestSubscriber,
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};

export const useDeleteServiceRequestSubscriber = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestSubscriber,
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};

export const useApproveServiceRequest = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: () => approveServiceRequest(serviceRequestId),
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};
export const useDeclineServiceRequest = (serviceRequestId: number) => {
  return useMutation({
    mutationFn: () => declineServiceRequest(serviceRequestId),
    onSuccess: invalidateServiceRequest(serviceRequestId, "details"),
  });
};
