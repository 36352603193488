import { usePagination } from "@sciencecorp/helix-components";
import { useState, useEffect } from "react";
import { MilestonePartialData, MilestoneStatus, useMilestonesQuery } from "../../../api/milestone";
import { useCurrentUserRoles } from "../../../helpers/hooks/useCurrentUserRoles";

type MilestoneByStatus = Record<MilestoneStatus, MilestonePartialData[] | null>;
const INITIAL: MilestoneByStatus = {
  draft: null,
  active: null,
  completed: null,
  abandoned: null,
};

export const useMilestonesByStatus = () => {
  const [milestonesByStatus, setMilestonesByStatus] = useState(INITIAL);
  const { roles, isLoading: isUserRolesLoading } = useCurrentUserRoles();
  const {
    data: milestones,
    isLoading: isMilestonesLoading,
    ...rest
  } = useMilestonesQuery({ per_page: -1 });

  useEffect(() => {
    if (!milestones?.results || !roles) return;
    const nextCategories = { ...INITIAL };
    nextCategories.draft = milestones.results.filter(
      ({ status, team }) =>
        status === "draft" && (roles.some((value) => value === "hr_admin") || team.can_act_as_lead)
    );
    nextCategories.active = milestones.results.filter(({ status }) => status === "active");
    nextCategories.completed = milestones.results.filter(({ status }) => status === "completed");

    setMilestonesByStatus(nextCategories);
  }, [milestones, roles]);

  return {
    milestonesByStatus,
    isLoading: isMilestonesLoading || isUserRolesLoading || milestonesByStatus.active === null,
    ...rest,
  };
};
