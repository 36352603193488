import {
  Box,
  ButtonGroup,
  HStack,
  Heading,
  IconButton,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EditableDate, EditableText } from "@sciencecorp/helix-components";
import { Select } from "chakra-react-select";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { RiCheckFill, RiCloseFill, RiEdit2Line } from "react-icons/ri";
import { CandidateData, CandidateKeyringMessageData } from "../../../api/candidates";

import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { MoneyInput } from "../../MoneyInput";
import { MoneyText } from "../../MoneyText";

const unitOptions = [
  { label: "Per Year", value: "year" },
  { label: "Per Hour", value: "hour" },
];

interface CandidateInformationProps {
  candidate: CandidateData;
  candidateKrData: CandidateKeyringMessageData;
  updateCandidateData(candidate: Partial<CandidateKeyringMessageData>): void;
}
export const CandidateInformation: React.FC<CandidateInformationProps> = ({
  candidate,
  candidateKrData,
  updateCandidateData,
}) => {
  const currency = useCurrency();
  const [edit, setEdit] = useState(false);
  const [salary, setSalary] = useState<{
    salary_per_unit: Money;
    unit: string;
    equity: number;
  }>(
    candidateKrData.salary ||
      candidate.salary || {
        salary_per_unit: Money.zero(currency),
        unit: "year",
        equity: 0,
      }
  );
  const userQuery = useCurrentUserQuery();
  const editable = userHasRole(userQuery, "recruiting_admin") || false;

  return (
    <VStack width="100%" p={8} spacing={6} borderRadius="md" align="start">
      <Heading size="md">Additional Information</Heading>
      <VStack width="100%" align="start">
        <Heading size="sm">Education</Heading>
        <Box width="100%">
          <EditableText
            persistentEdit={editable}
            disabled={!editable}
            defaultValue={candidate.education || undefined}
            multiline={true}
            placeholder="Add education information"
            onSubmit={(value) => {
              if (value) updateCandidateData({ education: value });
            }}
          />
        </Box>
      </VStack>
      <VStack width="100%" align="start">
        <Heading size="sm">CCAT Score</Heading>
        <EditableText
          persistentEdit={editable}
          disabled={!editable}
          defaultValue={candidateKrData.ccat_score?.toString() || undefined}
          multiline={false}
          placeholder="Add CCAT score (0-50)"
          onSubmit={(value) => {
            if (value) updateCandidateData({ ccat_score: parseInt(value) });
          }}
        />
      </VStack>
      <VStack width="100%" align="start">
        <Heading size="sm">Employment History</Heading>
        <Box width="100%">
          <EditableText
            persistentEdit={editable}
            disabled={!editable}
            multiline={true}
            defaultValue={candidate.employment_history || undefined}
            placeholder="Add employment history"
            onSubmit={(value) => {
              if (value) updateCandidateData({ employment_history: value });
            }}
          />
        </Box>
      </VStack>
      <VStack width="100%" align="start">
        <Heading size="sm">Evidence of Exceptional Ability</Heading>
        <Box width="100%">
          <EditableText
            persistentEdit={editable}
            disabled={!editable}
            multiline={true}
            defaultValue={candidate.exceptional_ability || undefined}
            placeholder="Add evidence of exceptional ability"
            onSubmit={(value) => {
              if (value) updateCandidateData({ exceptional_ability: value });
            }}
          />
        </Box>
      </VStack>
      <VStack width="100%" align="start">
        <Heading size="sm">Offer Letter</Heading>
        <Box width="100%">
          <EditableText
            persistentEdit={editable}
            disabled={!editable}
            defaultValue={candidate.offer_letter || undefined}
            placeholder="Add link to offer letter"
            onSubmit={(value) => {
              if (value) updateCandidateData({ offer_letter: value });
            }}
          />
        </Box>
      </VStack>
      <HStack width="100%">
        <VStack width="100%" align="start">
          <Heading size="sm">Compensation</Heading>

          {!edit ? (
            <HStack spacing={4}>
              {candidate.salary ? (
                <HStack spacing={6}>
                  <VStack align="start">
                    <Heading size="xs">Salary</Heading>
                    <MoneyText money={candidate.salary.salary_per_unit} />
                  </VStack>
                  <VStack align="start">
                    <Heading size="xs">Unit</Heading>
                    <Text>
                      {unitOptions.find((ele) => ele.value === candidate.salary?.unit)?.label}
                    </Text>
                  </VStack>
                  <VStack align="start">
                    <Heading size="xs">Equity</Heading>
                    <Text>{candidate.salary.equity}</Text>
                  </VStack>
                </HStack>
              ) : (
                <Text fontStyle="italic" color="gray.400">
                  Add Salary and Equity
                </Text>
              )}

              <IconButton
                alignSelf="end"
                size="xs"
                icon={<RiEdit2Line />}
                aria-label="edit salary"
                onClick={() => setEdit(true)}
              />
            </HStack>
          ) : (
            <HStack>
              <Stack direction={{ base: "column", lg: "row" }}>
                <VStack align="start">
                  <Heading size="xs">Salary</Heading>
                  <MoneyInput
                    size="sm"
                    borderRadius="md"
                    value={salary.salary_per_unit}
                    onChange={(value: Money) => {
                      setSalary((prev) => ({
                        ...prev,
                        salary_per_unit: value,
                      }));
                    }}
                  />
                </VStack>
                <VStack align="start">
                  <Heading size="xs">Unit</Heading>
                  <Box w={32}>
                    <Select
                      size="sm"
                      useBasicStyles
                      defaultValue={unitOptions.find((ele) => ele.value === salary.unit)}
                      options={unitOptions}
                      onChange={(value) =>
                        value && setSalary((prev) => ({ ...prev, unit: value.value }))
                      }
                    />
                  </Box>
                </VStack>
                <VStack align="start">
                  <Heading size="xs">Equity</Heading>
                  <Input
                    type="number"
                    size="sm"
                    borderRadius="md"
                    defaultValue={salary.equity}
                    onChange={(e) => {
                      setSalary((prev) => ({ ...prev, equity: Number(e.target.value) }));
                    }}
                  />
                </VStack>
              </Stack>
              <ButtonGroup alignSelf={{ base: "center", lg: "end" }}>
                <IconButton
                  aria-label="submit salary"
                  size="xs"
                  icon={<RiCheckFill />}
                  onClick={() => {
                    updateCandidateData({ salary: salary });
                    setEdit(false);
                  }}
                />
                <IconButton
                  aria-label="cancel"
                  size="xs"
                  icon={<RiCloseFill />}
                  onClick={() => setEdit(false)}
                />
              </ButtonGroup>
            </HStack>
          )}
        </VStack>
      </HStack>

      <VStack width="100%" align="start">
        <Heading size="sm">Start Date</Heading>
        <EditableDate
          preview={
            (candidate.start_date && (
              <Text>{DateTime.fromISO(candidate.start_date).toFormat("LLL dd, yyyy")}</Text>
            )) ||
            undefined
          }
          persistentEdit={editable}
          disabled={!editable}
          defaultValue={
            candidate.start_date
              ? DateTime.fromISO(candidate.start_date).toISODate() || undefined
              : undefined
          }
          placeholder="Add start date"
          onSubmit={(value) => {
            if (value)
              updateCandidateData({
                start_date: DateTime.fromISO(value),
              });
          }}
        />
      </VStack>
      <VStack width="100%" align="start">
        <HStack justify="space-between" width="100%">
          <Heading size="sm">Debrief Notes</Heading>
        </HStack>
        <EditableText
          persistentEdit={editable}
          disabled={!editable}
          defaultValue={candidate.notes}
          multiline={true}
          placeholder="Add notes"
          onSubmit={(value) => {
            if (value && userQuery.isSuccess)
              updateCandidateData({
                notes: value,
                candidate_timeline_events: [
                  ...(candidateKrData.candidate_timeline_events ?? []),
                  {
                    candidate_id: candidate.id,
                    user_id: userQuery.data.id,
                    notes: value,
                    created_at: DateTime.now(),
                  },
                ],
              });
          }}
        />
      </VStack>
    </VStack>
  );
};
