import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import {
  keyringMessageSchema,
  userPublicKeyIndexSchema,
  userPublicKeySchema,
} from "./keyring_message";
import { timelineEventSchema } from "./timeline_events";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { Money, moneySchema } from "../helpers/Money";

export const USER_COMPENSATIONS_BASE_URL = `user_compensations`;

export const hoursSchema = z.number();

export const invalidateUserCompensations = (queryClient: QueryClient) => {
  queryClient.invalidateQueries([USER_COMPENSATIONS_BASE_URL]);
  return;
};

export const userCompensationPayTypeOptions = [
  {
    label: "Salary",
    value: "salary_v2",
  },
  {
    label: "Hourly",
    value: "hourly_v2",
  },
];

export const userCompensationSchema = z.object({
  id: z.number(),
  user: userMinimalSchema,
  keyring_message: keyringMessageSchema,
  timeline_events: z.array(timelineEventSchema).optional(),
  keyring_user_keys: z.array(userPublicKeySchema),
});

const salaryCompensationSchemaV2 = z.object({
  payType: z.literal("salary_v2"),
  yearlySalary: moneySchema,
  hourlyRate: z.undefined().optional(),
  minHoursPerWeek: z.undefined().optional(),
  maxHoursPerWeek: z.undefined().optional(),
});

const hourlyCompensationSchemaV2 = z.object({
  payType: z.literal("hourly_v2"),
  yearlySalary: z.undefined().optional(),
  hourlyRate: moneySchema,
  minHoursPerWeek: hoursSchema,
  maxHoursPerWeek: hoursSchema,
});

const salaryCompensationSchemaV1 = z.object({
  payType: z.literal("salary"),
  yearlySalaryCents: z.number(),
  hourlyRateCents: z.undefined().optional(),
  minHoursPerWeek: z.undefined().optional(),
  maxHoursPerWeek: z.undefined().optional(),
});

const hourlyCompensationSchemaV1 = z.object({
  payType: z.literal("hourly"),
  hourlyRateCents: z.number(),
  minHoursPerWeek: hoursSchema,
  maxHoursPerWeek: hoursSchema,
  yearlySalaryCents: z.undefined().optional(),
});

export const anyCompensationSchema = z.discriminatedUnion("payType", [
  salaryCompensationSchemaV2,
  hourlyCompensationSchemaV2,
  salaryCompensationSchemaV1,
  hourlyCompensationSchemaV1,
]);

type AnyCompensation = z.infer<typeof anyCompensationSchema>;
type CompensationV2 = z.infer<typeof compensationSchema>;
const coerceV1ToV2 = (uc: AnyCompensation): CompensationV2 => {
  if (uc.payType == "salary_v2" || uc.payType == "hourly_v2") {
    return uc;
  } else if (uc.payType == "salary") {
    return {
      payType: "salary_v2",
      yearlySalary: Money.fromMinorUnits(uc.yearlySalaryCents, "USD"),
    };
  } else {
    return {
      payType: "hourly_v2",
      hourlyRate: Money.fromMinorUnits(uc.hourlyRateCents, "USD"),
      minHoursPerWeek: uc.minHoursPerWeek,
      maxHoursPerWeek: uc.maxHoursPerWeek,
    };
  }
};

const compensationCoerceSchema = anyCompensationSchema.transform(coerceV1ToV2);

export const compensationSchema = z.discriminatedUnion("payType", [
  salaryCompensationSchemaV2,
  hourlyCompensationSchemaV2,
]);

export const userCompensationMessageSchema = z.object({
  data: compensationCoerceSchema,
});

const userCompensationMessageSchemaV2 = z.object({
  data: compensationSchema,
});

export type SalaryCompensation = z.infer<typeof salaryCompensationSchemaV2>;
export type HourlyCompensation = z.infer<typeof hourlyCompensationSchemaV2>;
export type Compensation = z.infer<typeof compensationSchema>;

export type UserCompensation = z.infer<typeof userCompensationSchema>;
export type UserCompensationMessage = z.infer<typeof userCompensationMessageSchemaV2>;

export const getUserCompensations = async () => {
  const response = await api.get(`${USER_COMPENSATIONS_BASE_URL}`);
  return zodParse(userCompensationSchema.array(), response.data);
};

export const useGetUserCompensations = (enabled = true) => {
  return useQuery({
    queryKey: [USER_COMPENSATIONS_BASE_URL],
    queryFn: getUserCompensations,
    enabled: enabled,
  });
};

export const getCompensationForUser = async (userId: number) => {
  const response = await api.get(`users/${userId}/compensation`);
  return zodParse(userCompensationSchema.nullable(), response.data);
};

export const useGetCompensationForUser = (userId?: number | undefined, enabled = true) => {
  return useQuery({
    queryKey: [USER_COMPENSATIONS_BASE_URL, "user", userId],
    queryFn: () => getCompensationForUser(userId!),
    enabled: enabled && !!userId,
  });
};

export const createUserCompenstation = async ({
  userId,
  keyringMessageContent,
}: {
  userId: number;
  keyringMessageContent: string;
}): Promise<UserCompensation> => {
  const response = await api.post(`${USER_COMPENSATIONS_BASE_URL}`, {
    user_id: userId,
    keyring_message_content: keyringMessageContent,
  });
  return zodParse(userCompensationSchema, response.data);
};

export const useCreateUserCompensation = (id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createUserCompenstation,
    onSuccess: () => {
      invalidateUserCompensations(queryClient);
    },
  });
};

export const updateUserCompensation = async ({
  userCompensationId,
  keyringMessageContent,
}: {
  userCompensationId?: number;
  keyringMessageContent: string;
}) => {
  const response = await api.put(`${USER_COMPENSATIONS_BASE_URL}/${userCompensationId}`, {
    keyring_message_content: keyringMessageContent,
  });
  return zodParse(userCompensationSchema, response.data);
};

export const useUpdateUserCompensation = (id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUserCompensation,
    onSuccess: () => {
      invalidateUserCompensations(queryClient);
    },
  });
};

export const getUserCompPublicKeys = async () => {
  const response = await api.get(`${USER_COMPENSATIONS_BASE_URL}/public_keys`);
  return zodParse(userPublicKeyIndexSchema, response.data);
};

export const useGetUserCompPublicKeys = (enabled = true) => {
  return useQuery({
    queryKey: [USER_COMPENSATIONS_BASE_URL, "public_keys"],
    queryFn: getUserCompPublicKeys,
    enabled: enabled,
  });
};
