import React from "react";
import { FormModal } from "@sciencecorp/helix-components";
import { Button, IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { InventoryShowData } from "../../../api/inventory";
import { BsGear } from "react-icons/bs";
import { NewInventoryDetails } from "./NewInventoryDetails";
import { useNewInventoryModal } from "../Inventories/components/RestockModalComponents/util";

type NewInventoryItemModalProps = {
  inventory?: InventoryShowData;
};

export const NewInventoryModal = ({ inventory }: NewInventoryItemModalProps) => {
  const {
    isOpen,
    onOpen,
    isLoadingCreateInventory,
    isLoadingUpdateInventory,
    handleSubmit,
    control,
    formValues,
    handleClose,
    allFieldsFilledIn,
    setValue,
  } = useNewInventoryModal(inventory);

  return (
    <>
      {inventory ? (
        <IconButton aria-label="edit settings" icon={<BsGear />} onClick={onOpen} />
      ) : (
        <Button leftIcon={<AddIcon />} onClick={onOpen}>
          Add Item
        </Button>
      )}
      <FormModal
        title={inventory ? "Edit Inventory Item" : "New Inventory Item"}
        submitButtonColorSchema="teal"
        closeOnOverlayClick={false}
        submitButtonTitle="Done"
        size="3xl"
        isOpen={isOpen}
        isLoading={isLoadingCreateInventory || isLoadingUpdateInventory}
        submitButtonDisabled={!allFieldsFilledIn()}
        onClose={handleClose}
        handleSubmit={handleSubmit}>
        <NewInventoryDetails
          formValues={formValues}
          control={control}
          setValue={setValue}
          inventory={inventory}
        />
      </FormModal>
    </>
  );
};
