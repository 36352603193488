import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import { Select } from "@sciencecorp/helix-components";
import * as _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from "victory";
import {
  MonthlySpendSummary,
  SpendSummary,
  useGetCompanySpendSummary,
} from "../../../../api/finance";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { denull } from "../../../../helpers/index";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";

const monthToDate = { label: "Month To Date", value: "monthToDate" };
const yearToDate = { label: "Year To Date", value: "yearToDate" };
const spendSummaryOptions = { monthToDate, yearToDate };
const options = Object.keys(spendSummaryOptions).map((optionValue) => ({
  value: optionValue,
  label: spendSummaryOptions[optionValue].label,
}));

type LineStyling = {
  stroke: string;
  strokeWidth: string;
  strokeOpacity?: string;
};

const colors: string[][] = [
  ["gray.500", "green.900"],
  ["red.400", "red.500"],
  ["red.200", "red.300"],
  ["orange.400", "orange.500"],
  ["yellow.400", "yellow.500"],
  ["green.400", "green.500"],
  ["green.200", "green.300"],
  ["teal.500", "teal.600"],
  ["teal.200", "teal.300"],
  ["blue.400", "blue.500"],
  ["purple.400", "purple.500"],
  ["purple.800", "purple.300"],
];

function getLineStyling(): LineStyling[] {
  const currentMonth = new Date().getMonth();
  return _.range(0, 12).map((i) => {
    return {
      stroke: useToken("colors", useColorModeValue(colors[i][0], colors[i][1])),
      strokeWidth: "2",
      strokeOpacity: i < currentMonth ? "0.3" : "0.75",
    };
  });
}
type DayLineDataPoint = {
  day_of_month: number;
  expense: number;
  profit: number;
  revenue: number;
};

const lineStart: DayLineDataPoint[] = [{ day_of_month: 0, expense: 0, profit: 0, revenue: 0 }];

function monthlySpendSummaryToLines(
  lineStyling: LineStyling[],
  monthlySpendSummary: MonthlySpendSummary,
  index: number,
  isLastMonth: boolean
): JSX.Element {
  const lineStyle = lineStyling[index];
  if (isLastMonth) {
    lineStyle.strokeOpacity = "1";
    lineStyle.strokeWidth = "3";
  }
  const lineData: DayLineDataPoint[] = lineStart.concat(
    monthlySpendSummary.daily_summaries.map((day_summary) => {
      return {
        day_of_month: day_summary.day.toUTC().day,
        expense: day_summary.expense.majorUnits.toNumber(),
        profit: day_summary.profit.majorUnits.toNumber(),
        revenue: day_summary.revenue.majorUnits.toNumber(),
      };
    })
  );

  monthlySpendSummary.daily_summaries;
  return (
    <VictoryLine
      key={monthlySpendSummary.month.toISO()}
      x="day_of_month"
      y="expense"
      style={{
        data: lineStyle,
        parent: { border: "1px solid #ccc" },
      }}
      data={lineData}
    />
  );
}

function burnAndRevenue(
  spendSummary: SpendSummary,
  summaryTypeValue: string,
  currency: string
): { burn: Money; revenue: Money } {
  if (summaryTypeValue === monthToDate.value) {
    const month = _.last(spendSummary.monthly_summaries);
    const lastDay = _.last(month?.daily_summaries);
    return {
      burn: lastDay?.expense || Money.zero(currency),
      revenue: lastDay?.revenue || Money.zero(currency),
    };
  } else {
    return spendSummary.monthly_summaries.reduce(
      (acc: { burn: Money; revenue: Money }, nextMonth: MonthlySpendSummary) => {
        const lastDay = _.last(nextMonth.daily_summaries);
        return {
          burn: acc.burn.add(lastDay?.expense || Money.zero(currency)),
          revenue: acc.revenue.add(lastDay?.revenue || Money.zero(currency)),
        };
      },
      { burn: Money.zero(currency), revenue: Money.zero(currency) }
    );
  }
}

export function CompanySpendSummary(_props: {}): JSX.Element {
  const currency = useCurrency();
  const summaryCompanySpendQuery = useGetCompanySpendSummary();
  const gray200 = useColorModeValue("gray.200", "gray.700");
  const navigate = useNavigate();
  const [summaryTypeValue, setSummaryTypeValue] = React.useState(yearToDate.value);
  if (summaryCompanySpendQuery.isLoading || !summaryCompanySpendQuery.data) {
    return <p>Loading...</p>;
  } else {
    const spendSummary: SpendSummary = summaryCompanySpendQuery.data;
    const { burn, revenue } = burnAndRevenue(spendSummary, summaryTypeValue, currency);
    return (
      <>
        <Flex
          direction="column"
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          w="100%"
          gap={4}
          p={6}>
          <Flex direction="column" gap={5}>
            <Flex justifyContent="space-between" direction={["column", "row"]} gap={[2, 0, 0]}>
              <Box>
                <Heading as="h2" size="md">
                  Company Spend
                </Heading>
              </Box>
              <Spacer />
              <Flex>
                <Box>
                  <Select
                    size="sm"
                    name="summaryType"
                    options={options}
                    value={summaryTypeValue}
                    onChange={(value) => setSummaryTypeValue(value as string)}
                  />
                </Box>
                <Box>
                  <Button
                    size="sm"
                    ml={3}
                    onClick={() => {
                      navigate("/finance/ledger");
                    }}
                    bg={gray200}>
                    View General Ledger
                  </Button>
                </Box>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" gap={[4, 6, 12]} flexDirection={["column", "row"]}>
              <Stat maxW="max-content">
                <StatLabel> Balance</StatLabel>
                <StatNumber>
                  <MoneyText money={spendSummary.current_balance} />
                </StatNumber>
              </Stat>

              <Stat maxW="max-content">
                <StatLabel> Burn </StatLabel>
                <StatNumber>
                  <MoneyText money={burn} />
                </StatNumber>
              </Stat>

              <Stat maxW="max-content">
                <StatLabel> Revenue</StatLabel>
                <StatNumber>
                  <MoneyText money={revenue} />
                </StatNumber>
              </Stat>
            </Flex>
            <SpendChart summaryTypeValue={summaryTypeValue} currency={currency} />
          </Flex>
        </Flex>
      </>
    );
  }
}

function DayTickLabel({ x, y, verticalAnchor, textAnchor, index, text }: any): JSX.Element {
  const tickColor = useColorModeValue("gray.600", "gray.200");
  const tickColorHex = useToken("colors", tickColor);
  if (index == 0) {
    return <></>;
  }
  return (
    <VictoryLabel
      style={{ fill: tickColorHex, fontFamily: "inherit" }}
      x={x}
      y={y}
      verticalAnchor={verticalAnchor}
      textAnchor={textAnchor}
      text={text}></VictoryLabel>
  );
}

function SpendChart(props: { summaryTypeValue: string; currency: string }): JSX.Element {
  const summaryCompanySpendQuery = useGetCompanySpendSummary();
  const gray200 = useColorModeValue("gray.200", "gray.700");
  const axisColor = useToken("colors", gray200);
  // const theme = useScienceGrayscaleTheme();
  const lineStyling: LineStyling[] = getLineStyling();
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(400);
  const [chartHeight, setChartHeight] = useState(400);

  if (summaryCompanySpendQuery.isLoading || !summaryCompanySpendQuery.data) {
    return <p>Loading...</p>;
  } else {
    const spendSummary: SpendSummary = summaryCompanySpendQuery.data;
    const lines = _.sortBy(spendSummary.monthly_summaries, (ms) => {
      return ms.month.toUTC().startOf("month").month;
    }).map((monthlySummary: MonthlySpendSummary, index: number) => {
      return monthlySpendSummaryToLines(
        lineStyling,
        monthlySummary,
        index,
        DateTime.now().toUTC().month == monthlySummary.month.toUTC().month
      );
    });
    let linesToRender: JSX.Element[] = lines;
    let daysToShow: number = 31;
    if (props.summaryTypeValue === monthToDate.value) {
      linesToRender = [denull(_.last(lines))];
      daysToShow = denull(denull(_.last(spendSummary.monthly_summaries)).month.daysInMonth);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setChartWidth(width);
        setChartHeight(height);
      });
    });

    useEffect(() => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.clientWidth);
        setChartHeight(chartRef.current.clientHeight);

        resizeObserver.observe(chartRef.current);
      }
    }, [chartRef.current]);

    let tickStep = 3;
    if (chartWidth > 768) {
      tickStep = 1;
    } else if (chartWidth > 480) {
      tickStep = 2;
    }

    return (
      <Box>
        <Box ref={chartRef} height={["25vh", "300px"]}>
          <VictoryChart
            domainPadding={{ y: 75 }}
            padding={{ bottom: 35, left: 60, right: 20, top: 20 }}
            height={chartHeight}
            width={chartWidth}>
            <VictoryAxis
              key="Axis"
              style={{
                axis: { stroke: axisColor },
              }}
              tickValues={_.range(0, daysToShow + 1, tickStep)}
              tickLabelComponent={<DayTickLabel />}
            />

            <VictoryAxis
              dependentAxis
              key="Axis"
              tickCount={5}
              tickFormat={(tick) =>
                Money.fromMajorUnitString(tick.toString(), props.currency).format()
              }
              style={{
                axis: { stroke: "none" },
                grid: { stroke: axisColor, fill: "black" },
                tickLabels: {
                  fontFamily: "inherit",
                },
              }}
            />
            {linesToRender}
          </VictoryChart>
        </Box>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Flex
            maxWidth={["100%", "100%", "900px"]}
            gap={4}
            justifyContent={"space-around"}
            alignItems={"center"}
            flexFlow={"wrap"}
            pl={8}>
            {[
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ].map((month, index) => {
              return (
                <HStack>
                  <Box
                    w={["10px", "10px", "15px"]}
                    h={["10px", "10px", "15px"]}
                    background={lineStyling[index].stroke}></Box>
                  <Text size={["2xs", "xs", "md"]}>{month}</Text>
                </HStack>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    );
  }
}
