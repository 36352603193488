import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  VStack,
  ModalBody,
  Tag,
  Text,
  Badge,
  ModalContent,
  ModalOverlay,
  FormControl,
  useColorModeValue,
  Button,
  UnorderedList,
  ListItem,
  Divider,
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
} from "@chakra-ui/react";
import { MarkdownComponent } from "../../Chat/components";
import { decryptReviewFeedback } from "../../../api/review_feedback";
import { UserLoggedInData } from "../../../api/user";
import { updateReviewFeedback } from "../../../api/review_feedback";
import type { ReviewFeedbackPartialData } from "../../../api/review_feedback";
import { SubmitPinModal } from "./SubmitPinModal";

const bgColorForStatus = (status: string) => {
  switch (status) {
    case "green":
      return "green";
    case "yellow":
    case "orange":
      return "yellow";
    case "red":
      return "red";
    default:
      return "gray";
  }
};

const labelForStatus = (status: string) => {
  switch (status) {
    case "green":
      return "You're doing great";
    case "yellow":
    case "orange":
      return "Ok for now";
    case "red":
      return "Let's discuss";
    default:
      return "Score is missing";
  }
};

const nextStepsForStatus = (status: string) => {
  switch (status) {
    case "green":
      return "Since things are going well, no further action is required at this time. You'll get more feedback in a few months, or when Helix thinks something has changed.";
    case "yellow":
    case "orange":
      return "You may or may not hear from People Ops to discuss, but we hope you take this feedback to heart and are able to find something useful in it. People Ops can only see the color code, not the specific feedback.";
    case "red":
      return "People Ops will reach out to find a time to discuss next steps.";
    default:
      return "N/A";
  }
};

type FeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  encryptedFeedback: ReviewFeedbackPartialData | null;
  reviewFeedbackQuery: () => void;
  currentUser?: UserLoggedInData;
};

export const FeedbackModal = ({
  isOpen,
  onClose,
  encryptedFeedback,
  reviewFeedbackQuery,
  currentUser,
}: FeedbackModalProps) => {
  const [pin, setPin] = useState("");
  const [decryptedScore, setDecryptedScore] = useState("");
  const [decryptedContent, setDecryptedContent] = useState("");
  const [decryptionError, setDecryptionError] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const closeModal = () => {
    setPin("");
    setDecryptedContent("");
    setDecryptionError(false);
    setShowDisclaimer(true);
    onClose();
    reviewFeedbackQuery();
  };

  const submitPin = () => {
    setDecryptionError(false);
    if (encryptedFeedback && currentUser) {
      decryptReviewFeedback(encryptedFeedback, currentUser, pin)
        .then((decryptedFeedback) => {
          if (!decryptedFeedback) {
            setPin("");
            setDecryptionError(true);
            return;
          }
          setDecryptionError(false);
          setDecryptedContent(decryptedFeedback.feedback);
          setDecryptedScore(decryptedFeedback.score);
        })
        .then(() => {
          if (encryptedFeedback.viewed !== undefined && !encryptedFeedback.viewed) {
            updateReviewFeedback({ id: encryptedFeedback.id, viewed: true });
          }
        })
        .catch((err) => {
          console.log(err);
          setPin("");
          setDecryptionError(true);
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      size={decryptedContent && !decryptionError ? "xl" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={2}>
          {decryptedContent && !decryptionError
            ? showDisclaimer
              ? "How this works"
              : "Feedback for you"
            : "PIN"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {decryptedContent && !decryptionError ? (
            showDisclaimer ? (
              <VStack align="start" mb={4}>
                <Text fontWeight="medium">A few pieces of important context:</Text>
                <UnorderedList px={4} spacing={2}>
                  <ListItem>
                    The feedback you are about to read is derived from the responses in others'
                    reviews of you.
                  </ListItem>
                  <ListItem>
                    This feedback is generated automatically by Helix using OpenAI's GPT-4 reasoning
                    model and does not represent the viewpoint of any one person at Science.
                  </ListItem>
                  <ListItem>
                    You must have reviews with comments from at least five people for feedback to be
                    generated.
                  </ListItem>
                  <ListItem>
                    Your lead(s) cannot see your feedback; it is up to you whether to discuss the
                    feedback received here with others.
                  </ListItem>
                  <ListItem>
                    The color-code at the top is derived directly from your eigenreview score and is
                    not generated by AI.
                  </ListItem>
                </UnorderedList>
                <Button colorScheme="teal" alignSelf="end" onClick={() => setShowDisclaimer(false)}>
                  I understand
                </Button>
              </VStack>
            ) : (
              <VStack spacing={8} align="start">
                <FormControl textAlign={"center"}>
                  <Tag
                    size="4xl"
                    p={4}
                    width={"100%"}
                    textAlign={"center"}
                    variant="solid"
                    colorScheme={bgColorForStatus(decryptedScore)}>
                    {labelForStatus(decryptedScore)}
                  </Tag>
                </FormControl>
                <FormControl>
                  <Badge>Powered by GPT-4</Badge>
                  <MarkdownComponent>{decryptedContent}</MarkdownComponent>
                </FormControl>
                <FormControl>
                  <Divider />
                  <Text
                    color={useColorModeValue("gray.600", "gray.400")}
                    fontSize={14}
                    mt={2}
                    fontWeight={"bold"}>
                    What's next?
                  </Text>
                  <Text
                    color={useColorModeValue("gray.600", "gray.300")}
                    fontSize={14}
                    mt={1}
                    mb={3}>
                    {nextStepsForStatus(decryptedScore)}
                  </Text>
                </FormControl>
              </VStack>
            )
          ) : // PIN modal to enter PIN to unlocking feedback
          currentUser?.has_outstanding_prompts ? (
            <>
              <Alert status="error" mb={4}>
                <AlertIcon />
                <AlertDescription>
                  You must complete or decline all{" "}
                  <Box as="span" fontWeight="bold">
                    Review Requests
                  </Box>{" "}
                  before reading this feedback.
                </AlertDescription>
              </Alert>
            </>
          ) : (
            <SubmitPinModal
              pin={pin}
              setPin={setPin}
              decryptionError={decryptionError}
              submitPin={submitPin}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
