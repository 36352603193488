import React, { useEffect, useState } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Box,
  Text,
  InputGroup,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useCredentialsOptions } from "../../../../api/options";
import {
  TrainingData,
  useNewTraining,
  useUpdateTraining,
  useCreateCredentialTraining,
  useDeleteCredentialTraining,
} from "../../../../api/trainings";
import { Controller, useForm } from "react-hook-form";

type NewTrainingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  training?: TrainingData;
};

type FormValues = {
  name: string;
  selected_credentials: { label: string; value: string; credentialTraining?: number }[];
  has_test: string;
  cutoff_score: number;
  expiration_months: number;
};

export const NewTrainingModal = ({ isOpen, onClose, training }: NewTrainingModalProps) => {
  const [hasTest, setHasTest] = useState<string>(training?.has_test ? "yes" : "no");
  const [trainingExpires, setTrainingExpires] = useState<string>("no");
  const { mutate: newTraining } = useNewTraining();
  const { mutate: updateTraining } = useUpdateTraining();
  const { options: credentialOptions } = useCredentialsOptions();
  const { mutate: createCredentialTraining } = useCreateCredentialTraining();
  const { mutate: deleteCredentialTraining } = useDeleteCredentialTraining();

  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: {
      name: "",
      selected_credentials: [],
      has_test: "no",
      cutoff_score: 0,
      expiration_months: 0,
    },
  });

  const formValues = watch();

  const allFieldsFilledIn = () => {
    return (
      formValues.name &&
      formValues.selected_credentials.length &&
      (formValues.has_test === "no" || (formValues.has_test === "yes" && formValues.cutoff_score))
    );
  };

  useEffect(() => {
    if (training) {
      const selectedCredentials = training.credential_trainings.map((credentialTraining) => ({
        label: credentialTraining.credential.name,
        value: credentialTraining.credential.id.toString(),
        credentialTraining: credentialTraining.id,
      }));
      reset({
        name: training.name,
        selected_credentials: selectedCredentials,
        has_test: training.has_test ? "yes" : "no",
        cutoff_score: training.cutoff_score ? training.cutoff_score : 0,
        expiration_months: training.expiration_months ? training.expiration_months : 0,
      });
      setTrainingExpires(training.expiration_months ? "yes" : "no");
    }
  }, [training]);

  const handleClose = () => {
    reset();
    setHasTest(training?.has_test ? "yes" : "no");
    onClose();
  };

  const onSubmit = (data: FormValues) => {
    const credentials = data.selected_credentials.map((credential) => Number(credential.value));
    const trainingData = {
      ...data,
      has_test: data.has_test === "yes" ? true : false,
      cutoff_score: data.has_test === "yes" ? data.cutoff_score : null,
      expiration_months: trainingExpires === "yes" ? data.expiration_months : null,
    };

    if (training) {
      const credentialTrainingsToDelete = training.credential_trainings
        .filter((ct) => !credentials.includes(ct.credential.id))
        .map((ct) => ct.id);
      const existingCredentialIds = training.credential_trainings.map((ct) => ct.credential.id);
      const credentialTrainingsToAdd = credentials.filter(
        (credentialId) => !existingCredentialIds.includes(credentialId)
      );

      createCredentialTraining({
        training_id: training.id,
        credential_ids: credentialTrainingsToAdd,
      });
      deleteCredentialTraining(credentialTrainingsToDelete);
      updateTraining({ id: training.id, ...trainingData });
    } else {
      newTraining(trainingData, {
        onSuccess: (data) => {
          createCredentialTraining({
            training_id: data.id,
            credential_ids: credentials,
          });
        },
      });
    }
    handleClose();
  };

  return (
    <FormModal
      title="New Training"
      submitButtonColorSchema="teal"
      submitButtonTitle="Done"
      size="3xl"
      isOpen={isOpen}
      submitButtonDisabled={!allFieldsFilledIn()}
      onClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack width="100%" align="start" spacing={5}>
        <Text>Add a new training. Trainings grant credentials to employees.</Text>
        <Stack direction={{ base: "column", md: "row" }} width="100%">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Training Name</FormLabel>
                <Input {...field} placeholder="Type Training Name" />
              </FormControl>
            )}
          />
          <Controller
            name="selected_credentials"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Add Granted Credential</FormLabel>
                <Select {...field} isMulti useBasicStyles options={credentialOptions || []} />
              </FormControl>
            )}
          />
        </Stack>
        <Stack direction={{ base: "column", md: "row" }} width="100%">
          <Controller
            name="has_test"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Is There a Test Included With the Training?</FormLabel>
                <RadioGroup
                  {...field}
                  colorScheme="teal"
                  display="flex"
                  gap={6}
                  onChange={(value) => {
                    field.onChange(value);
                    setHasTest(value);
                  }}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </RadioGroup>
              </FormControl>
            )}
          />
          {hasTest === "yes" && (
            <Controller
              name="cutoff_score"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Cutoff Score</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children={<Box>%</Box>} />
                    <Input {...field} type="number" placeholder="Add cutoff score" />
                  </InputGroup>
                </FormControl>
              )}
            />
          )}
        </Stack>
        <Stack direction={{ base: "column", md: "row" }} width="100%">
          <FormControl>
            <FormLabel>Does the Training Expire?</FormLabel>
            <RadioGroup
              colorScheme="teal"
              display="flex"
              gap={6}
              value={trainingExpires}
              onChange={(value) => {
                setTrainingExpires(value);
              }}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioGroup>
          </FormControl>
          {trainingExpires === "yes" && (
            <Controller
              name="expiration_months"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>
                    Validty Period{" "}
                    <Box as="span" color="gray.500">
                      (months)
                    </Box>
                  </FormLabel>
                  <Input {...field} type="number" placeholder="Add period" />
                </FormControl>
              )}
            />
          )}
        </Stack>
      </VStack>
    </FormModal>
  );
};
