import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Stack,
  Flex,
  HStack,
  useColorModeValue,
  Spacer,
  Text,
  Box,
  Divider,
} from "@chakra-ui/react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import { IoBagCheckOutline } from "react-icons/io5";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { FormValuesType } from "../CheckoutModal";

type SelectCheckoutPurposeProps = {
  control: Control<FormValuesType, any>;
  setValue: UseFormSetValue<FormValuesType>;
};

export const SelectCheckoutPurpose = ({ control, setValue }: SelectCheckoutPurposeProps) => {
  const purposeOptions: {
    label: string;
    value: "spending_authority" | "used" | "waste";
    icon: React.ReactNode;
  }[] = [
    {
      label: "Checkout to spending authority",
      value: "spending_authority",
      icon: <MdOutlineShoppingCartCheckout />,
    },
    {
      label: "Mark as used",
      value: "used",
      icon: <IoBagCheckOutline />,
    },
    { label: "Waste", value: "waste", icon: <DeleteIcon /> },
  ];

  return (
    <Controller
      name="purpose"
      control={control}
      render={({ field }) => (
        <Stack direction="column" gap={3}>
          <Text fontWeight="semibold">What would you like to do with these inventory items?</Text>
          {purposeOptions.map((option) => (
            <HStack
              _hover={{ cursor: "pointer" }}
              p={3}
              bg={
                field.value === option.value
                  ? useColorModeValue("teal.50", "teal.500")
                  : "chakra-bg-color"
              }
              spacing={6}
              border="1px"
              borderRadius="md"
              borderColor={
                field.value === option.value
                  ? useColorModeValue("teal.200", "teal.400")
                  : "chakra-border-color"
              }
              onClick={() => {
                setValue("purpose", option.value);
              }}>
              {option.icon}
              <Text fontSize="sm" fontWeight="medium">
                {option.label}
              </Text>
              <Spacer />
              {field.value === option.value ? (
                <CheckCircleIcon color={useColorModeValue("teal.500", "teal.200")} boxSize={7} />
              ) : (
                <Box border="2px" borderRadius="full" borderColor="chakra-border-color" p={3} />
              )}
            </HStack>
          ))}
        </Stack>
      )}
    />
  );
};
