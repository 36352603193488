import { Spinner, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Money } from "../../helpers/Money";
import React from "react";
import { MoneyText } from "../MoneyText";

export type StatInfoProps = {
  isLoading: boolean;
  label: string;
  statMoney: Money;
  subText: string;
};
export const StatInfo: React.FC<StatInfoProps> = ({ isLoading, label, statMoney, subText }) => (
  <Stat>
    <StatLabel>{label}</StatLabel>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
        <StatNumber>
          <MoneyText money={statMoney} formatOptions={{ compact: "never" }} />
        </StatNumber>
        <StatLabel>{subText}</StatLabel>
      </>
    )}
  </Stat>
);
