import { FormControl, FormLabel, VStack, useDisclosure } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMergeBudgetItems } from "../../../api/budget_items";
import { MinimalSpendingAuthority } from "../../../api/spending_authority";
import { SpendingAuthoritySelect } from "../../Purchasing/SpendingAuthoritySelectTree";

type FormValuesType = {
  spending_authority: MinimalSpendingAuthority | null;
};

const defaultFormValues: FormValuesType = {
  spending_authority: null,
};

export type ConsolidateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  control: any;
  setValue: any;
  isLoading: boolean;
};

export const useConsolidateModal = (
  selectedBudgetItemIds: number[],
  setSelectedBudgetItemIds: React.Dispatch<React.SetStateAction<number[]>>
): ConsolidateModalProps & { onOpen: () => void } => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setValue, control, watch } = useForm<FormValuesType>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const { mutate: reassignBudgetItemPurchasesMutation, isLoading } = useMergeBudgetItems();

  const selectedSpendingAuthority = watch("spending_authority");

  const handleConsolidationSubmit = () => {
    if (
      !selectedSpendingAuthority?.id ||
      selectedSpendingAuthority?.type !== "BudgetItem" ||
      !selectedBudgetItemIds.length
    ) {
      console.error(
        "No budget item selected for consolidation, or the current budget item ID is missing."
      );
      return;
    }
    reassignBudgetItemPurchasesMutation(
      {
        budget_item_ids: selectedBudgetItemIds,
        new_budget_item_id: selectedSpendingAuthority.id,
      },
      {
        onSuccess: () => {
          onClose();
          setSelectedBudgetItemIds([]);
        },
        onError: (error) => {
          console.error("An error occurred during the consolidation process:", error);
        },
      }
    );
  };

  return {
    isOpen,
    onClose,
    control,
    setValue,
    onOpen,
    onSubmit: handleConsolidationSubmit,
    isLoading,
  };
};

export const ConsolidateModal = ({
  isOpen,
  onClose,
  onSubmit,
  control,
  setValue,
  isLoading,
}: ConsolidateModalProps) => {
  return (
    <FormModal
      title="Consolidate Budget Item"
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={onSubmit}>
      <VStack width="100%" align="start" spacing={5}>
        <Controller
          name="spending_authority"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Spending Authority</FormLabel>
              <SpendingAuthoritySelect
                onChange={(spendingAuthority) => {
                  setValue("spending_authority", spendingAuthority);
                }}
                spendingAuthority={field.value}
              />
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};
