import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Heading,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  Icon,
  VStack,
  Badge,
} from "@chakra-ui/react";
import {
  CollectionTable,
  Column,
  ConfirmationButton,
  EmptyState,
  RecordLink,
} from "@sciencecorp/helix-components";
import { inflect } from "inflection";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useBudgetItems, useGetBudgetSummary } from "../../api/budget";
import { useGetBudgetGroup, useGetBudgetGroupSpendByModel } from "../../api/budget_groups";
import { FiArchive, FiEye, FiEyeOff } from "react-icons/fi";
import { TbLetterC, TbLetterM } from "react-icons/tb";
import {
  BudgetItemShowData,
  useArchiveBudgetItemsQuery,
  useUpdateBudgetItemQuery,
} from "../../api/budget_items";
import { EditableLink } from "../Purchasing/Purchase/components/EditableLink";
import { AddExpenditureModal } from "../Teams/Team/components/AddExpenditureModal";
import { ConsolidateModal, useConsolidateModal } from "./Modals/ConsolidateModal";
import { MoveBudgetItemModal, useMoveBudgetItemModal } from "./Modals/MoveBudgetItemModal";
import { SubTeamBudgetItems } from "./SubTeamExpandableBudget";

import { MoneyText } from "../MoneyText";
import { BudgetActivityLinearBar } from "../Finance/Budgets/components/BudgetActivityLinearBar";
import { Link } from "react-router-dom";

const hasArchivedItems = (items) => {
  return items.some((item) => item.archived_at);
};

export const BudgetItemList = ({ budgetItemsQuery, columns, showArchived }) => {
  if (budgetItemsQuery.isSuccess) {
    const filteredItems = showArchived
      ? budgetItemsQuery.data.results
      : budgetItemsQuery.data.results.filter((item) => !item.archived_at);

    if (filteredItems.length === 0) {
      return (
        <EmptyState size="sm" title={`No items added. Create a budget and add items to start`} />
      );
    }

    return <CollectionTable items={filteredItems} columns={columns} order={{ id: "asc" }} />;
  } else if (budgetItemsQuery.isLoading) {
    return <Text>Loading...</Text>;
  } else {
    return <Text>Error loading budget items</Text>;
  }
};
interface BudgetExpenseCardProps {
  budgetId: number;
  editable: boolean;
  teamIds?: number[];
  projectId?: number;
  location?: "team" | "project" | "user" | "capitalEquipment";
  userId?: number;
}
export const SpendingCard = ({ budgetId, editable, location }: BudgetExpenseCardProps) => {
  const timeFilter = "allTime";

  const endDate = DateTime.now().endOf("year");

  const budgetItemsQuery = useBudgetItems({
    budget_id: budgetId,
    end_date: endDate,
  });

  const budgetQuery = useGetBudgetSummary(budgetId, endDate);
  const { data: budgetGroup } = useGetBudgetGroup(budgetQuery?.data?.budget_group_id);
  const { data: budgetGroupSpend } = useGetBudgetGroupSpendByModel(
    budgetQuery?.data?.budget_group_id,
    endDate
  );

  const { mutate: updateBudgetItem } = useUpdateBudgetItemQuery(budgetId);
  const { mutateAsync: archiveBudgetItems } = useArchiveBudgetItemsQuery();
  const [selectedBudgetItemIds, setSelectedBudgetItemIds] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showArchived, setShowArchived] = useState(false);

  const isDisabled = !editable;

  const canAddItems =
    (location === "user" && editable) || (location !== "user" && location !== "capitalEquipment");
  const consolidateModalProps = useConsolidateModal(
    selectedBudgetItemIds,
    setSelectedBudgetItemIds
  );
  const moveModalProps = useMoveBudgetItemModal(selectedBudgetItemIds, setSelectedBudgetItemIds);

  const handleSelectItem = (itemId) => {
    setSelectedBudgetItemIds((prev) =>
      prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
    );
  };

  const columns: Column[] = [
    {
      label: "",
      weight: 0.25,
      render: (budgetItem: BudgetItemShowData) =>
        !budgetItem.archived_at && editable ? (
          <Checkbox
            isDisabled={isDisabled}
            colorScheme="teal"
            isChecked={selectedBudgetItemIds.includes(budgetItem.id)}
            onChange={() => handleSelectItem(budgetItem.id)}
          />
        ) : null,
    },
    {
      label: "Item",
      weight: 2,
      render: (budgetItem: BudgetItemShowData) => {
        return (
          <VStack align="start">
            <RecordLink
              link={`/budgets/${budgetItem.budget_id}/budget_item/${budgetItem.id}`}
              type=""
              identifier={budgetItem.item_name}
            />
            {budgetItem.archived_at && <Tag colorScheme="red">Archived</Tag>}
          </VStack>
        );
      },
    },

    {
      label: "Spent",
      render: (budgetItem: BudgetItemShowData) => (
        <MoneyText
          fontWeight="bold"
          money={budgetItem.summary?.item_spent_amount}
          formatOptions={{ compact: "never" }}
        />
      ),
    },
    {
      label: "Committed",
      render: (budgetItem: BudgetItemShowData) => {
        const fontColor =
          (budgetItem.summary?.item_committed_amount?.cents.toNumber() || 0) > 0
            ? "yellow.500"
            : "auto";
        return (
          <MoneyText
            color={fontColor}
            money={budgetItem.summary?.item_committed_amount}
            formatOptions={{ compact: "never" }}
          />
        );
      },
    },
    {
      label: "Pending Approval",
      render: (budgetItem: BudgetItemShowData) => {
        const fontColor =
          (budgetItem.summary?.pending_approval_amount?.cents.toNumber() || 0) > 0
            ? "red.500"
            : "auto";
        return (
          <HStack>
            <MoneyText
              color={fontColor}
              money={budgetItem.summary?.pending_approval_amount}
              formatOptions={{ compact: "never" }}
            />
            {(budgetItem.summary?.pending_approval_records_count || 0) > 0 && (
              <Badge colorScheme="red">
                {budgetItem.summary?.pending_approval_records_count || 0}
              </Badge>
            )}
          </HStack>
        );
      },
    },
    {
      label: "Link",
      weight: 0.5,
      render: (budgetItem: BudgetItemShowData) => (
        <EditableLink
          iconSize={4}
          url={budgetItem.supporting_evidence}
          onSubmit={(value) => {
            if (value) updateBudgetItem({ id: budgetItem.id, supporting_evidence: value });
          }}
        />
      ),
    },
  ];

  return (
    <Box>
      <VStack
        spacing={4}
        w={"100%"}
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        p={6}
        borderRadius="md">
        <Stack w={"100%"} direction="row" justifyContent={"space-between"}>
          <Heading size="md">Spending</Heading>
          <HStack>
            {canAddItems && (
              <Button
                colorScheme={"teal"}
                leftIcon={<AddIcon />}
                isDisabled={!budgetQuery.isSuccess || !editable}
                onClick={onOpen}>
                Add Item
              </Button>
            )}
          </HStack>
        </Stack>
        {budgetGroup && (
          <Box
            w={"100%"}
            alignItems={"center"}
            borderRadius={"md"}
            borderWidth={"1px"}
            justifyContent={"space-between"}>
            {editable ? (
              <Link to={`/finance/budgets/budget_group/${budgetGroup.id}`}>
                <HStack
                  bgColor={useColorModeValue("gray.50", "gray.700")}
                  p={4}
                  justifyContent="space-between">
                  <Heading size="sm">{budgetGroup.name} Budget</Heading>
                  <ChevronRightIcon />
                </HStack>
              </Link>
            ) : (
              <HStack
                bgColor={useColorModeValue("gray.50", "gray.700")}
                p={4}
                justifyContent="space-between">
                <Heading size="sm">{budgetGroup.name} Budget</Heading>
              </HStack>
            )}
            <Box p={4}>
              <BudgetActivityLinearBar
                summary={budgetGroup?.summary}
                spendByModel={budgetGroupSpend}
              />
            </Box>
          </Box>
        )}

        {editable && (
          <Stack
            w={"100%"}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
            p={4}
            spacing={4}
            borderRadius={"md"}
            borderWidth={"1px"}
            justifyContent={"space-between"}>
            <Stack
              w={"100%"}
              direction={{ base: "column", md: "row" }}
              alignItems={"center"}
              p={2}
              spacing={4}
              borderRightWidth={["0", "1px"]}
              borderBottomWidth={["1px", "0"]}>
              <Box>
                {selectedBudgetItemIds.length > 0 ? (
                  <Text fontWeight="semibold">
                    {selectedBudgetItemIds.length} {inflect("Item", selectedBudgetItemIds.length)}{" "}
                    Selected
                  </Text>
                ) : (
                  <Text color={useColorModeValue("gray.500", "gray.200")}>
                    Select a Budget Item
                  </Text>
                )}
              </Box>
              {location !== "user" && location !== "capitalEquipment" && (
                <>
                  <Tooltip
                    label={!selectedBudgetItemIds.length ? "Select items to consolidate" : ""}>
                    <Button
                      leftIcon={<TbLetterC />}
                      variant="outline"
                      colorScheme={selectedBudgetItemIds.length ? "orange" : "gray"}
                      color={selectedBudgetItemIds.length ? "orange.500" : "gray.500"}
                      size="sm"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      onClick={consolidateModalProps.onOpen}>
                      Consolidate
                    </Button>
                  </Tooltip>
                  <Tooltip label={!selectedBudgetItemIds.length ? "Select items to move" : ""}>
                    <Button
                      leftIcon={<TbLetterM />}
                      isLoading={moveModalProps.isLoading}
                      variant={"outline"}
                      colorScheme={selectedBudgetItemIds.length ? "teal" : "gray"}
                      color={selectedBudgetItemIds.length ? "teal.500" : "gray.500"}
                      size="sm"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      onClick={moveModalProps.onOpen}>
                      Move
                    </Button>
                  </Tooltip>
                </>
              )}
              {location !== "capitalEquipment" && (
                <Tooltip label={!selectedBudgetItemIds.length ? "Select items to archive" : ""}>
                  <Box display="inline-block">
                    <ConfirmationButton
                      aria-label="archive-budgetItem-record"
                      leftIcon={<FiArchive />}
                      label="Archive"
                      confirmationButtonLabel="Archive"
                      isDisabled={selectedBudgetItemIds.length === 0}
                      children="Are you sure you want to archive this budget item? You will not be able to undo this action."
                      onConfirm={() => {
                        archiveBudgetItems({
                          budget_item_ids: selectedBudgetItemIds,
                          archived_at: DateTime.now().toISO(),
                        })
                          .then(() => setSelectedBudgetItemIds([]))
                          .catch((e) => console.error(e));
                      }}
                      variant="Button"
                      buttonVariant="outline"
                      colorScheme={selectedBudgetItemIds.length ? "red" : "gray"}
                      color={selectedBudgetItemIds.length ? "red.500" : "gray.500"}
                      size="sm"
                    />
                  </Box>
                </Tooltip>
              )}
            </Stack>
            <Button
              leftIcon={<Icon as={showArchived ? FiEyeOff : FiEye} />}
              variant="ghost"
              size="sm"
              colorScheme="gray"
              isDisabled={!hasArchivedItems(budgetItemsQuery.data?.results || [])}
              onClick={() => setShowArchived(!showArchived)}>
              {showArchived ? "Hide archived" : "Show archived"}
            </Button>
          </Stack>
        )}

        <BudgetItemList
          budgetItemsQuery={budgetItemsQuery}
          columns={columns}
          showArchived={showArchived}
        />
        {editable && budgetQuery?.data?.sub_team_budgets?.length ? (
          <Flex
            direction="column"
            w="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            border="1px"
            p={2}
            borderColor="chakra-border-color"
            borderRadius="md">
            {budgetQuery.data?.sub_team_budgets?.map((budgetWithSummary) => (
              <SubTeamBudgetItems
                key={budgetWithSummary.budget.id}
                selectedBudgetItemIds={selectedBudgetItemIds}
                handleSelectItem={handleSelectItem}
                budgetId={budgetWithSummary.budget.id}
                parentTeamBudget={budgetQuery.data?.id}
                editable={editable}
                timeFilter={timeFilter}
              />
            ))}
          </Flex>
        ) : null}
      </VStack>

      <AddExpenditureModal isOpen={isOpen} onClose={onClose} budgetId={budgetId} />
      <ConsolidateModal
        isOpen={consolidateModalProps.isOpen}
        onClose={consolidateModalProps.onClose}
        onSubmit={consolidateModalProps.onSubmit}
        control={consolidateModalProps.control}
        setValue={consolidateModalProps.setValue}
        isLoading={consolidateModalProps.isLoading}
      />

      <MoveBudgetItemModal
        isOpen={moveModalProps.isOpen}
        onClose={moveModalProps.onClose}
        onSubmit={moveModalProps.onSubmit}
        control={moveModalProps.control}
        setValue={moveModalProps.setValue}
        isLoading={moveModalProps.isLoading}
      />
    </Box>
  );
};
