import React from "react";
import { TeamShowData, useDeleteTeam } from "../../../../api/team";
import { Heading, VStack } from "@chakra-ui/react";
import { NewTeamModal } from "../../newTeam";
import { TeamCard } from "../../teamCard";

type Props = {
  team: TeamShowData;
  editable?: boolean;
};

export const SubTeamsList: React.FC<Props> = ({ team, editable }) => {
  return (
    <VStack width="100%" align="start" spacing={4}>
      <Heading size="md" mb={4} fontWeight="semibold">
        Sub Teams
      </Heading>
      {team.sub_teams?.map((subTeam) => (
        <TeamCard key={subTeam.id} team={subTeam} editable={!!editable} location="subteams" />
      ))}
      {editable && !team.is_archived && <NewTeamModal parentTeam={team} location="subteam" />}
    </VStack>
  );
};
