import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  useColorModeValue,
  HStack,
  VStack,
  Box,
  Text,
  Card,
  CardBody,
  Icon,
} from "@chakra-ui/react";
import React from "react";

export const QuickLinkCard = ({ icon, iconbg, title, description, onClick }) => {
  return (
    <Card
      w="100%"
      variant="outline"
      onClick={onClick}
      _hover={{
        bg: useColorModeValue("gray.100", "gray.700"),
        cursor: "pointer",
      }}>
      <CardBody>
        <HStack justifyContent={"space-between"}>
          <HStack spacing={6}>
            <Box bg={iconbg} borderRadius="full" color="white" p={4}>
              {icon}
            </Box>
            <VStack align="start" gap={0}>
              <Text fontWeight="bold">{title}</Text>
              <Text fontSize="sm" textAlign={"start"}>
                {description}
              </Text>
            </VStack>
          </HStack>
          <Icon as={ChevronRightIcon} />
        </HStack>
      </CardBody>
    </Card>
  );
};
