import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from "..";
import { dateTimeSchema } from "../../helpers/dateTime";
import { ARC_QUERY_KEY, ARCS_AND_PROJECTS_QUERY_KEY } from "./arcs";
import {
  BUDGET_ITEMS_BASE_URL,
  BudgetItemShowData,
  budgetItemShowSchema,
  budgetItemSummarySchema,
  budgetItemWithSummarySchema,
} from "../budget_items";
import { SearchParams, indexParams, searchParams } from "../collection_types";
import { createSearchResponseSchema } from "../shared";
import { userMinimalSchema } from "../user";
import { DateTime } from "luxon";
import { zodParse } from "../zodParse";
import { Money, moneySchema } from "../../helpers/Money";

export const PROJECT_BASE_URL = `/projects`;
export const PROJECT_QUERY_KEY = ["projects"];

export const projectBaseSchema = z.object({
  id: z.number(),
  budget_id: z.number(),
  arc_id: z.number().nullable(),
  title: z.string(),
  description: z.record(z.unknown()).nullable(),
  start_date: dateTimeSchema.nullable(),
  relative_min_end_date_months: z.number(),
  relative_expected_end_date_months: z.number(),
  relative_max_end_date_months: z.number(),
  index: z.number().optional().nullable(),
  status: z.string(),
  autonomous_spending: z.boolean(),
  created_at: dateTimeSchema,
  updated_at: dateTimeSchema,
  is_public: z.boolean(),
  invalid_dependency_ids: z.array(z.number()).nullable().optional(),
  archived_at: dateTimeSchema.nullable(),
});

export const projectIndexSchema = projectBaseSchema
  .pick({
    id: true,
    title: true,
    status: true,
    arc_id: true,
  })
  .extend({
    budget_id: z.number(),
    users: z.array(z.lazy(() => userMinimalSchema)),
    hired_count: z.number(),
    total_people: z.number(),
  });

export const projectSchema = projectBaseSchema.extend({
  budget_id: z.number(),
  can_act_as_lead: z.boolean(),
  users: z.array(z.lazy(() => userMinimalSchema)),
});

export const projectSummarySchema = z.object({
  project_name: z.string(),
  project_id: z.number(),
  summary: budgetItemSummarySchema,
  budget_items: z.array(budgetItemWithSummarySchema),
  project_members: z.number().nullable(),
});

export const budgetItemsForProjectsSchema = z.object({
  spent_amount: moneySchema,
});

export const projectSummaries = z.record(z.string(), budgetItemsForProjectsSchema);

export type ProjectSummaries = z.infer<typeof projectSummaries>;
export type ProjectBase = z.infer<typeof projectBaseSchema>;
export type ProjectIndex = z.infer<typeof projectIndexSchema>;
export type Project = z.infer<typeof projectSchema>;
export type ProjectSummarySchemaData = z.infer<typeof projectSummarySchema>;

function invalidateCache() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: ARC_QUERY_KEY,
    });
    queryClient.invalidateQueries({
      queryKey: PROJECT_QUERY_KEY,
    });
    queryClient.invalidateQueries({
      queryKey: ARCS_AND_PROJECTS_QUERY_KEY,
    });
  };
}

type UpdateProject = Partial<ProjectBase> & {
  id: number;
};
type CreateProject = Omit<
  ProjectBase,
  | "id"
  | "created_at"
  | "updated_at"
  | "description"
  | "status"
  | "budget_id"
  | "autonomous_spending"
  | "archived_at"
>;

export const getProjects = async (): Promise<Project[]> => {
  const response = await api.get(PROJECT_BASE_URL);
  return zodParse(z.array(projectSchema), response.data);
};
export const useGetProjects = () => {
  return useQuery({
    queryKey: PROJECT_QUERY_KEY,
    queryFn: getProjects,
  });
};

export const getProject = async (project_id: number): Promise<Project> => {
  const response = await api.get(`${PROJECT_BASE_URL}/${project_id}`);
  return zodParse(projectSchema, response.data);
};

export const getProjectCSV = async () => {
  const result = await api.get(`${PROJECT_BASE_URL}/csv`, {
    responseType: "blob",
  });

  return result.data;
};

export const useGetProject = (project_id: number | null | undefined) => {
  return useQuery({
    queryKey: [PROJECT_QUERY_KEY, project_id],
    queryFn: () => getProject(project_id as number),
    enabled: project_id !== null && project_id !== undefined,
  });
};

const projectSearchResponseSchema = createSearchResponseSchema(projectIndexSchema);
type ProjectSearchResponse = z.infer<typeof projectSearchResponseSchema>;
type ProjectSearchParams = SearchParams & { endDate: DateTime };

export const searchProjects = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
  endDate,
}: ProjectSearchParams) => {
  const path = [PROJECT_BASE_URL, "search"].join("/");
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const response = await api.post(path, {
    ...index,
    ...search,
    end_date: endDate,
  });
  return zodParse(createSearchResponseSchema(projectIndexSchema), response.data);
};

export const useSearchProjects = (searchParams: ProjectSearchParams) => {
  return useQuery({
    queryKey: [PROJECT_QUERY_KEY, "search", searchParams],
    queryFn: () => searchProjects(searchParams),
  });
};

export const useGetProjectSummaries = () => {
  return useQuery({
    queryKey: [PROJECT_QUERY_KEY, "summaries"],
    queryFn: () => getProjectSummaries(),
  });
};

export const getProjectSummaries = async (): Promise<ProjectSummaries> => {
  const response = await api.post(`${PROJECT_BASE_URL}/summaries`);
  return zodParse(projectSummaries, response.data);
};

const createProject = async (project: CreateProject): Promise<Project> => {
  const response = await api.post(PROJECT_BASE_URL, project);
  return zodParse(projectSchema, response.data);
};
export const useCreateProject = () => {
  return useMutation({
    mutationFn: createProject,
    onSuccess: invalidateCache(),
  });
};

const updateProject = async (project: UpdateProject): Promise<Project> => {
  const response = await api.put(`${PROJECT_BASE_URL}/${project.id}`, project);
  return zodParse(projectSchema, response.data);
};
export const useUpdateProject = () => {
  return useMutation({
    mutationFn: updateProject,
    onSuccess: invalidateCache(),
  });
};

const removeProject = async (id: number): Promise<number> => {
  await api.delete(`${PROJECT_BASE_URL}/${id}`);
  return id;
};
export const useRemoveProject = () => {
  return useMutation({
    mutationFn: removeProject,
    onSuccess: invalidateCache(),
  });
};

export const getProjectBudgetItems = async (projectId: number): Promise<BudgetItemShowData[]> => {
  const response = await api.get(`${PROJECT_BASE_URL}/${projectId}/budget_items`);
  return zodParse(z.array(budgetItemShowSchema), response.data);
};

export const useGetProjectBudgetItems = (projectId: number) => {
  return useQuery({
    queryKey: [BUDGET_ITEMS_BASE_URL, "PROJECT", projectId],
    queryFn: () => getProjectBudgetItems(projectId),
    enabled: projectId !== 0,
  });
};

export const projectComparator = (a: ProjectIndex, b: ProjectIndex): number => {
  return a.title.localeCompare(b.title);
};
