import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useReducer } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import useWebSocket from "react-use-websocket";
import { getChatThread, newChatThread, useChatThreadsQuery } from "../../api/chats";
import { ConnectionStatus } from "./components";
import { ChatInput } from "./input";
import { ChatMessages } from "./messages";
import { chatReducer } from "./state";
import { ChatThreadSelector } from "./threadSelector";
import { WebsocketMessage } from "./types";
import { Link } from "@sciencecorp/helix-components";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const ChatDrawer = (props: { socketUrl: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useHotkeys("`", (e) => {
    e.preventDefault();
    onOpen();
  });

  const chatThreads = useChatThreadsQuery();

  const [state, dispatch] = useReducer(chatReducer, {
    chatObjects: [],
    streamingTextContent: "",
    thinking: false,
  });

  const { sendMessage, lastMessage, readyState } = useWebSocket(props.socketUrl, {
    shouldReconnect: (_closeEvent) => true,
    retryOnError: true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      dispatch(JSON.parse(lastMessage.data) as WebsocketMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    getChatThread(state.threadId).then((thread) => {
      if (thread) {
        dispatch({ type: "set-chat-messages", messages: thread.messages });
      }
    });
  }, [state.threadId]);

  const makeNewThread = async () => {
    const newThreadId = await newChatThread("chimaera-0.2.0");
    await chatThreads.refetch();
    dispatch({ type: "set-thread-id", threadId: newThreadId });
  };

  const handleUserMessage = async (input: string) => {
    if (!state.threadId) {
      await makeNewThread();
    }
    const chatInput = input.trim();
    sendMessage(JSON.stringify({ role: "user", content: chatInput }));
    dispatch({
      type: "user-message",
      object: { content: chatInput, sender: "user", type: "text" },
    });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" size={"xl"} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <HStack justify="space-between">
            <HStack>
              <ChatThreadSelector
                chatThreads={chatThreads.data}
                selectedThreadId={state.threadId}
                setThreadId={(threadId?: number) => dispatch({ type: "set-thread-id", threadId })}
                onNewThread={makeNewThread}
              />
              {state.threadId && (
                <Link href={`/chat/threads/${state.threadId}`}>
                  <ExternalLinkIcon my={2} mx={2} />
                </Link>
              )}
            </HStack>
            <Spacer />
            <ConnectionStatus readyState={readyState} />
          </HStack>
        </DrawerHeader>

        <DrawerBody>
          <ChatMessages chatState={state} />
        </DrawerBody>

        <DrawerFooter>
          <ChatInput sendUserInput={handleUserMessage} thinking={state.thinking} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
