import { useEffect, useState } from "react";
import { useCurrentUserQuery } from "../../api/user";
import forge from "node-forge";
import { useSecureMode, useSecureModeModal } from "@sciencecorp/helix-components";
import { z } from "zod";
import { KeyringZodHelper, KRZod } from "../../helpers/KeyringZodHelper";

export function useSecureModeModalWithUser(message: string) {
  const currentUserQuery = useCurrentUserQuery();
  return useSecureModeModal(message, currentUserQuery.data?.encrypted_private_key);
}
type OnSecureModeResult = "noRetry" | void;

export function useSecureModeEffect(
  fn: (privateKey: forge.pki.rsa.PrivateKey) => OnSecureModeResult,
  deps: React.DependencyList = []
) {
  const [smState, _smDispatch] = useSecureMode();
  const [ran, setRan] = useState(false);
  useEffect(() => {
    if (smState.privateKey && !ran) {
      const result = fn(smState.privateKey);
      if (result === "noRetry") {
        setRan(true);
      }
    }
  }, [smState.privateKey, ...deps]);
}

interface SuccessfulDecryptionResult<Schema extends KRZod> {
  isSuccess: true;
  isPending: false;
  isError: false;
  nothingToDecrypt: false;
  data: z.infer<Schema>;
  errorMessage: null;
}
function success<Schema extends KRZod>(data: z.infer<Schema>): SuccessfulDecryptionResult<Schema> {
  return {
    isSuccess: true,
    isPending: false,
    isError: false,
    nothingToDecrypt: false,
    data: data,
    errorMessage: null,
  };
}

interface NothingToDecryptResult {
  isSuccess: false;
  isPending: false;
  isError: false;
  nothingToDecrypt: true;
  data: null;
  errorMessage: null;
}
function nothingToDecrypt(): NothingToDecryptResult {
  return {
    isSuccess: false,
    isPending: false,
    isError: false,
    nothingToDecrypt: true,
    data: null,
    errorMessage: null,
  };
}

interface PendingDecryptionResult {
  isSuccess: false;
  isPending: true;
  isError: false;
  nothingToDecrypt: false;
  data: null;
  errorMessage: null;
}
function pending(): PendingDecryptionResult {
  return {
    isSuccess: false,
    isPending: true,
    isError: false,
    nothingToDecrypt: false,
    data: null,
    errorMessage: null,
  };
}

interface FailedDecryptionResult {
  isSuccess: false;
  isPending: false;
  isError: true;
  nothingToDecrypt: false;
  data: null;
  errorMessage: string;
}
function fail(errorMessage: string): FailedDecryptionResult {
  return {
    isSuccess: false,
    isPending: false,
    isError: true,
    nothingToDecrypt: false,
    data: null,
    errorMessage: errorMessage,
  };
}

type DecryptionResult<Schema extends KRZod> =
  | SuccessfulDecryptionResult<Schema>
  | FailedDecryptionResult
  | PendingDecryptionResult
  | NothingToDecryptResult;

export function useDecryptOnSecureMode<Schema extends KRZod>(
  schema: Schema,
  encryptedData: string | null | undefined,
  deps: React.DependencyList = []
): DecryptionResult<Schema> {
  const [smState, smDispatch] = useSecureMode();
  const keyringHelper = new KeyringZodHelper<Schema>(schema);
  const [result, setResult] = useState<DecryptionResult<Schema>>(pending());
  useSecureModeEffect(
    (privateKey) => {
      if (smState.privateKey) {
        if (encryptedData === null || encryptedData === "") {
          setResult(nothingToDecrypt());
        } else if (encryptedData) {
          try {
            const decryptedData = keyringHelper.decrypt(encryptedData, privateKey, smDispatch);
            setResult(success(decryptedData));
          } catch (e) {
            console.error("Error decrypting data", e);
            if (e instanceof Error) {
              setResult(fail(e.message));
            } else {
              setResult(fail("Unknown error during decryption"));
            }
          }
        }
      }
    },
    [encryptedData, ...deps]
  );
  return result;
}
