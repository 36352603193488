import React, { useEffect, useMemo, useState } from "react";
import {
  SplitPage,
  Facets,
  Header,
  useCollection,
  buildFacets,
} from "@sciencecorp/helix-components";
import { Box, Button, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import { IoMedalOutline } from "react-icons/io5";
import { AddIcon, SettingsIcon } from "@chakra-ui/icons";
import { TbPresentationAnalytics } from "react-icons/tb";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useSearchUserCredentials } from "../../api/user_credentials";
import { useSearchCredentials } from "../../api/credentials";
import { useSearchTrainings } from "../../api/trainings";
import { Trainings } from "./Trainings";
import { AllCredentials } from "./components/AllCredentials";
import { NewTrainingModal } from "./Trainings/components/NewTrainingModal";
import { NewCredentialModal } from "./components/NewCredentialModal";
import { MyCredentialsPage } from "./components/MyCredentialsPage";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { credentialBodyOptions } from "./util";
import { useNavigate, useParams } from "react-router-dom";

export const Credentials = () => {
  const { tab } = useParams();
  if (!tab) return null;
  const [activeTab, setActiveTab] = useState(tab);

  const { facets, onFacets, filters, pagination, onPagination, resetPagination, resetFacets } =
    useCollection({ pagination: { per_page: 100 } });

  useEffect(() => {
    setActiveTab(tab);
    resetFacets();
    resetPagination();
  }, [tab]);

  const { search, debouncedSearch } = useDebouncedSearch();

  const currentUserQuery = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUserQuery, "credentials_admin") || false;

  const AGGREGATIONS_QUERY = ["is_public"];

  const bodyOptions =
    currentUserQuery && currentUserQuery.data
      ? activeTab === "me"
        ? {
            expiration_type: {
              filters: {
                filters: {
                  "Expiration Date": {
                    bool: {
                      must: [
                        { exists: { field: "expiration_months" } },
                        { term: { user_id: currentUserQuery.data.id } },
                      ],
                      must_not: [{ exists: { field: "currency_days" } }],
                    },
                  },
                  Currency: {
                    bool: {
                      must: [
                        { exists: { field: "currency_days" } },
                        { term: { user_id: currentUserQuery.data.id } },
                      ],
                      must_not: [{ exists: { field: "expiration_months" } }],
                    },
                  },
                  Both: {
                    bool: {
                      must: [
                        { exists: { field: "expiration_months" } },
                        { exists: { field: "currency_days" } },
                        { term: { user_id: currentUserQuery.data.id } },
                      ],
                    },
                  },
                },
              },
            },
          }
        : credentialBodyOptions
      : {};

  const { data, isLoading } =
    activeTab === "me"
      ? useSearchUserCredentials({
          term: search || "*",
          aggs: AGGREGATIONS_QUERY,
          bodyOptions,
          filters,
        })
      : activeTab === "all"
      ? useSearchCredentials({
          term: search || "*",
          bodyOptions,
          aggs: AGGREGATIONS_QUERY,
          pagination,
          filters,
        })
      : useSearchTrainings({
          term: search || "*",
          pagination,
          filters,
        });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const {
    isOpen: isOpenNewTraining,
    onOpen: onOpenNewTraining,
    onClose: onCloseNewTraining,
  } = useDisclosure();

  const {
    isOpen: isOpenNewCredential,
    onOpen: onOpenNewCredential,
    onClose: onCloseNewCredential,
  } = useDisclosure();

  const navigate = useNavigate();

  const actions =
    isCredentialsAdmin && activeTab !== "me"
      ? [
          <Button leftIcon={<AddIcon />} onClick={() => onOpenNewCredential()}>
            New Credential
          </Button>,
          <Button leftIcon={<AddIcon />} onClick={() => onOpenNewTraining()}>
            New Training
          </Button>,
        ]
      : [];

  const TABS = useMemo(
    () =>
      [
        { label: "Your Credentials", value: "me", icon: <IoMedalOutline /> },
        isCredentialsAdmin && {
          label: "All Credentials",
          value: "all",
          icon: <SettingsIcon />,
        },
        { label: "Trainings", value: "trainings", icon: <TbPresentationAnalytics /> },
      ].filter((x) => x) as { label: string; value: string; icon: JSX.Element }[],
    [currentUserQuery?.data, isCredentialsAdmin]
  );

  return (
    <Flex direction="column">
      <Header title="Credentials & Trainings" actions={actions} />
      <Box overflow={"auto"} pt={4}>
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          breakpoint="md"
          sidebar={
            <VStack width="100%" spacing={3}>
              <VStack width="100%">
                {TABS.length &&
                  TABS.map((tab) => (
                    <Button
                      key={tab.label}
                      leftIcon={tab.icon}
                      width="100%"
                      justifyContent="start"
                      colorScheme={activeTab === tab.value ? "teal" : "gray"}
                      bg={activeTab === tab.value ? "teal.500" : "transparent"}
                      onClick={() => {
                        navigate(`/credentials/${tab.value}`);
                      }}>
                      {tab.label}
                    </Button>
                  ))}
              </VStack>

              <Box width="full" display={["none", "none", "inline"]} alignSelf="start">
                <Facets
                  facets={populatedFacets}
                  onChange={onFacets}
                  debouncedSearch={debouncedSearch}
                  search
                  background
                />
              </Box>

              <Box display={{ base: "flex", md: "none" }} alignSelf="end">
                <Facets
                  variant="button"
                  facets={populatedFacets}
                  onChange={onFacets}
                  debouncedSearch={debouncedSearch}
                  search
                  background
                />
              </Box>
            </VStack>
          }
          main={
            <Box>
              {activeTab === "me" && <MyCredentialsPage data={data} />}
              {activeTab === "trainings" && (
                <Trainings
                  data={data}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  pagination={pagination}
                  isCredentialsAdmin={isCredentialsAdmin}
                />
              )}
              {activeTab === "all" && (
                <AllCredentials
                  data={data}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  pagination={pagination}
                />
              )}
            </Box>
          }
        />
      </Box>
      {isCredentialsAdmin && (
        <>
          <NewTrainingModal isOpen={isOpenNewTraining} onClose={onCloseNewTraining} />
          <NewCredentialModal isOpen={isOpenNewCredential} onClose={onCloseNewCredential} />
        </>
      )}
    </Flex>
  );
};
