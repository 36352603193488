import { z } from "zod";
import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { invalidateVendors } from "./vendor";

export const VENDOR_CONTACTS_BASE_URL = "vendor_contacts";

export const vendorContactSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  phone_number: z.string(),
  vendor_id: z.number(),
  additional_information: z.string().nullable(),
  remittance_address: z.string().nullable(),
});

export const vendorContactShowSchema = vendorContactSchema.omit({ vendor_id: true });

export const vendorContactIndexSchema = z.object({
  results: z.array(vendorContactShowSchema),
  pagination: paginationSchema,
});

export type VendorContactData = z.infer<typeof vendorContactSchema>;
export type VendorContactShowData = z.infer<typeof vendorContactShowSchema>;
export type VendorContactCreateParams = Pick<
  VendorContactData,
  "vendor_id" | "name" | "email" | "phone_number" | "additional_information" | "remittance_address"
>;

// api queries

export const newVendorContact = async (vendor_contact: VendorContactCreateParams) => {
  const result = await api.post(VENDOR_CONTACTS_BASE_URL, { vendor_contact });
  return result.data;
};

export const updateVendorContact = async (vendor_contact: Partial<VendorContactData>) => {
  const result = await api.put(`${VENDOR_CONTACTS_BASE_URL}/${vendor_contact.id}`, {
    vendor_contact,
  });
  return result.data;
};

export const deleteVendorContact = async (id: number) => {
  await api.delete(`${VENDOR_CONTACTS_BASE_URL}/${id}`);
};

// query hooks

export const useNewVendorContact = (vendorId: number) => {
  return useMutation({
    mutationFn: newVendorContact,
    onSuccess: invalidateVendors(vendorId, "contacts"),
  });
};

export const useUpdateVendorContact = (vendorId: number) => {
  return useMutation({
    mutationFn: updateVendorContact,
    onSuccess: invalidateVendors(vendorId, "contacts"),
  });
};

export const useDeleteVendorContact = (vendorId: number) => {
  return useMutation({
    mutationFn: deleteVendorContact,
    onSuccess: invalidateVendors(vendorId, "contacts"),
  });
};
