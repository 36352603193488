import React from "react";
import { useUserQuery } from "../../../api/user";

import { Avatar, HStack, Heading } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const UserCard = ({ userId }) => {
  const { data: user } = useUserQuery(userId);

  return (
    <HStack>
      <Avatar size="xs" src={user?.picture_uri} />
      <Heading size="xs">{user?.name}</Heading>
    </HStack>
  );
};

export const StaleUserCard = ({ userId }) => {
  const { data: user } = useUserQuery(userId);

  return (
    <HStack
      bg="red.50"
      p={1}
      border="1px"
      borderColor="red.200"
      borderRadius="md"
      width="max-content">
      <WarningTwoIcon color="red.400" />
      <Heading size="xs">{user?.name}</Heading>
    </HStack>
  );
};
