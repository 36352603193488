import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from "..";
import { Project, getProjects, PROJECT_QUERY_KEY } from "./projects";
import { zodParse } from "../zodParse";

export const ARC_BASE_URL = `/arcs`;
export const ARC_QUERY_KEY = ["arcs"];
export const ARCS_AND_PROJECTS_QUERY_KEY = ["arcsAndProjects"];

export const arcSchema = z.object({
  id: z.number(),
  title: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  index: z.number().optional().nullable(),
  project_ids: z.array(z.number()),
  parent_arc_id: z.number().optional().nullable(),
  child_arc_ids: z.array(z.number()),
});
export type Arc = z.infer<typeof arcSchema>;
export type CreateArc = Omit<
  Arc,
  "id" | "created_at" | "updated_at" | "child_arc_ids" | "project_ids"
>;
export type UpdateArc = Omit<Arc, "created_at" | "updated_at" | "child_arc_ids" | "project_ids">;

export type NewlyCreatedArc = CreateArc & { id?: number };

export function invalidateArcsAndProjectsCache() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({
      queryKey: ARC_QUERY_KEY,
    });
    queryClient.invalidateQueries({
      queryKey: PROJECT_QUERY_KEY,
    });
    queryClient.invalidateQueries({
      queryKey: ARCS_AND_PROJECTS_QUERY_KEY,
    });
  };
}

const getArcs = async (): Promise<Arc[]> => {
  const response = await api.get(ARC_BASE_URL);
  return zodParse(z.array(arcSchema), response.data);
};
export const useGetArcs = () => {
  return useQuery({
    queryKey: ARC_QUERY_KEY,
    queryFn: getArcs,
  });
};

interface ArcsAndProjects {
  arcs: Arc[];
  projects: Project[];
}
const getArcsAndProjects = async (): Promise<ArcsAndProjects> => {
  const [arcs, projects] = await Promise.all([getArcs(), getProjects()]);
  return { arcs, projects };
};
export const useGetArcsAndProjects = () => {
  return useQuery({
    queryKey: ARCS_AND_PROJECTS_QUERY_KEY,
    queryFn: getArcsAndProjects,
  });
};

const createArc = async (arc: CreateArc): Promise<Arc> => {
  const response = await api.post(ARC_BASE_URL, arc);
  return zodParse(arcSchema, response.data);
};
export const useCreateArc = () => {
  return useMutation({
    mutationFn: createArc,
    onSuccess: invalidateArcsAndProjectsCache(),
  });
};

const updateArc = async (arc: UpdateArc): Promise<Arc> => {
  const response = await api.put(`${ARC_BASE_URL}/${arc.id}`, arc);
  return zodParse(arcSchema, response.data);
};
export const useUpdateArc = () => {
  return useMutation({
    mutationFn: updateArc,
    onSuccess: invalidateArcsAndProjectsCache(),
  });
};

const removeArc = async (arc: Arc): Promise<void> => {
  await api.delete(`${ARC_BASE_URL}/${arc.id}`);
};
export const useRemoveArc = () => {
  return useMutation({
    mutationFn: removeArc,
    onSuccess: invalidateArcsAndProjectsCache(),
  });
};
