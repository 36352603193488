import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import MultiProgress from "react-multi-progress";
import { useBudgetItemSummary } from "../../api/budget_items";
import { MinimalSpendingAuthority, useGetSpendingAuthority } from "../../api/spending_authority";
import { Money } from "../../helpers/Money";
import Big from "big.js";
import { MoneyText } from "../MoneyText";
import { useCurrency } from "../../contexts/CurrencyContext";

export const BudgetItemBody = (props: {
  spendingAuthority: MinimalSpendingAuthority;
  pendingAmount: Money;
  approvalFlowBox?: boolean;
  session?: number;
}) => {
  const currency = useCurrency();
  const { spendingAuthority, pendingAmount, approvalFlowBox, session } = props;
  const spendingAuthorityQuery = useGetSpendingAuthority(
    spendingAuthority?.id,
    spendingAuthority?.type
  );

  const rootSpendingAuthority = spendingAuthorityQuery.data?.rootSpendingAuthority;
  const biSummaryQuery = useBudgetItemSummary(
    rootSpendingAuthority?.id,
    DateTime.now().endOf("year"),
    currency
  );

  useEffect(() => {
    if (biSummaryQuery.isFetched && rootSpendingAuthority?.id) {
      biSummaryQuery.refetch();
    }
  }, [session]);

  const theme = useTheme();
  const gray = useColorModeValue(theme.colors.gray[100], theme.colors.gray[400]);
  const orange = useColorModeValue(theme.colors.orange[200], theme.colors.orange[500]);
  const teal = useColorModeValue(theme.colors.teal[500], theme.colors.teal[300]);
  const red = useColorModeValue(theme.colors.red[200], theme.colors.red[500]);

  if (!rootSpendingAuthority) {
    return <Tag colorScheme="red">Not Budgeted</Tag>;
  }

  const summary = biSummaryQuery.data;
  const totalSpent = summary?.spent_amount || Money.zero(currency);
  const totalBudget = summary?.funding_amount || Money.zero(currency);
  const totalCommitted = summary?.committed_amount || Money.zero(currency);
  const totalRequested = pendingAmount;
  const totalAvailable = (summary?.remaining_amount || Money.zero(currency)).subtract(
    pendingAmount
  );

  const totalSpentPercentage = Money.pct(totalSpent, totalBudget);
  const totalCommittedPercentage = Money.pct(totalCommitted, totalBudget);
  const totalRequestedPercentage = Money.pct(totalRequested, totalBudget);
  const remainingBudgetPercentage = new Big(100).sub(
    totalSpentPercentage.add(totalCommittedPercentage)
  );
  const warningColor = useColorModeValue("red.500", "red.200");
  const remainingBudgetColor = totalAvailable.isNeg() ? warningColor : "default";

  const spendingProgressBarElements: { value: number; color: string }[] = [
    {
      value: totalSpentPercentage.toNumber(),
      color: teal,
    },
    {
      value: totalCommittedPercentage.toNumber(),
      color: orange,
    },
    {
      value: totalRequestedPercentage.toNumber(),
      color: red,
    },
    {
      value: remainingBudgetPercentage.toNumber(),
      color: gray,
    },
  ];

  return (
    <VStack width="100%" align="start" pb={3}>
      <Heading size="sm">{rootSpendingAuthority.name}</Heading>
      <MultiProgress
        height={15}
        elements={spendingProgressBarElements}
        backgroundColor="gray"
        border="none"
        round={false}
        roundLastElement={false}
      />
      <HStack justifyContent="space-between" width="100%" mt={4}>
        <VStack align="start">
          <MoneyText money={totalAvailable} color={remainingBudgetColor} />
          <Text fontSize="sm" textColor={useColorModeValue("gray.500", "auto")}>
            Available Balance
          </Text>
        </VStack>
        <VStack align="end">
          <MoneyText money={totalBudget} />
          <Text fontSize="sm" textColor={useColorModeValue("gray.500", "auto")}>
            Total Budget
          </Text>
        </VStack>
      </HStack>

      <Divider mt={2} />
      <Flex
        gap={4}
        mt={2}
        align={approvalFlowBox ? "start" : { base: "start", md: "center" }}
        direction={approvalFlowBox ? "column" : { base: "column", md: "row" }}>
        <Tooltip label="Total amount paid out for this spending authority, representing all payments that have been completed.">
          <HStack spacing={2}>
            <Box w="8px" h="8px" borderRadius="full" bg={teal} />
            <Text fontSize="xs">Spent:</Text>
            <MoneyText fontSize={"xs"} money={totalSpent} />
          </HStack>
        </Tooltip>
        <Tooltip label="Total amount approved but unpaid for this spending authority, representing all pending transactions.">
          <HStack spacing={2}>
            <Box w="8px" h="8px" borderRadius="full" bg={orange} />
            <Text fontSize="xs">Committed:</Text>
            <MoneyText fontSize={"xs"} money={totalCommitted} />
          </HStack>
        </Tooltip>
        <Tooltip label="Funds that have been requested for this specific purchase and not yet paid. If this purchase has been completed, the number should be zero.">
          <HStack spacing={2}>
            <Box w="8px" h="8px" borderRadius="full" bg={red} />
            <Text fontSize="xs">Requested:</Text>
            <MoneyText fontSize={"xs"} money={totalRequested} />
          </HStack>
        </Tooltip>
        <Tooltip label="Total funds still available after accounting for all expenditures and commitments. It represents what can still be spent or allocated for future purchases.">
          <HStack spacing={2}>
            <Box w="8px" h="8px" borderRadius="full" bg={gray} />
            <Text fontSize="xs">Available:</Text>
            <MoneyText fontSize={"xs"} money={totalAvailable} color={remainingBudgetColor} />
          </HStack>
        </Tooltip>
      </Flex>
    </VStack>
  );
};
