import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { AxiosResponse } from "axios";
import { SearchParams, indexParams, paginationSchema, searchParams } from "./collection_types";

export const KEYRING_MESSAGES_BASE_URL = "keyring_messages";

export const partialKeyringMessageSchema = z.object({
  id: z.number().optional(),
  content: z.string(),
  message_type: z.string(),
  is_stale: z.boolean().optional(),
  matching_users: z.array(z.number()).optional(),
  stale_users: z
    .array(
      z.object({
        user_id: z.number(),
        stale_public_key: z.string(),
        current_public_key: z.string(),
      })
    )
    .optional(),
  unlock_users: z.array(z.number()).optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
});

export const keyringMessageSchema = partialKeyringMessageSchema.extend({
  id: z.number(),
  content: z.string(),
  message_type: z.string(),
  is_stale: z.boolean(),
  matching_users: z.array(z.number()),
  stale_users: z.array(
    z.object({
      user_id: z.number(),
      stale_public_key: z.string(),
      current_public_key: z.string(),
    })
  ),
  unlock_users: z.array(z.number()),
  created_at: z.string(),
  updated_at: z.string(),
});

export const updateKeyringMessageSchema = keyringMessageSchema
  .pick({
    content: true,
    message_type: true,
  })
  .extend({
    id: z.number().optional(),
  });

export const keyringMessageIndexSchema = z.object({
  results: z.array(keyringMessageSchema),
  pagination: paginationSchema,
});

export const userPublicKeySchema = z.object({
  id: z.number(),
  public_key: z.string(),
});

export const userPublicKeyIndexSchema = z.object({
  user_public_keys: z.array(userPublicKeySchema),
});

export type UserPublicKey = z.infer<typeof userPublicKeySchema>;
export type UserPublicKeyIndex = z.infer<typeof userPublicKeyIndexSchema>;
export type KeyringMessageData = z.infer<typeof keyringMessageSchema>;
export type UpdateKeyringMessageData = z.infer<typeof updateKeyringMessageSchema>;
export type KeyringMessageIndexData = z.infer<typeof keyringMessageIndexSchema>;

export const getKeyringMessages = async () => {
  const result = await api.get(KEYRING_MESSAGES_BASE_URL);
  return result.data;
};

export const searchKeyringMessages = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [KEYRING_MESSAGES_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return result.data;
};

export const getStaleKeyringMessages = async () => {
  const result = await api.get(`${KEYRING_MESSAGES_BASE_URL}/stale`);
  return result.data;
};

export async function createKeyringMessage(
  keyringMessage: Partial<KeyringMessageData>
): Promise<AxiosResponse<KeyringMessageData>> {
  return await api.post(KEYRING_MESSAGES_BASE_URL, { keyring_message: keyringMessage });
}

export async function upsertKeyringMessage(
  keyringMessage: Partial<KeyringMessageData>
): Promise<AxiosResponse<KeyringMessageData>> {
  if (keyringMessage.id) {
    return updateKeyringMessage(keyringMessage);
  } else {
    return createKeyringMessage(keyringMessage);
  }
}

export const updateKeyringMessage = async (keyringMessage: Partial<KeyringMessageData>) => {
  return await api.put(`${KEYRING_MESSAGES_BASE_URL}/${keyringMessage.id}`, {
    keyring_message: keyringMessage,
  });
};

export const invalidateKeyringMessages = (id?: number) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [KEYRING_MESSAGES_BASE_URL, id] });
};

export const useGetKeyringMessagesQuery = () => {
  return useQuery({
    queryKey: [KEYRING_MESSAGES_BASE_URL],
    queryFn: () => getKeyringMessages(),
  });
};

export const useSearchKeyringMessagesQuery = (params: SearchParams) => {
  return useQuery({
    queryKey: [KEYRING_MESSAGES_BASE_URL, params],
    queryFn: () => searchKeyringMessages(params),
  });
};

export const useGetStaleKeyringMessagesQuery = () => {
  return useQuery({
    queryKey: [KEYRING_MESSAGES_BASE_URL, "stale"],
    queryFn: () => getStaleKeyringMessages(),
  });
};

export const useUpdateKeyringMessage = (id?: number) => {
  return useMutation({
    mutationFn: updateKeyringMessage,
    onSuccess: invalidateKeyringMessages(id),
  });
};

export const useCreateKeyringMessage = () => {
  return useMutation({
    mutationFn: createKeyringMessage,
  });
};

export const useUpsertKeyringMessage = () => {
  return useMutation({
    mutationFn: upsertKeyringMessage,
  });
};
