import React, { useEffect } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Input,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Stack,
  Button,
  useDisclosure,
  Box,
  Tag,
} from "@chakra-ui/react";
import { useCreateCapitalEquipment } from "../../../api/capital_equipment";
import { AddIcon } from "@chakra-ui/icons";
import { useTeamOptions } from "../../../api/options";
import { useForm, Controller } from "react-hook-form";

type NewToolModalProps = {
  teamId?: number;
};

type FormValuesType = {
  name: string;
  team_id: number | null;
  wafer_services_tool_id: string;
};

const defaultFormValues: FormValuesType = {
  name: "",
  team_id: null,
  wafer_services_tool_id: "",
};

export const NewCapitalEquipment = ({ teamId }: NewToolModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const teamOptions = useTeamOptions();

  const { control, handleSubmit, reset } = useForm({ defaultValues: defaultFormValues });
  const { mutate: createTool, isLoading: isLoadingCreateTool } = useCreateCapitalEquipment();
  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (teamId) {
      reset({ team_id: teamId });
    }
  }, [teamId]);

  const onSubmit = (data: FormValuesType) => {
    const { team_id, ...rest } = data;
    if (team_id) {
      createTool({ team_id: Number(team_id), ...rest });
    }
    handleClose();
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} onClick={onOpen} colorScheme="teal">
        Add New
      </Button>
      <FormModal
        title="New Tool"
        isOpen={isOpen}
        submitButtonColorSchema="teal"
        onClose={handleClose}
        isLoading={isLoadingCreateTool}
        handleSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" w="100%" gap={4}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input {...field} />
              </FormControl>
            )}
          />
          <Controller
            name="team_id"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Team</FormLabel>
                <Select options={teamOptions} {...field} isDisabled={!!teamId} />
              </FormControl>
            )}
          />
          <Controller
            name="wafer_services_tool_id"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Wafer Services Tool ID</FormLabel>
                <Input {...field} />
              </FormControl>
            )}
          />
        </Flex>
      </FormModal>
    </>
  );
};
