import { AttributesTable, Header, SplitPage } from "@sciencecorp/helix-components";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useChatThreadQuery } from "../../api/chats";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Spinner,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChatMessages } from "./messages";

export const ChatTranscript = () => {
  const params = useParams();
  const threadQuery = useChatThreadQuery(params.id);

  if (threadQuery.isLoading) {
    return <Spinner />;
  }

  if (threadQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          Error loading chat transcript: {String(threadQuery.error)}
        </AlertDescription>
      </Alert>
    );
  }

  const thread = threadQuery.data;
  const state = {
    chatObjects: thread?.messages || [],
    streamingTextContent: "",
    thinking: false,
  };

  return (
    <Box>
      <Header
        title={thread?.summary ? thread.summary : `Thread #${thread?.id}`}
        crumbsColor="teal.500"
      />
      <SplitPage
        sidebarWidth="200px"
        sidebarWidthXL="250px"
        sidebar={
          <VStack height="100%" spacing={3}>
            <Box
              width="100%"
              bg={useColorModeValue("gray.50", "gray.700")}
              border="1px"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              p={4}
              borderRadius="md">
              <AttributesTable
                title="Chat Information"
                attributes={[
                  { label: "Thread ID", value: thread?.id },
                  { label: "Agent", value: thread?.agent_id },
                  { label: "Created", value: thread?.created_at },
                  { label: "Updated", value: thread?.updated_at },
                  {
                    label: "Visibility",
                    value: thread?.is_public ? "Public to Science" : "Private to you",
                  },
                ]}
              />
            </Box>
          </VStack>
        }
        main={
          <Box>
            <ChatMessages chatState={state} />
          </Box>
        }
      />
    </Box>
  );
};
