import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { budgetItemSummarySchema } from "./budget_items";
import { zodParse } from "./zodParse";

export const CAPITAL_EQUIPMENTS_BASE_URL = "/capital_equipments";

export const capitalEquipmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number(),
  wafer_services_tool_id: z.string().nullable(),
});

export const capitalEquipmentShowSchema = capitalEquipmentSchema
  .omit({
    team_id: true,
  })
  .extend({
    team: z.object({
      id: z.number(),
      name: z.string(),
    }),
    budget_id: z.number(),
    can_be_deleted: z.boolean(),
    summary: budgetItemSummarySchema.optional(),
  });

export type CapitalEquipmentData = z.infer<typeof capitalEquipmentSchema>;
export type CapitalEquipmentShowData = z.infer<typeof capitalEquipmentShowSchema>;
export type CapitalEquipmentCreateParams = Omit<CapitalEquipmentData, "id">;

/** queries */
export const getCapitalEquipment = async (id: number | null | undefined) => {
  const result = await api.get(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}`);
  return zodParse(capitalEquipmentShowSchema, result.data);
};

export const getCapitalEquipments = async () => {
  const result = await api.get(CAPITAL_EQUIPMENTS_BASE_URL);
  return zodParse(capitalEquipmentShowSchema.array(), result.data);
};

export const searchCapitalEquipments = async ({
  aggs,
  filters,
  bodyOptions,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [CAPITAL_EQUIPMENTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term, bodyOptions });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(capitalEquipmentShowSchema), result.data);
};

export const createCapitalEquipment = async (capital_equipment: CapitalEquipmentCreateParams) => {
  const result = await api.post(CAPITAL_EQUIPMENTS_BASE_URL, { capital_equipment });
  return result.data;
};

export const updateCapitalEquipment = async (capital_equipment: Partial<CapitalEquipmentData>) => {
  await api.put(`${CAPITAL_EQUIPMENTS_BASE_URL}/${capital_equipment.id}`, { capital_equipment });
};

export const deleteCapitalEquipment = async (id: number) => {
  await api.delete(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const invalidateCapitalEquipments = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CAPITAL_EQUIPMENTS_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetCapitalEquipments = () => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL],
    queryFn: () => getCapitalEquipments(),
  });
};

export const useSearchCapitalEquipments = (params: SearchParams) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, params],
    queryFn: () => searchCapitalEquipments(params),
  });
};

export const useGetCapitalEquipment = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, id],
    queryFn: () => getCapitalEquipment(id),
    enabled: !!id,
  });
};

export const useCreateCapitalEquipment = () => {
  return useMutation({
    mutationFn: createCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(),
  });
};

export const useUpdateCapitalEquipment = (id?: number) => {
  return useMutation({
    mutationFn: updateCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useDeleteCapitalEquipment = () => {
  return useMutation({
    mutationFn: deleteCapitalEquipment,
  });
};
