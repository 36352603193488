import { z } from "zod";
import { api } from ".";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { paginationSchema } from "./shared";
import { invalidateTrainingSession } from "./training_sessions";
import { userMinimalSchema } from "./user";

export const USER_TRAINING_SESSIONS_BASE_URL = "user_training_sessions";

export const userTrainingSessionSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  user: userMinimalSchema,
  training_session_id: z.number(),
  test_score: z.number().nullable(),
  status: z.string(),
  completed_at: z.string().nullable(),
});

export const userTrainingSessionIndexSchema = z.object({
  results: z.array(userTrainingSessionSchema),
  pagination: paginationSchema,
});

export const newTrainingSessionAllUsersSchema = z.object({
  user_ids: z.array(z.number()),
  training_session_id: z.number(),
});

export type UserTrainingSessionAllUsersCreateParams = z.infer<
  typeof newTrainingSessionAllUsersSchema
>;
export type UserTrainingSessionData = z.infer<typeof userTrainingSessionSchema>;
export type UserTrainingSessionCreateParams = Pick<
  UserTrainingSessionData,
  "training_session_id" | "user_id"
> & {
  status?: string;
};

// api queries

export const getUserTrainingSessions = async () => {
  const result = await api.get(USER_TRAINING_SESSIONS_BASE_URL);
  return result.data;
};

export const newUserTrainingSession = async (
  user_training_session: UserTrainingSessionCreateParams
) => {
  const result = await api.post(USER_TRAINING_SESSIONS_BASE_URL, { user_training_session });
  return result.data;
};

export const newUserTrainingSessionForAllEmployees = async (
  user_training_session: UserTrainingSessionAllUsersCreateParams
) => {
  const result = await api.post(`${USER_TRAINING_SESSIONS_BASE_URL}/all`, {
    user_training_session,
  });
  return result.data;
};

export const updateUserTrainingSession = async (
  user_training_session: Partial<UserTrainingSessionData>
) => {
  const result = await api.put(`${USER_TRAINING_SESSIONS_BASE_URL}/${user_training_session.id}`, {
    user_training_session,
  });
  return result.data;
};

export const updateAllUserTrainingSessions = async (
  user_training_sessions: Partial<UserTrainingSessionData>[]
) => {
  const result = await api.put(`${USER_TRAINING_SESSIONS_BASE_URL}/attendees`, {
    user_training_sessions,
  });
  return result.data;
};

export const deleteUserTrainingSession = async (id: number) => {
  await api.delete(`${USER_TRAINING_SESSIONS_BASE_URL}/${id}`);
};

// query hooks

export const invalidateUserTrainingSession = (id?: number) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: id ? [USER_TRAINING_SESSIONS_BASE_URL, id] : [USER_TRAINING_SESSIONS_BASE_URL],
    });
};

export const useGetMyTrainingSessions = () => {
  return useQuery({
    queryKey: [USER_TRAINING_SESSIONS_BASE_URL, "me"],
    queryFn: getUserTrainingSessions,
  });
};

export const useNewUserTrainingSession = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: newUserTrainingSession,
    onSuccess: onSuccessCallback(),
  });
};

export const useNewUserTrainingSessionForAllEmployees = () => {
  return useMutation({
    mutationFn: newUserTrainingSessionForAllEmployees,
    onSuccess: invalidateTrainingSession(),
  });
};

export const useUpdateUserTrainingSession = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: updateUserTrainingSession,
    onSuccess: onSuccessCallback(),
  });
};

export const useUpdateAllUserTrainingSessions = () => {
  return useMutation({
    mutationFn: updateAllUserTrainingSessions,
    onSuccess: invalidateTrainingSession(),
  });
};

export const useDeleteUserTrainingSession = (
  onSuccessCallback: (id?: number) => () => Promise<void>
) => {
  return useMutation({
    mutationFn: deleteUserTrainingSession,
    onSuccess: onSuccessCallback(),
  });
};
