import React from "react";
import { createRoot } from "react-dom/client";

import Main from "../components/Main";

const form = document.querySelector("div#app-root-gating form");
if (form) {
  (form as HTMLFormElement).submit();
} else {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<Main />);
}
