import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  ButtonGroup,
  Card,
  CardBody,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  AttributesTable,
  EditableText,
  Header,
  RichTextEditor,
  SplitPage,
} from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { SlOptions } from "react-icons/sl";
import {
  abandonMilestone,
  activateMilestone,
  completeMilestone,
  deleteMilestone,
  updateMilestone,
  useMilestoneQuery,
  useMilestonesQuery,
} from "../../api/milestone";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { TeamCard } from "../Teams/teamCard";
import { ButtonWithConfirmation } from "../util";
import { PredictionsChart } from "./milestonePlot";
import { NewPredictionModal } from "./newPrediction";
import { MilestoneStatusBadge, MilestoneStatusColors } from "./util";

export const Milestone = () => {
  const { id } = useParams();
  const milestoneQuery = useMilestoneQuery(parseInt(id ?? "0"));
  const milestonesQuery = useMilestonesQuery();
  const userQuery = useCurrentUserQuery();
  const navigate = useNavigate();

  const editable = milestoneQuery.data?.team.can_act_as_lead || userHasRole(userQuery, "hr_admin");

  const teamMemberIds = useMemo(() => {
    return (milestoneQuery.data?.team?.team_memberships || []).map(
      (membership) => membership.user.id
    );
  }, [milestoneQuery.data]);

  if (milestoneQuery.isLoading) {
    return <Spinner />;
  }

  if (milestoneQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Error loading team: {String(milestoneQuery.error)}</AlertDescription>
      </Alert>
    );
  }

  const milestone = milestoneQuery.data;
  const onChangeDescription = (rich: string) => {
    updateMilestone({ ...milestone, description: rich });
    milestoneQuery.refetch();
  };

  const milestoneList = milestonesQuery.data?.results?.filter((thisMilestone) => {
    return thisMilestone.status === milestone.status;
  });

  const findNeighborsById = (arr, id) => {
    const index = arr.findIndex((item) => item.id === id);
    if (index === -1) {
      return null;
    }

    const result = {
      previous: index === 0 ? null : arr[index - 1],
      next: index === arr.length - 1 ? null : arr[index + 1],
    };

    return result;
  };

  const previousNext =
    milestone && milestoneList ? findNeighborsById(milestoneList, milestone.id) : null;

  return (
    <Box>
      <Header
        title={milestone.name}
        badge={{
          label: milestone.status,
          colorScheme: MilestoneStatusColors[milestone.status],
          size: "lg",
        }}
        crumbs={[{ label: "Progress", url: "/progress" }]}
        crumbsColor="teal.500"
        actions={
          previousNext
            ? ([
                <ButtonGroup variant="outline" isAttached={true}>
                  <IconButton
                    isDisabled={previousNext.previous === null}
                    onClick={() => {
                      navigate(`/milestones/${previousNext.previous?.id}`);
                    }}
                    aria-label="Previous Milestone"
                    icon={<HiArrowLeft />}
                  />
                  <IconButton
                    isDisabled={previousNext.next === null}
                    onClick={() => {
                      navigate(`/milestones/${previousNext.next?.id}`);
                    }}
                    aria-label="Next Milestone"
                    icon={<HiArrowRight />}
                  />
                </ButtonGroup>,
              ] as JSX.Element[])
            : undefined
        }
      />

      <Box mt={8}>
        <SplitPage
          sidebarWidth="350px"
          sidebarWidthXL="450px"
          sidebar={
            <VStack align="start" w="100%" spacing={8}>
              <AttributesTable
                title="Milestone info"
                attributes={[
                  { label: "ID", value: milestone.id },
                  {
                    label: "Name",
                    value: (
                      <EditableText
                        defaultValue={milestone.name}
                        preview={milestone.name}
                        onSubmit={async (value) => {
                          updateMilestone({ ...milestone, name: value || "" });
                          milestoneQuery.refetch();
                        }}
                        disabled={!editable || milestone.status !== "draft"}
                      />
                    ),
                  },
                  {
                    label: "Status",
                    value: (
                      <>
                        <MilestoneStatusBadge status={milestone.status} />
                        {userHasRole(userQuery, "hr_admin") && milestone.status !== "completed" && (
                          <Popover>
                            <PopoverTrigger>
                              <IconButton
                                size="xs"
                                marginInlineStart="1"
                                aria-label="Change Status"
                                icon={<SlOptions />}
                              />
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverBody>
                                <Stack>
                                  {milestone.status === "draft" && (
                                    <ButtonWithConfirmation
                                      colorScheme="teal"
                                      isDisabled={!userHasRole(userQuery, "hr_admin")}
                                      onConfirm={async () => {
                                        await activateMilestone(milestone.id);
                                        milestoneQuery.refetch();
                                      }}
                                      actionLabel="Activate"
                                      message="Are you sure you want to activate this milestone? This will lock the title and description!"
                                    />
                                  )}
                                  {milestone.status === "active" && (
                                    <ButtonWithConfirmation
                                      colorScheme="teal"
                                      isDisabled={!userHasRole(userQuery, "hr_admin")}
                                      onConfirm={async () => {
                                        await completeMilestone(milestone.id);
                                        milestoneQuery.refetch();
                                      }}
                                      actionLabel="Complete"
                                      message="Definitely complete this milestone?"
                                    />
                                  )}
                                  {milestone.status === "draft" && (
                                    <ButtonWithConfirmation
                                      colorScheme="red"
                                      isDisabled={!editable}
                                      onConfirm={async () => {
                                        await deleteMilestone(milestone.id);
                                        navigate("/progress");
                                      }}
                                      actionLabel="Delete"
                                      message="Are you sure you want to delete this milestone?"
                                    />
                                  )}
                                  {milestone.status === "active" && (
                                    <ButtonWithConfirmation
                                      colorScheme="red"
                                      isDisabled={!editable}
                                      onConfirm={async () => {
                                        await abandonMilestone(milestone.id);
                                        milestoneQuery.refetch();
                                      }}
                                      actionLabel="Abandon"
                                      message="Are you sure you want to abandon this milestone?"
                                    />
                                  )}
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )}
                      </>
                    ),
                  },
                ]}
              />

              <TeamCard team={milestone.team!} location="teams" />
            </VStack>
          }
          main={
            <VStack align="start" spacing={4}>
              <Box width="100%">
                <Text fontSize="2xl" fontWeight={"bold"}>
                  Description
                </Text>
                {milestoneQuery.data && (
                  <RichTextEditor
                    editable={editable && milestone.status === "draft"}
                    placeholder={
                      milestone.status === "draft"
                        ? "How will we know when this milestone is complete?"
                        : "No description provided."
                    }
                    defaultValue={milestoneQuery.data.description}
                    onSave={onChangeDescription}
                  />
                )}
              </Box>

              {milestone.status != "draft" && (
                <>
                  <PredictionsChart milestone={milestone} />

                  {milestone.milestone_predictions.map((prediction) => (
                    <Card key={prediction.id} w="100%">
                      <CardBody>
                        <HStack spacing={4}>
                          {teamMemberIds.includes(prediction.user.id) ? (
                            <Badge colorScheme="green">Team Member</Badge>
                          ) : (
                            <Badge colorScheme="orange">Non-Team Member</Badge>
                          )}
                          <Text color="gray.500">
                            {DateTime.fromISO(prediction.completion_date).toLocaleString(
                              DateTime.DATETIME_FULL
                            )}
                          </Text>
                        </HStack>
                      </CardBody>
                    </Card>
                  ))}

                  {milestone.status === "active" && (
                    <NewPredictionModal
                      milestone={milestone}
                      onCreate={() => milestoneQuery.refetch()}
                    />
                  )}
                </>
              )}
            </VStack>
          }
        />
      </Box>
    </Box>
  );
};
