import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { DateTime } from "luxon";
import { z } from "zod";
import { api } from ".";
import { budgetHeadcountShowSchema } from "./budget_headcounts";
import { budgetItemShowSchema, budgetItemSummarySchema } from "./budget_items";
import { SearchParams, indexParams, paginationSchema, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { dateTimeSchema } from "../helpers/dateTime";
import { userMinimalSchema } from "./user";
import { createPaginatedResponseSchema, createSearchResponseSchema } from "./shared";
import { invalidateBudgetGroup } from "./budget_groups";

export const BUDGETS_BASE_URL = "budgets";

export const budgetableTypes = z.enum(["Team", "Project", "CapitalEquipment", "User"]);
export type BudgetableTypeData = z.infer<typeof budgetableTypes>;

export const budgetSchema = z.object({
  id: z.number(),
  budgetable_id: z.number(),
  budgetable_type: z.string(),
  description: z.string().nullable(),
  start_date: z.string().nullable(),
  end_date: z.string().nullable(),
  residual_treatment: z.string(),
  status: z.string(),
  approved_at: z.string().nullable(),
  declined_at: z.string().nullable(),
  abandoned_at: z.string().nullable(),
  added_to_group: dateTimeSchema.nullable(),
  moved_from_group: dateTimeSchema.nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  requested_headcount: z.number(),
  filled_headcount: z.number(),
  budget_group_id: z.number().nullable(),
});
export const budgetPartialSchema = budgetSchema.pick({
  id: true,
  budgetable_id: true,
  budgetable_type: true,
  residual_treatment: true,
  start_date: true,
  end_date: true,
  approved_at: true,
  abandoned_at: true,
  created_at: true,
  updated_at: true,
  declined_at: true,
  status: true,
  requested_headcount: true,
  filled_headcount: true,
  added_to_group: true,
  moved_from_group: true,
});

export const budgetWithSummarySchema = z.lazy(() =>
  budgetPartialSchema
    .pick({
      id: true,
    })
    .extend({
      budget_group_id: z.number().nullable(),
      budgetable: z.object({
        id: z.number(),
        type: budgetableTypes,
        name: z.string(),
      }),
      summary: budgetItemSummarySchema,
      sub_team_budgets: z
        .object({
          budget: budgetPartialSchema,
          summary: budgetItemSummarySchema,
        })
        .array()
        .nullable()
        .optional(),
    })
);

const minimalBudgetableSchema = z.object({
  id: z.number(),
  name: z.string(),
  app_href: z.string(),
  type: budgetableTypes,
  picture_uri: z.string().nullable(),
});

export const budgetIndexSchema = budgetPartialSchema
  .pick({
    id: true,
    added_to_group: true,
    moved_from_group: true,
  })
  .extend({
    budgetable: minimalBudgetableSchema,
    summary: z.lazy(() => budgetItemSummarySchema).optional(),
    lead: z.lazy(() => userMinimalSchema).nullable(),
    team_name: z.string().nullable(),
  });

export const budgetTableSummarySchema = z.object({
  budgetable: z.object({
    id: z.number(),
    name: z.string(),
    type: budgetableTypes,
  }),
  summary: z.lazy(() => budgetItemSummarySchema),
  headcount: z.number(),
});

export const budgetItemsSchema = createPaginatedResponseSchema(z.lazy(() => budgetItemShowSchema));

export const budgetHeadcountIndexSchema = z.object({
  total_headcount: z.number(),
  vacancies: z.number(),
  results: z.array(budgetHeadcountShowSchema),
  pagination: paginationSchema,
});

export type BudgetItemsData = z.infer<typeof budgetItemsSchema>;

interface getBudgetParams {
  budget_id: number | null;
  end_date: DateTime;
}
export type BudgetData = z.infer<typeof budgetSchema>;
export type BudgetIndexItemData = z.infer<typeof budgetIndexSchema>;
export type BudgetWithSummary = z.infer<typeof budgetWithSummarySchema>;
export type BudgetTableSummaryData = z.infer<typeof budgetTableSummarySchema>;
export type BudgetPartialData = z.infer<typeof budgetPartialSchema>;

export const getBudgetItems = async ({
  budget_id,
  end_date,
}: getBudgetParams): Promise<BudgetItemsData> => {
  const result = await api.get(`${BUDGETS_BASE_URL}/${budget_id}/budget_items`, {
    params: { per_page: -1, end_date },
  });
  return zodParse(budgetItemsSchema, result.data);
};

interface budgetSummaryParams {
  per_page: number;
  include_archived: boolean;
  end_date: DateTime;
}

export const getBudgetItemsSummary = async (
  budget_id: number,
  endDate: DateTime
): Promise<BudgetWithSummary> => {
  const params: Partial<budgetSummaryParams> = {
    per_page: -1,
    end_date: endDate,
  };
  const result = await api.get(`${BUDGETS_BASE_URL}/${budget_id}/summary`, {
    params: params,
  });
  return zodParse(budgetWithSummarySchema, result.data);
};

export const getBudget = async (id: number | null) => {
  const result = await api.get(`${BUDGETS_BASE_URL}/${id}`);
  return zodParse(budgetIndexSchema, result.data);
};

export const removeBudgets = async ({ budgetIds }: { budgetIds: number[] }) => {
  const result = await api.post(`${BUDGETS_BASE_URL}/remove`, {
    budget_ids: budgetIds,
  });
  return zodParse(budgetIndexSchema.array(), result.data);
};

export const moveBudgets = async ({
  budgetIds,
  newBudgetGroupId,
}: {
  budgetIds: number[];
  newBudgetGroupId: number;
}) => {
  const result = await api.post(`${BUDGETS_BASE_URL}/move`, {
    new_budget_group_id: newBudgetGroupId,
    budget_ids: budgetIds,
  });
  return zodParse(budgetIndexSchema.array(), result.data);
};

export const bulkMoveBudgets = async ({
  budgetIds,
  budgetGroupId,
}: {
  budgetIds: number[];
  budgetGroupId: number;
}) => {
  await api.post(`${BUDGETS_BASE_URL}/bulk_update`, {
    budget_group_id: budgetGroupId,
    budget_ids: budgetIds,
  });
};

export const getBudgetSummary = async (id: number | null, endDate: DateTime) => {
  const result = await api.get(`${BUDGETS_BASE_URL}/${id}/summary`, {
    params: _.omitBy({ include_sub_teams: true, end_date: endDate.toISO() }, _.isUndefined),
  });
  return zodParse(budgetWithSummarySchema, result.data);
};

export const getAllBudgets = async () => {
  const result = await api.get(BUDGETS_BASE_URL);
  return zodParse(z.array(budgetPartialSchema), result.data);
};

// for finance > budgets

export const updateBudget = async (budget: Partial<BudgetData>) => {
  const result = await api.put(`${BUDGETS_BASE_URL}/${budget.id}`, {
    budget: budget,
  });
  return result.data;
};

export const getBudgetHeadcount = async (id: number | null) => {
  const result = await api.get(`${BUDGETS_BASE_URL}/${id}/headcount`, {
    params: { per_page: -1 },
  });
  return zodParse(budgetHeadcountIndexSchema, result.data);
};

export const getBudgetSummaryCsv = async () => {
  const result = await api.get(`${BUDGETS_BASE_URL}/csv`, {
    responseType: "blob",
  });
  return result.data;
};

export const getTeamSpend = async (id: number | null) => {
  const result = await api.get(`${BUDGETS_BASE_URL}/${id}/team_spend`);
  return result.data;
};

export const invalidateBudget = (id?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [BUDGETS_BASE_URL];
  if (id) queryKey.push(id);
  if (subQuery) queryKey.push(subQuery);

  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetBudget = (id: number | null) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, id],
    queryFn: () => getBudget(id),
    enabled: !!id,
  });
};

export const useGetBudgetSummary = (id: number | null, endDate: DateTime) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, id, "summary"],
    queryFn: () => getBudgetSummary(id, endDate),
    enabled: !!id,
  });
};

export const useGetAllBudgets = () => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL],
    queryFn: () => getAllBudgets(),
  });
};

export const useMoveBudgets = () => {
  return useMutation({
    mutationFn: moveBudgets,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useBulkMoveBudgets = () => {
  return useMutation({
    mutationFn: bulkMoveBudgets,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const useRemoveBudgets = () => {
  return useMutation({
    mutationFn: removeBudgets,
    onSuccess: invalidateBudgetGroup(),
  });
};

export const searchBudgets = async ({
  aggs,
  filters,
  pagination,
  order,
  term,
  include_summaries,
}: SearchParams & { include_summaries: boolean }) => {
  const path = [BUDGETS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), {
    include_summaries,
    ...index,
    ...search,
  });
  return zodParse(createSearchResponseSchema(budgetIndexSchema), result.data);
};

export const useUpdateBudget = (id?: number) => {
  return useMutation({
    mutationFn: updateBudget,
    onSettled: invalidateBudget(id),
  });
};

export const useBudgetItems = (params: getBudgetParams) => {
  const queryKey = [BUDGETS_BASE_URL, params.budget_id, "all", params];

  return useQuery({
    queryKey,
    queryFn: () => getBudgetItems(params),
    enabled: !!params.budget_id,
  });
};

export const useBudgetItemsSummary = (budgetId: number, endDate: DateTime) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, budgetId, "summary", endDate],
    queryFn: () => getBudgetItemsSummary(budgetId, endDate),
    enabled: !!budgetId,
  });
};

export const useSearchBudgets = (params: SearchParams & { include_summaries: boolean }) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, params],
    queryFn: () => searchBudgets(params),
  });
};

export const useBudgetHeadcount = (id: number | null) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, id, "budget_headcounts"],
    queryFn: () => getBudgetHeadcount(id),
    enabled: !!id,
  });
};

export const useGetTeamSpend = (id: number | null) => {
  return useQuery({
    queryKey: [BUDGETS_BASE_URL, id, "team_spend"],
    queryFn: () => getTeamSpend(id),
    enabled: !!id,
  });
};
