import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { MonthlySpendData } from "../../../../api/budget_groups";

const BudgetActivityBarGraphContent = ({ monthlySpend }: { monthlySpend: MonthlySpendData }) => {
  const barColor = useColorModeValue("#319795", "rgba(72, 187, 120, 0.6)");
  const borderColor = useColorModeValue("#319795", "rgba(72, 187, 120, 1)");
  const textColor = useColorModeValue("#4A5568", "#E2E8F0"); // Black in light mode, light color in dark mode
  const axisTitleColor = useColorModeValue("#718096", "gray.400"); // Gray for axis titles
  const gridColor = useColorModeValue("rgba(200, 200, 200, 0.3)", "rgba(100, 100, 100, 0.3)");

  const prepareData = useMemo(() => {
    const labels = Object.keys(monthlySpend);
    const data = Object.values(monthlySpend).map((money) => money.majorUnits.toNumber());

    return {
      labels,
      datasets: [
        {
          label: "Spent",
          data: data,
          backgroundColor: barColor,
          borderColor: borderColor,
          pointStyle: "circle",
        },
      ],
    };
  }, [monthlySpend]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: textColor,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => `$${tooltipItem.raw.toLocaleString()}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Amount ($)",
          color: axisTitleColor,
          font: {
            size: 12,
          },
        },
        ticks: {
          color: textColor,
          font: {
            size: 10,
          },
        },
        grid: {
          color: gridColor,
          drawBorder: true,
        },
      },
      x: {
        title: {
          display: true,
          text: "Month",
          color: axisTitleColor,
          font: {
            size: 12,
          },
        },
        ticks: {
          color: textColor,
        },
        grid: {
          color: gridColor,
          display: false,
        },
      },
    },
    barPercentage: 0.4,
    categoryPercentage: 0.4,
  };

  return (
    <Box style={{ height: "400px", width: "100%" }}>
      <Bar data={prepareData} options={options} />
    </Box>
  );
};

export default BudgetActivityBarGraphContent;
