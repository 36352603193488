import { Link, Text, VStack, TextProps, LinkProps, Box } from "@chakra-ui/react";
import Linkify from "linkify-react";
import { DateTime } from "luxon";
import React from "react";
import { PurchaseIndexItemData } from "../../api/purchase";

interface LinkifyTextProps extends Omit<TextProps, "fontSize"> {
  text: string;
  linkProps?: LinkProps;
  maxLinkChars?: number;
}

export const LinkifyText = ({
  text,
  maxLinkChars,
  linkProps = {},
  ...textProps
}: LinkifyTextProps) => {
  return (
    <VStack align="start" w="100%">
      {text.split("\n").map((line, i) => (
        <Text {...textProps} fontSize={["xs", "sm"]}>
          <Linkify
            key={i}
            options={{
              render: ({ attributes, content }) => (
                <Link
                  {...linkProps}
                  height={"1rem"}
                  display="inline-flex"
                  href={attributes.href}
                  isExternal
                  color="teal.400">
                  <Text
                    wordBreak="break-all"
                    isTruncated={true}
                    width={maxLinkChars ? `${maxLinkChars}ch` : linkProps.width}>
                    {content}
                  </Text>
                </Link>
              ),
            }}>
            {line}
          </Linkify>
        </Text>
      ))}
    </VStack>
  );
};

export const purchaseRequestStatusColor = (status: string) => {
  switch (status) {
    case "draft":
      return "gray";
    case "new":
      return "purple";
    case "needs_approval":
      return "red";
    case "declined":
      return "red";
    case "awaiting_purchase":
      return "orange";
    case "awaiting_delivery":
      return "teal";
    case "awaiting_payment":
      return "blue";
    case "delivered":
      return "green";
    case "closed":
      return "red";
  }
  return "gray";
};

export const PurchaseAdditionalStatusText = (props: { purchase: PurchaseIndexItemData }) => {
  const { purchase } = props;

  let text = "";
  switch (purchase.status) {
    case "awaiting_purchase":
    case "awaiting_delivery":
      text = purchase.estimated_ship_date
        ? `Shipping ${DateTime.fromISO(purchase.estimated_ship_date).toRelative()}`
        : "Ship date unknown";
      break;
    case "awaiting_payment":
    case "delivered":
      text = purchase.delivered_at
        ? `Delivered ${DateTime.fromISO(purchase.delivered_at).toRelative()}`
        : "Delivery date unknown";
      break;
  }

  return text ? (
    <Text fontSize="sm" color="gray.500" key={purchase.id}>
      {text}
    </Text>
  ) : null;
};

export const getWeeksOrMonthsRange = (
  startOf: "week" | "month" | "year",
  weeksOrMonthsAgo?: number
) => {
  const currentDate = DateTime.utc();

  let startOfPeriod;
  if (startOf === "week") {
    startOfPeriod = currentDate.minus({ weeks: weeksOrMonthsAgo || 0 }).startOf("week");
  } else if (startOf === "month") {
    startOfPeriod = currentDate.minus({ months: weeksOrMonthsAgo || 0 }).startOf("month");
  }

  const gte = startOfPeriod?.toFormat("yyyy-MM-dd");
  const lte = currentDate?.toFormat("yyyy-MM-dd");
  return { gte, lte };
};

export const TIME_FILTER_MAP: {
  [key in TimeFilterType]: any;
} = {
  this_week: getWeeksOrMonthsRange("week"),
  last_week: getWeeksOrMonthsRange("week", 1),
  last_2_weeks: getWeeksOrMonthsRange("week", 2),
  this_month: getWeeksOrMonthsRange("month"),
  last_month: getWeeksOrMonthsRange("month", 1),
};

export type TimeFilterType =
  | "this_week"
  | "last_week"
  | "last_2_weeks"
  | "this_month"
  | "last_month";

export const TIME_FILTER_OPTIONS: { label: string; value: TimeFilterType }[] = [
  {
    label: "This Week",
    value: "this_week",
  },
  {
    label: "Last Week",
    value: "last_week",
  },
  {
    label: "Last 2 Weeks",
    value: "last_2_weeks",
  },
  {
    label: "This Month",
    value: "this_month",
  },
  {
    label: "Last Month",
    value: "last_month",
  },
];

export const vendorStatusMap = [
  { label: "Pending", value: "pending", color: "orange" },
  { label: "Approved", value: "approved", color: "green" },
  { label: "Rejected", value: "rejected", color: "red" },
];

export const vendorStatusOptions = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];
