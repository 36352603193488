import React from "react";
import { BudgetGroupIndexData } from "../../../../api/budget_groups";
import { MoneyText } from "../../../MoneyText";
import {
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  Text,
  Tag,
  useColorModeValue,
  Tooltip,
  Avatar,
  Box,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import SpendingDonutChart from "../../../Budgets/SpendingDonutChart";

type BudgetGroupGridProps = {
  budgetGroups?: BudgetGroupIndexData[];
  isLoading: boolean;
};

export const BudgetGroupGrid = ({ budgetGroups, isLoading }: BudgetGroupGridProps) => {
  const backgroundColorGray = useColorModeValue("gray.50", "gray.700");
  const textColorGray = useColorModeValue("gray.500", "auto");
  const moneyColor = useColorModeValue("black", "auto");
  return (
    <Skeleton isLoaded={!isLoading}>
      <Grid
        gap={4}
        width="100%"
        templateColumns={{
          base: "repeat(auto-fit, min(200px, 1fr))",
          lg: "repeat(auto-fit, minmax(500px, 1fr))",
        }}>
        {budgetGroups?.map((budgetGroup) => (
          <LinkBox key={`budget-group-${budgetGroup.id}`}>
            <LinkOverlay as={RouterLink} to={`/finance/budgets/budget_group/${budgetGroup.id}`}>
              <GridItem
                flexDir="column"
                border="1px"
                borderColor="chakra-border-color"
                borderRadius="md"
                bg={backgroundColorGray}>
                <HStack p={4} justify="space-between">
                  <HStack>
                    <Heading size="md">{budgetGroup.name}</Heading>
                    {budgetGroup.archived_at && (
                      <Box>
                        <Tag colorScheme="red">Archived</Tag>
                      </Box>
                    )}
                  </HStack>
                  <Flex align="center" direction={["column", "row"]} gap={1}>
                    <MoneyText
                      as="span"
                      fontSize="sm"
                      fontWeight="semibold"
                      money={budgetGroup.budget_group_schedule.amount}
                      formatOptions={{ compact: "never" }}
                    />
                    {budgetGroup.budget_group_schedule.schedule_type === "monthly" ? (
                      <Tag colorScheme="blue" size="sm">
                        Recurring
                      </Tag>
                    ) : (
                      <Tag colorScheme="green" size="sm">
                        Single Payment
                      </Tag>
                    )}
                  </Flex>
                </HStack>
                <Divider />
                <Flex p={4} gap={4}>
                  <Flex direction="column" gap={2} flex="1">
                    <HStack>
                      <Text color={textColorGray} fontSize="sm">
                        Lead:
                      </Text>
                      <Tooltip label={budgetGroup.lead.user.name} closeDelay={500}>
                        <Avatar
                          size="sm"
                          src={budgetGroup.lead.user.picture_uri}
                          name={budgetGroup.lead.user.name}
                        />
                      </Tooltip>
                    </HStack>
                    <Divider />
                    <Text fontSize="sm" color={textColorGray}>
                      <MoneyText
                        as="span"
                        color={moneyColor}
                        fontWeight="bold"
                        money={budgetGroup.budget_group_schedule.amount}
                        formatOptions={{ compact: "never" }}
                      />{" "}
                      total funding
                    </Text>
                  </Flex>
                  <SpendingDonutChart
                    remaining={budgetGroup.summary.remaining_amount}
                    committed={budgetGroup.summary.committed_amount}
                    spent={budgetGroup.summary.spent_amount}
                    total={budgetGroup.summary.funding_amount}
                  />
                  <Flex direction="column" gap={1} flex="1" display={["none", "block"]}>
                    <HStack fontSize="sm" justify="space-between">
                      <Flex align="center" gap={1} color={textColorGray}>
                        <Box bg="#319795" boxSize={4} borderRadius="full" />
                        Spent
                      </Flex>
                      <MoneyText
                        as="span"
                        fontWeight="medium"
                        money={budgetGroup.summary.spent_amount}
                      />
                    </HStack>
                    <HStack fontSize="sm" justify="space-between">
                      <Flex align="center" gap={1} color={textColorGray}>
                        <Box bg="#FBD38D" boxSize={4} borderRadius="full" />
                        Committed
                      </Flex>
                      <MoneyText
                        as="span"
                        fontWeight="medium"
                        money={budgetGroup.summary.committed_amount}
                      />
                    </HStack>
                    <HStack fontSize="sm" justify="space-between">
                      <Flex align="center" gap={1} color={textColorGray}>
                        <Box bg="#EDF2F7" boxSize={4} borderRadius="full" />
                        Remaining
                      </Flex>
                      <MoneyText
                        as="span"
                        fontWeight="medium"
                        money={budgetGroup.summary.remaining_amount}
                      />
                    </HStack>
                  </Flex>
                </Flex>
              </GridItem>
            </LinkOverlay>
          </LinkBox>
        ))}
      </Grid>
    </Skeleton>
  );
};
