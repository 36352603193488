import { z } from "zod";
import { moneySchema } from "../../helpers/Money";

export const candidateIdentifierTypeSchema = z.enum(["greenhouse", "lever"]);
export const compensationOfferSchema = z.object({
  id: z.number(),
  user_id: z.number().nullable(),
  offer_type: z.enum(["new_hire", "promotion", "lateral", "refresh"]),
  cash_compensation: moneySchema.nullable(),
  equity_compensation: moneySchema.nullable(),
  vesting_period_months: z.number().nullable(),
  vesting_cliff_months: z.number().nullable(),
  signing_bonus: moneySchema.nullable(),
  annual_cash_bonus: moneySchema.nullable(),
  candidate_name: z.string().nullable(),
  candidate_identifier: z.string().nullable(),
  candidate_identifier_type: candidateIdentifierTypeSchema.nullable(),
  status: z.enum(["pending", "accepted", "declined", "expired"]),
  sent_at: z.string().nullable(),
  expires_at: z.string().nullable(),
  accepted_at: z.string().nullable(),
  declined_at: z.string().nullable(),
  withdrawn_at: z.string().nullable(),
  began_at: z.string().nullable(),
  ended_at: z.string().nullable(),
});

export type CompensationOfferPartialData = z.infer<typeof compensationOfferSchema>;
export type CompensationOfferType = CompensationOfferPartialData["offer_type"];
export type CompensationOfferStatus = CompensationOfferPartialData["status"];
