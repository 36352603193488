import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Header, PaginationControls, usePagination } from "@sciencecorp/helix-components";
import { IoFilter } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router";
import { useSearchNewsPostsQuery } from "../../api/news_post";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { sortBy } from "lodash";
import { TIME_FILTER_MAP, TIME_FILTER_OPTIONS, TimeFilterType } from "../Purchasing/util";
import { NewsCard } from "./NewsCard";
import { useCurrentUserQuery, userHasRole } from "../../api/user";

export const CustomPopover = ({
  options,
}: {
  options: { id: string; label: string; content: any }[];
}) => {
  return (
    <>
      {options.map((option) => (
        <Popover placement="left-start" key={option.id}>
          <PopoverTrigger>
            <Flex justifyContent="space-between" alignItems="center" width="100%" px="5" py="3">
              <span>{option.label}</span>
              <ChevronRightIcon />
            </Flex>
          </PopoverTrigger>
          <PopoverContent w={["10rem", "18.9rem"]}>
            <PopoverBody p="0">{option.content}</PopoverBody>
          </PopoverContent>
        </Popover>
      ))}
    </>
  );
};

export const News = () => {
  const navigate = useNavigate();
  const userQuery = useCurrentUserQuery();
  const { pagination, onPagination } = usePagination();
  const { debouncedSearch, search } = useDebouncedSearch();
  const [category, setCategory] = useState<string[]>([]);
  const [timeFilter, setTimeFilter] = useState<TimeFilterType | null>(null);
  const mutatedFilter = category.length
    ? {
        category,
      }
    : {};
  const { data: newsPosts } = useSearchNewsPostsQuery({
    pagination,
    term: search || "*",
    filters: {
      ...mutatedFilter,
      created_at: timeFilter ? TIME_FILTER_MAP[timeFilter] : undefined,
    },
    order: {
      created_at: "desc",
    },
  });

  const options = [
    {
      label: "By Category",
      id: "category",
      content: (
        <VStack align="start" w="100%">
          <Button
            bg="none"
            w="100%"
            justifyContent="start"
            onClick={() => setCategory(["news", ...category.filter((cat) => cat !== "news")])}>
            <Text textAlign="start" fontWeight={400}>
              News
            </Text>
          </Button>

          <Button
            bg="none"
            w="100%"
            justifyContent="start"
            onClick={() =>
              setCategory(["meeting_note", ...category.filter((cat) => cat !== "meeting_note")])
            }>
            <Text textAlign="start" fontWeight={400}>
              Meeting Notes
            </Text>
          </Button>
        </VStack>
      ),
    },
    {
      label: "By Date",
      id: "date",
      content: (
        <Box w="100%">
          <VStack align="start" w="100%">
            {TIME_FILTER_OPTIONS.map((time) => {
              return (
                <Button
                  bg="none"
                  w="100%"
                  justifyContent="start"
                  onClick={() => setTimeFilter(time.value)}>
                  <Text fontWeight={400}>{time.label}</Text>
                </Button>
              );
            })}
          </VStack>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Header
        title="News"
        actions={[
          userHasRole(userQuery, "hr_admin") || userHasRole(userQuery, "news_writer") ? (
            <Button
              leftIcon={<AiOutlinePlus />}
              onClick={() => navigate("/news/create")}
              colorScheme="teal">
              New Post
            </Button>
          ) : (
            <></>
          ),
        ]}
        crumbs={[{ label: "Dashboard", url: "/" }]}
        crumbsColor="teal"
      />

      <Flex justify={["flex-start", "center"]}>
        <Box maxW="3xl" width="100%" mt={8}>
          <Flex
            direction={["column", "row"]}
            justify="space-between"
            w="100%"
            gap={3}
            align={["start", "center"]}>
            <Heading size="md" fontWeight="medium">
              Feed
            </Heading>
            <Stack direction={["row"]} align="start" spacing={4}>
              <Input
                placeholder="Search..."
                onChange={(e) => debouncedSearch(e.target.value)}
                w="200px"
              />
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <Button leftIcon={<IoFilter />} colorScheme="gray" variant="outline">
                    Filter
                  </Button>
                </PopoverTrigger>
                <PopoverContent w={["10rem", "18.9rem"]}>
                  <PopoverBody p="0">
                    <>
                      <CustomPopover options={options as any} />
                    </>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Stack>
          </Flex>
          <HStack paddingBlockEnd={6} spacing={2} width="100%">
            <>
              {category.map((cat) => {
                return (
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="subtle"
                    colorScheme="orange"
                    minW="max-content">
                    <TagLabel fontWeight="bold" textTransform="capitalize">
                      Category: {cat.split("_").join(" ")}
                    </TagLabel>
                    <TagCloseButton
                      onClick={() =>
                        setCategory(category.filter((filteredCat) => filteredCat !== cat))
                      }
                    />
                  </Tag>
                );
              })}
            </>
            <HStack>
              {!!timeFilter && (
                <Tag
                  size="sm"
                  borderRadius="full"
                  variant="subtle"
                  colorScheme="orange"
                  minW="max-content">
                  <TagLabel fontWeight="bold" textTransform="capitalize">
                    Time:{" "}
                    {TIME_FILTER_OPTIONS.find((o) => o.value === timeFilter)?.label || timeFilter}
                  </TagLabel>
                  <TagCloseButton onClick={() => setTimeFilter(null)} />
                </Tag>
              )}
            </HStack>
          </HStack>

          <VStack align="start" width="100%" spacing={9}>
            {newsPosts?.results.map((newsPost) => (
              <NewsCard newsPost={newsPost} key={newsPost.id} />
            ))}
            {newsPosts?.results.length === 0 && (
              <Center w="100%">
                <Alert
                  status="info"
                  colorScheme="gray"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="left"
                  background="transparent"
                  paddingBlock="24"
                  fontSize="xl">
                  No results found
                </Alert>
              </Center>
            )}
          </VStack>
          <PaginationControls
            pagination={newsPosts?.pagination || pagination}
            onPagination={onPagination}
          />
        </Box>
      </Flex>
    </Box>
  );
};
