import React, { createContext } from "react";
import { useCurrentUserQuery } from "../api/user";

export type CurrencyContextType = string;

export const CurrencyContext = createContext<CurrencyContextType>("USD");

export interface CurrencyProviderProps {
  currency: string;
  children: React.ReactNode | React.ReactNode[];
}

export const CurrencyProvider: React.FC<CurrencyProviderProps> = ({ currency, children }) => {
  return (
    <>
      <CurrencyContext.Provider value={currency}>{children}</CurrencyContext.Provider>
    </>
  );
};

export const UserCurrencyProvider: React.FC<Omit<CurrencyProviderProps, "currency">> = ({
  children,
}) => {
  const user = useCurrentUserQuery();
  if (user.isSuccess && user.data.default_currency) {
    return (
      <CurrencyContext.Provider value={user.data.default_currency}>
        {children}
      </CurrencyContext.Provider>
    );
  } else {
    return <>{children}</>;
  }
};

export const useCurrency = (overrideCurrency?: string | null | undefined): string => {
  const ctxCurrency = React.useContext(CurrencyContext);
  return overrideCurrency ?? ctxCurrency;
};
