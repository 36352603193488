import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  Textarea,
  Tooltip,
  VStack,
  useColorModeValue,
  useSteps,
} from "@chakra-ui/react";
import { Select } from "@sciencecorp/helix-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSitesOptions, useVendorOptions } from "../../../../api/options";
import {
  PurchaseShowData,
  ShippingPriority,
  useNewPurchase,
  useUpdatePurchase,
} from "../../../../api/purchase";
import { useCurrentUserQuery } from "../../../../api/user";
import { useNewVendor, useUpdateVendor } from "../../../../api/vendor";
import { shippingPriorityOptions } from "../util";
import {
  CreatePurchaseLineItemList,
  EditPurchaseLineItemList,
  useCreatePurchaseLineItemList,
} from "./PurchaseLineItemList";

import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import { BudgetItemBody } from "../../../shared/BudgetItemBody";
import {
  SpendingAuthorityPreview,
  SpendingAuthoritySelect,
} from "../../SpendingAuthoritySelectTree";
import { useNavigate } from "react-router";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";
import { CurrencySelect } from "../../../CurrencySelect";
import { CurrencyProvider } from "../../../../contexts/CurrencyContext";

const defaultFormValues: FormValuesType = {
  vendor_id: null,
  site_id: null,
  purchase_type: "purchase_request",
  description: "",
  files: [],
  wafer_services_tool: "",
  shipping_priority: "standard",
  spending_authority: null,
  currency: "USD",
};

export type FormValuesType = {
  vendor_id: string | null;
  site_id: string | null;
  purchase_type: string;
  description: string;
  files: { name: string; file: string }[];
  wafer_services_tool: string;
  shipping_priority: ShippingPriority;
  spending_authority: MinimalSpendingAuthority | null;
  currency: string;
};

type FormValuesConversion = {
  vendor_id: number;
  site_id: number;
  purchase_type: string;
  description: string;
  ops_treated: boolean;
  files: { name: string; file: string }[];
  wafer_services_tool: string;
  shipping_priority: ShippingPriority;
  spending_authority_id: number | undefined;
  spending_authority_type: string | undefined;
  currency: string;
};

type NewPurchaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  spendingAuthority?: MinimalSpendingAuthority;
  purchase?: PurchaseShowData | null;
  onSuccessCallback?: () => (id?: number) => Promise<void>;
  disableSpendingAuthorityPicker?: boolean;
};

export const NewPurchaseModal = ({
  isOpen,
  onClose,
  spendingAuthority,
  purchase,
  onSuccessCallback,
  disableSpendingAuthorityPicker,
}: NewPurchaseModalProps) => {
  const navigate = useNavigate();
  const { mutate: newPurchase, isLoading: isNewLoading } = useNewPurchase(onSuccessCallback);
  const { mutate: updatePurchase, isLoading: isUpdateLoading } =
    useUpdatePurchase(onSuccessCallback);
  const { mutate: createVendor, isLoading } = useNewVendor();
  const { mutate: updateVendor } = useUpdateVendor();
  const [vendorApproved, setVendorApproved] = useState(false);
  const [encodedFiles, setEncodedFiles] = useState<{ name: string; file: string }[]>([]);
  const [newVendor, setNewVendor] = useState<string | null>(null);
  const [vendorWebsite, setVendorWebsite] = useState<string>("");
  const [notBudgetedSelected, setNotBudgetedSelected] = useState(false);
  const siteOptions = useSitesOptions();
  const currentUser = useCurrentUserQuery();
  const vendorOptions = useVendorOptions();

  const {
    lineItems: createdLineItems,
    setLineItems: setCreatedLineItems,
    isValid: lineItemsAreValid,
  } = useCreatePurchaseLineItemList();

  const steps = [
    { title: "Vendor Selection" },
    { title: "Basic Information" },
    !vendorApproved && { title: "Items to Buy" },
    { title: "Confirmation" },
    vendorApproved && { title: "Confirmation" },
    vendorApproved && { title: "Get Card" },
  ].filter(Boolean) as { title: string }[];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const { handleSubmit, setValue, control, reset, watch, formState, trigger } =
    useForm<FormValuesType>({ mode: "onChange", defaultValues: defaultFormValues });
  const currency = watch("currency");

  const formValues = watch();

  useEffect(() => {
    if (spendingAuthority) {
      setValue("spending_authority", spendingAuthority);
    }
  }, [spendingAuthority]);

  const handleFormValidation = () => {
    switch (activeStep) {
      case 0:
        if (newVendor) return vendorWebsite;
        else return formValues.vendor_id;

      case 1:
        return formValues.spending_authority && formValues.site_id;
      case 2:
        lineItemsAreValid || formValues.description !== "";

      default:
        return true;
    }
  };

  useEffect(() => {
    if (purchase) {
      const formData: FormValuesType = {
        ...purchase,
        vendor_id: purchase.vendor?.id.toString() || null,
        description: purchase.description || "",
        site_id: purchase.site_id ? purchase.site_id.toString() : null,
        purchase_type: purchase.purchase_type || "purchase_request",
        wafer_services_tool: purchase.wafer_services_tool || "",
        shipping_priority: purchase.shipping_priority || "standard",
        files: [],
        spending_authority: purchase.spending_authority || null,
      };

      reset(formData);
    }
  }, [purchase]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => setEncodedFiles(encodedFileObjects))
        .catch((error) => console.error(error));
    }
  };

  const handleNextStep = async () => {
    const result = await trigger();
    if (activeStep === 2) {
      setActiveStep((prev) => prev + 1);
    } else if (result) {
      // For steps other than 2, simply advance if validation passes
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleClose = () => {
    reset();
    setVendorWebsite("");
    setVendorApproved(false);
    setActiveStep(0);
    setCreatedLineItems([]);
    onClose();
    setNotBudgetedSelected(false);
  };

  //determine what function to use on submit
  const submitFunction = (
    purchaseFormData: FormValuesConversion,
    purchase: PurchaseShowData | null | undefined
  ) => {
    // if editing a purchase
    if (purchase) {
      updatePurchase(
        {
          ...purchaseFormData,
          user_id: currentUser.data?.id,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
      // if creating a new purchase
    } else if (currentUser.isSuccess) {
      newPurchase(
        {
          ...purchaseFormData,
          user_id: currentUser.data.id,
          purchase_line_items: createdLineItems,
        },
        {
          onSuccess: (data) => {
            handleClose();
            navigate(`/services/purchasing/${data}`);
          },
        }
      );
    }
  };

  const onSubmit = (data: FormValuesType) => {
    const { vendor_id, spending_authority, ...restData } = data;

    vendorWebsite && updateVendor({ id: Number(vendor_id), website: vendorWebsite });

    const purchaseData: FormValuesConversion = {
      ...restData,
      vendor_id: Number(vendor_id),
      site_id: Number(data.site_id),
      files: encodedFiles,
      ops_treated: !vendorApproved,
      spending_authority_id: spending_authority?.id,
      spending_authority_type: spending_authority?.type,
    };

    submitFunction(purchaseData, purchase);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size={"4xl"} closeOnOverlayClick={false}>
        <CurrencyProvider currency={formValues.currency}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader px={6}>
              <VStack width="100%" align="start">
                <Text>New Purchase</Text>
                <Stepper
                  p={3}
                  size={["xs", "xs", "sm"]}
                  index={activeStep}
                  display="flex"
                  alignItems="space-between"
                  width="100%"
                  colorScheme="teal">
                  {steps.map((step, index) => (
                    <Step key={index} onClick={() => setActiveStep(index)}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>
                      <Box
                        flexShrink="0"
                        color={activeStep >= index ? "teal.500" : "gray.500"}
                        display={["none", "none", "inline"]}>
                        <StepTitle>{step.title}</StepTitle>
                      </Box>
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
                <Divider />
              </VStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody justifyContent="center">
              <VStack gap={4}>
                {activeStep === 0 && (
                  <>
                    <VStack maxWidth="xl" width={"100%"} align="start" gap={4}>
                      <Stack width="100%" gap={4} direction="column">
                        <Controller
                          name="vendor_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, ...field } }) => (
                            <FormControl flex="1">
                              <FormLabel>Who Do You Want to Buy From?</FormLabel>
                              <HStack width="100%">
                                <Select
                                  width="100%"
                                  isDisabled={isLoading}
                                  {...field}
                                  onChange={(...args) => {
                                    setVendorWebsite("");
                                    setNewVendor(null);
                                    onChange(...args);
                                  }}
                                  creatable
                                  onCreate={(value) => {
                                    createVendor(
                                      {
                                        name: value.toString(),
                                        website: vendorWebsite,
                                      },
                                      {
                                        onSuccess: (data) => {
                                          setValue("vendor_id", data.id.toString());
                                          setNewVendor(data.id);
                                        },
                                      }
                                    );
                                  }}
                                  options={vendorOptions || []}
                                  placeholder="Choose or Create"
                                />
                                {isLoading && <Spinner />}
                              </HStack>
                            </FormControl>
                          )}
                        />

                        {newVendor && (
                          <FormControl>
                            <FormLabel>What is the Vendor's Website?</FormLabel>
                            <Input
                              value={vendorWebsite}
                              onChange={(e) => setVendorWebsite(e.target.value)}
                            />
                          </FormControl>
                        )}
                      </Stack>
                    </VStack>
                  </>
                )}
                {activeStep === 1 && (
                  <VStack maxWidth="xl" width={"100%"} align="start" gap={4}>
                    <Stack width="100%" gap={4} direction={{ base: "column", md: "row" }}>
                      <Controller
                        name="site_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel>Site</FormLabel>
                            <Select {...field} placeholder="Choose" options={siteOptions} />
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="spending_authority"
                        control={control}
                        rules={{ required: true }}
                        render={() => (
                          <FormControl>
                            <FormLabel>Spending Authority</FormLabel>
                            {disableSpendingAuthorityPicker && spendingAuthority ? (
                              <SpendingAuthorityPreview
                                spendingAuthorityId={spendingAuthority.id}
                                spendingAuthorityType={spendingAuthority.type}
                              />
                            ) : (
                              <SpendingAuthoritySelect
                                onChange={(spendingAuthority) => {
                                  setValue("spending_authority", spendingAuthority);
                                }}
                                spendingAuthority={spendingAuthority || null}
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Stack>

                    {notBudgetedSelected && (
                      <Alert status="warning">
                        <AlertIcon />
                        Choosing "Not Budgeted" for your spending authority can slow things down.
                        For a smoother and faster approval, it's best to pick a budgeted option if
                        possible.
                      </Alert>
                    )}

                    <HStack width="100%">
                      <Controller
                        name="shipping_priority"
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel>Shipping Priority</FormLabel>
                            <Select
                              {...field}
                              defaultValue={"standard"}
                              options={shippingPriorityOptions}
                            />
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => {
                          const hasLineItems = createdLineItems.length > 0;
                          return (
                            <FormControl>
                              <Tooltip
                                label={
                                  "To change currency, please remove all line items on step 3."
                                }
                                isDisabled={!hasLineItems}>
                                <Box>
                                  <FormLabel>Currency</FormLabel>
                                  <CurrencySelect isDisabled={hasLineItems} {...field} />
                                </Box>
                              </Tooltip>
                            </FormControl>
                          );
                        }}
                      />
                    </HStack>
                  </VStack>
                )}
                {activeStep === 2 && (
                  <VStack width="100%" textAlign="center">
                    {purchase ? (
                      <EditPurchaseLineItemList purchase={purchase} />
                    ) : (
                      <Flex direction="column" gap={4} alignItems="start">
                        <CreatePurchaseLineItemList
                          lineItems={createdLineItems}
                          setLineItems={setCreatedLineItems}
                        />
                        <Controller
                          name="purchase_type"
                          control={control}
                          render={({ field }) => (
                            <FormControl alignItems="start">
                              <FormLabel>Is this a purchase order?</FormLabel>
                              <Flex width="100%" alignItems="start">
                                <Checkbox
                                  onChange={(e) =>
                                    field.onChange(
                                      e.target.checked ? "purchase_order" : "purchase_request"
                                    )
                                  }>
                                  Yes, I have a quote in hand and I need a purchase order
                                </Checkbox>
                              </Flex>
                            </FormControl>
                          )}
                        />
                        <FormControl alignItems="start">
                          <FormLabel>Additional Information</FormLabel>
                          <Textarea
                            placeholder={"Type here..."}
                            value={formValues.description}
                            onChange={(e) => setValue("description", e.target.value)}
                          />
                          <FormHelperText mb={2} textAlign={"start"}>
                            When submitting purchase orders, please include any relevant vendor
                            information here, such as contact names and emails of individuals you
                            have communicated with.
                          </FormHelperText>
                        </FormControl>
                        <VStack w="100%" align="start" spacing={1}>
                          <Text fontWeight="bold">Attachments</Text>
                          <Input type={"file"} multiple onChange={handleFileChange} />
                        </VStack>
                      </Flex>
                    )}
                  </VStack>
                )}
                {activeStep === 3 && (
                  <VStack width={"100%"} align="start" spacing={4}>
                    <HStack
                      width={"100%"}
                      align="center"
                      gap={4}
                      textAlign="center"
                      bg={useColorModeValue("gray.50", "gray.700")}
                      p={6}>
                      <VStack width="100%" spacing={2} ml={2}>
                        <Text>You are requesting</Text>
                        <Heading size="lg">
                          <MoneyText
                            money={createdLineItems.reduce(
                              (acc, item) => acc.add(item.unit_amount.times(item.quantity)),
                              Money.zero(currency)
                            )}
                            formatOptions={{ compact: "never" }}
                          />
                        </Heading>
                      </VStack>
                      <ArrowForwardIcon boxSize={10} color="green.500" />
                      <VStack width="100%" spacing={2} ml={2}>
                        <Text>to spend on</Text>
                        <SpendingAuthorityPreview
                          spendingAuthorityId={formValues.spending_authority!.id}
                          spendingAuthorityType={formValues.spending_authority!.type}
                        />
                      </VStack>
                    </HStack>

                    <VStack
                      width={"100%"}
                      align="start"
                      spacing={4}
                      alignItems="center"
                      justifyContent="center">
                      {formValues.spending_authority && (
                        <BudgetItemBody
                          spendingAuthority={formValues.spending_authority}
                          pendingAmount={createdLineItems.reduce(
                            (acc, item) => acc.add(item.unit_amount.times(item.quantity)),
                            Money.zero(currency)
                          )}
                        />
                      )}
                    </VStack>
                  </VStack>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup gap={3}>
                {activeStep > 0 && (
                  <Button onClick={() => setActiveStep((prev) => --prev)}>Back</Button>
                )}
                {activeStep < steps.length - 1 ? (
                  <Button
                    colorScheme="teal"
                    onClick={handleNextStep}
                    isDisabled={!handleFormValidation()}
                    isLoading={isNewLoading}>
                    Next
                  </Button>
                ) : (
                  <Button
                    isLoading={isNewLoading || isUpdateLoading}
                    colorScheme="teal"
                    isDisabled={!handleFormValidation()}
                    onClick={() => handleSubmit(onSubmit)()}>
                    Continue
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </CurrencyProvider>
      </Modal>
    </>
  );
};
