import React from "react";
import {
  Heading,
  Text,
  Avatar,
  HStack,
  VStack,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

export const EmployeeCard = ({ employee }) => (
  <LinkBox width="100%">
    <LinkOverlay as={Link} to={`/hr/employees/${employee.id}`}>
      <HStack justify="space-between" align="center">
        <HStack>
          <Avatar size="sm" src={employee.picture_uri} />
          <VStack width="100%" align="start" spacing={0}>
            <Heading size="xs">{employee.name}</Heading>
            <Text fontSize="xs">{employee.employee_title}</Text>
          </VStack>
        </HStack>
        <Text fontSize="xs" color="gray.500">
          {DateTime.fromISO(employee.joined_at).toRelative({ base: DateTime.now(), style: "long" })}
        </Text>
      </HStack>
    </LinkOverlay>
  </LinkBox>
);
