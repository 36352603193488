import React from "react";
import { Flex, Text, Grid, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useColorModeValue } from "@chakra-ui/react";
import { BsDot } from "react-icons/bs";
import { CalendarEvent } from "../../../api/calendar_event";

interface DayCellProps {
  day: DateTime;
  currentMonth: DateTime;
  events: CalendarEvent[];
  onDayClick: (date: DateTime) => void;
}

export const DayCell: React.FC<DayCellProps> = ({ day, currentMonth, events, onDayClick }) => {
  const isCurrentMonth = day.month === currentMonth.month;
  const today = DateTime.local();
  const isToday = day.hasSame(today, "day");

  const eventsForDay = events.filter((event) => day.hasSame(event.start, "day"));
  const hasEventsForDay = eventsForDay.length > 0;

  const getTooltipLabel = () => {
    if (hasEventsForDay) {
      return eventsForDay.map((event) => event.summary).join(", ");
    } else {
      return "No events scheduled";
    }
  };

  const handleClick = () => {
    onDayClick(day);
  };

  return (
    <Tooltip label={getTooltipLabel()} aria-label="Day events tooltip">
      <Grid
        width={"100%"}
        height={"100%"}
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        templateColumns="repeat(7, 1fr)"
        mt={3}
        mb={3}
        borderRadius={6}
        bg={isToday ? useColorModeValue("teal.50", "teal.600") : "transparent"}
        _hover={{ bg: useColorModeValue("gray.200", "gray.700") }}
        fontWeight={isToday ? "bold" : "normal"}
        onClick={handleClick}
        cursor={"pointer"}>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="40px"
          position="relative">
          <Text
            color={
              isToday
                ? useColorModeValue("teal.600", "gray.300")
                : isCurrentMonth
                ? useColorModeValue("gray.800", "gray.300")
                : "gray.500"
            }>
            {day.day}
          </Text>
          <BsDot style={{ color: hasEventsForDay ? "#319795" : "transparent" }} />
        </Flex>
      </Grid>
    </Tooltip>
  );
};
