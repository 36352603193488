import React, { useState } from "react";
import {
  Box,
  Stack,
  HStack,
  Text,
  Icon,
  VStack,
  Tag,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { BsRecordCircle, BsThreeDots } from "react-icons/bs";
import { CheckCircleIcon, SmallAddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { RichTextEditor } from "@sciencecorp/helix-components";
import {
  ServiceRequestEventData,
  useUpdateServiceRequestEvent,
  useDeleteServiceRequestEvent,
} from "../../../api/service_request_events";
import {
  ServiceRequestShowData,
  useGetServiceRequestEventsQuery,
} from "../../../api/service_requests";
import { useCurrentUserQuery, UserLoggedInData } from "../../../api/user";
import { serviceStatusMap, UserServiceRequestPill } from "../utils";
import { titleize, humanize } from "inflection";
import { DateTime } from "luxon";
import { MoneyText } from "../../MoneyText";

type ServiceRequestTimelineProps = {
  serviceRequest: ServiceRequestShowData;
};

const emptyRichText = JSON.stringify({
  root: {
    type: "root",
    format: "",
    indent: 0,
    version: 1,
    children: [
      {
        type: "paragraph",
        format: "",
        indent: 0,
        version: 1,
        children: [
          {
            mode: "normal",
            text: "",
            type: "text",
            style: "",
            detail: 0,
            format: 0,
            version: 1,
          },
        ],
        direction: "ltr",
      },
    ],
    direction: "ltr",
  },
});

export const ServiceRequestTimeline = ({ serviceRequest }: ServiceRequestTimelineProps) => {
  const { data: currentUser } = useCurrentUserQuery();
  const { data: serviceRequestEvents } = useGetServiceRequestEventsQuery(serviceRequest.id);
  return serviceRequestEvents ? (
    <Stack py={2} borderY="1px" borderColor="gray.200">
      <Stack position="relative" pb={2}>
        <Box
          position="absolute"
          height="100%"
          bg={useColorModeValue("gray.200", "gray.700")}
          width={0.5}
          left={4}
          zIndex={-1}></Box>
        <VStack align="start" spacing={5} py={6}>
          {currentUser &&
            serviceRequestEvents?.map((event: ServiceRequestEventData) => (
              <TimelineEvent
                key={event.id}
                event={event}
                serviceRequest={serviceRequest}
                currentUser={currentUser}
              />
            ))}
        </VStack>
      </Stack>
    </Stack>
  ) : (
    <Spinner />
  );
};

type TimelineEventProps = {
  event: ServiceRequestEventData;
  serviceRequest: ServiceRequestShowData;
  currentUser: UserLoggedInData;
};
const TimelineEvent = ({ event, serviceRequest, currentUser }: TimelineEventProps) => {
  const { event_info: label, id, user, event_type: action, created_at: date } = event;
  const { mutate: deleteServiceRequestEvent } = useDeleteServiceRequestEvent(serviceRequest.id);
  const { mutate: updateServiceRequestEvent } = useUpdateServiceRequestEvent(serviceRequest.id);
  let component: JSX.Element | null = null;
  if (action === "status") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user?.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
          {label.description}{" "}
          <Tag
            size="sm"
            colorScheme={
              serviceStatusMap.find((ele) => ele.label === label.status)?.color || undefined
            }>
            {titleize(humanize(label.status!))}
          </Tag>{" "}
          on {DateTime.fromISO(date).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  } else if (action === "date") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
          {label.description} {DateTime.fromISO(label.date!).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  } else if (action === "cost") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
          {label.description}{" "}
          <MoneyText
            as="span"
            fontWeight="medium"
            money={label.cost || undefined}
            formatOptions={{ compact: "never" }}
          />{" "}
          on {DateTime.fromISO(date).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  } else if (action === "link") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text color="gray.600" fontWeight="medium" fontSize="sm">
          {label.description}{" "}
          <Box as="span" fontWeight="semibold" color={useColorModeValue("gray.600", "gray.300")}>
            Purchase {id}
          </Box>{" "}
          on {DateTime.fromISO(date).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  } else if (action === "charge") {
    if (label.type === "requesting_user") {
      component = (
        <Flex align="center" wrap="wrap" gap={1}>
          <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
            {label.description}{" "}
            <MoneyText
              as="span"
              fontWeight="medium"
              money={label.cost || undefined}
              formatOptions={{ compact: "never" }}
            />{" "}
            to {serviceRequest.requesting_team!.name}
          </Text>
        </Flex>
      );
    } else if (label.type === "approval") {
      component = (
        <Flex align="center" wrap="wrap" gap={1}>
          <Link href={`/users/${user.id}`}>
            <UserServiceRequestPill user={user} />
          </Link>
          <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
            {label.description}{" "}
            <MoneyText
              as="span"
              fontWeight="medium"
              money={label.cost || undefined}
              formatOptions={{ compact: "never" }}
            />{" "}
            on {DateTime.fromISO(date).toFormat("LLL dd")}.
          </Text>
        </Flex>
      );
    } else if (label.type === "declined") {
      component = (
        <Flex align="center" wrap="wrap" gap={1}>
          <Link href={`/users/${user.id}`}>
            <UserServiceRequestPill user={user} />
          </Link>
          <Text fontWeight="medium" color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
            {label.description} of{" "}
            <MoneyText
              as="span"
              fontWeight="medium"
              money={label.cost || undefined}
              formatOptions={{ compact: "never" }}
            />{" "}
            on {DateTime.fromISO(date).toFormat("LLL dd")}.
          </Text>
        </Flex>
      );
    } else {
      component = (
        <Flex align="center" wrap="wrap" gap={1}>
          <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
            {label.description}
          </Text>
        </Flex>
      );
    }
  } else if (action === "closed") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text
          color={useColorModeValue("gray.600", "gray.300")}
          fontWeight="medium"
          fontSize="sm"
          align="center">
          {label.description}{" "}
          {label.status ? (
            <>
              <Tag
                size="sm"
                colorScheme={
                  serviceStatusMap.find((ele) => ele.label === label.status)?.color || undefined
                }>
                {titleize(humanize(label.status))}
              </Tag>{" "}
            </>
          ) : (
            <></>
          )}
          on {DateTime.fromISO(date).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  } else if (action === "assigned_user") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text fontWeight="medium" color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
          {label.description} {label.user_name}{" "}
          {label.description === "assigned" ? "to this" : "from this"} request.
        </Text>
      </Flex>
    );
  } else if (action === "subscribed") {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text fontWeight="medium" color={useColorModeValue("gray.600", "gray.300")} fontSize="sm">
          {label.description} {label.user_name}{" "}
          {label.description === "subscribed" ? "to this" : "from this"} request.
        </Text>
      </Flex>
    );
  } else if (action === "comment" || action === "cost_review") {
    const now = DateTime.now();
    const createdDateFromISO = DateTime.fromISO(date);
    const diffText = createdDateFromISO.toRelative({ base: now, style: "long" });
    const [edit, setEdit] = useState(false);

    component = (
      <Box width="100%" maxW="3xl">
        <Box
          bg={useColorModeValue("gray.50", "gray.400")}
          p={2}
          mb={-2.5}
          borderTopRadius="lg"
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          borderBottom="0px"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Text fontWeight="medium" color="gray.600" fontSize="sm">
            <Link href={`/users/${user.id}`}>
              <Box as="span" fontWeight="semibold" color="gray.800">
                {user.name}
              </Box>{" "}
            </Link>
            {action === "comment" ? "commented" : label.description} | {diffText}
          </Text>
          {currentUser?.id === user.id && (
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BsThreeDots size={20} />}
                size="xs"
                color={useColorModeValue("gray.400", "gray.700")}
                variant="ghost"
              />
              <MenuList alignItems="center">
                <MenuItem
                  icon={<EditIcon />}
                  onClick={() => {
                    setEdit(true);
                  }}>
                  Edit comment
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon color="red.500" />}
                  onClick={() => {
                    deleteServiceRequestEvent(id);
                  }}>
                  Delete comment
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>
        <RichTextEditor
          width="100%"
          editable={edit}
          defaultValue={label.raw_text !== "" ? JSON.stringify(label.raw_text) : emptyRichText}
          onSave={(rich, raw) => {
            updateServiceRequestEvent(
              {
                id,
                event_info: { description: raw ?? "", raw_text: JSON.parse(rich) },
                slack_message: raw ?? "",
              },
              {
                onSuccess: () => setEdit(false),
              }
            );
          }}
        />
      </Box>
    );
  } else {
    component = (
      <Flex align="center" wrap="wrap" gap={1}>
        <Link href={`/users/${user.id}`}>
          <UserServiceRequestPill user={user} />
        </Link>
        <Text color={useColorModeValue("gray.600", "gray.300")} fontWeight="medium" fontSize="sm">
          {label.description} on {DateTime.fromISO(date).toFormat("LLL dd")}
        </Text>
      </Flex>
    );
  }

  let icon: JSX.Element | null = null;
  switch (action) {
    case "create":
      icon = <Icon as={SmallAddIcon} color="gray.500" boxSize={5} />;
      break;
    case "closed":
      if (label.status === "complete") {
        icon = <Icon as={CheckCircleIcon} color="green.400" boxSize={5} />;
      } else if (label.status === "abandoned") {
        icon = <Icon as={CheckCircleIcon} color="orange.400" boxSize={5} />;
      }
      break;
    default:
      icon = <Icon as={BsRecordCircle} color="gray.500" boxSize={5} />;
  }

  return action !== "comment" && action !== "cost_review" ? (
    <HStack alignItems="center">
      <Box
        borderRadius="full"
        bg={
          action !== "closed"
            ? useColorModeValue("gray.100", "gray.600")
            : label.status == "complete"
            ? useColorModeValue("green.100", "green.900")
            : useColorModeValue("orange.100", "orange.900")
        }
        boxSize={8}
        display="flex"
        justifyContent="center"
        alignItems="center">
        {icon}
      </Box>
      {component}
    </HStack>
  ) : (
    <HStack align="start" width="100%">
      <Link href={`/users/${user.id}`}>
        <Avatar src={user.picture_uri} size="sm" />
      </Link>
      {component}
    </HStack>
  );
};
