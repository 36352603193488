import { Flex, Text, useColorMode } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";

export const Greeting = ({ firstName, birthday, workAnniversary, weatherData, calendarEvents }) => {
  const today = DateTime.local();
  const hour = today.hour;
  const { colorMode } = useColorMode();

  const todayKey = `${today.month}-${today.day}`;

  const glowEffect =
    colorMode === "light"
      ? ""
      : "0 0 8px rgba(255, 255, 255, 0.8), 0 0 12px rgba(255, 255, 255, 0.7), 0 0 16px rgba(255, 205, 0, 0.6)"; // Yellow glow for dark mode

  const glowAnimation = {
    "&:hover": {
      textShadow: glowEffect,
      transition: "text-shadow 0.3s ease-in-out",
    },
  };

  const weatherCodeToDescription = {
    0: "with clear skies",
    1: "and mainly clear",
    2: "and partly cloudy",
    3: "and overcast",
    45: "and foggy",
    51: "with isolated showers",
    53: "with scattered showers",
    55: "with occasional showers",
    56: "and rainy",
    57: "with thunder showers",
    60: "with isolated snow showers",
    61: "with scattered snow showers",
    63: "with occasional snow showers",
    64: "and snowy",
    65: "and snowy",
    70: "and misty",
    71: "and foggy",
    73: "with thunderstorms",
    75: "and projecting thunderstorms with hail",
    80: "and rainy",
    81: "with heavy rain",
    85: "and snowy",
    86: "with heavy snowfall",
    95: "and hailing",
    97: "and thunderstorms with hail",
  };

  function getWeatherDescription(code) {
    return weatherCodeToDescription[code] || "";
  }

  const holidaysWithEmojis = {
    "1-1": { name: "New Year's Day", emoji: "🥳" },
    "2-2": { name: "Groundhog Day", emoji: "🎩" },
    "2-1": { name: "Black History Month", emoji: "✊🏾" },
    "2-11": { name: "International Day of Women and Girls in Science", emoji: "👩‍🔬" },
    "2-14": { name: "Valentine's Day", emoji: "🩷" },
    "3-1": { name: "Women's History Month", emoji: "👩" },
    "3-14": { name: "Pi Day", emoji: "🥧" },
    "3-17": { name: "St. Patrick's Day", emoji: "🍀" },
    "3-26": { name: "National Science Appreciation Day", emoji: "🔬" },
    "4-1": { name: "April Fools' Day", emoji: "😜" },
    "4-22": { name: "Earth Day", emoji: "🌍" },
    "4-25": { name: "National DNA Day", emoji: "🧬" },
    "5-1": { name: "AAPI Heritage Month", emoji: "🌏" },
    "5-4": { name: "Star Wars Day", emoji: "⭐️" },
    "5-5": { name: "Cinco de Mayo", emoji: "🇲🇽" },
    "6-1": { name: "Pride Month", emoji: "🏳️‍🌈" },
    "6-19": { name: "Juneteenth", emoji: "✊🏿" },
    "7-4": { name: "Independence Day", emoji: "🇺🇸" },
    "7-7": { name: "World Chocolate Day", emoji: "🍫" },
    "7-12": { name: "Birthday to Science Corporation", emoji: "🎂" },
    "8-8": { name: "International Cat Day", emoji: "😺" },
    "8-26": { name: "International Dog Day", emoji: "🐶" },
    "9-1": { name: "Hispanic Heritage Month", emoji: "🌎" },
    "9-4": { name: "National Wildlife Day", emoji: "🐅" },
    "9-13": { name: "Programmers Day", emoji: "💻" },
    "9-22": { name: "Autumnal Equinox", emoji: "🍂" },
    "9-26": { name: "HR Appreciation Day", emoji: "💼" },
    "10-1": { name: "Breast Cancer Awareness Month", emoji: "🎗️" },
    "10-31": { name: "Halloween", emoji: "🎃" },
    "11-11": { name: "Veterans Day", emoji: "🪖" },
    "11-13": { name: "World Kindness Day", emoji: "💖" },
    "12-4": { name: "National Cookie Day", emoji: "🍪" },
    "12-21": { name: "Winter Solstice", emoji: "❄️" },
    "12-31": { name: "New Year's Eve", emoji: "🍾" },
    // Holidays with Variable Dates (UPDATE IN 2025)
    "1-28": { name: "International Laboratory Animal Technician Week", emoji: "🐭" }, //last full week in Jan
    "2-15": { name: "Martin Luther King Jr. Day", emoji: "✊🏾" }, //third Monday in Jan
    "2-21": { name: "Presidents' Day", emoji: "🇺🇸" }, //third Monday in Feb
    "5-27": { name: "Memorial Day", emoji: "🇺🇸" }, //last Monday in May
    "9-2": { name: "Labor Day", emoji: "🛠️" }, //first Monday in Sept
    "11-28": { name: "Thanksgiving", emoji: "🦃" }, //fourth Thursday in Nov
  };

  const holiday = holidaysWithEmojis[todayKey];

  let timeOfDayGreeting;
  let timeOfDayEmoji;
  if (hour < 12) {
    timeOfDayGreeting = "Good morning";
    timeOfDayEmoji = "☕";
  } else if (hour < 14) {
    timeOfDayGreeting = "Good afternoon";
    timeOfDayEmoji = "🌞";
  } else if (hour < 18) {
    timeOfDayGreeting = "Good afternoon";
    timeOfDayEmoji = "🌤️";
  } else {
    timeOfDayGreeting = "Good evening";
    timeOfDayEmoji = "🌜";
  }

  let isBirthday: boolean;
  if (birthday) {
    const birthdayDateTime = DateTime.fromISO(birthday);
    isBirthday = birthdayDateTime.month === today.month && birthdayDateTime.day === today.day;
  } else isBirthday = false;

  const isWorkAnniversary =
    workAnniversary &&
    DateTime.fromISO(workAnniversary).year !== today.year &&
    DateTime.fromISO(workAnniversary).month === today.month &&
    DateTime.fromISO(workAnniversary).day === today.day;

  let specialOccasionGreeting = "",
    specialOccasionEmoji = "";
  if (isBirthday) {
    specialOccasionGreeting = "Happy Birthday";
    specialOccasionEmoji = "🎂";
  } else if (isWorkAnniversary) {
    specialOccasionGreeting = "Happy Work Anniversary";
    specialOccasionEmoji = "🎉";
  } else if (holiday) {
    specialOccasionGreeting = `Happy ${holiday.name}`;
    specialOccasionEmoji = holiday.emoji;
  }

  let weatherGreeting = "It's a beautiful day!";

  if (weatherData?.current_weather) {
    const { temperature, weathercode } = weatherData.current_weather;
    const weatherDescription = getWeatherDescription(weathercode);
    const roundedTemperature = Math.round(temperature);

    if (weatherDescription) {
      weatherGreeting = `It's currently ${roundedTemperature}°F ${weatherDescription}.`;
    } else {
      weatherGreeting = `It's currently ${roundedTemperature}°F.`;
    }
  }
  let greetingEmoji = specialOccasionEmoji || timeOfDayEmoji;

  // Greetings for a light day
  const lightCalendarGreetings = [
    "Your calendar looks chill today.",
    "Looks like you have a light day ahead.",
    "Today's agenda is looking light.",
    "You've got a breezy calendar today.",
    "It seems like a calm day on your schedule.",
  ];

  // Greetings for a busier day
  const busyCalendarGreetings = [
    "You've got a packed day ahead.",
    "Looks like a full schedule today!",
    "Today's agenda is brimming with events.",
    "Your day is shaping up to be quite busy!",
    "Brace yourself for a day full of activities.",
  ];

  const calendarGreeting =
    calendarEvents.length > 3
      ? busyCalendarGreetings[Math.floor(Math.random() * busyCalendarGreetings.length)]
      : lightCalendarGreetings[Math.floor(Math.random() * lightCalendarGreetings.length)];

  return (
    <Flex direction="column">
      <Text fontSize="4xl" sx={glowAnimation} fontWeight="medium" mb={4}>
        {greetingEmoji}
      </Text>
      <Text fontSize="xl" fontWeight="semibold">
        {specialOccasionGreeting ? (
          <>
            <Text fontSize="sm" fontWeight="normal">
              {timeOfDayGreeting}, {firstName},
            </Text>
            <Text fontSize="2xl" fontWeight="semibold" sx={glowAnimation}>
              {specialOccasionGreeting}!
            </Text>
          </>
        ) : (
          <>
            <Text fontSize="2xl" fontWeight="normal">
              {timeOfDayGreeting} {firstName},
            </Text>
          </>
        )}
      </Text>
      <Text fontSize="lg" fontWeight="normal">
        {weatherGreeting} {calendarGreeting}
      </Text>
    </Flex>
  );
};
