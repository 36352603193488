import { DateTime } from "luxon";

export type CompactOption = "always" | "never" | "over1000";

const percentFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export function percentage(value: number, total: number) {
  if (total == 0) {
    return "0%";
  }
  return percentFormat.format(value / total);
}

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const summaryDates = (option: string) => {
  if (months.includes(option)) {
    const startTime = DateTime.local()
      .set({ month: months.indexOf(option) + 1 })
      .startOf("month");

    return {
      scorePeriodStartDate: startTime,
      scorePeriodEndDate: startTime.endOf("month"),
    };
  }
  switch (option) {
    case "allTime":
      return {
        scorePeriodStartDate: DateTime.fromISO("2020-01-01"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("day"),
      };
    case "yearToDate":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().startOf("year"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("year"),
      };
    case "today":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().startOf("day"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("day"),
      };
    case "thisWeek":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().startOf("week"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("week"),
      };
    case "thisMonth":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().startOf("month"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("month"),
      };
    case "last3Months":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().minus({ months: 3 }).startOf("day"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("day"),
      };
    case "last6Months":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().minus({ months: 6 }).startOf("day"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("day"),
      };
    case "last12Months":
      return {
        scorePeriodStartDate: DateTime.now().toUTC().minus({ months: 12 }).startOf("day"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("day"),
      };

    default:
      return {
        scorePeriodStartDate: DateTime.now().toUTC().startOf("month"),
        scorePeriodEndDate: DateTime.now().toUTC().endOf("month"),
      };
  }
};
