import { AddIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CollectionTable, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import { humanize } from "inflection";
import _ from "lodash";
import React, { useMemo } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { PurchaseShowData } from "../../../../api/purchase";
import { PurchaseLineItemData } from "../../../../api/purchase_line_item";
import { useUpdatePurchaseRefund } from "../../../../api/purchase_refunds";
import {
  PurchaseReturnsData,
  PurchaseReturnsShowData,
  useCreatePurchaseReturnServiceRequest,
} from "../../../../api/purchase_returns";
import { UserPartialData } from "../../../../api/user";
import { EditableMoney } from "../../../EditableMoney";
import {
  formatReasonForReturn,
  refundStatusColor,
  updateLineItemsQuantity,
  updateReturnLineItems,
} from "../util";
import { RefundModal } from "./RefundModal";
import { ReturnModal } from "./ReturnModal";

type SubmittedReturnsProps = {
  returns: PurchaseReturnsShowData[];
  lineItems: PurchaseLineItemData[];
  user: UserPartialData;
  isPurchasingAdmin: boolean;
  purchase: PurchaseShowData;
};
export const SubmittedReturnsTable = ({
  returns,
  lineItems,
  user,
  isPurchasingAdmin,
  purchase,
}: SubmittedReturnsProps) => {
  const {
    isOpen: isOpenReturnModal,
    onOpen: onOpenReturnModal,
    onClose: onCloseReturnModal,
  } = useDisclosure();
  const { mutateAsync: updatePurchaseRefund } = useUpdatePurchaseRefund();

  const enableRefundButton = () => {
    if (!isPurchasingAdmin) {
      return false;
    }

    returns.forEach((r) => {
      if (r.purchase_refund !== null) {
        return false;
      }
    });
    return true;
  };

  const closedReturnLineItems = (returns || [])
    .filter((ret) => ret.service_request_id)
    .flatMap((ret) => ret.purchase_return_line_items);

  const lineItemsWithRemainingQty =
    lineItems && updateLineItemsQuantity(lineItems, closedReturnLineItems);

  const returnLineItems = updateReturnLineItems(returns);

  const {
    isOpen: isOpenRefundModal,
    onOpen: onOpenRefundModal,
    onClose: onCloseRefundModal,
  } = useDisclosure();
  const toast = useToast();
  const {
    mutateAsync: createPurchaseReturnServiceRequest,
    isLoading: createPurchaseReturnServiceRequestIsLoading,
  } = useCreatePurchaseReturnServiceRequest();

  const handleCreateServiceRequest = (returnId: number) => {
    createPurchaseReturnServiceRequest(returnId).catch((error) => {
      console.error(error);
      toast({
        title: "An error occurred.",
        description: error.response?.data?.error?.message || "Unknown error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  const columns = useMemo(
    () => [
      {
        label: "Link to Request",
        render: (item: PurchaseReturnsData) => {
          if (item?.service_request_id) {
            return (
              <RecordLink
                type="Service Request"
                identifier={item.service_request_id}
                link={`/services/requests/${item.service_request_id}`}
                justifyContent="align-start"
                width="max-content"
              />
            );
          } else {
            return isPurchasingAdmin ? (
              <Button
                size="sm"
                onClick={() => handleCreateServiceRequest(item.id)}
                isLoading={createPurchaseReturnServiceRequestIsLoading}>
                Create Service Request
              </Button>
            ) : (
              "No linked request yet."
            );
          }
        },
      },

      {
        label: "Created By",
        render: (item: PurchaseReturnsShowData) => {
          return (
            <Link as={RouterLink} to={`/users/${item.user.id}`}>
              <HStack>
                <Avatar size="xs" name={item.user.name} src={item.user.picture_uri} />
                <Text fontSize="sm">{item.user.name}</Text>
              </HStack>
            </Link>
          );
        },
      },
      {
        label: "Items Returned",
        render: (item: PurchaseReturnsShowData) => {
          return (
            <Tooltip key={item.id} label={formatReasonForReturn(item.reason_for_return)}>
              <Flex direction="column">
                {item.purchase_return_line_items.map((i, index) => (
                  <Flex key={i.id} direction="row">
                    - {i.name} x {i.quantity}
                  </Flex>
                ))}
              </Flex>
            </Tooltip>
          );
        },
      },
      {
        label: "Status",
        render: (item: PurchaseReturnsData) => {
          return <Tag colorScheme={refundStatusColor(item.status)}>{humanize(item.status)}</Tag>;
        },
      },
      {
        label: "Amount",
        render: (item: PurchaseReturnsShowData) =>
          item.purchase_refund ? (
            <EditableMoney
              defaultValue={item.purchase_refund.amount_refunded}
              onSubmit={(value) => {
                if (value && item.purchase_refund) {
                  updatePurchaseRefund({
                    id: item.purchase_refund.id,
                    purchase_id: purchase.id,
                    amount_refunded: value,
                  });
                }
              }}
            />
          ) : (
            "--"
          ),
      },
    ],
    [returns]
  );

  return (
    <>
      <Flex
        flex="1"
        direction="column"
        p={6}
        gap={4}
        border={"1px"}
        borderColor="chakra-border-color"
        borderRadius="md"
        h="100%"
        w="100%">
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Heading size="md">Submitted Returns</Heading>
          <Stack direction={["column", "row"]}>
            <Button size={"sm"} onClick={onOpenReturnModal}>
              <Box as={RiArrowGoBackLine} mr="2" />
              Return Items
            </Button>
            <Button size={"sm"} isDisabled={!enableRefundButton()} onClick={onOpenRefundModal}>
              <Box as={AddIcon} mr="2" />
              Add Refund
            </Button>
          </Stack>
        </Stack>
        {returns.length > 0 ? (
          <CollectionTable items={returns} columns={columns} />
        ) : (
          <EmptyState size="3xs" title="No returns have been started." />
        )}
      </Flex>
      <ReturnModal
        isOpen={isOpenReturnModal}
        onClose={onCloseReturnModal}
        purchaseLineItems={lineItemsWithRemainingQty}
        previousReturns={returns}
      />
      {returnLineItems && (
        <RefundModal
          isOpen={isOpenRefundModal}
          onClose={onCloseRefundModal}
          returns={returnLineItems}
        />
      )}
    </>
  );
};
