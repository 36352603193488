import { z } from "zod";
import forge from "node-forge";

export const keyringMessageSchema = z.object({
  encrypted_content: z.string(),
  passwords: z.array(
    z.object({
      public_key: z.string(),
      encrypted_password: z.string(),
    })
  ),
});

export type KeyringMessageData = z.infer<typeof keyringMessageSchema>;

type EncryptedPassword = {
  public_key: string;
  encrypted_password: string;
};

type SealedMessage = {
  encrypted_content: string;
  passwords: EncryptedPassword[];
};

export const decryptPrivateKey = (
  encryptedPrivateKey: string,
  pin: string
): forge.pki.rsa.PrivateKey | null => {
  return forge.pki.decryptRsaPrivateKey(encryptedPrivateKey, pin);
};

const removeControlCharacters = (str: string): string =>
  str.replace(/[\u0000-\u001F\u007F-\u009F]+/g, "�");

/**
 * Keyring is a class that can be used to encrypt and decrypt data to a set of public keys.
 * Posession of any of the corresponding private keys is sufficient to decrypt the data, and by
 * extension can be used re-encrypt the data to a new set of public keys. This is useful for
 * sharing data with a group of people, where the data can be encrypted to each person's public
 * key, and then re-encrypted to a new set of public keys when a new person is added to the group.
 *
 * This was originally developed for the purpose of sharing peer reviews between users while protecting
 * the information on the server, but can be used for any purpose where data needs to be securely shared
 * with a group of people.
 */
export class Keyring {
  private _content: string;
  private _publicKeys: Set<string>;
  private _password: string;
  private _privateKey: forge.pki.rsa.PrivateKey;
  private _publicKey: forge.pki.rsa.PublicKey;
  private _PEM: string;

  constructor(publicKeys: string[]) {
    this._publicKeys = new Set(publicKeys);
  }

  public decrypt(privateKey: forge.pki.rsa.PrivateKey, sealedMessageB64: string) {
    this._privateKey = privateKey;
    this._publicKey = forge.pki.setRsaPublicKey(this._privateKey.n, this._privateKey.e);
    this._PEM = forge.pki.publicKeyToPem(this._publicKey);

    const sealedMessage = JSON.parse(forge.util.decode64(sealedMessageB64)) as SealedMessage;

    if (sealedMessage && !this.openSealedMessage(sealedMessage)) {
      console.log("failed to open sealed message");
      return false;
    }

    this._publicKeys.add(this._PEM);

    return true;
  }

  public setContent(content: string) {
    this._content = content;
  }

  public getContent() {
    return this._content;
  }

  public getSealedMessage(): string {
    const [encryptedContent, passwords] = this._seal();
    return forge.util.encode64(
      JSON.stringify({
        encrypted_content: encryptedContent,
        passwords: passwords,
      })
    );
  }

  public addPublicKey(publicKey: string) {
    this._publicKeys.add(publicKey);
  }

  public removePublicKey(publicKey: string) {
    this._publicKeys.delete(publicKey);
  }

  public getPublicKeys() {
    return Array.from(this._publicKeys);
  }

  private openSealedMessage(sealedMessage: SealedMessage): boolean {
    this._publicKeys = new Set(sealedMessage.passwords.map((p) => p.public_key));
    const pkPem = forge.pki.publicKeyToPem(this._publicKey).replace(/\r\n/g, "\n");
    const normalizedMessagePasswords = sealedMessage.passwords.map((p) => ({
      public_key: p.public_key.replace(/\r\n/g, "\n"),
      encrypted_password: p.encrypted_password,
    }));

    const password = normalizedMessagePasswords.find((p) => pkPem == p.public_key);

    if (!password) {
      console.log("public key for given private key not found in sealed message passwords");
      return false;
    }

    this._password = this._privateKey.decrypt(
      forge.util.hexToBytes(password.encrypted_password),
      "RSA-OAEP"
    );

    if (!this._unseal(sealedMessage.encrypted_content)) {
      console.log("could not unseal message");
      return false;
    }

    return true;
  }

  private _seal(): [string, EncryptedPassword[]] {
    // generate a random key and IV (key size of 32 => AES-256)
    const password = forge.random.getBytesSync(32);
    const iv = forge.random.getBytesSync(32);

    // encrypt review content using key, IV, and authentication tag
    const cipher = forge.cipher.createCipher("AES-GCM", password);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(forge.util.encodeUtf8(this._content)));
    cipher.finish();
    const encryptedContent = cipher.output;
    const tag = cipher.mode.tag.bytes();

    // public key encrypt the key, IV, and authentication tag
    const publicKeys = Array.from(this._publicKeys).map((key) => forge.pki.publicKeyFromPem(key));
    const passwords = publicKeys.map((key) => ({
      public_key: forge.pki.publicKeyToPem(key),
      encrypted_password: forge.util.bytesToHex(key.encrypt(password + iv + tag, "RSA-OAEP")),
    }));

    return [encryptedContent.toHex(), passwords];
  }

  private _unseal(encryptedContent: string) {
    const cipher = forge.cipher.createDecipher("AES-GCM", this._password.slice(0, 32));

    const tag = new forge.util.ByteStringBuffer();
    tag.putBytes(this._password.slice(64));

    cipher.start({
      iv: this._password.slice(32, 64),
      tag: tag,
    });
    cipher.update(forge.util.createBuffer(forge.util.hexToBytes(encryptedContent)));
    cipher.finish();

    const decryptedContent = cipher.output;

    this._content = removeControlCharacters(forge.util.decodeUtf8(decryptedContent.data));

    return true;
  }
}
