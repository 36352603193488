import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ConfirmationModal } from "@sciencecorp/helix-components";
import { titleize } from "inflection";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDeleteFile } from "../../../../api/blob_files";
import { invalidateReimbursement, ReimbursementShowData } from "../../../../api/reimbursement";
import {
  ReimbursementLineItemShowData,
  useDeleteReimbursementLineItem,
  useUpdateReimbursementLineItem,
} from "../../../../api/reimbursement_line_items";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { MoneyText } from "../../../MoneyText";
import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";
import { FileUploadButton } from "../../Purchase/components/FileUploadButton";
import { AddExpenseToReimbursementModal } from "./AddExpenseToReimbursementModal";

type ReimbursementCardItemProps = {
  reimbursement: ReimbursementShowData;
  reimbursement_line_item: ReimbursementLineItemShowData;
};

const ReimbursementCardItem = ({
  reimbursement,
  reimbursement_line_item,
}: ReimbursementCardItemProps) => {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { mutate: deleteReimbursementLineItem } = useDeleteReimbursementLineItem(reimbursement.id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateReimbursement(reimbursement.id));
  const currentUser = useCurrentUserQuery();
  const { mutate: updateReimbursementLineItem, isLoading } = useUpdateReimbursementLineItem(
    reimbursement.id
  );
  const isPurchasingAdmin = userHasRole(currentUser, "purchasing_admin");
  const [editingReimbursementLineItem, setEditingReimbursementLineItem] =
    useState<ReimbursementLineItemShowData | null>(null);

  const editable =
    currentUser.data?.id === reimbursement?.user.id && reimbursement.approval_status === "draft";

  return (
    <>
      <Card direction={"row"} w="100%" variant="outline">
        <Flex direction={"column"} w="100%">
          <CardHeader p={6}>
            <VStack align={"start"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
                width={"100%"}>
                <Text fontSize={"xl"} fontWeight={"medium"}>
                  {titleize(reimbursement_line_item.name)}
                </Text>
                <Flex justifyContent={"space-between"}>
                  {(isPurchasingAdmin || reimbursement.approval_status === "draft") && (
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            as={IconButton}
                            icon={<BsThreeDotsVertical />}
                            size="md"
                            aria-label="More actions"
                            isActive={isOpen}
                            disabled={!editable || isPurchasingAdmin}
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                setEditingReimbursementLineItem(reimbursement_line_item);
                              }}>
                              <EditIcon marginRight="4" />
                              Edit Item
                            </MenuItem>
                            <MenuItem color="red.600" onClick={onDeleteOpen}>
                              <DeleteIcon marginRight="4" />
                              Delete Item
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  )}

                  <ConfirmationModal
                    colorScheme={"red"}
                    confirmText={"Yes"}
                    header={"Delete Reimbursement Line Item"}
                    isOpen={isDeleteOpen}
                    onClick={() => {
                      deleteReimbursementLineItem(reimbursement_line_item.id);
                    }}
                    onClose={onDeleteClose}>
                    {
                      "Are you sure you want to delete this Reimbursement Line Item? This action cannot be undone."
                    }
                  </ConfirmationModal>
                </Flex>
              </Flex>
              <Text>{reimbursement_line_item.description}</Text>
            </VStack>
          </CardHeader>
          <Divider />
          <CardBody p={6}>
            <HStack justifyContent={"space-between"} width={"100%"}>
              <Flex gap={"5"}>
                <Flex direction={{ base: "column", md: "row" }} gap={"2"}>
                  <Text>Date:</Text>
                  <Text fontWeight={"semibold"}>
                    {DateTime.fromISO(reimbursement_line_item.expense_date).toFormat(
                      "MMMM dd, yyyy"
                    )}
                  </Text>
                </Flex>
                <Flex direction={{ base: "column", md: "row" }} gap={"2"}>
                  <Text>Location: </Text>
                  <Text fontWeight={"semibold"}>{reimbursement_line_item.location}</Text>
                </Flex>
              </Flex>
              <Flex>
                <Center>
                  <MoneyText
                    fontSize={"xl"}
                    fontWeight={"bold"}
                    money={reimbursement_line_item.amount}
                    formatOptions={{ compact: "never" }}
                  />
                </Center>
              </Flex>
            </HStack>
          </CardBody>
          <Divider />
          <CardFooter p={6}>
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "start", md: "center" }}>
              <Box
                marginBottom={{ base: "2", md: "auto" }}
                height={"100%"}
                alignContent="center"
                justifyContent={"flex-center"}>
                <Text marginRight="2">Receipt(s): </Text>
              </Box>
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={["start", "center"]}
                gap={[2, 4]}
                flexWrap="wrap">
                {reimbursement_line_item.uploaded_files.map((file, idx) => (
                  <DeleteableFileDownload key={idx} file={file} deleteFile={deleteFile} />
                ))}
                {editable ? (
                  <Box>
                    <FileUploadButton
                      record={reimbursement_line_item}
                      updateFunction={updateReimbursementLineItem}
                      isLoading={false}
                    />
                  </Box>
                ) : null}
              </Flex>
            </Flex>
          </CardFooter>
        </Flex>
      </Card>

      {editingReimbursementLineItem && (
        <AddExpenseToReimbursementModal
          mode="edit"
          reimbursementId={reimbursement.id}
          isOpen={!!editingReimbursementLineItem}
          onClose={() => setEditingReimbursementLineItem(null)}
          reimbursementLineItem={editingReimbursementLineItem}
        />
      )}
    </>
  );
};

export default ReimbursementCardItem;
