import React, { useState, useEffect } from "react";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { SplitPage, useCollection, buildFacets, Facets } from "@sciencecorp/helix-components";
import { FiArchive } from "react-icons/fi";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useSearchArchivedCredentials } from "../../api/credentials";
import { useSearchArchivedTrainings } from "../../api/trainings";
import { ArchivedCredentialsTable } from "./components/ArchivedCredentialsTable";
import { ArchivedTrainingsTable } from "./Trainings/components/ArchivedTrainingsTable";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { credentialBodyOptions } from "./util";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";

const TABS = [
  { label: "Archived Credentials", value: "credentials", icon: <FiArchive /> },
  { label: "Archived Trainings", value: "trainings", icon: <FiArchive /> },
];

export const ArchivePage = () => {
  const { tab } = useParams();
  if (!tab) return null;
  const navigate = useNavigate();
  const currentUserQuery = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUserQuery, "credentials_admin");
  const [activeTab, setActiveTab] = useState(tab);

  const { search, debouncedSearch } = useDebouncedSearch();
  const { facets, onFacets, filters, pagination, onPagination, resetPagination, resetFacets } =
    useCollection();

  useEffect(() => {
    setActiveTab(tab);
    resetFacets();
    resetPagination();
  }, [tab]);

  const AGGREGATIONS_QUERY = ["is_public"];

  const { data, isLoading } =
    activeTab === "credentials"
      ? useSearchArchivedCredentials({
          term: search || "*",
          pagination,
          aggs: AGGREGATIONS_QUERY,
          bodyOptions: credentialBodyOptions,
          filters,
        })
      : useSearchArchivedTrainings({
          term: search || "*",
          pagination,
          filters,
        });

  const populatedFacets = buildFacets(data?.aggregations || [], facets);

  return isCredentialsAdmin ? (
    <Flex direction="column">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/credentials/all" color="teal.500">
            Trainings & Credentials
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading mb={4}>Archive</Heading>
      <SplitPage
        sidebarWidthXL="300px"
        sidebarWidth="250px"
        sidebar={
          <VStack width="100%" align="start">
            <VStack w="100%">
              {TABS.map((tab) => (
                <Button
                  key={tab.label}
                  leftIcon={tab.icon}
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === tab.value ? "teal" : "gray"}
                  bg={activeTab === tab.value ? "teal.500" : "transparent"}
                  onClick={() => {
                    navigate(`/credentials/archive/${tab.value}`);
                  }}>
                  {tab.label}
                </Button>
              ))}
            </VStack>
            <Box width="max-content" display={["none", "none", "inline"]} alignSelf="start">
              <Facets
                facets={populatedFacets}
                onChange={onFacets}
                debouncedSearch={debouncedSearch}
                search
                background
              />
            </Box>
            <Box display={{ base: "flex", md: "none" }} justifyContent="flex-end" w="100%">
              <Facets
                variant="button"
                facets={populatedFacets}
                onChange={onFacets}
                debouncedSearch={debouncedSearch}
                search
                background
              />
            </Box>
          </VStack>
        }
        main={
          <Flex width="100%">
            {activeTab === "credentials" && (
              <ArchivedCredentialsTable
                data={data}
                isLoading={isLoading}
                pagination={pagination}
                onPagination={onPagination}
              />
            )}
            {activeTab === "trainings" && (
              <ArchivedTrainingsTable
                data={data}
                isLoading={isLoading}
                pagination={pagination}
                onPagination={onPagination}
              />
            )}
          </Flex>
        }
      />
    </Flex>
  ) : (
    <Heading>Not Authorized</Heading>
  );
};
