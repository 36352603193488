import { z } from "zod";
import { invalidatePurchases } from "./purchase";
import { useMutation } from "@tanstack/react-query";
import { api } from ".";
import { moneySchema } from "../helpers/Money";

export const PURCHASE_REFUNDS_BASE_URL = "purchase_refund";

/** purchase refunds */
export const purchaseRefundsSchema = z.object({
  id: z.number(),
  type_of_refund: z.string(),
  amount_refunded: moneySchema,
  purchase_return_id: z.number(),
  purchase_id: z.number(),
});

export type PurchaseRefundsData = z.infer<typeof purchaseRefundsSchema>;
export type PurchaseRefundCreateParams = Pick<
  PurchaseRefundsData,
  "type_of_refund" | "amount_refunded" | "purchase_return_id" | "purchase_id"
>;

export const createPurchaseRefund = async (purchase_refund: PurchaseRefundCreateParams) => {
  const result = await api.post(PURCHASE_REFUNDS_BASE_URL, { purchase_refund });
  return result.data.id;
};

export const updatePurchaseRefund = async (purchase_refund: Partial<PurchaseRefundsData>) => {
  const result = await api.put(`${PURCHASE_REFUNDS_BASE_URL}/${purchase_refund.id}`, {
    purchase_refund,
  });
  return result.data.id;
};

export const useCreatePurchaseRefund = () => {
  return useMutation({
    mutationFn: createPurchaseRefund,
    onSuccess: invalidatePurchases(),
  });
};

export const useUpdatePurchaseRefund = () => {
  return useMutation({
    mutationFn: updatePurchaseRefund,
    onSuccess: invalidatePurchases(),
  });
};
